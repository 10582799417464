import '../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db, storage } from '../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineFolderView, AiOutlineFundView, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaRegEdit } from 'react-icons/fa';
import contractOkay from '../../../../../../../../images/icones/svg/contract-okay.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';
import BudgetBox_pendente from './Assets/Components/BudgetBox_single';

// TESTE EMAIL
import emailjs from '@emailjs/browser';



// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond'
import pt_BR from 'filepond/locale/pt-br';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
// FilePond.setOptions(pt_BR);

function PC_ADM_Orcamentos(props) {
    const [files, setFiles] = useState([])

    const [expandedSummary, setExpandedSummary] = useState(0); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);


    const [orcamentos, setOrcamentos] = useState([]);

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user) && props.user.representante && props.user.dados_representante.cargo === "CEO") {

            db.collection("servicos")
                .orderBy("expedicao", "desc")
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })


            /*
            db.collection("orcamentos")
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })

*/
            // db.collection("drive")
            //     .onSnapshot(snap => {
            //         var arr = ([]);
            //         snap.docs.map(doc => {
            //             arr.push(doc.data());
            //         })
            //         console.log(arr);
            //     })
        } else if (props.user && !isEmptyObject(props.user) && props.user.representante) {

            db.collection("servicos")
                .where("numero_representante_contratado", "==", props.user.token_representante)
                    .orderBy("expedicao", "desc")
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })

            /*
            db.collection("orcamentos")
                .where("numero_representante_contratado", "==", props.user.token_representante)
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })
*/

            // db.collection("drive")
            //     .onSnapshot(snap => {
            //         var arr = ([]);
            //         snap.docs.map(doc => {
            //             arr.push(doc.data());
            //         })
            //         console.log(arr);
            //     })
        }
    }, [])



    const [progresso, setProgresso] = useState(0);
    const [content, setContent] = useState([]);


    async function fazerUploadArquivo(arquivo, uid) {
        const uploadTask = storage.ref('drive/' + arquivo.name).put(arquivo);



        uploadTask.on('state_changed', (snapshot) => {

            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 1;

            setProgresso(progress);

        },

            function (error) {



            },



            function () {

                storage.ref('SERVICOS/E951357852456FC/' + arquivo.name).getDownloadURL().then((url) => {

                    db.collection('drive').add({

                        diretorio: url,

                        tipo: arquivo.type,

                        orderId: 1

                    })

                })


            }



        )

    }

    function cadastrarServico() {
        const obj = {
            "acompanhamento": true,
            "anexo_contrato": null,
            "aprovacao_orcamento": null,
            "area_servico": "200",
            "assinatura_contratante": null,
            "assinatura_representante": null,
            "cargo_representante": "CEO",
            "categoria": "Serviço Particular",
            "categoria_servico": "Serviço Particular",
            "compras": false,
            "contratado": "Engeart - Projetos Prontos",
            "contratante": "Sandinilson Jacovaci",
            "contrato": null,
            "cpf_cnpj_contratado": "32858181000188",
            "cpf_cnpj_contratante": "27758951821",
            "dataInicio": "2023-02-20T08:00",
            "dataUltimaAtualizacao": null,
            "descricao_servico": "Reforma e Ampliação Residencial",
            "email": "magouveiaj@gmail.com",
            "endereco_contratado": {
                "bairro": "Centro",
                "cidade": "Monte Aprazível",
                "estado": "SP",
                "cep": "15150000",
                "logradouro": "Rua Duque de Caxias",
                "numero": "1046"
            },
            "endereco_contratante": {
                "apelido": "Minha casa",
                "complemento": "Casa",
                "referencia": "",
                "principal": true,
                "estado": "SP",
                "logradouro": "Duque de Caxias",
                "tipo_logradouro": "Rua",
                "numero": "222",
                "cidade": "Monte Aprazível",
                "adicionais": {
                    "jardim": false,
                    "piscina": false
                },
                "criado": {
                    "seconds": 1676315761,
                    "nanoseconds": 330000000
                },
                "cep": "15150-000",
                "bairro": "Centro"
            },
            "endereco_servico": {
                "criado": {
                    "seconds": 1676315802,
                    "nanoseconds": 110000000
                },
                "tipo_logradouro": "Rua",
                "bairro": "Centro",
                "referencia": null,
                "estado": "SP",
                "apelido": "Endereço da Obra",
                "adicionais": {
                    "piscina": false,
                    "jardim": false
                },
                "principal": false,
                "complemento": "",
                "logradouro": "Duque de Caxias",
                "cep": "15150-000",
                "numero": "222",
                "cidade": "Monte Aprazível"
            },
            "estado_civil_contratante": "Casado(a)",
            "etapas": [
                {
                    "anexos": [],
                    "concluido": false,
                    "dataAprovacao": null,
                    "dataFinal": "2023-02-13T10:44",
                    "dataInicio": "2023-02-13T10:44",
                    "descricao": "Assinatura do Contrato",
                    "slug": "assinatura-do-contrato",
                    "status": false,
                    "statusContratado": false,
                    "statusContratante": false
                },
                {
                    "anexos": [],
                    "concluido": false,
                    "dataAprovacao": null,
                    "dataFinal": "2023-06-19T18:00",
                    "dataInicio": "2023-02-19T07:00",
                    "descricao": "Execução da Obra de 20 de Fevereiro à 19 de Junho de 2023",
                    "slug": "execucao-da-obra-de-20-de-fevereiro-a-20-de-junho-de-2023",
                    "status": false,
                    "statusContratado": false,
                    "statusContratante": false,
                },
                {
                    "anexos": [],
                    "concluido": false,
                    "dataAprovacao": null,
                    "dataFinal": "2023-06-20T18:00",
                    "dataInicio": "2023-06-20T07:00",
                    "descricao": "Entrega da combinado referente a obra",
                    "slug": "entrega-da-combinado-referente-a-obra",
                    "status": false,
                    "statusContratado": false,
                    "statusContratante": false,
                }
            ],
            "etapas_pagamento": [
                {
                    "status_pag": false,
                    "data_pag": "2023-02-13T10:44",
                    "valor_pag": "10000.00",
                    "descricao_pag": "Assinatura do Contrato",
                    "meio_pag": null
                },
                {
                    "meio_pag": null,
                    "valor_pag": "3500.00",
                    "status_pag": false,
                    "data_pag": "2023-02-24T18:00",
                    "descricao_pag": "Pagamento Semana de 20 à 24 de Fevereiro de 2023"
                },
                {
                    "status_pag": false,
                    "meio_pag": null,
                    "data_pag": "2023-03-03T18:00",
                    "valor_pag": "3500.00",
                    "descricao_pag": "Pagamento Semana de 27 de Fevereiro à 03 de Março de 2023"
                },
                {
                    "valor_pag": "3500.00",
                    "status_pag": false,
                    "data_pag": "2023-03-10T18:00",
                    "meio_pag": null,
                    "descricao_pag": "Pagamento Semana de 06 à 10 de Março de 2023  "
                },
                {
                    "data_pag": "2023-03-17T18:00",
                    "descricao_pag": "Pagamento Semana de 13 à 17 de Março de 2023  ",
                    "meio_pag": null,
                    "valor_pag": "3500.00",
                    "status_pag": false
                },
                {
                    "valor_pag": "3500.00",
                    "status_pag": false,
                    "meio_pag": null,
                    "descricao_pag": "Pagamento Semana de 20 à 24 de Março de 2023  ",
                    "data_pag": "2023-03-24T18:00"
                },
                {
                    "data_pag": "2023-03-31T18:00",
                    "descricao_pag": "Pagamento Semana de 27 de Março à 31 de Abril de 2023",
                    "valor_pag": "3500.00",
                    "meio_pag": null,
                    "status_pag": false
                },
                {
                    "status_pag": false,
                    "valor_pag": "3500.00",
                    "descricao_pag": "Pagamento Semana de 03 à 07 de Abril de 2023",
                    "data_pag": "2023-04-07T18:00",
                    "meio_pag": null
                },
                {
                    "status_pag": false,
                    "descricao_pag": "Pagamento Semana de 10 à 14 de Abril de 2023",
                    "data_pag": "2023-04-14T18:00",
                    "valor_pag": "3500.00",
                    "meio_pag": null
                },
                {
                    "descricao_pag": "Pagamento Semana de 17 à 21 de Abril de 2023",
                    "status_pag": false,
                    "valor_pag": "3500.00",
                    "data_pag": "2023-04-21T18:00",
                    "meio_pag": null
                },
                {
                    "valor_pag": "3500.00",
                    "data_pag": "2023-04-28T18:00",
                    "meio_pag": null,
                    "descricao_pag": "Pagamento Semana de 24 à 28 de Abril de 2023",
                    "status_pag": false
                },
                {
                    "data_pag": "2023-05-05T18:00",
                    "valor_pag": "3500.00",
                    "meio_pag": null,
                    "descricao_pag": "Pagamento Semana de 01 à 05 de Maio de 2023",
                    "status_pag": false
                },
                {
                    "meio_pag": null,
                    "valor_pag": "3500.00",
                    "status_pag": false,
                    "descricao_pag": "Pagamento Semana de 08 à 12 de Maio de 2023",
                    "data_pag": "2023-05-12T18:00"
                },
                {
                    "valor_pag": "3500.00",
                    "meio_pag": null,
                    "status_pag": false,
                    "descricao_pag": "Pagamento Semana de 15 à 19 de Maio de 2023",
                    "data_pag": "2023-05-19T18:00"
                },
                {
                    "meio_pag": null,
                    "data_pag": "2023-05-26T18:00",
                    "valor_pag": "3500.00",
                    "status_pag": false,
                    "descricao_pag": "Pagamento Semana de 22 à 26 de Maio de 2023"
                },
                {
                    "descricao_pag": "Pagamento Semana de 29 de Maio à 02 de Junho de 2023",
                    "meio_pag": null,
                    "data_pag": "2023-06-02T18:00",
                    "valor_pag": "3500.00",
                    "status_pag": false
                },
                {
                    "status_pag": false,
                    "descricao_pag": "Pagamento Semana de 05 à 09 de Junho de 2023",
                    "valor_pag": "3500.00",
                    "meio_pag": null,
                    "data_pag": "2023-06-09T10:58"
                },
                {
                    "meio_pag": null,
                    "valor_pag": "4000.00",
                    "data_pag": "2023-06-16T18:00",
                    "descricao_pag": "Entrega da combinado referente a obra",
                    "status_pag": false
                }
            ],
            "etapas_sem_garantia": [
                "Elétrica",
                "Hidráulica",
                "Madeiramento",
                "Pintura",
                "Locação de escoramento",
                "Tábuas para caixaria"
            ],
            "etapas_servico": [
                "Remover telhado",
                "Remover o forro",
                "Remover a laje da varanda do fundo",
                "Remover a laje da garagem",
                "Desmancha algumas paredes especificadas no projeto em anexo",
                "Refazer novas ampliações especificadas no projeto em anexo",
                "Fazer a laje em antigas áreas de forro",
                "Rebocar novas paredes",
                "Construir eitão para futuro recebimento de madeiramento do telhado",
                "Concretar o contra piso onde for necessário passar massa fina para receber o futuro assentamento de piso"
            ],
            "expedicao": {
                "seconds": 1676038162,
                "nanoseconds": 574000000
            },
            "gerenciamento": true,
            "membros": [
                {
                    "cargo": "CEO",
                    "foto": "https://firebasestorage.googleapis.com/v0/b/engeart-6a378.appspot.com/o/PNG%2FICONES%2FperfilPrancheta%201.jpg?alt=media&token=a568bc9e-de3c-459e-b4b3-ab5384e1cf89",
                    "nome": "Douglas Pereira",
                    "representante": true,
                    "tokenRepresentante": "E284603369595RP"
                },
                {
                    "cargo": "CEO",
                    "foto": "https://firebasestorage.googleapis.com/v0/b/engeart-6a378.appspot.com/o/PNG%2FICONES%2FperfilPrancheta%201.jpg?alt=media&token=a568bc9e-de3c-459e-b4b3-ab5384e1cf89",
                    "nome": "Celso Luis Pereira",
                    "representante": true,
                    "tokenRepresentante": "E795941926801RP"
                }
            ],
            "metodo_pagamento": null,
            "nome_conjuge_contratante": "Meire Aparecida Gouveia Jacovaci",
            "numero_representante_contratado": "E795941926801RP",
            "objeto_contratado": "empresarial",
            "observacoes": [],
            "representante_contratado": "Celso Luis Pereira",
            "requisitos_servico": [
                "Coordenar, em conjunto com o cliente, o andamento da oba.",
                "Providenciar o levantamento estimado dos materiais e prazos consoante a execução do serviço de reforma.",
                "Garantir a limpeza e o depósito de lixo conforme os padrões ambientais e legais."
            ],
            "rg_contratante": "",
            "slug_categoria": "servico-particular",
            "status": "aprovado",
            "telefone": "(17) 98159-5027",
            "tipo_execucao": "presencial",
            "tipo_servico": "Prestação de Serviços",
            "titulo_servico": "Reforma Residencial Unifamiliar Térrea",
            "token": "E1676038058229FC",
            "validade_orcamento": "2023-06-20T18:00",
        }

        db.collection("servicos")
            .add(obj)
            .then(() => console.log("Cadastado com sucesso!"));
    }

    // c

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Área do Administrador • Orçamentos" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Gerênciamento de Orçamentos</h1>
                            <div className='wrapper__boxes__budgets w100 d-fx d-fw-wp'>
                                <div className={(props.responsivoSize && props.responsivoSize >= 450) ? 'wrapper__new__budget w50 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' : 'wrapper__new__budget w100 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15'} style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Criar Novo Orçamento</h2>
                                    <p className='fz-14 m-bot-10' style={{ lineHeight: 1.3 }}>
                                        Nulla ea dolore eu elit cillum incididunt non voluptate amet eiusmod consequat voluptate. Excepteur aliquip aliquip proident reprehenderit ut reprehenderit nisi Lorem pariatur.
                                    </p>
                                    <Link to="/minha-conta/administrador/orcamentos/novo" class="button button-full button-primary tx-cen">Criar Orçamento</Link>
                                </div>
                                <div className={(props.responsivoSize && props.responsivoSize >= 450) ? 'wrapper__new__budget w50 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' : 'wrapper__new__budget w100 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15'} style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Negociação de Orçamentos</h2>
                                    <p className='fz-14 m-bot-10' style={{ lineHeight: 1.3 }}>
                                        Nulla ea dolore eu elit cillum incididunt non voluptate amet eiusmod consequat voluptate. Excepteur aliquip aliquip proident reprehenderit ut reprehenderit nisi Lorem pariatur.
                                    </p>
                                    {/* <Link to="/minha-conta/administrador/orcamentos/negociacao" class="button button-full button-primary tx-cen" style={{ backgroundColor: '#dba700' }}>Negociar Orçamentos</Link> */}
                                    <buttom
                                        class="button button-full button-primary tx-cen"
                                        style={{ backgroundColor: '#dba700' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toast.error("Esta função está em desenvolvimento!");
                                        }}
                                    >Negociar Orçamentos</buttom>
                                </div>
                                <div className='wrapper__new__budget w100 d-fx d-fw-wp m-top-10 m-bot-25 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Histórico de Orçamentos</h2>
                                    <div className='box-forms-singup w100'>
                                        {
                                            (orcamentos && orcamentos.length)
                                                ?
                                                <div className='dados-pessoais-usuario'>
                                                    <div className='box-requests w100 m-bot-20'>
                                                        {
                                                            orcamentos.map((dadosContrato, index) => {
                                                                return (
                                                                    <BudgetBox_pendente
                                                                        key={index}
                                                                        dadosContrato={dadosContrato}
                                                                        user={props.user}
                                                                        responsivoSize={props.responsivoSize}
                                                                        setOrcamentoUnico={props.setOrcamentoUnico}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className='d-fx d-al-cen'>
                                                    <div className='w65 p-g-25'>
                                                        <h2 className='m-bot-20'>Hey!</h2>
                                                        <div style={{ lineHeight: 1.5 }}>
                                                            <p className='fz-16'>Não foi possivel localizarmos nenhum contrato para o CPF do usuário logado :(</p>
                                                            <p className='fz-16'>Parece que o login foi realizado com o CPF de alguém que não seja o contratante principal!</p>
                                                            <div className='m-top-15 p-g-10' style={{ backgroundColor: "#f5f5f5", borderRadius: '6px' }}>
                                                                <p className='fz-14 m-bot-10'>O contratante principal já possui uma conta? Clique em sair e crie uma nova conta totalmente gratuita!</p>
                                                                <p className='fz-14'>Você é o contratante principal e está tendo problemas para visualizar seu contrato? Clique <Link to="/ouvidoria">aqui</Link>!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w35'>
                                                        <img src={contractOkay} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                                {/* <div className='w100'>
                                    <FilePond
                                        files={files}
                                        onupdatefiles={setFiles}
                                        allowMultiple={true}
                                        maxFiles={null}
                                        server={{
                                            // fake server to simulate loading a 'local' server file and processing a file
                                            process: (fieldName, file, metadata, load) => {
                                                // simulates uploading a file
                                                fazerUploadArquivo(file, 12345).then(() => {
                                                    load(progresso);
                                                })
                                            },
                                            load: (source, load) => {
                                                // simulates loading a file from the server
                                                fetch(source)
                                                    .then((res) => res.blob())
                                                    .then(load);
                                            },
                                        }}
                                        name="files"
                                        labelIdle='Arraste e solte seus arquivos ou <span class="filepond--label-action">Navegue</span>'
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );

}

export default PC_ADM_Orcamentos;