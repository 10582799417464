import '../Assets/styles/css/Main.css';

// IMPORTAÇÃO DE REACT ROUTER
import { Link } from 'react-router-dom';

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

import FormPromocoes from '../Assets/components/FormPromocoes';
import CardsAtendimento from '../Assets/components/CardsAtendimento';
import InfoLocalizacao from '../Assets/components/InfoLocalizacao';

function Promocoes(props) {

    let page = window.location.pathname.replace("/", "");
    return (
        <div id='promocoes'>
            <Helmet title="Engeart • Promoções" />
            <div className='projeto-single-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item active m-0">Promoções</li>
                                    </ol>
                                </nav>
                            </div>

                            <FormPromocoes formularioImagem={true} responsivoSize={props.responsivoSize} cadastros={props.cadastros} paginaAtual={page} />

                            <CardsAtendimento paginaAtual={page} />

                            <InfoLocalizacao pageImage={false} responsivoSize={props.responsivoSize} />

                        </div>
                    </div>
                </div>{/*center*/}
            </div>
        </div>
    );
}

export default Promocoes;