
import { db, fs, auth } from '../../../../firebase';
import { Link } from 'react-router-dom'; // IMPORTAÇÃO DE REACT ROUTER
import '../Assets/styles/css/Main.css';
import { useEffect, useState, useRef } from 'react';
import Helmet from 'react-helmet'; // IMPORTAÇÃO DE MANIPULADOR DE TITLE







// IMPORTAÇÃO DE IMAGENS
import addCart from '../../../../images/icones/gif/Add to Cart.gif';
import emptyCart from '../../../../images/icones/svg/empty-cart.svg';
import iconSuport from '../../../../images/icones/png/icon-suport.png';
import iconLove from '../../../../images/icones/png/icon-love.png';
import iconAlert from '../../../../images/icones/png/icon-alert.png';
import atendimento from '../../../../images/projetos/jpg/atendimento.jpg';

// IMPORTAÇÃO DE ICONES
import { HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { AiOutlineMail, AiOutlinePhone, AiOutlineClockCircle } from 'react-icons/ai';
import { BsChevronRight, BsCalendarRange } from 'react-icons/bs';
import { BiMapPin } from 'react-icons/bi';






import { AiOutlineMinusCircle, AiFillInfoCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { FaEye, FaHeart, FaShareSquare, FaStar } from 'react-icons/fa';

// IMPORTAÇÃO DE IMAGENS
import whats from '../../../../images/icones/svg/whats.svg';
import slide from '../../../../images/projetos/png/slide04.png';
import promo from '../../../../images/projetos/png/Projetos Prontos.png';
import home from '../../../../images/projetos/png/home1.png';
import promo2 from '../../../../images/projetos/jpg/promo.jpg';
import promoBox from '../../../../images/projetos/jpg/promo_380x400.jpg';

// IMPORTAÇÃO DE CARROSEL DE IMAGENS
// ESTILO - CARROSEL
// import 'react-multi-carousel/lib/styles.css';
// FUNÇÃO - CARROSSEL
// import Carousel from 'react-multi-carousel';
import axios from 'axios';
import Subtotal from './Components/Pagamentos/Subtotal';
import toast from 'react-hot-toast';
import { isEmptyObject } from 'jquery';
import { useMemo } from 'react';
import bloqueioCopia from '../Assets/funcoes/bloqueioCopia';



function Carrinho(props) {

    const toggleDrawer = () => {
        props.setIsOpen((prevState) => !prevState);
    }

    const [data, setData] = useState([]);

    // const carousel = useRef(null);

    const [invalid, setInvalid] = useState(null);

    // const handleLeftClick = (e) => {
    //     e.preventDefault();
    //     carousel.current.scrollLeft -= carousel.current.offsetWidth;

    //     console.log(carousel.current.scrollLeft);
    //     if (carousel.current.scrollLeft == 0) {
    //         console.log(e);
    //     }
    // };

    // const handleRightClick = (e) => {
    //     e.preventDefault();

    //     carousel.current.scrollLeft += carousel.current.offsetWidth;
    // };


    function totalFinal() {
        let amountProduct = 0; // SOMA DE VALORES DOS PRODUTOS
        let amountShipping = 0; // SOMA DE FRETE DOS PRODUTOS
        let amountDiscount = 0; // CALCULO DE DESCONTOS DOS PRODUTOS

        // LOOPING SOMAR VALORES DOS PRODUTOS
        props.cart.map((i) => {
            amountProduct += i.precoOriginal * i.quantidade
        })

        // LOOPING SOMAR FRETE DOS PRODUTOS
        props.cart.map((i) => {
            amountShipping += i.frete
        })

        // LOOPING SOMAR DESCONTO DOS PRODUTOS
        props.cart.map((i) => {
            amountDiscount += (i.preco - i.precoOriginal) * i.quantidade;
        })

        return ((amountProduct + amountShipping) + amountDiscount);
    }
    function shippingCost(tipo) {
        let amountShipping = 0
        props.cart.map((i) => {
            if (tipo == 'online') {
                amountShipping += i.frete.online.preco
            } else if (tipo == 'agendada') {
                amountShipping += i.frete.agendada.preco
            }
        })

        return amountShipping;
    }

    function totalDescontos() {
        let amountDiscount = 0; // CALCULO DE DESCONTOS DOS PRODUTOS

        // LOOPING SOMAR DESCONTO DOS PRODUTOS
        props.cart.map((i) => {
            amountDiscount += (i.preco - i.precoOriginal) * i.quantidade;
        })

        return amountDiscount;
    }
    function subTotal() {
        let amountProduct = 0
        props.cart.map((i) => {
            amountProduct += i.precoOriginal * i.quantidade
        })
        return amountProduct;
    }


    function increase(el, item) {
        if (item.quantidade > 0) {
            document.getElementById('btn_quantity_less_' + item.id).removeAttribute('disabled');
        }
        auth.onAuthStateChanged((val) => {
            db.collection('usuarios').doc(val.uid).collection('carrinho').doc(`${item.id}`).update("quantidade", fs.firestore.FieldValue.increment(1))
                .then(async () => {
                    try {
                        const ref = db.collection('usuarios').doc(val.uid).collection('carrinho');
                        const snapshot = await ref.get();
                        if (snapshot.empty) {
                            toast.error("Seu carrinho está vázio!");
                            return;
                        }
                        var arr = ([]);
                        snapshot.forEach(doc => {
                            arr.push(doc.data());
                        });
                        props.setCart(arr);
                    } catch (error) {
                        toast.error("Ocorreu um erro ao recuperar seus dados!");
                        console.log(error);
                    }
                })
        })
    }

    function decrease(el, item) {
        if (item.quantidade <= 1) {
            // el.currentTarget.setAttribute('disabled','disabled');
            document.getElementById('btn_quantity_less_' + item.id).setAttribute('disabled', 'disabled');
        } else {
            auth.onAuthStateChanged((val) => {
                db.collection('usuarios').doc(val.uid).collection('carrinho').doc(`${item.id}`).update("quantidade", fs.firestore.FieldValue.increment(-1))
                    .then(async () => {
                        try {
                            const ref = db.collection('usuarios').doc(val.uid).collection('carrinho');
                            const snapshot = await ref.get();
                            if (snapshot.empty) {
                                toast.error("Seu carrinho está vázio!");
                                return;
                            }
                            var arr = ([]);
                            snapshot.forEach(doc => {
                                arr.push(doc.data());
                            });
                            props.setCart(arr);
                        } catch (error) {
                            toast.error("Ocorreu um erro ao recuperar seus dados!");
                            console.log(error);
                        }
                    })
            })
            // auth.onAuthStateChanged((val) => {
            //     db.collection('usuarios').doc(val.uid).collection('carrinho').doc(`${item.id}`).update("quantidade", fs.firestore.FieldValue.increment(-1)).then(() => {
            //         db.collection('usuarios').doc(val.id).collection('carrinho').onSnapshot((data) => {
            //             var contentCart = new Array();
            //             data.docs.map(result => {
            //                 contentCart.push(result.data());
            //             })
            //             props.setCart(contentCart);
            //         })
            //     })
            // })
        }
    }

    function removetocart(item) {

        props.dadosProjeto.map((i) => {
            if (i.id == item.id) {
                i.cart = false
            }
        })


        if (props.user && !isEmptyObject(props.user)) {
            auth.onAuthStateChanged((val) => {
                db.collection('usuarios')
                    .doc(val.uid)
                    .collection('carrinho')
                    .doc(`${item.id}`)
                    .delete()
                    .then(async () => {
                        try {
                            const ref = db.collection('usuarios').doc(val.uid).collection('carrinho');
                            const snapshot = await ref.get();
                            if (snapshot.empty) {
                                toast.error("Não existe produtos no carrinho!");
                                return;
                            }
                            var arr = ([]);
                            snapshot.forEach(doc => {
                                arr.push(doc.data());
                            });
                            props.setCart(arr);
                        } catch (error) {
                            toast.error("Ocorreu um erro ao recuperar seus dados!");
                            console.log(error);
                        }
                    });
            })
        }

    }

    return (
        <div id='carrinho' className={(props.responsivoSize && (props.responsivoSize < 1200 && props.responsivoSize > 768)) ? 'p-lef-10 p-rig-10 m-bot-20' : 'm-bot-20'}>
            <Helmet title="Engeart • Meu Carrinho • Checkout" />
            <div className='projeto-single-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item active m-0">Carrinho</li>
                                    </ol>
                                </nav>
                            </div>
                            <section className={(props.responsivoSize && props.responsivoSize > 300) ? 'content-title p-g-20 m-top-10 d-fx d-al-cen d-ju-sp-bt' : 'content-title p-g-20 m-top-10 d-fx d-al-cen d-ju-sp-bt d-fd-col'} style={(props.responsivoSize && props.responsivoSize < 768) ? { margin: '0 1rem' } : null}>
                                <div className='content-principal left'>
                                    <h1 className={(props.responsivoSize && props.responsivoSize > 300) ? 'fz-30 m-bot-10' : 'fz-20 m-bot-10'} style={{ color: '#000' }}>Meu Carrinho</h1>
                                    {
                                        (props.responsivoSize && props.responsivoSize > 300)
                                            ?
                                            <p className='fz-16'>Sua localização é Monte Aprazível - SP, você pode retirar seus produto nas Lojas da região. <a href='#'>Política de entrega</a>.</p>
                                            : null
                                    }
                                </div>
                                <div className='content-secundario right'>
                                    <Link to="/projetos" className='d-fx d-al-cen fz-13 btn-minimalista'>Comprar mais projetos <BsChevronRight /></Link>
                                </div>
                                <div className='clear'></div>
                            </section>
                            {
                                (props.cart && props.cart.length > 0) ?
                                    <section id="cart_shopping" className={(props.responsivoSize && props.responsivoSize > 768) ? 'd-fx m-top-20' : 'd-fx d-fw-wp'} style={(props.responsivoSize && props.responsivoSize < 768) ? { padding: '1.563rem 1rem 1.563rem 1rem' } : null}>
                                        <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'content-title p-g-20 w70' : 'content-title p-g-20 w100'}>

                                            <h2 className={(props.responsivoSize && props.responsivoSize > 300) ? 'fz-30 m-bot-20 tx-black' : 'fz-15 m-bot-20 tx-black'}>
                                                Engregamos para você
                                            </h2>
                                            <div className='content_deadline w100 m-bot-15'>
                                                <span className='w100 d-bk fz-13 tx-black'>
                                                    Receba no prazo de: <strong>1 a 3 dias úteis</strong> por <strong>{shippingCost("online").toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                </span>
                                                <span className='w100 d-bk fz-13 tx-black'>
                                                    Agende para receber por <strong>{shippingCost("agendada").toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                </span>
                                            </div>

                                            <div className='wrapper__products__cart'>
                                                {props.cart.map((item) => {
                                                    return (
                                                        <div className='box__single__product b-bot'>
                                                            <div className={(props.responsivoSize && props.responsivoSize > 450) ? 'content__single__product d-fx w100 p-rlt' : 'content__single__product d-fx d-fw-wp w100 p-rlt'}>
                                                                <div className={(props.responsivoSize && props.responsivoSize > 450) ? 'image__product w20 m-rig-20 m-lef-0' : 'image__product w100'}>
                                                                    <Link to={'/projetos/' + item.orderId} className='w100'>
                                                                        <img className='w100' src={item.capa} onDragStart={e=> {e.preventDefault()}}  onContextMenu={(e) => bloqueioCopia(e, props.setOpenModal)} />
                                                                    </Link>
                                                                </div>
                                                                <div className={(props.responsivoSize && props.responsivoSize > 450) ? 'infos_product w80' : 'infos_product w100'}>
                                                                    <Link to={'/projetos/' + item.orderId} className={(props.responsivoSize && props.responsivoSize > 450) ? 'title__name__product w100 d-fx d-fw-wp fz-18' : 'title__name__product w100 d-fx d-fw-wp fz-15'}>
                                                                        <span>{item.titulo}</span>
                                                                        <span className='m-lef-5 m-rig-5'>•</span>
                                                                        <span>Projeto Arquitetônico</span>
                                                                    </Link>
                                                                    <span className='seller__product w100 d-bk fz-12'>
                                                                        Vendido e entregue por <strong>Engeart</strong>
                                                                    </span>
                                                                    <div className={(props.responsivoSize && props.responsivoSize > 450) ? 'w100 m-top-5 d-fx' : 'w100 m-top-5 d-fx d-fw-wp'}>
                                                                        <div class="css-5h5qqj-quantity e2ntp2t1">
                                                                            <button id={'btn_quantity_less_' + item.id} data-cy="quantity-minus-action" class="css-17sn2ka e2ntp2t0" onClick={(el) => decrease(el, item)}>
                                                                                <AiOutlineMinusCircle />
                                                                            </button>
                                                                            {item.quantidade}
                                                                            <button data-cy="quantity-plus-action" class="css-17sn2ka e2ntp2t0" onClick={(el) => increase(el, item)}>
                                                                                <AiOutlinePlusCircle />
                                                                            </button>
                                                                        </div>
                                                                        {
                                                                            (item.precoTabela && item.desconto)
                                                                                ?
                                                                                <div className={(props.responsivoSize && props.responsivoSize > 450) ? 'prices__products d-fx d-fw-wp w30' : 'prices__products d-fx d-fw-wp w100 m-top-5'}>
                                                                                    <span className='fz-12 d-bk w100' style={{ textDecoration: 'line-through' }}>
                                                                                        1 un {item.precoTabela.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                                    </span>
                                                                                    <span className='fz-14 d-bk w100'>
                                                                                        1 un {(item.precoTabela - ((item.precoTabela / 100) * item.desconto)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                                    </span>
                                                                                    <span className='fz-15 tx-bold tx-black d-bk w100'>
                                                                                        {item.quantidade} un {(item.quantidade * (item.precoTabela - ((item.precoTabela / 100) * item.desconto))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                                    </span>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (props.responsivoSize && props.responsivoSize > 450)
                                                                        ?
                                                                        <button className="btn__remove__product__cart p-abs" onClick={() => removetocart(item)}>
                                                                            <AiOutlinePlusCircle />
                                                                        </button>
                                                                        :
                                                                        <button className="button button-full button-primary tx-cen" onClick={() => removetocart(item)} style={{backgroundColor: '#ff0000'}}>
                                                                            Remover
                                                                        </button>
                                                                }

                                                                {
                                                                    (props.responsivoSize && props.responsivoSize > 450)
                                                                        ?
                                                                        <div className='p-abs bookmarks_infos d-fx d-al-cen' style={{ right: 0, bottom: 0 }}>
                                                                            <span className='fz-11' style={{ background: 'rgba(225,225,225,.5)', padding: '3px 10px 3px 15px', borderRadius: '20px' }}>Cód.: E{item.orderId}A</span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'w30' : 'w100 m-top-20'} style={(props.responsivoSize && props.responsivoSize > 768) ? { marginLeft: '20px' } : null}>
                                            <Subtotal cart={props.cart} responsivoSize={props.responsivoSize} />
                                            <Link to="/pedido/entrega" class="button button-full button-primary tx-cen">Continuar para pagamento</Link>
                                        </div>
                                    </section>
                                    :
                                    <section id="empty_cart_shopping" className='content-title p-g-20 m-top-10' style={{ textAlign: 'center' }}>
                                        <h2 className='fz-25 m-bot-20' style={{ color: '#000' }}>Você não possui itens adicionados ao seu carrinho.</h2>
                                        <div className='w100'>
                                            <img src={emptyCart} />
                                        </div>
                                    </section>
                            }
                        </div>
                    </div>
                </div>{/*center*/}
            </div>
        </div>
    );
}

export default Carrinho;