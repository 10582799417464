
import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { BsLink45Deg } from 'react-icons/bs';
import { MdOutlineMoreHoriz } from 'react-icons/md';
import { copiarLinkCompartilhamento } from '../funcoes/FuncoesCompartilhamento';
import { fecharModal } from '../funcoes/FuncoesGerais';

function ModalCompartilhamento(props) {

    function fechaModalCompartilhamento(el) {
        el.preventDefault();
        
        fecharModal();
        setTimeout(() => {props.setOpenModal(null)}, 1000);
    }

    return (
        <div class="popup" style={{ zIndex: 9999, position: 'fixed'}}>
            <div className='header-popup'>
                <span>Compartilhar projeto</span>
                <a class="close-popup" onClick={(el) => fechaModalCompartilhamento(el)}><AiOutlineClose /></a>
            </div>
            <div class="content">
                <p>Compartilhar este projeto via:</p>
                <ul class="icons">
                    <a href="#"><FaFacebookF /></a>
                    <a href="#"><FaTwitter /></a>
                    <a href="#"><FaInstagram /></a>
                    <a href="#"><FaWhatsapp /></a>
                    <a href="#"><MdOutlineMoreHoriz /></a>
                </ul>
                <p>Ou copiar o link</p>
                <div class="field">
                    <BsLink45Deg className="url-icon" />
                    <input className='share__link__input' type="text" name='share_link' readonly />
                    <button onClick={(el)=>{copiarLinkCompartilhamento(el)}}>Copiar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalCompartilhamento;