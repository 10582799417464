import { useState } from 'react';
import { motion } from 'framer-motion';

function FAQSingle(props) {
    const [expanded, setExpanded] = useState(false);
    return (            
        <motion.div
            style={{ transformOrigin: "top", overflow: "hidden" }}
            animate={{ height: expanded ? "auto" : '60px' }}
            className="accordion-item"
            onClick={() => setExpanded(!expanded)}
        >
            <button id={`accordion-button-${props.pergunta.orderId}`} aria-expanded={expanded}>
                <span class="accordion-title">{props.pergunta.pergunta}</span>
                <span class="icon" aria-hidden={expanded}></span>
            </button>
            <div class="accordion-content">
                <p>{props.pergunta.resposta}</p>
            </div>
        </motion.div>
    )
}

export default FAQSingle;