import { useEffect, useState, useMemo } from 'react';
import { db } from '../../../../../firebase';
import { Link } from 'react-router-dom';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from './Arrows';
import { BannerLazyload, WidgetLazyload } from './Lazyload';
import toast from 'react-hot-toast';

function BannersOfertas(props) {

    const [ofertasBanners, setOfertasBanners] = useState([]); // CUPONS DE OFERTAS
    const [lazyload, setLazyload] = useState(true); // LAZYLOAD

    useEffect(() => {
        // PEGAR DADOS DO SLIDE E INSERIR NA SESSÃO
        if (!sessionStorage.getItem("banners_ofertas")) {
            if (!ofertasBanners || !ofertasBanners.length) {
                (async () => {
                    try {
                        const cuponsRef = db.collection("marketing").doc("ofertas").collection("banners").where("status", "==", true);
                        const snapshot = await cuponsRef.get();
                        if (snapshot.empty) {
                            toast.error("Não existe cupom de oferta disponível no momento!");
                            return;
                        }
                        var arrCupons = ([]);
                        snapshot.forEach(doc => {
                            arrCupons.unshift(doc.data());
                        });

                        sessionStorage.setItem('banners_ofertas',JSON.stringify(arrCupons));
                        setOfertasBanners(arrCupons);
                    } catch (error) {
                        toast.error("Ocorreu um erro ao recuperar os slides de ofertas!");
                        console.log(error);
                    }
                })()
            }
        } else {
            // RECUPERAR DADOS SALVOS NA SESSÃO
            let dadosCupons = JSON.parse(sessionStorage.getItem('banners_ofertas'));
            setOfertasBanners(dadosCupons);
        }
    }, [])

    useMemo(() => {
        if (ofertasBanners && ofertasBanners.length) {
            setLazyload(false);
        }
    }, [ofertasBanners]);

    let rows = [];
    for (let i = 1; i <= 6; i++) {
        rows.push(<BannerLazyload />)
    }

    return (
        <section className="promos-departments container__content p-rlt z-less">
            <div className="center">
                <div className='promos-content d-fx d-ju-cen'>
                    {
                        (!lazyload)
                            ?
                                (ofertasBanners && ofertasBanners.length > 0)
                                    ?
                                    ofertasBanners.map((dadosBanners) => {
                                        return (
                                            <Link to={dadosBanners.link} className='promo-single d-fx'>
                                                <img className='w95' src={dadosBanners.capa} alt={dadosBanners.titulo} title={dadosBanners.titulo} />
                                            </Link>
                                        )
                                    })
                                    : null
                            :
                            rows
                }
                </div>{/*promos-content*/}
            </div>{/* center */}
        </section>
    )
}

export default BannersOfertas;