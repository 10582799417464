import './Assets/styles/css/Main.css';
import './Assets/styles/css/MainResponsivo.css';
// importação react router
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import ScrollToTop from './Pages/Components/ScrollToTop';

// IMPORTAÇÃO DE PÁGINAS
import Home from './Pages/Home'; // PÁGINA INICIAL - ANDAMENTO

import Projetos from './Pages/Projetos'; // PÁGINA TODOS PROJETOS - ANDAMENTO
import ProjetosProntos from './Pages/ProjetosProntos'; // PÁGINA TODOS PROJETOS - ANDAMENTO
import ProjetoSingle from './Pages/ProjetoSingle'; // PÁGINA PROJETO SINGLE- ANDAMENTO
import ProjetoPersonalizado from './Pages/ProjetoPersonalizado'; // PÁGINA PROJETO PERSONALIZADO- ANDAMENTO

import Contato from './Pages/Contato'; // PÁGINA CONTATO - ANDAMENTO
import Vendas from './Pages/Vendas'; // PÁGINA Vendas - ANDAMENTO
import Ouvidoria from './Pages/Ouvidoria';
import Promocoes from './Pages/Promocoes';

import Carrinho from './Pages/Carrinho'; // PÁGINA CARRINHO - ANDAMENTO
import Entrega from './Pages/Entrega'; // PÁGINA ENTREGA - ANDAMENTO
import Pagamento from './Pages/Pagamento'; // PÁGINA PAGAMENTO - ANDAMENTO
import PagamentoSucesso from './Pages/PagamentoSucesso'; // PÁGINA PAGAMENTO BEM SUCEDIDO - ANDAMENTO

import Ofertas from './Pages/Ofertas'; // PÁGINA DECORAÇÃO - ANDAMENTO

// import Favoritos from './Pages/Favoritos'; // PÁGINA PORTIFÓLIO - ANDAMENTO
// import FavoritoSingle from './Pages/FavoritoSingle'; // PÁGINA PORTIFÓLIO - ANDAMENTO

import Login from './Pages/Login'; // PÁGINA DE LOGIN - ANDAMENTO
import SingUp from './Pages/SingUp'; // PÁGINA DE CADASTRO - ANDAMENTO

import NoMatch from './Pages/NoMatch'; // PÁGINA 404 - ANDAMENTO
import ComingSoon from './Pages/ComingSoon'; // PÁGINA EM BREVE - ANDAMENTO

import PC_Main from './Painel/Cliente/PC-Main';
import MyAccount from './Painel/Cliente/Pages/PC-MyData'; // PÁGINA DE INFORMAÇÕES PESSOAIS DO USUÁRIOS - ANDAMENTO
import MyRequests from './Painel/Cliente/Pages/PC-MyRequests'; // PÁGINA DE PEDIDOS DO USUÁRIO - ANDAMENTO
// import MyFaithfulness from './Painel/Cliente/Pages/PC-MyFaithfulness'; // PÁGINA DE PEDIDOS DO USUÁRIO - ANDAMENTO
// import MyBenefits from './Painel/Cliente/Pages/PC-MyBenefits'; // PÁGINA DE PEDIDOS DO USUÁRIO - ANDAMENTO
// import MyExchanges from './Painel/Cliente/Pages/PC-MyExchanges'; // PÁGINA DE PEDIDOS DO USUÁRIO - ANDAMENTO
// import MyPoints from './Painel/Cliente/Pages/PC-MyPoints'; // PÁGINA DE PEDIDOS DO USUÁRIO - ANDAMENTO
// import MyQuestions from './Painel/Cliente/Pages/PC-MyQuestions'; // PÁGINA DE PEDIDOS DO USUÁRIO - ANDAMENTO
// import PC_Sidebar from './Painel/Cliente/Pages/Templates/Sidebar/PC-Sidebar';

// import Busca from './Pages/Busca';
import BancoPHP from './Pages/BancoPHP';
// import MyForgotPassword from './Painel/Cliente/Pages/PC-MyForgotPassword';
import { isEmptyObject } from 'jquery';
import Forgot from './Pages/Forgot';
import Reset from './Pages/Reset';
import Convite from './Pages/Convite';
import MyContract from './Painel/Cliente/Pages/PC-MyContract';
import MyContractSingle from './Painel/Cliente/Pages/PC-MyContractSingle';
import MyBudget from './Painel/Cliente/Pages/PC-MyBudget';
import MyBudgetSingle from './Painel/Cliente/Pages/PC-MyBudgetSingle';
import MyAdmin from './Painel/Cliente/Pages/PC-MyAdmin';
import PC_ADM_Orcamentos from './Painel/Cliente/Pages/Templates/Adminstrador/PC-ADM-Orcamentos';
import PC_ADM_CriarOrcamento from './Painel/Cliente/Pages/Templates/Adminstrador/PC-ADM-CriarOrcamento';
import PC_ADM_NegociarOrcamentos from './Painel/Cliente/Pages/Templates/Adminstrador/PC-ADM-NegociarOrcamentos';
import MyFollowUp from './Painel/Cliente/Pages/PC-MyFollowUp';
import FollowUpObras from './Painel/Cliente/Pages/Assets/Components/FollowUp_obra';
import PC_FollowUpObras from './Painel/Cliente/Pages/PC-FollowUpObras';
import FollowUpObra from './Painel/Cliente/Pages/Assets/Components/FollowUp_obra';
import PC_ADM_CriarUsuario from './Painel/Cliente/Pages/Templates/Adminstrador/PC-ADM-CriarUsuario';
import PC_ADM_Usuarios from './Painel/Cliente/Pages/Templates/Adminstrador/PC-ADM-Usuarios';
import PC_ADM_Painel from './Painel/Cliente/Pages/Templates/Adminstrador/PC-ADM-Painel';
import PC_ADM_Projetos from './Painel/Cliente/Pages/Templates/Adminstrador/PC-ADM-Projetos';

function Main(props) {
    // HOOKS - CARRINHO - ENTREGA
    // DADOS DO COMPRADOR
    // TELEFONE DO COMPRADOR
    return (
        <div id='main'>
            <ScrollToTop />
            <Routes>
                {/* BANCO PHP */}
                {/* <Route path='/banco' element={<BancoPHP />} /> */}

                {/* INICIO - SITE */}
                <Route path='/' element={<Home user={props.user} setOpenLike={props.setOpenLike} backgroundSlide={props.backgroundSlide} setBackgroundSlide={props.setBackgroundSlide} location={props.location} setItemFavorito={props.setItemFavorito} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} listasFavoritos={props.listasFavoritos} setListasFavoritos={props.setListasFavoritos} favorito={props.favorito} setFavorito={props.setFavorito} openModal={props.openModal} setOpenModal={props.setOpenModal} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} setItensAvaliados={props.setItensAvaliados} projetos={props.projetos} />} />

                {/* PROJETOS - SITE */}
                {/* PROJETOS - GERAL - PROJETOS */}
                <Route path='/projetos' element={<Projetos user={props.user} setOpenLike={props.setOpenLike} setOpenModal={props.setOpenModal} location={props.location} setItemFavorito={props.setItemFavorito} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} listasFavoritos={props.listasFavoritos} favorito={props.favorito} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} setItensAvaliados={props.setItensAvaliados} responsivoSize={props.responsivoSize} projetos={props.projetos} />} />

                {/* PROJETOS - PRONTOS - PROJETOS */}
                <Route path='/projetos/projetos-prontos' element={<ProjetosProntos setIsOpen={props.setIsOpen} responsivoSize={props.responsivoSize} setOpenModal={props.setOpenModal} setOpenLike={props.setOpenLike} setOpenFiltroResponsivo={props.setOpenFiltroResponsivo} dataFiltro={props.dataFiltro} setDataFiltro={props.setDataFiltro} filtroParam={props.filtroParam} setFiltroParam={props.setFiltroParam} filtroElements={props.filtroElements} setFiltroElements={props.setFiltroElements} projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />} />

                {/* PROJETOS - INDIVIDUAL - PROJETOS */}
                <Route path='/projetos/:id' element={<ProjetoSingle user={props.user} setOpenModal={props.setOpenModal} setOpenLike={props.setOpenLike} location={props.location} setItemFavorito={props.setItemFavorito} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} listasFavoritos={props.listasFavoritos} favorito={props.favorito} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} setItensAvaliados={props.setItensAvaliados} responsivoSize={props.responsivoSize} dadosProjeto={props.dadosProjeto} setDadosProjeto={props.setDadosProjeto} openCart={props.openCart} setOpenCart={props.setOpenCart} cart={props.cart} projetos={props.projetos} setCart={props.setCart} />} />

                {/* PROJETOS - PERSONALIZADO - PROJETOS */}
                <Route path='/projeto-personalizado' element={<ProjetoPersonalizado cadastros={props.cadastros} projetos={props.projetos} responsivoSize={props.responsivoSize} />} />

                {/* PROJETOS - CRIACAO - PROJETOS */}
                <Route path='/criar-projeto' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Crie Sua Casa 3d" />} />


                {/* ATENDIMENTO - SITE */}
                {/* CONTATO - ATENDIMENTO */}
                <Route path='/atendimento' element={<Contato user={props.user} location={props.location} cadastros={props.cadastros} responsivoSize={props.responsivoSize} />} />

                {/* VENDAS - ATENDIMENTO */}
                <Route path='/vendas' element={<Vendas user={props.user} location={props.location} cadastros={props.cadastros} responsivoSize={props.responsivoSize} departamentos={props.departamentos} setDepartamentos={props.setDepartamentos} projetos={props.projetos} />} />

                {/* OUVIDORIA - ATENDIMENTO */}
                <Route path='/ouvidoria' element={<Ouvidoria user={props.user} location={props.location} cadastros={props.cadastros} responsivoSize={props.responsivoSize} />} />

                {/* PROMOÇÕES - ATENDIMENTO */}
                <Route path='/promocoes' element={<Promocoes user={props.user} location={props.location} cadastros={props.cadastros} responsivoSize={props.responsivoSize} />} />


                {/* CARRINHO - SITE */}
                {/* CARRINHO - CHECKOUT - CARRINHO */}
                <Route path='/carrinho' element={<Carrinho user={props.user} setIsOpen={props.setIsOpen} setCart={props.setCart} cart={props.cart} setOpenModal={props.setOpenModal} dadosProjeto={props.dadosProjeto} setDadosProjeto={props.setDadosProjeto} responsivoSize={props.responsivoSize} />} />

                {/* CARRINHO - ENTREGA - CARRINHO */}
                <Route path='/pedido/entrega' element={<Entrega user={props.user} setIsOpen={props.setIsOpen} setCart={props.setCart} cart={props.cart} dadosProjeto={props.dadosProjeto} setDadosProjeto={props.setDadosProjeto} responsivoSize={props.responsivoSize} enderecoSelecionado={props.enderecoSelecionado} setEnderecoComprador={props.setEnderecoComprador} setTelefoneComprador={props.setTelefoneComprador} />} />

                {/* CARRINHO - PAGAMENTO - CARRINHO */}
                <Route path='/pedido/pagamento' element={<Pagamento user={props.user} setIsOpen={props.setIsOpen} setCart={props.setCart} cart={props.cart} dadosProjeto={props.dadosProjeto} setDadosProjeto={props.setDadosProjeto} responsivoSize={props.responsivoSize} enderecoComprador={props.enderecoComprador} telefoneComprador={props.telefoneComprador} />} />

                {/* CARRINHO - SUCESSO || RETORNO - CARRINHO */}
                <Route path='/pedido/pagamento/sucesso/:pedidoId' element={<PagamentoSucesso user={props.user} setCart={props.setCart} cart={props.cart} dadosProjeto={props.dadosProjeto} setDadosProjeto={props.setDadosProjeto} responsivoSize={props.responsivoSize} />} />

                <Route path='/pedido/pagamento?' element={<PagamentoSucesso user={props.user} setCart={props.setCart} cart={props.cart} dadosProjeto={props.dadosProjeto} setDadosProjeto={props.setDadosProjeto} />} />


                {/* DECORAÇÃO - SITE */}
                {/* DECORAÇÃO - GERAL - DECORAÇÃO */}
                <Route path='/decoracao' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Decoração" />} />

                {/* DECORAÇÃO - INDIVIDUAL - DECORAÇÃO */}
                {/* <Route path='/decoracao/:id' element={<DecoracaoSingle />} /> */}

                {/* DECORAÇÃO - BRICOLAGEM - DECORAÇÃO */}
                <Route path='/bricolagem' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Faça Você Mesmo" />} />

                {/* DECORAÇÃO - BLOG CONVENCIONAL - DECORAÇÃO */}
                <Route path='/blog' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Blog" />} />

                {/* DECORAÇÃO - BLOG PROFISSIONAL - DECORAÇÃO */}
                <Route path='/blog-profissional' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Blog do Profissional" />} />

                {/* DECORAÇÃO - DICAS - DECORAÇÃO */}
                <Route path='/dicas-decoracao' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Dicas de Decoração" />} />


                {/* OFERTAS - SITE */}
                {/* OFERTAS - GERAL - OFERTAS */}
                <Route path='/ofertas' element={<Ofertas user={props.user} location={props.location} setIsOpen={props.setIsOpen} setOpenLike={props.setOpenLike} projetos={props.projetos} setItemFavorito={props.setItemFavorito} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} listasFavoritos={props.listasFavoritos} setListasFavoritos={props.setListasFavoritos} favorito={props.favorito} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} setItensAvaliados={props.setItensAvaliados} />} />


                {/* SERVIÇOS - SITE */}
                {/* SERVIÇOS - GERAL - SERVIÇOS */}
                <Route path='/servicos' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Serviços" />} />

                {/* OBRAS - SERVIÇOS */}
                <Route path='/obras' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Obras Realizadas" />} />

                {/* SOBRE NÓS - SERVIÇOS */}
                <Route path='/sobre' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Sobre nós" />} />

                {/* PORTIFÓLIO - SERVIÇOS */}
                <Route path='/portifolio' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Portifólio da Empresa" />} />


                {/* FAVORITOS - SITE */}
                {/* FAVORITOS - GERAL - FAVORITOS */}
                {/* <Route path='/favoritos' element={<Favoritos setUser={props.setUser} user={props.user} setOpenLike={props.setOpenLike} openLike={props.openLike} setOpenCart={props.setOpenCart} openCart={props.openCart} setCart={props.setCart} cart={props.cart} dadosProjeto={props.dadosProjeto} setDadosProjeto={props.setDadosProjeto} setItemFavorito={props.setItemFavorito} itemFavorito={props.itemFavorito} setItensFavoritos={props.setItensFavoritos} itensFavoritos={props.itensFavoritos} listasFavoritos={props.listasFavoritos} setListasFavoritos={props.setListasFavoritos} favorito={props.favorito} setFavorito={props.setFavorito} setFavoritoSingle={props.setFavoritoSingle} favoritoSingle={props.favoritoSingle} />} /> */}
                <Route path='/favoritos' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Meus Favoritos" />} />

                {/* FAVORITO - INDIVIDUAL - FAVORITOS */}
                {/* <Route path='/favoritos/:lista' element={<FavoritoSingle setUser={props.setUser} user={props.user} setOpenLike={props.setOpenLike} openLike={props.openLike} setOpenCart={props.setOpenCart} openCart={props.openCart} setCart={props.setCart} cart={props.cart} dadosProjeto={props.dadosProjeto} setDadosProjeto={props.setDadosProjeto} setItemFavorito={props.setItemFavorito} itemFavorito={props.itemFavorito} setItensFavoritos={props.setItensFavoritos} itensFavoritos={props.itensFavoritos} listasFavoritos={props.listasFavoritos} setListasFavoritos={props.setListasFavoritos} favorito={props.favorito} setFavorito={props.setFavorito} setFavoritoSingle={props.setFavoritoSingle} favoritoSingle={props.favoritoSingle} />} /> */}

                {/* BENEFICIOS - SITE */}
                {/* PARCEIROS - SITE */}
                <Route path='/parceiros' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Parceiros Engeart" />} />


                {/* ÁREA DO CLIENTE - PAINEL */}
                {/* LOGIN - PAINEL */}
                <Route path='/login' element={<Login setUser={props.setUser} user={props.user} setOpenCart={props.setOpenCart} responsivoSize={props.responsivoSize} />} />

                {/* CRIAR CONTA - PAINEL */}
                <Route path='/login/sing-up' element={<SingUp user={props.user} setUser={props.setUser} cadastros={props.cadastros} setOpenCart={props.setOpenCart} responsivoSize={props.responsivoSize} />} />

                {/* ESQUECEU A SENHA - PAINEL */}
                <Route path='/login/forgot' element={<Forgot responsivoSize={props.responsivoSize} />} />

                {/* TROCAR A SENHA - PAINEL */}
                <Route path='/login/reset' element={<Reset responsivoSize={props.responsivoSize} />} />


                {/* ÁREA DO CLIENTE - CONVITES - DEPOIMENTO - TESTEMUNHO */}
                <Route
                    path='/convite'
                    element={
                        <Convite
                            user={props.user}
                            responsivoSize={props.responsivoSize}
                            openModal={props.openModal}
                            setOpenModal={props.setOpenModal}
                        />
                    }
                />

                {/* ÁREA DO CLIENTE - CONVITES - DEPOIMENTO - TESTEMUNHO */}
                <Route
                    path='/convite/:tipoConvite/:tokenRepresentante'
                    element={
                        <Convite
                            user={props.user}
                            responsivoSize={props.responsivoSize}
                            openModal={props.openModal}
                            setOpenModal={props.setOpenModal}
                        />
                    }
                />

                {/* MINHA CONTA - PAINEL - CLIENTE */}
                {
                    (props.user && !isEmptyObject(props.user))
                        ?
                        <Route path='/minha-conta' element={<PC_Main user={props.user} responsivoSize={props.responsivoSize} />}>
                            {/* DADOS - MINHA CONTA - PAINEL */}
                            <Route path="meus-dados" element={<MyAccount setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} />} />

                            {/* PEDIDOS - MINHA CONTA - PAINEL */}
                            <Route path="meus-pedidos" element={<MyRequests user={props.user} responsivoSize={props.responsivoSize} />} />

                            {/* PEDIDO - INDIVIDUAL - MINHA CONTA - PAINEL */}
                            <Route path="meus-pedidos/:id" element={<MyRequests setUser={props.setUser} user={props.user} />} />

                            {/* FIDELIDADE - GERAL - MINHA CONTA - PAINEL */}
                            <Route path="fidelidade" element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Programa Fidelidade" />} />

                            {/* DADOS - MEUS CONTRATOS - TODOS */}
                            <Route path="meus-contratos" element={<MyContract setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} setContratoUnico={props.setContratoUnico} />} />

                            {/* DADOS - MEUS CONTRATOS - INDIVIDUAL */}
                            <Route path="meus-contratos/:token" element={<MyContractSingle setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} setContratoUnico={props.setContratoUnico} />} /> 

                            {/* DADOS - MEUS ORÇAMENTOS - TODOS */}
                            <Route path="meus-orcamentos" element={<MyBudget setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} setContratoUnico={props.setContratoUnico} orcamentoUnico={props.orcamentoUnico} setOrcamentoUnico={props.setOrcamentoUnico} />} />

                            {/* DADOS - MEUS ORÇAMENTOS - INDIVIDUAL */}
                            <Route path="meus-orcamentos/:token" element={<MyBudgetSingle setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} />} />

                            {/* DADOS - MEUS ACOMPANHAMENTOS - TODOS */}
                            <Route
                                path="acompanhamento"
                                element={
                                    <MyFollowUp
                                        responsivoSize={props.responsivoSize}
                                        user={props.user}
                                        acompanhamentoServicos={props.acompanhamentoServicos} setAcompanhamentoServicos={props.setAcompanhamentoServicos}
                                        setAcompanhamentoObras={props.setAcompanhamentoObras}
                                        setContratoUnico={props.setContratoUnico}
                                    />
                                }
                            />

                            {/* DADOS - MEUS ACOMPANHAMENTOS - OBRAS - TODOS */}
                            <Route
                                path="acompanhamento/obras"
                                element={
                                    <PC_FollowUpObras
                                        responsivoSize={props.responsivoSize}
                                        user={props.user}
                                        acompanhamentoServicos={props.acompanhamentoServicos} setAcompanhamentoServicos={props.setAcompanhamentoServicos}
                                        setAcompanhamentoObras={props.setAcompanhamentoObras}
                                    />
                                }
                            />

                            {/* DADOS - MEUS ACOMPANHAMENTOS - OBRA - SINGLE */}
                            <Route
                                path="acompanhamento/obras/:obraToken"
                                element={
                                    <FollowUpObra
                                        responsivoSize={props.responsivoSize}
                                        user={props.user}
                                        openModal={props.openModal} setOpenModal={props.setOpenModal}
                                        acompanhamentoObras={props.acompanhamentoObras} setAcompanhamentoObras={props.setAcompanhamentoObras}
                                        setContratoUnico={props.setContratoUnico}
                                    />
                                }
                            />
                            <Route
                                path="acompanhamento/obras/:obraToken/:idPosition"
                                element={
                                    <FollowUpObra
                                        responsivoSize={props.responsivoSize}
                                        user={props.user}
                                        openModal={props.openModal} setOpenModal={props.setOpenModal}
                                        acompanhamentoObras={props.acompanhamentoObras} setAcompanhamentoObras={props.setAcompanhamentoObras}
                                        setContratoUnico={props.setContratoUnico}
                                    />
                                }
                            />

                            {/* DADOS - MEUS ACOMPANHAMENTOS - PROJETOS - TODOS */}
                            <Route path="acompanhamento/projetos" element={<MyBudgetSingle setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} />} />

                            {/* DADOS - MEUS ACOMPANHAMENTOS - DECORAÇÃO - TODOS */}
                            <Route path="acompanhamento/decoracoes" element={<MyBudgetSingle setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} />} />

                            {/* PEDIDOS - MINHA CONTA - PAINEL */}
                            {/* <Route path="recuperar-senha" element={<MyForgotPassword user={props.user} responsivoSize={props.responsivoSize} />} /> */}

                            {/* FIDELIDADE - VANTAGENS - MINHA CONTA - PAINEL */}
                            {/* <Route path="fidelidade/vantagens" element={<MyBenefits setUser={props.setUser} user={props.user} />} /> */}

                            {/* FIDELIDADE - TROCAS - MINHA CONTA - PAINEL */}
                            {/* <Route path="fidelidade/trocas" element={<MyExchanges setUser={props.setUser} user={props.user} />} /> */}

                            {/* FIDELIDADE - PONTOS - MINHA CONTA - PAINEL */}
                            {/* <Route path="fidelidade/pontos" element={<MyPoints setUser={props.setUser} user={props.user} />} /> */}

                            {/* FIDELIDADE - DUVIDAS - MINHA CONTA - PAINEL */}
                            {/* <Route path="fidelidade/duvidas" element={<MyQuestions setUser={props.setUser} user={props.user} />} /> */}
                        </Route>
                        : null
                }

                {/* MINHA CONTA - PAINEL - ADMINISTRADOR */}
                {
                    (props.user && !isEmptyObject(props.user) && props.user.representante)
                        ?
                        <Route path='/minha-conta' element={<PC_Main user={props.user} responsivoSize={props.responsivoSize} />}>
                            {/* DADOS - ADMINISTRADOR/REPRESENTANTE - ORÇAMENTOS - TODOS */}
                            <Route path="administrador" element={<PC_ADM_Painel setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} />} />


                            {/* DADOS - ADMINISTRADOR/REPRESENTANTE - ORÇAMENTOS - TODOS */}
                            <Route path="administrador/orcamentos" element={<PC_ADM_Orcamentos setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} setOrcamentoUnico={props.setOrcamentoUnico} />} />

                            {/* DADOS - ADMINISTRADOR/REPRESENTANTE - ORÇAMENTOS - CRIAR */}
                            <Route path="administrador/orcamentos/novo" element={<PC_ADM_CriarOrcamento setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} setOrcamentoCadastroEnderecoCliente={props.setOrcamentoCadastroEnderecoCliente} orcamentoCadastroEnderecoCliente={props.orcamentoCadastroEnderecoCliente} setBudgetEnderecoServico={props.setBudgetEnderecoServico} budgetEnderecoServico={props.budgetEnderecoServico} />} />

                            {/* DADOS - ADMINISTRADOR/REPRESENTANTE - ORÇAMENTOS - NEGOCIAÇÃO */}
                            <Route path="administrador/orcamentos/negociacao" element={<PC_ADM_NegociarOrcamentos setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} />} />


                            {/* DADOS - ADMINISTRADOR/REPRESENTANTE - USUÁRIOS - TODOS */}
                            <Route path="administrador/usuarios" element={<PC_ADM_Usuarios setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} setOrcamentoUnico={props.setOrcamentoUnico} />} />

                            {/* DADOS - ADMINISTRADOR/REPRESENTANTE - USUÁRIOS - CRIAR */}
                            <Route path="administrador/usuarios/novo" element={<PC_ADM_CriarUsuario setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} setOrcamentoCadastroEnderecoCliente={props.setOrcamentoCadastroEnderecoCliente} orcamentoCadastroEnderecoCliente={props.orcamentoCadastroEnderecoCliente} setBudgetEnderecoServico={props.setBudgetEnderecoServico} budgetEnderecoServico={props.budgetEnderecoServico} />} />

                            {/* DADOS - ADMINISTRADOR/REPRESENTANTE - ORÇAMENTOS - TODOS */}
                            <Route path="administrador/projetos" element={<PC_ADM_Projetos setUser={props.setUser} user={props.user} preferenciasDisciplinas={props.preferenciasDisciplinas} preferenciasProjetos={props.preferenciasProjetos} preferenciasInteriores={props.preferenciasInteriores} responsivoSize={props.responsivoSize} openModal={props.openModal} setOpenModal={props.setOpenModal} contratoUnico={props.contratoUnico} orcamentoUnico={props.orcamentoUnico} setOrcamentoUnico={props.setOrcamentoUnico} />} />
                        </Route>
                        : null
                }
                {/* <Route path='/minha-conta/meus-dados' element={<MyAccount setUser={props.setUser} user={props.user} />} /> */}

                {/* ADICIONAIS - SITE */}

                {/* BUSCAR - ADICIONAIS */}
                {/* <Route path='/busca/:termo' element={<Busca user={props.user} setOpenLike={props.setOpenLike} backgroundSlide={props.backgroundSlide} setBackgroundSlide={props.setBackgroundSlide} location={props.location} setItemFavorito={props.setItemFavorito} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} listasFavoritos={props.listasFavoritos} setListasFavoritos={props.setListasFavoritos} favorito={props.favorito} setFavorito={props.setFavorito} />} /> */}
                <Route path='/busca/:termo' element={<ComingSoon responsivoSize={props.responsivoSize} commingSoon="Busca de produtos e conteúdos" />} />

                {/* 404 - PAGINA NÃO ENCONTRADA - ADICIONAIS */}
                <Route path="*" element={<NoMatch setIsOpen={props.setIsOpen} user={props.user} setOpenLike={props.setOpenLike} projetos={props.projetos} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} setItemFavorito={props.setItemFavorito} listasFavoritos={props.listasFavoritos} favorito={props.favorito} visualizacao={props.visualizacao} favoritados={props.favoritados} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} setItensAvaliados={props.setItensAvaliados} />} />

                {/* BREVE - ADICIONAIS */}
                <Route path="/em-breve/:id" element={<ComingSoon setIsOpen={props.setIsOpen} responsivoSize={props.responsivoSize} />} />

                {/* <Route path='/test/:id' element={<TourTeste />} /> */}

                {/* <Route path='/pagar' element={<Pagarme />} /> */}
                {/* <Route path='/pagar' element={<MercadoPago />} /> */}
            </Routes>
        </div>
    );
}

export default Main;