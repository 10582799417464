import { useEffect, useState, useMemo } from 'react';
import $ from 'jquery';
import { db } from '../../../../../firebase';

import slide from '../../../../../images/projetos/png/slide04.png';
import { Link } from 'react-router-dom';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from './Arrows';
import toast from 'react-hot-toast';

function SlidesOfertasProjetos(props) {

    const [bgSlideHome, setbgSlideHome] = useState([]); // FUNDO DO SLIDE
    const [ofertasSlides, setOfertasSlides] = useState([]); // IMAGENS DO SLIDE
    const [lazyload, setLazyload] = useState(true); // LAZYLOAD

    useEffect(() => {
        // PEGAR DADOS DO SLIDE E INSERIR NA SESSÃO
        if (!sessionStorage.getItem("slides_ofertas")) {
            if (!ofertasSlides || !ofertasSlides.length) {
                (async () => {
                    try {
                        const slidesRef = db.collection("marketing").doc("ofertas").collection("slides").where("status", "==", true);
                        const snapshot = await slidesRef.get();
                        if (snapshot.empty) {
                            toast.error("Não existe nenhum oferta disponível no momento!");
                            return;
                        }
                        var arrSlides = ([]);
                        snapshot.forEach(doc => {
                            //   console.log(doc.id, '=>', doc.data());
                            arrSlides.unshift(doc.data());
                        });

                        sessionStorage.setItem('slides_ofertas',JSON.stringify(arrSlides));
                        setOfertasSlides(arrSlides);
                        setbgSlideHome({
                            left: arrSlides[0].fundo_esquerdo,
                            right: arrSlides[0].fundo_direito,
                            rotate: arrSlides[0].fundo_rotacao
                        })
                    } catch (error) {
                        toast.error("Ocorreu um erro ao recuperar os slides de ofertas!");
                        console.log(error);
                    }
                })()
            }
        } else {
            // RECUPERAR DADOS SALVOS NA SESSÃO
            let dadosSlide = JSON.parse(sessionStorage.getItem('slides_ofertas'));
            // console.log(dadosSlide);
            setOfertasSlides(dadosSlide);
            setbgSlideHome({
                left: dadosSlide[0].fundo_esquerdo,
                right: dadosSlide[0].fundo_direito,
                rotate: dadosSlide[0].fundo_rotacao
            })
        }
    }, [])

    useMemo(() => {
        if (ofertasSlides && ofertasSlides.length) {
            setLazyload(false);
        }
    }, [ofertasSlides]);
    return (
        <section id="slide_ofertas_projetos" className="slides-wrapper p-rlt z-less" style={{ background: 'linear-gradient(' + bgSlideHome.rotate + ', ' + bgSlideHome.left + ', ' + bgSlideHome.right + ')' }}>
            {
                (!lazyload)
                    ?
                        <div className="center p-rlt">
                            <div className="content-slides">
                                <Carousel
                                    itemsToShow={1}
                                    initialActiveIndex={0}
                                    enableAutoPlay={false}
                                    autoPlaySpeed={8000}
                                    className="w100"
                                    onChange={(currentItem, pageIndex) =>
                                        setbgSlideHome({
                                            left: currentItem.item.colorLeft,
                                            right: currentItem.item.colorRight,
                                            rotate: currentItem.item.colorRotate
                                        })
                                    }
                                    renderArrow={({ type, onClick, isEdge }) => {
                                        const pointer = type === consts.PREV ? <ArrowLeft /> : <ArrowRight />
                                        return (
                                            <button className='btn__controller__carousel' onClick={onClick} disabled={isEdge} style={{ zIndex: 1 }}>
                                                {pointer}
                                            </button>
                                        )
                                    }}
                                >
                                    {
                                        (ofertasSlides && ofertasSlides.length > 0)
                                            ?
                                            ofertasSlides.map((dataSlides) => {
                                                return (
                                                    <Link someProp="cool2" className='slide_single_link' to={dataSlides.link} colorLeft={dataSlides.fundo_esquerdo} colorRight={dataSlides.fundo_direito} colorRotate={dataSlides.fundo_rotacao} >
                                                        <img className='slide__capa w100' src={dataSlides.capa} alt="Super promoção semana do construtor" style={{height: '100%'}}/>
                                                    </Link>
                                                )
                                            })
                                            : null
                                    }
                                </Carousel>
                            </div>
                        </div>
                    :
                    <div class="slide-lazyload p-rlt w100 bg__lazyload"></div>
            }
        </section>
    )
}

export default SlidesOfertasProjetos;