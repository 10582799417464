import React from 'react';
import DarkModeToggle from "react-dark-mode-toggle"; // DARK MODE
import Avvvatars from 'avvvatars-react' // AVATAR DO USUÁRIO
import './Assets/styles/css/Header.css';
import { useEffect, useState, useMemo } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCartShopping, faHeart, faUser, faMapMarkedAlt, faAngleDown, faHome, faBuilding, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { IoConstructOutline, IoMdLocate } from 'react-icons/io5';
import { RiHomeHeartLine, RiHomeSmile2Line, RiGalleryLine, RiContactsLine, RiHandHeartLine, RiHomeGearLine } from 'react-icons/ri';
import { AiOutlineShoppingCart, AiOutlineHeart, AiOutlineClose, AiOutlineShareAlt, AiOutlineClockCircle } from 'react-icons/ai';
import { BiUserCircle, BiCurrentLocation } from 'react-icons/bi';
import { GiPencilRuler, GiPostOffice } from 'react-icons/gi';
import { BsBookmarkHeart, BsNewspaper, BsChevronDown } from 'react-icons/bs';
import { MdOutlinePriceCheck, MdTranslate, MdOutlineShareLocation } from 'react-icons/md';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { CgPlayListSearch } from 'react-icons/cg';
import { TbLetterCase } from 'react-icons/tb';
import { GoLocation } from 'react-icons/go';

import logo from '../../../images/icones/svg/logo.svg';
import whats from '../../../images/icones/svg/whats.svg';
import userImage from '../../../images/projetos/jpg/douglas.jpg';

// IMPORTAÇÃO DE MODAL
// import { Alert, Confirm } from 'react-st-modal';

import $, { isEmptyObject } from 'jquery';
import toast from 'react-hot-toast';

// IMPORTAÇÃO DE REACT ROUTER
import { Link, useNavigate } from 'react-router-dom';
// import Projetos from '../Main/Pages/Projetos';

// IMPORTAÇÃO FIREBASE
import { auth, db, providerGoogle } from '../../../firebase.js';
import firebase from 'firebase';
import axios from 'axios';


function Header(props) {
    let navigate = useNavigate();

    const [isDarkMode, setIsDarkMode] = useState(() => false);
    const [busca, setBusca] = useState('');
    const [buscaExata, setBuscaExata] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [stateTooltip, setStateTooltip] = useState(false);
    const [search, setSearch] = useState('')
    const [projetos, setProjetos] = useState([]);
    const [projetosTitulos, setProjetosTitulos] = useState([]);

    useEffect(() => {
        db.collection("produtos").doc("projetos").collection("residencial").where("status", "==", true).onSnapshot((infos) => {
            var arr = ([]);
            var titulos = ([]);
            infos.docs.map((dados) => {
                arr.unshift(dados.data());
                titulos.unshift(dados.data().titulo);
            })
            setProjetos(arr);
            setProjetosTitulos(titulos);
        })
    }, [])

    const toggleDrawer = () => {
        props.setOpenMenuResponsivo((prevState) => !prevState);
    }

    // FUNÇÃO ABRIR TOOLTIP DE LOGIN
    function abrirTooltipIdiomas(e) {
        e.preventDefault();

        let tooltip = $('.box__tooltip__lenguages');
        tooltip.fadeToggle();
    }

    // FUNÇÃO ABRIR TOOLTIP DE LOGIN
    /*
    function abrirTooltipLogin(e) {
        // e.preventDefault();

        // let tooltip = $('.my-account-box.login-user');
        // let overlay = $('.my-account-overlay');

        // overlay.fadeToggle();
        // tooltip.fadeToggle();

        let modalPhone = $(".my-account-overlay"); // BG
        let modalContent = $('.my-account-box.login-user'); // CONTENT
        modalPhone.slideDown(500);
        setTimeout(() => {
            modalContent.slideDown();
        }, 500);
    }
    function fecharTooltipLogin() {
        console.log("HEllo")

        // let tooltip = $('.my-account-box.login-user');
        // let overlay = $('.my-account-overlay');

        // overlay.fadeToggle();
        // tooltip.fadeToggle();


        let overlay = $('.my-account-overlay'); // BG
        let tooltip = $('.my-account-box.login-user'); // CONTENT
        if (overlay.css('display') == 'block') {
            tooltip.slideUp(500);
            setTimeout(() => {
                overlay.slideUp();
            }, 500);
        }
    }
    function abrirTooltipOptions(e) {
        e.preventDefault();

        // let tooltip = $('.my-account-box.opts-user');
        // let overlay = $('.my-account-overlay');

        // overlay.fadeToggle();
        // tooltip.fadeToggle();

        let overlay = $(".my-account-overlay"); // BG
        let tooltip = $('.my-account-box.opts-user'); // CONTENT
        overlay.slideDown(500);
        setTimeout(() => {
            tooltip.slideDown();
        }, 500);
    }
    function fecharTooltipOptions() {

        // let tooltip = $('.my-account-box.opts-user');
        // let overlay = $('.my-account-overlay');

        // overlay.fadeToggle();
        // tooltip.fadeToggle();
        let overlay = $(".my-account-overlay"); // BG
        let tooltip = $('.my-account-box.opts-user'); // CONTENT
        if (overlay.css('display') === 'block') {

            console.log(overlay.css('display'))
            tooltip.slideUp(500);
            setTimeout(() => {
                overlay.slideUp();
            }, 500);
        }
    }
    */

    function logar(e) {
        e.preventDefault();

        let email = document.getElementById("email-login-tooltip").value;
        let senha = document.getElementById("senha-login-tooltip").value;

        auth.signInWithEmailAndPassword(email, senha)
            .then((auth) => {
                props.setUser(auth.user.displayName);
            }).catch((err) => {
                alert(err.message);
            })
    }

    function deslogar(e) {
        e.preventDefault();
        auth.signOut().then(function (val) {
            props.setUser(null);
            // window.location.href = "/";
            // fecharTooltipOptions();
            veririficaTooltip(e);
        }).then(() => {
            sessionStorage.removeItem('dados_usuario');
            props.setCart([]);
        })
            .then(() => {
                toast("👋 Até logo");
            })
    }
    /*
    function handleLoginGoogle(e) {
        e.preventDefault();
        auth.signInWithPopup(providerGoogle)
            .then((result) => {
                if (result) {
                    props.setUser(result.user.displayName);
                    fecharTooltipLogin();
                    // console.log(result);
                    // alert("Logado!");
                }
            }).catch((error) => {
                console.log('Erro:' + error);
                alert("Erro na Autenticação \n Verifique o Console");
            })
    }

    function handleLocation() {
        $.ajax({
            url: "https://geolocation-db.com/jsonp",
            jsonpCallback: "callback",
            dataType: "jsonp",
            success: function (location) {
                var estado = location.state.split(' ');
                var estadoFirst = estado[0].substr(0, 1);
                var estadoSecond = estado[1].substr(0, 1);

                props.setLocation({
                    country_code: location.country_code,
                    country: location.country_name,
                    state: estadoFirst + estadoSecond,
                    city: location.city,
                    cep: location.postal,
                    latitude: location.latitude,
                    longitude: location.longitude,
                    ip: location.IPv4
                });
            }
        });
    }

    function filtroBuscaGeral(el) {
        el.preventDefault()

        var inputBusca = document.getElementById("searchbar").value;

        if (inputBusca.length >= 3) {

        }
    }
    */
    /* 
    // FUNCIONANDO PERFEITAMENTE
    const projetosFiltrados = useMemo(()=>{
        
        const lowerBusca = busca.toLowerCase();

        return projetosTitulos.filter((projeto) => projeto.toLowerCase().includes(lowerBusca));

    },[busca])
    */
    const projetosFiltrados = useMemo(() => {
        if (buscaExata) {
            // var resultFilter = projetos.filter((projeto) => projeto.titulo.startsWith(busca)); // SE A BUSCA FOR EXATA COMEÇANDO PELA PRIMEIRA PALAVRA USE ESSE 
            var resultFilter = projetos.filter((projeto) => projeto.titulo.includes(busca)); // SE A BUSCA FOR EXATA COMEÇANDO PELA PRIMEIRA PALAVRA USE ESSE 
        } else {
            const lowerBusca = busca.toLowerCase();

            var resultFilter = projetos.filter((projeto) => projeto.titulo.toLowerCase().includes(lowerBusca));
        }
        return resultFilter;

    }, [busca])

    useEffect(() => {
        (busca.length >= 3)
            ?
            setShowSearch(true)
            :
            setShowSearch(false)
    }, [busca])

    function realizarBusca(e) {
        e.preventDefault();

        navigate(`/busca/${busca}?termo=default&buscaTermo=${busca}&buscaTipo=default`);

        setBusca('');
    }

    // const lowerBusca = search.toLowerCase();
    // var result = frutas.filter((fruta) => {return fruta.nome.toLowerCase().includes(lowerBusca)});
    // console.log(result);

    function emDesenvolvimento(e) {
        e.preventDefault();
        toast('Esta página está em desenvolvimento!', {
            icon: '⏰',
        });
    }

    function veririficaTooltip(e) {
        let overlay = $(".my-account-overlay"); // BG
        if (overlay.css('display') === 'none') { // Abrir Tooltip
            abrirTooltip();
        } else {
            fecharTooltip();
        }
    }

    function abrirTooltip() {
        let overlay = $(".my-account-overlay"); // BG
        let tooltip = $('.my-account-box'); // CONTENT
        let btnMenu = $('.options__menu__user'); // BOTÃO MENU
        if (overlay.css('display') === 'none') {
            overlay.slideDown(500);
            setTimeout(() => {
                tooltip.slideDown(500);
                setTimeout(() => {
                    btnMenu.css("z-index", 20);
                }, 500);
            }, 500);
        }
    }

    function fecharTooltip() {
        let overlay = $(".my-account-overlay"); // BG
        let tooltip = $('.my-account-box'); // CONTENT
        let btnMenu = $('.options__menu__user'); // BOTÃO MENU
        btnMenu.css("z-index", 0);
        setTimeout(() => {
            tooltip.slideUp(500);
            setTimeout(() => {
                overlay.slideUp(500);
            }, 500);
        }, 200);
    }



    useEffect(() => {
        document.addEventListener('keydown', detectKeyDown, true)
    }, [])

    const detectKeyDown = (e) => {
        if (e.key === 'Escape') { // fechar TOOLTIP
            let overlay = $(".my-account-overlay"); // BG
            let tooltip = $('.my-account-box'); // CONTENT
            let btnMenu = $('.options__menu__user'); // BOTÃO MENU
            btnMenu.css("z-index", 0);
            setTimeout(() => {
                tooltip.slideUp(500);
                setTimeout(() => {
                    overlay.slideUp(500);
                }, 500);
            }, 200);
        }
    }
    return (
        // <div id='header' style={{ height: '128px' }}>
        <div id='header' onLoad={(e) => console.log(e)}>
            {
                (!props.responsivo)
                    ?
                    <div className='new-header'>
                        <div className='new-header-wrapper wrapper-content'>
                            <div className='header-logo-wrapper'>
                                <h1 className='header-logo'>
                                    <Link to="/" title="Engeart - Loja de Projetos Prontos, Personalizados, Dicas de Decoração e Design de Interiores" alt="Engeart - Loja de Projetos Prontos, Personalizados, Dicas de Decoração e Design de Interiores">
                                        <img src={logo} alt="Engeart - Loja de Projetos Prontos, Personalizados, Dicas de Decoração e Design de Interiores" />
                                    </Link>
                                </h1>{/*header-logo*/}
                            </div>{/*header-logo-wrapper*/}
                            <div className='header-box-content-wrapper'>
                                <div className='header-topbar'></div>{/*header-topbar*/}
                                <div className='new-header-content-wrapper'>
                                    <div className='box-content-nav'>
                                        <div className='box-top-nav'>
                                            {/* <div className='location-wrapper d-fx'>
                                                <span className='icon-loc'>
                                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                                </span>
                                                <a
                                                    href='javascript:void(0)'
                                                    className='info-secondary'
                                                    title='Busque pela sua localização e veja os modelos mais vendidos para sua região'
                                                    // onClick={(e) => toast.error("Esta função está em desenvolvimento")}
                                                    onClick={(e) => {
                                                        // var apiKey = "61fc9def5655357a14b004e85f6a05b7";
                                                        // fetch('https://ipapi.co/json/')
                                                        //   .then(response => response.json())
                                                        //   .then(data =>
                                                        //     console.log({
                                                        //       location: data,
                                                        //       isLoading: false,
                                                        //     })
                                                        //   )
                                                        //   .catch(error => this.setState({ error, isLoading: false }));

                                                        axios.get('https://ipapi.co/json/').then(resp => console.log(resp.data));
                                                    }}
                                                >
                                                    Onde você está?
                                                </a>
                                            </div> */}
                                            {
                                                // (props.location && !isEmptyObject(props.location.localidade))
                                                (props.location && !isEmptyObject(props.location))
                                                    ?
                                                    <div className='location-wrapper d-fx info-primary'>
                                                        <span className='icon-loc'>
                                                            <svg viewBox="0 0 21 21" fill="currentColor" aria-hidden="true" height="12" width="12">
                                                                <path fill-rule="evenodd" d="M10.0171 0.999515C11.9974 0.905213 13.7892 1.65963 15.1094 2.97986C16.5239 4.3001 17.2783 6.09184 17.3726 7.97789C17.3726 9.01521 17.0897 10.0525 16.6182 10.9956L14.1664 15.5221L11.3373 20.5201C11.1487 20.803 10.7715 20.9916 10.3943 20.9916C10.1114 20.9916 9.73416 20.803 9.54555 20.5201L3.88741 10.807C3.77693 10.586 3.73117 10.4621 3.69325 10.3595C3.66645 10.2869 3.64357 10.225 3.6045 10.1468C3.3216 9.48672 3.22729 8.638 3.22729 7.88358C3.3216 4.11149 6.33927 1.09382 10.0171 0.999515ZM13.7892 4.4887C12.8461 3.54568 11.6202 3.07417 10.3 3.07417H10.1114C7.4709 3.16847 5.39625 5.33742 5.30195 7.97789C5.30195 8.4494 5.39625 9.01521 5.58485 9.48672C5.58485 9.59721 5.61721 9.67533 5.64402 9.74005C5.66298 9.78581 5.67916 9.82487 5.67916 9.86393L10.3 17.9739L12.3746 14.3904L14.7322 10.0525C15.1094 9.39242 15.298 8.73231 15.298 8.07219C15.298 6.65765 14.7322 5.43172 13.7892 4.4887ZM13.4119 7.78928C13.4119 9.48672 11.9974 10.9013 10.2999 10.9013C8.5082 10.9013 7.09367 9.48672 7.09367 7.78928C7.09367 6.09184 8.6025 4.67731 10.2999 4.67731C11.9974 4.67731 13.4119 6.09184 13.4119 7.78928ZM8.88541 7.78928C8.88541 8.5437 9.45123 9.20382 10.2999 9.20382C11.0544 9.20382 11.7145 8.5437 11.7145 7.78928C11.7145 7.03486 11.0544 6.37475 10.2999 6.37475C9.54553 6.37475 8.88541 7.03486 8.88541 7.78928Z" clip-rule="evenodd"></path>
                                                            </svg>
                                                        </span>
                                                        <a title='Busque pela sua localização e veja os modelos mais vendidos para sua região' onClick={(el) => props.setOpenModal({ "status": true, "tipo": "location" })}>
                                                            <span id='city' className='city-loc'>{props.location.city}</span>
                                                            <span className='division-loc'>-</span>
                                                            <span id='state' className='state-loc'>{props.location.state}</span>
                                                        </a>
                                                    </div>
                                                    :
                                                    <div className='location-wrapper d-fx'>
                                                        <span className='icon-loc'>
                                                            {/* <FontAwesomeIcon icon={faMapMarkedAlt} /> */}
                                                        </span>
                                                        <a href='javascript:void(0)' className='info-secondary' title='Busque pela sua localização e veja os modelos mais vendidos para sua região' onClick={(el) => props.setOpenModal({ "status": true, "tipo": "location" })}>
                                                            Onde você está?
                                                        </a>
                                                    </div>
                                            }
                                            <div className="phone-wrapper">
                                                <span className="info-primary">Compre pelo telefone (somente vendas):</span>
                                                <a href='tel:+55 (17) 99711-7422' className="info-secondary"> (17) 99655-5652</a>
                                            </div>

                                            <div className="faq-wrapper">
                                                <span className="info-primary">Dúvidas?</span>
                                                <Link to="/atendimento" className="info-secondary">Clique aqui</Link>
                                            </div>

                                            <div className='p-rlt m-rig-50 d-nn'>
                                                <div className="box__languages p-rlt d-fx d-al-cen p-abs" style={{ cursor: 'pointer' }} title="Selecione seu idioma preferido" onClick={(e) => abrirTooltipIdiomas(e)}>
                                                    <span className='icon-lang'>
                                                        <MdTranslate className='fz-17' />
                                                    </span>
                                                    <span className='m-lef-5 m-rig-5 fz-10'>PT/BR</span>
                                                    <span className='icon-lang'>
                                                        <BsChevronDown className='fz-12' style={{ marginTop: '3px', marginLeft: '3px' }} />
                                                    </span>
                                                </div>

                                                {/* OPÇÕES DE LOGIN */}
                                                <div className='box__tooltip__lenguages p-abs d-nn'>
                                                    <ul>
                                                        <li className='b-bot-small'>
                                                            <a className='d-fx d-ju-sp-ev p-top-5 p-bot-5'>
                                                                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Brazil.svg/275px-Flag_of_Brazil.svg.png' width='32px' />
                                                                <h1 className='fz-13 tx-black d-in-bk'>Português (BR)</h1>
                                                            </a>
                                                        </li>
                                                        <li className='b-bot-small'>
                                                            <a className='d-fx d-ju-sp-ev p-top-5 p-bot-5'>
                                                                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/2000px-Flag_of_Portugal.svg.png' width='32px' />
                                                                <h1 className='fz-13 tx-black d-in-bk'>Português (PT)</h1>
                                                            </a>
                                                        </li>
                                                        <li className='b-bot-small'>
                                                            <a className='d-fx d-ju-sp-ev p-top-5 p-bot-5'>
                                                                <img src='https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg' width='32px' />
                                                                <h1 className='fz-13 tx-black d-in-bk'>Español (ES)</h1>
                                                            </a>
                                                        </li>
                                                        <li className='b-bot-small'>
                                                            <a className='d-fx d-ju-sp-ev p-top-5 p-bot-5'>
                                                                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1235px-Flag_of_the_United_States.svg.png' width='32px' />
                                                                <h1 className='fz-13 tx-black d-in-bk'>English (EUA)</h1>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="box__dark__mode p-rlt d-nn">
                                                <DarkModeToggle
                                                    onChange={setIsDarkMode}
                                                    checked={isDarkMode}
                                                    size={45}
                                                />
                                            </div>
                                        </div>{/* box-top-nav */}
                                        <div className="box-center-nav">
                                            <div className="search-opts-user">
                                                <div className="box-sub-top-nav p-rlt">
                                                    <div className="search-wrapper">
                                                        <form onSubmit={(e) => { realizarBusca(e) }}>
                                                            <div className='w100 p-rlt d-fx d-al-cen'>
                                                                <label className='w100'>
                                                                    <input id="searchbar" className='tx-grey-dark' type="text" name="searchbar" autocomplete="off" value={busca} placeholder="Digite aqui oque deseja buscar" onChange={(ev) => setBusca(ev.target.value)} />
                                                                </label>
                                                                {
                                                                    (busca.length > 0)
                                                                        ?
                                                                        <div className='p-abs d-fx d-al-cen' style={{ right: '5px' }}>
                                                                            <AiOutlineClose
                                                                                className='fz-16 tx-red-dark tx-rig m-rig-5'
                                                                                style={{ cursor: 'pointer' }}
                                                                                title="Limpar busca"
                                                                                onClick={() => { setBusca('') }}
                                                                            />

                                                                            <TbLetterCase
                                                                                className={(buscaExata) ? 'btn__sensitive__case__search fz-18 tx-grey tx-rig active' : 'btn__sensitive__case__search fz-18 tx-grey tx-rig'}
                                                                                style={{ cursor: 'pointer' }}
                                                                                title="Busca exata"
                                                                                onClick={() => { setBuscaExata((prevState) => !prevState) }}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <TbLetterCase
                                                                            className={(buscaExata) ? 'btn__sensitive__case__search fz-18 p-abs tx-grey tx-rig active' : 'btn__sensitive__case__search fz-18 p-abs tx-grey tx-rig'}
                                                                            style={{ right: '5px', cursor: 'pointer' }}
                                                                            title="Busca exata"
                                                                            onClick={() => { setBuscaExata((prevState) => !prevState) }}
                                                                        />
                                                                }
                                                            </div>
                                                            {/* <input id="searchbar" type="text" name="searchbar" autocomplete="off" value={search} placeholder="Digite aqui oque deseja buscar" onChange={(ev)=>setSearch(ev.target.value)}/> */}
                                                            <button type="submit" title={`Buscar por: ${busca}`}>
                                                                <svg role="presentation" class="css-1dinjto-icon-icon--color-currentColor-icon--size-20px e108jq0t0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="16" height="16" fill="currentColor"><path d="M19.54 17.669l-4.923-4.923a7.71 7.71 0 001.85-5.012C16.468 3.472 12.997 0 8.735 0S1 3.472 1 7.734c0 4.262 3.472 7.734 7.734 7.734 1.35 0 2.621-.35 3.732-.96l5.112 5.112c.26.26.59.38.93.38.34 0 .671-.13.931-.38l.1-.1c.51-.5.51-1.33 0-1.851zM1.95 7.744A6.79 6.79 0 018.734.96a6.79 6.79 0 016.783 6.784 6.79 6.79 0 01-6.783 6.783A6.79 6.79 0 011.95 7.744zM18.86 18.859l-.1.1c-.14.14-.37.14-.51 0l-4.973-4.972c.23-.17.46-.36.67-.55l4.913 4.912c.14.13.14.37 0 .51z" fill-rule="nonzero"></path></svg>
                                                            </button>
                                                        </form>
                                                        <div>
                                                            {/* {
                                                                    frutas.map((fruta)=>{
                                                                        return(
                                                                            <p key={fruta}>{fruta}</p>
                                                                        )
                                                                    })
                                                                } */}
                                                        </div>
                                                    </div>{/*search-wrapper*/}
                                                    {
                                                        (showSearch)
                                                            ?
                                                            <div className='result__search p-abs m-top-10 w100 tx-black d-fx d-ju-sp-bt' style={{ background: '#fff', borderRadius: '8px', boxShadow: '0 0 1.25rem 0 rgba(0,0,0,.5)', zIndex: 1 }}>
                                                                <div className='collun__search w50-c p-g-10 m-top-15 m-bot-15'>
                                                                    <div className='termos__busca tx-lef'>
                                                                        <h2 className='tx-18'>Termos da busca</h2>
                                                                        <ul className='d-fx d-fw-wp w100 m-top-15'>
                                                                            <li className='w100 b-bot-medium'>
                                                                                <Link to={`/busca/${busca}?termo=default&buscaTermo=${busca}&buscaTipo=default`} onClick={() => { setBusca('') }}>
                                                                                    <a className='tx-hov-dec-line tx-primary fz-15 tx-bold'>{busca}</a>
                                                                                </Link>
                                                                            </li>
                                                                            {
                                                                                projetosFiltrados.map((dadosProjetos, index) => {
                                                                                    if (dadosProjetos.categoria == 'Projetos Prontos') {
                                                                                        return (
                                                                                            <li key={index} className='w100 b-bot-medium' title={`${busca} • em: Projetos Prontos`}>
                                                                                                <Link to={`/busca/${busca}?termo=Projetos Prontos&buscaTermo=${busca}&buscaTipo=quickTerm`} onClick={() => { setBusca('') }}>
                                                                                                    <a className='tx-hov-dec-line tx-primary fz-15 tx-bold'>{busca}</a>
                                                                                                    <p className='tx-grey fz-13 m-top-5'>em: Projetos Prontos</p>
                                                                                                </Link>
                                                                                            </li>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                            <li className='w100 b-bot-medium'>
                                                                                <Link to={`/busca/${busca}?termo=Projeto Personalizado&buscaTermo=${busca}&buscaTipo=quickTerm`} onClick={() => { setBusca('') }}>
                                                                                    <a className='tx-hov-dec-line tx-primary fz-15 tx-bold'>{busca}</a>
                                                                                    <p className='tx-grey fz-13 m-top-5'>em: Projetos Personalizados</p>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className='termos__busca tx-lef'>
                                                                        <h2 className='tx-18'>Conteúdos relacionados</h2>
                                                                        <ul className='d-fx w100 m-top-15'>
                                                                            <li className='w100 '>
                                                                                <Link to="/" onClick={() => { setBusca('') }}>
                                                                                    <CgPlayListSearch className='tx-primary fz-20' />
                                                                                    <a className='tx-hov-dec-line tx-grey-dark fz-13'>Como montar um home office pequeno</a>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className='collun__search w50-c p-g-10 m-top-15 m-bot-15'>
                                                                    <div className='produtos__busca tx-lef'>
                                                                        <h2 className='tx-18'>Produtos relacionados</h2>
                                                                        <ul className='d-fx d-fw-wp w100 m-top-15'>
                                                                            {
                                                                                (projetosFiltrados.length > 0)
                                                                                    ?
                                                                                    <div>
                                                                                        {
                                                                                            projetosFiltrados.map((dadosProjetos) => {
                                                                                                return (
                                                                                                    <li className='w100 b-bot'>
                                                                                                        <Link to={`/projetos/${dadosProjetos.orderId}`} onClick={() => { setBusca('') }}>
                                                                                                            <div className='d-fx w100'>
                                                                                                                <div className='w30 img__prod m-rig-5'>
                                                                                                                    <img className='w100' src="https://projetos.eengeart.com/Views/Painel/uploads/61fa682bb9c49.jpg" style={{ borderRadius: '.25rem' }} />
                                                                                                                </div>
                                                                                                                <div className='w70 content__prod'>
                                                                                                                    <a className='w100 d-fx d-al-cen tx-lig tx-grey-dark fz-13 tx-hov-dec-line'>
                                                                                                                        {dadosProjetos.titulo}
                                                                                                                    </a>
                                                                                                                    <div className='w100 prices__prod d-fx d-ju-sp-bt m-top-10'>
                                                                                                                        <span className='tx-lef fz-12 tx-grey' style={{ textDecoration: 'line-through' }}>
                                                                                                                            {(dadosProjetos.precoTabela) ? dadosProjetos.precoTabela.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null}
                                                                                                                        </span>

                                                                                                                        <span className='tx-rig fz-14 tx-bold tx-red-dark'>
                                                                                                                            {(dadosProjetos.precoFinal) ? dadosProjetos.precoFinal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                        <li className='w100 b-bot'>
                                                                                            <Link to="/projeto-personalizado" onClick={() => { setBusca('') }}>
                                                                                                <div className='d-fx w100'>
                                                                                                    <div className='w30 img__prod m-rig-5'>
                                                                                                        <img className='w100' src="https://projetos.eengeart.com/Views/Painel/uploads/61fa682bb9c49.jpg" style={{ borderRadius: '.25rem' }} />
                                                                                                    </div>
                                                                                                    <div className='w70 content__prod'>
                                                                                                        <a className='w100 d-fx d-al-cen tx-lig tx-grey-dark fz-13 tx-hov-dec-line'>
                                                                                                            Projeto Personalizado
                                                                                                        </a>
                                                                                                        <div className='w100 prices__prod d-fx d-ju-sp-bt m-top-10 tx-grey'>
                                                                                                            <span className='w100 fz-12 tx-bold'>
                                                                                                                Orçamento <b className='tx-green-dark'>GRÁTIS</b>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Link>
                                                                                        </li>
                                                                                    </div>
                                                                                    :
                                                                                    <li className='w100 b-bot'>
                                                                                        <Link to="/projeto-personalizado" onClick={() => { setBusca('') }}>
                                                                                            <div className='d-fx w100'>
                                                                                                <div className='w30 img__prod m-rig-5'>
                                                                                                    <img className='w100' src="https://projetos.eengeart.com/Views/Painel/uploads/61fa682bb9c49.jpg" style={{ borderRadius: '.25rem' }} />
                                                                                                </div>
                                                                                                <div className='w70 content__prod'>
                                                                                                    <a className='w100 d-fx d-al-cen tx-lig tx-grey-dark fz-13 tx-hov-dec-line'>
                                                                                                        Projeto Personalizado
                                                                                                    </a>
                                                                                                    <div className='w100 prices__prod d-fx d-ju-sp-bt m-top-10 tx-grey'>
                                                                                                        <span className='w100 fz-12 tx-bold'>
                                                                                                            Orçamento <b className='tx-green-dark'>GRÁTIS</b>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </li>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>{/*box-sub-top-nav*/}
                                                <div className="box-opts-sub-top-nav">
                                                    <Link to='/carrinho' className="box-opt-single-wrapper" style={{ color: 'var(--text-color)' }}>
                                                        <div className="icon-primary p-rlt d-fx">
                                                            <AiOutlineShoppingCart className='fz-40' />
                                                            {
                                                                (props.cart && props.cart.length > 0)
                                                                    ?
                                                                    <span className='itens-cart p-abs d-fx d-ju-cen d-al-cen'>
                                                                        {props.cart.length}
                                                                    </span>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div className="title-option-nav">
                                                            Meu <br /> Carrinho
                                                        </div>
                                                    </Link>{/*box-opt-single-wrapper*/}
                                                    <Link to="/favoritos" className="box-opt-single-wrapper" style={{ color: 'var(--text-color)' }} >
                                                        <div className="icon-primary p-rlt d-fx">
                                                            <AiOutlineHeart className='fz-40' />
                                                        </div>
                                                        <div className="title-option-nav">
                                                            Lista de <br /> Favoritos
                                                        </div>
                                                    </Link>{/*box-opt-single-wrapper*/}
                                                    {
                                                        (props.user)
                                                            ?
                                                            // <div className="box-opt-single-wrapper" style={{ zIndex: 20, cursor: 'pointer' }} onClick={(e) => abrirTooltipOptions(e)}>
                                                            <div className="box-opt-single-wrapper options__menu__user" style={{ zIndex: 20, cursor: 'pointer' }} onClick={(e) => veririficaTooltip(e)}>
                                                                <div className="icon-primary">
                                                                    {
                                                                        (props.user.photo) ?
                                                                            <img src={props.user.photo} style={{ maxWidth: '38px', borderRadius: '50%', border: '3px solid #fff' }} />
                                                                            :
                                                                            <Avvvatars value={props.user.full_name} size={40} border={true} borderSize={3} borderColor="#fff" />
                                                                    }
                                                                </div>
                                                                <div className="title-option-nav">
                                                                    {/* <BiUserCircle className='fz-40' /> */}
                                                                    <span>Bem Vindo, <br /><strong className='fz-12 tx-norm' style={{ textTransform: 'uppercase' }}>{props.user.nome}</strong></span>
                                                                </div>
                                                            </div>
                                                            :
                                                            // <div className="box-opt-single-wrapper" style={{ zIndex: 99, cursor: 'pointer' }} onClick={(e) => abrirTooltipLogin(e)}>
                                                            <div className="box-opt-single-wrapper options__menu__user" style={{ zIndex: 99, cursor: 'pointer' }} onClick={(e) => veririficaTooltip(e)}>
                                                                <div className="icon-primary p-rlt d-fx">
                                                                    {/* <FontAwesomeIcon icon={faUser}/> */}
                                                                    <BiUserCircle className='fz-40' />
                                                                </div>
                                                                <div className="title-option-nav">
                                                                    <span className='d-bk'>
                                                                        Entrar
                                                                    </span>
                                                                    <strong className='d-fx'>
                                                                        <span style={{ marginRight: '3px' }}>Minha</span>
                                                                        <span>Conta</span>
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                    }
                                                    <span class="my-account-overlay" style={{ display: 'none' }}></span>

                                                    {/* OPÇÕES DE LOGIN */}
                                                    <div className='my-account-box' style={{ display: 'none' }}>
                                                        {
                                                            (props.user && !isEmptyObject(props.user))
                                                                ?
                                                                <>
                                                                    <ul className='d-fx d-fw-wp b-bot' style={{ maxWidth: '250px' }}>
                                                                        <li className='w100'>
                                                                            <Link to="/minha-conta/meus-dados" className='w100 p-g-10 tx-lef fz-16 left tx-black' onClick={(e) => veririficaTooltip(e)}>Meus Dados</Link>
                                                                        </li>
                                                                        <li className='w100'>
                                                                            <Link to="/minha-conta/meus-pedidos" className='w100 p-g-10 tx-lef fz-16 left tx-black' onClick={(e) => veririficaTooltip(e)}>Meus Pedidos</Link>
                                                                        </li>
                                                                        <li className='w100'>
                                                                            <Link to="/minha-conta/fidelidade" className='w100 p-g-10 tx-lef fz-16 left tx-black' onClick={(e) => veririficaTooltip(e)}>Programa Fidelidade</Link>
                                                                        </li>
                                                                        <li className='w100'>
                                                                            <Link to="/favoritos" className='w100 p-g-10 tx-lef fz-16 left tx-black' onClick={(e) => veririficaTooltip(e)}>Lista de Favoritos</Link>
                                                                        </li>
                                                                        <li className='w100'>
                                                                            <Link to="/criar-projeto" className='w100 p-g-10 tx-lef fz-16 left tx-black' onClick={(e) => veririficaTooltip(e)}>Meus Projetos</Link>
                                                                        </li>
                                                                        <li className='w100'>
                                                                            <Link to="/minha-conta/meus-contratos" className='w100 p-g-10 tx-lef fz-16 left tx-black' onClick={(e) => veririficaTooltip(e)}>Meus Contratos</Link>
                                                                        </li>
                                                                        <li className='w100'>
                                                                            <Link to="/minha-conta/meus-orcamentos" className='w100 p-g-10 tx-lef fz-16 left tx-black' onClick={(e) => veririficaTooltip(e)}>Meus Orçamentos</Link>
                                                                        </li>
                                                                        <li className='w100'>
                                                                            <Link to="/minha-conta/acompanhamento" className='w100 p-g-10 tx-lef fz-16 left tx-black' onClick={(e) => veririficaTooltip(e)}>Meus Acompanhamentos</Link>
                                                                        </li>
                                                                        {
                                                                            (props.user && !isEmptyObject(props.user) && props.user.representante)
                                                                                ?
                                                                                <li className='w100'>
                                                                                    <Link to="/minha-conta/administrador" className='w100 p-g-10 tx-lef fz-16 left tx-black' onClick={(e) => veririficaTooltip(e)}>Área do Admininstrador</Link>
                                                                                </li>
                                                                                : null
                                                                        }
                                                                    </ul>
                                                                    <div className='w100 d-fx d-ju-sp-bt m-bot-10 p-lef-10 p-rig-10'>
                                                                        <span className='tx-lef fz-16' style={{ color: 'var(--grey)' }}>Não é você?</span>
                                                                        <a className='tx-rig fz-16' onClick={(e) => deslogar(e)}>Sair</a>

                                                                    </div>
                                                                </>
                                                                :
                                                                <div>
                                                                    <div class="user-social">
                                                                        <button type="button" class="user-social-button user-social-button-facebook" onClick={(e) => toast.error("Este meio de login está em desenvolvimento")}>Iniciar sessão com o Facebook</button>
                                                                        {/* <button type="button" class="user-social-button user-social-button-google" onClick={()=>logarGoogle()}>Iniciar sessão com o Google</button> */}
                                                                        {/* <button type="button" class="user-social-button user-social-button-google" onClick={(e) => handleLoginGoogle(e)}>Iniciar sessão com o Google</button> */}
                                                                        <button type="button" class="user-social-button user-social-button-google" onClick={(e) => toast.error("Este meio de login está em desenvolvimento")}>Iniciar sessão com o Google</button>
                                                                        <button type="button" class="user-social-button user-social-button-apple" onClick={(e) => toast.error("Este meio de login está em desenvolvimento")}>Iniciar sessão com a Apple</button>
                                                                        <span class="user-social-spacer m-g-0">ou</span>
                                                                    </div>

                                                                    <form className='d-nn' onSubmit={(e) => logar(e)}>
                                                                        <div data-component="capslock-checker" data-tip-element="[data-capslock-tip]">
                                                                            <div class="field m-g-0" data-validation="field">
                                                                                <input type="email" name="login" class="input" id="email-login-tooltip" data-required="" data-validate="required" />
                                                                                <label for="email" class="label"></label>
                                                                                <span class="helper" aria-describedby="email">Ex: nome@email.com ou 999.999.999-99</span>
                                                                            </div>
                                                                            <div class="field" data-validation="field">
                                                                                <input type="password" name="password" class="input" id="senha-login-tooltip" autocomplete="off" data-required="" data-validate="required" />
                                                                                <label for="password" class="label"></label>
                                                                            </div>
                                                                            <div class="col-xs-12">
                                                                                <a href="https://www.leroymerlin.com.br/user/forgot" class="underlined text-normal small">
                                                                                    Esqueceu a senha?
                                                                                </a>
                                                                            </div>
                                                                            <button class="button button-full button-primary margin-top align-left">
                                                                                Entrar
                                                                            </button>
                                                                            <div data-capslock-tip="" class="hidden margin-top notification notification-warning">
                                                                                A tecla capslock está ativa.
                                                                            </div>
                                                                        </div>
                                                                    </form>

                                                                    <div class="user-login">
                                                                        <Link to="/login" type="button" class="user-login-button m-top-20" onClick={(e) => veririficaTooltip(e)}>Entrar</Link>
                                                                        {/* <Link to="/login" type="button" class="user-login-button" onClick={// ()=>fecharTooltipLogin()}>Entrar</Link> */}
                                                                        <span class="user-register user-social-link">Cliente novo? <Link to='/login/sing-up' onClick={(e) => veririficaTooltip(e)}>Cadastre-se</Link></span>
                                                                    </div>
                                                                    <div class="user-options">
                                                                        <Link to="/login" onClick={(e) => veririficaTooltip(e)}>Minha conta</Link>
                                                                        <span class="spacing"></span>
                                                                        <Link to="/login" onClick={(e) => veririficaTooltip(e)}>Meus pedidos</Link>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>

                                                </div>{/*box-opts-sub-top-nav*/}
                                            </div>{/*search-opts-user*/}
                                            <div className="box-menu-nav z-min">
                                                <nav>
                                                    <ul>
                                                        <li className="opt-single-menu" > {/* onMouseEnter={() => setShowDrop1(true)} */}
                                                            <Link to="/projetos">
                                                                Projetos
                                                                {/* <FontAwesomeIcon className='m-lef-5' icon={faAngleDown} /> */}
                                                            </Link>
                                                            <ul className="sub-menu-nav">
                                                                <li>
                                                                    <Link to='/projetos/projetos-prontos' className='w100 d-fx d-al-cen'>
                                                                        <RiHomeSmile2Line className='fz-25' />
                                                                        <span className='p-lef-10'>Projetos Prontos</span>
                                                                    </Link>
                                                                    <ul className='sub-desc-opt'>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/projetos?pavimento=terreo' className='w100 d-fx d-al-cen'>
                                                                                <RiHomeSmile2Line className='fz-25' />
                                                                                <span className='p-lef-10'>Projetos Térreos</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/projetos?pavimento=sobrado' className='w100 d-fx d-al-cen'>
                                                                                <RiHomeSmile2Line className='fz-25' />
                                                                                <span className='p-lef-10'>Projetos Assobradados</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/projetos?tipo=moderno' className='w100 d-fx d-al-cen'>
                                                                                <RiHomeSmile2Line className='fz-25' />
                                                                                <span className='p-lef-10'>Projetos Modernos</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/projetos?tipo=rustico' className='w100 d-fx d-al-cen'>
                                                                                <RiHomeSmile2Line className='fz-25' />
                                                                                <span className='p-lef-10'>Projetos Rústicos</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/projetos?tipo=contemporaneo' className='w100 d-fx d-al-cen'>
                                                                                <RiHomeSmile2Line className='fz-25' />
                                                                                <span className='p-lef-10'>Projetos Contemporâneos</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/projetos?categoria=economico' className='w100 d-fx d-al-cen'>
                                                                                <RiHomeSmile2Line className='fz-25' />
                                                                                <span className='p-lef-10'>Projetos Alto Padrão</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/projetos?categoria=economico' className='w100 d-fx d-al-cen'>
                                                                                <RiHomeSmile2Line className='fz-25' />
                                                                                <span className='p-lef-10'>Projetos Médio Padrão</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/projetos?categoria=economico' className='w100 d-fx d-al-cen'>
                                                                                <RiHomeSmile2Line className='fz-25' />
                                                                                <span className='p-lef-10'>Projetos Econômicos</span>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li>
                                                                    <Link to='/projeto-personalizado' className='w100 d-fx d-al-cen'>
                                                                        <RiHomeGearLine className='fz-25' />
                                                                        <span className='p-lef-10'>Projetos Personalizados</span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    {/* <Link to='/criar-projeto' className='w100 d-fx d-al-cen'>
                                                                        <RiHomeHeartLine className='fz-25' />
                                                                        <span className='p-lef-10'>Crie sua casa 3D</span>
                                                                    </Link> */}
                                                                    <Link to='/criar-projeto' className='w100 d-fx d-al-cen'>
                                                                        <RiHomeHeartLine className='fz-25' />
                                                                        <span className='p-lef-10'>Crie sua casa 3D</span>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>{/*opt-single-menu*/}
                                                        <li className="opt-single-menu">
                                                            <Link to='/ofertas'>
                                                                Ofertas
                                                            </Link>
                                                        </li>{/*opt-single-menu*/}
                                                        <li className="opt-single-menu d-nn">
                                                            {/* <Link to='/ofertas'>
                                                                    Tendências
                                                                </Link> */}
                                                            <Link to='/noticias'>
                                                                Novidades
                                                            </Link>
                                                        </li>{/*opt-single-menu*/}
                                                        <li className="opt-single-menu">
                                                            <Link to="/decoracao">
                                                                Decoração
                                                                {/* <FontAwesomeIcon className='m-lef-5' icon={faAngleDown} /> */}
                                                            </Link>
                                                            <ul className="sub-menu-nav">
                                                                <li>
                                                                    <Link to='/decoracao' className='w100 d-fx d-al-cen'>
                                                                        <GiPencilRuler className='fz-25' />
                                                                        <span className='p-lef-10'>Design de Interiores</span>
                                                                    </Link>
                                                                    <ul className='sub-desc-opt'>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/decoracao' className='w100 d-fx d-al-cen'>
                                                                                <GiPencilRuler className='fz-25' />
                                                                                <span className='p-lef-10'> Alto padrão</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/decoracao' className='w100 d-fx d-al-cen'>
                                                                                <GiPencilRuler className='fz-25' />
                                                                                <span className='p-lef-10'> Médio padrão</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/decoracao' className='w100 d-fx d-al-cen'>
                                                                                <GiPencilRuler className='fz-25' />
                                                                                <span className='p-lef-10'>Padrão econômico</span>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li>
                                                                    <Link to='/dicas-decoracao' className='w100 d-fx d-al-cen'>
                                                                        <BsBookmarkHeart className='fz-25' />
                                                                        <span className='p-lef-10'>Dicas de Decoração</span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/bricolagem' className='w100 d-fx d-al-cen'>
                                                                        <RiHandHeartLine className='fz-25' />
                                                                        <span className='p-lef-10'>Faça você mesmo</span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/blog-profissional' className='w100 d-fx d-al-cen'>
                                                                        <BsNewspaper className='fz-25' />
                                                                        <span className='p-lef-10'>Blog para Profissionais</span>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>{/*opt-single-menu*/}
                                                        <li className="opt-single-menu">
                                                            <Link to='/servicos'>
                                                                Serviços
                                                                {/* <FontAwesomeIcon className='m-lef-5' icon={faAngleDown} /> */}
                                                            </Link>
                                                            <ul className="sub-menu-nav">
                                                                <li>
                                                                    <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                        <IoConstructOutline className='fz-25' />
                                                                        <span className='p-lef-10'>Obras Realizadas</span>
                                                                    </Link>
                                                                    <ul className='sub-desc-opt'>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <IoConstructOutline className='fz-25' />
                                                                                <span className='p-lef-10'> Alto padrão</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <IoConstructOutline className='fz-25' />
                                                                                <span className='p-lef-10'> Médio padrão</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <IoConstructOutline className='fz-25' />
                                                                                <span className='p-lef-10'>Padrão econômico</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <IoConstructOutline className='fz-25' />
                                                                                <span className='p-lef-10'>Projeto Pronto</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <IoConstructOutline className='fz-25' />
                                                                                <span className='p-lef-10'>Projeto Personalizado</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <IoConstructOutline className='fz-25' />
                                                                                <span className='p-lef-10'>Reforma</span>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li>
                                                                    <Link to='/portifolio' className='w100 d-fx d-al-cen'>
                                                                        <RiGalleryLine className='fz-25' />
                                                                        <span className='p-lef-10'>Portifólio</span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/sobre' className='w100 d-fx d-al-cen'>
                                                                        <RiContactsLine className='fz-25' />
                                                                        <span className='p-lef-10'>Sobre Nós</span>
                                                                    </Link>
                                                                    <ul className='sub-desc-opt'>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <RiContactsLine className='fz-25' />
                                                                                <span className='p-lef-10'>Equipe</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <RiContactsLine className='fz-25' />
                                                                                <span className='p-lef-10'>Empresa</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <RiContactsLine className='fz-25' />
                                                                                <span className='p-lef-10'>Fundação</span>
                                                                            </Link>
                                                                        </li>
                                                                        <li className='desc-opt-single'>
                                                                            <Link to='/obras' className='w100 d-fx d-al-cen'>
                                                                                <RiContactsLine className='fz-25' />
                                                                                <span className='p-lef-10'>Segurança</span>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>{/*opt-single-menu*/}

                                                        <li className="opt-single-menu">
                                                            <a href="javascript:void(0)">
                                                                Beneficios
                                                                {/* <FontAwesomeIcon className='m-lef-5' icon={faAngleDown} /> */}
                                                            </a>
                                                            <ul className="sub-menu-nav">
                                                                <li>
                                                                    <Link to="/minha-conta/fidelidade" className='w100 d-fx d-al-cen'>
                                                                        <MdOutlinePriceCheck className='fz-25' />
                                                                        <span className='p-lef-10'>Programa Fidelidade</span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/parceiros' className='w100 d-fx d-al-cen'>
                                                                        <HiOutlineUserGroup className='fz-25' />
                                                                        <span className='p-lef-10'>Parceiros</span>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>{/*opt-single-menu*/}

                                                        <li className="opt-single-menu">
                                                            <a className='opt-nav-custom' href="javascript:void(0)">
                                                                Novidades
                                                            </a>
                                                        </li>{/*opt-single-menu*/}
                                                    </ul>
                                                </nav>
                                            </div>{/*box-menu-nav*/}
                                        </div>{/*box-center-nav*/}
                                    </div>{/* box-content-nav */}
                                </div>{/*new-header-content-wrapper*/}
                            </div>{/*header-box-content-wrapper*/}
                        </div>{/*new-header-wrapper wrapper-content*/}
                    </div>
                    :
                    <div class="new-header">
                        <div class="new-header-mobile">
                            <div class="css-sflnvq-wrapper-icon-cross e1frdfjw0">
                                <span class="new-header-mobile-overlay"></span>
                            </div>
                            <div class="new-header-mobile-top">
                                <div class="new-header-mobile-divisor left">
                                    <a class="hamburguer-menu" onClick={() => toggleDrawer()}>
                                        <div class="hamburguer-menu-bread">
                                        </div>
                                        <div class="hamburguer-menu-bread">
                                        </div>
                                        <div class="hamburguer-menu-bread">
                                        </div>
                                    </a>
                                    <div class="css-k3z3v5-wrapper-logo e1vdabx90">
                                        <Link to="/" title="Engeart - Loja de Projetos Prontos, Personalizados, Dicas de Decoração e Design de Interiores" alt="Engeart - Loja de Projetos Prontos, Personalizados, Dicas de Decoração e Design de Interiores">
                                            <img src={logo} style={{ maxHeight: '50px' }} />
                                        </Link>
                                    </div>
                                </div>
                                <div class="new-header-mobile-divisor right">
                                    <Link to="/favoritos" title="Minhas listas de Favoritos" class="wishlist">
                                        <AiOutlineHeart className='fz-30 info-primary' />
                                    </Link>
                                    <div class="share-buttons-component">
                                        <div class="share-button-overlay">
                                        </div>
                                        <button aria-label="Compartilhar" class="glyph glyph-share icon-share-trigger d-nn">
                                            <AiOutlineShareAlt className='fz-30 info-primary' />
                                        </button>
                                        <div class="share-button-content">
                                            <ul class="share-button-list">
                                                <li class="share-button-item">
                                                    <a href="https://twitter.com/home?status=https://www.leroymerlin.com.br/" title="twitter" target="_blank" rel="noopener noreferrer">
                                                        <i class="glyph glyph-twitter share-button share-button-twitter"></i>
                                                    </a>
                                                </li>
                                                <li class="share-button-item">
                                                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.leroymerlin.com.br/" title="facebook" target="_blank" rel="noopener noreferrer">
                                                        <i class="glyph glyph-facebook share-button share-button-facebook"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <section>
                                        <Link to="/carrinho" title="Meu carrinho" class="css-17xkciz e1jvl7ws3">
                                            <AiOutlineShoppingCart className='fz-30 info-primary' />
                                        </Link>
                                    </section>
                                    <div class="drawer-overlay">
                                    </div>
                                    <div data-cy="minicart-drawer" class="drawer drawer-padding stock-info-drawer">
                                        <div>
                                            <div class="css-d472k5-container e5gryz80">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="new-header-mobile-bottom">
                                <div class="css-1dzcjy5-search__wrapper e1door6w5">
                                    <div class="css-1h7vqth-text-field-search e1door6w0 p-rlt">
                                        <div className="search-wrapper">
                                            <form onSubmit={(e) => { realizarBusca(e) }}>
                                                <div className='w100 p-rlt d-fx d-al-cen'>
                                                    <label className='w100'>
                                                        <input id="searchbar" className='tx-grey-dark' type="text" name="searchbar" autocomplete="off" value={busca} placeholder="Digite aqui oque deseja buscar" onChange={(ev) => setBusca(ev.target.value)} />
                                                    </label>
                                                    {
                                                        (busca.length > 0)
                                                            ?
                                                            <div className='p-abs d-fx d-al-cen' style={{ right: '5px' }}>
                                                                <AiOutlineClose
                                                                    className='fz-16 tx-red-dark tx-rig m-rig-5'
                                                                    style={{ cursor: 'pointer' }}
                                                                    title="Limpar busca"
                                                                    onClick={() => { setBusca('') }}
                                                                />

                                                                <TbLetterCase
                                                                    className={(buscaExata) ? 'btn__sensitive__case__search fz-18 tx-grey tx-rig active' : 'btn__sensitive__case__search fz-18 tx-grey tx-rig'}
                                                                    style={{ cursor: 'pointer' }}
                                                                    title="Busca exata"
                                                                    onClick={() => { setBuscaExata((prevState) => !prevState) }}
                                                                />
                                                            </div>
                                                            :
                                                            <TbLetterCase
                                                                className={(buscaExata) ? 'btn__sensitive__case__search fz-18 p-abs tx-grey tx-rig active' : 'btn__sensitive__case__search fz-18 p-abs tx-grey tx-rig'}
                                                                style={{ right: '5px', cursor: 'pointer' }}
                                                                title="Busca exata"
                                                                onClick={() => { setBuscaExata((prevState) => !prevState) }}
                                                            />
                                                    }
                                                </div>
                                                {/* <input id="searchbar" type="text" name="searchbar" autocomplete="off" value={search} placeholder="Digite aqui oque deseja buscar" onChange={(ev)=>setSearch(ev.target.value)}/> */}
                                                <button type="submit" title={`Buscar por: ${busca}`}>
                                                    <svg role="presentation" class="css-1dinjto-icon-icon--color-currentColor-icon--size-20px e108jq0t0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="16" height="16" fill="currentColor"><path d="M19.54 17.669l-4.923-4.923a7.71 7.71 0 001.85-5.012C16.468 3.472 12.997 0 8.735 0S1 3.472 1 7.734c0 4.262 3.472 7.734 7.734 7.734 1.35 0 2.621-.35 3.732-.96l5.112 5.112c.26.26.59.38.93.38.34 0 .671-.13.931-.38l.1-.1c.51-.5.51-1.33 0-1.851zM1.95 7.744A6.79 6.79 0 018.734.96a6.79 6.79 0 016.783 6.784 6.79 6.79 0 01-6.783 6.783A6.79 6.79 0 011.95 7.744zM18.86 18.859l-.1.1c-.14.14-.37.14-.51 0l-4.973-4.972c.23-.17.46-.36.67-.55l4.913 4.912c.14.13.14.37 0 .51z" fill-rule="nonzero"></path></svg>
                                                </button>
                                            </form>
                                            <div>
                                                {/* {
                                                                    frutas.map((fruta)=>{
                                                                        return(
                                                                            <p key={fruta}>{fruta}</p>
                                                                        )
                                                                    })
                                                                } */}
                                            </div>
                                        </div>{/*search-wrapper*/}
                                        {
                                            (showSearch)
                                                ?
                                                <div className='result__search p-abs m-top-10 w100 tx-black d-fx d-ju-sp-bt' style={{ background: '#fff', borderRadius: '8px', boxShadow: '0 0 1.25rem 0 rgba(0,0,0,.5)', zIndex: 9 }}>
                                                    <div className='collun__search w100 p-g-10 m-top-15 m-bot-15' style={{ borderRight: 'none' }}>
                                                        <div className='termos__busca tx-lef'>
                                                            <h2 className='tx-18'>Termos da busca</h2>
                                                            <ul className='d-fx d-fw-wp w100 m-top-15'>
                                                                <li className='w100 b-bot-medium'>
                                                                    <Link to={`/busca/${busca}?termo=default&buscaTermo=${busca}&buscaTipo=default`} onClick={() => { setBusca('') }}>
                                                                        <a className='tx-hov-dec-line tx-primary fz-15 tx-bold'>{busca}</a>
                                                                    </Link>
                                                                </li>
                                                                {
                                                                    projetosFiltrados.map((dadosProjetos, index) => {
                                                                        if (dadosProjetos.categoria == 'Projetos Prontos') {
                                                                            return (
                                                                                <li key={index} className='w100 b-bot-medium' title={`${busca} • em: Projetos Prontos`}>
                                                                                    <Link to={`/busca/${busca}?termo=Projetos Prontos&buscaTermo=${busca}&buscaTipo=quickTerm`} onClick={() => { setBusca('') }}>
                                                                                        <a className='tx-hov-dec-line tx-primary fz-15 tx-bold'>{busca}</a>
                                                                                        <p className='tx-grey fz-13 m-top-5'>em: Projetos Prontos</p>
                                                                                    </Link>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                <li className='w100 b-bot-medium'>
                                                                    <Link to={`/busca/${busca}?termo=Projeto Personalizado&buscaTermo=${busca}&buscaTipo=quickTerm`} onClick={() => { setBusca('') }}>
                                                                        <a className='tx-hov-dec-line tx-primary fz-15 tx-bold'>{busca}</a>
                                                                        <p className='tx-grey fz-13 m-top-5'>em: Projetos Personalizados</p>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='termos__busca tx-lef'>
                                                            <h2 className='tx-18'>Conteúdos relacionados</h2>
                                                            <ul className='d-fx w100 m-top-15'>
                                                                <li className='w100 '>
                                                                    <Link to="/" onClick={() => { setBusca('') }}>
                                                                        <CgPlayListSearch className='tx-primary fz-20' />
                                                                        <a className='tx-hov-dec-line tx-grey-dark fz-13'>Como montar um home office pequeno</a>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <span class="color-primary location-info w100">
                                    {
                                        (props.location && !isEmptyObject(props.location.localidade))
                                            ?
                                            <div className='location-wrapper d-fx info-primary d-ju-cen'>
                                                <span className='icon-loc'>
                                                    <svg viewBox="0 0 21 21" fill="currentColor" aria-hidden="true" height="12" width="12">
                                                        <path fill-rule="evenodd" d="M10.0171 0.999515C11.9974 0.905213 13.7892 1.65963 15.1094 2.97986C16.5239 4.3001 17.2783 6.09184 17.3726 7.97789C17.3726 9.01521 17.0897 10.0525 16.6182 10.9956L14.1664 15.5221L11.3373 20.5201C11.1487 20.803 10.7715 20.9916 10.3943 20.9916C10.1114 20.9916 9.73416 20.803 9.54555 20.5201L3.88741 10.807C3.77693 10.586 3.73117 10.4621 3.69325 10.3595C3.66645 10.2869 3.64357 10.225 3.6045 10.1468C3.3216 9.48672 3.22729 8.638 3.22729 7.88358C3.3216 4.11149 6.33927 1.09382 10.0171 0.999515ZM13.7892 4.4887C12.8461 3.54568 11.6202 3.07417 10.3 3.07417H10.1114C7.4709 3.16847 5.39625 5.33742 5.30195 7.97789C5.30195 8.4494 5.39625 9.01521 5.58485 9.48672C5.58485 9.59721 5.61721 9.67533 5.64402 9.74005C5.66298 9.78581 5.67916 9.82487 5.67916 9.86393L10.3 17.9739L12.3746 14.3904L14.7322 10.0525C15.1094 9.39242 15.298 8.73231 15.298 8.07219C15.298 6.65765 14.7322 5.43172 13.7892 4.4887ZM13.4119 7.78928C13.4119 9.48672 11.9974 10.9013 10.2999 10.9013C8.5082 10.9013 7.09367 9.48672 7.09367 7.78928C7.09367 6.09184 8.6025 4.67731 10.2999 4.67731C11.9974 4.67731 13.4119 6.09184 13.4119 7.78928ZM8.88541 7.78928C8.88541 8.5437 9.45123 9.20382 10.2999 9.20382C11.0544 9.20382 11.7145 8.5437 11.7145 7.78928C11.7145 7.03486 11.0544 6.37475 10.2999 6.37475C9.54553 6.37475 8.88541 7.03486 8.88541 7.78928Z" clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                                <a title='Busque pela sua localização e veja os modelos mais vendidos para sua região' onClick={(el) => props.setOpenModal({ "status": true, "tipo": "location" })}>
                                                    <span id='city' className='city-loc'>{props.location.city}</span>{/*city-loc*/}
                                                    <span className='division-loc'>-</span>{/*division-loc*/}
                                                    <span id='state' className='state-loc'>{props.location.state}</span>{/*state-loc*/}
                                                </a>
                                            </div>
                                            :
                                            <div className='location-wrapper d-fx d-al-cen d-ju-cen'>
                                                <span className='icon-loc'>
                                                    {/* <FontAwesomeIcon className='tx-grey-dark' icon={faMapMarkedAlt} /> */}
                                                </span>
                                                {/* <a href='javascript:void(0)' className='tx-grey fz-12 m-lef-5' title='Busque pela sua localização e veja os modelos mais vendidos para sua região' onClick={(el) => props.setOpenModal({ "status": true, "tipo": "location" })}> */}
                                                <a href='javascript:void(0)' className='tx-grey fz-12 m-lef-5' title='Busque pela sua localização e veja os modelos mais vendidos para sua região' onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                    Onde você está?
                                                </a>
                                            </div>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
            }


            <section className='buy-whats'>
                <a href="https://api.whatsapp.com/send?phone=5517996555652&text=Ol%C3%A1%2C%20desejo%20comprar%20um%20projeto" title="Fale já com um de nossos atendentes" >
                    <span>
                        <img src={whats} alt="Fale já com um de nossos atendentes" />
                        <p>
                            <strong style={{ marginRight: '4px' }}>Clique aqui, </strong> <span style={{ color: 'rgb(203, 227, 181)' }}>compre pelo Whatsapp</span> <strong style={{ margin: '0 4px' }}> receba na hora sem sair de casa</strong> | (17) 99655-5652
                        </p>
                    </span>
                </a>
            </section>
        </div>
    );
}

export default Header;