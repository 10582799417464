import { useEffect, useState, useMemo } from 'react';
import { db } from '../../../../../firebase';
import { Link } from 'react-router-dom';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from './Arrows';
import { WidgetLazyload } from './Lazyload';
import toast from 'react-hot-toast';

function CuponsOfertas(props) {

    const [ofertasCupons, setOfertasCupons] = useState([]); // CUPONS DE OFERTAS
    const [lazyload, setLazyload] = useState(true); // LAZYLOAD

    useEffect(() => {
        // PEGAR DADOS DO SLIDE E INSERIR NA SESSÃO
        if (!sessionStorage.getItem("cupons_ofertas")) {
            if (!ofertasCupons || !ofertasCupons.length) {
                (async () => {
                    try {
                        const cuponsRef = db.collection("marketing").doc("ofertas").collection("cupons").where("status", "==", true);
                        const snapshot = await cuponsRef.get();
                        if (snapshot.empty) {
                            toast.error("Não existe cupom de oferta disponível no momento!");
                            return;
                        }
                        var arrCupons = ([]);
                        snapshot.forEach(doc => {
                            arrCupons.unshift(doc.data());
                        });

                        sessionStorage.setItem('cupons_ofertas', JSON.stringify(arrCupons));
                        setOfertasCupons(arrCupons);
                    } catch (error) {
                        toast.error("Ocorreu um erro ao recuperar os slides de ofertas!");
                        console.log(error);
                    }
                })()
            }
        } else {
            // RECUPERAR DADOS SALVOS NA SESSÃO
            let dadosCupons = JSON.parse(sessionStorage.getItem('cupons_ofertas'));
            setOfertasCupons(dadosCupons);
        }
    }, [])

    useMemo(() => {
        if (ofertasCupons && ofertasCupons.length) {
            setLazyload(false);
        }
    }, [ofertasCupons]);

    let rows = [];
    for (let i = 0; i <= 7; i++) {
        rows.push(<WidgetLazyload />)
    }

    let breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 300, itemsToShow: 2 },
        { width: 500, itemsToShow: 3 },
        { width: 600, itemsToShow: 4 },
    ]

    return (

        <section className="widget-wrapper container__content p-rlt z-less">
            <div className="center p-rlt">
                <h2 className='sub-title d-bk'>Seleção de Oportunidades</h2>
                <Carousel
                    breakPoints={breakPoints}
                    itemsToShow={8}
                    className="w100 hide-dots p-rlt"
                    renderArrow={({ type, onClick, isEdge }) => {
                        const pointer = type === consts.PREV ? <ArrowLeft /> : <ArrowRight />
                        return (
                            <button className='btn__controller__carousel' onClick={onClick} disabled={isEdge} style={{ zIndex: 1 }}>
                                {pointer}
                            </button>
                        )
                    }}
                >
                    {
                        (ofertasCupons && ofertasCupons.length > 0)
                            ?
                            ofertasCupons.map((dadosCupons) => {
                                return (
                                    <div className="swiper-slide-single m-rig-15" style={{ marginLeft: '8px' }}>
                                        <Link to={dadosCupons.link} title={dadosCupons.titulo} itemprop="url">
                                            <div className="box-swiper-single">
                                                <div className="capa-swiper-single">
                                                    <img className='capa-widget-single' src={dadosCupons.capa} alt={dadosCupons.titulo} title={dadosCupons.titulo} itemprop={dadosCupons.tipo} />
                                                </div>
                                                <div className="title-swiper-single fz-14">
                                                    <h3 itemprop="name" >{dadosCupons.titulo}</h3>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                            : rows
                    }
                </Carousel>
            </div>
        </section>
    )
}

export default CuponsOfertas;