import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

// REACT ICONS
import { BsCartPlusFill, BsClipboardCheck, BsHeartFill, BsShareFill, BsCheck2Circle, BsFillCalendarEventFill, BsChevronRight, BsCollectionPlay } from 'react-icons/bs';
import { FaBan, FaStar, FaShareSquare, FaHeart, FaEye } from 'react-icons/fa';
import { AiOutlineMinusCircle, AiOutlinePlusCircle, AiFillInfoCircle, AiOutlineCheck } from 'react-icons/ai';
import { MdVrpano, MdOutlineArrowBack, MdOutlineVrpano, MdMoreVert, MdClose, MdOutlineArrowBackIos } from 'react-icons/md';
import { RiImageEditFill, RiFilePaper2Line } from 'react-icons/ri';
import { GoMirror } from 'react-icons/go';
import { SiGooglecardboard } from 'react-icons/si';
import { IoMdImages } from 'react-icons/io';
import { TbCardboards } from 'react-icons/tb';

// IMPORTAÇÃO DE ZOOM DE IMAGEM
// ESTILO - ZOOM - IMAGEM
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
// FUNÇÃO - ZOOM - IMAGEM
import InnerImageZoom from 'react-inner-image-zoom'

import Marzipano from 'react-marzipano';
import { useState } from 'react';
import bloqueioCopia from '../funcoes/bloqueioCopia';

function BoxPlantasProjeto(props) {

    const [tourOpen, setTourOpen] = useState(false);
    const [tourPano, setPanoOpen] = useState(false);
    const scenes = [
        { current: true, imageUrl: 'https://www.marzipano.net/media/equirect/angra.jpg', type: 'equirect' }
    ];

    return (
        <section className={(props.responsivoSize && props.responsivoSize > 1200) ? "infos-tec-projeto" : "infos-tec-projeto p-lef-10 p-rig-10"} style={{ marginTop: '20px' }}>
            <div className="sub-title-section">
                {
                    (props.projeto.pavimentoQnt < 2)
                        ?
                        <h3>Planta Humanizada</h3>
                        :
                        <h3>Plantas Humanizadas</h3>
                }
            </div>
            <div className="box-content-pbh">
                <div style={{ justifyContent: 'space-evenly' }} className="box-nav-pbh d-fx d-fw-wp">
                    <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                        {/* <i style="margin: 0 5px;" className="fa-solid fa-calendar"></i>  */}
                        <BsFillCalendarEventFill className='m-rig-5 fz-16' />
                        Agendar Reunião
                        <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                            <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                        </p>
                        <div className="clear"></div>
                    </button>
                    <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                        {/* <i style="margin: 0 5px;" className="fa-solid fa-panorama"></i>  */}
                        <MdVrpano className='m-rig-5 fz-16' />
                        Tour 360º
                        <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                            <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                        </p>
                        <div className="clear"></div>
                    </button>
                    {
                        (tourOpen)
                            ?
                            <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                {/* <i style="margin: 0 5px;" className="fa-solid fa-vr-cardboard"></i>  */}
                                <RiFilePaper2Line className='m-rig-5 fz-16' />
                                Planta Baixa
                                <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                                    <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                                </p>
                                <div className="clear"></div>
                            </button>
                            :
                            <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={() => toast.error("Esta função está em desenvolvimento")}>
                                {/* <i style="margin: 0 5px;" className="fa-solid fa-vr-cardboard"></i>  */}
                                <SiGooglecardboard className='m-rig-5 fz-16' />
                                Tour Interativo
                                <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                                    <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                                </p>
                                <div className="clear"></div>
                            </button>
                    }
                    <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                        {/* <i style="margin: 0 5px;" className="fa-solid fa-arrows-rotate"></i>  */}
                        <GoMirror className='m-rig-5 fz-16' />
                        Inverter Planta
                        <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                            <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                        </p>
                        <div className="clear"></div>
                    </button>
                    <Link to="/projeto-personalizado" className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                        {/* <i style="margin: 0 5px;" className="fa-solid fa-pen-to-square"></i>  */}
                        <RiImageEditFill className='m-rig-5 fz-16' />
                        Personalizar esta Planta
                        <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                            <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                        </p>
                        <div className="clear"></div>
                    </Link>
                    {/* <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado p-g-10">
                                    <MdMoreHoriz />
                                </button> */}
                </div>
                {
                    (tourOpen || tourPano)
                        ?
                        (tourOpen)
                            ?
                            <div className="box-preview-pbh m-top-20 w100"> {/* BOX DE TOUR VIRTUAL */}
                                <h3 className="fz-16 m-bot-20">Tour Virtual</h3>
                                {/* <TourTeste /> */}
                            </div>
                            :
                            <div className="box-preview-pbh m-top-20 w100">
                                <h3 className="fz-16 m-bot-20">Tour 360º</h3>
                                <Marzipano scenes={scenes} className="myMarzi w100" style={{ height: '720px', position: 'relative' }} />
                            </div>
                        :
                        <div className="box-preview-pbh m-top-20 b-top" onDragStart={e=> {e.preventDefault()}}  onContextMenu={(e) => bloqueioCopia(e, props.setOpenModal)}> {/* BOX DE IMAGENS DE PLANTA BAIXA */}
                            {
                                (props.projeto.plantas)
                                    ?
                                    <div className="container__pavimentos">
                                        {
                                            (props.projeto.pavimentoQnt < 2)
                                                ?
                                                <div className="box-terreo">
                                                    <h3 className="fz-16 m-bot-20">Pávimento Térreo</h3>
                                                    {/* <div className="responsive-bg" style={{ backgroundImage: `url(${home1})` }}></div> */}
                                                    <InnerImageZoom
                                                        className='w100'
                                                        src={props.projeto.plantas.baixa_pav_terreo}
                                                        zoomScale={0.3}
                                                    />
                                                </div>
                                                :
                                                <div className="box-sobrado">
                                                    <div className='content__sobrado__pavimento__terreo'>
                                                        <h3 className="fz-16 m-bot-20">Pávimento Térreo</h3>
                                                        <InnerImageZoom
                                                            className='w100'
                                                            src={props.projeto.plantas.baixa_pav_terreo}
                                                            zoomScale={0.3}
                                                        />
                                                    </div>

                                                    <div className='content__sobrado__pavimento__superior m-top-30'>
                                                        <h3 className="fz-16 m-bot-20">Pávimento Superior</h3>
                                                        <InnerImageZoom
                                                            className='w100'
                                                            src={props.projeto.plantas.baixa_pav_superior}
                                                            zoomScale={0.3}
                                                        />
                                                    </div>
                                                </div>

                                        }
                                    </div>
                                    :
                                    null
                            }
                        </div>
                }
            </div>
        </section>
    )
}

export default BoxPlantasProjeto;