function ArrowLeft() {
    return(
        <div className='p-abs btn-nav-slides btn-left'>
            <svg fill="#3C3738" width="24" height="24" viewBox="0 0 20 20" role="presentation" className="css-1dinjto-icon-icon--color-currentColor-icon--size-20px e108jq0t0"><title>Anterior</title><path d="M14.7432886,20 L11.5822148,20 C11.1728188,20 10.783557,19.7986577 10.5419463,19.4697987 L4.2466443,10.7516779 C3.91778523,10.3020134 3.91778523,9.69798658 4.2466443,9.24832215 L10.5419463,0.530201342 C10.783557,0.194630872 11.1728188,0 11.5822148,0 L14.783557,0 C15.2667785,0 15.7097315,0.268456376 15.9312081,0.704697987 C16.1526846,1.1409396 16.1124161,1.65100671 15.8238255,2.04697987 L10.0989933,9.91275168 C10.0587248,9.96644295 10.0587248,10.033557 10.0989933,10.0872483 L15.7902685,17.9597315 C16.0721477,18.3557047 16.1124161,18.8657718 15.8909396,19.295302 C15.6694631,19.7315436 15.2332215,20 14.7432886,20 Z M11.5889262,1.13422819 C11.5419463,1.13422819 11.4949664,1.15436242 11.4681208,1.19463087 L5.16610738,9.90604027 C5.12583893,9.95973154 5.12583893,10.0268456 5.16610738,10.0805369 L11.4681208,18.7986577 C11.4949664,18.8389262 11.5419463,18.8590604 11.5889262,18.8590604 L14.75,18.8590604 C14.8238255,18.8590604 14.864094,18.8120805 14.8842282,18.7785235 C14.9043624,18.738255 14.9177852,18.6845638 14.8708054,18.6241611 L9.1795302,10.7516779 C8.85067114,10.3020134 8.85067114,9.69127517 9.1795302,9.24161074 L14.9110738,1.36912752 C14.9580537,1.30872483 14.9446309,1.24832215 14.9244966,1.2147651 C14.9043624,1.18120805 14.864094,1.13422819 14.7902685,1.13422819 L11.5889262,1.13422819 Z"></path></svg>
        </div>
    );
}
function ArrowRight() {
    return(
        <div className='p-abs btn-nav-slides btn-right'>
            <svg fill="#3C3738" width="24" height="24" viewBox="0 0 20 20" role="presentation" className="css-1dinjto-icon-icon--color-currentColor-icon--size-20px e108jq0t0"><title>Próximo</title><path d="M8.49578184,20 L5.33576842,20 C4.85270905,20 4.40990462,19.7316337 4.18850241,19.3022476 C3.96710019,18.8728615 4.00735514,18.3562563 4.28913978,17.9671251 L9.97850576,10.0972828 C10.0187607,10.0436095 10.0187607,9.97651795 9.97850576,9.92284468 L4.24888483,2.04629319 C3.96039103,1.65045287 3.92013609,1.14055686 4.1415383,0.70446159 C4.36294051,0.26836632 4.80574494,0 5.28880432,0 L8.48907268,0 C8.89833132,0 9.28746249,0.20127474 9.52899217,0.530023482 L15.8288915,9.24521972 C16.1576403,9.69473331 16.1576403,10.2985575 15.8288915,10.7480711 L9.52899217,19.4632674 C9.29417164,19.7987253 8.90504048,20 8.49578184,20 Z M5.28880432,1.14055686 C5.21500358,1.14055686 5.17474863,1.18752097 5.15462116,1.22106676 C5.13449368,1.2613217 5.12107537,1.31499497 5.16803947,1.37537739 L10.8976604,9.24521972 C11.2264091,9.69473331 11.2264091,10.3052667 10.8976604,10.7547803 L5.21500358,18.6246226 C5.16803947,18.685005 5.18145779,18.7453875 5.20158526,18.7789332 C5.22171274,18.8191882 5.26196769,18.8594431 5.33576842,18.8594431 L8.49578184,18.8594431 C8.54274595,18.8594431 8.58971005,18.8393157 8.61654669,18.7990607 L14.916446,10.0838645 C14.956701,10.0301912 14.956701,9.96309963 14.916446,9.90942637 L8.61654669,1.20093928 C8.58971005,1.16068433 8.54274595,1.14055686 8.49578184,1.14055686 L5.28880432,1.14055686 Z"></path></svg>
        </div>
    );
}

export { ArrowLeft, ArrowRight };