import '../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from './Templates/Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineFolderView, AiOutlineFundView, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaRegEdit } from 'react-icons/fa';
import contractOkay from '../../../../../../images/icones/svg/contract-okay.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useNavigate } from 'react-router-dom';
import BudgetBox_single from './Templates/Adminstrador/Assets/Components/BudgetBox_single';
import moment from 'moment';

function MyFollowUp(props) {
    const navigate = useNavigate();
    const [acompanhamentoTypeView, setAcompanhamentoTypeView] = useState('grid');

    useMemo(async () => {
        if (props.user && props.user.representante) { // PEGAR TODOS OS REGISTOS POIS O USUÁRIO É AUTORIZADO
            // console.log("Aqui no cont")
            if (props.acompanhamentoServicos && !props.acompanhamentoServicos.length) {
                const ref = db.collection('servicos');
                const snapshot = await ref.get();
                var arr = ([]);
                // var token = ([]);

                snapshot.forEach((doc) => {
                    arr.push(doc.data());
                });
                // arr.at(-1).id = token.at(-1);
                props.setAcompanhamentoServicos(arr);
            }
        } else { // FILTRAR REGISTROS APENAS PARA O CPF DO USUARIO
            // console.log("Aqui no prop")
            if (props.acompanhamentoServicos && !props.acompanhamentoServicos.length) {
                const ref = db.collection('servicos');
                const snapshot = await ref.where("cpf_cnpj_contratante", "==", props.user.cpf).get();
                // console.log(snapshot.empty)

                var arr = ([]);
                // var token = ([]);

                snapshot.forEach((doc) => {
                    arr.push(doc.data());
                });
                // arr.at(-1).id = token.at(-1);
                props.setAcompanhamentoServicos(arr);
            }
        }
    }, [])

    useEffect(() => {
        if (props.responsivoSize && props.responsivoSize < 430) {
            setAcompanhamentoTypeView('grid');
        }
    }, [props.responsivoSize])

    function pegarPercentual(princ, etapa, posit) {
        var res = (((posit + 1) * 100) / princ.etapas.length).toFixed(0);

        console.log(res)
    }

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Área do Administrador" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Acompanhamento de Serviços</h1>
                            <div className='wrapper__boxes__budgets w100 d-fx d-fw-wp'>
                                {/*
                                <div
                                    className={(props.responsivoSize && props.responsivoSize >= 450) ? 'wrapper__new__budget w50 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' : 'wrapper__new__budget w100 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15'}
                                    style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}
                                >
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Minhas Obras</h2>
                                    <p className='fz-14 m-bot-10' style={{ lineHeight: 1.3 }}>
                                        Nulla ea dolore eu elit cillum incididunt non voluptate amet eiusmod consequat voluptate. Excepteur aliquip aliquip proident reprehenderit ut reprehenderit nisi Lorem pariatur.
                                    </p>
                                    <Link to="/minha-conta/acompanhamento/obras" class="button button-full button-primary tx-cen">Acompanhar Obras</Link>
                                </div>
                                <div
                                    className={(props.responsivoSize && props.responsivoSize >= 450) ? 'wrapper__new__budget w50 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' : 'wrapper__new__budget w100 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15'}
                                    style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}
                                >
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Meus Projetos</h2>
                                    <p className='fz-14 m-bot-10' style={{ lineHeight: 1.3 }}>
                                        Nulla ea dolore eu elit cillum incididunt non voluptate amet eiusmod consequat voluptate. Excepteur aliquip aliquip proident reprehenderit ut reprehenderit nisi Lorem pariatur.
                                    </p>
                                    <buttom
                                        class="button button-full button-primary tx-cen"
                                        style={{ backgroundColor: '#dba700' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toast.error("Esta função está em desenvolvimento!");
                                        }}
                                    >Acompanhar Projetos</buttom>
                                </div>
                                <div
                                    className={(props.responsivoSize && props.responsivoSize >= 450) ? 'wrapper__new__budget w50 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' : 'wrapper__new__budget w100 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15'}
                                    style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}
                                >
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Minhas Decorações de Interiores</h2>
                                    <p className='fz-14 m-bot-10' style={{ lineHeight: 1.3 }}>
                                        Nulla ea dolore eu elit cillum incididunt non voluptate amet eiusmod consequat voluptate. Excepteur aliquip aliquip proident reprehenderit ut reprehenderit nisi Lorem pariatur.
                                    </p>
                                    <Link to="/minha-conta/administrador/orcamentos/novo" class="button button-full button-primary tx-cen">Acompanhar Decoração de Interiores</Link>
                                </div>
                                */}
                                <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-25 p-g-15 w100' style={{ background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>

                                    <div class="projects-section">
                                        <div class="projects-section-header">
                                            <p>Acompanhamentos</p>
                                            <p class="time">
                                                {
                                                    (() => {

                                                        const today = new Date();
                                                        return (new Intl.DateTimeFormat('pt-BR', { dateStyle: 'full' }).format(today));
                                                    })
                                                }
                                            </p>
                                        </div>
                                        <div class="projects-section-line">
                                            <div class="projects-status">
                                                <div class="item-status">
                                                    <span class="status-number">
                                                        {
                                                            (props.acompanhamentoServicos && props.acompanhamentoServicos.length)
                                                                ?
                                                                (() => {
                                                                    var count = 0;
                                                                    props.acompanhamentoServicos.map((dataStep) => {
                                                                        var subCount = 0;
                                                                        if (dataStep.etapas) {
                                                                            dataStep.etapas.map((subDataStep, index) => {
                                                                                if (subDataStep.status === true && subDataStep.concluido === false) {
                                                                                    if ((((index + 1) * 100) / dataStep.etapas.length) < 100) {
                                                                                        subCount++;
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        count += subCount;
                                                                    })
                                                                    return count;
                                                                })()
                                                                : 0
                                                        }
                                                    </span>
                                                    <span class="status-type">Em Andamento</span>
                                                </div>
                                                <div class="item-status">
                                                    <span class="status-number">
                                                        {
                                                            (props.acompanhamentoServicos && props.acompanhamentoServicos.length)
                                                                ?
                                                                (() => {
                                                                    var count = 0;
                                                                    props.acompanhamentoServicos.map((dataStep) => {
                                                                        var subCount = 0;
                                                                        if (!dataStep.etapas) {
                                                                            subCount++;
                                                                        }
                                                                        count += subCount;
                                                                    })
                                                                    return count;
                                                                })()
                                                                : 0
                                                        }
                                                    </span>
                                                    <span class="status-type">Pendente</span>
                                                </div>
                                                <div class="item-status">
                                                    <span class="status-number">
                                                        {
                                                            (props.acompanhamentoServicos && props.acompanhamentoServicos.length)
                                                                ?
                                                                (() => {
                                                                    var count = 0;
                                                                    props.acompanhamentoServicos.map((dataStep) => {
                                                                        var subCount = 0;
                                                                        if (dataStep.etapas) {
                                                                            dataStep.etapas.map((subDataStep, index) => {
                                                                                if (subDataStep.status === true && subDataStep.concluido === false) {
                                                                                    if ((((index + 1) * 100) / dataStep.etapas.length) === 100) {
                                                                                        subCount++;
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        count += subCount;
                                                                    })
                                                                    return count;
                                                                })()
                                                                : 0
                                                        }
                                                    </span>
                                                    <span class="status-type">Concluído</span>
                                                </div>
                                                <div class="item-status">
                                                    <span class="status-number">
                                                        {
                                                            (props.acompanhamentoServicos && props.acompanhamentoServicos.length)
                                                                ?
                                                                (() => {
                                                                    var count = 0;
                                                                    props.acompanhamentoServicos.map((dataStep) => {
                                                                        count++;
                                                                    })
                                                                    return count;
                                                                })()
                                                                : 0
                                                        }
                                                    </span>
                                                    <span class="status-type">Projetos Totais</span>
                                                </div>
                                            </div>
                                            {
                                                (props.responsivoSize && props.responsivoSize > 430)
                                                    ?
                                                    <div class="view-actions">
                                                        <button class={(acompanhamentoTypeView && acompanhamentoTypeView === 'list') ? "view-btn list-view active" : "view-btn list-view"} title="List View" onClick={() => setAcompanhamentoTypeView("list")}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list">
                                                                <line x1="8" y1="6" x2="21" y2="6" />
                                                                <line x1="8" y1="12" x2="21" y2="12" />
                                                                <line x1="8" y1="18" x2="21" y2="18" />
                                                                <line x1="3" y1="6" x2="3.01" y2="6" />
                                                                <line x1="3" y1="12" x2="3.01" y2="12" />
                                                                <line x1="3" y1="18" x2="3.01" y2="18" /></svg>
                                                        </button>
                                                        <button class={(acompanhamentoTypeView && acompanhamentoTypeView === 'grid') ? "view-btn grid-view active" : "view-btn grid-view"} title="Grid View" onClick={() => setAcompanhamentoTypeView("grid")}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid">
                                                                <rect x="3" y="3" width="7" height="7" />
                                                                <rect x="14" y="3" width="7" height="7" />
                                                                <rect x="14" y="14" width="7" height="7" />
                                                                <rect x="3" y="14" width="7" height="7" /></svg>
                                                        </button>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div class={(acompanhamentoTypeView && acompanhamentoTypeView === 'grid') ? "project-boxes jsGridView" : "project-boxes jsListView"}>
                                            {
                                                (props.acompanhamentoServicos && props.acompanhamentoServicos.length)
                                                    ?
                                                    props.acompanhamentoServicos.map((dadosPrincipais) => {
                                                        var color = "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0");
                                                        if (dadosPrincipais.etapas) {
                                                            return (
                                                                dadosPrincipais.etapas.map((dadosEtapa, index) => {
                                                                    if (dadosEtapa.status === true) {
                                                                        return (
                                                                            <Link
                                                                                key={index}
                                                                                to={`/minha-conta/acompanhamento/obras/${dadosPrincipais.token}`}
                                                                                class="project-box-wrapper"
                                                                                onClick={(e) => {
                                                                                    if ((dadosPrincipais.anexo_contrato || (dadosPrincipais.assinatura_contratante && dadosPrincipais.contrato)) && dadosPrincipais.status === "aprovado") {
                                                                                        props.setAcompanhamentoObras(dadosPrincipais)
                                                                                    } else {
                                                                                        e.preventDefault();
                                                                                        toast.error("Você precisa primeiro assinar o contrato " + dadosPrincipais.token + "!")
                                                                                        props.setContratoUnico(dadosPrincipais);
                                                                                        navigate(`/minha-conta/meus-contratos/${dadosPrincipais.token}`)
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <div class="project-box" style={{ backgroundColor: color + "25" }}>
                                                                                    <div class="project-box-header">
                                                                                        <span>
                                                                                            {
                                                                                                (() => {
                                                                                                    const today = new Date(dadosEtapa.dataFinal);
                                                                                                    return (new Intl.DateTimeFormat('pt-BR', { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' }).format(today));
                                                                                                })
                                                                                            }
                                                                                        </span>
                                                                                        <div class="more-wrapper">
                                                                                            <button
                                                                                                class="project-btn-more"
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    toast.error("Função em desenvolvimento!");
                                                                                                }}
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical">
                                                                                                    <circle cx="12" cy="12" r="1" />
                                                                                                    <circle cx="12" cy="5" r="1" />
                                                                                                    <circle cx="12" cy="19" r="1" /></svg>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="project-box-content-header m-bot-5">
                                                                                        <p
                                                                                            class="box-content-header"
                                                                                            style={{ color: "#333" }}
                                                                                        >
                                                                                            {dadosPrincipais.categoria}
                                                                                        </p>
                                                                                        <p
                                                                                            class="box-content-subheader"
                                                                                            title={dadosEtapa.descricao}
                                                                                            style={{ marginBottom: '5px', color: "#333" }}
                                                                                        >
                                                                                            {dadosEtapa.descricao}
                                                                                        </p>
                                                                                        {
                                                                                            ((dadosPrincipais.anexo_contrato || (dadosPrincipais.assinatura_contratante && dadosPrincipais.contrato && dadosPrincipais.contrato.length)) && dadosPrincipais.status === "aprovado")
                                                                                                ?
                                                                                                <Link
                                                                                                    to={`/minha-conta/acompanhamento/obras/${dadosPrincipais.token}/${index + 1}`}
                                                                                                    className='m-top-5 m-bot-5 fz-12'
                                                                                                    style={{ background: '#fff', padding: '3px 10px', border: '1px solid ' + color, color: color, borderRadius: '20px' }}
                                                                                                    onClick={() => props.setAcompanhamentoObras(dadosPrincipais)}
                                                                                                >
                                                                                                    Ver Etapa Atual
                                                                                                </Link>
                                                                                                : null

                                                                                        }
                                                                                    </div>
                                                                                    <div class="box-progress-wrapper">
                                                                                        <p
                                                                                            class="box-progress-header"

                                                                                            style={{ color: "#333" }}
                                                                                        >
                                                                                            Progresso
                                                                                        </p>
                                                                                        <div class="box-progress-bar">
                                                                                            <span class="box-progress" style={{
                                                                                                width: (((index + 1) * 100) / (() => {
                                                                                                    if (!dadosEtapa.statusContratado && !dadosEtapa.statusContratante || dadosEtapa.statusContratante && !dadosEtapa.statusContratado) {
                                                                                                        return (dadosPrincipais.etapas.length + 2);
                                                                                                    } else if (dadosEtapa.statusContratado && !dadosEtapa.statusContratante) {
                                                                                                        return (dadosPrincipais.etapas.length + 1);
                                                                                                    } else {
                                                                                                        return (dadosPrincipais.etapas.length);
                                                                                                    }
                                                                                                })()) + "%", backgroundColor: color
                                                                                            }}></span>
                                                                                        </div>
                                                                                        <p
                                                                                            class="box-progress-percentage"
                                                                                            style={{ color: "#333" }}
                                                                                        >{`${(((index + 1) * 100) / (() => {
                                                                                            if (!dadosEtapa.statusContratado && !dadosEtapa.statusContratante || dadosEtapa.statusContratante && !dadosEtapa.statusContratado) {
                                                                                                return (dadosPrincipais.etapas.length + 2);
                                                                                            } else if (dadosEtapa.statusContratado && !dadosEtapa.statusContratante) {
                                                                                                return (dadosPrincipais.etapas.length + 1);
                                                                                            } else {
                                                                                                return (dadosPrincipais.etapas.length);
                                                                                            }
                                                                                        })()).toFixed(0)}%`}</p>
                                                                                        {
                                                                                            (() => {
                                                                                                if (!dadosEtapa.statusContratado) {
                                                                                                    return (<p className='fz-12 tx-cen m-top-5 m-bot-5 p-g-5' style={{ background: '#fff', borderRadius: '20px', color: '#444' }}>Em Execução</p>);
                                                                                                } else if (!dadosEtapa.statusContratante) {
                                                                                                    return (<p className='fz-12 tx-cen m-top-5 m-bot-5 p-g-5' style={{ background: '#fff', borderRadius: '20px', color: '#444' }}>Aguardando Aprovação</p>);
                                                                                                } else {
                                                                                                    return;
                                                                                                }
                                                                                            })()
                                                                                        }
                                                                                    </div>
                                                                                    <div class="project-box-footer">
                                                                                        <div class="participants">
                                                                                            {
                                                                                                (() => {
                                                                                                    var count = 0;
                                                                                                    return (
                                                                                                        dadosPrincipais.membros.map((dadosMembros, index2) => {
                                                                                                            if (count < 9) {
                                                                                                                return (
                                                                                                                    <img
                                                                                                                        key={index2}
                                                                                                                        src={dadosMembros.foto}
                                                                                                                        alt="Participante"
                                                                                                                        title={`${dadosMembros.nome} | ${dadosMembros.cargo}`}
                                                                                                                    />
                                                                                                                )
                                                                                                            }
                                                                                                            count++;
                                                                                                        })
                                                                                                    )
                                                                                                })()
                                                                                            }
                                                                                            <button
                                                                                                class="add-participant"
                                                                                                style={{ color: color }}
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    toast.error("Função em desenvolvimento!");
                                                                                                }}
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus">
                                                                                                    <path d="M12 5v14M5 12h14" />
                                                                                                </svg>
                                                                                            </button>
                                                                                        </div>
                                                                                        {
                                                                                            (dadosPrincipais.status === 'pendente')
                                                                                                ?
                                                                                                <div class="days-left" style={{ color: color }}>

                                                                                                    {
                                                                                                        (() => {
                                                                                                            var dataInformada = dadosEtapa.dataFinal;

                                                                                                            const d1 = new Date().toLocaleDateString("en-US");
                                                                                                            const d2 = new Date(dataInformada).toLocaleDateString("en-US");

                                                                                                            const diffInMs = new Date(d2) - new Date(d1);
                                                                                                            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                                                                                                            if (diffInDays >= 0) {
                                                                                                                return (`Faltam ${diffInDays + 1} dia${(diffInDays > 0) ? 's' : ''}`)
                                                                                                            } else {
                                                                                                                return ("Vencida")
                                                                                                            }
                                                                                                        })()
                                                                                                    }
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        }
                                                    })
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div >
    );

}

export default MyFollowUp;