import { useEffect } from "react";

export default function Subtotal(props) {
    function totalFinal() {
        let amountProduct = 0; // SOMA DE VALORES DOS PRODUTOS
        let amountShipping = 0; // SOMA DE FRETE DOS PRODUTOS
        let amountDiscount = 0; // CALCULO DE DESCONTOS DOS PRODUTOS

        // LOOPING SOMAR VALORES DOS PRODUTOS
        props.cart.map((i) => {
            amountProduct += i.precoTabela * i.quantidade
        })

        // LOOPING SOMAR FRETE DOS PRODUTOS
        props.cart.map((i) => {
            amountShipping += i.frete.online.preco
        })

        // LOOPING SOMAR DESCONTO DOS PRODUTOS
        props.cart.map((i) => {
            amountDiscount += ((i.precoTabela / 100) * i.desconto) * i.quantidade;
        })
        
        return ((amountProduct + amountShipping) - amountDiscount);
    }
    function shippingCost(tipo) {
        let amountShipping = 0
        props.cart.map((i) => {
            if (tipo == 'online') {
                amountShipping += i.frete.online.preco
            } else if (tipo == 'agendada') {
                amountShipping += i.frete.online.preco
            } 
        })

        return amountShipping;
    }
    
    function totalDescontos() {
        let amountDiscount = 0; // CALCULO DE DESCONTOS DOS PRODUTOS

        // LOOPING SOMAR DESCONTO DOS PRODUTOS
        props.cart.map((i) => {
            amountDiscount += ((i.precoTabela / 100) * i.desconto) * i.quantidade;
        })
        
        return amountDiscount;
    }
    function subTotal() {
        let amountProduct = 0
        props.cart.map((i) => {
            amountProduct += i.precoTabela * i.quantidade
        })
        return amountProduct;
    }

    return (
        <>
            <div className='content-title p-g-20 m-bot-20'>
                <div className='content__checkout__cart'>
                    <div className='d-fx d-fw-wp'>
                        <div className='amount__single w100 d-bk b-bot'>
                            <div className={(props.responsivoSize && props.responsivoSize > 450) ? 'd-fx d-al-cen d-ju-sp-bt tx-black fz-16' : 'd-fx d-al-cen d-ju-sp-bt d-fd-col tx-black fz-16'}>
                                <span className='d-bk tx-lig'>
                                    Subtotal
                                </span>
                                <span className='d-bk tx-bold'>
                                    {subTotal().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span>
                            </div>
                        </div>
                        <div className='amount__single w100 d-bk b-bot'>
                            <div className={(props.responsivoSize && props.responsivoSize > 450) ? 'd-fx d-al-cen d-ju-sp-bt tx-black fz-16' : 'd-fx d-al-cen d-ju-sp-bt d-fd-col tx-black fz-16'}>
                                <span className='d-bk tx-lig'>
                                    Frete estimado
                                </span>
                                <span className='d-bk tx-bold'>
                                    {shippingCost("online").toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span>
                            </div>
                        </div>
                        <div className='amount__single w100 d-bk b-bot'>
                            <div className={(props.responsivoSize && props.responsivoSize > 450) ? 'd-fx d-al-cen d-ju-sp-bt tx-black fz-16' : 'd-fx d-al-cen d-ju-sp-bt d-fd-col tx-black fz-16'}>
                                <span className='d-bk tx-lig'>
                                    Descontos
                                </span>
                                <span className='d-bk tx-bold tx-blue-dark'>
                                    {totalDescontos().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span>
                            </div>
                        </div>
                        <div className='amount__single w100 d-bk'>
                            <div className={(props.responsivoSize && props.responsivoSize > 450) ? 'd-fx d-al-cen d-ju-sp-bt tx-black fz-16' : 'd-fx d-al-cen d-ju-sp-bt d-fd-col tx-black fz-16'}>
                                <span className='d-bk tx-bold'>
                                    Total estimado
                                </span>
                                <span className='d-bk tx-bold'>
                                    {totalFinal().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}