import { Link } from 'react-router-dom'; // IMPORTAÇÃO DE REACT ROUTER
import '../Assets/styles/css/Main.css';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet'; // IMPORTAÇÃO DE MANIPULADOR DE TITLE

import Subtotal from './Components/Pagamentos/Subtotal';

import $ from 'jquery';

// IMPORTAÇÃO DE ICONES
import { BsCreditCard2Front, BsCreditCard2FrontFill } from 'react-icons/bs';
import { ImQrcode } from 'react-icons/im';
import { GrTransaction } from 'react-icons/gr';
import { FcMoneyTransfer } from 'react-icons/fc';
import { RiHomeGearLine } from 'react-icons/ri';
import { FaBarcode, FaApplePay, FaGooglePay, FaStar } from 'react-icons/fa';

// IMPORTAÇÃO DE IMAGENS
import imgUserBoleto from '../../../../images/icones/svg/user-invoice.svg'
import imgUserPagamentoVista from '../../../../images/icones/svg/user-paymernt-in-cash.svg'
import imgUserPagamentoParcelado from '../../../../images/icones/svg/user-installment.svg'

// IMPORTAÇÕES PARA USO DO STRIPE
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

import { db, auth } from '../../../../firebase';

// import CheckoutForm from "./Components/CheckoutForm";
import FormPagamento from '../Assets/components/FormPagamentos';

import toast from 'react-hot-toast';


function Pagamento(props) {
    const [paymentMethod, setPaymentMethod] = useState('credito');
    const [btnPaymentDisabled, setBtnPaymentDisabled] = useState(false);

    useEffect(() => [
        console.log(paymentMethod)
    ][paymentMethod]);

    useEffect(() => {
        console.log(props.enderecoSelecionado);
    }, [props.enderecoSelecionado])

    return (
        <div id='pagamento' className={(props.responsivoSize && (props.responsivoSize < 1200 && props.responsivoSize > 768)) ? 'p-lef-10 p-rig-10' : null}>
            <Helmet title="Engeart • Meu Pedido • Pagamento" />
            <div className='projeto-single-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/carrinho">Carrinho</Link>
                                        </li>
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/pedido/entrega">Entrega</Link>
                                        </li>
                                        <li className="breadcrumb-item active m-0">Pagamento</li>
                                    </ol>
                                </nav>
                            </div>
                            <section className='p-g-20 m-top-10 d-fx d-al-cen d-ju-sp-bt w70'>
                                <div className='content-principal'>
                                    {
                                        (props.user)
                                            ?
                                            <h1 className='fz-30 m-bot-10 tx-lig' style={{ color: '#000' }}>Olá, <span style={{ textTransform: 'uppercase' }}>{props.user.nome}</span></h1>
                                            :
                                            <h1 className='fz-30 m-bot-10 tx-lig' style={{ color: '#000' }}>Olá</h1>
                                    }
                                    <p className='fz-16'>Chegamos na última parte. Faltam apenas os dados de pagamento :)</p>
                                </div>
                            </section>

                            <section className={(props.responsivoSize && props.responsivoSize > 768) ? 'd-fx m-top-20' : 'd-fx d-fw-wp'} style={(props.responsivoSize && props.responsivoSize < 768) ? { padding: '1.563rem 1rem 1.563rem 1rem' } : null}>
                                <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'content-title p-g-20 w70' : 'content-title p-g-20 w100'} style={(props.responsivoSize && props.responsivoSize < 200) ? {padding: '10px'} : null}>
                                    {/* MEIOS DE PAGAMENTO */}
                                    <div className='dados__content__principal w100'>
                                        <div className='tipo__pagamento b-bot'>
                                            <div className="input-button m-g-5" onClick={() => { setPaymentMethod('credito') }}>
                                                <input type="radio" name="payment__type" value="credito" id="pay_CreditCard" checked={(paymentMethod && paymentMethod == 'credito') ? 'checked' : null} />
                                                <label className="label d-al-cen" for="pay_CreditCard" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex' } : { display: 'flex', flexWrap: 'wrap' }}>
                                                    <BsCreditCard2Front className='fz-18 m-rig-5 m-lef-5' />
                                                    <span>Cartão de Crédito</span>
                                                </label>
                                            </div>

                                            <div className="input-button m-g-5" onClick={() => { setPaymentMethod('boleto') }}>
                                                <input type="radio" name="payment__type" value="boleto" id="pay_Boleto" checked={(paymentMethod && paymentMethod == 'boleto') ? 'checked' : null} />
                                                <label className="label d-al-cen" for="pay_Boleto" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex' } : { display: 'flex', flexWrap: 'wrap' }}>
                                                    <FaBarcode className='fz-18 m-rig-5 m-lef-5' />
                                                    <span>Boleto</span>
                                                </label>
                                            </div>

                                            <div className="input-button m-g-5" onClick={() => { setPaymentMethod('pix') }}>
                                                <input type="radio" name="payment__type" value="pix" id="pay_Pix" checked={(paymentMethod && paymentMethod == 'pix') ? 'checked' : null} />
                                                <label className="label d-al-cen" for="pay_Pix" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex' } : { display: 'flex', flexWrap: 'wrap' }}>
                                                    <ImQrcode className='fz-18 m-rig-5 m-lef-5' />
                                                    <span>PIX</span>
                                                </label>
                                            </div>

                                            <div className="input-button m-g-5" onClick={(e) => toast.error("Mais meios de pagamento, em breve...")}>
                                                <label className="label d-al-cen" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex', padding: '.68rem .81rem .68rem .81rem' } : { display: 'flex', padding: '.68rem .81rem .68rem .81rem', flexWrap: 'wrap' }}>
                                                    <RiHomeGearLine className='fz-18 m-rig-5 m-lef-5' />
                                                    <span>Outros meios de pagamento (Em Breve...)</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* FORMULÁRIO DE PAGAMENTO */}
                                    <FormPagamento responsivoSize={props.responsivoSize} user={props.user} paymentMethod={paymentMethod} enderecoComprador={props.enderecoComprador} telefoneComprador={props.telefoneComprador} cart={props.cart} setBtnPaymentDisabled={setBtnPaymentDisabled} />
                                </div>
                                <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'w30' : 'w100 m-top-20'} style={(props.responsivoSize && props.responsivoSize > 768) ? { marginLeft: '20px' } : null}>
                                    <Subtotal cart={props.cart} responsivoSize={props.responsivoSize} paymentMethod={paymentMethod} />
                                    <button form={`dados-form-pagamento-${paymentMethod}`} class="button button-full button-primary" disabled={btnPaymentDisabled}>Finalizar Pagamento</button>
                                </div>
                                <div className='clear'></div>
                            </section>
                        </div>
                    </div>
                </div>{/*center*/}
            </div>
        </div>
    );
}

export default Pagamento;