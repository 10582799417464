import '../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db, storage } from '../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineSearch, AiFillDownCircle, AiFillUpCircle, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TbSortAscendingNumbers, TbSortDescendingNumbers } from "react-icons/tb";
import { FaRegEdit } from 'react-icons/fa';
import contractOkay from '../../../../../../../../images/icones/svg/contract-okay.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';
import BudgetBox_pendente from './Assets/Components/BudgetBox_single';

// TESTE EMAIL
import emailjs from '@emailjs/browser';



// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond'
import pt_BR from 'filepond/locale/pt-br';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import UserBox_single from './Assets/Components/UserBox_single';
import FormSingUp from '../../../../../Assets/components/FormSingUp';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
// FilePond.setOptions(pt_BR);

function PC_ADM_Projetos(props) {
    const [files, setFiles] = useState([])

    const [expandedSummary, setExpandedSummary] = useState(0); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);


    const [orcamentos, setOrcamentos] = useState([]);
    const [viewUsers, setViewUsers] = useState(false);
    const [primeiraFiltragem, setprimeiraFiltragem] = useState(false);
    const [clientes, setClientes] = useState([]);

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user) && props.user.representante) {
            db.collection("usuarios")
                .onSnapshot((snap) => {
                    setClientes(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })
        }
    }, [])

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }



    const [progresso, setProgresso] = useState(0);
    const [content, setContent] = useState([]);


    async function fazerUploadArquivo(arquivo, uid) {
        const uploadTask = storage.ref('drive/' + arquivo.name).put(arquivo);



        uploadTask.on('state_changed', (snapshot) => {

            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 1;

            setProgresso(progress);

        },

            function (error) {



            },



            function () {

                storage.ref('SERVICOS/E951357852456FC/' + arquivo.name).getDownloadURL().then((url) => {

                    db.collection('drive').add({

                        diretorio: url,

                        tipo: arquivo.type,

                        orderId: 1

                    })

                })


            }



        )

    }


    // FORMATAR CPF / CPNJ CO CLIENTE
    const [dadosCliente, setDadosCliente] = useState([]);
    const [nomeCliente, setNomeCliente] = useState([]);
    const [dadosRG, setDadosRG] = useState([]);
    const [emptyClient, setEmptyClient] = useState(false);
    const [tokenCliente, setTokenCliente] = useState('');
    const [conflitoRepresentante, setconflitoRepresentante] = useState(false);
    const [busca, setBusca] = useState('');

    function isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    function formatarCPFCNPJ(val) {
        setDadosCliente(clientes);
        var nomeDigitado = val;

        // const result = dadosCliente.filter(nomeBuscado => nomeBuscado.full_name == nomeDigitado);
        var filtrado = clientes.filter(function (obj) { return obj.full_name == val; });
        console.log(filtrado);
        setNomeCliente(val);
    }


    const clientesFiltrados = useMemo(() => {
        if (busca && busca.length) {
            setprimeiraFiltragem(true);
            if (isNumber(busca.split("")[0])) {
                var cpf_cnpj = busca.toString();
                var cpf_cnpj_clear = busca.replace(/[^0-9]/g, '');
                cpf_cnpj_clear = (typeof cpf_cnpj_clear == 'string') ? cpf_cnpj_clear : cpf_cnpj_clear.toString();

                var resultFilter = clientes.filter((obj) => obj.cpf.includes(cpf_cnpj_clear));
                if (cpf_cnpj_clear.length <= 14) {
                    if (cpf_cnpj_clear.length && cpf_cnpj_clear.length > 11) {
                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
                        setBusca(new_cnpj);
                    } else {
                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                        setBusca(new_cnpj);
                    }
                }
            } else {
                const lowerBusca = busca.toLowerCase();

                var resultFilter = clientes.filter((obj) => obj.full_name.toLowerCase().includes(lowerBusca));
            }
            return resultFilter;
        } else {
            return clientes;
        }
    }, [busca]);

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Área do Administrador" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Gerênciamento de Projetos</h1>
                            <div className='wrapper__boxes__budgets w100 d-fx d-fw-wp'>
                                <div className='wrapper__new__budget w100 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={{ background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Cadastrar Novo Cliente</h2>
                                    <div className='content-singup'>
                                        <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'm-top-15 w60' : 'm-top-15 w100'} >
                                            <FormSingUp
                                                responsivoSize={props.responsivoSize}
                                                criador="representante"
                                                setDadosCliente={setDadosCliente}
                                                setTokenCliente={setTokenCliente}
                                                operacao="novo-cliente"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='wrapper__new__budget w100 d-fx d-fw-wp m-top-10 m-bot-25 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Todos os Clientes</h2>
                                    <div className='wrapper__filter__users w100 d-fx d-al-cen d-fw-wp'>
                                        <div className={(props.responsivoSize && props.responsivoSize > 700) ? 'box-input-single m-top-20 w70' : 'box-input-single w100'}>
                                            <label>
                                                <div className='title-input d-fx d-al-cen p-rlt'>
                                                    <span className='fz-12 m-rig-5'>Buscar Cliente por Nome, CPF ou CNPJ</span>
                                                    <span className='icon-info-add p-rlt'>
                                                        <IoIosInformationCircleOutline />
                                                        <div className='helper-input tx-black d-nn'>
                                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                <span class="css-1i1x8nz">
                                                                    Seu CPF será usado para:
                                                                </span>
                                                                <div class="css-1l7kih-stack">
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                            </div>
                                                        </div>{/*Helper*/}
                                                    </span>
                                                </div>
                                            </label>
                                            <div className='p-rlt'>
                                                <input
                                                    className='input-form w100 fz-16 m-top-5'
                                                    type='text'
                                                    name="busca-nome-cpf-cnpj"
                                                    placeholder='Digite um Nome, CPF ou CNPJ'
                                                    value={busca}
                                                    onChange={(ev) => setBusca(ev.target.value)}
                                                />
                                                {
                                                    (busca && busca.length)
                                                        ?

                                                        <button
                                                            className='p-abs' style={{ top: "50%", transform: "translateY(calc(-50% + 5px))", right: '35px', background: 'none', cursor: 'pointer' }}
                                                            title="Limpar Busca"
                                                            onClick={(e) => setBusca('')}
                                                        >
                                                            <AiOutlineClose className='fz-20' style={{ color: 'var(--alert-red-color-dark)' }} />
                                                        </button>
                                                        : null
                                                }
                                                <button
                                                    className='p-abs' style={{ top: "50%", transform: "translateY(calc(-50% + 5px))", right: '2%', background: 'none', cursor: 'pointer' }}
                                                    title="Buscar cliente por Nome, CPF ou CNPJ"
                                                    onClick={(e) => setBusca(busca)}
                                                >
                                                    <AiOutlineSearch className='fz-20' style={{ color: 'var(--logo-color-dark)' }} />
                                                </button>

                                            </div>
                                        </div>
                                        <div className={(props.responsivoSize && props.responsivoSize > 700) ? 'box-input-single m-top-35 w30' : 'box-input-single m-top-5 w100' }>
                                            <section className="box-order-filter m-top-10">
                                                <div className="box-order">
                                                    <div className={(props.responsivoSize && props.responsivoSize > 350) ? "btn-order d-fx d-al-cen d-fd-row" : "btn-order d-fx d-al-cen d-ju-cen d-fd-col" }>
                                                        <button className="default selected d-fx d-al-cen" style={{gap: '2px'}} onClick={(el) => setViewUsers(true)}>
                                                            <AiFillDownCircle /> Mostrar tudo
                                                        </button>
                                                        <button className="default d-fx d-al-cen" style={{gap: '2px'}} onClick={(el) => setViewUsers(false)}>
                                                            <AiFillUpCircle /> Ocultar tudo
                                                        </button>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div className='box-forms-singup w100'>
                                        {
                                            (clientesFiltrados && clientesFiltrados.length)
                                                ?
                                                <div className='dados-pessoais-usuario'>
                                                    <div className='box-requests w100 m-bot-20'>
                                                        {
                                                            clientesFiltrados.map((dadosUsuario, index) => {
                                                                return (
                                                                    <UserBox_single
                                                                        key={index}
                                                                        setOpenModal={props.setOpenModal}
                                                                        dadosUsuario={dadosUsuario}
                                                                        dadosContrato={null}
                                                                        user={props.user}
                                                                        responsivoSize={props.responsivoSize}
                                                                        setOrcamentoUnico={props.setOrcamentoUnico}
                                                                        viewUsers={viewUsers}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        (primeiraFiltragem)
                                                            ?
                                                            <div className='d-fx d-al-cen'>
                                                                <div className='w65 p-g-25'>
                                                                    <h2 className='m-bot-20'>Hey!</h2>
                                                                    <div style={{ lineHeight: 1.5 }}>
                                                                        <p className='fz-16'>Não foi possivel localizarmos nenhum contrato para o CPF do usuário logado :(</p>
                                                                        <p className='fz-16'>Parece que o login foi realizado com o CPF de alguém que não seja o contratante principal!</p>
                                                                        <div className='m-top-15 p-g-10' style={{ backgroundColor: "#f5f5f5", borderRadius: '6px' }}>
                                                                            <p className='fz-14 m-bot-10'>O contratante principal já possui uma conta? Clique em sair e crie uma nova conta totalmente gratuita!</p>
                                                                            <p className='fz-14'>Você é o contratante principal e está tendo problemas para visualizar seu contrato? Clique <Link to="/ouvidoria">aqui</Link>!</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='w35'>
                                                                    <img src={contractOkay} />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='dados-pessoais-usuario'>
                                                                <div className='box-requests w100 m-bot-20'>
                                                                    {
                                                                        clientes.map((dadosUsuario, index) => {
                                                                            return (
                                                                                <UserBox_single
                                                                                    key={index}
                                                                                    dadosUsuario={dadosUsuario}
                                                                                    dadosContrato={null}
                                                                                    user={props.user}
                                                                                    responsivoSize={props.responsivoSize}
                                                                                    setOrcamentoUnico={props.setOrcamentoUnico}
                                                                                    viewUsers={viewUsers}
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className='w100'>
                                    <FilePond
                                        files={files}
                                        onupdatefiles={setFiles}
                                        allowMultiple={true}
                                        maxFiles={null}
                                        server={{
                                            // fake server to simulate loading a 'local' server file and processing a file
                                            process: (fieldName, file, metadata, load) => {
                                                // simulates uploading a file
                                                fazerUploadArquivo(file, 12345).then(() => {
                                                    load(progresso);
                                                })
                                            },
                                            load: (source, load) => {
                                                // simulates loading a file from the server
                                                fetch(source)
                                                    .then((res) => res.blob())
                                                    .then(load);
                                            },
                                        }}
                                        name="files"
                                        labelIdle='Arraste e solte seus arquivos ou <span class="filepond--label-action">Navegue</span>'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );

}

export default PC_ADM_Projetos;