import '../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import firebase from 'firebase';

import PC_Sidebar from '../../Templates/Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS

// IMPORTAÇÃO DE ICONES
import { GoChecklist } from 'react-icons/go';
import { TbZoomCancel, TbZoomIn, TbZoomOut } from 'react-icons/tb';
import { GiPresent, GiNotebook } from 'react-icons/gi';
import { BsClipboardCheck, BsCreditCard2Front } from 'react-icons/bs';
import { AiOutlineFolderView, AiOutlineFundView, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { FiDownload, FiEye } from 'react-icons/fi';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { CgMoreVerticalO, CgCloseO } from 'react-icons/cg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useNavigate, useParams } from 'react-router-dom';
import searchFolder from '../../../../../../../../images/icones/svg/search-document.svg';

import { Document, Page, pdfjs } from 'react-pdf';
import { db, storage } from '../../../../../../../../firebase';
import pdfDemo from '../../PDFs/model1.pdf';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
// FUNÇÃO - VIDEO
import { Player } from 'video-react';

function FollowUpObra(props) {
    let { obraToken, idPosition } = useParams();
    let navigate = useNavigate();
    // const httpsReference = storage.ref(storage.getStorage(), 'https://firebasestorage.googleapis.com/b/bucket/o/images%20stars.jpg'); 
    // console.log("result Ref: ", httpsReference)

    // storage.ref("SERVICOS/E951357852456FC/Contrato_assinado.pdf").getDownloadURL().then((url) => {
    //     console.log("urlFile", url);
    // })
    // .catch((err) => {
    //     console.log("Ocorreu um ero")
    //     console.log(err);
    // })

    const url = "https://cors-anywhere.herokuapp.com/https://firebasestorage.googleapis.com/v0/b/engeart-6a378.appspot.com/o/Para_Plotagem_Projeto_Completo_2.0_Assinado.pdf?alt=media&token=8cb902c4-4222-4be7-99ee-2845ab3b1dd5";
    const url2 = "https://cors-anywhere.herokuapp.com/https://firebasestorage.googleapis.com/v0/b/engeart-6a378.appspot.com/o/ENGEART%20-%20Contrato%20de%20Presta%C3%A7%C3%A3o%20de%20Servi%C3%A7os%20-%20AUDRIA.pdf?alt=media&token=eb448359-5c4d-42ed-91fe-b766136611a8";


    const [acompanhamentoTypeView, setAcompanhamentoTypeView] = useState('list');
    const [acompanhamentoEmptyStatus, setAcompanhamentoEmptyStatus] = useState(false);
    /*
    const [props.acompanhamentoServicos, setprops.acompanhamentoServicos] = useState([
        {
            dataInicio: new Date(),
            etapaNome: "Obra",
            etapaDescricao: "Aguardando Assinatura do Contrato",
            etapaQnt: 17,
            etapaAtual: 0,
            etapaMembros: [
                {
                    nome: 'Douglas Pereira',
                    cargo: 'CEO',
                    foto: "http://localhost:3000/static/media/logo_icone.dc0279f1f9f15a6a0c78.jpg"
                }
            ],
            dataFinal: '25/01/2023'
        },
        {
            dataInicio: new Date(),
            etapaNome: "Projeto",
            etapaDescricao: "Aguardando Aprovação da Etapa Finalizada",
            etapaQnt: 6,
            etapaAtual: 4,
            etapaMembros: [
                {
                    nome: 'Douglas Pereira',
                    cargo: 'CEO',
                    foto: "http://localhost:3000/static/media/logo_icone.dc0279f1f9f15a6a0c78.jpg"
                },
            ],
            dataFinal: '25/01/2023'
        }
    ]);
    */

    // useEffect(() => {
    //     db.collection("orcamentos")
    //     .onSnapshot(snapshot => {
    //         var result = ([]);

    //         snapshot.docs.map((doc) => {
    //             result.push(doc.data())
    //         })

    //         db.collection("servicos")
    //             .doc("XEg9G8ttQGZk8jREIlEd")
    //             .update(result[0])
    //     })
    // },[])

    useEffect(() => {
        if (props.acompanhamentoServicos && props.acompanhamentoServicos.length) {
            props.acompanhamentoServicos.map((dadosPrincipais) => {
                // console.log("Resultado", "=>", dadosPrincipais);
                var color = "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0");
                if (dadosPrincipais.slug_categoria && dadosPrincipais.slug_categoria === "obra_e_servico") {
                    setAcompanhamentoEmptyStatus(false);
                } else {
                    setAcompanhamentoEmptyStatus(true);
                }
            })
        }
    })


    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);


    const [contratos, setContratos] = useState([]);
    const [menuOptions, setMenuOptions] = useState([]);
    const [slideCard, setSlideCard] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [statusAprovacaoContratante, setStatusAprovacaoContratante] = useState(false);

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    /*To Prevent right click on screen*/
    // document.addEventListener("contextmenu", (event) => {
    //     event.preventDefault();
    // });

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    useMemo(async () => {
        if (props.user && props.user.representante) { // PEGAR TODOS OS REGISTOS POIS O USUÁRIO É AUTORIZADO
            if (!props.acompanhamentoObras || props.acompanhamentoObras.length || isEmptyObject(props.acompanhamentoObras)) {
                if (obraToken && obraToken.length) {
                    const ref = db.collection('servicos');
                    const snapshot = await ref.where("token", "==", obraToken).get();
                    var arr = ([]);

                    snapshot.forEach((doc) => {
                        arr.push(doc.data());
                    });
                    props.setAcompanhamentoObras(arr[0]);
                } else {
                    toast.error("Não foi possível recuperar o código da obra");
                    navigate("/minha-conta/acompanhamento/obras");
                }
            }
            if (idPosition) {
                setSlideCard(parseInt(idPosition));
            }
        } else { // FILTRAR REGISTROS APENAS PARA O CPF DO USUARIO
            if (!props.acompanhamentoObras || props.acompanhamentoObras.length || isEmptyObject(props.acompanhamentoObras)) {
                if (obraToken && obraToken.length) {
                    const ref = db.collection('servicos');
                    const snapshot = await ref.where("cpf_cnpj_contratante", "==", props.user.cpf).where("token", "==", obraToken).get();
                    var arr = ([]);

                    snapshot.forEach((doc) => {
                        arr.push(doc.data());
                    });
                    props.setAcompanhamentoObras(arr[0]);
                } else {
                    toast.error("Não foi possível recuperar o código da obra");
                    navigate("/minha-conta/acompanhamento/obras");
                }
            }
            if (idPosition) {
                setSlideCard(parseInt(idPosition));
            }
        }
    }, [])






    function aprovarFase(e, index, categoria) {
        e.preventDefault();
        if (!props.user || isEmptyObject(props.user)) {
            toast.error("Não foi possivel recuperar as informações do usuário logado!");
        } else if (!index) {
            toast.error("Não foi possivel recuperar a etapa!");
        } else if (props.user.cpf !== props.acompanhamentoObras.cpf_cnpj_contratante) {
            toast.error("Apenas o proprietário desta proposta pode aprovar cada fase do serviço!");
        } else {
            props.setOpenModal({ status: true, tipo: "aprovar-fase-servico", token: props.acompanhamentoObras.token, dados_servico: props.acompanhamentoObras, indexEtapa: index })
            // console.log({ status: true, tipo: "aprovar-fase-servico", token: props.acompanhamentoObras.token, dados_servico: props.acompanhamentoObras })
        }
    }

    function masonry(container, childs) {
        let columns = (props.responsivoSize && props.responsivoSize >= 481) ? 2 : 1;
        const $container = document.querySelector(container);
        const $childs = document.querySelectorAll(childs);
        $container.classList.add('masonry', `masonry--columns_${columns}`);


        // Aqui creamos las columnas dinamicamnente
        const elementsColumn = [];
        for (let i = 1; i <= columns; i++) {
            const $item = document.createElement('article');
            $item.classList.add('masonry-item');
            elementsColumn.push($item);
        }

        const galeryItems = [];
        $childs.forEach(gItem => {
            galeryItems.push(gItem);
            gItem.remove();
        });

        let counter = 0;
        galeryItems.forEach(item => {
            if (counter >= columns) {
                counter = 0;
            }
            elementsColumn[counter].appendChild(item);
            counter++;
        });

        // Aqui las agregamos al contenedor
        elementsColumn.forEach(elemItem => {
            $container.appendChild(elemItem);
        });
    }

    function definirGaleriaResponsiva(e) {
        e.preventDefault();
        setTimeout(() => {
            masonry('#galery', '.galery-item')
        }, 250);
    }
    // useMemo(() => {
    //         masonry('#galery', '.galery-item');
    // },[props.responsivoSize])



    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        console.log("data", "=>", data);
        const blob = await data.blob();
        console.log("blob", "=>", blob);
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    return (
        <div id='follow_up_obra_single' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Área do Administrador" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd', height: '100%' }}>
                        {
                            (props.acompanhamentoObras && (props.acompanhamentoObras.length || !isEmptyObject(props.acompanhamentoObras)))
                                ?
                                <div className='content-account p-g-25'>
                                    <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>{props.acompanhamentoObras.categoria}: {props.acompanhamentoObras.token}</h1>
                                    <div className='box-forms-singup'>
                                        <div className='d-fx d-al-cen'>
                                            <div className='w100 p-bot-25'>
                                                <div className='wrapper__card__info__budget'>
                                                    {
                                                        (() => {
                                                            if (slideCard === 0) { // Dados da Obra e Serviço
                                                                return (
                                                                    <div className='card__info__budget__single'>
                                                                        <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                                                                            <h2 className=''>Acompanhamento</h2>
                                                                            <div class="description-page__btn-toggle w45"><div class="line-wrapper w100" style={{ cursor: 'text' }}><span className='fz-14'>de</span></div></div>
                                                                            <h2 className=''>
                                                                                {
                                                                                    props.acompanhamentoObras.categoria
                                                                                }
                                                                            </h2>

                                                                        </div>
                                                                        <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-25 p-g-15 w100' style={{ background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                                                            <div class="projects-section">
                                                                                <div class="projects-section-header tx-cen" style={{ margin: '0 auto' }}>
                                                                                    <p>Dados {props.acompanhamentoObras.categoria}</p>
                                                                                </div>
                                                                                <div className='wrapper__revisar__orcamento w100 m-bot-25'>
                                                                                    <div className='wrapper__itens__request d-fx d-fw-wp d-ju-sp-bt'>

                                                                                        <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}> {/* DADOS DO SERVIÇO */}
                                                                                            <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Serviço</span>
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.objeto_contratado && props.acompanhamentoObras.objeto_contratado.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Objeto de Contratação:</strong> <span style={{ textTransform: 'capitalize' }}>{props.acompanhamentoObras.objeto_contratado}</span>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.tipo_contratado && props.acompanhamentoObras.tipo_contratado.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Tipo de Serviço:</strong> <span style={{ textTransform: 'capitalize' }}>{props.acompanhamentoObras.tipo_contratado}</span>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.categoria_servico && props.acompanhamentoObras.categoria_servico.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Categoria de Serviço:</strong> <span style={{ textTransform: 'capitalize' }}>{props.acompanhamentoObras.categoria_servico}</span>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.descricao_servico && props.acompanhamentoObras.descricao_servico.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Descrição de Serviço:</strong> <span style={{ textTransform: 'capitalize' }}>{props.acompanhamentoObras.descricao_servico}</span>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.tipo_execucao && props.acompanhamentoObras.tipo_execucao.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Tipo de Execução:</strong> <span style={{ textTransform: 'capitalize' }}>{props.acompanhamentoObras.tipo_execucao}</span>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.endereco_servico && !isEmptyObject(props.acompanhamentoObras.endereco_servico))
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Endereço:</strong> {`${props.acompanhamentoObras.endereco_servico.tipo_logradouro} ${props.acompanhamentoObras.endereco_servico.logradouro}, ${props.acompanhamentoObras.endereco_servico.numero}, ${props.acompanhamentoObras.endereco_servico.bairro} - ${props.acompanhamentoObras.endereco_servico.cidade}, - ${props.acompanhamentoObras.endereco_servico.estado}, CEP ${(() => {
                                                                                                            var new_cep = props.acompanhamentoObras.endereco_servico.cep.toString();
                                                                                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                        })()}`}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.etapas_pagamento && props.acompanhamentoObras.etapas_pagamento.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Quantidade de Etapas de Pagamento:</strong> {props.acompanhamentoObras.etapas_pagamento.length}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                <strong>Acompanhamento de Serviço:</strong> {(props.acompanhamentoObras && props.acompanhamentoObras.acompanhamento) ? "Ativo" : "Não Incluso"}
                                                                                            </span>
                                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                <strong>Gerênciamento de Serviço:</strong> {(props.acompanhamentoObras && props.acompanhamentoObras.gerenciamento) ? "Ativo" : "Não Incluso"}
                                                                                            </span>
                                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                <strong>Compra de Materiais do Serviço:</strong> {(props.acompanhamentoObras && props.acompanhamentoObras.compras) ? "Ativo" : "Não Incluso"}
                                                                                            </span>
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.etapas_servico && props.acompanhamentoObras.etapas_servico.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Etapas do Serviço:</strong>
                                                                                                        <ul style={{ marginLeft: '20px' }}>
                                                                                                            {
                                                                                                                props.acompanhamentoObras.etapas_servico.map((obs, index) => {
                                                                                                                    return (<li key={index} className="m-top-5">{obs}</li>)
                                                                                                                })
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.etapas_pagamento && props.acompanhamentoObras.etapas_pagamento.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Etapas de Pagamentos:</strong>
                                                                                                        <ul style={{ marginLeft: '20px' }}>
                                                                                                            {
                                                                                                                props.acompanhamentoObras.etapas_pagamento.map((obs, index) => {
                                                                                                                    return (
                                                                                                                        <li key={index} className="m-top-15">
                                                                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                                <strong>Etapa:</strong> {index + 1}
                                                                                                                            </span>
                                                                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                                <strong>Descrição:</strong> {obs.descricao_pag}
                                                                                                                            </span>
                                                                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                                <strong>Valor:</strong> {(obs.valor_pag && obs.valor_pag.length) ? parseInt(obs.valor_pag).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ *****,**"}
                                                                                                                            </span>
                                                                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                                <strong>Vencimento:</strong> {(obs.data_pag && obs.data_pag.length) ? new Date(obs.data_pag).toLocaleDateString("pt-BR") : "R$ *****,**"}
                                                                                                                            </span>
                                                                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                                <strong>Percentual Financeiro:</strong>
                                                                                                                                <span className='m-lef-5'>
                                                                                                                                    {
                                                                                                                                        (() => {
                                                                                                                                            var valorTotal = 0;
                                                                                                                                            props.acompanhamentoObras.etapas_pagamento.map((val) => {
                                                                                                                                                valorTotal += parseInt(val.valor_pag);
                                                                                                                                            })
                                                                                                                                            var percentalUnico = ((parseInt(obs.valor_pag) * 100) / valorTotal).toFixed(0);
                                                                                                                                            return (`${percentalUnico}%`);
                                                                                                                                        })()
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </li>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.etapas_pagamento && props.acompanhamentoObras.etapas_pagamento.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Valor Total do Serviço:</strong>
                                                                                                        <span className='m-lef-5'>
                                                                                                            {
                                                                                                                (() => {
                                                                                                                    var valorTotal = 0;
                                                                                                                    props.budgetPagamentos.map((val) => {
                                                                                                                        valorTotal += parseInt(val.valor_pag);
                                                                                                                    })
                                                                                                                    return (`${valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`);
                                                                                                                })
                                                                                                            }
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.requisitos_servico && props.acompanhamentoObras.requisitos_servico.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Requisitos para o Serviço:</strong>
                                                                                                        <ul style={{ marginLeft: '20px' }}>
                                                                                                            {
                                                                                                                props.acompanhamentoObras.requisitos_servico.map((obs, index) => {
                                                                                                                    return (<li key={index} className="m-top-5">{obs}</li>)
                                                                                                                })
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.etapas_sem_garantia && props.acompanhamentoObras.etapas_sem_garantia.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Limites da Garantia para o Serviço:</strong>
                                                                                                        <ul style={{ marginLeft: '20px' }}>
                                                                                                            {
                                                                                                                props.acompanhamentoObras.etapas_sem_garantia.map((obs, index) => {
                                                                                                                    return (<li key={index} className="m-top-5">{obs}</li>)
                                                                                                                })
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.observacoes && props.acompanhamentoObras.observacoes.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Observações:</strong>
                                                                                                        <ul style={{ marginLeft: '20px' }}>
                                                                                                            {
                                                                                                                props.acompanhamentoObras.observacoes.map((obs, index) => {
                                                                                                                    return (<li key={index} className="m-top-5">{obs}</li>)
                                                                                                                })
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                            <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratante</span>
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.contratante && props.acompanhamentoObras.contratante.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Contratante:</strong> {props.acompanhamentoObras.contratante}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.cpf_cnpj_contratante)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                var cpf_cnpj = props.acompanhamentoObras.cpf_cnpj_contratante.toString();
                                                                                                                if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                    var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                    return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                } else {
                                                                                                                    var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                    return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.rg_contratante)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                var rg = props.acompanhamentoObras.rg_contratante.toString();
                                                                                                                var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                                                            })()
                                                                                                        }
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.endereco_contratante && !isEmptyObject(props.acompanhamentoObras.endereco_contratante))
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Endereço:</strong> {`${props.acompanhamentoObras.endereco_contratante.tipo_logradouro} ${props.acompanhamentoObras.endereco_contratante.logradouro}, ${props.acompanhamentoObras.endereco_contratante.numero}, ${props.acompanhamentoObras.endereco_contratante.bairro} - ${props.acompanhamentoObras.endereco_contratante.cidade}, - ${props.acompanhamentoObras.endereco_contratante.estado}, CEP ${(() => {
                                                                                                            var new_cep = props.acompanhamentoObras.endereco_contratante.cep.toString();
                                                                                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                        })()}`}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.email && props.acompanhamentoObras.email.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>E-mail:</strong> {props.acompanhamentoObras.email}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.telefone && props.acompanhamentoObras.telefone.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Telefone:</strong> {props.acompanhamentoObras.telefone}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}> {/* DADOS DO CONTRATADO */}
                                                                                            <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratado</span>
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.contratado && props.acompanhamentoObras.contratado.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Empresa:</strong> {props.acompanhamentoObras.contratado}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.cpf_cnpj_contratado && props.acompanhamentoObras.cpf_cnpj_contratado)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                var cpf_cnpj = props.acompanhamentoObras.cpf_cnpj_contratado.toString();
                                                                                                                if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                    var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                    return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                } else {
                                                                                                                    var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                    return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.representante_contratado && props.acompanhamentoObras.representante_contratado.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Nome do Representante:</strong> {props.acompanhamentoObras.representante_contratado}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.numero_representante_contratado)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Código do Representante:</strong> {props.acompanhamentoObras.numero_representante_contratado}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.objeto_contratado && props.acompanhamentoObras.objeto_contratado.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Objeto de Contratação:</strong> <span style={{ textTransform: 'capitalize' }}>{props.acompanhamentoObras.objeto_contratado}</span>
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.tipo_servico && props.acompanhamentoObras.tipo_servico.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Tipo de Serviço:</strong> {props.acompanhamentoObras.tipo_servico}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.categoria_servico && props.acompanhamentoObras.categoria_servico.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Categoria de Serviço:</strong> {props.acompanhamentoObras.categoria_servico}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.descricao_servico && props.acompanhamentoObras.descricao_servico.length)
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Descrição de Serviço:</strong> {props.acompanhamentoObras.descricao_servico}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (props.acompanhamentoObras && props.acompanhamentoObras.endereco_contratado && !isEmptyObject(props.acompanhamentoObras.endereco_contratado))
                                                                                                    ?
                                                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                        <strong>Endereço:</strong> {`${(props.acompanhamentoObras.endereco_contratado.tipo_logradouro && props.acompanhamentoObras.endereco_contratado.tipo_logradouro.length) ? props.acompanhamentoObras.endereco_contratado.tipo_logradouro : ''} ${props.acompanhamentoObras.endereco_contratado.logradouro}, ${props.acompanhamentoObras.endereco_contratado.numero}, ${props.acompanhamentoObras.endereco_contratado.bairro} - ${props.acompanhamentoObras.endereco_contratado.cidade}, - ${props.acompanhamentoObras.endereco_contratado.estado}, CEP ${(() => {
                                                                                                            var new_cep = props.acompanhamentoObras.endereco_contratado.cep.toString();
                                                                                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                        })()}`}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    props.acompanhamentoObras.etapas.map((dadosEtapa, index) => {
                                                                        if (slideCard === (index + 1)) { // Etapas
                                                                            return (
                                                                                <div className='card__info__budget__single'>
                                                                                    <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                                                                                        <h2 className=''>Etapa {index + 1}</h2>
                                                                                        <div class="description-page__btn-toggle w45"><div class="line-wrapper w100" style={{ cursor: 'text' }}><span className='fz-14'>de</span></div></div>
                                                                                        <h2 className=''>
                                                                                            {dadosEtapa.descricao}
                                                                                        </h2>

                                                                                    </div>
                                                                                    {
                                                                                        (dadosEtapa.slug && dadosEtapa.slug === "assinatura-do-contrato")
                                                                                            ?
                                                                                            <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-25 p-g-15 w100' style={{ background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }} onContextMenu={(e) => { e.preventDefault() }}>
                                                                                                <div class="projects-section">
                                                                                                    <div class="projects-section-header tx-cen" style={{ margin: '0 auto' }}>
                                                                                                        <p>Contrato {props.acompanhamentoObras.categoria}</p>
                                                                                                    </div>
                                                                                                    <div className='wrapper__revisar__orcamento w100'>
                                                                                                        <div className='wrapper__infos__request p-g-15 m-top-15 m-bot-15'>
                                                                                                            <div className='info__single__request w100 d-fx'>
                                                                                                                <div className='icon__info__request info-primary'>
                                                                                                                    <IoIosInformationCircleOutline className='fz-18' />
                                                                                                                </div>
                                                                                                                <div className='message__info__request w100 m-lef-5'>
                                                                                                                    <p>
                                                                                                                        {
                                                                                                                            (props.acompanhamentoObras.etapas[0].dataAprovacao)
                                                                                                                                ?
                                                                                                                                <span className='fz-13' style={{ fontWeight: '300' }}>
                                                                                                                                    <strong className='fz-13'>Atenção</strong>: {`O Contrato foi assinado no dia ${props.acompanhamentoObras.etapas[0].dataAprovacao.toDate().toLocaleDateString()} ${props.acompanhamentoObras.etapas[0].dataAprovacao.toDate().toLocaleTimeString()} e está ativo até a conclusão prevista do serviço em ${new Date(props.acompanhamentoObras.etapas.at(-1).dataFinal).toLocaleDateString()}.`}
                                                                                                                                </span>
                                                                                                                                :
                                                                                                                                <span className='fz-13' style={{ fontWeight: '300' }}>
                                                                                                                                    <strong className='fz-13'>Atenção</strong>: O Contrato ainda não foi assinado, clique <Link to={`/minha-conta/meus-contratos/${props.acompanhamentoObras.token}`} onClick={(e) => props.setContratoUnico(props.acompanhamentoObras)}>aqui</Link> para assinar!
                                                                                                                                </span>
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='wrapper__itens__request d-fx d-fw-wp d-ju-sp-bt'>
                                                                                                            {
                                                                                                                (dadosEtapa.anexos && dadosEtapa.anexos.length)
                                                                                                                    ?
                                                                                                                    dadosEtapa.anexos.map((anexo) => {
                                                                                                                        if (anexo.tipo.split('/')[0] === 'application') {
                                                                                                                            if (anexo.tipo.split('/')[1] === 'pdf') {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <div className='w100 view__anexos__wrapper p-rlt m-top-15 m-bot-15' style={{ padding: '20px 10px', background: '#fff', borderRadius: '8px', border: '1px solid #ccc' }}>
                                                                                                                                            <TransformWrapper>
                                                                                                                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                                                                                                                    <>
                                                                                                                                                        <TransformComponent>
                                                                                                                                                            <Document
                                                                                                                                                                loading="Carregando Documento ..."
                                                                                                                                                                noData="O Documento está vázio!"
                                                                                                                                                                renderMode={undefined}
                                                                                                                                                                file={anexo.diretorio}
                                                                                                                                                                onLoadSuccess={onDocumentLoadSuccess}
                                                                                                                                                                onLoadProgress={(data) => { console.log("Progresso", data) }}
                                                                                                                                                            >
                                                                                                                                                                <BrowserView>
                                                                                                                                                                    <Page
                                                                                                                                                                        scale={(anexo.tipo.split('/')[1] === 'pdf') ? 5 : 2}
                                                                                                                                                                        renderTextLayer={false}
                                                                                                                                                                        renderAnnotationLayer={false}
                                                                                                                                                                        pageNumber={pageNumber}
                                                                                                                                                                    />
                                                                                                                                                                </BrowserView>
                                                                                                                                                                <MobileView>
                                                                                                                                                                    <Page
                                                                                                                                                                        renderTextLayer={false}
                                                                                                                                                                        renderAnnotationLayer={false}
                                                                                                                                                                        pageNumber={pageNumber}
                                                                                                                                                                    />
                                                                                                                                                                </MobileView>
                                                                                                                                                            </Document>
                                                                                                                                                        </TransformComponent>

                                                                                                                                                        <div className="page-tools">
                                                                                                                                                            <button
                                                                                                                                                                className='btn__tools'
                                                                                                                                                                onClick={() => zoomIn()}
                                                                                                                                                            >
                                                                                                                                                                <TbZoomIn className='fz-18' />
                                                                                                                                                            </button>
                                                                                                                                                            <button
                                                                                                                                                                onClick={() => zoomOut()}
                                                                                                                                                            >
                                                                                                                                                                <TbZoomOut className='fz-18' />
                                                                                                                                                            </button>
                                                                                                                                                            <button
                                                                                                                                                                onClick={() => resetTransform()}
                                                                                                                                                            >
                                                                                                                                                                <TbZoomCancel className='fz-18' />
                                                                                                                                                            </button>
                                                                                                                                                        </div>

                                                                                                                                                        <div class="page-controls">
                                                                                                                                                            <button
                                                                                                                                                                type="button"
                                                                                                                                                                disabled={pageNumber <= 1}
                                                                                                                                                                onClick={previousPage}
                                                                                                                                                                className="Pre"
                                                                                                                                                            >‹</button>
                                                                                                                                                            <span>{pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}</span>
                                                                                                                                                            <button
                                                                                                                                                                type="button"
                                                                                                                                                                disabled={pageNumber >= numPages}
                                                                                                                                                                onClick={nextPage}
                                                                                                                                                            >›</button>
                                                                                                                                                        </div>
                                                                                                                                                    </>
                                                                                                                                                )}
                                                                                                                                            </TransformWrapper>
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        }
                                                                                                                    })
                                                                                                                    :
                                                                                                                    <div id='divToPrint' className='content__contract p-bot-35 m-bot-20'>
                                                                                                                        <div dangerouslySetInnerHTML={{ __html: props.acompanhamentoObras.contrato }} />
                                                                                                                        <div className='m-top-20'>
                                                                                                                            <p className='d-fx d-fd-col'>
                                                                                                                                <img src={props.acompanhamentoObras.assinatura_contratado} style={{ width: 'fit-content', maxWidth: '320px', marginBottom: '-20px' }} />
                                                                                                                                _________________________________________
                                                                                                                            </p>
                                                                                                                            <p style={{ textTransform: 'uppercase' }}>CONTRATADA: {props.acompanhamentoObras.contratado}</p>
                                                                                                                            <p style={{ textTransform: 'uppercase' }}>
                                                                                                                                {
                                                                                                                                    (() => {
                                                                                                                                        var cpf_cnpj = props.acompanhamentoObras.cpf_cnpj_contratado.toString();
                                                                                                                                        if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                            var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                            return (`CNPJ: ${new_cnpj}`);
                                                                                                                                        } else {
                                                                                                                                            var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                            return (`CPF: ${new_cnpj}`);
                                                                                                                                        }
                                                                                                                                    })()
                                                                                                                                }
                                                                                                                            </p>
                                                                                                                            <p style={{ textTransform: 'uppercase' }}>NOME DO REPRESENTANTE: {props.acompanhamentoObras.representante_contratado}</p>
                                                                                                                            <p style={{ textTransform: 'uppercase' }}>CÓDIGO DO REPRESENTANTE: {props.acompanhamentoObras.numero_representante_contratado}</p>
                                                                                                                            <p><br /></p>
                                                                                                                            <p><br /></p>
                                                                                                                            <p><br /></p>
                                                                                                                            <p><br /></p>
                                                                                                                            <p><br /></p>
                                                                                                                            <p className='d-fx d-fd-col'>
                                                                                                                                <img src={props.acompanhamentoObras.assinatura_contratante} style={{ width: 'fit-content', maxWidth: '320px', marginBottom: '-20px' }} />
                                                                                                                                _________________________________________
                                                                                                                            </p>
                                                                                                                            <p style={{ textTransform: 'uppercase' }}>CONTRATANTE: {props.acompanhamentoObras.contratante}</p>
                                                                                                                            <p style={{ textTransform: 'uppercase' }}>CPF: {props.acompanhamentoObras.cpf_cnpj_contratante}</p>
                                                                                                                            <p style={{ textTransform: 'uppercase' }}>
                                                                                                                                {
                                                                                                                                    (() => {
                                                                                                                                        var rg = props.acompanhamentoObras.rg_contratante.toString();
                                                                                                                                        var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (`RG: ${new_rg}`);
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-25 p-g-15 w100' style={{ background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }} onContextMenu={(e) => { e.preventDefault() }}>
                                                                                                <div className='wrapper__revisar__orcamento w100 p-rlt'>
                                                                                                    <div className='wrapper__itens__request d-fx d-fw-wp d-ju-sp-bt'>

                                                                                                        <div className='card__info__budget__single w100 b-bot'>
                                                                                                            <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                                                                                                                {/* <GoChecklist className='fz-100 m-bot-10' style={{ color: "#4caf50" }} /> */}
                                                                                                                <div className={(dadosEtapa.concluido) ? "circle-loader load-complete" : "circle-loader"}>
                                                                                                                    <div class="checkmark draw" style={(dadosEtapa.concluido) ? { display: 'block' } : { display: 'none' }}></div>
                                                                                                                </div>
                                                                                                                {
                                                                                                                    (() => {
                                                                                                                        if (dadosEtapa.concluido && dadosEtapa.statusContratante && dadosEtapa.statusContratado) {
                                                                                                                            let count = 0;
                                                                                                                            props.acompanhamentoObras.etapas.map((doc) => {
                                                                                                                                if (slideCard === props.acompanhamentoObras.etapas.length && doc.concluido && doc.statusContratante) {
                                                                                                                                    count++;
                                                                                                                                }
                                                                                                                            })
                                                                                                                            if (count) { // A Obra foi concluida
                                                                                                                                var tituloServico = props.acompanhamentoObras.titulo_servico;
                                                                                                                                var vogal = tituloServico.split(" ")[0].split("").at(-1);

                                                                                                                                if (vogal.toLowerCase() === 'a') {
                                                                                                                                    return (<h2 className='m-bot-5'>{`${vogal.toUpperCase()} ${tituloServico} foi finalizada!`}</h2>)
                                                                                                                                } else {
                                                                                                                                    return (<h2 className='m-bot-5'>{`A ${props.acompanhamentoObras.categoria} foi finalizada!`}</h2>)
                                                                                                                                }
                                                                                                                            } else {
                                                                                                                                return (<h2 className='m-bot-5'>{`Esta etapa do serviço já foi concluída!`}</h2>);
                                                                                                                            }
                                                                                                                        } else if ((!dadosEtapa.concluido || !dadosEtapa.statusContratante) && dadosEtapa.statusContratado) {
                                                                                                                            return (
                                                                                                                                <h2 className='m-bot-5'>Esta fase está aguadando sua aprovação!</h2>
                                                                                                                            )
                                                                                                                        } else {
                                                                                                                            return (
                                                                                                                                <h2 className='m-bot-5'>Esta fase está em execução!</h2>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    })()
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosEtapa.concluido)
                                                                                                                        ?
                                                                                                                        <div style={{ lineHeight: 1.5 }}>
                                                                                                                            <p className='fz-16 tx-cen'>O combinado para esta parte já foi entrege e aprovado!</p>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div style={{ lineHeight: 1.5, padding: '0 25px' }}>
                                                                                                                            <p className='fz-14 tx-cen'>O serviço contratado para esta etapa está sendo executado, em breve será concluido e estará disponível para sua aprovação!</p>
                                                                                                                        </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {
                                                                                                            (dadosEtapa.anexos && dadosEtapa.anexos.length)
                                                                                                                ?
                                                                                                                <div className='w100'>
                                                                                                                    <h3 className='m-bot-15'>Relatório de Acompanhamento</h3>
                                                                                                                    <div className='w100 d-fx d-fd-col'>
                                                                                                                        {
                                                                                                                            dadosEtapa.anexos.map((anexo, index) => {
                                                                                                                                if (anexo.tipo.split('/')[0] === 'application') {
                                                                                                                                    if (anexo.tipo.split('/')[1] === 'pdf') {
                                                                                                                                        return (
                                                                                                                                            <>
                                                                                                                                                <div className='w100 view__anexos__wrapper p-rlt m-top-15 m-bot-15' style={{ padding: '20px 10px', background: '#fff', borderRadius: '8px', border: '1px solid #ccc' }}>
                                                                                                                                                    <TransformWrapper>
                                                                                                                                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                                                                                                                            <>
                                                                                                                                                                <TransformComponent>
                                                                                                                                                                    <Document
                                                                                                                                                                        loading="Carregando Documento ..."
                                                                                                                                                                        noData="O Documento está vázio!"
                                                                                                                                                                        renderMode={undefined}
                                                                                                                                                                        file={anexo.diretorio}
                                                                                                                                                                        onLoadSuccess={onDocumentLoadSuccess}
                                                                                                                                                                        onLoadProgress={(data) => { console.log("Progresso", data) }}
                                                                                                                                                                    >
                                                                                                                                                                        <BrowserView>
                                                                                                                                                                            <Page
                                                                                                                                                                                scale={(anexo.tipo.split('/')[1] === 'pdf') ? 5 : 2}
                                                                                                                                                                                renderTextLayer={false}
                                                                                                                                                                                renderAnnotationLayer={false}
                                                                                                                                                                                pageNumber={pageNumber}
                                                                                                                                                                            />
                                                                                                                                                                        </BrowserView>
                                                                                                                                                                        <MobileView>
                                                                                                                                                                            <Page
                                                                                                                                                                                renderTextLayer={false}
                                                                                                                                                                                renderAnnotationLayer={false}
                                                                                                                                                                                pageNumber={pageNumber}
                                                                                                                                                                            />
                                                                                                                                                                        </MobileView>
                                                                                                                                                                    </Document>
                                                                                                                                                                </TransformComponent>

                                                                                                                                                                <div className="page-tools">
                                                                                                                                                                    <button
                                                                                                                                                                        className='btn__tools'
                                                                                                                                                                        onClick={() => zoomIn()}
                                                                                                                                                                    >
                                                                                                                                                                        <TbZoomIn className='fz-18' />
                                                                                                                                                                    </button>
                                                                                                                                                                    <button
                                                                                                                                                                        onClick={() => zoomOut()}
                                                                                                                                                                    >
                                                                                                                                                                        <TbZoomOut className='fz-18' />
                                                                                                                                                                    </button>
                                                                                                                                                                    <button
                                                                                                                                                                        onClick={() => resetTransform()}
                                                                                                                                                                    >
                                                                                                                                                                        <TbZoomCancel className='fz-18' />
                                                                                                                                                                    </button>
                                                                                                                                                                </div>

                                                                                                                                                                <div class="page-controls">
                                                                                                                                                                    <button
                                                                                                                                                                        type="button"
                                                                                                                                                                        disabled={pageNumber <= 1}
                                                                                                                                                                        onClick={previousPage}
                                                                                                                                                                        className="Pre"
                                                                                                                                                                    >‹</button>
                                                                                                                                                                    <span>{pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}</span>
                                                                                                                                                                    <button
                                                                                                                                                                        type="button"
                                                                                                                                                                        disabled={pageNumber >= numPages}
                                                                                                                                                                        onClick={nextPage}
                                                                                                                                                                    >›</button>
                                                                                                                                                                </div>
                                                                                                                                                            </>
                                                                                                                                                        )}
                                                                                                                                                    </TransformWrapper>
                                                                                                                                                </div>
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className='w100 d-fx d-fd-col'>
                                                                                                                        <div className='w100 view__anexos__wrapper p-rlt m-top-15 m-bot-15 d-fx d-fw-wp' style={{ padding: '10px 10px', background: '#fff', borderRadius: '6px', border: '1px solid #ccc', userSelect: 'none' }}>
                                                                                                                            <section class="galery" id="galery">
                                                                                                                                {
                                                                                                                                    dadosEtapa.anexos.map((anexo, index) => {
                                                                                                                                        if (anexo.tipo.split('/')[0] === "image") {
                                                                                                                                            return (
                                                                                                                                                <article
                                                                                                                                                    key={index}
                                                                                                                                                    class="galery-item p-rlt"
                                                                                                                                                >
                                                                                                                                                    <div className='p-rlt'>
                                                                                                                                                        {
                                                                                                                                                            (menuOptions && menuOptions.status && menuOptions.id === index)
                                                                                                                                                                ?
                                                                                                                                                                <CgCloseO className='fz-22 p-abs' style={{ top: '5px', right: '5px', color: "#fff", cursor: 'pointer' }} onClick={(e) => {
                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                    setMenuOptions({ status: false, id: index });
                                                                                                                                                                }} />
                                                                                                                                                                :
                                                                                                                                                                <CgMoreVerticalO className='fz-20 p-abs' style={{ top: '6px', right: '6px', color: "#fff", cursor: 'pointer' }} onClick={(e) => {
                                                                                                                                                                    e.preventDefault();
                                                                                                                                                                    setMenuOptions({ status: true, id: index });
                                                                                                                                                                }} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            (menuOptions && menuOptions.status && menuOptions.id === index)
                                                                                                                                                                ?
                                                                                                                                                                <div class="menu">
                                                                                                                                                                    {/* <ul class="menu-list">
                                                                                                                                                                        <li class="menu-item"><button class="menu-button"><i data-feather="corner-up-right"></i>Share</button></li>
                                                                                                                                                                        <li class="menu-item"><button class="menu-button"><i data-feather="edit-2"></i>Rename</button></li>
                                                                                                                                                                    </ul> */}
                                                                                                                                                                    <ul class="menu-list">
                                                                                                                                                                        {/* <li class="menu-item"><button class="menu-button menu-button--black"><i data-feather="circle"></i>No status<i data-feather="chevron-right"></i></button>
                                                                                                                                                                            <ul class="menu-sub-list">
                                                                                                                                                                                <li class="menu-item"><button class="menu-button menu-button--orange"><i data-feather="square"></i>Needs review</button></li>
                                                                                                                                                                                <li class="menu-item"><button class="menu-button menu-button--purple"><i data-feather="octagon"></i>In progress</button></li>
                                                                                                                                                                                <li class="menu-item"><button class="menu-button menu-button--green"><i data-feather="triangle"></i>Approved</button></li>
                                                                                                                                                                                <li class="menu-item"><button class="menu-button menu-button--black menu-button--checked"><i data-feather="circle"></i>No status<i data-feather="check"></i></button></li>
                                                                                                                                                                            </ul>
                                                                                                                                                                        </li>
                                                                                                                                                                        <li class="menu-item"><button class="menu-button"><i data-feather="link"></i>Copy Link Address</button></li>
                                                                                                                                                                        <li class="menu-item"><button class="menu-button"><i data-feather="folder-plus"></i>Move to</button></li>
                                                                                                                                                                        <li class="menu-item"><button class="menu-button"><i data-feather="copy"></i>Copy to</button></li>
                                                                                                                                                                        <li class="menu-item"><button class="menu-button"><i data-feather="lock"></i>Make Private</button></li> 
                                                                                                                                                                        <li class="menu-item">
                                                                                                                                                                            <a href= download class="menu-button"><FiDownload />Download</a>
                                                                                                                                                                        </li>*/}
                                                                                                                                                                        <li class="menu-item">
                                                                                                                                                                            <a href={anexo.diretorio} target="_blank" class="menu-button"><FiEye />Visualizar</a>
                                                                                                                                                                        </li>
                                                                                                                                                                        <li class="menu-item">
                                                                                                                                                                            <button
                                                                                                                                                                                class="menu-button"
                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                    // storage.refFromURL(anexo.diretorio).getDownloadURL().then((url) => {
                                                                                                                                                                                    //     // `url` is the download URL for 'images/stars.jpg'

                                                                                                                                                                                    //     // Isso pode ser baixado diretamente:
                                                                                                                                                                                    //     const xhr = new XMLHttpRequest();
                                                                                                                                                                                    //     xhr.responseType = 'blob';
                                                                                                                                                                                    //     xhr.onload = (event) => {
                                                                                                                                                                                    //       const blob = xhr.response;
                                                                                                                                                                                    //     };
                                                                                                                                                                                    //     xhr.open('GET', url);
                                                                                                                                                                                    //     xhr.send();
                                                                                                                                                                                    //     console.log("Resposta: ",url)
                                                                                                                                                                                    //   })
                                                                                                                                                                                    //   .catch((error) => {
                                                                                                                                                                                    //     // Handle any errors
                                                                                                                                                                                    //   });
                                                                                                                                                                                    getBase64FromUrl(anexo.diretorio).then((data) => {
                                                                                                                                                                                        var a = $("<a>")
                                                                                                                                                                                            .attr("href", data)
                                                                                                                                                                                            .attr("download", "engeart-imagem-acompanhamento.jpg")
                                                                                                                                                                                            .appendTo("body");

                                                                                                                                                                                        a[0].click();

                                                                                                                                                                                        a.remove();
                                                                                                                                                                                    })
                                                                                                                                                                                }}
                                                                                                                                                                            ><FiDownload />Baixar</button>{/*  */}
                                                                                                                                                                        </li>
                                                                                                                                                                    </ul>
                                                                                                                                                                    {/* <ul class="menu-list">
                                                                                                                                                                        <li class="menu-item"><button class="menu-button menu-button--delete"><i data-feather="trash-2"></i>Delete</button></li>
                                                                                                                                                                    </ul> */}
                                                                                                                                                                </div>
                                                                                                                                                                : null
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    <img
                                                                                                                                                        src={anexo.diretorio}
                                                                                                                                                        class="image"
                                                                                                                                                        onLoad={(e) => definirGaleriaResponsiva(e)}
                                                                                                                                                        style={{ borderRadius: '8px' }}
                                                                                                                                                    />
                                                                                                                                                    {
                                                                                                                                                        (anexo.descricao && anexo.descricao.length && (props.responsivoSize && props.responsivoSize >= 768))
                                                                                                                                                            ?
                                                                                                                                                            <p
                                                                                                                                                                className='w100 p-abs fz-12 p-g-5'
                                                                                                                                                                style={{ background: "rgba(255, 255, 255, 0.80)", color: '#444', height: '50px', bottom: '5px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', maxHeight: '50px', overflowY: 'scroll' }}
                                                                                                                                                            >
                                                                                                                                                                {anexo.descricao}
                                                                                                                                                            </p>
                                                                                                                                                            : null
                                                                                                                                                    }
                                                                                                                                                </article>
                                                                                                                                            )
                                                                                                                                        } else if (anexo.tipo.split('/')[0] === "video") {
                                                                                                                                            return (
                                                                                                                                                <article class="galery-item">
                                                                                                                                                    <Player
                                                                                                                                                        playsInline
                                                                                                                                                        src={anexo.diretorio}
                                                                                                                                                    />
                                                                                                                                                </article>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </section>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                : null
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                )
                                                            }


                                                        })()
                                                    }
                                                </div>

                                                <div className='d-fx d-al-cen d-ju-sp-ev d-fw-wp m-top-20'>
                                                    {
                                                        (slideCard > 0)
                                                            ?
                                                            <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={() => {
                                                                setSlideCard(slideCard - 1);
                                                                window.scrollTo(0, 0);
                                                            }}>
                                                                <span className=' fz-12'>Anterior</span>
                                                            </button>
                                                            : null
                                                    }
                                                    {
                                                        (slideCard < props.acompanhamentoObras.etapas.length)
                                                            ?
                                                            (() => {
                                                                let conclusao = 0;
                                                                let contratado = 0;
                                                                let contratante = 0;
                                                                let id = 0;

                                                                props.acompanhamentoObras.etapas.map((dadosEtapa, index) => {
                                                                    if ((slideCard - 1 === index) && dadosEtapa.status === true && !dadosEtapa.concluido) {
                                                                        if (dadosEtapa.statusContratado) {
                                                                            contratado++;
                                                                        } else if (dadosEtapa.statusContratante && dadosEtapa.statusContratado) {
                                                                            contratante++;
                                                                        }
                                                                        id += index;
                                                                    }
                                                                })

                                                                if (contratado && !contratante) {
                                                                    return (
                                                                        <div className='footer__contract m-top-15 m-bot-15 d-fx d-ju-rig'>
                                                                            <button class="continue-application" onClick={(e) => aprovarFase(e, slideCard - 1)}>
                                                                                <div>
                                                                                    <div class="pencil"></div>
                                                                                    <div class="folder">
                                                                                        <div class="top">
                                                                                            <svg viewBox="0 0 24 27">
                                                                                                <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div class="paper"></div>
                                                                                    </div>
                                                                                </div>
                                                                                Aprovar Etapa
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <button className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }} onClick={() => {
                                                                            setSlideCard(slideCard + 1);
                                                                            window.scrollTo(0, 0);
                                                                        }}
                                                                        >
                                                                            <span className=' fz-12'>Próximo</span>
                                                                        </button>
                                                                    )
                                                                }
                                                            })()
                                                            :
                                                            (() => {
                                                                let conclusao = 0;
                                                                let contratado = 0;
                                                                let contratante = 0;

                                                                props.acompanhamentoObras.etapas.map((dadosEtapa, index) => {
                                                                    if ((slideCard - 1 === index) && dadosEtapa.status === true && !dadosEtapa.concluido) {
                                                                        if (dadosEtapa.statusContratado) {
                                                                            contratado++;
                                                                        } else if (dadosEtapa.statusContratante && dadosEtapa.statusContratado) {
                                                                            contratante++;
                                                                        }
                                                                    }
                                                                })
                                                                if (contratado && !contratante) {
                                                                    return (
                                                                        <div className='footer__contract m-top-15 m-bot-15 d-fx d-ju-rig'>
                                                                            <button class="continue-application" onClick={(e) => aprovarFase(e, slideCard - 1)}>
                                                                                <div>
                                                                                    <div class="pencil"></div>
                                                                                    <div class="folder">
                                                                                        <div class="top">
                                                                                            <svg viewBox="0 0 24 27">
                                                                                                <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div class="paper"></div>
                                                                                    </div>
                                                                                </div>
                                                                                Aprovar Etapa
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='d-fx d-al-cen'>
                                    <div className='w65 p-g-25'>
                                        <h2 className='m-bot-20'>Ooops!</h2>
                                        <div style={{ lineHeight: 1.5 }}>
                                            <p className='fz-16'>Não foi possivel localizarmos o serviço informado :(</p>
                                            <p className='fz-16'>Parece que o serviço que você está buscando está sendo desenvolvido, o código localizador passado está errado ou o serviço está no cadastr de outro proprietário!</p>
                                        </div>

                                        <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                                            <Link to="/minha-conta/acompanhamento" className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado">
                                                <span className=' fz-12'>Meus Acompanhamentos</span>
                                            </Link>

                                            <Link to="/" className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }}>
                                                <span className=' fz-12'>Página Inícial</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='w35'>
                                        <img src={searchFolder} />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div >
    );
}

export default FollowUpObra;