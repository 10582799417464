import '../../../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../../../Assets/styles/css/Buttons.css';
import CurrencyInput from 'react-currency-input-field';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../../../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiSave } from 'react-icons/gi';
import { AiFillEdit, AiOutlineCheck, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdHomeFilled, MdHomeWork } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaMoneyBill } from 'react-icons/fa';

import { BsCreditCard2Front, BsTrash } from 'react-icons/bs';
import { ImQrcode } from 'react-icons/im';
import { GrTransaction } from 'react-icons/gr';
import { FcMoneyTransfer } from 'react-icons/fc';
import { RiHomeGearLine } from 'react-icons/ri';
import { FaBarcode, FaApplePay, FaGooglePay, FaStar } from 'react-icons/fa';


import contractOkay from '../../../../../../../../../../images/icones/svg/Construction costs-pana.svg';
import searchFolder from '../../../../../../../../../../images/icones/svg/search-folder.svg';
import searchDocument from '../../../../../../../../../../images/icones/svg/search-document.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';

function BudgetForm_pagamentos(props) {

    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');


    const [orcamentos, setOrcamentos] = useState([]);

    // DADOS DO FORMULÁRIO
    const [usuarioCadastrado, setUsuarioCadastrado] = useState(false); // VERIFICAR SE O USARIO ESTÁ CADASTRADO NO SITE ? ABRIR SELEÇÃO DE USUARIO : ABRIR MODAL DE CADASTRAMENTO DE USUARIO

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user) && props.user.representante) {
            db.collection("orcamentos")
                .where("numero_representante_contratado", "==", props.user.token_representante)
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })

            // console.log(orcamentos)
        }
    }, [])

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }

    // FUNÇÕES DE SELECT PERSONALIZADO - CATEGORIAS
    const [novaCategoriaStatus, setNovaCategoriaStatus] = useState(false);
    const [novaCategoria, setNovaCategoria] = useState([]);

    const [toggleOpenOptions, setToggleOpenOptions] = useState(false);


    // FUNÇÕES DE SELECT PERSONALIZADO - TIPOS
    const [novoTipoStatus, setNovoTipoStatus] = useState(false);
    const [novoTipo, setNovoTipo] = useState([]);

    const [toggleOpenOptionsTipo, setToggleOpenOptionsTipo] = useState(false);

    function typingSearchCategoria(e) {
        filterListCategoria(e.target.value);
    };

    const filterListCategoria = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.categoria").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function typingSearchTipo(e) {
        filterListTipo(e.target.value);
    };

    const filterListTipo = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.tipo").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function convertStringToSlug(string) {
        string = string.replace(/^\s+|\s+$/g, '')
        string = string.toLowerCase()

        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'

        for (let i = 0, l = from.length; i < l; i++) {
            string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        string = string.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')

        return string
    }

    function definirNovaCategoria(val) {
        setNovaCategoria({
            categoria: val,
            slug_categoria: convertStringToSlug(val),
            campo: "categoria-orcamento"
        });
    }

    function definirNovoTipo(val) {
        setNovoTipo({
            tipo: val,
            slug_tipo: convertStringToSlug(val),
            campo: "tipo-orcamento"
        });
    }

    // FUNÇÕES INICIAIS - RECUPERAÇÃO DE CATEGORIAS
    const [categorias, setCategorias] = useState([]);
    const [tipos, setTipos] = useState([]);

    // AVANÇAR PROGRESSO DE CRIAÇÃO DE ORÇAMENTO
    const [saveCategoriaStatus, setSaveCategoriaStatus] = useState(false);

    const [saveTipoStatus, setSaveTipoStatus] = useState(false);

    function avancarOrcamento() {
        toast.success("Etapas de pagamentos salvas com sucesso!");
        props.setSlideCard(props.slideCard + 1);
    }

    // ETPAS DO SERVIÇO
    const [etapa, setEtapa] = useState([]);
    const [etapaValor, setEtapaValor] = useState([]);
    const [etapaDescricao, setEtapaDescricao] = useState([]);
    const [etapaVencimento, setEtapaVencimento] = useState([]);
    const [editStage, setEditStage] = useState(null);
    const [editStageNew, setEditStageNew] = useState([]);

    function apagarEtapa(e, id) {
        e.preventDefault();

        var newStage = ([]);
        if (props.budgetPagamentos && props.budgetPagamentos.length) {
            props.budgetPagamentos.map((doc) => {
                newStage.push(doc);
            })
        }
        newStage.splice(id, 1);
        props.setBudgetPagamentos(newStage);
    }

    function editarEtapa(e, id) {
        e.preventDefault();
        setEditStage(id);
        console.log(editStage);
    }

    function salvarEtapaEditada(e, id, dataOriginal) {
        e.preventDefault();

        if (editStageNew && editStageNew.length) {
            var newEditStage = ([]);
            if (props.budgetEtapas && props.budgetEtapas.length) {
                props.budgetEtapas.map((doc) => {
                    newEditStage.push(doc);
                })
            }
            newEditStage[id] = editStageNew;
            props.setBudgetEtapas(newEditStage);
            setEditStage(null);
            setEditStageNew([]);

            console.log(props.budgetEtapas);
            toast.success(`A etapa de ${editStageNew} foi editada com sucesso!`);
        } else {
            setEditStage(null);
            setEditStageNew([]);
            toast("Nada foi alterado")
        }
    }



    function salvarPagamento(e) {
        e.preventDefault();

        if ((etapaValor && etapaValor.length) && (etapaDescricao && etapaDescricao.length) && (etapaVencimento && etapaVencimento.length)) {
            var newPay = ([]);
            if (props.budgetPagamentos && props.budgetPagamentos.length) {
                props.budgetPagamentos.map((doc) => {
                    newPay.push(doc)
                })
            }
            newPay.push(
                {
                    "descricao_pag": etapaDescricao,
                    "valor_pag": etapaValor.replace(",", '.'),
                    "data_pag": etapaVencimento,
                    "status_pag": false,
                    "meio_pag": null
                }
            )

            props.setBudgetPagamentos(newPay)
            setEtapaValor([]);
            setEtapaDescricao([]);
            setEtapaVencimento([]);
            document.getElementById("myForm").reset();
            /**/
        }
    }
    return (
        <div id="budget_form_categoria">

            <div className='card__info__budget__single'>
                <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                    <h2 className='b-bot-medium'>Cadastro de Etapas de Pagamento</h2>
                    <p className='fz-14'>Preencha as infomações a seguir para gerar um novo orçamento.</p>
                </div>
                <div className='wrapper__form__criar__orcamento'>
                    <form id="myForm" className='form__criar__orcamento'>
                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Valor do Pagamento da Etapa</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <div className='p-rlt'>
                                <CurrencyInput
                                    id='cadastro_etapas_pagamento'
                                    className={((!props.budgetPagamentos || !props.budgetPagamentos.length) && (!etapaValor || !etapaValor.length)) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                    name="etapa_pagamento"
                                    placeholder="R$ 30.000,00"
                                    value={(etapaValor && etapaValor.length) ? etapaValor : null}
                                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                    onValueChange={(ev) => setEtapaValor(ev)}
                                />
                            </div>
                            {
                                (!etapaValor || !etapaValor.length)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>O serviço deve ter pelo menos uma etapa de pagamento!</span>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Descrição do Pagamento da Etapa</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <div className='p-rlt'>
                                <textarea
                                    className={((!props.budgetPagamentos || !props.budgetPagamentos.length) && (!etapaDescricao || !etapaDescricao.length)) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                    name="etapa_descricao"
                                    placeholder='Assinatura do Contrato'
                                    value={(etapaDescricao && etapaDescricao.length) ? etapaDescricao : null}
                                    onChange={(ev) => setEtapaDescricao(ev.target.value)}
                                >
                                </textarea>
                            </div>
                            {
                                (!etapaDescricao || !etapaDescricao.length)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>Cada etapa de pagamento deve ter ao menos uma descrição!</span>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Vencimento do Pagamento da Etapa</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <div className='p-rlt'>
                                <input
                                    type='datetime-local'
                                    className={((!props.budgetEtapas || !props.budgetEtapas.length) && (!etapaVencimento || !etapaVencimento.length)) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                    name="etapa_descricao"
                                    placeholder='Selecione a data do vencimento'
                                    value={(etapaVencimento && etapaVencimento.length) ? etapaVencimento : null}
                                    onChange={(ev) => setEtapaVencimento(ev.target.value)}
                                />
                            </div>
                            {
                                (!etapaVencimento || !etapaVencimento.length)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>Cada etapa de pagamento deve ter uma data de vencimento!</span>
                                    </div>
                                    : null
                            }
                        </div>
                        <button
                            className='button button-full button-primary tx-cen m-top-15 d-fx d-al-cen'
                            onClick={(e) => salvarPagamento(e)}
                            disabled={(!etapaValor || !etapaValor.length || !etapaDescricao || !etapaDescricao.length || !etapaVencimento || !etapaVencimento.length) ? true : false}
                        >
                            <GiSave className='m-rig-5' /> Salvar Etapa
                        </button>
                        {
                            (props.budgetPagamentos && props.budgetPagamentos.length > 0)
                                ?
                                <div className='m-top-20' style={{ padding: '15px', border: "1px solid #ededed", borderRadius: '8px' }}>
                                    <h2 className='fz-18 tx-cen b-bot'>Etapas de Pagamentos do Serviço</h2>
                                    <div className='d-fx d-fw-wp d-ju-sp-ar'>
                                        {
                                            props.budgetPagamentos.map((dadosPagamento, index) => {
                                                return (
                                                    <div
                                                        className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-10 p-g-15 w50'
                                                        style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0' }}
                                                        key={index}
                                                    >
                                                        <div className='p-rlt b-bot-medium w100'>
                                                            <div className='d-fx d-al-cen m-bot-10 d-ju-cen'>
                                                                <h2 className='fz-18 tx-cen'>{`Pagamento ${index + 1}`}</h2>
                                                                <button
                                                                    className='p-abs'
                                                                    style={{ right: 0, background: 'none', cursor: 'pointer' }}
                                                                    title={`Apagar Etapa ${index + 1} de pagamento`}
                                                                    onClick={(e) => { apagarEtapa(e, index) }}
                                                                >
                                                                    <BsTrash
                                                                        className='fz-18'
                                                                        style={{ color: '#ff0000' }}
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='d-fx d-fd-col'>
                                                            <div>
                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                    <strong>Descrição:</strong>
                                                                    <span className='m-lef-5'>{`${dadosPagamento.descricao_pag}`}</span>
                                                                </span>
                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                    <strong>Valor: </strong>
                                                                    <span className='m-lef-5'>{(dadosPagamento.valor_pag) ? `${parseInt(dadosPagamento.valor_pag).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}` : "Não Foi Possivel Recuperar o Valor"}</span>
                                                                </span>
                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                    <strong>Vencimento:</strong>
                                                                    <span className='m-lef-5'>{(dadosPagamento.data_pag) ? `${new Date(dadosPagamento.data_pag).toLocaleDateString("pt-BR")}` : "Não Foi Possivel Recuperar o Vencimento"}</span>
                                                                </span>
                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                    <strong>Percentual Financeiro:</strong>
                                                                    <span className='m-lef-5'>
                                                                        {
                                                                            (() => {
                                                                                var valorTotal = 0;
                                                                                props.budgetPagamentos.map((val) => {
                                                                                    valorTotal += parseInt(val.valor_pag);
                                                                                })
                                                                                var percentalUnico = ((parseInt(dadosPagamento.valor_pag) * 100) / valorTotal).toFixed(0);
                                                                                return (`${percentalUnico}%`);
                                                                            })()
                                                                        }
                                                                    </span>
                                                                </span>
                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>

                                                                    {
                                                                        (() => {
                                                                            const d1 = new Date().toLocaleDateString("en-US");
                                                                            const d2 = new Date(dadosPagamento.data_pag).toLocaleDateString("en-US");

                                                                            const diffInMs = new Date(d2) - new Date(d1);
                                                                            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                                                                            if (diffInDays >= 0) {
                                                                                return (
                                                                                    <div className='med-badge active-badge' style={{ textTransform: 'capitalize' }}>
                                                                                        Em Dia
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div className='med-badge active-badge' style={{ textTransform: 'capitalize' }}>
                                                                                        Vencida
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()
                                                                    }
                                                                    {/* <div className={(1 == 1) ? 'med-badge active-badge' : 'med-badge'} style={{ textTransform: 'capitalize' }}>
                                                                        Em Dia
                                                                    </div> */}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='box-input-single'>
                                        <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                            <span className='icon-valid'><TiInfo /></span>
                                            <span className='text-valid m-lef-5'>{`O serviço terá um total de ${props.budgetPagamentos.length} etapa${(props.budgetPagamentos.length > 1) ? "s" : ""} de pagamento.`}</span>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </form>
                </div>
            </div>

            <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                <button
                    className="cart"
                    style={{ marginTop: 0, textTransform: 'uppercase' }}
                    onClick={() => avancarOrcamento()}
                    disabled={(() => {
                        if (!props.budgetPagamentos || !props.budgetPagamentos.length) {
                            return true;
                        } else {
                            return false;
                        }
                    })()}
                >
                    <span className=' fz-12'>Próximo</span>
                </button>
            </div>
        </div>
    );

}

export default BudgetForm_pagamentos;