function BoxInfoProjeto(props) {
    return (
        <section className={(props.responsivoSize && props.responsivoSize > 1200) ? "infos-tec-projeto m-top-15" : "infos-tec-projeto m-top-15 p-lef-10 p-rig-10"}>
            <h2 className='sub-title d-bk'>Informações do projeto</h2>
            <div className="box-content-infos m-top-15">
                <div className="informacoes-projeto">
                    <div className="info-single">
                        <div>
                            Área da construção (m²):
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{(props.projeto.areaTotal) ? props.projeto.areaTotal.toFixed(2).toString().replace(".", ",") : null}m²</span>
                        </div>
                    </div>
                    <div className="info-single">
                        <div> Área do terreno:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{(props.projeto.larguraTerreno) ? props.projeto.larguraTerreno.toFixed(2).toString().replace(".", ",") : null} x {(props.projeto.comprimentoTerreno) ? props.projeto.comprimentoTerreno.toFixed(2).toString().replace(".", ",") : null}</span>
                        </div>
                    </div>
                    {
                        (props.projeto.areaGourmet && (typeof props.projeto.areaGourmet == 'string' && props.projeto.areaGourmet.length || typeof props.projeto.areaGourmet == "number" && props.projeto.areaGourmet > 0))
                            ?
                            <div className="info-single">
                                <div> Área Gourmet:
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.areaGourmet}</span>
                                </div>
                            </div>
                            : null
                    }
                    <div className="info-single">
                        <div> Banheiro:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.banheiro}</span>
                        </div>
                    </div>
                    <div className="info-single">
                        <div> Caixa d'água:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.caixaAguaQnt} de {props.projeto.caixaAguaVol} litros cada</span>
                        </div>
                    </div>
                    <div className="info-single">
                        <div> Casa de Gás:
                            {
                                (props.projeto.casaGas < 2) ?
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>Para {props.projeto.casaGas} botijão</span>
                                    :
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>Para {props.projeto.casaGas} botijões</span>
                            }
                        </div>
                    </div>
                    {
                        (props.projeto.closet && (typeof props.projeto.closet == 'string' && props.projeto.closet.length || typeof props.projeto.closet == "number" && props.projeto.closet > 0))
                            ?
                            <div className="info-single">
                                <div> Closet:
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.closet}</span>
                                </div>
                            </div>
                            : null
                    }
                    <div className="info-single">
                        <div> Cobertura:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.cobertura}</span>
                        </div>
                    </div>
                    <div className="info-single">
                        <div> Cozinha:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.cozinha}</span>
                        </div>
                    </div>
                    {
                        (props.projeto.deposito && (typeof props.projeto.deposito == 'string' && props.projeto.deposito.length || typeof props.projeto.deposito == "number" && props.projeto.deposito > 0))
                            ?
                            <div className="info-single">
                                <div> Depósito:
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.deposito}</span>
                                </div>
                            </div>
                            : null
                    }
                    {
                        (props.projeto.despensa && (typeof props.projeto.despensa == 'string' && props.projeto.despensa.length || typeof props.projeto.despensa == "number" && props.projeto.despensa > 0))
                            ?
                            <div className="info-single">
                                <div> Despensa:
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.despensa}</span>
                                </div>
                            </div>
                            : null
                    }
                    <div className="info-single">
                        <div> Dormitórios:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.quarto}</span>
                        </div>
                    </div>
                    <div className="info-single">
                        <div> Fachada:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.tipo}</span>
                        </div>
                    </div>
                    <div className="info-single">
                        <div> Garagem:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.garagemVagas}</span>
                        </div>
                    </div>
                    {
                        (props.projeto.hallEntrada && (typeof props.projeto.hallEntrada == 'string' && props.projeto.hallEntrada.length || typeof props.projeto.hallEntrada == "number" && props.projeto.hallEntrada > 0))
                            ?
                            <div className="info-single">
                                <div> Hall de Entrada:
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.hallEntrada}</span>
                                </div>
                            </div>
                            : null
                    }
                    <div className="info-single">
                        <div> Janela:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.janelasTipo}</span>
                        </div>
                    </div>
                    {
                        (props.projeto.lavanderia && (typeof props.projeto.lavanderia == 'string' && props.projeto.lavanderia.length || typeof props.projeto.lavanderia == "number" && props.projeto.lavanderia > 0))
                            ?
                            <div className="info-single">
                                <div> Lavanderia:
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.lavanderia}</span>
                                </div>
                            </div>
                            : null
                    }
                    <div className="info-single">
                        <div> Lote:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.lote}</span>
                        </div>
                    </div>
                    <div className="info-single">
                        <div> Pavimento:
                            {
                                (props.projeto.pavimentoQnt < 2) ?
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.pavimentoQnt} Pávimento</span>
                                    :
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.pavimentoQnt} Pávimentos</span>
                            }
                        </div>
                    </div>
                    {
                        (props.projeto.piscina && (typeof props.projeto.piscina == 'string' && props.projeto.piscina.length || typeof props.projeto.piscina == "number" && props.projeto.piscina > 0))
                            ?
                            <div className="info-single">
                                <div> Piscina:
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.piscina}</span>
                                </div>
                            </div>
                            : null
                    }
                    {
                        (props.projeto.deck && (typeof props.projeto.deck == 'string' && props.projeto.deck.length || typeof props.projeto.deck == "number" && props.projeto.deck > 0))
                            ?
                            <div className="info-single">
                                <div> Deck:
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.deck}</span>
                                </div>
                            </div>
                            : null
                    }
                    {
                        (props.projeto.ofuro && (typeof props.projeto.ofuro == 'string' && props.projeto.ofuro.length || typeof props.projeto.ofuro == "number" && props.projeto.ofuro > 0))
                            ?
                            <div className="info-single">
                                <div> Ofurô:
                                    <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.ofuro}</span>
                                </div>
                            </div>
                            : null
                    }
                    {
                        (props.projeto.salaJantar && (typeof props.projeto.salaJantar == 'string' && props.projeto.salaJantar.length || typeof props.projeto.salaJantar == "number" && props.projeto.salaJantar > 0))
                            ?
                    <div className="info-single">
                        <div> Sala de Jantar:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.salaJantar}</span>
                        </div>
                    </div>
                            : null
                    }
                    {
                        (props.projeto.salaTv && (typeof props.projeto.salaTv == 'string' && props.projeto.salaTv.length || typeof props.projeto.salaTv == "number" && props.projeto.salaTv > 0))
                            ?
                    <div className="info-single">
                        <div> Sala de TV:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.salaTv}</span>
                        </div>
                    </div>
                            : null
                    }
                    {
                        (props.projeto.salaEstar && (typeof props.projeto.salaEstar == 'string' && props.projeto.salaEstar.length || typeof props.projeto.salaEstar == "number" && props.projeto.salaEstar > 0))
                            ?
                    <div className="info-single">
                        <div> Sala de Estar:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.salaEstar}</span>
                        </div>
                    </div>
                            : null
                    }
                    {
                        (props.projeto.suite && (typeof props.projeto.suite == 'string' && props.projeto.suite.length || typeof props.projeto.suite == "number" && props.projeto.suite > 0))
                            ?
                    <div className="info-single">
                        <div> Suíte:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.suite}</span>
                        </div>
                    </div>
                            : null
                    }
                    {
                        (props.projeto.suiteMaster && (typeof props.projeto.suiteMaster == 'string' && props.projeto.suiteMaster.length || typeof props.projeto.suiteMaster == "number" && props.projeto.suiteMaster > 0))
                            ?
                    <div className="info-single">
                        <div> Suíte Master:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.suiteMaster}</span>
                        </div>
                    </div>
                            : null
                    }
                    <div className="info-single">
                        <div> Telha:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.telha}</span>
                        </div>
                    </div>
                    <div className="info-single">
                        <div> Terreno:
                            <span className="right dest-val" style={{ fontWeight: 'normal', fontFamily: 'Courier new' }}>{props.projeto.inclinacao}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BoxInfoProjeto;