
import { AiOutlineClose, AiOutlineReload, AiOutlineClear } from 'react-icons/ai';
import { fecharModal } from '../funcoes/FuncoesGerais';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri';
import { TiInfo } from 'react-icons/ti';
import { GiSave } from 'react-icons/gi';
import { useMemo, useState } from 'react';
import logoIcon from '../../../../../images/icones/jpg/logo_icone.jpg';
import { auth, db, fs } from '../../../../../firebase';
import toast from 'react-hot-toast';
import $, { isEmptyObject } from 'jquery';


// IMPORTAÇÃO PARA ASSINATURA
// import { createRoot } from 'react-dom/client'
import { useEffect, useRef } from 'react';
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getComponent, registerLicense } from '@syncfusion/ej2-base';
import { updateSampleSection } from '../../../../../common/sample-base';
// import SignaturePad from 'react-signature-pad-wrapper'
import SignaturePad from "react-signature-canvas";
import firebase from 'firebase';


function ModalRecortarFoto(props) {

    let navigate = useNavigate();
    const [lazyload, setLazyload] = useState(false); // LAZYLOAD
    const [depoimentos, setDepoimentos] = useState([]); // DEPOIMENTOS DOS CLIENTES
    const [senha, setSenha] = useState(''); // SENHA
    const [token, setToken] = useState(''); // Token ID Firestore
    const [senhaValid, setSenhaValid] = useState(false) // SENHA VÁLIDA
    const [signatureValid, setSignatureValid] = useState(false) // ASSINATURA SALVA
    const [signatureSaved, setSignatureSaved] = useState(false) // ASSINATURA SALVA
    const [signatureContent, setSignatureContent] = useState([]) // ASSINATURA SALVA
    const [contratacaoAtiva, setContratacaoAtiva] = useState(false) // ASSINATURA SALVA
    const [contratacaoAvisada, setContratacaoAvisada] = useState(false) // ASSINATURA SALVA
    const [conviteDepoimento, setConviteDepoimento] = useState(false) // ASSINATURA SALVA
    const [passStateView, setPassStateView] = useState(false);

    function fechaModalPreviewDepoimento(el) {
        el.preventDefault();

        fecharModal();
        setTimeout(() => { props.setOpenModal(null) }, 1000);
    }


    return (
        <div class="popup" style={{ zIndex: 9999, position: 'fixed', maxWidth: '566px' }}>
            <div className='header-popup'>
                <strong>Crop Image</strong>
                <a class="close-popup" onClick={(el) => fechaModalPreviewDepoimento(el)}><AiOutlineClose /></a>
            </div>
            <div class="content">
                <div id='crop-image-container'>
                    
                </div>
            </div>
            <div className='w60 right tx-rig'>
                <button className="btn__cancel__modal" onClick={(el) => {
                    fechaModalPreviewDepoimento(el);
                    navigate('/minha-conta/meus-orcamentos');
                }}>
                    Adiar
                </button>
                <button className="btn__confirm__modal" onClick={(el) => {
                    fechaModalPreviewDepoimento(el)
                    navigate(`/convite/depoimento/${props.openModal.dados_servico.numero_representante_contratado}`);
                }}>
                    Avaliar
                </button>
            </div>
            <div className='clear'></div>
        </div>
    )
}

export default ModalRecortarFoto;