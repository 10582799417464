import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyC6aw-EyVugcbD6vjYRk2An9JLVnxKoIXQ",
    authDomain: "engeart-6a378.firebaseapp.com",
    projectId: "engeart-6a378",
    storageBucket: "engeart-6a378.appspot.com",
    messagingSenderId: "138914595331",
    appId: "1:138914595331:web:1d0469a665e56ae0d10436",
    measurementId: "G-MSQ57E6GHE"
  });

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();
const providerGoogle = new firebase.auth.GoogleAuthProvider();
const providerFacebook = new firebase.auth.FacebookAuthProvider();

const fs = firebase;

export {db, auth, storage, functions, providerGoogle, providerFacebook, fs};