import '../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { db } from '../../../../../../firebase';

import PC_Sidebar from './Templates/Sidebar/PC-Sidebar';


// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'


// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { AiOutlineHeart, AiOutlineCheck, AiOutlinePlusCircle } from 'react-icons/ai';
import { MdOtherHouses } from 'react-icons/md';
import { HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';
import { TiInfo } from 'react-icons/ti';

// IMPORTAÇÃO DE COMPONENTES
import PedidoSingle from './Assets/Components/PedidoSingle';
import { isEmptyObject } from 'jquery';
import { FAQLazyload } from '../../../Assets/components/Lazyload';

function MyRequests(props) {

    const [dadosPagamento, setDadosPagamento] = useState([]);
    const [dadosCobranca, setDadosCobranca] = useState([]);
    const [dadosPedido, setDadosPedido] = useState([]);

    const [show, setShow] = useState(false);
    const [erro, setErro] = useState([]);

    useEffect(async () => {
        if (props.user && !isEmptyObject(props.user)) {
            // await axios.post("https://config.eengeart.com/pedidos", { nome: props.user.full_name, cpf: props.user.cpf, offset: 0, limit: 10 }) // FUNCIONANDO

            // FUNCIONANDO - COMPLEXO
            await axios.post("https://eengeart.com.br/pedidos", {
                nome: props.user.full_name, email: props.user.email, cpf: props.user.cpf, offset: 0, limit: 10
            })
                .then((result) => {
                    if (result.data.data.length) {
                        setDadosCobranca(result.data);
                    } else {
                        toast.error("Você não possui nenhum pedido!");
                    }
                })
                .catch((error) => {
                    toast.error("Ocorreu um erro ao recuperar os dados da requisição!");
                    toast.error(error.message);
                })
            /*    */
        }
    }, [props.user]);

    let rows = [];

    for (let i = 1; i <= 5; i++) {
        rows.push(<FAQLazyload />)
    }
    return (
        <div id='myRequests' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Meus Pedidos" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Meu(s) Pedido(s)</h1>
                            <div className='box-requests w100 m-bot-20'>
                                {
                                    (dadosCobranca && !isEmptyObject(dadosCobranca))
                                        ?
                                        dadosCobranca.data.map((dadosPedido, index) => {
                                            // console.warn('Dados do pedido','=>',dadosPedido)
                                            return (
                                                <PedidoSingle key={index} dadosCobranca={dadosPedido} user={(props.user) ? props.user : null} responsivoSize={props.responsivoSize} />
                                            )
                                        })

                                        :
                                        rows
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );
}

export default MyRequests;