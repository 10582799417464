import '../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from './Templates/Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineFolderView, AiOutlineFundView, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaRegEdit } from 'react-icons/fa';
import contractOkay from '../../../../../../images/icones/svg/contract-okay.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';
import BudgetBox_single from './Templates/Adminstrador/Assets/Components/BudgetBox_single';

function MyBudget(props) {
    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);


    const [orcamentos, setOrcamentos] = useState([]);

    useMemo(() => {
        const cpf_user = props.user.cpf.replace(/[^\d]+/g, '');
        if (props.user && !isEmptyObject(props.user)) {

            // db.collection("orcamentos")
            //     .where("cpf_cnpj_contratante", "==", cpf_user)
            //     .onSnapshot((snap) => {
            //         setOrcamentos(
            //             snap.docs.map((doc) => {
            //                 return doc.data();
            //             })
            //         )
            //     })

            db.collection("servicos")
                .where("cpf_cnpj_contratante", "==", cpf_user)
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })


            db.collection("orcamentos")
                .onSnapshot((snap) => {
                    console.log(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })
        }
    }, [])

    const arr = [
        {
            "etapas_pagamento": [
                {
                    "data_pag": "2023-02-13T12:20",
                    "meio_pag": null,
                    "valor_pag": "50000.00",
                    "status_pag": false,
                    "descricao_pag": "Assinatura do Contrato"
                },
                {
                    "status_pag": false,
                    "valor_pag": "25000.00",
                    "data_pag": "2023-03-13T18:00",
                    "meio_pag": null,
                    "descricao_pag": "Finalização e Concretagem da Laje"
                },
                {
                    "data_pag": "2023-04-14T18:00",
                    "meio_pag": null,
                    "status_pag": false,
                    "valor_pag": "15000.00",
                    "descricao_pag": "Finalização do Telhado, Finalização do Reboco com Instalação das Esquadrias"
                },
                {
                    "descricao_pag": "Entrega da Obra",
                    "valor_pag": "15000.00",
                    "meio_pag": null,
                    "data_pag": "2023-05-15T18:00",
                    "status_pag": false
                }
            ],
            "gerenciamento": true,
            "endereco_servico": {
                "bairro": "Jardim Aprazível",
                "complemento": "",
                "principal": true,
                "cidade": "Monte Aprazível",
                "referencia": null,
                "apelido": "Casa Jardim Aprazível",
                "tipo_logradouro": "Rua",
                "estado": "SP",
                "numero": "90",
                "criado": null,
                "cep": "15150-000",
                "adicionais": {
                    "jardim": false,
                    "piscina": false
                },
                "logradouro": "Washington Luiz"
            },
            "contratado": "Engeart - Projetos Prontos",
            "cargo_representante": "CEO",
            "endereco_contratante": {
                "principal": true,
                "estado": "SP",
                "cep": "15150-000",
                "referencia": null,
                "numero": "90",
                "adicionais": {
                    "piscina": false,
                    "jardim": false
                },
                "criado": null,
                "bairro": "Jardim Aprazível",
                "apelido": "Casa Jardim Aprazível",
                "complemento": "",
                "cidade": "Monte Aprazível",
                "logradouro": "Washington Luiz",
                "tipo_logradouro": "Rua"
            },
            "tipo_servico": "Prestação de Serviços",
            "endereco_contratado": {
                "bairro": "Centro",
                "cidade": "Monte Aprazível",
                "estado": "SP",
                "cep": "15150000",
                "numero": "1046",
                "logradouro": "Rua Duque de Caxias"
            },
            "observacoes_privadas": [
                "O novo valor mínimo agora é de R$ 90.000,00.",
                " O valor minimo passado inicial mente foi de R$ 100.000,00 (Desatualizado)"
            ],
            "expedicao": {
                "seconds": 1675697214,
                "nanoseconds": 837000000
            },
            "etapas_servico": [
                "Locação da Obra",
                "Perfuração e Concretagem de estacas",
                "Abertura e Concretagem de Viga Baldame",
                "Levantamento e Revestimento de Alvenaria de Embasamento",
                "Impermeabilização das paredes",
                "Levantamento da Alvenaria",
                "Intalação de Forro/Laje",
                "Instalação de Revestimentos",
                "Assentamento de Pisos",
                "Instalação de Esquadrias",
                "Pintura da Obra",
                "Instalações Hidráulicas",
                "Instalações Elétricas",
                "Intalações de Louças e Metais",
                "Instalação de Acabamentos",
                "Limpeza e Entrega da Obra"
            ],
            "categoria_servico": "Obra e Serviço",
            "representante_contratado": "Douglas Pereira da Silva",
            "observacoes": [
                "Valor com Obra e Materiais Novos",
                "Em caso de abatimento de valor em materiais será realizado um memorial descritivo dos bens a serem abatidos do valor final."
            ],
            "status": "pendente",
            "aprovacao_orcamento": null,
            "descricao_servico": "Execução de uma construção residêncial unfamiliar popular em área rural.",
            "tipo_execucao": "presencial",
            "requisitos_servico": [
                "Responder perante o CONTRATANTE, pela execução e entrega do serviço de reforma residencial em área rural.",
                "Assumir, na qualidade de autoria, a responsabilidade técnica pelas especificações feitas, atendendo prontamente às exigências, modificações e esclarecimentos que forem necessários bem como intermediar as partes fornecedor/cliente quando houver algum problema.",
                "Fornece um arquivo com as plantas, detalhes relativos ao desenvolvimento do projeto e memorial descritivo ao CONTRATANTE.",
                "Entregar, da forma e no prazo ajustados, a obra descrita neste contrato, em caso de eventual atraso no prazo de entrega da obra será necessário um novo objeto de contrato constando uma reprogramação físico financeira para enquadramento de entrega da obra.",
                "Realizar, com a devida dedicação e seriedade e em cumprimento dos detalhes, projetos e especificações, os serviços necessários à conclusão da obra.",
                "Respeitar as normas técnicas e as condições de segurança aplicáveis à espécie de trabalho prestado.",
                "Fornecer toda a mão-de-obra necessária à execução e conclusão da obra, da forma e no prazo ajustados.",
                "Cumprir todas as determinações impostas pelas autoridades públicas competentes, referentes a estes serviços.",
                "Reparar os defeitos e as desconformidades eventualmente apresentadas pela obra em relação ao projeto, às normas e às instruções recebidas.",
                "Manter sigilosas, mesmo após findo este contrato, as informações privilegiadas de qualquer natureza às quais tenha acesso em virtude da execução desta obra.",
                "Providenciar, em boa qualidade, todos os materiais, os equipamentos e demais meios necessários à correta execução da obra."
            ],
            "cpf_cnpj_contratado": "32858181000188",
            "rg_contratante": null,
            "titulo_servico": "Contrução Residêncial Popular Térrea",
            "compras": true,
            "etapas_sem_garantia": [
                "             Desgaste natural, deformação do material e variação da textura da madeira.",
                "Defeitos em equipamentos e aparelhos cobertos pelo fabricante.",
                "Rachadura formadas.",
                "Danos causados pela luz solar.",
                "Quaisquer defeitos decorrentes de modificação após a conclusão do projeto.",
                "Perdas ou danos decorrentes de desastres naturais, ou sinistros."
            ],
            "objeto_contratado": "empresarial",
            "contratante": "Audria Martins Tridico Junqueira",
            "numero_representante_contratado": "E284603369595RP",
            "acompanhamento": true,
            "validade_orcamento": "2023-02-13T12:18",
            "metodo_pagamento": null,
            "area_servico": "55",
            "token": "E1675697153662FC",
            "email": "audriaj@bol.com.br",
            "telefone": "(17) 99771-0673",
            "cpf_cnpj_contratante": "12165189802"
        },
        {
            "rg_contratante": null,
            "observacoes_privadas": [
                "Valor minimo determinado foi de R$ 95.000,00 (Desatualizado)",
                "O novo valor minimo determinado agora é de R$ 90.000,00"
            ],
            "etapas_servico": [
                "Trocar Contra Piso",
                " Assentamento de Piso",
                " Assentamento de Revestimento",
                " Trocar Reboco (600m2)",
                " Amarração de ferro de travamento nas paredes",
                " Construção de 3 Banheiros",
                " Troca 4 Vitrôs (150 x 1,00)",
                " Instalação de 3 novos Vitrôs de Banheiro",
                " Instalação de 3 novas Portas de Banheiro",
                " Assentar 1 Porta de Lavanderia",
                " Refazer o madeiramento da área",
                " Trocar caibro do beiral",
                " Fazer 56 m² de calçada",
                " Fazer 28 m de muro de arrimo para calçada",
                " Pintura Interna",
                " Pintura Externa",
                "Fazer Instalação de Esgoto dos 3 Banheiros",
                " Fazer Elétrica dos 3 Banheiros",
                "Fazer Instalação de Água  Fria dos 3 Banheiros"
            ],
            "descricao_servico": "Execução de Reforma de Uma Residência Localizada em Área Rural",
            "tipo_execucao": "presencial",
            "etapas_sem_garantia": [
                "Desgaste natural, deformação do material e variação da textura da madeira.",
                "Defeitos em equipamentos e aparelhos cobertos pelo fabricante.",
                "Rachadura formadas.",
                "Danos causados pela luz solar.",
                "Quaisquer defeitos decorrentes de modificação após a conclusão do projeto.",
                "Perdas ou danos decorrentes de desastres naturais, ou sinistros."
            ],
            "objeto_contratado": "empresarial",
            "cargo_representante": "CEO",
            "endereco_contratante": {
                "logradouro": "Washington Luiz",
                "numero": "90",
                "bairro": "Jardim Aprazível",
                "principal": true,
                "referencia": null,
                "tipo_logradouro": "Rua",
                "criado": null,
                "adicionais": {
                    "jardim": false,
                    "piscina": false
                },
                "complemento": "",
                "estado": "SP",
                "cep": "15150-000",
                "cidade": "Monte Aprazível",
                "apelido": "Casa Jardim Aprazível"
            },
            "aprovacao_orcamento": null,
            "categoria_servico": "Obra e Serviço",
            "etapas_pagamento": [
                {
                    "data_pag": "2023-02-13T11:58",
                    "valor_pag": "40000.00",
                    "meio_pag": null,
                    "status_pag": false,
                    "descricao_pag": "Assinatura do Contrato"
                },
                {
                    "descricao_pag": "Finalização da Troca do Reboco e Troca de Contrapiso",
                    "data_pag": "2023-03-31T18:00",
                    "meio_pag": null,
                    "valor_pag": "28000.00",
                    "status_pag": false
                },
                {
                    "meio_pag": null,
                    "data_pag": "2023-04-21T18:00",
                    "descricao_pag": "Finalização da Instalação de Pisos e Revestimentos",
                    "valor_pag": "15000.00",
                    "status_pag": false
                },
                {
                    "descricao_pag": "Entrega da Obra",
                    "valor_pag": "15000.00",
                    "data_pag": "2023-05-15T18:00",
                    "meio_pag": null,
                    "status_pag": false
                }
            ],
            "token": "E1675696226172FC",
            "observacoes": [
                "A ser definido o abatimento dos materiais já adquiridos por conta do CONTRATANTE no local da obra será especificado mediante a memorial descritivo de bens a serem abatidos no valor final a combinar."
            ],
            "validade_orcamento": "2023-02-13T11:56",
            "requisitos_servico": [
                "Responder perante o CONTRATANTE, pela execução e entrega do serviço de reforma residencial em área rural.",
                "Assumir, na qualidade de autoria, a responsabilidade técnica pelas especificações feitas, atendendo prontamente às exigências, modificações e esclarecimentos que forem necessários bem como intermediar as partes fornecedor/cliente quando houver algum problema.",
                "Fornece um arquivo com as plantas, detalhes relativos ao desenvolvimento do projeto e memorial descritivo ao CONTRATANTE.",
                "Entregar, da forma e no prazo ajustados, a obra descrita neste contrato, em caso de eventual atraso no prazo de entrega da obra será necessário um novo objeto de contrato constando uma reprogramação físico financeira para enquadramento de entrega da obra.",
                "Realizar, com a devida dedicação e seriedade e em cumprimento dos detalhes, projetos e especificações, os serviços necessários à conclusão da obra.",
                "Respeitar as normas técnicas e as condições de segurança aplicáveis à espécie de trabalho prestado.",
                "Fornecer toda a mão-de-obra necessária à execução e conclusão da obra, da forma e no prazo ajustados.",
                "Cumprir todas as determinações impostas pelas autoridades públicas competentes, referentes a estes serviços.",
                "Reparar os defeitos e as desconformidades eventualmente apresentadas pela obra em relação ao projeto, às normas e às instruções recebidas.",
                "Manter sigilosas, mesmo após findo este contrato, as informações privilegiadas de qualquer natureza às quais tenha acesso em virtude da execução desta obra.",
                "Providenciar, em boa qualidade, todos os materiais, os equipamentos e demais meios necessários à correta execução da obra."
            ],
            "contratado": "Engeart - Projetos Prontos",
            "expedicao": {
                "seconds": 1675696286,
                "nanoseconds": 602000000
            },
            "endereco_contratado": {
                "bairro": "Centro",
                "numero": "1046",
                "logradouro": "Rua Duque de Caxias",
                "estado": "SP",
                "cidade": "Monte Aprazível",
                "cep": "15150000"
            },
            "acompanhamento": true,
            "cpf_cnpj_contratado": "32858181000188",
            "titulo_servico": "Reforrma Residêncial Térrea",
            "telefone": "(17) 99771-0673",
            "status": "pendente",
            "metodo_pagamento": null,
            "tipo_servico": "Prestação de Serviços",
            "compras": true,
            "representante_contratado": "Douglas Pereira da Silva",
            "endereco_servico": {
                "adicionais": {
                    "jardim": false,
                    "piscina": false
                },
                "apelido": "Casa Jardim Aprazível",
                "logradouro": "Washington Luiz",
                "tipo_logradouro": "Rua",
                "estado": "SP",
                "numero": "90",
                "criado": null,
                "cep": "15150-000",
                "complemento": "",
                "referencia": null,
                "cidade": "Monte Aprazível",
                "principal": true,
                "bairro": "Jardim Aprazível"
            },
            "gerenciamento": true,
            "numero_representante_contratado": "E284603369595RP",
            "area_servico": "115",
            "cpf_cnpj_contratante": "12165189802",
            "contratante": "Audria Martins Tridico Junqueira",
            "email": "audriaj@bol.com.br"
        }
    ]

    function addObjFirestore(e) {
        e.preventDefault();

        arr.map((doc) => {
            db.collection("servicos")
                .add(doc)
                .then(() => alert("Sucesso!"))
        })
    }

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }


    String.prototype.extenso = function (c) { // Função para converter número no formato string para número por extenso.
        var ex = [
            ["zero", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"],
            ["dez", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"],
            ["cem", "cento", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"],
            ["mil", "milhão", "bilhão", "trilhão", "quadrilhão", "quintilhão", "sextilhão", "setilhão", "octilhão", "nonilhão", "decilhão", "undecilhão", "dodecilhão", "tredecilhão", "quatrodecilhão", "quindecilhão", "sedecilhão", "septendecilhão", "octencilhão", "nonencilhão"]
        ];
        var a, n, v, i, n = this.replace(c ? /[^,\d]/g : /\D/g, "").split(","), e = " e ", $ = "real", d = "centavo", sl;
        for (var f = n.length - 1, l, j = -1, r = [], s = [], t = ""; ++j <= f; s = []) {
            j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2));
            if (!(a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g), v = l % 3 ? [v.slice(0, l % 3)] : [], v = a ? v.concat(a) : v).length) continue;
            for (a = -1, l = v.length; ++a < l; t = "") {
                if (!(i = v[a] * 1)) continue;
                i % 100 < 20 && (t += ex[0][i % 100]) ||
                    i % 100 + 1 && (t += ex[1][(i % 100 / 10 >> 0) - 1] + (i % 10 ? e + ex[0][i % 10] : ""));
                s.push((i < 100 ? t : !(i % 100) ? ex[2][i == 100 ? 0 : i / 100 >> 0] : (ex[2][i / 100 >> 0] + e + t)) +
                    ((t = l - a - 2) > -1 ? " " + (i > 1 && t > 0 ? ex[3][t].replace("ão", "ões") : ex[3][t]) : ""));
            }
            a = ((sl = s.length) > 1 ? (a = s.pop(), s.join(" ") + e + a) : s.join("") || ((!j && (n[j + 1] * 1 > 0) || r.length) ? "" : ex[0][0]));
            a && r.push(a + (c ? (" " + (v.join("") * 1 > 1 ? j ? d + "s" : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is") : j ? d : $)) : ""));
        }
        return r.join(e);
    }

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Meus Orçamentos" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Meus Orçamentos <button onClick={(e) => addObjFirestore(e)}>Cadastrar</button></h1>
                            <div className='box-forms-singup'>
                                {
                                    (orcamentos && orcamentos.length)
                                        ?
                                        <div className='dados-pessoais-usuario'>
                                            <div className='box-requests w100 m-bot-20'>
                                                {
                                                    orcamentos.map((dadosContrato, index) => {
                                                        return (
                                                            <BudgetBox_single
                                                                key={index}
                                                                dadosContrato={dadosContrato}
                                                                user={props.user}
                                                                responsivoSize={props.responsivoSize}
                                                                setOrcamentoUnico={props.setOrcamentoUnico}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className='d-fx d-al-cen'>
                                            <div className='w65 p-g-25'>
                                                <h2 className='m-bot-20'>Hey!</h2>
                                                <div style={{ lineHeight: 1.5 }}>
                                                    <p className='fz-16'>Não foi possivel localizarmos nenhum contrato para o CPF do usuário logado :(</p>
                                                    <p className='fz-16'>Parece que o login foi realizado com o CPF de alguém que não seja o contratante principal!</p>
                                                    <div className='m-top-15 p-g-10' style={{ backgroundColor: "#f5f5f5", borderRadius: '6px' }}>
                                                        <p className='fz-14 m-bot-10'>O contratante principal já possui uma conta? Clique em sair e crie uma nova conta totalmente gratuita!</p>
                                                        <p className='fz-14'>Você é o contratante principal e está tendo problemas para visualizar seu contrato? Clique <Link to="/ouvidoria">aqui</Link>!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w35'>
                                                <img src={contractOkay} />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );

}

export default MyBudget;