import '../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineFolderView, AiOutlineFundView, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaRegEdit } from 'react-icons/fa';
import contractOkay from '../../../../../../../../images/icones/svg/contract-okay.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';

function PC_ADM_NegociarOrcamentos(props) {

    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);


    const [orcamentos, setOrcamentos] = useState([]);

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user) && props.user.representante) {
            db.collection("orcamentos")
                .where("numero_representante_contratado", "==", props.user.token_representante)
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })

            console.log(orcamentos)
        }
    }, [])

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Área do Administrador" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Negociação de Orçamentos</h1>
                            <div className='wrapper__boxes__budgets w100 d-fx d-fw-wp'>
                                <div className='wrapper__new__budget w50 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed"}}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Criar Novo Orçamento</h2>
                                    <p className='fz-14 m-bot-10' style={{ lineHeight: 1.3 }}>
                                        Nulla ea dolore eu elit cillum incididunt non voluptate amet eiusmod consequat voluptate. Excepteur aliquip aliquip proident reprehenderit ut reprehenderit nisi Lorem pariatur.
                                    </p>
                                    <Link to="/minha-conta/administrador/orcamentos/novo" class="button button-full button-primary tx-cen">Criar Orçamento</Link>
                                </div>
                                <div className='wrapper__new__budget w50 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed"}}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Negociação de Orçamentos</h2>
                                    <p className='fz-14 m-bot-10' style={{ lineHeight: 1.3 }}>
                                        Nulla ea dolore eu elit cillum incididunt non voluptate amet eiusmod consequat voluptate. Excepteur aliquip aliquip proident reprehenderit ut reprehenderit nisi Lorem pariatur.
                                    </p>
                                    <Link to="/minha-conta/administrador/orcamentos/negociacao" class="button button-full button-primary tx-cen" style={{ backgroundColor: '#dba700' }}>Negociar Orçamentos</Link>
                                </div>
                                <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-25 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed"}}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Histórico de Orçamentos</h2>
                                    <div className='box-forms-singup'>
                                        {
                                            (orcamentos && orcamentos.length)
                                                ?
                                                <div className='dados-pessoais-usuario'>
                                                    <div className='box-requests w100 m-bot-20'>
                                                        {
                                                            orcamentos.map((dadosContrato, index) => {
                                                                if (dadosContrato.status && dadosContrato.status === 'pendente') {
                                                                    return (
                                                                        <div>
                                                                            <h3 className='info-primary fz-16 text__status__bot'>Orçamentos Pendentes</h3>
                                                                            <motion.div
                                                                                key={index}
                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                animate={{ height: expandedSummary ? "auto" : expandedSummarySize }}
                                                                                className='description-page__content'
                                                                            >
                                                                                <div className={(expandedSummary) ? 'wrapper__request__single w100 p-g-10 box__admin is-open' : 'wrapper__request__single w100 p-g-10 box__admin'}>
                                                                                    {/* DADOS PRINCIPAIS */}
                                                                                    <div className='order__title__request p-top-10 p-bot-15 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                        <div className='order__item__request info-primary'>
                                                                                            <strong>
                                                                                                Orçamento {dadosContrato.token}
                                                                                            </strong>
                                                                                        </div>
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 460 && dadosContrato.expedicao && !isEmptyObject(dadosContrato.expedicao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da expedição
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.expedicao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 600 && dadosContrato.aprovacao && !isEmptyObject(dadosContrato.aprovacao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da aprovação
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.aprovacao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        <div className='order__item__request info-default'>
                                                                                            <div className={(dadosContrato.status === "aprovado") ? 'med-badge active-badge-success' : 'med-badge'} style={{ textTransform: 'capitalize' }}>
                                                                                                {
                                                                                                    dadosContrato.status
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <buttom className="order__item__request info-primary btn__show__request" onClick={() => setExpandedSummary(!expandedSummary)}>
                                                                                            <BsChevronDown className='fz-20' />
                                                                                        </buttom>
                                                                                    </div>
    
                                                                                    {/* SUB DADOS */}
                                                                                    <div className='order__body__request'>
                                                                                        <div className='content__request d-fx d-fd-col'>
                                                                                            <motion.div
                                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                                animate={{ height: expandedSubSummary ? "auto" : "70px" }}
                                                                                                className='description-page__content'
                                                                                            >
                                                                                                <div className={(expandedSubSummary) ? 'wrapper__order__request order-content p-g-15 open' : 'wrapper__order__request order-content p-g-15'}> {/* open */}
                                                                                                    <div className='order__title__request w100 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                                        <div className='delivery__order__type info-primary'>
                                                                                                            <strong>Dados do Orçamento</strong>
                                                                                                        </div>
                                                                                                        {
                                                                                                            (props.responsivoSize && props.responsivoSize > 450 && dadosContrato.validade_orcamento && dadosContrato.validade_orcamento.length)
                                                                                                                ?
    
                                                                                                                <div className='delivery__order__date info-default'>
                                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                                        Vencimento da Proposta
                                                                                                                    </small>
                                                                                                                    <strong className='d-bk tx-norm fz-13'>
                                                                                                                        {
                                                                                                                            new Date(dadosContrato.validade_orcamento).toLocaleDateString("pt-BR")
                                                                                                                        }
                                                                                                                    </strong>
                                                                                                                </div>
                                                                                                                : null
                                                                                                        }
                                                                                                        <buttom className='delivery__order__action info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                                                                                            <BsChevronDown className='fz-20' />
                                                                                                        </buttom>
                                                                                                    </div>
                                                                                                    <div className='delivery__body__request m-top-20'>
                                                                                                        <div className='wrapper__itens__request m-top-20 d-fx d-fw-wp d-ju-sp-bt'>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratado</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratado && dadosContrato.contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Empresa:</strong> {dadosContrato.contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratado && dadosContrato.cpf_cnpj_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratado.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.representante_contratado && dadosContrato.representante_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Nome do Representante:</strong> {dadosContrato.representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.numero_representante_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Código do Representante:</strong> {dadosContrato.numero_representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_servico && dadosContrato.tipo_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratado && !isEmptyObject(dadosContrato.endereco_contratado))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratado.logradouro}, ${dadosContrato.endereco_contratado.numero}, ${dadosContrato.endereco_contratado.bairro} - ${dadosContrato.endereco_contratado.cidade}, - ${dadosContrato.endereco_contratado.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratado.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratante</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratante && dadosContrato.contratante.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Contratante:</strong> {dadosContrato.contratante}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratante.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.rg_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var rg = dadosContrato.rg_contratante.toString();
                                                                                                                                    var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                    return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratante && !isEmptyObject(dadosContrato.endereco_contratante))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratante.logradouro}, ${dadosContrato.endereco_contratante.numero}, ${dadosContrato.endereco_contratante.bairro} - ${dadosContrato.endereco_contratante.cidade}, - ${dadosContrato.endereco_contratante.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratante.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.email && props.user.email.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>E-mail:</strong> {props.user.email}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.telefone && props.user.telefone.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Telefone:</strong> {props.user.telefone}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#fff', borderRadius: '8px', border: "1px solid #ededed" }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Serviço</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_contratado && dadosContrato.tipo_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_execucao && dadosContrato.tipo_execucao.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Execução:</strong> {dadosContrato.tipo_execucao}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_servico && !isEmptyObject(dadosContrato.endereco_servico))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_servico.logradouro}, ${dadosContrato.endereco_servico.numero}, ${dadosContrato.endereco_servico.bairro} - ${dadosContrato.endereco_servico.cidade}, - ${dadosContrato.endereco_servico.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_servico.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.etapas_pagamento && dadosContrato.etapas_pagamento.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Quantidade de Etapas de Pagamento:</strong> {dadosContrato.etapas_pagamento.length}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Acompanhamento de Serviço:</strong> {(dadosContrato && dadosContrato.acompanhamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Gerênciamento de Serviço:</strong> {(dadosContrato && dadosContrato.gerenciamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Compra de Materiais do Serviço:</strong> {(dadosContrato && dadosContrato.compras) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.observacoes && dadosContrato.observacoes.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Observações:</strong>
                                                                                                                            <ul style={{ marginLeft: '20px' }}>
                                                                                                                                {
                                                                                                                                    dadosContrato.observacoes.map((obs) => {
                                                                                                                                        return (<li>{obs}</li>)
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </ul>
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='wrapper__infos__request p-g-15 m-top-25 m-bot-5'>
                                                                                                            <div className='info__single__request w100 d-fx'>
                                                                                                                <div className='icon__info__request info-primary left'>
                                                                                                                    <IoIosInformationCircleOutline className='fz-18' />
                                                                                                                </div>
                                                                                                                <div className='message__info__request w100 left m-lef-5'>
                                                                                                                    <p>
                                                                                                                        <span className='fz-13'>
                                                                                                                            <span className='tx-bold'>Atenção</span>: A Engeart utiliza o WhatsApp <span className='tx-bold'>apenas para se comunicar sobre pedidos e fazer vendas</span>, porém <span className='tx-bold'>não envia nenhum tipo de código</span> para confirmar ou acessar sua conta em nossos canais de venda. Não compartilhe seus dados com ninguém. Em caso de dúvidas, utilize nossos canais digitais de atendimento: <Link to='/atendimento'>Fale conosco</Link>.
                                                                                                                        </span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className='clear'></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </motion.div>
                                                                                            <div className='w100 d-fx m-top-15' style={{ justifyContent: 'flex-end' }}>
                                                                                                <Link to={`/minha-conta/meus-orcamentos/${dadosContrato.token}`} className='view-button view-btn-secondary' onClick={(e) => props.setOrcamentoUnico(dadosContrato)}>
                                                                                                    <AiOutlineFundView className='fz-24' /> Visualizar Orçamento
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </motion.div>
                                                                        </div>
                                                                    )
                                                                } else if (dadosContrato.status && dadosContrato.status === "vencido") {
                                                                    return (
                                                                        <div>
                                                                            <h3 className='info-primary fz-16 text__status__bot'>Orçamentos Vencidos</h3>
                                                                            <motion.div
                                                                                key={index}
                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                animate={{ height: expandedSummary ? "auto" : expandedSummarySize }}
                                                                                className='description-page__content'
                                                                            >
                                                                                <div className={(expandedSummary) ? 'wrapper__request__single w100 p-g-10 box__admin is-open' : 'wrapper__request__single w100 p-g-10 box__admin'}>
                                                                                    {/* DADOS PRINCIPAIS */}
                                                                                    <div className='order__title__request p-top-10 p-bot-15 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                        <div className='order__item__request info-primary'>
                                                                                            <strong>
                                                                                                Orçamento {dadosContrato.token}
                                                                                            </strong>
                                                                                        </div>
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 460 && dadosContrato.expedicao && !isEmptyObject(dadosContrato.expedicao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da expedição
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.expedicao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 600 && dadosContrato.aprovacao && !isEmptyObject(dadosContrato.aprovacao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da aprovação
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.aprovacao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        <div className='order__item__request info-default'>
                                                                                            <div className={(dadosContrato.status === "aprovado") ? 'med-badge active-badge-success' : 'med-badge'} style={{ textTransform: 'capitalize' }}>
                                                                                                {
                                                                                                    dadosContrato.status
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <buttom className="order__item__request info-primary btn__show__request" onClick={() => setExpandedSummary(!expandedSummary)}>
                                                                                            <BsChevronDown className='fz-20' />
                                                                                        </buttom>
                                                                                    </div>
    
                                                                                    {/* SUB DADOS */}
                                                                                    <div className='order__body__request'>
                                                                                        <div className='content__request d-fx d-fd-col'>
                                                                                            <motion.div
                                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                                animate={{ height: expandedSubSummary ? "auto" : "70px" }}
                                                                                                className='description-page__content'
                                                                                            >
                                                                                                <div className={(expandedSubSummary) ? 'wrapper__order__request order-content p-g-15 open' : 'wrapper__order__request order-content p-g-15'}> {/* open */}
                                                                                                    <div className='order__title__request w100 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                                        <div className='delivery__order__type info-primary'>
                                                                                                            <strong>Dados do Orçamento</strong>
                                                                                                        </div>
                                                                                                        {
                                                                                                            (props.responsivoSize && props.responsivoSize > 450 && dadosContrato.validade_orcamento && dadosContrato.validade_orcamento.length)
                                                                                                                ?
    
                                                                                                                <div className='delivery__order__date info-default'>
                                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                                        Vencimento da Proposta
                                                                                                                    </small>
                                                                                                                    <strong className='d-bk tx-norm fz-13'>
                                                                                                                        {
                                                                                                                            new Date(dadosContrato.validade_orcamento).toLocaleDateString("pt-BR")
                                                                                                                        }
                                                                                                                    </strong>
                                                                                                                </div>
                                                                                                                : null
                                                                                                        }
                                                                                                        <buttom className='delivery__order__action info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                                                                                            <BsChevronDown className='fz-20' />
                                                                                                        </buttom>
                                                                                                    </div>
                                                                                                    <div className='delivery__body__request m-top-20'>
                                                                                                        <div className='wrapper__itens__request m-top-20 d-fx d-fw-wp d-ju-sp-bt'>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratado</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratado && dadosContrato.contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Empresa:</strong> {dadosContrato.contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratado && dadosContrato.cpf_cnpj_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratado.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.representante_contratado && dadosContrato.representante_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Nome do Representante:</strong> {dadosContrato.representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.numero_representante_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Código do Representante:</strong> {dadosContrato.numero_representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_servico && dadosContrato.tipo_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratado && !isEmptyObject(dadosContrato.endereco_contratado))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratado.logradouro}, ${dadosContrato.endereco_contratado.numero}, ${dadosContrato.endereco_contratado.bairro} - ${dadosContrato.endereco_contratado.cidade}, - ${dadosContrato.endereco_contratado.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratado.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratante</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratante && dadosContrato.contratante.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Contratante:</strong> {dadosContrato.contratante}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratante.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.rg_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var rg = dadosContrato.rg_contratante.toString();
                                                                                                                                    var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                    return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratante && !isEmptyObject(dadosContrato.endereco_contratante))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratante.logradouro}, ${dadosContrato.endereco_contratante.numero}, ${dadosContrato.endereco_contratante.bairro} - ${dadosContrato.endereco_contratante.cidade}, - ${dadosContrato.endereco_contratante.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratante.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.email && props.user.email.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>E-mail:</strong> {props.user.email}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.telefone && props.user.telefone.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Telefone:</strong> {props.user.telefone}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#fff', borderRadius: '8px', border: "1px solid #ededed" }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Serviço</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_contratado && dadosContrato.tipo_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_execucao && dadosContrato.tipo_execucao.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Execução:</strong> {dadosContrato.tipo_execucao}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_servico && !isEmptyObject(dadosContrato.endereco_servico))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_servico.logradouro}, ${dadosContrato.endereco_servico.numero}, ${dadosContrato.endereco_servico.bairro} - ${dadosContrato.endereco_servico.cidade}, - ${dadosContrato.endereco_servico.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_servico.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.etapas_pagamento && dadosContrato.etapas_pagamento.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Quantidade de Etapas de Pagamento:</strong> {dadosContrato.etapas_pagamento.length}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Acompanhamento de Serviço:</strong> {(dadosContrato && dadosContrato.acompanhamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Gerênciamento de Serviço:</strong> {(dadosContrato && dadosContrato.gerenciamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Compra de Materiais do Serviço:</strong> {(dadosContrato && dadosContrato.compras) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.observacoes && dadosContrato.observacoes.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Observações:</strong>
                                                                                                                            <ul style={{ marginLeft: '20px' }}>
                                                                                                                                {
                                                                                                                                    dadosContrato.observacoes.map((obs) => {
                                                                                                                                        return (<li>{obs}</li>)
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </ul>
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='wrapper__infos__request p-g-15 m-top-25 m-bot-5'>
                                                                                                            <div className='info__single__request w100 d-fx'>
                                                                                                                <div className='icon__info__request info-primary left'>
                                                                                                                    <IoIosInformationCircleOutline className='fz-18' />
                                                                                                                </div>
                                                                                                                <div className='message__info__request w100 left m-lef-5'>
                                                                                                                    <p>
                                                                                                                        <span className='fz-13'>
                                                                                                                            <span className='tx-bold'>Atenção</span>: A Engeart utiliza o WhatsApp <span className='tx-bold'>apenas para se comunicar sobre pedidos e fazer vendas</span>, porém <span className='tx-bold'>não envia nenhum tipo de código</span> para confirmar ou acessar sua conta em nossos canais de venda. Não compartilhe seus dados com ninguém. Em caso de dúvidas, utilize nossos canais digitais de atendimento: <Link to='/atendimento'>Fale conosco</Link>.
                                                                                                                        </span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className='clear'></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </motion.div>
                                                                                            <div className='w100 d-fx m-top-15' style={{ justifyContent: 'flex-end' }}>
                                                                                                <Link to={`/minha-conta/meus-orcamentos/${dadosContrato.token}`} className='view-button view-btn-secondary' onClick={(e) => props.setOrcamentoUnico(dadosContrato)}>
                                                                                                    <AiOutlineFundView className='fz-24' /> Visualizar Orçamento
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </motion.div>
                                                                        </div>
                                                                    )
                                                                } else if (dadosContrato.status && dadosContrato.status === "reprovado") {
                                                                    return (
                                                                        <div>
                                                                            <h3 className='info-primary fz-16 text__status__bot'>Orçamentos Reprovados</h3>
                                                                            <motion.div
                                                                                key={index}
                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                animate={{ height: expandedSummary ? "auto" : expandedSummarySize }}
                                                                                className='description-page__content'
                                                                            >
                                                                                <div className={(expandedSummary) ? 'wrapper__request__single w100 p-g-10 box__admin is-open' : 'wrapper__request__single w100 p-g-10 box__admin'}>
                                                                                    {/* DADOS PRINCIPAIS */}
                                                                                    <div className='order__title__request p-top-10 p-bot-15 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                        <div className='order__item__request info-primary'>
                                                                                            <strong>
                                                                                                Orçamento {dadosContrato.token}
                                                                                            </strong>
                                                                                        </div>
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 460 && dadosContrato.expedicao && !isEmptyObject(dadosContrato.expedicao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da expedição
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.expedicao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 600 && dadosContrato.aprovacao && !isEmptyObject(dadosContrato.aprovacao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da aprovação
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.aprovacao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        <div className='order__item__request info-default'>
                                                                                            <div className={(dadosContrato.status === "aprovado") ? 'med-badge active-badge-success' : 'med-badge'} style={{ textTransform: 'capitalize' }}>
                                                                                                {
                                                                                                    dadosContrato.status
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <buttom className="order__item__request info-primary btn__show__request" onClick={() => setExpandedSummary(!expandedSummary)}>
                                                                                            <BsChevronDown className='fz-20' />
                                                                                        </buttom>
                                                                                    </div>
    
                                                                                    {/* SUB DADOS */}
                                                                                    <div className='order__body__request'>
                                                                                        <div className='content__request d-fx d-fd-col'>
                                                                                            <motion.div
                                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                                animate={{ height: expandedSubSummary ? "auto" : "70px" }}
                                                                                                className='description-page__content'
                                                                                            >
                                                                                                <div className={(expandedSubSummary) ? 'wrapper__order__request order-content p-g-15 open' : 'wrapper__order__request order-content p-g-15'}> {/* open */}
                                                                                                    <div className='order__title__request w100 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                                        <div className='delivery__order__type info-primary'>
                                                                                                            <strong>Dados do Orçamento</strong>
                                                                                                        </div>
                                                                                                        {
                                                                                                            (props.responsivoSize && props.responsivoSize > 450 && dadosContrato.validade_orcamento && dadosContrato.validade_orcamento.length)
                                                                                                                ?
    
                                                                                                                <div className='delivery__order__date info-default'>
                                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                                        Vencimento da Proposta
                                                                                                                    </small>
                                                                                                                    <strong className='d-bk tx-norm fz-13'>
                                                                                                                        {
                                                                                                                            new Date(dadosContrato.validade_orcamento).toLocaleDateString("pt-BR")
                                                                                                                        }
                                                                                                                    </strong>
                                                                                                                </div>
                                                                                                                : null
                                                                                                        }
                                                                                                        <buttom className='delivery__order__action info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                                                                                            <BsChevronDown className='fz-20' />
                                                                                                        </buttom>
                                                                                                    </div>
                                                                                                    <div className='delivery__body__request m-top-20'>
                                                                                                        <div className='wrapper__itens__request m-top-20 d-fx d-fw-wp d-ju-sp-bt'>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratado</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratado && dadosContrato.contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Empresa:</strong> {dadosContrato.contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratado && dadosContrato.cpf_cnpj_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratado.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.representante_contratado && dadosContrato.representante_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Nome do Representante:</strong> {dadosContrato.representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.numero_representante_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Código do Representante:</strong> {dadosContrato.numero_representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_servico && dadosContrato.tipo_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratado && !isEmptyObject(dadosContrato.endereco_contratado))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratado.logradouro}, ${dadosContrato.endereco_contratado.numero}, ${dadosContrato.endereco_contratado.bairro} - ${dadosContrato.endereco_contratado.cidade}, - ${dadosContrato.endereco_contratado.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratado.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratante</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratante && dadosContrato.contratante.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Contratante:</strong> {dadosContrato.contratante}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratante.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.rg_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var rg = dadosContrato.rg_contratante.toString();
                                                                                                                                    var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                    return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratante && !isEmptyObject(dadosContrato.endereco_contratante))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratante.logradouro}, ${dadosContrato.endereco_contratante.numero}, ${dadosContrato.endereco_contratante.bairro} - ${dadosContrato.endereco_contratante.cidade}, - ${dadosContrato.endereco_contratante.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratante.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.email && props.user.email.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>E-mail:</strong> {props.user.email}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.telefone && props.user.telefone.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Telefone:</strong> {props.user.telefone}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#fff', borderRadius: '8px', border: "1px solid #ededed" }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Serviço</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_contratado && dadosContrato.tipo_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_execucao && dadosContrato.tipo_execucao.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Execução:</strong> {dadosContrato.tipo_execucao}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_servico && !isEmptyObject(dadosContrato.endereco_servico))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_servico.logradouro}, ${dadosContrato.endereco_servico.numero}, ${dadosContrato.endereco_servico.bairro} - ${dadosContrato.endereco_servico.cidade}, - ${dadosContrato.endereco_servico.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_servico.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.etapas_pagamento && dadosContrato.etapas_pagamento.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Quantidade de Etapas de Pagamento:</strong> {dadosContrato.etapas_pagamento.length}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Acompanhamento de Serviço:</strong> {(dadosContrato && dadosContrato.acompanhamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Gerênciamento de Serviço:</strong> {(dadosContrato && dadosContrato.gerenciamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Compra de Materiais do Serviço:</strong> {(dadosContrato && dadosContrato.compras) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.observacoes && dadosContrato.observacoes.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Observações:</strong>
                                                                                                                            <ul style={{ marginLeft: '20px' }}>
                                                                                                                                {
                                                                                                                                    dadosContrato.observacoes.map((obs) => {
                                                                                                                                        return (<li>{obs}</li>)
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </ul>
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='wrapper__infos__request p-g-15 m-top-25 m-bot-5'>
                                                                                                            <div className='info__single__request w100 d-fx'>
                                                                                                                <div className='icon__info__request info-primary left'>
                                                                                                                    <IoIosInformationCircleOutline className='fz-18' />
                                                                                                                </div>
                                                                                                                <div className='message__info__request w100 left m-lef-5'>
                                                                                                                    <p>
                                                                                                                        <span className='fz-13'>
                                                                                                                            <span className='tx-bold'>Atenção</span>: A Engeart utiliza o WhatsApp <span className='tx-bold'>apenas para se comunicar sobre pedidos e fazer vendas</span>, porém <span className='tx-bold'>não envia nenhum tipo de código</span> para confirmar ou acessar sua conta em nossos canais de venda. Não compartilhe seus dados com ninguém. Em caso de dúvidas, utilize nossos canais digitais de atendimento: <Link to='/atendimento'>Fale conosco</Link>.
                                                                                                                        </span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className='clear'></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </motion.div>
                                                                                            <div className='w100 d-fx m-top-15' style={{ justifyContent: 'flex-end' }}>
                                                                                                <Link to={`/minha-conta/meus-orcamentos/${dadosContrato.token}`} className='view-button view-btn-secondary' onClick={(e) => props.setOrcamentoUnico(dadosContrato)}>
                                                                                                    <AiOutlineFundView className='fz-24' /> Visualizar Orçamento
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </motion.div>
                                                                        </div>
                                                                    )
                                                                } else if (dadosContrato.status && dadosContrato.status === 'aprovado') {
                                                                    return (
                                                                        <div>
                                                                            <h3 className='info-primary fz-16 text__status__bot'>Orçamentos Aprovados</h3>
                                                                            <motion.div
                                                                                key={index}
                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                animate={{ height: expandedSummary ? "auto" : expandedSummarySize }}
                                                                                className='description-page__content'
                                                                            >
                                                                                <div className={(expandedSummary) ? 'wrapper__request__single w100 p-g-10 box__admin is-open' : 'wrapper__request__single w100 p-g-10 box__admin'}>
                                                                                    {/* DADOS PRINCIPAIS */}
                                                                                    <div className='order__title__request p-top-10 p-bot-15 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                        <div className='order__item__request info-primary'>
                                                                                            <strong>
                                                                                                Orçamento {dadosContrato.token}
                                                                                            </strong>
                                                                                        </div>
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 460 && dadosContrato.expedicao && !isEmptyObject(dadosContrato.expedicao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da expedição
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.expedicao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 600 && dadosContrato.aprovacao && !isEmptyObject(dadosContrato.aprovacao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da aprovação
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.aprovacao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        <div className='order__item__request info-default'>
                                                                                            <div className={(dadosContrato.status === "aprovado") ? 'med-badge active-badge-success' : 'med-badge'} style={{ textTransform: 'capitalize' }}>
                                                                                                {
                                                                                                    dadosContrato.status
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <buttom className="order__item__request info-primary btn__show__request" onClick={() => setExpandedSummary(!expandedSummary)}>
                                                                                            <BsChevronDown className='fz-20' />
                                                                                        </buttom>
                                                                                    </div>
    
                                                                                    {/* SUB DADOS */}
                                                                                    <div className='order__body__request'>
                                                                                        <div className='content__request d-fx d-fd-col'>
                                                                                            <motion.div
                                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                                animate={{ height: expandedSubSummary ? "auto" : "70px" }}
                                                                                                className='description-page__content'
                                                                                            >
                                                                                                <div className={(expandedSubSummary) ? 'wrapper__order__request order-content p-g-15 open' : 'wrapper__order__request order-content p-g-15'}> {/* open */}
                                                                                                    <div className='order__title__request w100 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                                        <div className='delivery__order__type info-primary'>
                                                                                                            <strong>Dados do Orçamento</strong>
                                                                                                        </div>
                                                                                                        {
                                                                                                            (props.responsivoSize && props.responsivoSize > 450 && dadosContrato.validade_orcamento && dadosContrato.validade_orcamento.length)
                                                                                                                ?
    
                                                                                                                <div className='delivery__order__date info-default'>
                                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                                        Vencimento da Proposta
                                                                                                                    </small>
                                                                                                                    <strong className='d-bk tx-norm fz-13'>
                                                                                                                        {
                                                                                                                            new Date(dadosContrato.validade_orcamento).toLocaleDateString("pt-BR")
                                                                                                                        }
                                                                                                                    </strong>
                                                                                                                </div>
                                                                                                                : null
                                                                                                        }
                                                                                                        <buttom className='delivery__order__action info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                                                                                            <BsChevronDown className='fz-20' />
                                                                                                        </buttom>
                                                                                                    </div>
                                                                                                    <div className='delivery__body__request m-top-20'>
                                                                                                        <div className='wrapper__itens__request m-top-20 d-fx d-fw-wp d-ju-sp-bt'>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratado</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratado && dadosContrato.contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Empresa:</strong> {dadosContrato.contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratado && dadosContrato.cpf_cnpj_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratado.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.representante_contratado && dadosContrato.representante_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Nome do Representante:</strong> {dadosContrato.representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.numero_representante_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Código do Representante:</strong> {dadosContrato.numero_representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_servico && dadosContrato.tipo_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratado && !isEmptyObject(dadosContrato.endereco_contratado))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratado.logradouro}, ${dadosContrato.endereco_contratado.numero}, ${dadosContrato.endereco_contratado.bairro} - ${dadosContrato.endereco_contratado.cidade}, - ${dadosContrato.endereco_contratado.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratado.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratante</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratante && dadosContrato.contratante.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Contratante:</strong> {dadosContrato.contratante}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratante.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.rg_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var rg = dadosContrato.rg_contratante.toString();
                                                                                                                                    var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                    return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratante && !isEmptyObject(dadosContrato.endereco_contratante))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratante.logradouro}, ${dadosContrato.endereco_contratante.numero}, ${dadosContrato.endereco_contratante.bairro} - ${dadosContrato.endereco_contratante.cidade}, - ${dadosContrato.endereco_contratante.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratante.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.email && props.user.email.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>E-mail:</strong> {props.user.email}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.telefone && props.user.telefone.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Telefone:</strong> {props.user.telefone}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#fff', borderRadius: '8px', border: "1px solid #ededed" }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Serviço</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_contratado && dadosContrato.tipo_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_execucao && dadosContrato.tipo_execucao.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Execução:</strong> {dadosContrato.tipo_execucao}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_servico && !isEmptyObject(dadosContrato.endereco_servico))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_servico.logradouro}, ${dadosContrato.endereco_servico.numero}, ${dadosContrato.endereco_servico.bairro} - ${dadosContrato.endereco_servico.cidade}, - ${dadosContrato.endereco_servico.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_servico.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.etapas_pagamento && dadosContrato.etapas_pagamento.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Quantidade de Etapas de Pagamento:</strong> {dadosContrato.etapas_pagamento.length}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Acompanhamento de Serviço:</strong> {(dadosContrato && dadosContrato.acompanhamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Gerênciamento de Serviço:</strong> {(dadosContrato && dadosContrato.gerenciamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Compra de Materiais do Serviço:</strong> {(dadosContrato && dadosContrato.compras) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.observacoes && dadosContrato.observacoes.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Observações:</strong>
                                                                                                                            <ul style={{ marginLeft: '20px' }}>
                                                                                                                                {
                                                                                                                                    dadosContrato.observacoes.map((obs) => {
                                                                                                                                        return (<li>{obs}</li>)
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </ul>
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='wrapper__infos__request p-g-15 m-top-25 m-bot-5'>
                                                                                                            <div className='info__single__request w100 d-fx'>
                                                                                                                <div className='icon__info__request info-primary left'>
                                                                                                                    <IoIosInformationCircleOutline className='fz-18' />
                                                                                                                </div>
                                                                                                                <div className='message__info__request w100 left m-lef-5'>
                                                                                                                    <p>
                                                                                                                        <span className='fz-13'>
                                                                                                                            <span className='tx-bold'>Atenção</span>: A Engeart utiliza o WhatsApp <span className='tx-bold'>apenas para se comunicar sobre pedidos e fazer vendas</span>, porém <span className='tx-bold'>não envia nenhum tipo de código</span> para confirmar ou acessar sua conta em nossos canais de venda. Não compartilhe seus dados com ninguém. Em caso de dúvidas, utilize nossos canais digitais de atendimento: <Link to='/atendimento'>Fale conosco</Link>.
                                                                                                                        </span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className='clear'></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </motion.div>
                                                                                            <div className='w100 d-fx m-top-15' style={{ justifyContent: 'flex-end' }}>
                                                                                                <Link to={`/minha-conta/meus-orcamentos/${dadosContrato.token}`} className='view-button view-btn-secondary' onClick={(e) => props.setOrcamentoUnico(dadosContrato)}>
                                                                                                    <AiOutlineFundView className='fz-24' /> Visualizar Orçamento
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </motion.div>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div>
                                                                            <h3 className='info-primary fz-16 text__status__bot'>Orçamentos Diversos</h3>
                                                                            <motion.div
                                                                                key={index}
                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                animate={{ height: expandedSummary ? "auto" : expandedSummarySize }}
                                                                                className='description-page__content'
                                                                            >
                                                                                <div className={(expandedSummary) ? 'wrapper__request__single w100 p-g-10 box__admin is-open' : 'wrapper__request__single w100 p-g-10 box__admin'}>
                                                                                    {/* DADOS PRINCIPAIS */}
                                                                                    <div className='order__title__request p-top-10 p-bot-15 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                        <div className='order__item__request info-primary'>
                                                                                            <strong>
                                                                                                Orçamento {dadosContrato.token}
                                                                                            </strong>
                                                                                        </div>
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 460 && dadosContrato.expedicao && !isEmptyObject(dadosContrato.expedicao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da expedição
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.expedicao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 600 && dadosContrato.aprovacao && !isEmptyObject(dadosContrato.aprovacao))
                                                                                                ?
                                                                                                <div className='order__item__request info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Data da aprovação
                                                                                                    </small>
                                                                                                    <strong className='d-bk'>
                                                                                                        {
                                                                                                            dadosContrato.aprovacao.toDate().toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        <div className='order__item__request info-default'>
                                                                                            <div className={(dadosContrato.status === "aprovado") ? 'med-badge active-badge-success' : 'med-badge'} style={{ textTransform: 'capitalize' }}>
                                                                                                {
                                                                                                    dadosContrato.status
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <buttom className="order__item__request info-primary btn__show__request" onClick={() => setExpandedSummary(!expandedSummary)}>
                                                                                            <BsChevronDown className='fz-20' />
                                                                                        </buttom>
                                                                                    </div>
    
                                                                                    {/* SUB DADOS */}
                                                                                    <div className='order__body__request'>
                                                                                        <div className='content__request d-fx d-fd-col'>
                                                                                            <motion.div
                                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                                animate={{ height: expandedSubSummary ? "auto" : "70px" }}
                                                                                                className='description-page__content'
                                                                                            >
                                                                                                <div className={(expandedSubSummary) ? 'wrapper__order__request order-content p-g-15 open' : 'wrapper__order__request order-content p-g-15'}> {/* open */}
                                                                                                    <div className='order__title__request w100 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                                        <div className='delivery__order__type info-primary'>
                                                                                                            <strong>Dados do Orçamento</strong>
                                                                                                        </div>
                                                                                                        {
                                                                                                            (props.responsivoSize && props.responsivoSize > 450 && dadosContrato.validade_orcamento && dadosContrato.validade_orcamento.length)
                                                                                                                ?
    
                                                                                                                <div className='delivery__order__date info-default'>
                                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                                        Vencimento da Proposta
                                                                                                                    </small>
                                                                                                                    <strong className='d-bk tx-norm fz-13'>
                                                                                                                        {
                                                                                                                            new Date(dadosContrato.validade_orcamento).toLocaleDateString("pt-BR")
                                                                                                                        }
                                                                                                                    </strong>
                                                                                                                </div>
                                                                                                                : null
                                                                                                        }
                                                                                                        <buttom className='delivery__order__action info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                                                                                            <BsChevronDown className='fz-20' />
                                                                                                        </buttom>
                                                                                                    </div>
                                                                                                    <div className='delivery__body__request m-top-20'>
                                                                                                        <div className='wrapper__itens__request m-top-20 d-fx d-fw-wp d-ju-sp-bt'>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratado</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratado && dadosContrato.contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Empresa:</strong> {dadosContrato.contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratado && dadosContrato.cpf_cnpj_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratado.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.representante_contratado && dadosContrato.representante_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Nome do Representante:</strong> {dadosContrato.representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.numero_representante_contratado)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Código do Representante:</strong> {dadosContrato.numero_representante_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_servico && dadosContrato.tipo_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratado && !isEmptyObject(dadosContrato.endereco_contratado))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratado.logradouro}, ${dadosContrato.endereco_contratado.numero}, ${dadosContrato.endereco_contratado.bairro} - ${dadosContrato.endereco_contratado.cidade}, - ${dadosContrato.endereco_contratado.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratado.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratante</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.contratante && dadosContrato.contratante.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Contratante:</strong> {dadosContrato.contratante}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratante.toString();
                                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                                    } else {
                                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                                    }
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.rg_contratante)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            {
                                                                                                                                (() => {
                                                                                                                                    var rg = dadosContrato.rg_contratante.toString();
                                                                                                                                    var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                                    return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                                                                                })()
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_contratante && !isEmptyObject(dadosContrato.endereco_contratante))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratante.logradouro}, ${dadosContrato.endereco_contratante.numero}, ${dadosContrato.endereco_contratante.bairro} - ${dadosContrato.endereco_contratante.cidade}, - ${dadosContrato.endereco_contratante.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_contratante.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.email && props.user.email.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>E-mail:</strong> {props.user.email}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && props.user.telefone && props.user.telefone.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Telefone:</strong> {props.user.telefone}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#fff', borderRadius: '8px', border: "1px solid #ededed" }}>
                                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Serviço</span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Objeto de Contratação:</strong> {dadosContrato.objeto_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_contratado && dadosContrato.tipo_contratado.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Serviço:</strong> {dadosContrato.tipo_contratado}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Categoria de Serviço:</strong> {dadosContrato.categoria_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Descrição de Serviço:</strong> {dadosContrato.descricao_servico}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.tipo_execucao && dadosContrato.tipo_execucao.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Tipo de Execução:</strong> {dadosContrato.tipo_execucao}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.endereco_servico && !isEmptyObject(dadosContrato.endereco_servico))
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_servico.logradouro}, ${dadosContrato.endereco_servico.numero}, ${dadosContrato.endereco_servico.bairro} - ${dadosContrato.endereco_servico.cidade}, - ${dadosContrato.endereco_servico.estado}, CEP ${(() => {
                                                                                                                                var new_cep = dadosContrato.endereco_servico.cep.toString();
                                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                                            })()}`}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.etapas_pagamento && dadosContrato.etapas_pagamento.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Quantidade de Etapas de Pagamento:</strong> {dadosContrato.etapas_pagamento.length}
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Acompanhamento de Serviço:</strong> {(dadosContrato && dadosContrato.acompanhamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Gerênciamento de Serviço:</strong> {(dadosContrato && dadosContrato.gerenciamento) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                    <strong>Compra de Materiais do Serviço:</strong> {(dadosContrato && dadosContrato.compras) ? "Ativo" : "Não Incluso"}
                                                                                                                </span>
                                                                                                                {
                                                                                                                    (dadosContrato && dadosContrato.observacoes && dadosContrato.observacoes.length)
                                                                                                                        ?
                                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                                            <strong>Observações:</strong>
                                                                                                                            <ul style={{ marginLeft: '20px' }}>
                                                                                                                                {
                                                                                                                                    dadosContrato.observacoes.map((obs) => {
                                                                                                                                        return (<li>{obs}</li>)
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </ul>
                                                                                                                        </span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='wrapper__infos__request p-g-15 m-top-25 m-bot-5'>
                                                                                                            <div className='info__single__request w100 d-fx'>
                                                                                                                <div className='icon__info__request info-primary left'>
                                                                                                                    <IoIosInformationCircleOutline className='fz-18' />
                                                                                                                </div>
                                                                                                                <div className='message__info__request w100 left m-lef-5'>
                                                                                                                    <p>
                                                                                                                        <span className='fz-13'>
                                                                                                                            <span className='tx-bold'>Atenção</span>: A Engeart utiliza o WhatsApp <span className='tx-bold'>apenas para se comunicar sobre pedidos e fazer vendas</span>, porém <span className='tx-bold'>não envia nenhum tipo de código</span> para confirmar ou acessar sua conta em nossos canais de venda. Não compartilhe seus dados com ninguém. Em caso de dúvidas, utilize nossos canais digitais de atendimento: <Link to='/atendimento'>Fale conosco</Link>.
                                                                                                                        </span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className='clear'></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </motion.div>
                                                                                            <div className='w100 d-fx m-top-15' style={{ justifyContent: 'flex-end' }}>
                                                                                                <Link to={`/minha-conta/meus-orcamentos/${dadosContrato.token}`} className='view-button view-btn-secondary' onClick={(e) => props.setOrcamentoUnico(dadosContrato)}>
                                                                                                    <AiOutlineFundView className='fz-24' /> Visualizar Orçamento
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </motion.div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className='d-fx d-al-cen'>
                                                    <div className='w65 p-g-25'>
                                                        <h2 className='m-bot-20'>Hey!</h2>
                                                        <div style={{ lineHeight: 1.5 }}>
                                                            <p className='fz-16'>Não foi possivel localizarmos nenhum contrato para o CPF do usuário logado :(</p>
                                                            <p className='fz-16'>Parece que o login foi realizado com o CPF de alguém que não seja o contratante principal!</p>
                                                            <div className='m-top-15 p-g-10' style={{ backgroundColor: "#f5f5f5", borderRadius: '6px' }}>
                                                                <p className='fz-14 m-bot-10'>O contratante principal já possui uma conta? Clique em sair e crie uma nova conta totalmente gratuita!</p>
                                                                <p className='fz-14'>Você é o contratante principal e está tendo problemas para visualizar seu contrato? Clique <Link to="/ouvidoria">aqui</Link>!</p>
                                                            </div>
                                                        </div>

                                                        {/* <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                                                            <Link to="/" className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }}>
                                                                <span className=' fz-12'>Página Inícial</span>
                                                            </Link>
                                                        </div>


                                                        <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                                                            <Link to="/minha-conta/meus-orcamentos/123" className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }}>
                                                                <span className=' fz-12'>Teste Proposta</span>
                                                            </Link>
                                                        </div> */}
                                                    </div>
                                                    <div className='w35'>
                                                        <img src={contractOkay} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );

}

export default PC_ADM_NegociarOrcamentos;