import { motion } from 'framer-motion'; // FRAMER MOTION 
// import '../../../Assets/styles/css/Main.css'; // ESTILO GERAL
// import '../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE 
import Avvvatars from 'avvvatars-react' // AVATAR DO USUÁRIO

// IMPORTAÇÃO DE REACT ROUTER
import { Link, useMatch } from 'react-router-dom';

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'
import { useEffect, useState } from 'react';
// IMPORTAÇÃO DE IMAGENS
// import singUp from '../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent, GiTakeMyMoney } from 'react-icons/gi';
import { RiAdminLine } from 'react-icons/ri';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineHeart, AiOutlineCheck, AiOutlinePlusCircle } from 'react-icons/ai';
import { MdOtherHouses, MdMoneyOff } from 'react-icons/md';
import { FaFileContract } from 'react-icons/fa';
import { GoChecklist } from 'react-icons/go';
import { isEmptyObject } from 'jquery';
import "./Assets/Styles/Css/PC-Sidebar.css";
import toast from 'react-hot-toast';

function PC_Sidebar(props) {
    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSummaryAdmin, setExpandedSummaryAdmin] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSummaryAcompanhamento, setExpandedSummaryAcompanhamento] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [page, setPage] = useState(null);
    var url_atual = window.location.href;
    var url_explode = url_atual.split('/');

    return (
        <div className='box-form-singup w20 left' style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd', width: '18%', marginRight: '10px', height: '100%' }}>
            <div className='header-myaccount b-bot p-g-25 p-bot-0'>
                {
                    (props.user)
                        ?
                        <div className='d-fx d-ju-sp-bt d-al-cen'>
                            {
                                (props.user.photo && props.user.photo.length) ?
                                    <img className='img__user w40' src={props.user.photo} />
                                    :
                                    <div className='w40'>
                                        {
                                            (props.user.full_name)
                                                ?
                                                <Avvvatars value={props.user.full_name} size={48} border={true} borderSize={2} borderColor="#006DFF" />
                                                : null
                                        }
                                    </div>
                            }
                            <div className='infos-user-myaccount w60 tx-cen'>
                                <h3 className='tx-lig tx-grey-dark fz-16'>{props.user.nome}</h3>
                                {/* <a className='fz-16'><b>500</b> pts</a> */}
                            </div>
                        </div>
                        : null
                }
            </div>
            <div className='body-account'>
                <h2 className='fz-15 tx-grey-light m-lef-25 m-bot-10'>Minha Conta</h2>
                <ul className='d-fx d-fw-wp m-bot-20'>
                    <li className='w100 m-bot-5'>
                        <Link to="/minha-conta/meus-dados" className={(url_explode.length < 6 && url_explode[4] == 'meus-dados') ? 'w100 p-g-10 tx-lef fz-16 left active-account' : 'w100 p-g-10 tx-lef fz-16 left'}>
                            <span className='m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                <RiFileUserLine className='m-rig-5 fz-20' />
                                Meus Dados
                            </span>
                        </Link>
                    </li>

                    <li className='w100 m-bot-5'>
                        <Link to="/minha-conta/meus-pedidos" className={(url_explode.length < 6 && url_explode[4] == 'meus-pedidos') ? 'w100 p-g-10 tx-lef fz-16 left active-account' : 'w100 p-g-10 tx-lef fz-16 left'}>
                            <span className='m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                <GiPresent className='m-rig-5 fz-20' />
                                Meus Pedidos
                            </span>
                        </Link>
                    </li>

                    <motion.div
                        style={{ transformOrigin: "top", overflow: "hidden" }}
                        animate={{ height: expandedSummary ? "auto" : "40px" }}
                        className='description-page__content'
                    >
                        <li className='w100 m-bot-5 d-fx d-al-cen d-fw-wp'>
                            <Link to="/minha-conta/fidelidade" className={(url_explode.length <= 6 && url_explode[4] == 'fidelidade') ? 'w80 p-g-10 tx-lef fz-16 d-fx d-al-cen active-account' : 'w80 p-g-10 tx-lef fz-16 d-fx d-al-cen'}>
                                <span className='w100 m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                    <BsCashCoin className='m-rig-5 fz-20' />
                                    Com Você
                                </span>

                            </Link>
                            <buttom className="w20 fz-12 tx-rig p-rig-10" onClick={() => setExpandedSummary(!expandedSummary)} style={{ cursor: 'pointer' }}>
                                <BsChevronDown />
                            </buttom>
                            <div className='clear'></div>
                            {/* <buttom className="w20 right fz-12">
                            <BsChevronDown />
                        </buttom> */}
                            <ul className='submenu__account active-submenu d-fx d-fw-wp w100 m-lef-30'>
                                <li className='item__single__submenu__account w100'>
                                    <Link to="/minha-conta/fidelidade/vantagens" className={(url_explode.length == 6 && url_explode[5] == 'vantagens') ? 'w100 p-g-10 tx-lef fz-16 left active-submenu' : 'w100 p-g-10 tx-lef fz-16 left'}>
                                        <span className='m-lef-20 d-fx d-al-cen'>
                                            Vantagens
                                        </span>
                                    </Link>
                                </li>
                                <li className='item__single__submenu__account w100'>
                                    <Link to="/minha-conta/fidelidade/trocas" className={(url_explode.length == 6 && url_explode[5] == 'trocas') ? 'w100 p-g-10 tx-lef fz-16 left active-submenu' : 'w100 p-g-10 tx-lef fz-16 left'}>
                                        <span className='m-lef-20 d-fx d-al-cen'>
                                            Troque
                                        </span>
                                    </Link>
                                </li>
                                <li className='item__single__submenu__account w100'>
                                    <Link to="/minha-conta/fidelidade/pontos" className={(url_explode.length == 6 && url_explode[5] == 'pontos') ? 'w100 p-g-10 tx-lef fz-16 left active-submenu' : 'w100 p-g-10 tx-lef fz-16 left'}>
                                        <span className='m-lef-20 d-fx d-al-cen'>
                                            Meus Pontos
                                        </span>
                                    </Link>
                                </li>
                                <li className='item__single__submenu__account w100'>
                                    <Link to="/minha-conta/fidelidade/duvidas" className={(url_explode.length == 6 && url_explode[5] == 'duvidas') ? 'w100 p-g-10 tx-lef fz-16 left active-submenu' : 'w100 p-g-10 tx-lef fz-16 left'}>
                                        <span className='m-lef-20 d-fx d-al-cen'>
                                            Dúvidas
                                        </span>
                                    </Link>
                                </li>
                            </ul>

                        </li>
                    </motion.div>

                    <li className='w100 m-bot-5'>
                        <Link to="/favoritos" className='w100 p-g-10 tx-lef fz-16 left'>
                            <span className='m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                <AiOutlineHeart className='m-rig-5 fz-20' />
                                Favoritos
                            </span>
                        </Link>
                    </li>

                    <li className='w100 m-bot-5'>
                        <Link to="/criar-projeto" className='w100 p-g-10 tx-lef fz-16 left'>
                            <span className='m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                <MdOtherHouses className='m-rig-5 fz-20' />
                                Meus Projetos
                            </span>
                        </Link>
                    </li>

                    <li className='w100 m-bot-5'>
                        <Link to="/minha-conta/meus-contratos" className={(url_explode.length <= 6 && url_explode[4] == 'meus-contratos') ? 'w100 p-g-10 tx-lef fz-16 left active-account' : 'w100 p-g-10 tx-lef fz-16 left'}>
                            <span className='m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                <FaFileContract className='m-rig-5 fz-20' />
                                Meus Contratos
                            </span>
                        </Link>
                    </li>

                    <li className='w100 m-bot-5'>
                        <Link to="/minha-conta/meus-orcamentos" className={(url_explode.length <= 6 && url_explode[4] == 'meus-orcamentos') ? 'w100 p-g-10 tx-lef fz-16 left active-account' : 'w100 p-g-10 tx-lef fz-16 left'}>
                            <span className='m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                <MdMoneyOff className='m-rig-5 fz-20' />
                                Meus Orçamentos
                            </span>
                        </Link>
                    </li>

                    <li className='w100 m-bot-5'>
                        <Link to="/minha-conta/acompanhamento" className={(url_explode[4] == 'acompanhamento') ? 'w100 p-g-10 tx-lef fz-16 left active-account' : 'w100 p-g-10 tx-lef fz-16 left'}>
                            <span className='m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                <GoChecklist className='m-rig-5 fz-20' />
                                Acompanhamentos
                            </span>
                        </Link>
                    </li>

                    {/*
                    <motion.div
                        style={{ transformOrigin: "top", overflow: "hidden" }}
                        animate={{ height: (expandedSummaryAcompanhamento || (url_explode.length >= 6 && url_explode[4] == 'acompanhamento')) ? "auto" : "40px" }}
                        className='description-page__content'
                    >
                        <li className='w100 m-bot-5 d-fx d-al-cen d-fw-wp'>
                            <div className={(url_explode.length <= 5 && url_explode[4] == 'acompanhamento') ? 'w100 m-bot-5 d-fx d-al-cen d-fw-wp active-account' : "w100 m-bot-5 d-fx d-al-cen d-fw-wp"}>
                                <Link to="/minha-conta/acompanhamento" className='w80 p-g-10 tx-lef fz-16 d-fx d-al-cen'>
                                    <span className='w100 m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                        <GoChecklist className='m-rig-5 fz-20' />
                                        Acompanhamento
                                    </span>
                                </Link>
                                <buttom className={(expandedSummaryAcompanhamento) ? "w20 fz-12 active__options__admin" : "w20 fz-12"} onClick={() => setExpandedSummaryAcompanhamento(!expandedSummaryAcompanhamento)} style={{ cursor: 'pointer' }}>
                                    <BsChevronDown />
                                </buttom>
                                <div className='clear'></div>
                            </div>
                            <ul className='submenu__account active-submenu d-fx d-fw-wp w100 m-lef-30'>
                                <li className='item__single__submenu__account w100'>
                                    <Link
                                        to="/minha-conta/acompanhamento/obras"
                                        className={(url_explode.length >= 6 && url_explode[5] == 'obras') ? 'w100 p-g-10 tx-lef fz-16 left active-submenu' : 'w100 p-g-10 tx-lef fz-16 left'}
                                        style={{ cursor: 'not-allowed' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toast.error("Esta página está em desenvolvimento!");
                                        }}
                                    >
                                        <span className='m-lef-20 d-fx d-al-cen fz-14'>
                                            Obras
                                        </span>
                                    </Link>
                                </li>
                                <li className='item__single__submenu__account w100'>
                                    <Link
                                        to="/minha-conta/acompanhamento/projetos"
                                        className={(url_explode.length >= 6 && url_explode[5] == 'projetos') ? 'w100 p-g-10 tx-lef fz-16 left active-submenu' : 'w100 p-g-10 tx-lef fz-16 left'}
                                        style={{ cursor: 'not-allowed' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toast.error("Esta página está em desenvolvimento!");
                                        }}
                                    >
                                        <span className='m-lef-20 d-fx d-al-cen fz-14'>
                                            Projetos
                                        </span>
                                    </Link>
                                </li>
                                <li className='item__single__submenu__account w100'>
                                    <Link
                                        to="/minha-conta/acompanhamento/decoracoes"
                                        className={(url_explode.length >= 6 && url_explode[5] == 'decoracoes') ? 'w100 p-g-10 tx-lef fz-16 left active-submenu' : 'w100 p-g-10 tx-lef fz-16 left'}
                                        style={{ cursor: 'not-allowed' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toast.error("Esta página está em desenvolvimento!");
                                        }}
                                    >
                                        <span className='m-lef-20 d-fx d-al-cen fz-14'>
                                            Decorações
                                        </span>
                                    </Link>
                                </li>
                            </ul>

                        </li>
                    </motion.div>
                    */}

                    {/* ÁREA DO ADMINISTRADOR */}
                    {
                        (props.user && !isEmptyObject(props.user) && props.user.representante)
                            ?
                            <motion.div
                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                animate={{ height: (expandedSummaryAdmin || (url_explode.length >= 6 && url_explode[4] == 'administrador')) ? "auto" : "40px" }}
                                className='description-page__content'
                            >
                                <li className='w100 m-bot-5 d-fx d-al-cen d-fw-wp'>
                                    {/* <div className={(url_explode.length <= 5 && url_explode[4] == 'administrador') ? 'w100 m-bot-5 d-fx d-al-cen d-fw-wp active-account' : "w100 m-bot-5 d-fx d-al-cen d-fw-wp"}> */}
                                    <div
                                        className={(() => {
                                            if (url_explode.length <= 5 && url_explode[4] == 'administrador') {
                                                return 'w100 d-fx d-al-cen d-fw-wp active-account'
                                            } else if (url_explode.length >= 6 && url_explode[4] == 'administrador') {
                                                return 'w100 d-fx d-al-cen d-fw-wp p-rlt active-line-son'
                                            } else {
                                                return "w100 d-fx d-al-cen d-fw-wp"
                                            }
                                        })()}
                                    >
                                        <Link to="/minha-conta/administrador" className='w80 p-g-10 tx-lef fz-16 d-fx d-al-cen'>
                                            <span className='w100 m-lef-10 d-fx d-al-cen tx-bold fz-14'>
                                                <RiAdminLine className='m-rig-5 fz-20' />
                                                Administrador
                                            </span>

                                        </Link>
                                        <buttom className={(expandedSummaryAdmin) ? "w20 fz-12 active__options__admin" : "w20 fz-12"} onClick={() => setExpandedSummaryAdmin(!expandedSummaryAdmin)} style={{ cursor: 'pointer' }}>
                                            <BsChevronDown />
                                        </buttom>
                                        <div className='clear'></div>
                                    </div>
                                    <ul
                                        className='submenu__account active-submenu d-fx d-fw-wp w100 m-lef-30'
                                    >
                                        {/* <li className='item__single__submenu__account w100 m-bot-5 d-fx d-al-cen d-fw-wp'>
                                            <div
                                                className={(() => {
                                                    if (url_explode.length <= 5 && url_explode[4] == 'administrador') {
                                                        return 'w100 d-fx d-al-cen d-fw-wp active-account'
                                                    } else if (url_explode.length >= 6 && url_explode[4] == 'administrador') {
                                                        return 'w100 d-fx d-al-cen d-fw-wp p-rlt active-line-son'
                                                    } else {
                                                        return "w100 d-fx d-al-cen d-fw-wp"
                                                    }
                                                })()}
                                            >
                                                <Link
                                                    to="/minha-conta/administrador/orcamentos"
                                                    className={(() => {
                                                        if (url_explode.length <= 6 && url_explode[5] == 'orcamentos') {
                                                            return 'w100 p-g-10 tx-lef fz-16 left active-submenu'
                                                        } else if (url_explode.length >= 7 && url_explode[5] == 'orcamentos') {
                                                            return 'w100 p-g-10 tx-lef fz-16 left p-rlt active-line-son'
                                                        } else {
                                                            return "w100 p-g-10 tx-lef fz-16 left"
                                                        }
                                                    })()}
                                                >
                                                    <span className='m-lef-20 d-fx d-al-cen fz-14'>
                                                        Orçamentos
                                                    </span>
                                                </Link>
                                                <buttom className={(expandedSummaryAdmin) ? "w20 fz-12 active__options__admin" : "w20 fz-12"} onClick={() => setExpandedSummaryAdmin(!expandedSummaryAdmin)} style={{ cursor: 'pointer' }}>
                                                    <BsChevronDown />
                                                </buttom>
                                                <div className='clear'></div>
                                            </div>
                                            <motion.div
                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                animate={{ height: "0px" }}
                                                className='submenu__account active-submenu d-fx d-fw-wp w100 m-lef-30'>
                                                <li className='item__single__submenu__account w100 sub__sub__menu p-rlt'>
                                                    <Link to="/minha-conta/administrador/orcamentos/novo" className={(url_explode.length >= 6 && url_explode[5] == 'orcamentos' && url_explode[6] == 'novo') ? 'w100 p-g-10 tx-lef fz-16 left active-submenu' : 'w100 p-g-10 tx-lef fz-16 left'}>
                                                        <span className='m-lef-20 d-fx d-al-cen fz-14'>
                                                            Criar Novo
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li className='item__single__submenu__account w100 p-rlt'>
                                                    <Link to="/" 
                                                    className={(url_explode.length >= 6 && url_explode[5] == 'orcamentos' && url_explode[6] == 'negociar') ? 'w100 p-g-10 tx-lef fz-16 left active-submenu' : 'w100 p-g-10 tx-lef fz-16 left'}
                                                    style={{ cursor: 'not-allowed' }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        toast.error("Esta página está em desenvolvimento!");
                                                    }}>
                                                        <span className='m-lef-20 d-fx d-al-cen fz-14'>
                                                            Negociar
                                                        </span>
                                                    </Link>
                                                </li>
                                            </motion.div>
                                        </li> */}
                                        <li className='item__single__submenu__account w100'>
                                            <Link
                                                to="/minha-conta/administrador/orcamentos"
                                                className={(() => {
                                                    if (url_explode.length <= 6 && url_explode[5] == 'orcamentos') {
                                                        return 'w100 p-g-10 tx-lef fz-16 left active-submenu'
                                                    } else if (url_explode.length >= 7 && url_explode[5] == 'orcamentos') {
                                                        return 'w100 p-g-10 tx-lef fz-16 left p-rlt active-line-son'
                                                    } else {
                                                        return "w100 p-g-10 tx-lef fz-16 left"
                                                    }
                                                })()}
                                            >
                                                <span className='m-lef-20 d-fx d-al-cen fz-14'>
                                                    Orçamentos
                                                </span>
                                            </Link>
                                        </li>
                                        <li className='item__single__submenu__account w100'>
                                            <Link
                                                to="/minha-conta/administrador/usuarios"
                                                className={(() => {
                                                    if (url_explode.length <= 6 && url_explode[5] == 'usuarios') {
                                                        return 'w100 p-g-10 tx-lef fz-16 left active-submenu'
                                                    } else if (url_explode.length >= 7 && url_explode[5] == 'usuarios') {
                                                        return 'w100 p-g-10 tx-lef fz-16 left p-rlt active-line-son'
                                                    } else {
                                                        return "w100 p-g-10 tx-lef fz-16 left"
                                                    }
                                                })()}
                                            >
                                                <span className='m-lef-20 d-fx d-al-cen fz-14'>
                                                    Clientes
                                                </span>
                                            </Link>
                                        </li>
                                        <li className='item__single__submenu__account w100'>
                                            <Link
                                                to="/minha-conta/administrador/projetos"
                                                className={(() => {
                                                    if (url_explode.length <= 6 && url_explode[5] == 'projetos') {
                                                        return 'w100 p-g-10 tx-lef fz-16 left active-submenu'
                                                    } else if (url_explode.length >= 7 && url_explode[5] == 'projetos') {
                                                        return 'w100 p-g-10 tx-lef fz-16 left p-rlt active-line-son'
                                                    } else {
                                                        return "w100 p-g-10 tx-lef fz-16 left"
                                                    }
                                                })()}
                                            >
                                                <span className='m-lef-20 d-fx d-al-cen fz-14'>
                                                    Projetos
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </motion.div>
                            : null
                    }
                </ul>
            </div>
        </div>
    );
}

export default PC_Sidebar;