// TODO: SENHA DO JORGINHO: OQJikBcJ
import '../styles/css/Main.css';
import { useEffect, useMemo, useState } from 'react';
import InputMask from "react-input-mask"; // MASCARA DE INPUT

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../firebase.js';

// IMPORTAÇÃO DE REACT ROUTER
import { Link, useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE ICONES
import { HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri';
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';

import emailjs from '@emailjs/browser';

function FormSingUp(props) {

    let navigate = useNavigate();

    const [password, setPassword] = useState();
    const [passLength, setPassLength] = useState(false);
    const [passLetter, setPassLetter] = useState(false);
    const [passNumber, setPassNumber] = useState(false);
    const [passSequence, setPassSequence] = useState(false);
    const [passCharacters, setPassCharacters] = useState(false);
    const [passValid, setPassValid] = useState(false);
    const [passStateView, setPassStateView] = useState(false);

    // HOOKS - CPF
    const [CPF, setCPF] = useState();
    const [CPFMessage, setCPFMessage] = useState([]);
    const [CPFValid, setCPFValid] = useState(false);

    // HOOKS - NOME
    const [name, setName] = useState();
    const [nameMessage, setNameMessage] = useState([]);
    const [nameValid, setNameValid] = useState(false);

    // HOOKS - EMAIL
    const [email, setEmail] = useState();
    const [emailMessage, setEmailMessage] = useState([]);
    const [emailValid, setEmailValid] = useState(false);

    // HOOKS - TELEFONE
    const [phone, setPhone] = useState();
    const [phoneMessage, setPhoneMessage] = useState([]);
    const [phoneValid, setPhoneValid] = useState(false);

    // HOOKS - findelidade / comunicação
    const [fidelidade, setFidelidade] = useState(false);
    const [comunicacaoGeral, setComunicacaoGeral] = useState(false);
    const [comunicacaoWhats, setComunicacaoWhats] = useState(false);
    const [comunicacaoEmail, setComunicacaoEmail] = useState(false);
    const [comunicacaoTelefone, setComunicacaoTelefone] = useState(false);

    // HOOKS - estado civil
    const [toggleOpenOptions, setToggleOpenOptions] = useState(false);
    const [estadoCivis, setestadoCivis] = useState([
        {
            titulo: 'Solteiro(a)',
            descricao: 'Pessoa que nunca se casou, independente se possui um relacionamento estável ou não.',
            slug: 'solteiro'
        },
        {
            titulo: 'Casado(a)',
            descricao: 'Pessoa que possui uma união matrimonial através do casamento civil, independente do regime de bens adotado.',
            slug: 'casado'
        },
        {
            titulo: 'Separado(a)',
            descricao: 'Pessoa não vive mais com o companheiro, porém ainda não está divorciado. A pessoa que está separada, pode decretar a separação judicial, para acabar com os deveres da sociedade conjugal.',
            slug: 'separado'
        },
        {
            titulo: 'Divorciado(a)',
            descricao: 'Pessoa que teve homologado seu pedido de divórcio através da justiça, ou de uma escritura.',
            slug: 'divorciado'
        },
        {
            titulo: 'Viúvo(a)',
            descricao: 'Pessoa que o cônjuge (marido ou esposa) faleceu.',
            slug: 'viuvo'
        }
    ]);
    const [estadoCivilSelecionado, setestadoCivilSelecionado] = useState([]);
    const [novaCategoriaStatus, setNovaCategoriaStatus] = useState(false);

    // HOOKS - estado civil - nome do conjuge
    const [nomeConjuge, setNomeConjuge] = useState('');

    // HOOKS - observaçoes do cliente
    const [observacoes, setObservacoes] = useState('');

    // HOOKS - Profissional da Contrução Civil
    const [profissional, setProfissional] = useState(false);

    // HOOKS - Profissional da Contrução Civil    
    const [disabledSendSingup, setDisabledSendSingup] = useState(false);

    useEffect(() => {
        if (props.user) {
            navigate("/");
        }
    }, []);

    function criarConta(e) {
        // DADOS BÁSICOS PESSOAIS DO USUARIO
        let cpf = CPF;
        let nome = name;
        let telefone = phone;
        let senha = (props.criador && props.criador === 'representante') ? (() => {
            var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var passwordLength = 8;
            var newPassword = "";

            for (var i = 0; i < passwordLength; i++) {
                var randomNumber = Math.floor(Math.random() * chars.length);
                newPassword += chars.substring(randomNumber, randomNumber + 1);
            }

            return newPassword;

        })() : password;
        var newsletter = comunicacaoGeral;
        var news_email = comunicacaoEmail;
        var news_sms = comunicacaoTelefone;
        var news_whats = comunicacaoWhats;

        /*
        */

        function getGivenName() {
            var full_name_func = nome.split(' ');
            return full_name_func[0];

        }

        function getFamilyName() {
            var full_name_func = nome.split(' ');
            var familyName = full_name_func.splice(0, 1);
            return full_name_func.join(' ');
        }

        // CRIAR CONTA - FIREBASE
        /*
            */
        auth.createUserWithEmailAndPassword(email, senha)
            .then((authUser) => {
                authUser.user.updateProfile({
                    displayName: nome
                }).then(() => {
                    let newCPFClear = cpf.match(/\d/g).join("");
                    db.collection('usuarios').doc(authUser.user.uid).set({
                        nome: getGivenName(),
                        given_name: getGivenName(),
                        family_name: getFamilyName(),
                        full_name: nome,
                        cpf: newCPFClear,
                        rg: null,
                        email: email,
                        emailVerified: authUser.user.emailVerified,
                        telefone: telefone,
                        termos_condicoes_cadastro: true,
                        fidelidade: fidelidade,
                        newsletter: newsletter,
                        newsletter_sms: news_sms,
                        newsletter_whatsapp: news_whats,
                        newsletter_email: news_email,
                        profissional_contrucao: profissional,
                        estado_civil: estadoCivilSelecionado,
                        nome_conjuge: nomeConjuge,
                        observacoes: observacoes
                    })
                        .then((data) => {
                            const dados_cadastrados = {
                                nome: getGivenName(),
                                given_name: getGivenName(),
                                family_name: getFamilyName(),
                                full_name: getGivenName() + " " + getFamilyName(),
                                cpf: newCPFClear,
                                rg: null,
                                email: email,
                                emailVerified: authUser.user.emailVerified,
                                telefone: telefone,
                                termos_condicoes_cadastro: true,
                                fidelidade: fidelidade,
                                newsletter: newsletter,
                                newsletter_sms: news_sms,
                                newsletter_whatsapp: news_whats,
                                newsletter_email: news_email,
                                profissional_contrucao: profissional,
                                estado_civil: estadoCivilSelecionado,
                                nome_conjuge: nomeConjuge,
                                observacoes: observacoes
                            }

                            if (props.criador && props.criador === 'representante') {
                                props.setDadosCliente([dados_cadastrados]);
                                props.setTokenCliente([authUser.user.uid]);

                                // ENVIO DE EMAIL DE AVISO DE CONTA CRIADA PARA O NOVO USUARIO
                                var nome = authUser.user.displayName;
                                var primeiroNome = nome.split(' ')[0];

                                var templateParams = {
                                    remetente: "Engeart Projetos Prontos",
                                    destinatario: nome,
                                    destinatarioPrimeiroNome: primeiroNome,
                                    destinatarioEmail: email,
                                    password: senha,
                                }
                                console.log(senha);

                                emailjs.send("service_engeart", "template_mbl6rvt", templateParams, "Q8jfIP3ih8m9_S2yv")
                                    .then(() => {
                                        toast.success('A conta de ' + primeiroNome + ' foi criada com sucesso!');
                                        setDisabledSendSingup(false);
                                        setCPF('');
                                        setCPFMessage([]);
                                        setName('');
                                        setNameValid(false);
                                        setEmail('');
                                        setEmailValid(false);
                                        setPhone('');
                                        setestadoCivilSelecionado('');
                                        setNomeConjuge('');
                                        setObservacoes('');
                                        setPassword('');
                                        setProfissional(false);
                                        setFidelidade(false);
                                        setComunicacaoGeral(false);
                                        setComunicacaoEmail(false);
                                        setComunicacaoTelefone(false);
                                        setComunicacaoWhats(false);

                                    }, (err) => {
                                        setDisabledSendSingup(false);
                                        setCPF('');
                                        setCPFMessage([]);
                                        setName('');
                                        setNameValid(false);
                                        setEmail('');
                                        setEmailValid(false);
                                        setPhone('');
                                        setestadoCivilSelecionado('');
                                        setNomeConjuge('');
                                        setObservacoes('');
                                        setPassword('');
                                        setProfissional(false);
                                        setFidelidade(false);
                                        setComunicacaoGeral(false);
                                        setComunicacaoEmail(false);
                                        setComunicacaoTelefone(false);
                                        setComunicacaoWhats(false);

                                        console.log(err);
                                        toast.error('A conta de ' + primeiroNome + ' foi criada com sucesso!\nMas ocorreu um erro ao enviar o e-mail ao usuário!');
                                    })
                            } else {
                                if (!sessionStorage.getItem("dados_usuario")) {
                                    if (!props.user || !props.user.length) {
                                        sessionStorage.setItem('dados_usuario', JSON.stringify(dados_cadastrados));
                                        props.setUser(dados_cadastrados);
                                    }
                                } else {
                                    let dadosUsuario = JSON.parse(sessionStorage.getItem('dados_usuario'));
                                    props.setUser(dadosUsuario);
                                }
                                var nome = authUser.user.displayName.split(' ');
                                toast.success('Seja bem vindo a Engeart, ' + nome[0] + '!');
                                navigate("/");
                            }
                        })
                        .catch((err) => {
                            console.log(err.message);
                            // alert();
                            toast.error('Ocorreu um erro!');
                        })
                })
                    .catch((err) => {
                        console.log(err.message);
                        toast.error('Ocorreu um erro!');
                    })
            }).catch((error) => {
                console.log(error);
                if (error.code == "auth/email-already-in-use") {
                    toast.error("O endereço de e-mail já está em uso");
                } else if (error.code == "auth/invalid-email") {
                    toast.error("O endereço de e-mail não é válido");
                } else if (error.code == "auth/operation-not-allowed") {
                    toast.error("Operação não permitida");
                } else if (error.code == "auth/weak-password") {
                    toast.error("A senha é muito fraca");
                } else {
                    toast.error('Ocorreu um erro!');
                }

            });
    }

    function validaFomulario(e) {
        e.preventDefault();
        setDisabledSendSingup(true);

        if (!CPFValid) {
            toast.error('O CPF não é válido!');

            setDisabledSendSingup(false);
        } else if (!nameValid) {
            toast.error('O Nome não é válido!');

            setDisabledSendSingup(false);
        } else if (!emailValid) {
            toast.error('O e-mail não é válido!');

            setDisabledSendSingup(false);
        } else if (!phoneValid) {
            toast.error('O telefone não é válido!');

            setDisabledSendSingup(false);
        } else if ((!props.criador || props.criador !== 'representante') && !passValid) {
            toast.error('A senha não é válida!');

            setDisabledSendSingup(false);
        } else {
            criarConta(e);
        }
    }

    useMemo(() => {
        if (password) { // VERIFICAÇÃO DE TAMANHO DE SENHA
            if (password.length >= 8 && password.length <= 20) {
                setPassLength(true);
            } else if (password.length < 8 || password.length > 20) {
                setPassLength(false);
            }
        }

        // VERIFICAÇÃO DE LETRAS EM SENHA
        var padrao = /[A-Za-z]/gi;

        if (password && password.match(padrao)) {
            setPassLetter(true);
        } else {
            setPassLetter(false);
        }

        // VERIFICAÇÃO DE NUMEROS EM SENHA
        var padrao = /[0-9]/;

        if (password && password.match(padrao)) {
            setPassNumber(true);
        } else {
            setPassNumber(false);
        }

        // VERIFICAÇÃO DE SEQUENCIA EM SENHA
        var padrao = '^(?=.*\d)(?=.*[a-zA-Z])(?!.*[\W_\x7B-\xFF]).{4,15}$';
        // var padrao = '"^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,}$"';

        if (password && password.toLowerCase().match(padrao)) {
            setPassSequence(true);
        } else {
            setPassSequence(false);
        }

        // VERIFICAÇÃO DE CARACTERES EM SENHA
        var padrao = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

        if (password && password.match(padrao)) {
            setPassCharacters(true);
        } else {
            setPassCharacters(false);
        }
    }, [password])

    useMemo(async () => { // CPF ok
        // PEGAR TODOS OS USUARIOS
        if (CPF) {
            var newCPF = CPF.replace(".", "").replace(".", "").replace("-", "");

            if (newCPF.length == 11) {
                var er = /^[0-9]+$/;
                if (!er.test(newCPF)) {
                    // O CPF CONTEM OUTROS CARACTERES ALEM DE NUEMROS -> MOSTRAR MENSAGEM DE ERRO
                    setCPFMessage([{
                        'status': 'error',
                        'message': 'O CPF deve contem apenas números'
                    }])
                } else {
                    // O CPF CONTEM APENAS NÚMEROS -> PODEMOS CONTINUAR COM A VALIÇÃO SE ELE É VÁLIDO
                    var cpf = CPF;
                    // Codigo relativo ao video 3

                    function isCPF(cpf = 0) {
                        // console.log(cpf);
                        cpf = cpf.replace(/\.|-/g, "");
                        // console.log(cpf);
                        if (!validaPrimeiroDigito(cpf)) {
                            return false;
                        }
                        if (!validaSegundoDigito(cpf)) {
                            return false;
                        }
                        if (cpf.length != 11 ||
                            cpf == "00000000000" ||
                            cpf == "11111111111" ||
                            cpf == "22222222222" ||
                            cpf == "33333333333" ||
                            cpf == "44444444444" ||
                            cpf == "55555555555" ||
                            cpf == "66666666666" ||
                            cpf == "77777777777" ||
                            cpf == "88888888888" ||
                            cpf == "99999999999") {
                            return false;
                        }
                        return true;

                    }
                    var sum = 0;

                    function validaPrimeiroDigito(cpf = null) {
                        let fDigit = (sumFristDigit(cpf) * 10) % 11;
                        fDigit = (fDigit == 10 || fDigit == 11) ? 0 : fDigit;
                        if (fDigit != cpf[9])
                            return false
                        return true;
                    }
                    function validaSegundoDigito(cpf = null) {
                        let sDigit = (sumSecondDigit(cpf) * 10) % 11;
                        sDigit = (sDigit == 10 || sDigit == 11) ? 0 : sDigit;

                        if (sDigit != cpf[10])
                            return false
                        return true;
                    }


                    function sumFristDigit(cpf, position = 0, sum = 0) {
                        if (position > 9)
                            return 0;
                        return sum + sumFristDigit(cpf, position + 1, cpf[position] * ((cpf.length - 1) - position));
                    }


                    function sumSecondDigit(cpf, position = 0, sum = 0) {
                        if (position > 10)
                            return 0;
                        return sum + sumSecondDigit(cpf, position + 1, cpf[position] * ((cpf.length) - position));
                    }

                    if (!isCPF(cpf)) { // O CPF NÃO É VÁLIDO
                        setCPFMessage([{
                            'status': 'error',
                            'message': 'O CPF informado não é válido'
                        }])
                    } else { // O CPF É NUMÉRICO E VÁLIDO -> PODEMOS CONTINUAR COM A VALIDAÇÃO

                        // VALIDANDO CADASTRO EXISTENTE
                        const ref = db.collection('usuarios');
                        const snapshot = await ref.where('cpf', '==', newCPF).get();
                        if (snapshot.empty) {
                            // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
                            setCPFValid(true);
                            setCPFMessage([{
                                'status': 'success',
                                'message': 'O CPF informado é válido!'
                            }]);
                        } else {
                            // EXISTE UM email JÁ CADASTRADO NO BANCO DE DADOS -> MOSTRAR MENSAGEM DE ERRO
                            setCPFValid(false);
                            setCPFMessage([{
                                'status': 'error',
                                'message': 'O CPF informado já está vinculado a uma conta existente'
                            }])
                        }
                    }
                }
            }
            // if (value.length == 8) {
            //     console.log(value)
            // }

            // VERIFICANDO SE O CPF É VÁLIDO 
        }

    }, [CPF])

    useMemo(() => { // NOME OK
        if (name) {
            // VERIFICAÇÃO DE LETRAS EM SENHA
            var padrao = /[^a-zà-ú\s-]/gi;

            var valida_nome = name.match(padrao);

            if (valida_nome) {
                setNameValid(false);
                setNameMessage([{
                    'status': 'error',
                    'message': 'O nome deve conter apenas letras'
                }])
            } else {
                //O NOME É VÁLIDO CONTEM APENAS LETRAS -> VERIFICAR SE O NOME É COMPLETO
                var arrName = name.split(' ');
                if (arrName.length <= 1) {
                    // O USUARIO NÃO PASSOU O NOME COMPLETO
                    setNameValid(false);
                    setNameMessage([{
                        'status': 'error',
                        'message': 'O nome deve ser completo'
                    }])
                } else {
                    // O USUARIO PASSOU O NOME COMPLETO
                    setNameValid(true);
                    setNameMessage([{
                        'status': 'success',
                        'message': 'O nome informado é válido!'
                    }])
                }
            }
        }
    }, [name])

    useMemo(async () => { // E-mail OK
        if (email && email.length > 0) {
            function IsEmail(email) {
                var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                if (email == '' || !er.test(email)) { return false; }
                else { return true; }
            }
            if (!IsEmail(email)) {
                setEmailValid(false);
                setEmailMessage([{
                    'status': 'error',
                    'message': 'O e-mail informado não é válido'
                }])
            } else {
                // VALIDANDO CADASTRO EXISTENTE
                const ref = db.collection('usuarios');
                const snapshot = await ref.where('email', '==', email).get();
                if (snapshot.empty) {
                    // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
                    setEmailValid(true);
                    setEmailMessage([{
                        'status': 'success',
                        'message': 'O E-mail informado é válido!'
                    }]);
                } else {
                    // EXISTE UM email JÁ CADASTRADO NO BANCO DE DADOS -> MOSTRAR MENSAGEM DE ERRO
                    setEmailValid(false);
                    setEmailMessage([{
                        'status': 'error',
                        'message': 'O e-mail informado já está vinculado a uma conta existente'
                    }]);
                }

            }
        }

    }, [email])


    useMemo(() => {
        if (phone) {
            var phoneDDD = phone.replace(/([()])/g, '').replace("_", '').split(" ");

            if (phoneDDD[0].length == 2) { // VERIFICAR EXISTENCIA DO DDD
                function getStateFromDDD(ddd) {

                    let ddds = {
                        "AC": ["68"],
                        "AL": ["82"],
                        "AM": ["92", "97"],
                        "AP": ["96"],
                        "BA": ["71", "73", "74", "75", "77"],
                        "CE": ["85", "88"],
                        "DF": ["61"],
                        "ES": ["27", "28"],
                        "GO": ["62", "64"],
                        "MA": ["98", "99"],
                        "MG": ["31", "32", "33", "34", "35", "37", "38"],
                        "MS": ["67"],
                        "MT": ["65", "66"],
                        "PA": ["91", "93", "94"],
                        "PB": ["83"],
                        "PE": ["81", "87"],
                        "PI": ["86", "89"],
                        "PR": ["41", "42", "43", "44", "45", "46"],
                        "RJ": ["21", "22", "24"],
                        "RN": ["84"],
                        "RO": ["69"],
                        "RR": ["95"],
                        "RS": ["51", "53", "54", "55"],
                        "SC": ["47", "48", "49"],
                        "SE": ["79"],
                        "SP": ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        "TO": ["63"]
                    }

                    for (let index in ddds) {
                        if (ddds[index].includes(ddd)) {
                            return true;
                        }
                    }
                }

                var retornoDDD = getStateFromDDD(phoneDDD[0]);

                setPhoneValid(getStateFromDDD(phoneDDD[0]))

                if (!retornoDDD) {
                    setPhoneMessage([{
                        'status': 'error',
                        'message': 'O DDD informado não é válido'
                    }])
                } else {
                    setPhoneMessage([])
                }
            }

            var padrao = /(\d+)| /g;

            if (!phone.match(padrao)) {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([{
                    'status': 'error',
                    'message': 'O telefone deve conter apenas números'
                }]);
                console.log("InValido")
            } else {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([]);
            }
        }
    }, [phone])

    function validatePhone() {
        let newPhoneClear = phone.replace(/[^\d]+/g, '');
        if (newPhoneClear.length < 11) {
            setPhoneValid(false);
            setPhoneMessage([{
                'status': 'error',
                'message': 'O telefone deve conter o DDD'
            }]);
        } else {
            setPhoneValid(true);
            setPhoneMessage([]);
        }
    }

    function validatePassword() {
        $(".box-requisitos").slideUp();
        if (passLength && passLetter && passNumber && passSequence) {
            if (passCharacters) { // A SENHA POSSUI CARACTERES ENTÃO É UMA SENHA VÁLIDA
                setPassValid("strong");
            } else {
                setPassValid("default");
            }
        } else {
            setPassValid("");
        }
    }

    function alterarVisualizacaoSenha(el) {
        el.preventDefault();

        setPassStateView((prevState) => !prevState);
    }



    function typingSearchCategoria(e) {
        filterListCategoria(e.target.value);
    };

    const filterListCategoria = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.categoria").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    return (
        <>
            <div className={((props.responsivoSize && props.responsivoSize < 768) || (props.criador && props.criador === 'representante')) ? 'w100 p-g-25' : 'w50 p-g-25'} style={{ background: 'rgb(255 255 255 / 95%)', borderRadius: 8, border: '1px solid #ddd' }}>
                <h2 className='m-bot-20'>Criar Conta</h2>
                {
                    (!props.criador || props.criador !== 'representante')
                        ?
                        <div className='box-opts-acount'>
                            <button className='otp-acount w50-abs fz-16 active'>
                                <HiOutlineUser />
                                <span className='m-lef-5'>Para você</span>
                            </button>

                            {
                                (props.responsivoSize && props.responsivoSize > 380)
                                    ?
                                    < button className='otp-acount w50-abs fz-16 default' onClick={(e) => toast.error("O cadastro para empresas está em desenvolvimento")}>
                                        <HiOutlineUserGroup />
                                        <span className='m-lef-5'>Para sua empresa</span>
                                    </button>
                                    : null
                            }
                        </div>
                        : null
                }
                <div className='box-forms-singup'>
                    <form id="form__created__account__person" className='singup-person'>
                        <div className='box-input-single'> {/* CPF */}
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>CPF</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Analizar crédito</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            {
                                (() => {

                                    if (CPFValid && !CPFMessage) {
                                        return (
                                            <div className='p-rlt m-top-5'>
                                                <InputMask
                                                    id='cpf-cadastro-singup'
                                                    className="input-form w100 fz-16 valid 1"
                                                    mask="999.999.999-99"
                                                    placeholder='999.999.999-99'
                                                    value={CPF}
                                                    onChange={(ev) => setCPF(ev.target.value)}
                                                    style={{ paddingRight: '35px' }}
                                                />
                                                <button
                                                    className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%', background: 'none', cursor: 'pointer', }}
                                                    title="CPF válido!"
                                                >
                                                    <BsCheckCircleFill className='fz-18' style={{ color: '#5cb85c' }} />
                                                </button>
                                            </div>
                                        )
                                    } else {
                                        if (CPFMessage && CPFMessage.length > 0) {
                                            return (
                                                CPFMessage.map((infos) => {
                                                    if (infos.status == 'error') {
                                                        return (
                                                            <div className='p-rlt m-top-5'>
                                                                <InputMask
                                                                    id='cpf-cadastro-singup'
                                                                    className='input-form w100 fz-16 invalid 2'
                                                                    mask="999.999.999-99"
                                                                    placeholder='999.999.999-99'
                                                                    value={CPF}
                                                                    onChange={(ev) => setCPF(ev.target.value)}
                                                                    style={{ paddingRight: '35px' }}
                                                                />
                                                                <div
                                                                    className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%' }}
                                                                    title="CPF inválido!"
                                                                >
                                                                    <BsXCircleFill className='fz-18' style={{ color: '#b85c5c' }} />
                                                                </div>
                                                            </div>
                                                        )
                                                    } else if (infos.status == 'success') {
                                                        return (
                                                            <div className='p-rlt m-top-5'>
                                                                <InputMask
                                                                    id='cpf-cadastro-singup'
                                                                    className='input-form w100 fz-16 valid 3'
                                                                    mask="999.999.999-99"
                                                                    placeholder='999.999.999-99'
                                                                    value={CPF}
                                                                    onChange={(ev) => setCPF(ev.target.value)}
                                                                    style={{ paddingRight: '35px' }}
                                                                />
                                                                <div
                                                                    className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%', }}
                                                                    title="CPF válido!"
                                                                >
                                                                    <BsCheckCircleFill className='fz-18' style={{ color: '#5cb85c' }} />
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className='p-rlt m-top-5'>
                                                                <InputMask
                                                                    id='cpf-cadastro-singup'
                                                                    className='input-form w100 fz-16 4'
                                                                    mask="999.999.999-99"
                                                                    placeholder='999.999.999-99'
                                                                    value={CPF}
                                                                    onChange={(ev) => setCPF(ev.target.value)}
                                                                    style={{ paddingRight: '35px' }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            )
                                        } else {
                                            return (
                                                <InputMask id='cpf-cadastro-singup' className='input-form w100 fz-16 m-top-5 5' mask="999.999.999-99" placeholder='999.999.999-99' value={CPF} onChange={(ev) => setCPF(ev.target.value)} />
                                            )
                                        }
                                    }
                                })()
                            }
                            {/* <InputMask id='cpf-cadastro-singup' className='input-form w100 fz-16 m-top-5 valid 6' mask="999.999.999-99" placeholder='999.999.999-99' value={CPF} onChange={(ev) => setCPF(ev.target.value)} /> */}
                            {
                                (CPFMessage)
                                    ?
                                    CPFMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'> {/* NOME */}
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Nome Completo</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu Nome será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Saber como podemos te chamar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1l7kih-stack">
                                                            <div>
                                                                <div class="css-gqzso-divider ekzeljq0" style={{
                                                                    margin: '4px 0px',
                                                                    width: '15px',
                                                                    height: '2px',
                                                                    background: 'rgb(211, 210, 214)'
                                                                }}></div>
                                                            </div>
                                                            <div>
                                                                <p class="css-169bm6a-text-text-color--n500-text--kilo-heading--no-margin fz-13" style={{ color: 'rgb(136, 127, 135)', fontWeight: 'lighter' }}>Se você preferir, pode informar seu nome social com sobrenome, ok?</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>

                            <div className='p-rlt m-top-5'>
                                <input
                                    id="nome-cadastro-singup"
                                    className={(() => {
                                        if (name && !nameValid) {
                                            return ("input-form w100 fz-16 invalid");
                                        } else if (name && name.length && nameValid) {
                                            return ("input-form w100 fz-16 valid");
                                        } else {
                                            return ("input-form w100 fz-16");
                                        }
                                    })()}
                                    type='text'
                                    name="nome"
                                    placeholder='Seu Nome'
                                    value={name}
                                    onChange={(ev) => setName(ev.target.value)}
                                    style={{ paddingRight: '35px' }}
                                />
                                {
                                    (() => {
                                        if (name && !nameValid) {
                                            return (
                                                <div
                                                    className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%' }}
                                                    title="Nome inválido!"
                                                >
                                                    <BsXCircleFill className='fz-18' style={{ color: '#b85c5c' }} />
                                                </div>
                                            )
                                        } else if (name && name.length && nameValid) {
                                            return (
                                                <div
                                                    className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%' }}
                                                    title="Nome válido!"
                                                >
                                                    <BsCheckCircleFill className='fz-18' style={{ color: '#5cb85c' }} />
                                                </div>
                                            )
                                        } else {
                                            return;
                                        }
                                    })()
                                }
                            </div>
                            {
                                (nameMessage)
                                    ?
                                    nameMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'> {/* E-MAIL */}
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>E-mail</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu e-mail será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Receber sua nota fiscal</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Receber o status do seu pedido</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <div className='p-rlt m-top-5'>
                                <input
                                    id='email-cadastro-singup'
                                    className={(() => {
                                        if (email && !emailValid) {
                                            return ("input-form w100 fz-16 invalid");
                                        } else if (email && email.length && emailValid) {
                                            return ("input-form w100 fz-16 valid");
                                        } else {
                                            return ("input-form w100 fz-16");
                                        }
                                    })()}
                                    type='email'
                                    name="email"
                                    placeholder='seuemail@email.com'
                                    value={email}
                                    onChange={(ev) => setEmail(ev.target.value)}
                                    style={{ paddingRight: '35px' }}
                                />
                                {
                                    (() => {
                                        if (email && !emailValid) {
                                            return (
                                                <div
                                                    className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%' }}
                                                    title="E-mail inválido!"
                                                >
                                                    <BsXCircleFill className='fz-18' style={{ color: '#b85c5c' }} />
                                                </div>
                                            )
                                        } else if (email && email.length && emailValid) {
                                            return (
                                                <div
                                                    className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%' }}
                                                    title="E-mail válido!"
                                                >
                                                    <BsCheckCircleFill className='fz-18' style={{ color: '#5cb85c' }} />
                                                </div>
                                            )
                                        } else {
                                            return;
                                        }
                                    })()
                                }
                            </div>
                            {
                                (emailMessage)
                                    ?
                                    emailMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Telefone</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu telefone será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Garantir mais segurança a sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Contato rápido para informar sobre seu pedido</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Envio de novidade e ofertas, se você aceitar :)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <InputMask id='telefone-cadastro-singup' mask="(99) 99999-9999" placeholder='Insira seu telefone' className={(phone && phone.match('[0-9]') && !phoneValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} value={phone} onChange={(ev) => setPhone(ev.target.value)} onBlur={() => validatePhone()} />

                            {
                                (phoneMessage)
                                    ?
                                    phoneMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        {
                            (props.criador && props.criador === 'representante')
                                ?
                                <>
                                    <h3 className='b-top'>Informações Adicionais</h3>
                                    <div class="box-input-single m-top-20"> {/* ESTADO CIVIL */}
                                        <label>
                                            <div className='title-input d-fx d-al-cen p-rlt'>
                                                <span className='fz-12 m-rig-5'>Selecione o estado cívil do cliente</span>
                                            </div>
                                        </label>

                                        <div class="select-box m-top-5">
                                            <div class={toggleOpenOptions ? "options-container active" : "options-container"}>
                                                <div class="search-box">
                                                    <input className='input-form' type="text" placeholder="Busque aqui a opção desejada..." onKeyUp={(e) => typingSearchCategoria(e)} />
                                                </div>

                                                {
                                                    (estadoCivis && estadoCivis.length)
                                                        ?
                                                        estadoCivis.map((dadosCategorias, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    class={(estadoCivilSelecionado && !isEmptyObject(estadoCivilSelecionado) && estadoCivilSelecionado.slug === dadosCategorias.slug) ? "option categoria active" : "option categoria"}
                                                                    onClick={() => {
                                                                        setestadoCivilSelecionado({ slug: dadosCategorias.slug, titulo: dadosCategorias.titulo })
                                                                        setToggleOpenOptions(!toggleOpenOptions)
                                                                        setNovaCategoriaStatus(false);
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        class="radio"
                                                                        name={dadosCategorias.slug}
                                                                        value={dadosCategorias.titulo}
                                                                    />
                                                                    <label>
                                                                        {
                                                                            dadosCategorias.titulo
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </div>

                                            <div class="selected__option input-form" onClick={() => setToggleOpenOptions(!toggleOpenOptions)}>
                                                {
                                                    (estadoCivilSelecionado && !isEmptyObject(estadoCivilSelecionado) && estadoCivilSelecionado.titulo)
                                                        ?
                                                        estadoCivilSelecionado.titulo
                                                        : "Selecione o seu estado civil"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (estadoCivilSelecionado && !isEmptyObject(estadoCivilSelecionado) && estadoCivilSelecionado.slug && estadoCivilSelecionado.slug.length && estadoCivilSelecionado.slug === 'casado')
                                            ?
                                            <div className='box-input-single m-top-20'> {/* NOME DO CONJUGE */}
                                                <label>
                                                    <div className='title-input d-fx d-al-cen'>
                                                        <span className='fz-12 m-rig-5'>Nome do cônjuge</span>
                                                    </div>
                                                </label>
                                                <div className='p-rlt m-top-5'>
                                                    <input
                                                        id='conjuge-cadastro-singup'
                                                        className='input-form w100 fz-16'
                                                        type='conjuge'
                                                        name="conjuge"
                                                        placeholder='Nome do marido ou esposa'
                                                        value={nomeConjuge}
                                                        onChange={(ev) => setNomeConjuge(ev.target.value)}
                                                        style={{ paddingRight: '35px', resize: 'vertical' }}
                                                    />
                                                </div>
                                            </div>
                                            : null
                                    }
                                    <div className='box-input-single m-top-20'> {/* OBSERVAÇÕES */}
                                        <label>
                                            <div className='title-input d-fx d-al-cen'>
                                                <span className='fz-12 m-rig-5'>Observações</span>
                                            </div>
                                        </label>
                                        <div className='p-rlt m-top-5'>
                                            <textarea
                                                id='observacoes-cadastro-singup'
                                                className='input-form w100 fz-16'
                                                type='observacoes'
                                                name="observacoes"
                                                placeholder='O Cliente é profissional da construção civil'
                                                value={observacoes}
                                                maxLength={1000}
                                                onChange={(ev) => {
                                                    setObservacoes(ev.target.value);
                                                }}
                                                style={{ paddingRight: '35px', resize: 'vertical' }}
                                            >
                                            </textarea>
                                            <span style={{
                                                position: 'absolute',
                                                right: '15px',
                                                bottom: '5px',
                                                color: '#ccc',
                                                fontSize: '12px'
                                            }}><b style={{
                                                fontWeight: 'bold',
                                                color: '#999'
                                            }}>{observacoes.length}</b> / 1000</span>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='box-input-single m-top-20'> {/* SENHA */}
                                        <label>
                                            <div className='title-input d-fx d-al-cen'>
                                                <span className='fz-12 m-rig-5'>Senha</span>
                                                <span className='icon-info-add p-rlt'>
                                                    <IoIosInformationCircleOutline />
                                                    <div className='helper-input tx-black d-nn'>
                                                        <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                            <span class="css-1i1x8nz">
                                                                Sua senha será usada para:
                                                            </span>
                                                            <div class="css-1l7kih-stack">
                                                                <div>
                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                        <div>
                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Garantir mais segurança a sua conta</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                        <div>
                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                        <div>
                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Aprovar Serviços e Projetos Personalizados</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                        <div>
                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                        </div>
                                                    </div>{/*Helper*/}
                                                </span>
                                            </div>
                                        </label>
                                        <div className='p-rlt'>
                                            <input
                                                id='senha-cadastro-singup'
                                                className='input-form w100 fz-16 m-top-5'
                                                type={(passStateView) ? 'text' : 'password'}
                                                name="nome"
                                                placeholder='Insira sua senha'
                                                autoComplete='off'
                                                value={password}
                                                onChange={(ev) => setPassword(ev.target.value)}
                                                onBlur={() => validatePassword()}
                                                onFocus={() => {
                                                    $(".box-requisitos").slideDown();
                                                }}
                                            />
                                            <button className='btn__toggle__state__password p-abs' onClick={(el) => alterarVisualizacaoSenha(el)}>
                                                {
                                                    (passStateView)
                                                        ?
                                                        <RiEyeFill />
                                                        :
                                                        <RiEyeCloseLine />
                                                }
                                            </button>
                                        </div>
                                        {
                                            (password)
                                                ?
                                                <div>
                                                    {
                                                        (passValid)
                                                            ?
                                                            <div className='info-valid m-top-10 d-bk'>
                                                                {
                                                                    (() => {
                                                                        if (passValid == 'default') {
                                                                            return (
                                                                                <div className='d-fx d-al-cen'>
                                                                                    <span className='icon-invalid'><TiInfo /></span>
                                                                                    <span className='text-invalid m-lef-5'>A senha atende aos requisitos mínimos, mas pode ser mais seguro usar caracteres especiais (Ex.: @, -, _)</span>
                                                                                </div>
                                                                            )
                                                                        } else if (passValid == 'strong') {
                                                                            return (
                                                                                <div className='d-fx d-al-cen'>
                                                                                    <span className='icon-valid'><AiOutlineCheck /></span>
                                                                                    <span className='text-valid m-lef-5'>A senha é forte</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()
                                                                }
                                                            </div>
                                                            :
                                                            <div className='info-invalid m-top-10 d-bk'>
                                                                <div className='d-fx d-al-cen'>
                                                                    <span className='icon-invalid'><TiInfo /></span>
                                                                    <span className='text-invalid m-lef-5'>A senha não atende aos requisitos mínimos abaixo</span>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                                : null
                                        }
                                        <div
                                            className='box-requisitos w100 m-top-5 p-g-15 d-nn'
                                            style={{
                                                marginTop: '4px',
                                                marginBottom: '12px',
                                                boxShadow: 'rgba(60, 55, 56, 0.07) 0px 0px 0px 1px, rgba(60, 55, 56, 0.07) 0px 2px 2px 0px, rgba(60, 55, 56, 0.07) 0px 4px 4px 0px',
                                                width: '100%',
                                                padding: '16px',
                                                background: 'rgb(255, 255, 255)',
                                                borderRadius: '8px'
                                            }}
                                        >
                                            <h3 className='fz-12'>Sua senha deve ter:</h3>
                                            <ul className='m-top-10'>
                                                <li className={(passLength) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12 invalid'}>
                                                    <span className='icon-req-pass'>
                                                        {
                                                            (passLength)
                                                                ?
                                                                <AiOutlineCheck />
                                                                :
                                                                <AiOutlineClose />
                                                        }
                                                    </span>
                                                    <span className='text-req-pass m-lef-5'>De 8 a 20 caracteres*</span>
                                                </li>
                                                <li className={(passLetter) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12 invalid'}>
                                                    <span className='icon-req-pass'>
                                                        {
                                                            (passLetter)
                                                                ?
                                                                <AiOutlineCheck />
                                                                :
                                                                <AiOutlineClose />
                                                        }
                                                    </span>
                                                    <span className='text-req-pass m-lef-5'>Ao menos uma letra*</span>
                                                </li>
                                                <li className={(passNumber) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12 invalid'}>
                                                    <span className='icon-req-pass'>
                                                        {
                                                            (passNumber)
                                                                ?
                                                                <AiOutlineCheck />
                                                                :
                                                                <AiOutlineClose />
                                                        }
                                                    </span>
                                                    <span className='text-req-pass m-lef-5'>Ao menos um número*</span>
                                                </li>
                                                <li className={(passSequence) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12 invalid'}>
                                                    <span className='icon-req-pass'>
                                                        {
                                                            (passSequence)
                                                                ?
                                                                <AiOutlineCheck />
                                                                :
                                                                <AiOutlineClose />
                                                        }
                                                    </span>
                                                    <span className='text-req-pass m-lef-5'>Caracteres fora da sequência*</span>
                                                </li>
                                                <li className={(passCharacters) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12'}>
                                                    <span className='icon-req-pass'>
                                                        {
                                                            (passCharacters)
                                                                ?
                                                                <AiOutlineCheck />
                                                                :
                                                                <AiOutlinePlus />
                                                        }
                                                    </span>
                                                    <span className='text-req-pass m-lef-5'>Caracteres especiais (Ex.: @, -, _)</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='box-opts-add b-top'>
                                        <div
                                            className='termos-news-single d-fx w100'
                                            for="fidelidade-cadastro-singup"
                                            onClick={() => setFidelidade(!fidelidade)}
                                        >
                                            <input
                                                id='fidelidade-cadastro-singup'
                                                type='checkbox'
                                                name="fidelidade"
                                                checked={fidelidade}
                                            />
                                            <label className='infos-programa m-lef-10' style={{ cursor: 'pointer' }}>
                                                <h4>Participar do programa fidelidade</h4>
                                                <span className='small'>Cupons, pontos e vantagens para você</span>
                                            </label>
                                        </div>{/*termos-news-single*/}
                                        <div
                                            className='termos-news-single d-fx w100 m-top-20'
                                            for="newsletter-cadastro-singup"
                                            onClick={() => {
                                                setComunicacaoGeral(!comunicacaoGeral);
                                                setComunicacaoEmail(!comunicacaoGeral);
                                                setComunicacaoWhats(!comunicacaoWhats);
                                                setComunicacaoTelefone(!comunicacaoGeral);
                                            }}
                                        >
                                            <input
                                                id='newsletter-cadastro-singup'
                                                type='checkbox'
                                                name="newsletter"
                                                checked={(comunicacaoGeral || comunicacaoEmail || comunicacaoTelefone || comunicacaoWhats) ? true : false}
                                            />
                                            <label className='infos-programa m-lef-10' style={{ cursor: 'pointer' }}>
                                                <h4>Receber comunicação da Engeart</h4>
                                                <span className='small'>Escolha em quais canais você prefere receber</span>
                                            </label>
                                        </div>{/*termos-news-single*/}
                                        <div className='termos-news-single d-fx d-ju-sp-ev w100 m-top-20 d-fw-wp'>
                                            <div
                                                className='canais-single d-fx d-al-cen m-top-5 m-bot-5'
                                                for="newsletter-email-cadastro-singup"
                                                onClick={() => {
                                                    if ((comunicacaoEmail && (comunicacaoTelefone || comunicacaoWhats))) {
                                                        setComunicacaoEmail(!comunicacaoEmail);
                                                    } else {
                                                        setComunicacaoGeral(!comunicacaoGeral);
                                                        setComunicacaoEmail(!comunicacaoEmail);
                                                    }
                                                }}
                                            >
                                                <input
                                                    id='newsletter-email-cadastro-singup'
                                                    type='checkbox'
                                                    name="news_email"
                                                    checked={comunicacaoEmail}
                                                />
                                                <label className='m-lef-5' style={{ cursor: 'pointer' }}>E-mail</label>
                                            </div>
                                            <div
                                                className='canais-single d-fx d-al-cen m-top-5 m-bot-5'
                                                for="newsletter-sms-cadastro-singup"
                                                onClick={() => {
                                                    if ((comunicacaoTelefone && (comunicacaoEmail || comunicacaoWhats))) {
                                                        setComunicacaoTelefone(!comunicacaoTelefone);
                                                    } else {
                                                        setComunicacaoGeral(!comunicacaoGeral);
                                                        setComunicacaoTelefone(!comunicacaoTelefone);
                                                    }
                                                }}
                                            >
                                                <input
                                                    id='newsletter-sms-cadastro-singup'
                                                    type='checkbox'
                                                    name="news_sms"
                                                    checked={comunicacaoTelefone}
                                                />
                                                <label className='m-lef-5' style={{ cursor: 'pointer' }}>SMS</label>
                                            </div>
                                            <div
                                                className='canais-single d-fx d-al-cen m-top-5 m-bot-5'
                                                for="newsletter-whatsapp-cadastro-singup"
                                                onClick={() => {
                                                    if ((comunicacaoWhats && (comunicacaoEmail || comunicacaoTelefone))) {
                                                        setComunicacaoWhats(!comunicacaoWhats);
                                                    } else {
                                                        setComunicacaoGeral(!comunicacaoGeral);
                                                        setComunicacaoWhats(!comunicacaoWhats);
                                                    }
                                                }}
                                            >
                                                <input
                                                    id='newsletter-whatsapp-cadastro-singup'
                                                    type='checkbox'
                                                    name="news_whats"
                                                    checked={comunicacaoWhats}
                                                />
                                                <label className='m-lef-5' style={{ cursor: 'pointer' }}>WhatsApp</label>
                                            </div>
                                        </div>{/*termos-news-single*/}
                                    </div>
                                </>
                        }

                        <div className='box-opts-add b-top'>
                            <div
                                className='termos-news-single d-fx w100'
                                for="profissional-cadastro-singup"
                                onClick={() => setProfissional(!profissional)}
                            >
                                <input
                                    id='profissional-cadastro-singup'
                                    type='checkbox'
                                    name="profissional"
                                    checked={profissional}
                                />
                                <label className='infos-programa m-lef-10' style={{ cursor: 'pointer' }}>
                                    <h4>Sou profissional da construção civil</h4>
                                    <span className='small'>Profissionais das áreas de engenharia, arquitetura, decoração, ou mesmo quem atua como pedreiro ou pedreira, etc tem vantagens únicas como carteira de clientes e acesso à eventos.</span>
                                </label>
                            </div>{/*termos-news-single*/}
                        </div>

                        <button class="button button-full button-primary margin-top align-left btn__send__form" onClick={(e) => validaFomulario(e)} disabled={disabledSendSingup}>
                            Criar conta
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default FormSingUp;