
import { AiOutlineClose, AiOutlineReload, AiOutlineClear } from 'react-icons/ai';
import { fecharModal } from '../funcoes/FuncoesGerais';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri';
import { TiInfo } from 'react-icons/ti';
import { GiSave } from 'react-icons/gi';
import { useMemo, useState } from 'react';
import logoIcon from '../../../../../images/icones/jpg/logo_icone.jpg';
import { auth, db, fs } from '../../../../../firebase';
import toast from 'react-hot-toast';


// IMPORTAÇÃO PARA ASSINATURA
// import { createRoot } from 'react-dom/client'
import { useEffect, useRef } from 'react';
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getComponent, registerLicense } from '@syncfusion/ej2-base';
import { updateSampleSection } from '../../../../../common/sample-base';
// import SignaturePad from 'react-signature-pad-wrapper'
import SignaturePad from "react-signature-canvas";
import firebase from 'firebase';


function ModalAprovarFaseServico(props) {

    let navigate = useNavigate();
    const [lazyload, setLazyload] = useState(false); // LAZYLOAD
    const [depoimentos, setDepoimentos] = useState([]); // DEPOIMENTOS DOS CLIENTES
    const [senha, setSenha] = useState(''); // SENHA
    const [token, setToken] = useState(''); // Token ID Firestore
    const [senhaValid, setSenhaValid] = useState(false) // SENHA VÁLIDA
    const [signatureValid, setSignatureValid] = useState(false) // ASSINATURA SALVA
    const [signatureSaved, setSignatureSaved] = useState(false) // ASSINATURA SALVA
    const [signatureContent, setSignatureContent] = useState([]) // ASSINATURA SALVA
    const [contratacaoAtiva, setContratacaoAtiva] = useState(false) // ASSINATURA SALVA
    const [contratacaoAvisada, setContratacaoAvisada] = useState(false) // ASSINATURA SALVA
    const [conviteDepoimento, setConviteDepoimento] = useState(false) // ASSINATURA SALVA
    const [passStateView, setPassStateView] = useState(false);

    function fechaModalPreviewDepoimento(el) { 
        el.preventDefault();

        fecharModal();
        setTimeout(() => { props.setOpenModal(null) }, 1000);
    }

    function validarSenha(e) {
        e.preventDefault();

        if (!senha || !senha.length) {
            toast.error("A senha não pode ser vázia");
        } else { // PASSOU EM TODAS AS VALIDAÇÕES E VAMOS REALIZAR O LOGIN
            console.log(props.openModal)

            var newSteps = ([]);
            newSteps = props.openModal.dados_servico
            for (let i = 0; i < props.openModal.indexEtapa; i++) {
                newSteps.etapas[i].status = false;
            }
            if (props.openModal.indexEtapa < (newSteps.etapas.length - 1)) { // DEVE-SE SUBITRAIR POR CONTA DO ARAY COMEÇA NO INDICE 0 E APRESENTAR O TAMANHO TOTAL MAIOR
                // console.log("Etapa Modal: ",props.openModal.indexEtapa);
                // console.log("Etapa Tamanho: ",newSteps.etapas.length);
                newSteps.etapas[props.openModal.indexEtapa].status = false;
                newSteps.etapas[props.openModal.indexEtapa + 1].status = true;
            } else {
                // console.log("Ultima Etapa: ",newSteps.etapas.length);
                newSteps.etapas[props.openModal.indexEtapa].status = true;
            }
            newSteps.etapas[props.openModal.indexEtapa].statusContratante = true;
            newSteps.etapas[props.openModal.indexEtapa].concluido = true;
            newSteps.etapas[props.openModal.indexEtapa].dataAprovacao = new Date();

            auth.signInWithEmailAndPassword(props.user.email, senha)
                .then(async () => {
                    const contractRef = db.collection('servicos');
                    const snapshot = await contractRef.where("token", "==", props.openModal.token).get();
                    if (snapshot.empty) {
                        console.log('No matching documents.');
                        return;
                    }
                    snapshot.forEach(doc => {
                        db.collection('servicos')
                            .doc(doc.id)
                            .update({ "etapas": newSteps.etapas, "dataUltimaAtualizacao": firebase.firestore.FieldValue.serverTimestamp() })
                            .then((data) => {
                                // console.log("Retorno", "=>", data)
                                fechaModalPreviewDepoimento(e);
                                props.setAcompanhamentoObras(newSteps);
                                toast.success('A aprovação da etapa dos serviços foi realizada com sucesso!');
                                setConviteDepoimento(true);
                                // navigate('/minha-conta/meus-orcamentos')
                            })
                            .catch((err) => {
                                setContratacaoAtiva(false);
                                toast.error('Ocorreu um erro');
                                console.log(err)
                            })
                    });
                })
                .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    if (errorCode === 'auth/invalid-email') {
                        toast.error("O endereço de e-mail não é válido")
                    } else if (errorCode === 'auth/user-disabled') {
                        toast.error("O usuário correspondente ao e-mail fornecido foi desabilitado")
                    } else if (errorCode === 'auth/user-not-found') {
                        toast.error('Não houve usuário correspondente ao e-mail fornecido');
                    } else if (errorCode === 'auth/wrong-password') {
                        toast.error("Usuário ou senha inválidos");
                    } else if (errorCode === 'auth/too-many-requests') {
                        toast.error("O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login com falha. Você pode restaurá-la imediatamente redefinindo sua senha ou pode tentar novamente mais tarde.");
                    } else {
                        toast.error(errorMessage);
                    }
                    console.log(error);
                })
            /**/
        }
    }

    if (!conviteDepoimento) {
        return (
            <div class="popup" style={{ zIndex: 9999, position: 'fixed', maxWidth: '566px' }}>
                <div className='header-popup'>
                    <strong>Confirmar Aprovação de Etapa de Serviço</strong>
                    <a class="close-popup" onClick={(el) => fechaModalPreviewDepoimento(el)}><AiOutlineClose /></a>
                </div>
                <div class="content">
                    <div className='box-password-contract'>
                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <strong className='fz-12 m-rig-5'>Digite sua senha do site</strong>
                                    <strong className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <strong class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </strong>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin fz-13">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin fz-13">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin fz-13">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin fz-13">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </strong>
                                </div>
                            </label>
                            <div className='p-rlt'>
                                <input
                                    id="nome-cadastro-singup"
                                    className={(!senha || !senha.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                    type={(passStateView) ? 'text' : 'password'}
                                    name="senha-confirmacao"
                                    placeholder='Sua senha'
                                    value={senha}
                                    onChange={(e) => setSenha(e.target.value)}
                                />

                                <button className='btn__toggle__state__password p-abs' onClick={(el) => setPassStateView((prevState) => !prevState)}>
                                    {
                                        (passStateView)
                                            ?
                                            <RiEyeFill />
                                            :
                                            <RiEyeCloseLine />
                                    }
                                </button>
                            </div>
                            {
                                (senhaValid)
                                    ?
                                    senhaValid.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <strong className='icon-invalid'><TiInfo /></strong>
                                                    <strong className='text-invalid m-lef-5'>{infoStatus.message}</strong>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <strong className='icon-valid'><TiInfo /></strong>
                                                    <strong className='text-valid m-lef-5'>{infoStatus.message}</strong>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}
                    </div>
                </div>
                <div className='w60 right tx-rig'>
                    <button class="continue-application" onClick={(e) => validarSenha(e)}>
                        <div>
                            <div class="pencil"></div>
                            <div class="folder">
                                <div class="top">
                                    <svg viewBox="0 0 24 27">
                                        <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                                    </svg>
                                </div>
                                <div class="paper"></div>
                            </div>
                        </div>
                        Aprovar Etapa
                    </button>
                </div>
                <div className='clear'></div>
            </div>
        )
    } else {
        return (
            <div class="popup" style={{ zIndex: 9999, position: 'fixed', maxWidth: '566px' }}>
                <div className='header-popup'>
                    <strong>Por favor, ajude-nos a melhorar</strong>
                    <a class="close-popup" onClick={(el) => fechaModalPreviewDepoimento(el)}><AiOutlineClose /></a>
                </div>
                <div class="content">
                    <p
                        style={{ fontStyle: 'italic', fontSize: '14px' }}
                    >
                        Ajude outros clientes dizendo como foi sua expriencia navegando em nosso site, e em relação ao serviços prestados e sinta-se a vontade para dizer como podemos melhorar sua expriência em nosso site e em nossos serviços.
                    </p>
                </div>
                <div className='w60 right tx-rig'>
                    <button className="btn__cancel__modal" onClick={(el) => {
                        fechaModalPreviewDepoimento(el);
                        navigate('/minha-conta/meus-orcamentos');
                    }}>
                        Adiar
                    </button>
                    <button className="btn__confirm__modal" onClick={(el) => {
                        fechaModalPreviewDepoimento(el)
                        navigate(`/convite/depoimento/${props.openModal.dados_servico.numero_representante_contratado}`);
                    }}>
                        Avaliar
                    </button>
                </div>
                <div className='clear'></div>
            </div>
        )
    }
}

export default ModalAprovarFaseServico;