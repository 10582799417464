
import { useState } from "react";
import brandModel from "../../../../../../images/icones/png/cartao-bandeira-mastercard.png";
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo, TiInputChecked } from 'react-icons/ti';
import { useMemo } from "react";

function CardsCartaoTokenizado(props) {
    const [walletCardCvvValid, setWalletCardCvvValid] = useState(false);
    const [CVVNumCard, setCVVNumCard] = useState();

    useMemo(() => {
        if (props.carteiraNovoCartao) {
            // LIMPAR DADOS DO CARTÃO TOKENUIZADO
            props.setCartaoSelecionado([]);
            if (props.parcelasCartao && props.parcelasCartao.length) {
                document.querySelector(".box__selecao__cartoes .selected__option").innerHTML = "Selecione a quantidade de parcelas";
                document.querySelector(".box__selecao__cartoes .options-container").classList.remove("active");
            }
            props.setParcelasCartao('');
            setWalletCardCvvValid(false);
            setCVVNumCard('');
        }
    }, [props.carteiraNovoCartao])

    // FUNÇÕES DO SELECT
    const optionsList = document.querySelectorAll(".option");

    function selectedMain(val) {
        console.log(document.querySelector(".options-container"))
        document.querySelector(".options-container." + val).classList.toggle("active");

        document.querySelector("." + val + ".search-box input").value = "";
        filterList("");

        if (document.querySelector(".options-container." + val).classList.contains("active")) {
            document.querySelector("." + val + ".search-box input").focus();
        }
    };
    function selectedOption(o, val, qntParc) {
        document.querySelector(".selected__option." + val).innerHTML = o.target.querySelector("label").innerHTML;
        document.querySelector(".options-container." + val).classList.remove("active");
        props.setParcelasCartao(qntParc);
    };
    function selectedOptionSon(o, val, qntParc) {
        document.querySelector(".selected__option." + val).innerHTML = o.target.parentNode.querySelector("label").innerHTML;
        document.querySelector(".options-container." + val).classList.remove("active");
    };

    function typingSearch(e) {
        filterList(e.target.value);
    };

    const filterList = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        optionsList.forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function limparDadosNovoCartao(dadosCartao, index, tipo) {
        props.setCarteiraNovoCartao(false);
        props.setCartaoSelecionado({ infosCard: dadosCartao, id: index, paymentType: tipo });
    }

    function totalFinal() {
        let amountProduct = 0; // SOMA DE VALORES DOS PRODUTOS
        let amountShipping = 0; // SOMA DE FRETE DOS PRODUTOS
        let amountDiscount = 0; // CALCULO DE DESCONTOS DOS PRODUTOS

        // LOOPING SOMAR VALORES DOS PRODUTOS
        props.cart.map((i) => {
            amountProduct += i.precoTabela * i.quantidade
        })

        // LOOPING SOMAR FRETE DOS PRODUTOS
        props.cart.map((i) => {
            amountShipping += i.frete.online.preco
        })

        // LOOPING SOMAR DESCONTO DOS PRODUTOS
        props.cart.map((i) => {
            amountDiscount += ((i.precoTabela / 100) * i.desconto) * i.quantidade;
        })

        return ((amountProduct + amountShipping) - amountDiscount);
    }

    function calcInstallmetQnt() {
        // console.log(props.cart)
        if (props.cart.length) {
            let valorTotal = parseInt(totalFinal().toFixed(2));

            // if (valorTotal < 10 ) {
            //     // APENAS 1X
            // } else if (valorTotal < 15) {
            //     // APENAS 2X
            // } else if (valorTotal < 20) {
            //     // APENAS 3X
            // } else if (valorTotal < 25) {
            //     // APENAS 4X
            // } else if (valorTotal < 30) {
            //     // APENAS 5X
            // } else if (valorTotal < 35) {
            //     // APENAS 6X
            // } else if (valorTotal < 40) {
            //     // APENAS 7X
            // }
            var arr = ([]);
            for (let i = 0; i <= valorTotal; i += 5) {
                if (i > 0 && i <= 90) {
                    arr.push(i)
                }
            }
            var arrInstallmetQnt = ([]);
            for (let i = 1; i <= arr.length; i++) {
                if (i > 0 && i <= 90) {
                    arrInstallmetQnt.push(i)
                }
            }
            return arrInstallmetQnt;
        }
    }

    function calcInstallmetValue(installment) {
        if (props.cart.length) {
            let valorTotal = parseInt(totalFinal().toFixed(2));
            let valorParcelado = (valorTotal / installment);
            let valorTaxaAM = 1.99;
            // if (valorParcelado) {
            //     return valorParcelado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            // }

            // 1- ACHAR O VALOR EM REAIS DA PORCENTAGEM EM CIMA DO TOTAL DA PARCELA
            if (installment > 6) {
                // APLICAR TAXA DE PARCELAMENTO
                let valorTaxa = (valorParcelado / 100) * valorTaxaAM;
                // console.log(valorTaxa);
                valorParcelado = valorParcelado + valorTaxa;
                return valorParcelado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            } else {
                // ENVIAR VALOR SEM TAXA
                return valorParcelado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            }
        }
    }

    function verificaCodigoCartao() {
        if (props.cartaoSelecionado.infosCard.cvv == CVVNumCard.replace(/[^0-9]/g, "")) {
            setWalletCardCvvValid(true);
        } else {
            setWalletCardCvvValid(false);
        }
    }


    return (
        <div className="box__selecao__cartoes d-fx d-al-cen d-ju-sp-bt d-fw-wp w100 m-top-15">
            {
                (props.carteiraCartoes && props.carteiraCartoes.length)
                    ?
                    props.carteiraCartoes.map((dadosCartao, index) => {
                        return (
                            <div key={index} className={(props.responsivoSize && props.responsivoSize > 650) ? 'box__cartao__single w50' : 'box__cartao__single w100'}>
                                <div className={(props.cartaoSelecionado && props.cartaoSelecionado.id == index) ? "selecao__enderecos__checkout radio-item d-fx d-al-cen d-fw-wp p-rlt w100 box_single_credit_card selected_option" : "selecao__enderecos__checkout radio-item d-fx d-al-cen d-fw-wp p-rlt w100 box_single_credit_card"}>
                                    <div className="d-fx d-al-cen d-ju-sp-bt p-rlt w100">
                                        <label for={"address-selected"} className="radio-item-label tx-white d-fw-wp" style={{ position: 'relative', paddingLeft: 0 }}>
                                            <div className="p-abs d-fx d-al-cen w100" style={{ top: 0 }}>
                                                <input className="p-abs" type="radio" name="address-selected" id={"address-selected"} value="" data-validate="radio" style={{ marginRight: '.81rem', top: "0" }} onClick={e => { limparDadosNovoCartao(dadosCartao, index, 'token') }} checked={(props.cartaoSelecionado && props.cartaoSelecionado.id == index) ? true : false} />
                                                <strong className="fz-12 p-rlt">Cartão de crédito</strong>
                                                <span className={(props.responsivoSize && props.responsivoSize > 350) ? "img__brand__card p-rlt m-lef-5" : "img__brand__card p-rlt m-lef-5 m-rig-25"}>
                                                    <img className="card-brand" src={brandModel} />
                                                </span>
                                                <button className='p-abs d-fx d-al-cen fz-18 tx-red-dark' onClick={e => alert("Apagar")} style={(props.responsivoSize && props.responsivoSize < 200) ? {right: '-10px'} : null}>
                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path><path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path></svg>
                                                </button>
                                            </div>
                                            <div className={(props.responsivoSize && props.responsivoSize > 350) ? "w100 p-rlt" : "w100 p-rlt b-top"} style={(props.responsivoSize && props.responsivoSize > 350) ? { marginTop: '30px' } : { marginTop: '55px' }}>
                                                <div className="d-fx d-al-cen d-ju-sp-bt p-rlt w100 fz-16 d-fw-wp">
                                                    <span>Final: {dadosCartao.numero}</span>
                                                    <span>Venc. {dadosCartao.vencimento_mes}/{dadosCartao.vencimento_ano}</span>
                                                </div>
                                                <div className="opcoes__pagamento__single">
                                                    <div class="box-input-single m-top-20">
                                                        <label>
                                                            <div className='title-input d-fx d-al-cen p-rlt'>
                                                                <span className='fz-12 m-rig-5'>Selecione um parcelamento</span>

                                                                <span className='icon-info-add p-rlt'>
                                                                    <IoIosInformationCircleOutline />
                                                                    {
                                                                        (walletCardCvvValid)
                                                                            ?
                                                                            <div className='helper-input tx-black d-nn'>
                                                                                <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                    <span class="css-1i1x8nz">
                                                                                        Seu CPF será usado para:
                                                                                    </span>
                                                                                    <div class="css-1l7kih-stack">
                                                                                        <div>
                                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                <div>
                                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                <div>
                                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                <div>
                                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                <div>
                                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className='helper-input tx-black d-nn'>
                                                                                <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                    <span class="css-1i1x8nz">
                                                                                        Opções de parcelamento:
                                                                                    </span>
                                                                                    <div class="css-1l7kih-stack">
                                                                                        <div>
                                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                <div>
                                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Preencha o CVV do cartão</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                <div>
                                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Selecione a sua opção de parcelamento</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </label>
                                                        {
                                                            (walletCardCvvValid)
                                                                ?

                                                                <div class="select-box">
                                                                    <div class="options-container parcelamento">
                                                                        <div class="search-box d-nn">
                                                                            <input className='input-form' type="text" placeholder="Busque aqui a opção desejada..." onKeyUp={(e) => typingSearch(e)} />
                                                                        </div>
                                                                        {
                                                                            (props.cart && props.cart.length)
                                                                                ?
                                                                                calcInstallmetQnt().map((dados, index) => {
                                                                                    return (
                                                                                        <div key={index} class="option" onClick={(o) => selectedOption(o, "parcelamento", dados)}>
                                                                                            <input type="radio" class="radio" id="personalizar_parcelamento_alterar_projeto" name="parcelamento" value="alterar" />
                                                                                            <label for="personalizar_parcelamento_alterar_projeto" onClick={(o) => selectedOptionSon(o, "parcelamento", 1)}>
                                                                                                {dados}x de {calcInstallmetValue(dados)} {(dados <= 6) ? 'sem juros' : 'com juros (1,99% a.m.)'}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                : null
                                                                        }
                                                                    </div>

                                                                    <div class="selected__option parcelamento input-form" onClick={() => selectedMain("parcelamento")}>
                                                                        Selecione a quantidade de parcelas
                                                                    </div>

                                                                    <input id='personalizar_parcelamento_finalidade' type="text" name="personalizar_parcelamento_finalidade" value={props.parcelasCartao} readOnly disabled style={{ display: "none" }} />
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            (props.cartaoSelecionado && props.cartaoSelecionado.length && (!props.parcelasCartao || props.parcelasCartao.length <= 0))
                                                                ?
                                                                <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                                    <span className='icon-invalid'><TiInfo /></span>
                                                                    <span className='text-invalid m-lef-5'>Nenhuma opção de parcelamento selecionado</span>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            (!walletCardCvvValid)
                                                                ?
                                                                <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                                    <span className='icon-invalid'><TiInfo /></span>
                                                                    <span className='text-invalid m-lef-5'>Preencha o código de segurança do cartão para visualizar as opções de parcelamento</span>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                                <div className='box-input-single w100 m-lef-0 m-top-5'>
                                                    <label>
                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                            <span className='fz-12 m-rig-5'>Código de segurança</span>
                                                            <span className='icon-info-add p-rlt'>
                                                                <IoIosInformationCircleOutline />
                                                                <div className='helper-input tx-black d-nn'>
                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                        <span class="css-1i1x8nz">
                                                                            Seu CPF será usado para:
                                                                        </span>
                                                                        <div class="css-1l7kih-stack">
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </label>
                                                    <InputMask id={`pagamento-cvv-cartao-${index}`} className='input-form w100 fz-16 m-top-5' mask="9999" placeholder='9999' value={CVVNumCard} onChange={(ev) => setCVVNumCard(ev.target.value)} onBlur={
                                                        (e) => verificaCodigoCartao()} />
                                                    {
                                                        (!walletCardCvvValid)
                                                            ?
                                                            <div className='info-invalid d-fx d-al-cen m-top-10'>
                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                <span className='text-invalid m-lef-5'>O código de segurança é inválido</span>
                                                            </div>
                                                            : null
                                                    }
                                                </div>{/*box-input-single */}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : null
            }
        </div>
    )
}

export default CardsCartaoTokenizado;