
import { useMemo, useState } from "react";
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';
import $, { isEmptyObject } from 'jquery';
import Avvvatars from 'avvvatars-react' // AVATAR DO USUÁRIO


import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { FcRemoveImage, FcEditImage, FcAddImage, FcCancel, FcApproval } from 'react-icons/fc';

import { AiOutlineFolderView, AiOutlineFundView, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';

import { IoIosInformationCircleOutline } from 'react-icons/io';
import { db, storage } from "../../../../../../../../../../firebase";
import firebase from 'firebase';
import toast from "react-hot-toast";
import { FilePond, File, registerPlugin } from 'react-filepond';
import "filepond";
// import * as FilePond from 'filepond';

// Import the plugin code
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import the plugin styles
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import pt_BR from 'filepond/locale/pt-br';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';

export default function UserBox_single(props) {
    const [files, setFiles] = useState([])
    const [expandedSummary, setExpandedSummary] = useState(0); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");

    const [progresso, setProgresso] = useState(0);
    const [editPhotoProfileMode, setEditPhotoProfileMode] = useState(false);


    async function fazerUploadArquivo(arquivo, clienteName, clientCPF, clientPhoto) {
        if (clientPhoto && clientPhoto.length) { // O CLIENTE JÁ POSSUI UMA FOTO DE PERFIL
            // Create a reference to the file to delete
            const desertRef = storage.refFromURL(clientPhoto).delete().then(() => {
                const nomeArquivo = (clienteName + '-' + new Date().getTime() + "." + arquivo.type.split('/')[1]).toString().toLocaleLowerCase();
                const uploadTask = storage.ref('ICONES/USUARIOS/' + nomeArquivo).put(arquivo);

                uploadTask.on('state_changed', (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 1;
                    setProgresso(progress);
                },

                    function (error) {
                        toast.error("Ocoreu um erro ao salvar a foto do cliente");
                        console.log("Erro após foto apagada:", error);
                    },

                    function () {
                        storage.ref('ICONES/USUARIOS/' + nomeArquivo).getDownloadURL().then(async (url) => {

                            const ref = db.collection('usuarios');
                            const snapshot = await ref.where('cpf', '==', clientCPF).get();
                            if (snapshot.empty) {
                                toast.error('Não foi possivel localizar o cliente.');
                                return;
                            }
                            var clientId = ([]);
                            snapshot.forEach(doc => {
                                // console.log(doc.id, '=>', doc.data());
                                clientId.push(doc.id);
                            });

                            db.collection('usuarios')
                                .doc(clientId[0])
                                .update('photo', url)
                                .then(() => {
                                    setEditPhotoProfileMode(false);
                                    return;
                                })
                                .catch((err) => {
                                    toast.err("Ocorreu um erro ao salvar a foto de perfil do cliente.");
                                    console.log(err);
                                })
                        })
                    }
                )
            }).catch((err) => {
                if (err.code_ === 'storage/object-not-found') {
                    const nomeArquivo = (clienteName + '-' + new Date().getTime() + "." + arquivo.type.split('/')[1]).toString().toLocaleLowerCase();
                    const uploadTask = storage.ref('ICONES/USUARIOS/' + nomeArquivo).put(arquivo);

                    uploadTask.on('state_changed', (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 1;
                        setProgresso(progress);
                    },

                        function (error) {
                            toast.error("Ocoreu um erro ao salvar a foto do cliente");
                            console.log("Erro após foto apagada:", error);
                        },

                        function () {
                            storage.ref('ICONES/USUARIOS/' + nomeArquivo).getDownloadURL().then(async (url) => {

                                const ref = db.collection('usuarios');
                                const snapshot = await ref.where('cpf', '==', clientCPF).get();
                                if (snapshot.empty) {
                                    toast.error('Não foi possivel localizar o cliente.');
                                    return;
                                }
                                var clientId = ([]);
                                snapshot.forEach(doc => {
                                    // console.log(doc.id, '=>', doc.data());
                                    clientId.push(doc.id);
                                });

                                db.collection('usuarios')
                                    .doc(clientId[0])
                                    .update('photo', url)
                                    .then(() => {
                                        setEditPhotoProfileMode(false);
                                        return;
                                    })
                                    .catch((err) => {
                                        toast.err("Ocorreu um erro ao salvar a foto de perfil do cliente.");
                                        console.log(err);
                                    })
                            })
                        }
                    )
                } else {
                    toast.error("Ocoreu um erro ao apagar a foto do cliente");
                    console.log("Erro Apagar Foto: ", err)
                }
            });
        } else {
            const nomeArquivo = (clienteName + '-' + new Date().getTime() + "." + arquivo.type.split('/')[1]).toString().toLocaleLowerCase();
            const uploadTask = storage.ref('ICONES/USUARIOS/' + nomeArquivo).put(arquivo);



            uploadTask.on('state_changed', (snapshot) => {

                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 1;

                setProgresso(progress);

            },

                function (error) {



                },



                function () {

                    storage.ref('ICONES/USUARIOS/' + nomeArquivo).getDownloadURL().then(async (url) => {

                        const ref = db.collection('usuarios');
                        const snapshot = await ref.where('cpf', '==', clientCPF).get();
                        if (snapshot.empty) {
                            toast.error('Não foi possivel localizar o cliente.');
                            return;
                        }
                        var clientId = ([]);
                        snapshot.forEach(doc => {
                            // console.log(doc.id, '=>', doc.data());
                            clientId.push(doc.id);
                        });

                        db.collection('usuarios')
                            .doc(clientId[0])
                            .update('photo', url)
                            .then(() => {
                                setEditPhotoProfileMode(false);
                                return;
                            })
                            .catch((err) => {
                                toast.err("Ocorreu um erro ao salvar a foto de perfil do cliente.");
                                console.log(err);
                            })

                        // db.collection('usuarios').add({

                        //     diretorio: url,

                        //     tipo: arquivo.type,

                        //     orderId: 1

                        // })

                    })


                }



            )
        }

        return;

    }
    registerPlugin(
        FilePondPluginFileValidateType,
        FilePondPluginImageExifOrientation,
        FilePondPluginImagePreview,
        FilePondPluginImageCrop,
        FilePondPluginImageResize,
        FilePondPluginImageTransform,
        FilePondPluginImageEdit
    );
    // create(
    //     document.getElementById('myInput'),
    //     {
    //       labelIdle: `Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`,
    //       imagePreviewHeight: 170,
    //       imageCropAspectRatio: '1:1',
    //       imageResizeTargetWidth: 200,
    //       imageResizeTargetHeight: 200,
    //       stylePanelLayout: 'compact circle',
    //       styleLoadIndicatorPosition: 'center bottom',
    //       styleProgressIndicatorPosition: 'right bottom',
    //       styleButtonRemoveItemPosition: 'left bottom',
    //       styleButtonProcessItemPosition: 'right bottom',
    //     }
    //   );

    async function apagarFotoPerfilCliente(e, clientCPF) {
        e.preventDefault();

        const ref = db.collection('usuarios');
        const snapshot = await ref.where('cpf', '==', clientCPF).get();
        if (snapshot.empty) {
            toast.error('Não foi possivel localizar o cliente.');
            return;
        }
        var clientId = ([]);
        snapshot.forEach(doc => {
            clientId.push(doc.id);
        });

        // Remove the 'capital' field from the document
        db.collection('usuarios').doc(clientId[0]).update({
            photo: firebase.firestore.FieldValue.delete()
        }).then(() => {
            toast.success("A foto do cliente foi deletada!");
            setEditPhotoProfileMode(false);
            setFiles([]);
        }).catch((err) => {
            toast.error("Ocorreu um erro ao apagar a foto!");
            console.log(err);
        });
    }

    const getSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const [fileList, setFileList] = useState([]);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        const src = file.url || (await getSrcFromFile(file));
        const imgWindow = window.open(src);

        if (imgWindow) {
            const image = new Image();
            image.src = src;
            imgWindow.document.write(image.outerHTML);
        } else {
            window.location.href = src;
        }
    };

    async function fazerUploadFoto(clientCPF, arquivo) {
        const ref = db.collection('usuarios');
        const snapshot = await ref.where('cpf', '==', clientCPF).get();
        if (snapshot.empty) {
            toast.error('Não foi possivel localizar o cliente.');
            return;
        }
        var clientId = ([]);
        snapshot.forEach(doc => {
            clientId.push(doc.id);
        });

        // Remove the 'capital' field from the document
        db.collection('usuarios').doc(clientId[0]).update({
            photo: fileList[0].thumbUrl
        }).then(() => {
            toast.success("A foto do cliente foi alterada!");
            setEditPhotoProfileMode(false);
            setFileList([])
        }).catch((err) => {
            toast.error("Ocorreu um erro ao apagar a foto!");
            console.log(err);
        });
    }

    useMemo(() => {
        setExpandedSummary(props.viewUsers);
        setExpandedSubSummary(props.viewUsers);
    }, [props.viewUsers])
    return (
        <div>
            <motion.div
                style={{ transformOrigin: "top", overflow: "hidden" }}
                animate={{ height: (expandedSummary) ? "auto" : expandedSummarySize }}
                className='description-page__content'
            >
                <div className={(expandedSummary) ? 'wrapper__request__single w100 p-g-10 box__admin is-open' : 'wrapper__request__single w100 p-g-10 box__admin'}>
                    {/* DADOS PRINCIPAIS */}
                    <div className='order__title__request p-top-10 p-bot-15 d-fx d-ju-sp-bt d-al-cen d-fw-wp w100'>
                        <div className='order__item__request d-fx d-al-cen'>
                            {
                                (props.responsivoSize && props.responsivoSize > 300)
                                    ?
                                    <>
                                        {
                                            (props.dadosUsuario.photo && props.dadosUsuario.photo.length) ?
                                                <img className='img__user w40' src={props.dadosUsuario.photo} />
                                                :
                                                <div className="avatar__character">
                                                    {
                                                        (props.dadosUsuario.full_name)
                                                            ?
                                                            <Avvvatars value={props.dadosUsuario.full_name} size={48} border={true} borderSize={2} borderColor="#006DFF" class="asasasas" />
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </>
                                    : null
                            }
                            <div className="d-fx d-ju-left d-fd-col" style={{ gap: '5px' }}>
                                <strong className="m-lef-5 fz-14" style={(props.responsivoSize && props.responsivoSize >= 768) ? { color: '#333' } : { color: '#333', whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {
                                        (props.responsivoSize && props.responsivoSize > 370)
                                            ?
                                            props.dadosUsuario.full_name
                                            :
                                            props.dadosUsuario.given_name
                                    }
                                </strong>

                                {
                                    (props.responsivoSize && props.responsivoSize >= 481)
                                        ?
                                        <strong className="m-lef-5 fz-12" style={{ color: '#636a7e', fontWeight: 'lighter' }}>
                                            {props.dadosUsuario.email}
                                        </strong>
                                        : null
                                }
                            </div>
                        </div>
                        {
                            (props.responsivoSize && props.responsivoSize > 530 && props.dadosUsuario && !isEmptyObject(props.dadosUsuario) && props.dadosUsuario.dataCadastro && !isEmptyObject(props.dadosUsuario.dataCadastro))
                                ?
                                <div className='order__item__request info-default'>
                                    <small className='d-bk m-bot-5'>
                                        Data do Cadastro
                                    </small>
                                    <strong className='d-bk'>
                                        {
                                            props.dadosUsuario.dataCadastro.toDate().toLocaleDateString("pt-BR")
                                        }
                                    </strong>
                                </div>
                                : null
                        }
                        {
                            (props.responsivoSize && props.responsivoSize >= 481)
                                ?
                                <div className='order__item__request info-default'>
                                    <div className={(!props.dadosUsuario.representante) ? 'med-badge active-badge-success' : 'med-badge active-badge'} style={{ textTransform: 'capitalize' }}>
                                        {
                                            (props.dadosUsuario.representante)
                                                ?
                                                "Representante"
                                                :
                                                "Cliente"
                                        }
                                    </div>
                                </div>
                                : null
                        }
                        <buttom className="order__item__request info-primary btn__show__request">
                            <BsChevronDown className='fz-20' onClick={() => setExpandedSummary(!expandedSummary)} />
                        </buttom>
                    </div>

                    {/* SUB DADOS */}
                    <div className='order__body__request'>
                        <div className='content__request d-fx d-fd-col'>
                            <motion.div
                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                animate={{ height: (expandedSubSummary) ? "auto" : "70px" }}
                                className='description-page__content'
                            >
                                <div className={(expandedSubSummary) ? 'wrapper__order__request order-content p-g-15 open' : 'wrapper__order__request order-content p-g-15'}> {/* open */}
                                    <div className='order__title__request w100 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                        <div className='delivery__order__type info-primary'>
                                            <strong>Informações do Cliente</strong>
                                        </div>
                                        {
                                            (props.responsivoSize && props.responsivoSize > 450 && props.dadosContrato && !isEmptyObject(props.dadosContrato) && props.dadosContrato.validade_orcamento && props.dadosContrato.validade_orcamento.length)
                                                ?
                                                <div className='delivery__order__date info-default'>
                                                    <small className='d-bk m-bot-5'>
                                                        Vencimento da Proposta
                                                    </small>
                                                    <strong className='d-bk tx-norm fz-13'>
                                                        {
                                                            new Date(props.dadosContrato.validade_orcamento).toLocaleDateString("pt-BR")
                                                        }
                                                    </strong>
                                                </div>
                                                : null
                                        }
                                        <buttom className='delivery__order__action info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                            <BsChevronDown className='fz-20' />
                                        </buttom>
                                    </div>
                                    <div className='delivery__body__request m-top-20'>
                                        <div className='wrapper__itens__request m-top-20 d-fx d-fw-wp d-ju-sp-bt'>
                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%', height: '100%' } : { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%', height: '100%' }}>
                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados Pessoais</span>
                                                <div className='order__item__request info-default d-fx d-al-cen' style={{ gap: '5px' }}>
                                                    <div className={(!props.dadosUsuario.representante) ? 'med-badge active-badge-success' : 'med-badge active-badge'} style={{ textTransform: 'capitalize' }}>
                                                        {
                                                            (props.dadosUsuario.representante)
                                                                ?
                                                                "Representante"
                                                                :
                                                                "Cliente"
                                                        }
                                                    </div>
                                                    {
                                                        (props.dadosUsuario.fidelidade)
                                                            ?
                                                            <div className='med-badge active-badge' style={{ textTransform: 'capitalize' }}>
                                                                Com Você
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.full_name && props.dadosUsuario.full_name.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Nome:</strong> {props.dadosUsuario.full_name}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.cpf && props.dadosUsuario.cpf)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            {
                                                                (() => {
                                                                    var cpf_cnpj = props.dadosUsuario.cpf.toString();
                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                    } else {
                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                    }
                                                                })()
                                                            }
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.rg && props.dadosUsuario.rg.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            {
                                                                (() => {
                                                                    var rg = props.dadosUsuario.rg.toString();
                                                                    var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                    return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                })()
                                                            }
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.email)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>E-mail:</strong> {props.dadosUsuario.email}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.emailVerified)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>E-mail Verificado:</strong> Verificado
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && !props.dadosUsuario.emailVerified)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>E-mail Verificado:</strong> <span style={{ color: '#ff0000' }}>Não Verificado</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.telefone)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Telefone:</strong> {props.dadosUsuario.telefone}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.token_representante && props.dadosUsuario.token_representante.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Código de Representante:</strong> {props.dadosUsuario.token_representante}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.dataCadastro && !isEmptyObject(props.dadosUsuario.dataCadastro))
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Data de Cadastro:</strong> {props.dadosUsuario.dataCadastro.toDate().toLocaleDateString('pt-BR')} {props.dadosUsuario.dataCadastro.toDate().toLocaleTimeString('pt-BR')}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.dados_login && !isEmptyObject(props.dadosUsuario.dados_login))
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Informações de Login:</strong>
                                                            <ul style={{ textTransform: 'capitalize', marginTop: '5px', border: '1px solid #ccc', padding: '10px 15px', borderRadius: '6px', listStyleType: 'none' }}>
                                                                {
                                                                    ((props.dadosUsuario.dados_login.dispositivo && props.dadosUsuario.dados_login.dispositivo.length) || (props.dadosUsuario.dados_login.navegador && props.dadosUsuario.dados_login.navegador.length))
                                                                        ?
                                                                        <li className="m-top-5 m-bot-5">
                                                                            Dispositivo/navegador: <strong>{(props.dadosUsuario.dados_login.dispositivo && props.dadosUsuario.dados_login.dispositivo.length) ? props.dadosUsuario.dados_login.dispositivo : null}, {(props.dadosUsuario.dados_login.navegador && props.dadosUsuario.dados_login.navegador.length) ? props.dadosUsuario.dados_login.navegador : null}</strong>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    ((props.dadosUsuario.dados_login.localizacao.cidade && props.dadosUsuario.dados_login.localizacao.cidade.length) || (props.dadosUsuario.dados_login.localizacao.estado && props.dadosUsuario.dados_login.localizacao.estado.length) || (props.dadosUsuario.dados_login.localizacao.pais && props.dadosUsuario.dados_login.localizacao.pais.length))
                                                                        ?
                                                                        <li className="m-top-5 m-bot-5">
                                                                            Local aproximado: <strong>{(props.dadosUsuario.dados_login.localizacao.cidade && props.dadosUsuario.dados_login.localizacao.cidade.length) ? props.dadosUsuario.dados_login.localizacao.cidade : null}, {(props.dadosUsuario.dados_login.localizacao.estado && props.dadosUsuario.dados_login.localizacao.estado.length) ? props.dadosUsuario.dados_login.localizacao.estado : null} - {(props.dadosUsuario.dados_login.localizacao.pais && props.dadosUsuario.dados_login.localizacao.pais.length) ? props.dadosUsuario.dados_login.localizacao.pais : null}</strong>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    (props.dadosUsuario.dados_login.ultimo_login && !isEmptyObject(props.dadosUsuario.dados_login.ultimo_login))
                                                                        ?
                                                                        <li className="m-top-5 m-bot-5">
                                                                            Data do Último Login: <strong>{props.dadosUsuario.dados_login.ultimo_login.toDate().toLocaleDateString('pt-BR')} {props.dadosUsuario.dados_login.ultimo_login.toDate().toLocaleTimeString('pt-BR')}</strong>
                                                                        </li>
                                                                        :
                                                                        <li className="m-top-5 m-bot-5">
                                                                            Data do Último Login: <span style={{ color: "#ff0000" }}>Indefinido</span>
                                                                        </li>
                                                                }
                                                            </ul>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosUsuario && (props.dadosUsuario.newsletter || props.dadosUsuario.newsletter_email || props.dadosUsuario.newsletter_sms || props.dadosUsuario.newsletter_whatsapp))
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Meios de Contato:</strong>
                                                            <ul className="m-lef-15" style={{ textTransform: 'capitalize' }}>
                                                                {
                                                                    (props.dadosUsuario.newsletter)
                                                                        ?
                                                                        <li className="m-top-5 m-bot-5">
                                                                            <span>Carta (Correspondência)</span>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    (props.dadosUsuario.newsletter_email)
                                                                        ?
                                                                        <li className="m-top-5 m-bot-5">
                                                                            <span>E-mail (Correio Eletrônico)</span>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    (props.dadosUsuario.newsletter_sms)
                                                                        ?
                                                                        <li className="m-top-5 m-bot-5">
                                                                            <span>SMS (Mensagem de Texto)</span>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    (props.dadosUsuario.newsletter_whatsapp)
                                                                        ?
                                                                        <li className="m-top-5 m-bot-5">
                                                                            <span>Whatsapp (Aplicativos)</span>
                                                                        </li>
                                                                        : null
                                                                }
                                                            </ul>
                                                        </span>
                                                        : null
                                                }
                                            </div>
                                            <div className='take__product_adress m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%', height: '100%' } : { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%', height: '100%' }}>
                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Foto de Perfil</span>
                                                <div className="m-top-15 d-fx w100 d-al-cen d-ju-cen d-fd-col">
                                                    {
                                                        (editPhotoProfileMode)
                                                            ?
                                                            <>
                                                                <div className='w50 m-top-20'>
                                                                    <ImgCrop
                                                                        grid
                                                                        rotate
                                                                        shape="round"
                                                                        modalTitle="Editar Foto"
                                                                        modalOk="Salvar"
                                                                        modalCancel="Cancelar"
                                                                    >
                                                                        <Upload
                                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                                            listType="picture-card"
                                                                            fileList={fileList}
                                                                            onChange={onChange}
                                                                            onPreview={onPreview}
                                                                        >
                                                                            {fileList.length < 1 && 'Arraste e solte seus arquivos ou Navegue'}
                                                                        </Upload>
                                                                    </ImgCrop>
                                                                </div>
                                                                <section className="box-order-filter w100 tx-cen d-fx d-al-cen d-ju-cen">
                                                                    <div className="box-order">
                                                                        <div className="btn-order d-fx d-al-cen d-fd-row">
                                                                            <a
                                                                                className="default d-fx d-al-cen" style={{ gap: '2px', border: 'none', color: "#646464" }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setEditPhotoProfileMode(false)
                                                                                    setFiles([]);
                                                                                }}
                                                                                onMouseOver={(e) => { e.target.style.background = "#cccccc40" }}
                                                                                onMouseOut={(e) => { e.target.style.background = "none" }}
                                                                            >
                                                                                <FcCancel /> Cancelar
                                                                            </a>
                                                                            {
                                                                                (fileList && fileList.length)
                                                                                    ?
                                                                                    <a
                                                                                        className="default d-fx d-al-cen" style={{ gap: '2px', border: '1px solid #8bc34a', color: "#8bc34a" }}
                                                                                        onClick={(e) => fazerUploadFoto(props.dadosUsuario.cpf, fileList)}
                                                                                        onMouseOver={(e) => { e.target.style.background = "#8bc34a10" }}
                                                                                        onMouseOut={(e) => { e.target.style.background = "none" }}
                                                                                    >
                                                                                        <FcApproval /> Salvar
                                                                                    </a>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                (props.dadosUsuario.photo && props.dadosUsuario.photo.length)
                                                                                    ?
                                                                                    <a
                                                                                        className="default d-fx d-al-cen" style={{ gap: '2px' }}
                                                                                        onClick={(e) => apagarFotoPerfilCliente(e, props.dadosUsuario.cpf)}
                                                                                    >
                                                                                        <FcRemoveImage /> Deletar Foto Atual
                                                                                    </a>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </>
                                                            :
                                                            <div
                                                                className="box__photo__client d-fx w50 d-al-cen d-ju-cen d-fd-col p-rlt"
                                                                title="Alterar foto de perfil"
                                                                onClick={() => setEditPhotoProfileMode(true)}
                                                            >
                                                                <img
                                                                    className="img__client"
                                                                    src={(props.dadosUsuario.photo && props.dadosUsuario.photo.length) ? props.dadosUsuario.photo : "https://firebasestorage.googleapis.com/v0/b/engeart-6a378.appspot.com/o/ICONES%2FUSUARIOS%2Fuser.png?alt=media&token=dd15761b-e6ed-4683-b50b-a6d2e93d76a1"}
                                                                    style={{ width: '100%' }}
                                                                />
                                                                {
                                                                    (() => {
                                                                        if (props.dadosUsuario.photo) {
                                                                            return (
                                                                                <FcEditImage
                                                                                    className="p-abs icon__remove__photo__client"
                                                                                    style={{
                                                                                        fontSize: '45px'
                                                                                    }}
                                                                                />
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <FcAddImage
                                                                                    className="p-abs icon__remove__photo__client"
                                                                                    style={{
                                                                                        fontSize: '45px'
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                    })()
                                                                }
                                                            </div>
                                                    }
                                                    {
                                                        (!props.dadosUsuario.photo || !props.dadosUsuario.photo.length)
                                                            ?
                                                            <span className="m-top-5 fz-14" style={{ color: '#ff0000' }}>{`${(props.dadosUsuario.given_name && props.dadosUsuario.given_name.length) ? props.dadosUsuario.given_name : "O cliente"} não possui nenhum foto de perfil.`}</span>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#fff', borderRadius: '8px', border: "1px solid #ededed", heigth: '100%' }}>
                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Endereços</span>
                                                {
                                                    (props.dadosUsuario && props.dadosUsuario.enderecos && props.dadosUsuario.enderecos.length)
                                                        ?
                                                        props.dadosUsuario.enderecos.map((endereco) => {
                                                            return (
                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                    <strong>{endereco.apelido}:</strong> {`${endereco.logradouro}, ${endereco.numero}, ${endereco.bairro} - ${endereco.cidade}, - ${endereco.estado}, CEP ${(() => {
                                                                        var new_cep = endereco.cep.toString();
                                                                        return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                    })()}`}
                                                                    {
                                                                        (endereco.principal)
                                                                            ?
                                                                            <div className='med-badge active-badge m-lef-5' style={{ textTransform: 'capitalize' }}>Principal</div>
                                                                            : null
                                                                    }
                                                                </span>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                            <div className='w100 d-fx m-top-15' style={{ justifyContent: 'flex-end' }}>
                                {
                                    (() => {
                                        const d1 = new Date().toLocaleDateString("en-US");
                                        const d2 = new Date(props.dadosContrato.validade_orcamento).toLocaleDateString("en-US");

                                        const diffInMs = new Date(d2) - new Date(d1);
                                        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                                        if (diffInDays >= 0 || props.user.representante === true) {
                                            return (
                                                <Link to={`/minha-conta/meus-orcamentos/${props.dadosContrato.token}`} className='view-button view-btn-secondary' onClick={(e) => props.setOrcamentoUnico(props.dadosContrato)}>
                                                    <AiOutlineFundView className='fz-24' /> Visualizar Orçamento
                                                </Link>
                                            )
                                        } else {
                                            return (
                                                <button
                                                    className='view-button view-btn-secondary'
                                                    onClick={(e) => e.preventDefault()}
                                                    disabled={true}
                                                    title="Este orçamento está vencido"
                                                >
                                                    <AiOutlineFundView className='fz-24' /> Visualizar Orçamento
                                                </button>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}