
import { db, fs } from '../../../../../firebase';
import firebase from 'firebase';
import toast from 'react-hot-toast';

function Newsletter(props) {
    function cadastraNewsletter(el) {
        el.preventDefault();
        // console.log(details);
        // VARIÁVEIS PARA CADASTRO DE SOLICITAÇÃO DE CONTATO
        let formNewsletter = document.getElementById('form-newsletter-geral');
        let email = document.getElementById('email-newsletter-geral').value;

        // let myDate = document.getElementById('datetime').value;
        if (props.user) {
            db.collection('newsletter').add({
                email: email,
                data_envio: firebase.firestore.FieldValue.serverTimestamp(),
                detalhes: props.location,
                usuario: props.user,
                formulario: 'geral | home'
            }).then(() => {
                // TODO: FALTA CADASTRAR O STATUS DA CONTA DO USUÁRIO COMO POSITIVO PARA NEWSLETTER
                toast.success('Seja bem vindo ao nosso boletim de notícias ' + props.user.nome + '!');
                formNewsletter.reset();
            }).catch((error) => {
                toast.error(error.message);
            })
        } else {
            db.collection('newsletter').add({
                email: email,
                data_envio: firebase.firestore.FieldValue.serverTimestamp(),
                detalhes: props.location,
                formulario: 'geral | home'
            }).then(() => {
                // TODO: FALTA CADASTRAR O STATUS DA CONTA DO USUÁRIO COMO POSITIVO PARA NEWSLETTER
                toast.success('Seja bem vindo ao nosso boletim de notícias!');
                formNewsletter.reset();
            }).catch((error) => {
                toast.error(error.message);
            })
        }
    }

    return (
        <section className="newsletter p-rlt container__content z-less">
            <div className="center">
                <div className="news-content n-promo w100">
                    <form id='form-newsletter-geral' className='w100 d-fx d-ju-cen d-al-cen p-rlt d-fw-wp' onSubmit={(el) => cadastraNewsletter(el)}>
                        <div className='icon-bg-news w10 p-abs'>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><path d="M285.72 186.06c-.165 1.215-2.445 3.585-3.54 3.78H111.075L68.85 69.72l214.26 10.845c1.38 0 2.52.9 2.61 1.26V186.06zM283.5 66.285h-.015L63.93 55.17 49.14 19.41a7.157 7.157 0 00-6.6-4.41H7.14A7.138 7.138 0 000 22.14a7.138 7.138 0 007.14 7.14h30.615l11.55 27.9 51.66 146.955H282.18c8.835 0 17.82-9.09 17.82-18.015V81.825c0-8.415-7.725-15.54-16.5-15.54zm-27.45 207.09c-13.785 0-25.02-11.22-25.02-25.02 0-13.8 11.235-25.02 25.02-25.02 13.8 0 25.02 11.22 25.02 25.02 0 13.8-11.22 25.02-25.02 25.02zm0-64.32c-21.66 0-39.3 17.625-39.3 39.3 0 21.675 17.64 39.3 39.3 39.3 21.675 0 39.315-17.625 39.315-39.3 0-21.675-17.64-39.3-39.315-39.3zm-112.725 64.32c-13.8 0-25.035-11.22-25.035-25.02 0-13.8 11.235-25.02 25.035-25.02 13.785 0 25.02 11.22 25.02 25.02 0 13.8-11.235 25.02-25.02 25.02zm0-64.32c-21.675 0-39.315 17.625-39.315 39.3 0 21.675 17.64 39.3 39.315 39.3 21.675 0 39.3-17.625 39.3-39.3 0-21.675-17.625-39.3-39.3-39.3z" fill="#18415D" fill-rule="nonzero" /></svg> */}
                        </div>
                        <div className='text-news w30'>
                            <h1>
                                Fique por dentro das
                                <br />
                                <strong className='info-primary m-rig-5'>Novidades</strong>
                                e
                                <strong className='info-primary m-lef-5'>Promoções</strong>
                            </h1>
                        </div>
                        <div className='content-input w60 d-fx d-ju-sp-bt d-fw-wp'>
                            <input id='email-newsletter-geral' className='w75 default' type="text" name="newsletter" placeholder='seuemail@email.com' />
                            <button className='btn-mod-2'>
                                <div class="svg-wrapper-1">
                                    <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <span>Cadastrar</span>
                            </button>
                        </div>
                    </form>
                </div>{/* last-views-content */}

                <div className='info-termos'>
                    <span>
                        <em>Ao se cadastrar você concorda em receber e-mails promocionais e novidades. Saiba mais na nossa </em><a className='info-primary' href='javascript:vodi(0)'>Politica de Privacidade</a>.
                    </span>
                </div>
            </div>{/* center */}
        </section>
    )
}

export default Newsletter;