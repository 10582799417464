import { BASE_PATH } from '../../../../js/constants'; // CONSTANTES BASES
import '../Assets/styles/css/Main.css';
import { useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInfoCircle, faCartPlus, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import $ from 'jquery';

// IMPORTAÇÃO DE IMAGENS
import whats from '../../../../images/icones/svg/whats.svg';
import slide from '../../../../images/projetos/png/slide04.png';
import promo from '../../../../images/projetos/png/Projetos Prontos.png';
import home from '../../../../images/projetos/png/home1.png';
import promo2 from '../../../../images/projetos/jpg/promo.jpg';
import promoBox from '../../../../images/projetos/jpg/promo_380x400.jpg';
import promoBox2 from '../../../../images/projetos/png/oferta01.png';

// REACT ICONS
import { FaBan, FaStar, FaShareSquare, FaEye, FaHeart } from 'react-icons/fa';
import { AiOutlineMinusCircle, AiOutlinePlusCircle, AiFillInfoCircle } from 'react-icons/ai';

import SlidesOfertasGerais from '../Assets/components/SlidesOfertasGerais';
import CuponsOfertas from '../Assets/components/CuponsOfertas';
import BoxOfertas from '../Assets/components/BoxOfertas';
import CardsProjetos from '../Assets/components/CardsProjetos';
import { WidgetDepartamentos } from '../Assets/components/Departamentos';
import Newsletter from '../Assets/components/Newsletter';


// IMPORTAÇÃO DE CARROSEL DE IMAGENS
import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from '../Assets/components/Arrows';

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'
import { db } from '../../../../firebase';

function Ofertas(props) {
    const [bgSlideOffers,setbgSlideOffers] = useState([]);
    
    const toggleDrawer = () => {
        props.setIsOpen((prevState) => !prevState);
    }

    const [data,setData] = useState([]);
	const carousel = useRef(null);

    const [visualizacao, setVisualizacao] = useState([]);
    const [favoritados, setFavoritados] = useState([]);
    const [ofertasCupons, setOfertasCupons] = useState([]);
    const [projetos, setProjetos] = useState([]);

	useEffect(() => {

        // PEGANDO TODOS OS PROJETOS
        db.collection("produtos").doc("projetos").collection("residencial").where("status", "==", true).orderBy("orderId","desc").onSnapshot((infos) => {
            var arr = ([]);
            infos.docs.map((dados) => {
                arr.unshift(dados.data());
            })
            setProjetos(arr);
        })

        db.collection("relatorios").doc("visualizacoes").collection("projetos").onSnapshot((allViews) => {
            var arr = ([]);
            allViews.docs.map((dataViews) => {
                arr.unshift(dataViews.data());
            })
            setVisualizacao(arr);
        })

        db.collection("relatorios").doc("visualizacoes").collection("favoritos").onSnapshot((allLike) => {
            var arr = ([]);
            allLike.docs.map((dataLike) => {
                arr.unshift(dataLike.data());
            })
            setFavoritados(arr);
        })
	}, []);
    return(
        <div id='ofertas'>
            <Helmet title="Engeart • Ofertas" />
            {/* SLIDES */}
            <SlidesOfertasGerais />

            {/* SESSÃO - CUPONS - PROMOÇÕES */}
            <CuponsOfertas />

            {/* SESSÃO - PROMOÇÃO APP */}
            <BoxOfertas />
            
            {/* SESSÃO - ULTIMAS VISUALIZAÇÕES */}
            <CardsProjetos user={props.user} setOpenLike={props.setOpenLike} projetos={projetos} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} setItemFavorito={props.setItemFavorito} listasFavoritos={props.listasFavoritos} favorito={props.favorito} visualizacao={visualizacao} favoritados={favoritados} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} setItensAvaliados={props.setItensAvaliados} />

            {/* SESSÃO - DEPARTAMENTOS */}
            <WidgetDepartamentos />
            
            {/* SESSÃO - ULTIMAS VISUALIZAÇÕES */}
            <CardsProjetos user={props.user} setOpenLike={props.setOpenLike} projetos={projetos} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} setItemFavorito={props.setItemFavorito} listasFavoritos={props.listasFavoritos} favorito={props.favorito} visualizacao={visualizacao} favoritados={favoritados} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} setItensAvaliados={props.setItensAvaliados} />
            
            {/* SESSÃO - NEWSLETTER */}
            <Newsletter user={props.user} />
        </div>
    );
}

export default Ofertas;