import './Assets/Styles/Css/Sidenav.css';
import { auth, db } from '../../../firebase';
import { Link } from 'react-router-dom'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { motion } from 'framer-motion'; // FRAMER MOTION 


import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { BsArrowLeftShort, BsFileEarmarkSpreadsheet, BsTrash, BsBookmarkHeart, BsNewspaper, BsChevronDown } from 'react-icons/bs';
import { GiPencilRuler, GiPostOffice } from 'react-icons/gi';
import { MdOutlineArchitecture, MdOutlineElectricalServices, MdOutlineDesignServices, MdOutlineWaterDamage, MdOutlineWaterDrop, MdOutlineConstruction, MdOutlinePriceCheck, MdTranslate, MdOutlineShareLocation } from 'react-icons/md';
import { RiHomeHeartLine, RiHomeSmile2Line, RiGalleryLine, RiContactsLine, RiHandHeartLine, RiHomeGearLine } from 'react-icons/ri';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { IoConstructOutline, IoMdLocate } from 'react-icons/io5';
import { TiInfo } from 'react-icons/ti';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';

import emptyCart from '../../../images/icones/svg/empty-cart.svg';


import firebase from 'firebase';

import toast from 'react-hot-toast';

import $, { isEmptyObject } from 'jquery';
import Avvvatars from 'avvvatars-react';
import FiltroPavimentos from '../Main/Pages/Components/Filtros/Projetos/FiltroPavimentos';
import FiltroTerrenos from '../Main/Pages/Components/Filtros/Projetos/FiltroTerrenos';
import FiltroDormitorios from '../Main/Pages/Components/Filtros/Projetos/FiltroDormitorios';
import FiltroSuites from '../Main/Pages/Components/Filtros/Projetos/FiltroSuites';
import FiltroAreas from '../Main/Pages/Components/Filtros/Projetos/FiltroAreas';
import FiltroNiveis from '../Main/Pages/Components/Filtros/Projetos/FiltroNiveis';
import FiltroInclinacoes from '../Main/Pages/Components/Filtros/Projetos/FiltroInclinacoes';
import FiltroTipos from '../Main/Pages/Components/Filtros/Projetos/FiltroTipos';
import FiltroOrdenacao from '../Main/Pages/Components/Filtros/Projetos/FiltroOrdenacao';

function Sidebar(props) {
    const [btnBack, setBtnBack] = useState(false);
    const [listlike, setListLike] = useState(false);
    const [favoritos, setFavoritos] = useState([]);
    const [listasFavoritos, setListasFavoritos] = useState([]);
    const [projetosFavoritos, setProjetosFavoritos] = useState([]);
    const [qnt, setQnt] = useState([]);
    const [subMenu, setSubMenu] = useState(false);
    const [paginaMenu, setPaginaMenu] = useState('');


    const [projetosFiltro, setProjetosFiltro] = useState([]);

    const toggleFormNewListLike = () => {
        setListLike((prevState) => !prevState)
    }

    const toggleLike = () => {
        props.setOpenLike((prevState) => !prevState)
        setListLike(false);
    }

    const toggleCart = () => {
        props.setOpenCart((prevState) => !prevState)
        toggleFormNewListLike()
    }

    const toggleDrawer = () => {
        props.setOpenMenuResponsivo((prevState) => !prevState);
        setTimeout(() => {
            setPaginaMenu('')
        }, 500);
    }

    const toggleDrawerSubMenu = () => {
        props.setOpenMenuResponsivo((prevState) => !prevState);
        setSubMenu('')
        setTimeout(() => {
            setPaginaMenu('')
        }, 500);
    }

    const showBtnBack = () => {
        setBtnBack(true);
    }

    const hideBtnBack = () => {
        setBtnBack(false);
    }

    const backElementLike = () => {
        toggleFormNewListLike();
        hideBtnBack();
    }

    // useEffect(() => {
    //     if (props.user) {
    //         // EXISTE O USUARIO = ESTÁ LOGADO -> CONTINUAR COM BD
    //         db.collection('usuarios').doc(props.user.id).collection('carrinho').onSnapshot((data) => {
    //             var contentCart = new Array();
    //             data.docs.map(result => {
    //                 contentCart.push(result.data());
    //             })
    //             props.setCart(contentCart);
    //         })
    //     }
    // }, [props.openLike, props.user]);

    function removetocart(item) {

        props.projetos.map((i) => {
            if (i.id == item.id) {
                i.cart = false
            }
        })
        if (props.user && !isEmptyObject(props.user)) {
            console.log("Remover", item.id);

            auth.onAuthStateChanged((val) => {
                db.collection('usuarios').doc(val.uid).collection('carrinho').doc(`${item.id}`).delete().then(() => {
                    if (props.openCart == false) {
                        props.setOpenCart((prevState) => !prevState)
                    }
                });
            })
        }


    }
    function addtocart(item) {
        props.dadosProjeto.map((i) => {
            if (i.id == item.id) {
                i.cart = true
            }
        })
        db
            .collection('usuarios')
            .doc(props.user.id)
            .collection('carrinho')
            .doc(`${item.id}`)
            .set(item, { merge: true })
            .then(() => {
                if (props.openCart == false) {
                    props.setOpenCart((prevState) => !prevState)
                }
            });
    }

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    function createdNewListLike(el) {
        el.preventDefault();
        var nomeLista = document.getElementById('name_list_like').value;
        let uniqueId = Date.now() + getRandomInt(1, 100000);

        db.collection('usuarios').doc(props.user.id).collection('favoritos').doc('grupos').collection("listas").add({
            nome: nomeLista,
            data_adicionado: firebase.firestore.FieldValue.serverTimestamp(),
            data_alterado: null,
            id: uniqueId,
            id_lista: null,
            itens: null
        }).then((data) => {
            db.collection('usuarios').doc(props.user.id).collection('favoritos').doc('grupos').collection("listas").doc(data.id).update({
                id_lista: data.id,
                itens: [
                    {
                        tipo: props.itemFavorito.tipo,
                        item: props.itemFavorito.item,
                        id: Date.now() + getRandomInt(1, 100000),
                        id_lista_original: data.id,
                        id_lista_generico: uniqueId,
                        id_item: props.itemFavorito.item.orderId
                    }
                ]
            }).then(() => {
                db.collection("relatorios").doc("visualizacoes").collection("favoritos").add({
                    data_visualizacao: firebase.firestore.FieldValue.serverTimestamp(),
                    categoria: "projetos",
                    id: props.itemFavorito.item.orderId,
                    usuario: props.user,
                    id_lista_favorito_original: data.id,
                    id_lista_favorito_generico: uniqueId,
                }).then(() => {
                    toast.success('A lista ' + nomeLista + ' foi criada com sucesso!');
                    setTimeout(() => {
                        toast.success('O ' + props.itemFavorito.tipo + ' ' + props.itemFavorito.item.titulo + ' foi adicionado com sucesso a lista ' + nomeLista + '!');
                        toggleLike();
                    }, 250);
                })
            })
        }).catch((error) => {
            console.error(error);
        })
    }

    function adicionarProjetoFavorito(el, id_original, id_generico, itens) {
        el.preventDefault();
        console.log(props.itemFavorito);
        var contentItens = itens;
        var itemSingle = {
            tipo: props.itemFavorito.tipo,
            item: props.itemFavorito.item,
            id: Date.now() + getRandomInt(1, 100000),
            id_lista_original: id_original,
            id_lista_generico: id_generico,
            id_item: props.itemFavorito.item.orderId
        }
        contentItens.push(itemSingle);
        // console.log(id_original);
        // NÃO TEM COMO ADICIONAR O MESMO PROJETO VÁRIAS VEZES POIS A VALIDAÕA SERÁ FEITA NO BOTÃO, SE O BOTÃO JÁ ESTIVER VERMELHO E O USUÁRIO CLICAR IRÁ REMOVER AQUELE ELEMENTO DA LISTA DE FAVORITOS
        db.collection('usuarios').doc(props.user.id).collection('favoritos').doc('grupos').collection("listas").doc(id_original).update({
            itens: contentItens
        }).then(() => {
            db.collection("relatorios").doc("visualizacoes").collection("favoritos").add({
                data_visualizacao: firebase.firestore.FieldValue.serverTimestamp(),
                categoria: "projetos",
                id: props.itemFavorito.item.orderId,
                usuario: props.user,
                id_lista_favorito_original: id_original,
                id_lista_favorito_generico: id_generico,
                id_item_favorito: props.itemFavorito.item.orderId
            }).then(() => {
                toast.success('O ' + props.itemFavorito.item.titulo + ' foi adicionado com sucesso aos seus favoritos!');
                toggleLike();
            })
        })
    }

    // FUNÇÃO DE FILTROS
    function clearAllFilter(el) {
        el.preventDefault();
        var allInputs = document.querySelectorAll('.box-filter-project input[type=radio]:checked');
        for (var i = 0; i < allInputs.length; i++) {
            if (allInputs[i].type == 'radio') {
                allInputs[i].checked = false;
            }
        }

        props.setFiltrados(props.projetos)
        props.setFiltrosParametros([]);
        props.setFiltrosSelecionados([]);
    }

    function pegaDadosElementos(elementoFiltro) {
        if (elementoFiltro.parametro == 'pavimentoQnt') {
            if (elementoFiltro.valorParametro > 1) {
                return "Sobrado";
            } else {
                return "Térreo";
            }
        } else if (elementoFiltro.parametro == 'quarto') {
            if (elementoFiltro.valorParametro <= 1) {
                return elementoFiltro.valorParametro + " Quarto";
            } else if (elementoFiltro.valorParametro >= 5) {
                return "+" + elementoFiltro.valorParametro + " Quartos";
            } else {
                return elementoFiltro.valorParametro + " Quartos";
            }
        } else if (elementoFiltro.parametro == 'tipo') {
            return elementoFiltro.valorParametro;
        } else if (elementoFiltro.parametro == 'terreno') {
            return elementoFiltro.valorParametro;
        } else if (elementoFiltro.parametro == 'suite') {
            if (elementoFiltro.valorParametro <= 1) {
                return elementoFiltro.valorParametro + " Suíte";
            } else if (elementoFiltro.valorParametro >= 5) {
                return "+" + elementoFiltro.valorParametro + " Suítes";
            } else {
                return elementoFiltro.valorParametro + " Suítes";
            }
        } else if (elementoFiltro.parametro == 'area') {
            return elementoFiltro.valorParametro + " M²";
        } else if (elementoFiltro.parametro == 'classificacao') {
            if (elementoFiltro.valorParametro == 'alto') {
                return "Alto Padrão";
            } else if (elementoFiltro.valorParametro == 'medio') {
                return "Classe Média";
            } else {
                return "Econômico";
            }
        } else if (elementoFiltro.parametro == 'inclinacao') {
            return elementoFiltro.valorParametro;
        }
    }

    function removerParametroFiltro(e, filtroId, filtroValue) {
        e.preventDefault();

        var novoFiltro = props.filtrosSelecionados;
        var novaLista = props.filtrosParametros;
        var posicaoElemento = props.filtrosParametros.indexOf(filtroId);

        novoFiltro.splice(posicaoElemento, 1);
        novaLista.splice(posicaoElemento, 1);

        // console.log(novoFiltro);
        // console.log(novaLista);
        console.log(props.filtrosSelecionados);

        const produtos = props.projetos;
        if (props.filtrosParametros.length == 1) {
            var produtosFiltrados = produtos.filter(produto => produto[props.filtrosSelecionados[0].parametro] == props.filtrosSelecionados[0].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (props.filtrosParametros.length == 2) {
            var produtosFiltrados = produtos.filter(produto => produto[props.filtrosSelecionados[0].parametro] == props.filtrosSelecionados[0].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[1].parametro] == props.filtrosSelecionados[1].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (props.filtrosParametros.length == 3) {
            var produtosFiltrados = produtos.filter(produto => produto[props.filtrosSelecionados[0].parametro] == props.filtrosSelecionados[0].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[1].parametro] == props.filtrosSelecionados[1].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[2].parametro] == props.filtrosSelecionados[2].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (props.filtrosParametros.length == 4) {
            var produtosFiltrados = produtos.filter(produto => produto[props.filtrosSelecionados[0].parametro] == props.filtrosSelecionados[0].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[1].parametro] == props.filtrosSelecionados[1].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[2].parametro] == props.filtrosSelecionados[2].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[3].parametro] == props.filtrosSelecionados[3].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (props.filtrosParametros.length == 5) {
            var produtosFiltrados = produtos.filter(produto => produto[props.filtrosSelecionados[0].parametro] == props.filtrosSelecionados[0].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[1].parametro] == props.filtrosSelecionados[1].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[2].parametro] == props.filtrosSelecionados[2].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[3].parametro] == props.filtrosSelecionados[3].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[4].parametro] == props.filtrosSelecionados[4].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (props.filtrosParametros.length == 6) {
            var produtosFiltrados = produtos.filter(produto => produto[props.filtrosSelecionados[0].parametro] == props.filtrosSelecionados[0].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[1].parametro] == props.filtrosSelecionados[1].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[2].parametro] == props.filtrosSelecionados[2].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[3].parametro] == props.filtrosSelecionados[3].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[4].parametro] == props.filtrosSelecionados[4].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[5].parametro] == props.filtrosSelecionados[5].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (props.filtrosParametros.length == 7) {
            var produtosFiltrados = produtos.filter(produto => produto[props.filtrosSelecionados[0].parametro] == props.filtrosSelecionados[0].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[1].parametro] == props.filtrosSelecionados[1].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[2].parametro] == props.filtrosSelecionados[2].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[3].parametro] == props.filtrosSelecionados[3].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[4].parametro] == props.filtrosSelecionados[4].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[5].parametro] == props.filtrosSelecionados[5].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[6].parametro] == props.filtrosSelecionados[6].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (props.filtrosParametros.length == 8) {
            var produtosFiltrados = produtos.filter(produto => produto[props.filtrosSelecionados[0].parametro] == props.filtrosSelecionados[0].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[1].parametro] == props.filtrosSelecionados[1].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[2].parametro] == props.filtrosSelecionados[2].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[3].parametro] == props.filtrosSelecionados[3].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[4].parametro] == props.filtrosSelecionados[4].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[5].parametro] == props.filtrosSelecionados[5].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[6].parametro] == props.filtrosSelecionados[6].valorParametro)
                .filter(produto => produto[props.filtrosSelecionados[7].parametro] == props.filtrosSelecionados[7].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else {
            props.setFiltrados(props.projetos);
            props.setFiltrosParametros([]);
            props.setFiltrosSelecionados([]);

        }

        if (filtroId == "pavimentoQnt") {
            var allInputs = document.querySelectorAll(".wrapper__pavimento input[type=radio]:checked");
            for (var i = 0; i < allInputs.length; i++) {
                if (allInputs[i].type == 'radio') {
                    allInputs[i].checked = false;
                }
            }
        } else if (filtroId == "quarto") {
            var allInputs = document.querySelectorAll(".wrapper__quarto input[type=radio]:checked");
            for (var i = 0; i < allInputs.length; i++) {
                if (allInputs[i].type == 'radio') {
                    allInputs[i].checked = false;
                }
            }
        } else if (filtroId == "tipo") {
            var allInputs = document.querySelectorAll(".wrapper__tipo input[type=radio]:checked");
            for (var i = 0; i < allInputs.length; i++) {
                if (allInputs[i].type == 'radio') {
                    allInputs[i].checked = false;
                }
            }
        } else if (filtroId == "terreno") {
            var allInputs = document.querySelectorAll(".wrapper__terreno input[type=radio]:checked");
            for (var i = 0; i < allInputs.length; i++) {
                if (allInputs[i].type == 'radio') {
                    allInputs[i].checked = false;
                }
            }
        } else if (filtroId == "suite") {
            var allInputs = document.querySelectorAll(".wrapper__suite input[type=radio]:checked");
            for (var i = 0; i < allInputs.length; i++) {
                if (allInputs[i].type == 'radio') {
                    allInputs[i].checked = false;
                }
            }
        } else if (filtroId == "area") {
            var allInputs = document.querySelectorAll(".wrapper__area input[type=radio]:checked");
            for (var i = 0; i < allInputs.length; i++) {
                if (allInputs[i].type == 'radio') {
                    allInputs[i].checked = false;
                }
            }
        } else if (filtroId == "classificacao") {
            var allInputs = document.querySelectorAll(".wrapper__classificacao input[type=radio]:checked");
            for (var i = 0; i < allInputs.length; i++) {
                if (allInputs[i].type == 'radio') {
                    allInputs[i].checked = false;
                }
            }
        } else if (filtroId == "inclinacao") {
            var allInputs = document.querySelectorAll(".wrapper__inclinacao input[type=radio]:checked");
            for (var i = 0; i < allInputs.length; i++) {
                if (allInputs[i].type == 'radio') {
                    allInputs[i].checked = false;
                }
            }
        }
    }

    // FUNÇÕES MENU RESPONSIVO
    function deslogar(e) {
        e.preventDefault();
        auth.signOut().then(function (val) {
            props.setUser(null);
            // window.location.href = "/";
            toggleDrawer();
        }).then(() => {
            sessionStorage.removeItem('dados_usuario');
            props.setCart([]);
        })
            .then(() => {
                toast("👋 Até logo");
            })
    }
    return (
        <div className='Sidebar'>
            {
                (props.openLike)
                    ?
                    <Drawer open={props.openLike} direction='right' className='mySidenav' zIndex='99' style={{ width: '30vw', overflowX: 'hidden' }}> {/* SIDEBAR - FAVORITOS */}
                        <div className='header__sidenav p-g-20'>
                            <div className='sidenav__functions p-rlt'>
                                <div className='sidenav__function__btn__back left'>
                                    {
                                        (listlike)
                                            ?
                                            <a href='javascript:void(0)' onClick={backElementLike}>
                                                <BsArrowLeftShort className='fz-40 top-5' />
                                            </a>
                                            : null
                                    }
                                </div>
                                <div className='sidenav__function__btn__back right'>
                                    <a href='javascript:void(0)' onClick={toggleLike}>
                                        <AiOutlineClose className='fz-25 top-5' />
                                    </a>
                                </div>
                                <div className='clear'></div>
                            </div>
                            {
                                (!listlike)
                                    ?
                                    <div className='sidenav_like'>
                                        <div className='sidenav__title p-rlt m-top-5 w100 b-bot' style={{ textAlign: "center" }}>
                                            <h2>Adicionar à lista de favoritos</h2>
                                        </div>
                                        <div className='sidenav__section_newList w100 b-bot'>
                                            <div className='d-fx d-ju-cen'>
                                                <button class="cta" onClick={toggleFormNewListLike}>
                                                    <span class="hover-underline-animation"> Criar uma nova lista </span>
                                                    <svg id="arrow-horizontal" xmlns="http://www.w3.org/2000/svg" width="30" height="10" viewBox="0 0 46 16">
                                                        <path id="Path_10" data-name="Path 10" d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z" transform="translate(30)"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            (favoritos && favoritos.length > 0)
                                                ?
                                                <div className='content__others'>
                                                    <div className='sidenav__title p-rlt m-top-5 w100 b-bot' style={{ textAlign: "center" }}>
                                                        <h2>Adicionar à uma lista existente</h2>
                                                    </div>
                                                    <div className='sidenav__section_newList w100 b-bot'>
                                                        <div className='d-fx d-ju-cen d-fw-wp'>
                                                            <div id="content_add_favoritos" className="box-all-list d-bk w100">
                                                                {
                                                                    favoritos.map((dados) => {
                                                                        return (
                                                                            <div class="box-list-single d-fx d-al-cen m-bot-20">
                                                                                <div class="icon-prev-single left p-g-5 w30 d-fx d-al-cen d-ju-cen">
                                                                                    {/* <MdOutlineElectricalServices className='fz-50 m-rig-10' /> */}
                                                                                    {
                                                                                        (dados.itens && dados.itens.length > 0) ?

                                                                                            <img src={
                                                                                                (() => {
                                                                                                    var contentDados = new Array();
                                                                                                    contentDados.push(dados.itens);
                                                                                                    var arr = contentDados.map((obj) => {
                                                                                                        return Object.keys(obj).map((key) => {
                                                                                                            return obj[key];
                                                                                                        })
                                                                                                    })
                                                                                                    {/* console.log(arr) */ }
                                                                                                    arr.map((subDados) => {
                                                                                                        contentDados = subDados;
                                                                                                    })
                                                                                                    // console.log(contentDados[0].item.image);
                                                                                                    return contentDados[0].item.image;
                                                                                                })
                                                                                            }
                                                                                                className="w100"
                                                                                                style={{ borderRadius: '.25rem' }}
                                                                                            />
                                                                                            :
                                                                                            <MdOutlineElectricalServices className='fz-50 m-rig-10' />
                                                                                    }
                                                                                </div>
                                                                                <div class="infos-list-single right d-fx d-ju-sp-bt d-al-cen w100">
                                                                                    <div class="folder-item-infos left">
                                                                                        <span class="item-name-infos">
                                                                                            <div class="title-disciplina">
                                                                                                <strong class="info-item-name-main tx-grey-dark fz-16">{dados.nome}</strong>
                                                                                            </div>
                                                                                            <div class="info-item-name-content d-fx fz-13 tx-grey">

                                                                                                <span class='m-rig-5'>
                                                                                                    <b className='m-rig-5'>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                var contentDados = new Array();
                                                                                                                contentDados.push(dados.itens);
                                                                                                                var arr = contentDados.map((obj) => {
                                                                                                                    return Object.keys(obj).map((key) => {
                                                                                                                        return obj[key];
                                                                                                                    })
                                                                                                                })
                                                                                                                {/* console.log(arr) */ }
                                                                                                                arr.map((subDados) => {
                                                                                                                    contentDados = subDados;
                                                                                                                })
                                                                                                                var contador = 0;
                                                                                                                contentDados.map((subDados2) => {
                                                                                                                    {/* console.log(subDados2.tipo); */ }
                                                                                                                    if (subDados2.tipo == 'projeto') {
                                                                                                                        contador++;
                                                                                                                    }
                                                                                                                })
                                                                                                                return contador;
                                                                                                            })()
                                                                                                        }
                                                                                                    </b>
                                                                                                    Produtos
                                                                                                </span>
                                                                                                |
                                                                                                <span class='m-lef-5 m-rig-5'>
                                                                                                    <b className='m-rig-5'>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                var contentDados = new Array();
                                                                                                                contentDados.push(dados.itens);
                                                                                                                var arr = contentDados.map((obj) => {
                                                                                                                    return Object.keys(obj).map((key) => {
                                                                                                                        return obj[key];
                                                                                                                    })
                                                                                                                })
                                                                                                                {/* console.log(arr) */ }
                                                                                                                arr.map((subDados) => {
                                                                                                                    contentDados = subDados;
                                                                                                                })
                                                                                                                var contador = 0;
                                                                                                                contentDados.map((subDados2) => {
                                                                                                                    {/* console.log(subDados2.tipo); */ }
                                                                                                                    if (subDados2.tipo == 'conteudo') {
                                                                                                                        contador++;
                                                                                                                    }
                                                                                                                })
                                                                                                                return contador;
                                                                                                            })()
                                                                                                        }
                                                                                                    </b>
                                                                                                    Conteúdos
                                                                                                </span>
                                                                                            </div>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="btn-add-list right">
                                                                                        {/* VALIDAR SE O BOTÃO JÁ ESTÁ SELECIONADO POISO USUARIO POSSUI ESSE FAVORITO EM UMA LISTA JÁ  */}
                                                                                        <button class="btn-func-add-like button-hollow folder-item-button fz-13 p-top-10 p-bot-10 p-lef-20 p-rig-20 p-rlt" onClick={(el) => adicionarProjetoFavorito(el, dados.id_lista, dados.id, dados.itens)}>
                                                                                            <span class='d-fx d-al-cen'>
                                                                                                <AiOutlinePlus class='m-rig-5 fz-16' />
                                                                                                Adicionar
                                                                                            </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class='clear'></div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                : null
                                        }
                                    </div>
                                    :
                                    <div className='sidenav_like'>
                                        <div className='sidenav__title p-rlt m-top-5 w100 b-bot' style={{ textAlign: "center" }}>
                                            <h2>Dê um nome para sua lista:</h2>
                                        </div>
                                        <div className='sidenav__section_newList w100'>
                                            <form onSubmit={(el) => { createdNewListLike(el) }}>
                                                <input id="name_list_like" class="inputPay" type="text" placeholder="Ex.: Minha casa dos sonhos" />
                                                <input type='tag' />
                                                <div className='d-fx d-ju-cen'>
                                                    <button class="cta">
                                                        <span class="hover-underline-animation"> Criar lista de favoritos </span>
                                                        <svg id="arrow-horizontal" xmlns="http://www.w3.org/2000/svg" width="30" height="10" viewBox="0 0 46 16">
                                                            <path id="Path_10" data-name="Path 10" d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z" transform="translate(30)"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                            }
                        </div >
                    </Drawer >
                    : null
            }
            {
                (props.openCart)
                    ?
                    <Drawer open={props.openCart} direction='right' className='mySidenav' zIndex='99' style={{ width: (props.responsivoSize && props.responsivoSize >= 768) ? '30vw' : '100vw', overflowX: 'hidden' }}> {/* SIDEBAR - CARRINHO */}
                        <div className='header__sidenav p-g-20'>
                            <div className='sidenav__functions p-rlt'>
                                <div className='sidenav__function__btn__back left'>
                                    <a href='javascript:void(0)' onClick={toggleCart}>
                                        <BsArrowLeftShort className='fz-40 top-5' />
                                    </a>
                                </div>
                                <div className='sidenav__function__btn__back right'>
                                    <a href='javascript:void(0)' onClick={toggleCart}>
                                        <AiOutlineClose className='fz-25 top-5' />
                                    </a>
                                </div>
                                <div className='clear'></div>
                            </div>
                            <div className='sidenav_like'>
                                <div className='content__cart'>
                                    <div className='sidenav__title p-rlt m-top-5 w100 b-bot' style={{ textAlign: "center" }}>
                                        <h2>Meu Carrinho</h2>
                                    </div>
                                    <div className='sidenav__section_newList w100 b-bot my__cart__products'>
                                        <div className='d-fx d-ju-cen d-fw-wp'>
                                            <div id="cart_sidenav" className="box-all-list d-bk w100">
                                                {
                                                    (props.cart && props.cart.length)
                                                        ?
                                                        props.cart.map((item, index) => (
                                                            <div key={index} className="box-list-single d-fx d-al-cen m-bot-20">
                                                                <div className="icon-prev-single left p-g-5">
                                                                    <MdOutlineDesignServices className='fz-50 m-rig-10' />
                                                                </div>
                                                                <div className="infos-list-single right d-fx d-ju-sp-bt d-al-cen w100">
                                                                    <div className="folder-item-infos left">
                                                                        <span className="item-name-infos">
                                                                            <div className="title-disciplina">
                                                                                <strong className="info-item-name-main fz-16">{item.titulo}</strong>
                                                                            </div>
                                                                            {/* <span className="info-item-name-content d-fx fz-13 tx-grey">{item.itens.length} Itens | E{item.codProduto}A | <a className="info-item-name-sub t-lef d-al-cen tx-bold" href="javascript:void(0)" style={{ padding: '0 2px 0' }}> Ajuda</a></span> */}
                                                                            <span className="info-item-name-amount tx-bold fz-12 tx-grey-light m-rig-5" style={{ textDecoration: 'line-through' }}>{(item.precoTabela).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                                                            <span className="info-item-name-amount tx-bold fz-13 tx-grey-dark">{(item.precoFinal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>

                                                                            <div className='p-rlt d-fx m-top-5 m-bot-5'>
                                                                                <div class="bookmarks_infos d-fx d-al-cen m-rig-5" style={{ right: '0px' }}>
                                                                                    <span class="fz-11" style={{ background: 'rgba(225, 225, 225, 0.5)', padding: '3px 10px 3px 15px', borderRadius: '20px' }}>Cód.: E{item.orderId}A</span>
                                                                                </div>

                                                                                <div className='d-fx p-rlt m-top-5 m-bot-5'>
                                                                                    {
                                                                                        (() => {
                                                                                            if (item && item.status && item.lancamento && new Date(item.lancamento.seconds * 1000).getTime() > new Date().getTime()) {
                                                                                                return (
                                                                                                    <div class="bookmarks_infos d-fx d-al-cen pre__lancamento" style={{ right: '0px' }}>
                                                                                                        <span class="fz-11" style={{ background: 'rgba(225, 225, 225, 0.5)', padding: '3px 10px 3px 10px', borderRadius: '20px' }}>Pré Venda</span>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })()
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                    <div className="btn-add-list right">
                                                                        <button className="button-hollow folder-item-button remove-button fz-13 p-top-10 p-bot-10 p-lef-20 p-rig-20 p-rlt" onClick={() => removetocart(item)}>
                                                                            <span className='d-fx d-al-cen'>
                                                                                <BsTrash className='m-rig-5 fz-16' />
                                                                                Remover
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='clear'></div>
                                                            </div>
                                                        ))
                                                        :
                                                        <section id="empty_cart_shopping" className='content-title p-g-20 m-top-10' style={{ textAlign: 'center' }}>
                                                            <h2 className='fz-25 m-bot-20' style={{ color: '#000' }}>Você não possui itens adicionados ao seu carrinho.</h2>
                                                            <div className='w100'>
                                                                <img className='w100' src={emptyCart} />
                                                            </div>
                                                            <Link to="/projetos" className="button button-full button-primary margin-top tx-cen" onClick={toggleCart}>
                                                                Ver mais projetos
                                                            </Link>
                                                        </section>
                                                }
                                            </div>

                                            <Link to="/carrinho" className="button button-full button-primary margin-top tx-cen" onClick={toggleCart}>
                                                Ir para o carrinho
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                    : null
            }
            {
                (props.openMenuResponsivo)
                    ?
                    <Drawer open={props.openMenuResponsivo} direction='left' className='mySidenav w85' zIndex='99' style={{ width: '85%', overflowX: 'hidden', backgroundColor: '#00263a' }}> {/* SIDEBAR - MENU RESPONSIVO */}
                        <div className={(subMenu) ? 'header__sidenav' : 'header__sidenav p-g-20'}>
                            {
                                (paginaMenu)
                                    ?
                                    <div className='sidenav__functions p-rlt d-fx d-ju-sp-bt d-al-cen' style={{ borderBottom: '2px solid rgb(24, 65, 93)' }}>

                                        <div className='sidenav__function__btn__back'>
                                            {
                                                (paginaMenu)
                                                    ?
                                                    <div>
                                                        {
                                                            (subMenu)
                                                                ?
                                                                <a className='d-fx d-al-cen tx-white' href='javascript:void(0)' onClick={() => setSubMenu(false)}>
                                                                    <BsArrowLeftShort className='fz-40' />
                                                                    <span style={{ textTransform: 'capitalize' }}>
                                                                        {
                                                                            (() => {
                                                                                if (paginaMenu == 'projetos') {
                                                                                    return ("Projetos")
                                                                                } else if (paginaMenu == 'decoracao') {
                                                                                    return ("Decoração")
                                                                                } else if (paginaMenu == 'servicos') {
                                                                                    return ("Serviços")
                                                                                } else if (paginaMenu == 'beneficios') {
                                                                                    return ("Beneficios")
                                                                                }
                                                                            })()
                                                                        }
                                                                    </span>
                                                                </a>
                                                                :
                                                                <a className='d-fx d-al-cen tx-white' href='javascript:void(0)' onClick={() => setPaginaMenu('')}>
                                                                    <BsArrowLeftShort className='fz-40' />
                                                                    <span style={{ textTransform: 'capitalize' }}>
                                                                        {
                                                                            (() => {
                                                                                if (paginaMenu == 'projetos') {
                                                                                    return ("Projetos")
                                                                                } else if (paginaMenu == 'decoracao') {
                                                                                    return ("Decoração")
                                                                                } else if (paginaMenu == 'servicos') {
                                                                                    return ("Serviços")
                                                                                } else if (paginaMenu == 'beneficios') {
                                                                                    return ("Beneficios")
                                                                                }
                                                                            })()
                                                                        }
                                                                    </span>
                                                                </a>
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className='sidenav__function__btn__back'>
                                            <a href='javascript:void(0)' onClick={() => props.setOpenMenuResponsivo((prevState) => !prevState)}>
                                                <AiOutlineClose className='fz-25 top-5 tx-white' />
                                            </a>
                                        </div>
                                    </div>
                                    :
                                    <div className='sidenav__functions p-rlt d-fx d-ju-sp-bt d-al-cen' style={{ borderBottom: 'none' }}>

                                        <div className='sidenav__function__btn__back'>
                                            {
                                                (paginaMenu)
                                                    ?
                                                    <a className='d-fx d-al-cen tx-white' href='javascript:void(0)' onClick={() => setPaginaMenu('')}>
                                                        <BsArrowLeftShort className='fz-40' />
                                                        <span className='m-lef-5' style={{ textTransform: 'capitalize' }}>
                                                            {
                                                                (() => {
                                                                    if (paginaMenu == 'projetos') {
                                                                        return ("Projetos")
                                                                    } else if (paginaMenu == 'decoracao') {
                                                                        return ("Decoração")
                                                                    } else if (paginaMenu == 'servicos') {
                                                                        return ("Serviços")
                                                                    } else if (paginaMenu == 'beneficios') {
                                                                        return ("Beneficios")
                                                                    }
                                                                })()
                                                            }
                                                        </span>
                                                    </a>
                                                    : null
                                            }
                                        </div>
                                        <div className='sidenav__function__btn__back'>
                                            <a href='javascript:void(0)' onClick={() => props.setOpenMenuResponsivo((prevState) => !prevState)}>
                                                <AiOutlineClose className='fz-25 top-5 tx-white' />
                                            </a>
                                        </div>
                                    </div>
                            }
                            <div className='sidenav__menu'>
                                {
                                    (!paginaMenu)
                                        ?
                                        <div className='sidenav__main__menu'>
                                            <div className='box__single b-bot '>
                                                {
                                                    (props.user)
                                                        ?
                                                        <div className='header-myaccount p-lef-25'>
                                                            <div className='d-fx d-al-cen'>
                                                                {
                                                                    (props.user.photo)
                                                                        ?
                                                                        <img className='img__client' src={props.user.photo} width={54} height={54} />
                                                                        :
                                                                        <Avvvatars value={props.user.full_name} size={54} border={true} borderSize={2} borderColor="#006DFF" />
                                                                }
                                                                {/* 
                                                                        <Avvvatars value={props.user.full_name} size={54} style={{background: 'linear-gradient(white, white) padding-box, linear-gradient(to right, darkblue, darkorchid) border-box', borderRadius: '50em', border: '4px solid transparent'}} />*/}
                                                                <div className='infos-user-myaccount tx-lef m-lef-5'>
                                                                    <h3 className='tx-lig tx-white'>Olá, {props.user.given_name}</h3>
                                                                    {/* <a className='fz-16'><b>500</b> pts</a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='header-myaccount p-lef-25'>
                                                            <div className='d-fx d-al-cen'>
                                                                <div>
                                                                    <span class="css-b0j9sw-user-avatar-user-avatar--logged-out e1wzyyf96">
                                                                        <svg viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" role="presentation" class="css-9620ll-icon-icon--color-undefined-icon--size-16px">
                                                                            <path fill-rule="nonzero" d="M2.035 17.815c.336-3.163 2.339-5.803 5.06-6.922a5.232 5.232 0 002.905.891 5.232 5.232 0 002.906-.89c2.72 1.118 4.723 3.758 5.06 6.921-3.078.06-12.854.06-15.931 0M10 1.985c1.952 0 3.54 1.752 3.54 3.907 0 2.154-1.588 3.907-3.54 3.907S6.46 8.046 6.46 5.892c0-2.155 1.588-3.907 3.54-3.907m4.421 7.427a6.117 6.117 0 001.105-3.52C15.526 2.643 13.047 0 10 0 6.954 0 4.475 2.643 4.475 5.892A6.11 6.11 0 005.579 9.41l-.001.001C2.276 11.115 0 14.666 0 18.759c0 .996.858 1.014 1.85 1.035.56.011 1.357.022 2.304.029 3.913.032 6.858.055 9.076.055 6.753 0 6.756-.218 6.77-1.103v-.016c0-4.093-2.277-7.643-5.579-9.347"></path>
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                                {/* <Avvvatars value={props.user.full_name} size={54} border={true} borderSize={2} borderColor="#006DFF" /> */}
                                                                <div className='infos-user-myaccount tx-lef m-lef-15'>
                                                                    <h3 className='tx-lig tx-white m-bot-20'>Olá!</h3>
                                                                    <div className='d-fx d-al-cen d-fw-wp'>
                                                                        <Link to="/login" className="btn__default__model selected" style={{ marginLeft: 0, paddingLeft: '20px', paddingRight: '20px', }} onClick={() => toggleDrawer()}>
                                                                            Entrar
                                                                        </Link>
                                                                        <Link to="/login/sing-up" className="btn__default__model" style={(props.responsivoSize && props.responsivoSize > 420) ? { marginLeft: 0, paddingLeft: '20px', paddingRight: '20px', cursor: 'pointer', fontWeight: 'bold' } : { marginLeft: 0, marginTop: '10px', paddingLeft: '20px', paddingRight: '20px', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => toggleDrawer()}>
                                                                            Criar conta
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            {
                                                (props.user)
                                                    ?
                                                    <div className='box__single b-bot p-lef-15'>
                                                        <ul className='w100 p-rlt'>
                                                            <li className='w100'>
                                                                <Link to="/minha-conta/meus-dados" className='p-g-10 d-fx w100' onClick={() => toggleDrawer()}>Meus dados</Link>
                                                            </li>
                                                            <li className='w100'>
                                                                <Link to="/minha-conta/meus-pedidos" className='p-g-10 d-fx w100' onClick={() => toggleDrawer()}>Meus Pedidos</Link>
                                                            </li>
                                                            <li className='w100'>
                                                                <Link to="/minha-conta/fidelidade" className='p-g-10 d-fx w100' onClick={() => toggleDrawer()}>Programa de Fidelidade</Link>
                                                            </li>
                                                            <li className='w100'>
                                                                <Link to="/criar-projeto" className='p-g-10 d-fx w100' onClick={() => toggleDrawer()}>Meus Projetos</Link>
                                                            </li>
                                                            <li className='w100'>
                                                                <Link to="/minha-conta/meus-contratos" className='p-g-10 d-fx w100' onClick={() => toggleDrawer()}>Meus Contratos</Link>
                                                            </li>
                                                            <li className='w100'>
                                                                <Link to="/minha-conta/meus-orcamentos" className='p-g-10 d-fx w100' onClick={() => toggleDrawer()}>Meus Orçamentos</Link>
                                                            </li>
                                                            <li className='w100'>
                                                                <Link to="/minha-conta/acompanhamento" className='p-g-10 d-fx w100' onClick={() => toggleDrawer()}>Meus Acompanhamentos</Link>
                                                            </li>
                                                            {
                                                                (props.user && props.user.representante)
                                                                    ?
                                                                    <li className='w100'>
                                                                        <Link to="/minha-conta/acompanhamento" className='p-g-10 d-fx w100' onClick={() => setPaginaMenu('admininstrador')}>Administrador</Link>
                                                                    </li>
                                                                    : null
                                                            }
                                                        </ul>
                                                    </div>
                                                    : null
                                            }
                                            <div className='box__single b-bot p-lef-15'>
                                                <ul className='w100 p-rlt'>
                                                    <li className='w100'>
                                                        <a className='p-g-10 d-fx w100' onClick={() => setPaginaMenu('projetos')}>Projetos</a>
                                                    </li>
                                                    <li className='w100'>
                                                        <Link to="/ofertas" className='p-g-10 d-fx w100' onClick={() => toggleDrawer()}>Ofertas</Link>
                                                    </li>
                                                    <li className='w100'>
                                                        <a className='p-g-10 d-fx w100' onClick={() => setPaginaMenu('decoracao')}>Decoração</a>
                                                    </li>
                                                    <li className='w100'>
                                                        <a className='p-g-10 d-fx w100' onClick={() => setPaginaMenu('servicos')}>Serviços</a>
                                                    </li>
                                                    <li className='w100'>
                                                        <a className='p-g-10 d-fx w100' onClick={() => setPaginaMenu('beneficios')}>Beneficios</a>
                                                    </li>
                                                    <li className='w100'>
                                                        <Link to="/" className='p-g-10 d-fx w100'>Novidades</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            {
                                                (props.user)
                                                    ?
                                                    <div className='box__single p-lef-15'>
                                                        <ul>
                                                            <li className='w100'>
                                                                <a className='p-g-10 d-fx w100' onClick={(e) => deslogar(e)}>Sair</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        :
                                        <div className='sidenav__page__menu'>
                                            {
                                                (() => {
                                                    if (paginaMenu == 'projetos') {
                                                        return (
                                                            <div className='box__single b-bot p-lef-15'>
                                                                <ul>
                                                                    <li className='w100'>
                                                                        <Link to="/projetos" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <RiHomeSmile2Line className='fz-25' />
                                                                            <span className='p-lef-10'>Projetos Prontos</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className='w100'>
                                                                        <Link to="/projeto-personalizado" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <RiHomeGearLine className='fz-25' />
                                                                            <span className='p-lef-10'>Projetos Personalizados</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className='w100'>
                                                                        <Link to="/criar-projeto" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <RiHomeHeartLine className='fz-25' />
                                                                            <span className='p-lef-10'>Crie sua casa 3D</span>
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    } else if (paginaMenu == 'decoracao') {
                                                        return (
                                                            <div className='box__single b-bot p-lef-15'>
                                                                <ul>
                                                                    <li className='w100'>
                                                                        <Link to="/decoracao" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <GiPencilRuler className='fz-25' />
                                                                            <span className='p-lef-10'>Design de Interiores</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className='w100'>
                                                                        <Link to="/dicas-decoracao" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <BsBookmarkHeart className='fz-25' />
                                                                            <span className='p-lef-10'>Dicas de Decoração</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className='w100'>
                                                                        <Link to="/bricolagem" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <RiHandHeartLine className='fz-25' />
                                                                            <span className='p-lef-10'>Faça você mesmo</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className='w100'>
                                                                        <Link to="/blog-profissional" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <BsNewspaper className='fz-25' />
                                                                            <span className='p-lef-10'>Blog para Profissionais</span>
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    } else if (paginaMenu == 'servicos') {
                                                        return (
                                                            <div className='box__single b-bot p-lef-15'>
                                                                <ul>
                                                                    <li className='w100'>
                                                                        <Link to="/obras" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <IoConstructOutline className='fz-25' />
                                                                            <span className='p-lef-10'>Obras Realizadas</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className='w100'>
                                                                        <Link to="/portifolio" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <RiGalleryLine className='fz-25' />
                                                                            <span className='p-lef-10'>Portifólio</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className='w100'>
                                                                        <Link to="/sobre" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <RiContactsLine className='fz-25' />
                                                                            <span className='p-lef-10'>Sobre Nós</span>
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    } else if (paginaMenu == 'beneficios') {
                                                        return (
                                                            <div className={(subMenu) ? 'box__single b-bot p-lef-0' : 'box__single b-bot p-lef-15'}>
                                                                <ul>
                                                                    <li className='w100'>
                                                                        <Link to="/minha-conta/fidelidade" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <MdOutlinePriceCheck className='fz-25' />
                                                                            <span className='p-lef-10'>Programa Fidelidade</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className='w100'>
                                                                        <Link to="/parceiros" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <HiOutlineUserGroup className='fz-25' />
                                                                            <span className='p-lef-10'>Parceiros</span>
                                                                        </Link>
                                                                    </li>
                                                                    {/* <li className={(subMenu && subMenu == 'teste') ? 'p-g-10 submenu__active' : 'p-g-10'}>
                                                                        <a className='d-fx d-al-cen' onClick={() => setSubMenu('teste')}>
                                                                            <HiOutlineUserGroup className='fz-25' />
                                                                            <span className='p-lef-10'>Teste Sub menu</span>
                                                                        </a>
                                                                    </li> */}
                                                                </ul>
                                                            </div>
                                                        )
                                                    } else if (paginaMenu == 'admininstrador') {
                                                        return (
                                                            <div className={(subMenu) ? 'box__single b-bot p-lef-0' : 'box__single b-bot p-lef-15'}>
                                                                <ul>
                                                                    <li className='w100'>
                                                                        <Link to="/minha-conta/administrador/orcamentos" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <MdOutlinePriceCheck className='fz-25' />
                                                                            <span className='p-lef-10'>Orçamentos</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className='w100'>
                                                                        <Link to="/minha-conta/administrador/usuarios" className='d-fx d-al-cen p-g-10 d-fx w100' onClick={() => toggleDrawer()}>
                                                                            <HiOutlineUserGroup className='fz-25' />
                                                                            <span className='p-lef-10'>Clientes</span>
                                                                        </Link>
                                                                    </li>
                                                                    {/* <li className={(subMenu && subMenu == 'teste') ? 'p-g-10 submenu__active' : 'p-g-10'}>
                                                                        <a className='d-fx d-al-cen' onClick={() => setSubMenu('teste')}>
                                                                            <HiOutlineUserGroup className='fz-25' />
                                                                            <span className='p-lef-10'>Teste Sub menu</span>
                                                                        </a>
                                                                    </li> */}
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </Drawer>
                    : null
            }
            {
                (subMenu)
                    ?
                    <Drawer open={(subMenu) ? true : false} direction='right' className='mySidenav' zIndex='100' style={{ width: '94%', overflowX: 'hidden', boxShadow: 'inset .4375rem 0 .5625rem -.4375rem rgba(0,0,0,.7)' }}> {/* SIDEBAR - SUB MENU RESPONSIVO */}
                        <div className='header__sidenav p-g-'>
                            <div className='sidenav__submenu'>
                                <div className='sidenav__main__submenu'>
                                    <div className='box__single b-bot'>
                                        <ul>
                                            <li className='p-g-10'>
                                                <a className='d-fx d-al-cen'>
                                                    <HiOutlineUserGroup className='fz-25' />
                                                    <span className='p-lef-10'>Teste Sub menu 2</span>
                                                </a>
                                            </li>
                                            <li className='p-g-10'>
                                                <a className='d-fx d-al-cen' onClick={() => toggleDrawerSubMenu()}>
                                                    <HiOutlineUserGroup className='fz-25' />
                                                    <span className='p-lef-10'>Teste Sub menu 2</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                    : null
            }

            {/* SIDEBAR - FILTRO RESPONSIVO */}
            {
                (props.openFiltroResponsivo)
                    ?
                    <Drawer open={props.openFiltroResponsivo} direction='left' className='mySidenav w100 p-rlt' zIndex='99' style={{ width: '100%', overflowX: 'hidden', backgroundColor: 'rgb(238, 238, 240)' }}> {/* SIDEBAR - MENU RESPONSIVO */}
                        <div className='header__sidenav p-g-20 p-rlt' style={{ backgroundColor: 'var(--logo-color-dark)' }}>
                            <div className='sidenav__functions p-rlt d-fx d-ju-sp-bt d-al-cen'>
                                <div className='sidenav__function__btn__back'>
                                    <h1 className='tx-norm tx-white fz-22'>Filtrar: <strong>Projetos Prontos</strong></h1>
                                </div>
                                <div className='sidenav__function__btn__back'>
                                    <a cla href='javascript:void(0)' onClick={() => props.setOpenFiltroResponsivo((prevState) => !prevState)}>
                                        <AiOutlineClose className='fz-25 top-5 tx-white' />
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* TODO: Quando for filtar mais itens alem de projetos alterar o filtro */}
                        <div className='sidenav__filtro-main p-lef-20 p-rig-20' style={{ maxHeight: 'calc(100vh - 215px)', overflowY: 'scroll' }}>

                            {
                                (props.filtrosSelecionados && props.filtrosSelecionados.length > 0)
                                    ?
                                    <div id="filter_0" className="box-filter-single f-tipo m-top-10 m-bot-10">
                                        <div className="title-filter-single m-g-10">
                                            <h3 className="left">Filtros selecionados</h3>
                                        </div>

                                        <motion.div
                                            style={{ transformOrigin: "top", overflow: "hidden" }}
                                            animate={{ height: "auto" }}
                                            className="box-tags-filtro"
                                            id='box_tags'
                                        >
                                            {
                                                props.filtrosSelecionados.map((dadosElementos) => {
                                                    return (
                                                        <div class="filter-single-inline">
                                                            <div class="inline-tag">
                                                                <span class="text-tag" style={{ textTransform: 'capitalize' }}>{pegaDadosElementos(dadosElementos)}</span>
                                                                <button class="default btn-close-tag-single d-fx d-al-cen" type="button" onClick={(e) => removerParametroFiltro(e, dadosElementos.parametro, dadosElementos.valorParametro)}>
                                                                    <IoMdClose className='fz-15 tx-white m-lef-5' />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </motion.div>
                                    </div>
                                    : null
                            }
                            <FiltroOrdenacao projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />

                            <FiltroPavimentos projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />

                            <FiltroTerrenos projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />

                            <FiltroDormitorios projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />

                            <FiltroSuites projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />

                            <FiltroAreas projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />

                            <FiltroTipos projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />

                            <FiltroNiveis projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />

                            <FiltroInclinacoes projetos={props.projetos} filtrados={props.filtrados} setFiltrados={props.setFiltrados} filtrosSelecionados={props.filtrosSelecionados} setFiltrosSelecionados={props.setFiltrosSelecionados} filtrosParametros={props.filtrosParametros} setFiltrosParametros={props.setFiltrosParametros} />
                        </div>


                        <div className='sidenav__filtro-footer p-fix w100' style={{ padding: '24px calc(20px)', bottom: 0, background: '#fff' }}>
                            <div class="css-ry0yd2-filters-mobile__footer e1vw9a781">
                                <div class="css-vvm28p-stack-stack--alignX-center">
                                    <div>
                                        <div class="css-10lfwh0-filters-mobile__footer-filters-mobile__text e1vw9a780">
                                            <span class="css-1kxt2mk-text-text--bold-text--mega-heading--no-margin">{props.filtrados.length} </span>
                                            <span class="css-1kxt2mk-text-text--bold-text--mega-heading--no-margin">Produtos encontrados</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div role="group" aria-label="botões de filtros" label="botões de filtros" class="css-ip3p39-button-group-button-group--center-button-group--inline">
                                            <button disabled="" class="e1utufpw0 css-r31xhj-button" onClick={(el) => clearAllFilter(el)}>Limpar filtros</button>
                                            <button class="css-1mrxw8b-button" onClick={() => props.setOpenFiltroResponsivo((prevState) => !prevState)}>Aplicar filtros</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                    : null
            }
        </div >
    );
}
// TODO: Verificar qual filtro quer abrir e ai sim renderizar o componente
export default Sidebar;