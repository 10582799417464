import { useEffect, useState, useMemo } from 'react';
import $ from 'jquery';
import { db } from '../../../../../firebase';

import promoBox from '../../../../../images/projetos/jpg/promo_380x400.jpg';
import { Link } from 'react-router-dom';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from './Arrows';
import { BoxLazyload } from './Lazyload';
import toast from 'react-hot-toast';

function BoxOfertas(props) {

    const [ofertasPacotes, setOfertasPacotes] = useState([]); // IMAGENS DA PROMOÇÃO
    const [lazyload, setLazyload] = useState(true); // LAZYLOAD

    useEffect(() => {
        // PEGAR DADOS DO SLIDE E INSERIR NA SESSÃO
        if (!sessionStorage.getItem("pacotes_ofertas")) {
            if (!ofertasPacotes || !ofertasPacotes.length) {
                (async () => {
                    try {
                        const cuponsRef = db.collection("marketing").doc("ofertas").collection("pacotes").where("status", "==", true);
                        const snapshot = await cuponsRef.get();
                        if (snapshot.empty) {
                            toast.error("Não existe cupom de oferta disponível no momento!");
                            return;
                        }
                        var arrPacotes = ([]);
                        snapshot.forEach(doc => {
                            arrPacotes.unshift(doc.data());
                        });

                        sessionStorage.setItem('pacotes_ofertas', JSON.stringify(arrPacotes));
                        setOfertasPacotes(arrPacotes);
                    } catch (error) {
                        toast.error("Ocorreu um erro ao recuperar os pacotes de ofertas!");
                        console.log(error);
                    }
                })()
            }
        } else {
            // RECUPERAR DADOS SALVOS NA SESSÃO
            let dadosPacotes = JSON.parse(sessionStorage.getItem('pacotes_ofertas'));
            setOfertasPacotes(dadosPacotes);
        }
    }, [])

    useMemo(() => {
        if (ofertasPacotes && ofertasPacotes.length) {
            setLazyload(false);
        }
    }, [ofertasPacotes]);

    let rows = [];
    for (let i = 1; i <= 3; i++) {
        rows.push(<BoxLazyload />)
    }

    return (
        <section className="newsletter p-rlt container__content w100 z-less">
            <div className="center">
                {/* <div className="promo-app-content w100 d-fx d-ju-sp-bt d-fw-wp"> */}
                <div className={(lazyload) ? "promo-app-content w100 d-fx d-ju-sp-bt d-fw-wp" : "promo-app-content w100 d-fx d-ju-cen d-fw-wp"}>
                    {
                        (!lazyload)
                            ?
                            (ofertasPacotes && ofertasPacotes.length > 0)
                                ?
                                ofertasPacotes.map((dadosOferta) => {
                                    return (
                                        <Link to={dadosOferta.link} title={dadosOferta.titulo} className='promo-box-single m-g-5'>
                                            <img className='w95' src={dadosOferta.capa} alt={dadosOferta.titulo} title={dadosOferta.titulo} style={{ borderRadius: '16px' }} />
                                        </Link>
                                    )
                                })
                                : null
                            :
                            rows
                    }
                </div>{/* promo-app-content */}
            </div>{/* center */}
        </section>
    )
}

export default BoxOfertas;