import '../Assets/styles/css/Main.css';
import axios from 'axios';
import { auth, db, fs } from '../../../../firebase';
import { useEffect, useMemo, useState } from 'react';

// import ImageGallery from 'react-image-gallery';
// import "react-image-gallery/styles/css/image-gallery.css";
// IMPORTAÇÃO DE PLAYER DE VIDEO
// ESTILO - VIDEO
import "../../../../../node_modules/video-react/dist/video-react.css"; // import css
// FUNÇÃO - VIDEO
import { Player } from 'video-react';

// IMPORTAÇÃO DE ZOOM DE IMAGEM
// ESTILO - ZOOM - IMAGEM
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
// FUNÇÃO - ZOOM - IMAGEM
import InnerImageZoom from 'react-inner-image-zoom'

// IMPORTAÇÃO DE CARROSEL DE IMAGENS
import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from '../Assets/components/Arrows';

// IMPORTAÇÃO DE TOUR VIRTUAL
// import TourTeste from './TourTeste';

// import Marzipano from 'react-marzipano';

import toast from 'react-hot-toast';


// IMPORTAÇÃO DE IMAGENS
// ICONES
import iconPano from '../../../../images/icones/png/360.png';
import iconPlay from '../../../../images/icones/png/play.png';
import iconCreditCard from '../../../../images/icones/svg/icon-credit-card.svg';
import iconEasyReturns from '../../../../images/icones/svg/icon-easy-returns.svg';
import iconFreeDelivery from '../../../../images/icones/svg/icon-free-delivery.svg';
// import video from '../../../../videos/video-demo.mp4';

// REACT ICONS
import { BsCartPlusFill, BsClipboardCheck, BsHeartFill, BsShareFill, BsCheck2Circle, BsFillCalendarEventFill, BsChevronRight, BsCollectionPlay } from 'react-icons/bs';
import { FaBan, FaStar, FaShareSquare, FaHeart, FaEye } from 'react-icons/fa';
import { AiOutlineMinusCircle, AiOutlinePlusCircle, AiFillInfoCircle, AiOutlineCheck } from 'react-icons/ai';
import { MdVrpano, MdOutlineArrowBack, MdOutlineVrpano, MdMoreVert, MdClose, MdOutlineArrowBackIos } from 'react-icons/md';
import { RiImageEditFill, RiFilePaper2Line } from 'react-icons/ri';
import { GoMirror } from 'react-icons/go';
import { SiGooglecardboard } from 'react-icons/si';
import { IoMdImages } from 'react-icons/io';
import { TbCardboards } from 'react-icons/tb';


// IMAGENS
import home1 from '../../../../images/projetos/png/home1.png';
import promo from '../../../../images/projetos/png/Projetos Prontos.png';
import plantaBaixa from '../../../../images/projetos/jpg/PB.jpg';

// IMPORTAÇÃO DE REACT ROUTER
import { Link, useParams } from 'react-router-dom';


import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';
import firebase from 'firebase';
import FAQProjetosProntos from '../Assets/components/FAQProjetosProntos';
import CardsProjetos from '../Assets/components/CardsProjetos';
import { WidgetParceiros } from '../Assets/components/BoxParceiros';
import Depoimentos from '../Assets/components/Depoimentos';


import { AnimateSharedLayout } from "framer-motion";
import PageSlider from '../Assets/components/PageSlider';
import BoxInfoProjeto from '../Assets/components/BoxInfoProjeto';
import BoxPlantasProjeto from '../Assets/components/BoxPlantasProjeto';
import { isEmptyObject } from 'jquery';
import bloqueioCopia from '../Assets/funcoes/bloqueioCopia';
import { WidgetDepartamentos } from '../Assets/components/Departamentos';
import BoxDescricaoProduto from '../Assets/components/BoxDescricaoProduto';
import BoxItensProduto from '../Assets/components/BoxItensProduto';

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};
// const images = [
//     "https://projetos.eengeart.com/Views/Painel/uploads/61fa679038a3a.jpg",
//     "https://projetos.eengeart.com/Views/Painel/uploads/61fa686d32918.jpg",
//     "https://projetos.eengeart.com/Views/Painel/uploads/61fa682bb9c49.jpg",
//     "https://projetos.eengeart.com/Views/Painel/uploads/61fa6678bfb83.jpg"
// ];


function ProjetoSingle(props) {
    const [[page, direction], setPage] = useState([0, 0]);

    // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
    // then wrap that within 0-2 to find our image ID in the array below. By passing an
    // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
    // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
    // const imageIndex = wrap(0, images.length, page);
    // let imageIndex = null;

    const paginate = (newDirection) => {
        console.log([page + newDirection, newDirection]);
        setPage([page + newDirection, newDirection]);
    };



    const [tourOpen, setTourOpen] = useState(false);
    const [tourPano, setPanoOpen] = useState(false);
    const [complementares, setComplementares] = useState([]);
    const [statusView, setStatusView] = useState(false);
    const [projetos, setProjetos] = useState([]);
    const [visualizacao, setVisualizacao] = useState([]);
    const [favoritados, setFavoritados] = useState([]);

    const [visualizacaoGaleria, setVisualizacaoGaleria] = useState(null);
    const [openMenuGaleria, setOpenMenuGaleria] = useState(false);
    const [expandedDescription, setExpandedDescription] = useState(false);
    const [expandedItens, setExpandedItens] = useState(false);

    // const images = [
    //     {
    //         original: 'https://picsum.photos/id/0/367/267',
    //         thumbnail: 'https://picsum.photos/id/0/367/267',
    //         originalTitle: 'Lorem'
    //     },
    //     {
    //         original: 'https://picsum.photos/id/1001/367/267',
    //         thumbnail: 'https://picsum.photos/id/1001/367/267',
    //         originalTitle: 'Ipisum'
    //     },
    //     {
    //         original: 'https://picsum.photos/id/1002/367/267',
    //         thumbnail: 'https://picsum.photos/id/1002/367/267',
    //         originalTitle: 'Dolor'
    //     }
    // ]

    let { id } = useParams();

    const [data, setData] = useState([]);
    const [projeto, setProjeto] = useState([]);

    useEffect(() => {
        // PUXANDO PROJETO EM ESPECIFICO
        // db.collection("produtos").doc("projetos").collection("residencial").onSnapshot((projetos) => {
        //     var arrProjeto = ([]);
        //     var arrComplementares = ([]);
        //     projetos.docs.map((dadosProjeto) => {
        //         if (dadosProjeto.id === id) {
        //             arrProjeto.push(dadosProjeto.data());
        //             arrComplementares.unshift(dadosProjeto.data().adicionais);
        //         }
        //     })
        //     setProjeto(arrProjeto[0]);
        //     setComplementares(arrComplementares[0]);
        // })

        // // PUXANDO TODOS OS PROJETO DO BD
        // db.collection("produtos").doc("projetos").collection("residencial").onSnapshot((projetos) => {
        //     var arrProjetos = ([]);
        //     projetos.docs.map((dadosProjetos) => {
        //         arrProjetos.push(dadosProjetos.data());
        //     })
        //     setData(arrProjetos);
        // })

        // CADASTRANDO VISUALIZACAO
        // db.collection("relatorios").doc("visualizacoes").collection("projetos").add({
        //     data_visualizacao: firebase.firestore.FieldValue.serverTimestamp(),
        //     categoria: "projetos",
        //     id: id
        // })
        // PEGANDO TODOS OS PROJETOS
        // db.collection("produtos").doc("projetos").collection("residencial").where("status", "==", true).onSnapshot((infos) => {
        //     var arr = ([]);
        //     infos.docs.map((dados) => {
        //         arr.unshift(dados.data());
        //     })
        //     setProjetos(arr);
        // })

        // db.collection("relatorios").doc("visualizacoes").collection("projetos").onSnapshot((allViews) => {
        //     var arr = ([]);
        //     allViews.docs.map((dataViews) => {
        //         arr.unshift(dataViews.data());
        //     })
        //     setVisualizacao(arr);
        // })

        // db.collection("relatorios").doc("visualizacoes").collection("favoritos").onSnapshot((allLike) => {
        //     var arr = ([]);
        //     allLike.docs.map((dataLike) => {
        //         arr.unshift(dataLike.data());
        //     })
        //     setFavoritados(arr)
        // })

    }, []);

    function toggleClass(e) {
        e.preventDefault();
        e.currentTarget.classList.toggle('is-open');
    }

    function toggleCart() {
        props.setOpenCart((prevState) => !prevState);
    }

    function getCodeItem(item) {
        item.adicionais.map((dados) => {
            if (dados.adicionais == "arquitetonico") {
                return (dados.id)
            }
        })
    }

    function addtocart(item) {
        props.dadosProjeto.map((i) => {
            if (i.id == item.id) {
                i.cart = true
            }
        })

        if (props.user && !isEmptyObject(props.user)) {
            auth.onAuthStateChanged((val) => {
                db.collection('usuarios').doc(val.uid).collection('carrinho').doc(`${item.id}`).set(item, { merge: true })
                    .then(() => {
                        db.collection('usuarios').doc(val.uid).collection('carrinho').orderBy("orderId", "desc").onSnapshot((dados_carrinho) => {
                            var produtos_carrinho = ([]);
                            dados_carrinho.docs.map((dados_produto) => {
                                produtos_carrinho.unshift(dados_produto.data());
                            })

                            // DEFINIR VALORES RECUPERADOS (HOOK)
                            props.setCart(produtos_carrinho);
                        })
                        props.setOpenCart((prevState) => !prevState)
                    })
                    .catch((error) => {
                        toast.error("Ocorreu um erro ao adicionar seu produto ao carrinho");
                    })
            })
        } else {
            // USUARIO NÃO ESTÁ LOGADO
            toast.error("Efetue login para continuar com sua compra!");
        }

        // CRIAR OBJETO
        // let arrCart = (
        //     {
        //         "produto": {
        //             "tituloProduto": item.titulo,
        //             "categoria": item.categoria,
        //             // "disciplina": (item.categoria.toLowerCase() == "projetos prontos") ? "Projeto Arquitetônico" : null,
        //             "disciplina": "arquitetonico",
        //             "codProduto": item.id,
        //             "capa": item.capa,
        //             "preco": item.precoTabela,
        //             "desconto": item.desconto,
        //             "lancamento": item.lancamento,
        //             "vendedor": "Engeart",
        //             "entregador": "Engeart" // FAZER UMA VALIDAÇÃO E PUXAR QUEM REALMENTE É O VENDEDOR
        //         },
        //         "envio": item.entrega[0],
        //         "quantidade": 1
        //     }
        // );
        // console.log(arrCart)
        // var uniqueId = Math.floor(Math.random() * new Date().getTime());
        // console.log(uniqueId)

        // if (props.user) {
        //     db.collection('usuarios')
        //         .doc(props.user.id)
        //         .collection('carrinho')
        //         .doc(`${item.orderId}`)
        //         .set(
        //             arrCart
        //             , { merge: true })
        //         .then(() => {
        //             props.setOpenCart((prevState) => !prevState)
        //         })
        // }

        // var timestamp = new Date();
        // let dateLaunch = new Date(item.lancamento.seconds);
        // var date = new Date(dateLaunch*1000); // converte para data
        // console.log(date.toLocaleDateString("pt-BR"));

        // COMPARANDO DATA DE LANÇAMENTO COM DATA ATUAL - FUNCIONANDO
        // if (new Date(item.lancamento.seconds * 1000).getTime() > new Date().getTime()) {
        //     console.log("Não foi lançado")
        // } else if (new Date(item.lancamento.seconds * 1000).getTime() < new Date().getTime()) {
        //     console.log("Foi lançado")
        // } else if (new Date(item.lancamento.seconds * 1000).getTime() === new Date().getTime()) {
        //     console.log("Tbm foi lançado podemos disponibilizar!");
        // } else {
        //     console.log(date)
        //     console.log(dateNow)
        // }

        // auth.onAuthStateChanged((val) => {
        // db.collection('usuarios')
        // .doc(props.user.id)
        // .collection('carrinho')
        // .doc(`${item.codProduto}`)
        // .set(
        //     {
        //     item
        // }
        // , { merge: true })
        // .then(() => {
        //     props.setOpenCart((prevState) => !prevState)
        // })
        // })
    }
    // TODO: TENHO QUE FAZER UMA VALIDAÇÃO PARA VERIFICAR SE O USUARIO ESTÁ LOADO, SE NÃO ESTIVER CRIO UM CARRINHO NA SESSÃO DELE

    useEffect(() => {
        // db.collection('projetos').doc('principais').collection(id).orderBy("id", "asc").onSnapshot((val) => {
        //     var myArr = [];
        //     val.docs.map(result => {
        //         myArr.push(result.data());
        //     })
        //     props.setDadosProjeto(myArr);
        // })
        // console.error(props.cart);

        // db.collection("produtos").doc("projetos").collection("residencial").where("orderId", "==", parseInt(id)).onSnapshot((val) => {
        //     var projetosAdiciaionais = new Array();
        //     val.docs.map((dadosProjetos) => {
        //         if (dadosProjetos.data().adicionais) {
        //             projetosAdiciaionais.unshift(dadosProjetos.data().adicionais);
        //         }
        //     })

        //     props.setDadosProjeto(projetosAdiciaionais)
        // })
    }, [])

    useMemo(() => {
        if (props.projetos && props.projetos.length) {
            let produtos = props.projetos;
            let dados_recuperados_projeto = produtos.filter(produto => produto.orderId == id)

            if (dados_recuperados_projeto.length && dados_recuperados_projeto.length === 1) {
                // console.log("Dados Projeto", dados_recuperados_projeto);
                setProjeto(dados_recuperados_projeto[0]);
                setComplementares(dados_recuperados_projeto[0].adicionais);
            } else {
                toast.error("Não foi possivel recuperar os dados do produto");
            }
        }
    }, [props.projetos])

    function abrirTour(e) {
        e.preventDefault();
        setTourOpen((prevState) => !prevState);
    }

    function abrirPano(e) {
        e.preventDefault();
        setPanoOpen((prevState) => !prevState);
    }


    // const scenes = [
    //     { current: true, imageUrl: 'https://www.marzipano.net/media/equirect/angra.jpg', type: 'equirect' }
    // ]

    function pegaItensArquitetonicos() {
        var item = null
        complementares.map((itensAdicionais) => {
            if (itensAdicionais.slug == "arquitetonico") { // ALTERAR == POR !=
                if (itensAdicionais.itens && itensAdicionais.itens.length) {
                    itensAdicionais.itens.map((subItensAdicionais) => {
                        item = subItensAdicionais.nome;
                    })
                }
            }
        })
        return item
    }


    //  INICIO - FUNÇÕES FRAMER MOTION
    // FIM - FUNÇÕES FRAMER MOTION

    function definirVisualizacaoGaleria(param) {
        if (param == 'video') {
            setVisualizacaoGaleria("video");
        } else if (param == 'pano') {
            setVisualizacaoGaleria("pano");
        } else if (param == 'tour') {
            setVisualizacaoGaleria("tour");
        } else {
            setVisualizacaoGaleria(null)
        }

        if (props.responsivoSize && props.responsivoSize < 530 && openMenuGaleria) {
            setOpenMenuGaleria(false)
        }
    }


    return (
        <div id='projetoSingle'>
            <div className='projeto-single-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    {
                                        (props.responsivoSize && props.responsivoSize >= 600)
                                            ?
                                            <ol className="breadcrumb-list">
                                                <li className="breadcrumb-item">
                                                    <Link to="/">Início</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/projetos">Projetos</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/projetos/projetos-prontos">Projetos Prontos</Link>
                                                </li>
                                                <li className="breadcrumb-item active">{projeto.titulo}</li>
                                            </ol>
                                            :
                                            <ol className="breadcrumb-list">
                                                <li className="breadcrumb-item">
                                                    <Link to="/projetos/projetos-prontos" className='d-fx d-al-cen'>
                                                        <MdOutlineArrowBackIos className='m-rig-5' />
                                                        Projetos Prontos
                                                    </Link>
                                                </li>
                                            </ol>
                                    }
                                </nav>
                            </div>
                            {
                                (props.responsivoSize && props.responsivoSize >= 530)
                                    ?
                                    <div className="grid product d-fx d-ju-sp-bt d-fw-wp w100"> {/* DESKTOP */}
                                        <div className="column-xs-12 column-md-7 w60 m-0">
                                            <div className="product-gallery w100">
                                                {/* <div className="product-image p-rlt">
                                            <Player
                                            playsInline
                                            poster={home1}
                                            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                            />
                                        </div>
                                        <ul className="image-list" style={{marginTop: '10px'}}>
                                            <li className="image-item p-rlt">
                                                <img className="img-norm-proj" src={home1} />
                                                <img className="p-abs img-icon" src={iconPlay} alt="" srcset="" />
                                            </li>
                                            <li className="image-item p-rlt">
                                                <img className="img-norm-proj" src={home1} />
                                                <img className="p-abs img-icon" src={iconPano} alt="" srcset="" />
                                            </li>
                                            <li className="image-item p-rlt">
                                                <img className="img-norm-proj" src={home1} />
                                            </li>
                                        </ul> */}
                                                {/* <ImageGallery 
                                            items={images} 
                                            lazyLoad={true}
                                        /> */}
                                                <div className='box__galeria__visualizacao__projeto p-rlt'>
                                                    {
                                                        (!visualizacaoGaleria || visualizacaoGaleria == 'imagem')
                                                            ?
                                                            <div className='product-image p-rlt' onDragStart={e => { e.preventDefault() }} onContextMenu={(e) => bloqueioCopia(e, props.setOpenModal)}>
                                                                {
                                                                    (projeto.imagens)
                                                                        ?
                                                                        <div className="image-list d-ju-rig">
                                                                            <AnimatePresence initial={false} custom={direction} className="p-rlt">
                                                                                <motion.img
                                                                                    className='tag__imagem'
                                                                                    key={page}
                                                                                    src={projeto.imagens[wrap(0, projeto.imagens.length, page)]}
                                                                                    custom={direction}
                                                                                    variants={variants}
                                                                                    initial="enter"
                                                                                    animate="center"
                                                                                    exit="exit"
                                                                                    transition={{
                                                                                        x: { type: "spring", stiffness: 300, damping: 30 },
                                                                                        opacity: { duration: 0.2 }
                                                                                    }}
                                                                                    drag="x"
                                                                                    dragConstraints={{ left: 0, right: 0 }}
                                                                                    dragElastic={1}
                                                                                    onDragEnd={(e, { offset, velocity }) => {
                                                                                        const swipe = swipePower(offset.x, velocity.x);

                                                                                        if ((wrap(0, projeto.imagens.length, page) !== (projeto.imagens.length - 1)) && (swipe < -swipeConfidenceThreshold)) {
                                                                                            paginate(1);
                                                                                        } else if ((wrap(0, projeto.imagens.length, page) !== 0) && (swipe > swipeConfidenceThreshold)) {
                                                                                            paginate(-1);
                                                                                        }
                                                                                    }}
                                                                                    onDrag={(e) => e.preventDefault()}
                                                                                />
                                                                            </AnimatePresence>
                                                                            {/* <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', background: 'rgba(255, 255, 255, 0.7)', zIndex: 3, padding: '0px 10px', bottom: '5px', left: '50%', transform: 'translateX(-50%)', borderRadius: '20px', fontSize: '12px', color: 'rgb(60, 55, 56)' }}>
                                                                                <p>{wrap(0, projeto.imagens.length, page) + 1}</p>
                                                                                <p style={{ margin: '0 2px' }}>/</p>
                                                                                <p>{projeto.imagens.length}</p>
                                                                            </div> */}

                                                                            <div class="sc-iCfMLu gZZBOx rec rec-pagination" style={{ position: 'absolute', bottom: '3px', zIndex: 3, left: '50%', transform: 'translateX(-50%)' }}>
                                                                                {
                                                                                    projeto.imagens.map((dados, index) => {
                                                                                        return (<button
                                                                                            key={index}
                                                                                            tabindex={index}
                                                                                            class={(wrap(0, projeto.imagens.length, page) === index) ? "sc-gKclnd lcUbOd rec rec-dot rec rec-dot_active" : "sc-gKclnd eaptOe rec rec-dot"}
                                                                                            type="button"
                                                                                            title={`Visualização ${index + 1} de ${projeto.imagens.length}`}
                                                                                            onClick={(e) => setPage([index, (page > index) ? -1 : 1])}
                                                                                        ></button>);
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <button
                                                                                className="prev btn__controller__slides"
                                                                                onClick={() => {
                                                                                    if (wrap(0, projeto.imagens.length, page) !== 0) {
                                                                                        paginate(-1)
                                                                                    }
                                                                                }}
                                                                                disabled={
                                                                                    (wrap(0, projeto.imagens.length, page) === 0)
                                                                                        ?
                                                                                        true
                                                                                        :
                                                                                        false
                                                                                }
                                                                                style={{ zIndex: 3 }}
                                                                            >
                                                                                <ArrowLeft />
                                                                            </button>
                                                                            <button
                                                                                className="next btn__controller__slides"
                                                                                onClick={() => {
                                                                                    if (wrap(0, projeto.imagens.length, page) !== (projeto.imagens.length - 1)) {
                                                                                        paginate(1);
                                                                                    }
                                                                                }}
                                                                                disabled={
                                                                                    (wrap(0, projeto.imagens.length, page) === (projeto.imagens.length - 1))
                                                                                        ?
                                                                                        true
                                                                                        :
                                                                                        false
                                                                                }
                                                                                style={{ zIndex: 3 }}
                                                                            >
                                                                                <ArrowRight />
                                                                            </button>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            :
                                                            (() => {
                                                                if (visualizacaoGaleria && visualizacaoGaleria == "video") {
                                                                    return (
                                                                        <Player
                                                                            playsInline
                                                                            poster={home1}
                                                                            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                                                        />
                                                                    )
                                                                } else if (visualizacaoGaleria && visualizacaoGaleria == "pano") {
                                                                    return (
                                                                        <div className="box-preview-pbh m-top-20 w100">
                                                                            {/* <Marzipano scenes={scenes} className="myMarzi w100" style={{ position: 'relative', aspectRatio: '16/9' }} /> */}
                                                                        </div>
                                                                    )
                                                                } else if (visualizacaoGaleria && visualizacaoGaleria == "tour") {
                                                                    return (
                                                                        <div className="box-preview-pbh m-top-20 w100"> {/* BOX DE TOUR VIRTUAL */}
                                                                            {/* <TourTeste /> */}
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                    }
                                                </div>
                                            </div>

                                            <div className='opcoes__visualizacao__projeto m-top-10 w100'>
                                                <h3 className='fz-16'>Opções de visualização do projeto</h3>
                                                <div className='funcoes__visualizacao__projeto w100 d-fx d-al-cen d-fw-wp d-ju-sp-bt'>
                                                    <button className={(!visualizacaoGaleria || visualizacaoGaleria == "imagem") ? "d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado active" : "d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado"} onClick={() => definirVisualizacaoGaleria("imagem")}>
                                                        <IoMdImages className='m-rig-5 fz-16' />
                                                        <span className=' fz-12'>Imagens</span>
                                                        <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                                                            <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                                                        </p>
                                                        <div className="clear"></div>
                                                    </button>

                                                    <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                        <BsCollectionPlay className='m-rig-5 fz-14' />
                                                        <span className=' fz-12'>Video</span>
                                                        <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                                                            <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                                                        </p>
                                                        <div className="clear"></div>
                                                    </button>

                                                    <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                        <MdOutlineVrpano className='m-rig-5 fz-16' />
                                                        <span className=' fz-12'>Tour 360º</span>
                                                        <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                                                            <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                                                        </p>
                                                        <div className="clear"></div>
                                                    </button>

                                                    <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                        <TbCardboards className='m-rig-5 fz-16' />
                                                        <span className=' fz-12'>Tour Interativo</span>
                                                        <p style={{ margin: '0 5px' }} className="d-flex-center" data-cooltipz-dir="top" data-cooltipz-size="medium" aria-label="A definir">
                                                            <i title="Ajuda" className="fa-regular fa-circle-question f-sz-10"></i>
                                                        </p>
                                                        <div className="clear"></div>
                                                    </button>
                                                </div>
                                            </div>

                                            {/* DIV - PROJETOS ADICIONAIS */}
                                            {
                                                (complementares && complementares.length > 1)
                                                    ?
                                                    <div className="widget-wrapper widget-adicional p-rlt p-top-25 p-bot-25">
                                                        <h3 className='sub-title d-bk'>Projetos adicionais</h3>
                                                        {/*<h3 className='sub-title d-bk'>Projetos adicionais disponíveis</h3> */}

                                                        < Carousel
                                                            itemsToShow={4}
                                                            className="w50 hide-dots"
                                                            renderArrow={({ type, onClick, isEdge }) => {
                                                                const pointer = type === consts.PREV ? <ArrowLeft /> : <ArrowRight />
                                                                return (
                                                                    <button className='btn__controller__carousel' onClick={onClick} disabled={isEdge} style={{ zIndex: 1 }}>
                                                                        {pointer}
                                                                    </button>
                                                                )
                                                            }}
                                                        >
                                                            {
                                                                complementares.map((dadosComplementares) => {
                                                                    if (dadosComplementares.slug != "arquitetonico") { // ALTERAR == POR !=
                                                                        return (
                                                                            <div className="swiper-slide-single m-rig-15">
                                                                                <a href="javascript:void(0)" title={dadosComplementares.titulo} itemprop="url" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                                                    <div className="box-swiper-single">
                                                                                        <div className="capa-swiper-single p-rlt">
                                                                                            {
                                                                                                (dadosComplementares.tipo_icone == 'svg')
                                                                                                    ?
                                                                                                    <div width="125" height="125" class="css-vp7bse-category-carousel__thumb-category-carousel__thumb-svg-thumb__icon e1u7pvy50">
                                                                                                        <svg viewBox="0 0 40 72" fill="currentColor" aria-hidden="true">
                                                                                                            <path fill-rule="nonzero" d={dadosComplementares.icone}></path>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        <div className="title-swiper-single fz-14">
                                                                                            <h3 itemprop="name">{dadosComplementares.titulo}</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </Carousel>

                                                    </div>
                                                    : null
                                            }
                                        </div>

                                        <div className="column-xs-12 column-md-5 w40 m-0">
                                            <div className="box-header-proj-single b-bot">
                                                <h1>{projeto.titulo}</h1>
                                                <div className="box-valor d-fx d-ju-sp-bt d-al-cen d-fw-wp w100">
                                                    <div className="d-fx d-ju-sp-bt d-al-cen d-fw-wp">
                                                        <div className="valor-atual m-rig-5">
                                                            <h2>{(projeto.precoFinal) ? projeto.precoFinal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null}</h2>
                                                        </div>
                                                        <div className="valor-original m-lef-5">
                                                            <p>{(projeto.precoTabela) ? projeto.precoTabela.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null}</p>
                                                        </div>
                                                    </div>
                                                    <div className='d-fx'>
                                                        {
                                                            (projeto.desconto && projeto.desconto > 0)
                                                                ?
                                                                <div className="descontos m-rig-5 m-lef-5">
                                                                    <span>-{projeto.desconto}%</span>
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            (() => {
                                                                if (projeto && projeto.status && projeto.lancamento && new Date(projeto.lancamento.seconds * 1000).getTime() > new Date().getTime()) {
                                                                    return (
                                                                        <div className="descontos pre__lancamento m-rig-5 m-lef-5">
                                                                            <span>Pré Venda</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                </div>
                                                <div className='w100 d-fx d-al-cen d-ju-sp-bt d-fw-wp'>
                                                    <div id="full-stars-example">
                                                        <div className="rating-group">
                                                            <input className="rating__input rating__input--none" name="rating" id="rating-none" value="0" type="radio" />
                                                            <label aria-label="No rating" className="rating__label" for="rating-none">
                                                                <FaBan className="rating__icon rating__icon--none" />
                                                            </label>

                                                            <label aria-label="1 star" className="rating__label" for="rating-1">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />

                                                            <label aria-label="2 stars" className="rating__label" for="rating-2">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />

                                                            <label aria-label="3 stars" className="rating__label" for="rating-3">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" />

                                                            <label aria-label="4 stars" className="rating__label" for="rating-4">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />

                                                            <label aria-label="5 stars" className="rating__label" for="rating-5">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" checked />
                                                        </div>
                                                        <span className='m-lef-5'>5/5</span>
                                                    </div>{/*full-stars-example*/}
                                                    <div className='p-rlt'>
                                                        <div class="bookmarks_infos d-fx d-al-cen" style={{ right: '0px' }}>
                                                            <span class="fz-11" style={{ background: 'rgba(225, 225, 225, 0.5)', padding: '3px 10px 3px 15px', borderRadius: '20px' }}>Cód.: E{id}A</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='resumo__infos__projeto'>
                                                <BoxDescricaoProduto descricao={projeto.descricao} />

                                                <BoxItensProduto complementares={complementares} />
                                            </div>
                                            <div className="box-buttons m-top-10">
                                                <button className="cart m-lef-5 m-rig-5" style={{ marginTop: 0 }} onClick={() => addtocart(projeto)}>
                                                    <BsCartPlusFill className='m-rig-5' />
                                                    Adicionar ao carrinho
                                                </button>
                                                {
                                                    (() => {
                                                        if (!props.cart || !props.cart && props.dadosProjeto) { // NÃO TEM NADA NO CARRINHO
                                                            props.dadosProjeto.map((item) => {
                                                                if (item.categoria == 'arquitetonico')
                                                                    return (
                                                                        <button className="cart m-lef-5 m-rig-5" style={{ marginTop: 0 }} onClick={() => addtocart(item)}>
                                                                            <BsCartPlusFill className='m-rig-5' />
                                                                            Adicionar ao carrinho
                                                                        </button>
                                                                    )
                                                            })
                                                        } else if (props.cart && props.dadosProjeto) { // EXISTE PRODUTOS NO CARRINHO
                                                            // GERANDO ARRAY COM ID DOS PROJETOS QUE ESTÃO NO CARRINHO
                                                            var arrCodCarrinho = ([]);
                                                            props.cart.map((dados) => {
                                                                if (dados.slug == "arquitetonico") {
                                                                    arrCodCarrinho.unshift(dados.codProduto);
                                                                }
                                                            })

                                                            var posicaoElemento = arrCodCarrinho.includes(parseInt(id))

                                                            if (posicaoElemento) {
                                                                return (
                                                                    <button className="cart m-lef-5 m-rig-5" style={{ marginTop: 0 }} onClick={() => { props.setOpenCart((prevState) => !prevState) }}>
                                                                        <BsCartPlusFill className='m-rig-5' />
                                                                        Abrir carrinho
                                                                    </button>
                                                                )
                                                            } else {
                                                                return (
                                                                    props.dadosProjeto[0].map((item) => {
                                                                        if (item.categoria == "arquitetonico") {
                                                                            return (
                                                                                <button className="cart m-lef-5 m-rig-5" style={{ marginTop: 0 }} onClick={() => addtocart(item)}>
                                                                                    <BsCartPlusFill className='m-rig-5' />
                                                                                    Adicionar ao carrinho
                                                                                </button>
                                                                            )
                                                                        }
                                                                    })
                                                                )
                                                            }
                                                        } else {
                                                            return (
                                                                <button className="cart m-lef-5 m-rig-5" style={{ marginTop: 0 }} onClick={() => { props.setOpenCart((prevState) => !prevState) }}>
                                                                    <BsCartPlusFill className='m-rig-5' />
                                                                    Abrir carrinho
                                                                </button>
                                                            )
                                                        }
                                                    })

                                                }
                                                <button className="personalizado m-lef-5 m-rig-5 m-top-10 m-bot-10" style={{ marginTop: 0 }} onClick={() => toast.error("Esta função está em desenvolvimento")}>
                                                    <BsHeartFill />
                                                </button>
                                                <button className="personalizado m-lef-5 m-rig-5 m-top-10 m-bot-10" title='Compartilhar' onClick={(el) => props.setOpenModal({ "status": true, "code": id, "categoria": "projetos_prontos_individual", "tipo": "share", "link": `/projetos/${id}` })} style={{ marginTop: 0 }}>
                                                    <BsShareFill />
                                                </button>
                                            </div>

                                            <div className="product-payinfo">
                                                <div className="item">
                                                    <span>
                                                        <div className='p-abs'>
                                                            <img src={iconCreditCard} />
                                                        </div>
                                                    </span>
                                                    <p className="m-top-60"> Em até 6x <br />sem juros</p>
                                                </div>

                                                <div className="item">
                                                    <span>
                                                        <div className='p-abs'>
                                                            <img src={iconEasyReturns} />
                                                        </div>
                                                    </span>
                                                    <p className="m-top-60">Segurança no pagamento</p>
                                                </div>

                                                <div className="item">
                                                    <span>
                                                        <div className='p-abs'>
                                                            <img src={iconFreeDelivery} />
                                                        </div>
                                                    </span>
                                                    <p className="m-top-60">Entrega imediata</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="grid product d-fx d-ju-sp-bt d-fw-wp w100"> {/* MOBILE */}
                                        <div className="column-xs-12 column-md-7 w100 m-0">
                                            <div className="product-gallery w100">
                                                <div className='box__galeria__visualizacao__projeto p-rlt'>
                                                    {
                                                        (!visualizacaoGaleria || visualizacaoGaleria == 'imagem')
                                                            ?
                                                            <div className='product-image p-rlt'>
                                                                {
                                                                    (projeto.imagens)
                                                                        ?
                                                                        <div className="image-list d-ju-rig">
                                                                            <AnimatePresence initial={false} custom={direction} className="p-rlt">
                                                                                <motion.img
                                                                                    className='tag__imagem'
                                                                                    key={page}
                                                                                    src={projeto.imagens[wrap(0, projeto.imagens.length, page)]}
                                                                                    custom={direction}
                                                                                    variants={variants}
                                                                                    initial="enter"
                                                                                    animate="center"
                                                                                    exit="exit"
                                                                                    transition={{
                                                                                        x: { type: "spring", stiffness: 300, damping: 30 },
                                                                                        opacity: { duration: 0.2 }
                                                                                    }}
                                                                                    drag="x"
                                                                                    dragConstraints={{ left: 0, right: 0 }}
                                                                                    dragElastic={1}
                                                                                    onDragEnd={(e, { offset, velocity }) => {
                                                                                        const swipe = swipePower(offset.x, velocity.x);

                                                                                        if (swipe < -swipeConfidenceThreshold) {
                                                                                            paginate(1);
                                                                                        } else if (swipe > swipeConfidenceThreshold) {
                                                                                            paginate(-1);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </AnimatePresence>
                                                                            <div className="next" onClick={() => paginate(1)}>
                                                                                <ArrowRight />
                                                                            </div>
                                                                            <div className="prev" onClick={() => paginate(-1)}>
                                                                                <ArrowLeft />
                                                                            </div>


                                                                            <div className='menu__kebab__visualizacao__galeria p-rlt'>
                                                                                <button className="btn__menu__kebab d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen p-abs" onClick={() => setOpenMenuGaleria((prevState) => !prevState)}>
                                                                                    <MdMoreVert />
                                                                                </button>
                                                                                {
                                                                                    (openMenuGaleria)
                                                                                        ?
                                                                                        <div className="menu__kebaba__content p-abs">
                                                                                            <div className='menu__kebaba__wrapper'>
                                                                                                <button className={(!visualizacaoGaleria || visualizacaoGaleria == "imagem") ? "active" : null} onClick={() => definirVisualizacaoGaleria("imagem")}>
                                                                                                    <IoMdImages className='m-rig-5 fz-16' />
                                                                                                    <span className=' fz-12'>Imagens</span>
                                                                                                </button>
                                                                                                <button onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                                                                    <BsCollectionPlay className='m-rig-5 fz-14' />
                                                                                                    <span className=' fz-12'>Video</span>
                                                                                                </button>
                                                                                                <button onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                                                                    <MdOutlineVrpano className='m-rig-5 fz-16' />
                                                                                                    <span className='fz-12 d-fx' style={{ flexDirection: 'row', whiteSpace: 'nowrap' }}>Tour 360º</span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            :
                                                            (() => {
                                                                if (visualizacaoGaleria && visualizacaoGaleria == "video") {
                                                                    return (
                                                                        <Player
                                                                            playsInline
                                                                            poster={home1}
                                                                            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                                                        />
                                                                    )
                                                                } else if (visualizacaoGaleria && visualizacaoGaleria == "pano") {
                                                                    return (
                                                                        <div className="box-preview-pbh m-top-20 w100">
                                                                            {/* <Marzipano scenes={scenes} className="myMarzi w100" style={{ position: 'relative', aspectRatio: '16/9' }} /> */}
                                                                        </div>
                                                                    )
                                                                } else if (visualizacaoGaleria && visualizacaoGaleria == "tour") {
                                                                    return (
                                                                        <div className="box-preview-pbh m-top-20 w100"> {/* BOX DE TOUR VIRTUAL */}
                                                                            {/* <TourTeste /> */}
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                    }

                                                    <div className='menu__kebab__visualizacao__galeria p-abs'>
                                                        <button className="btn__menu__kebab d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen p-abs" onClick={() => setOpenMenuGaleria((prevState) => !prevState)}>
                                                            {
                                                                (openMenuGaleria)
                                                                    ?
                                                                    <MdClose />
                                                                    :
                                                                    <MdMoreVert />
                                                            }
                                                        </button>
                                                        {
                                                            (openMenuGaleria)
                                                                ?
                                                                <div className="menu__kebaba__content p-abs">
                                                                    <div className='menu__kebaba__wrapper'>
                                                                        <button className={(!visualizacaoGaleria || visualizacaoGaleria == "imagem") ? "active" : null} onClick={() => definirVisualizacaoGaleria("imagem")}>
                                                                            <IoMdImages className='m-rig-5 fz-16' />
                                                                            <span className='tx-grey-dark fz-12'>Imagens</span>
                                                                        </button>
                                                                        <button onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                                            <BsCollectionPlay className='m-rig-5 fz-14' />
                                                                            <span className='tx-grey-dark fz-12'>Video</span>
                                                                        </button>
                                                                        <button onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                                            <MdOutlineVrpano className='m-rig-5 fz-16' />
                                                                            <span className='tx-grey-dark fz-12 d-fx' style={{ flexDirection: 'row', whiteSpace: 'nowrap' }}>Tour 360º</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column-xs-12 column-md-5 w100 m-0">
                                            <div className="box-header-proj-single b-bot">
                                                <h1>{projeto.titulo}</h1>
                                                <div className="box-valor d-fx d-ju-sp-bt d-al-cen d-fw-wp w100">
                                                    <div className="d-fx d-ju-sp-bt d-al-cen d-fw-wp">
                                                        <div className="valor-atual m-rig-5">
                                                            <h2>{(projeto.precoFinal) ? projeto.precoFinal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null}</h2>
                                                        </div>
                                                        <div className="valor-original m-lef-5">
                                                            <p>{(projeto.precoTabela) ? projeto.precoTabela.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null}</p>
                                                        </div>
                                                    </div>
                                                    <div className="descontos">
                                                        <span>-{projeto.desconto}%</span>
                                                    </div>
                                                </div>
                                                <div className='w100 d-fx d-al-cen d-ju-sp-bt d-fw-wp'>
                                                    <div id="full-stars-example">
                                                        <div className="rating-group">
                                                            <input className="rating__input rating__input--none" name="rating" id="rating-none" value="0" type="radio" />
                                                            <label aria-label="No rating" className="rating__label" for="rating-none">
                                                                <FaBan className="rating__icon rating__icon--none" />
                                                            </label>

                                                            <label aria-label="1 star" className="rating__label" for="rating-1">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" />

                                                            <label aria-label="2 stars" className="rating__label" for="rating-2">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" />

                                                            <label aria-label="3 stars" className="rating__label" for="rating-3">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" />

                                                            <label aria-label="4 stars" className="rating__label" for="rating-4">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" />

                                                            <label aria-label="5 stars" className="rating__label" for="rating-5">
                                                                <FaStar className="rating__icon rating__icon--star" />
                                                            </label>
                                                            <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" checked />
                                                        </div>
                                                        <span className='m-lef-5'>5/5</span>
                                                    </div>{/*full-stars-example*/}
                                                    <div className='p-rlt'>
                                                        <div class="bookmarks_infos d-fx d-al-cen" style={{ right: '0px' }}>
                                                            <span class="fz-11" style={{ background: 'rgba(225, 225, 225, 0.5)', padding: '3px 10px 3px 15px', borderRadius: '20px' }}>Cód.: E{id}A</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='resumo__infos__projeto'>
                                                <motion.div
                                                    style={{ transformOrigin: "top", overflow: "hidden" }}
                                                    animate={{ height: expandedDescription ? "auto" : '30px' }}
                                                    className="accordion-item description is-open"
                                                    onClick={() => setExpandedDescription(!expandedDescription)}>
                                                    <div className='title-description m-bot-10 d-fx d-al-cen d-ju-sp-bt'>
                                                        <h3>Descrição</h3>
                                                        <button className='d-fx' style={{ color: 'var(--dark-grey-blue)' }}>
                                                            {
                                                                (expandedDescription)
                                                                    ?
                                                                    <AiOutlineMinusCircle className="fz-18 btn-minus" />
                                                                    :
                                                                    <AiOutlinePlusCircle className="fz-18 btn-more" />
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className='content-description fz-14'>
                                                        {projeto.descricao}
                                                        <br />
                                                        {/* <a className='m-top-15 m-bot-5 fz-13' style={{ color: 'var(--dark-grey-blue)' }} href="javascript:void(0)">Saiba Mais...</a> */}
                                                    </div>
                                                </motion.div>
                                                <div className='b-top' />
                                                <motion.div
                                                    style={{ transformOrigin: "top", overflow: "hidden" }}
                                                    animate={{ height: expandedItens ? "auto" : '30px' }}
                                                    className="accordion-item description itens__inclusos__projeto"
                                                    onClick={() => setExpandedItens(!expandedItens)}>
                                                    <div className='title-description m-bot-10 d-fx d-al-cen d-ju-sp-bt'>
                                                        <h3>Itens Inclusos</h3>
                                                        <button className='d-fx' style={{ color: 'var(--dark-grey-blue)' }}>
                                                            {
                                                                (expandedItens)
                                                                    ?
                                                                    <AiOutlineMinusCircle className="fz-18 btn-minus" />
                                                                    :
                                                                    <AiOutlinePlusCircle className="fz-18 btn-more" />
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className='content-description'>
                                                        <h2 className="fz-16 d-fx d-al-cen" style={{ color: 'var(--grey)' }}>
                                                            <BsClipboardCheck className="m-rig-5" style={{ color: '#129632' }} />
                                                            Projeto Arquitetônico
                                                        </h2>
                                                        <ul className="tx-grey m-lef-25 m-top-5">
                                                            {
                                                                (complementares && complementares.length)
                                                                    ?
                                                                    complementares.map((itensAdicionais) => {
                                                                        if (itensAdicionais.slug == "arquitetonico") {
                                                                            return (
                                                                                <li className="fz-14 m-top-5 d-fx d-al-cen">
                                                                                    <BsCheck2Circle className="m-rig-5" style={{ color: '#129632' }} />
                                                                                    {pegaItensArquitetonicos()}
                                                                                </li>
                                                                            )
                                                                        }
                                                                    })
                                                                    : null
                                                            }
                                                        </ul>
                                                    </div>
                                                </motion.div>
                                                <div className='b-top' />
                                            </div>
                                            <div className="box-buttons m-top-10">
                                                <button className="cart m-lef-5 m-rig-5" style={{ marginTop: 0 }} onClick={() => addtocart(projeto)}>
                                                    <BsCartPlusFill className='m-rig-5' />
                                                    Adicionar ao carrinho
                                                </button>
                                                {/* <button className="personalizado m-lef-5 m-rig-5 m-top-10 m-bot-10" style={{ marginTop: 0 }} onClick={() => { props.setOpenLike((prevState) => !prevState) }}> */}
                                                <button className="personalizado m-lef-5 m-rig-5 m-top-10 m-bot-10" style={{ marginTop: 0 }} onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                    <BsHeartFill />
                                                </button>
                                                <button className="personalizado m-lef-5 m-rig-5 m-top-10 m-bot-10" style={{ marginTop: 0 }}>
                                                    <BsShareFill />
                                                </button>
                                            </div>

                                            <div className="product-payinfo d-ju-cen d-fw-wp">
                                                <div className="item">
                                                    <span>
                                                        <div className='p-abs'>
                                                            <img src={iconCreditCard} />
                                                        </div>
                                                    </span>
                                                    <p className="m-top-60"> Em até 6x <br />sem juros</p>
                                                </div>

                                                <div className="item">
                                                    <span>
                                                        <div className='p-abs'>
                                                            <img src={iconEasyReturns} />
                                                        </div>
                                                    </span>
                                                    <p className="m-top-60">Segurança no pagamento</p>
                                                </div>

                                                <div className="item">
                                                    <span>
                                                        <div className='p-abs'>
                                                            <img src={iconFreeDelivery} />
                                                        </div>
                                                    </span>
                                                    <p className="m-top-60">Entrega imediata</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>{/*grid second-nav*/}
                    </div>{/*box-product-page*/}

                    {/*SESSÃO CARD INFORMACOES DO PROJETO*/}
                    <BoxInfoProjeto projeto={projeto} responsivoSize={props.responsivoSize} />

                    {/* SESSÃO PLANTA HUMANIZADA */}
                    <BoxPlantasProjeto projeto={projeto} responsivoSize={props.responsivoSize} setOpenModal={props.setOpenModal} />

                    {/* SESSÃO - PARCEIROS */}
                    <WidgetDepartamentos />

                    {/* SESSÃO - ULTIMAS VISUALIZAÇÕES */}
                    <div>
                        <CardsProjetos user={props.user} setItemFavorito={props.setItemFavorito} setOpenLike={props.setOpenLike} projetos={props.projetos} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} listasFavoritos={props.listasFavoritos} setListasFavoritos={props.setListasFavoritos} favorito={props.favorito} setFavorito={props.setFavorito} visualizacao={visualizacao} favoritados={favoritados} openModal={props.openModal} setOpenModal={props.setOpenModal} />
                        <div className='w100 d-fx d-al-cen d-ju-cen'>
                            <Link to="/projetos/projetos-prontos" className='d-fx d-al-cen fz-13 btn-minimalista' style={{ maxWidth: '170px' }}>Ver todos os projetos <BsChevronRight /></Link>
                        </div>
                    </div>

                    {/* SESSÃO - DEPOIMENTOS */}
                    <Depoimentos />

                    {/* SESSÃO - FAQ - PROJETO SINGLE */}
                    <FAQProjetosProntos />
                </div>{/*center*/}
            </div>
        </div >
    );
}



export default ProjetoSingle;

