import { auth, db } from '../../../firebase';
import { Link } from 'react-router-dom'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { useEffect, useState } from 'react';

import './Assets/styles/modais.css';

import firebase from 'firebase';

import toast from 'react-hot-toast';

import $, { isEmptyObject } from 'jquery';
import ModalCompartilhamento from './Assets/componentes/ModalCompartilhamento';
import { abrirModalCompartilhar, fecharModalCompartilhar } from './Assets/funcoes/FuncoesCompartilhamento';
import ModalLocalizacao from './Assets/componentes/ModalLocalizacao';
import { abrirModalBlockCopyright, abrirModalLocalizacao, abrirModalPreviewDepoimento, abrirModalVisualizarDepoimento, abrirModalAssinaturaContrato, abrirModalAprovarOrcamento, abrirModalCadastrarEndereco, abrirModalAprovarFaseServico, abrirModalRecortarFoto } from './Assets/funcoes/FuncoesLocalizacao';
import { fecharModal } from './Assets/funcoes/FuncoesGerais';
import ModalPreviewDepoimento from './Assets/componentes/ModalPreviewDepoimento';
import ModalVisualizarDepoimento from './Assets/componentes/ModalVisualizarDepoimento';
import ModalBlockCopyright from './Assets/componentes/ModalBlockCopyright';
import ModalAssinaturaContrato from './Assets/componentes/ModalAssinaturaContrato';
import ModalAprovarOrcamento from './Assets/componentes/ModalAprovarOrcamento';
import ModalCadastrarEndereco from './Assets/componentes/ModalCadastrarEndereco';
import ModalAprovarFaseServico from './Assets/componentes/ModalAprovarFaseServico';
import ModalRecortarFoto from './Assets/componentes/ModalRecortarFoto';

function Modal(props) {

    useEffect(() => {
        if (props.openModal && Object.values(props.openModal).length > 0 && props.openModal.status === true) {
            if (props.openModal.tipo == "share") {
                abrirModalCompartilhar(props.openModal.link);
            } else if (props.openModal.tipo == "location") {
                abrirModalLocalizacao(props.openModal.link);
            } else if (props.openModal.tipo == "depoimento") {
                abrirModalPreviewDepoimento();
            } else if (props.openModal.tipo == "visualizar_depoimento") {
                abrirModalVisualizarDepoimento();
            } else if (props.openModal.tipo == "bloqueio_copyright") {
                abrirModalBlockCopyright();
            } else if (props.openModal.tipo == "assinatura-contrato") {
                abrirModalAssinaturaContrato();
            } else if (props.openModal.tipo == "aprovar-orcamento") {
                abrirModalAprovarOrcamento();
            } else if (props.openModal.tipo == "cadastrar-endereco") {
                abrirModalCadastrarEndereco();
            } else if (props.openModal.tipo == "aprovar-fase-servico") {
                abrirModalAprovarFaseServico();
            } else if (props.openModal.tipo == "editar-imagem") {
                abrirModalRecortarFoto();
            }
        } else {
            fecharModal();
        }
    }, [props.openModal])
    return (
        <div id='wrapper__modal'>
            {
                (props.openModal && !isEmptyObject(props.openModal) && props.openModal.status === true)
                    ?
                        (() => {
                            if (props.openModal.tipo == 'share') {
                                return (
                                    <div id="modal_compartilhamento_link" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalCompartilhamento id="modal_compartilhar" openModal={props.openModal} setOpenModal={props.setOpenModal} />
                                    </div>
                                )
                            } else if (props.openModal.tipo == 'location') {
                                return (
                                    <div id="modal_compartilhamento_localizacao" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalLocalizacao id="modal_localizacao" openModal={props.openModal} setOpenModal={props.setOpenModal} setLocation={props.setLocation} location={props.location} />
                                    </div>
                                )
                            } else if (props.openModal.tipo == 'depoimento') {
                                return (
                                    <div id="modal_depoimento" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalPreviewDepoimento id="modal_preview_depoimento" openModal={props.openModal} setOpenModal={props.setOpenModal} />
                                    </div>
                                )
                            } else if (props.openModal.tipo == 'visualizar_depoimento') {
                                return (
                                    <div id="modal_visualizar_depoimento" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalVisualizarDepoimento openModal={props.openModal} setOpenModal={props.setOpenModal} />
                                    </div>
                                )
                            } else if (props.openModal.tipo == 'bloqueio_copyright') {
                                return (
                                    <div id="modal_copyright" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalBlockCopyright openModal={props.openModal} setOpenModal={props.setOpenModal} />
                                    </div>
                                )
                            } else if (props.openModal.tipo == 'assinatura-contrato') {
                                return (
                                    <div id="modal_contrato" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalAssinaturaContrato id="modal_assinatura_contrato" openModal={props.openModal} setOpenModal={props.setOpenModal} user={props.user} responsivoSize={props.responsivoSize} />
                                    </div>
                                )
                            } else if (props.openModal.tipo == 'aprovar-orcamento') {
                                return (
                                    <div id="modal_orcamento" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalAprovarOrcamento id="modal_aprovar_orcamento" openModal={props.openModal} setOpenModal={props.setOpenModal} user={props.user} />
                                    </div>
                                )
                            } else if (props.openModal.tipo == 'cadastrar-endereco') {
                                return (
                                    <div id="modal_endereco" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalCadastrarEndereco id="modal_cadastrar_endereco" openModal={props.openModal} setOpenModal={props.setOpenModal} user={props.user}  setOrcamentoCadastroEnderecoCliente={props.setOrcamentoCadastroEnderecoCliente} setBudgetEnderecoServico={props.setBudgetEnderecoServico} />
                                    </div>
                                )
                            } else if (props.openModal.tipo == 'aprovar-fase-servico') {
                                return (
                                    <div id="modal_aprovacao_fase_servico" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalAprovarFaseServico id="modal_aprovacao_fase" openModal={props.openModal} setOpenModal={props.setOpenModal} user={props.user} setAcompanhamentoObras={props.setAcompanhamentoObras}  />
                                    </div>
                                )
                            } else if (props.openModal.tipo == 'editar-imagem') {
                                return (
                                    <div id="modal_edicao_recortar_foto" className='content__modal modal modal-enter modal-show d-nn'>
                                        <ModalRecortarFoto id="modal_recortar_foto" openModal={props.openModal} setOpenModal={props.setOpenModal} user={props.user}  />
                                    </div>
                                )
                            }
                        })()
                    : null
            }
        </div>
    );
}

export default Modal;