import $ from 'jquery';

function fecharModal() {
    let modalPhone = $('#wrapper__modal .content__modal'); // BG
    let modalContent = $("#wrapper__modal .content__modal .popup"); // CONTENT
    if (modalPhone.css('display') == 'block') {
        modalContent.slideUp(500);
        setTimeout(() => {
            modalPhone.slideUp();
            // modalContent.currentTarget.classList.toggle('show');
        }, 500);
    }
}

export { fecharModal };