import { motion } from 'framer-motion'; // FRAMER MOTION 
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';

// IMPORTAÇÃO DE REACT ROUTER
import { Link, useParams } from 'react-router-dom';

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../images/icones/svg/sing-up.svg';

import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';

import { IoIosInformationCircleOutline } from 'react-icons/io';
import { db } from '../../../../../../../../firebase';
import { useMemo } from 'react';
import DadosPedido from './DadosPedido';
import { isEmptyObject } from 'jquery';

function PedidoSingle(props) {
    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);
    // useEffect(() => {
    //     // RECEBER DADOS DO PEDIDO
    //     // console.warn(props.dadosCobranca);

    //     // axios.post("https://config.eengeart.com/recuperar-pedido", { pedido: props.dadosCobranca.id, referencia: props.dadosCobranca.externalReference })
    //     axios.post("http://localhost:5000/recuperar-pedido", { pedido: props.dadosCobranca.id, referencia: props.dadosCobranca.externalReference })
    //         .then((result) => {
    //             console.log(result.data)
    //             // console.log({
    //             //     pedido: props.dadosCobranca.id,
    //             //     referencia: props.dadosCobranca.externalReference,
    //             //     produtos: result.data.dados_recuperados
    //             // });
    //             setDadosPedido(result.data.dados_recuperados)
    //         })
    //         .catch((error) => {
    //             toast.error("Ocorreu um erro na requisição dos produtos!");
    //             console.log(error.message);
    //         })
    // }, [])
    useMemo(() => {
        if (props.responsivoSize < 350) {
            setExpandedSummarySize("90px");
        } else {
            setExpandedSummarySize("70px");
        }
    }, [props.responsivoSize]);
    return (
        <motion.div
            style={{ transformOrigin: "top", overflow: "hidden" }}
            animate={{ height: expandedSummary ? "auto" : expandedSummarySize }}
            className='description-page__content'
        >
            <div className={(expandedSummary) ? 'wrapper__request__single w100 p-g-10 is-open' : 'wrapper__request__single w100 p-g-10'}>
                <div className='order__title__request p-top-10 p-bot-15 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                    <div className='order__item__request info-primary'>
                        <strong>
                            Pedido {(props.dadosCobranca) ? props.dadosCobranca.externalReference : null}
                        </strong>
                    </div>
                    {
                        (props.responsivoSize && props.responsivoSize > 460)
                            ?
                            <div className='order__item__request info-default'>
                                <small className='d-bk m-bot-5'>
                                    Total
                                </small>
                                <strong className='d-bk tx-norm'>
                                    {
                                        (props.dadosCobranca)
                                            ?
                                            (() => {
                                                if (props.dadosCobranca.installmentNumber) {
                                                    return ((props.dadosCobranca.value * props.dadosCobranca.installmentNumber).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
                                                } else {
                                                    return ((props.dadosCobranca.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
                                                }
                                            })()
                                            : null
                                    }
                                </strong>
                            </div>
                            : null
                    }
                    {
                        (props.responsivoSize && props.responsivoSize > 600)
                            ?
                            <div className='order__item__request info-default'>
                                <small className='d-bk m-bot-5'>
                                    Data do pedido
                                </small>
                                <strong className='d-bk tx-norm'>
                                    {
                                        (() => {
                                            var data = new Date(props.dadosCobranca.dateCreated);
                                            var dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
                                            return (dataFormatada);
                                        })()
                                    }
                                </strong>
                            </div>
                            : null
                    }
                    <div className='order__item__request info-default'>
                        {/* <div className={(isShow)?'med-badge':'med-badge active-badge'}>
                                                1 entrega
                                            </div> */}
                    </div>
                    <buttom className="order__item__request info-primary btn__show__request" onClick={() => setExpandedSummary(!expandedSummary)}>
                        <BsChevronDown className='fz-20' />
                    </buttom>
                </div>
                <div className='order__body__request'>
                    <div className='content__request d-fx d-fd-col'>
                        <motion.div
                            style={{ transformOrigin: "top", overflow: "hidden" }}
                            animate={{ height: expandedSubSummary ? "auto" : "70px" }}
                            className='description-page__content'
                        >
                            <div className={(expandedSubSummary) ? 'wrapper__order__request order-content p-g-15 open' : 'wrapper__order__request order-content p-g-15'}> {/* open */}
                                <div className='order__title__request w100 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                    <div className='delivery__order__type info-primary'>
                                        <strong>Entrega online</strong>
                                    </div>
                                    {
                                        (props.responsivoSize && props.responsivoSize > 450)
                                            ?
                                                
                                            <div className='delivery__order__date info-default'>
                                            <small className='d-bk m-bot-5'>
                                                Data da entrega
                                            </small>
                                            <strong className='d-bk tx-norm fz-13'>
                                                {
                                                    (() => {
                                                        var data = new Date(props.dadosCobranca.dueDate); // PEGANDO COBRANÇA APARTIR DO VENCIMENTO
                                                        // console.log(data.getDate()); // 14
                                                        data.setDate(data.getDate() + 4); // AQUI ESTOU ADICIONANDO O NUMERO DE DIAS PARA O VENCIMENTO

                                                        // console.log(data.getDay());
                                                        // console.log(data.toLocaleDateString("pt-BR")); // 19
                                                        if (data.getDay() == 6) {
                                                            data.setDate(data.getDate() + 2);
                                                            // console.log(data.toLocaleDateString("pt-BR")); // 19
                                                            var dataBrasileira = data.toLocaleDateString("pt-BR");
                                                            var dataSeparada = dataBrasileira.split("/");
                                                            var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                                                            var data = new Date(novaData);
                                                            var dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
                                                            return (dataFormatada)
                                                        } else if (data.getDay() == 0) {
                                                            data.setDate(data.getDate() + 1);
                                                            // console.warn(data.toLocaleDateString("pt-BR")); // 19
                                                            var dataBrasileira = data.toLocaleDateString("pt-BR");
                                                            var dataSeparada = dataBrasileira.split("/");
                                                            var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                                                            var data = new Date(novaData);
                                                            var dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
                                                            return (dataFormatada)
                                                        } else {
                                                            // console.error(data.toLocaleDateString("pt-BR")); // 19
                                                            var dataBrasileira = data.toLocaleDateString("pt-BR");
                                                            var dataSeparada = dataBrasileira.split("/");
                                                            var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                                                            var data = new Date(novaData);
                                                            var dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
                                                            return (dataFormatada)
                                                        }
                                                    })()
                                                }
                                            </strong>
                                        </div>
                                            : null
                                    }
                                    <buttom className='delivery__order__action info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                        <BsChevronDown className='fz-20' />
                                    </buttom>
                                </div>
                                <div className='delivery__body__request m-top-20'>
                                    <div class="wrapper__step__progress  d-fx d-ju-sp-ev">
                                        {
                                            (props.dadosCobranca.status)
                                                ?
                                                <div class="wrapper__step__request">
                                                    <div class="step__bullet__request">
                                                        <span class="status__step__bullet">
                                                            <img src="https://cdn.leroymerlin.com.br/assets/images/timeline/step1.svg" />
                                                        </span>
                                                    </div>
                                                    <div class="step__description__status">
                                                        <span class="content__description__status">Pedido recebido</span>
                                                        <span class="content__description__status">
                                                            {
                                                                (props.dadosCobranca.dateCreated)
                                                                    ?
                                                                    (() => {
                                                                        let dataEvento = props.dadosCobranca.dateCreated.split("-");

                                                                        return (dataEvento[2] + '/' + dataEvento[1] + '/' + dataEvento[0]);
                                                                    })()
                                                                    : null
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                :
                                                <div class="step__default">
                                                    <div class="step__default__bullet"></div>
                                                </div>
                                        }
                                        {
                                            (props.dadosCobranca.status && (props.dadosCobranca.status == "CONFIRMED" || props.dadosCobranca.status == "RECEIVED" || props.dadosCobranca.status == "RECEIVED_IN_CASH"))
                                                ?
                                                <div class="step__default wrapper__step__request">
                                                    <div class="step__bullet__request">
                                                        <span class="status__step__bullet">
                                                            <img src="https://cdn.leroymerlin.com.br/assets/images/timeline/step2.svg" />
                                                        </span>
                                                    </div>
                                                    <div class="step__description__status">
                                                        <span class="content__description__status">Pagamento autorizado</span>
                                                        <span class="content__description__status">
                                                            {
                                                                (props.dadosCobranca.dateCreated)
                                                                    ?
                                                                    (() => {
                                                                        let dataEvento = props.dadosCobranca.dateCreated.split("-");

                                                                        return (dataEvento[2] + '/' + dataEvento[1] + '/' + dataEvento[0]);
                                                                    })()
                                                                    : null
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                :
                                                <div class="step__default">
                                                    <div class="step__default__bullet"></div>
                                                </div>
                                        }
                                        {
                                            (props.dadosCobranca.status && (props.dadosCobranca.status == "CONFIRMED" || props.dadosCobranca.status == "RECEIVED" || props.dadosCobranca.status == "RECEIVED_IN_CASH") && props.dadosCobranca.status == "SEPARATED")
                                                ?
                                                <div class="step__default wrapper__step__request">
                                                    <div class="step__bullet__request">
                                                        <span class="status__step__bullet">
                                                            <img src="https://cdn.leroymerlin.com.br/assets/images/timeline/step3.svg" />
                                                        </span>
                                                    </div>
                                                    <div class="step__description__status">
                                                        <span class="content__description__status">Pedido separado</span>
                                                        <span class="content__description__status">
                                                            {
                                                                (props.dadosCobranca.dateCreated)
                                                                    ?
                                                                    (() => {
                                                                        let dataEvento = props.dadosCobranca.dateCreated.split("-");

                                                                        return (dataEvento[2] + '/' + dataEvento[1] + '/' + dataEvento[0]);
                                                                    })()
                                                                    : null
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                :
                                                <div class="step__default">
                                                    <div class="step__default__bullet"></div>
                                                </div>
                                        }
                                        {
                                            (props.dadosCobranca.status && (props.dadosCobranca.status == "CONFIRMED" || props.dadosCobranca.status == "RECEIVED" || props.dadosCobranca.status == "RECEIVED_IN_CASH") && props.dadosCobranca.status == "SENT")
                                                ?
                                                <div class="step__default wrapper__step__request">
                                                    <div class="step__bullet__request">
                                                        <span class="status__step__bullet">
                                                            <img src="https://cdn.leroymerlin.com.br/assets/images/timeline/step4.svg" />
                                                        </span>
                                                    </div>
                                                    <div class="step__description__status">
                                                        <span class="content__description__status">Pedido enviado</span>
                                                        <span class="content__description__status">
                                                            {
                                                                (props.dadosCobranca.dateCreated)
                                                                    ?
                                                                    (() => {
                                                                        let dataEvento = props.dadosCobranca.dateCreated.split("-");

                                                                        return (dataEvento[2] + '/' + dataEvento[1] + '/' + dataEvento[0]);
                                                                    })()
                                                                    : null
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                :
                                                <div class="step__default">
                                                    <div class="step__default__bullet"></div>
                                                </div>
                                        }
                                        {
                                            (props.dadosCobranca.status && (props.dadosCobranca.status == "CONFIRMED" || props.dadosCobranca.status == "RECEIVED" || props.dadosCobranca.status == "RECEIVED_IN_CASH") && props.dadosCobranca.status == "TRANSIT")
                                                ?
                                                <div class="step__default wrapper__step__request">
                                                    <div class="step__bullet__request">
                                                        <span class="status__step__bullet">
                                                            <img src="https://cdn.leroymerlin.com.br/assets/images/timeline/step5.svg" />
                                                        </span>
                                                    </div>
                                                    <div class="step__description__status">
                                                        <span class="content__description__status">Em Trânsito</span>
                                                        <span class="content__description__status">
                                                            {
                                                                (props.dadosCobranca.dateCreated)
                                                                    ?
                                                                    (() => {
                                                                        let dataEvento = props.dadosCobranca.dateCreated.split("-");

                                                                        return (dataEvento[2] + '/' + dataEvento[1] + '/' + dataEvento[0]);
                                                                    })()
                                                                    : null
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                :
                                                <div class="step__default">
                                                    <div class="step__default__bullet"></div>
                                                </div>
                                        }
                                        {
                                            (props.dadosCobranca.status && (props.dadosCobranca.status == "CONFIRMED" || props.dadosCobranca.status == "RECEIVED" || props.dadosCobranca.status == "RECEIVED_IN_CASH") && props.dadosCobranca.status == "DELIVERED")
                                                ?
                                                <div class="step__default wrapper__step__request">
                                                    <div class="step__bullet__request">
                                                        <span class="status__step__bullet">
                                                            <img src="https://cdn.leroymerlin.com.br/assets/images/timeline/step6.svg" />
                                                        </span>
                                                    </div>
                                                    <div class="step__description__status">
                                                        <span class="content__description__status">Pedido entregue</span>
                                                        <span class="content__description__status">
                                                            {
                                                                (props.dadosCobranca.dateCreated)
                                                                    ?
                                                                    (() => {
                                                                        let dataEvento = props.dadosCobranca.dateCreated.split("-");

                                                                        return (dataEvento[2] + '/' + dataEvento[1] + '/' + dataEvento[0]);
                                                                    })()
                                                                    : null
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                :
                                                <div class="step__default">
                                                    <div class="step__default__bullet"></div>
                                                </div>
                                        }
                                    </div>
                                    <div className='wrapper__itens__request m-top-20'>
                                        {
                                            (status)
                                                ?
                                                <DadosPedido dadosCobranca={props.dadosCobranca} responsivoSize={props.responsivoSize} />
                                                : null
                                        }
                                        {/* <div className='take__product_adress d-fx d-fw-wp'>
                                            <span className='adress__title tx-bold fz-13 d-bk w100'>Endereço de retirada</span>
                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                Rodovia Washington Luis, 435 | Fazenda Palmeira São José do Rio Preto - SP | CEP 15082000
                                            </span>
                                            <span className='complement__adress fz-13 d-bk w100'>
                                                Complemento: Km 435
                                            </span>
                                        </div> */}
                                        <div className='take__product_adress d-fx d-fw-wp'>
                                            <span className='adress__title tx-bold fz-13 d-bk w100'>Tipo de entrega</span>
                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                Online | E-mail: {(props.user && props.user.email && props.user.email.length) ? props.user.email : null}
                                            </span>
                                            <span className='complement__adress fz-13 d-bk w100'>
                                                OBS: Quando seus projetos estiverem disponiveis eles serão enviados para o seu e-mail, você pode acompanhar o andamento do seu pedido logo acima ou solicitar suporte exclusivo clicando <Link to="/ouvidoria">aqui</Link>.
                                            </span>
                                        </div>
                                    </div>
                                    <div className='wrapper__infos__request p-g-15 m-top-25 m-bot-5'>
                                        <div className='info__single__request w100 d-fx'>
                                            <div className='icon__info__request info-primary left'>
                                                <IoIosInformationCircleOutline className='fz-18' />
                                            </div>
                                            <div className='message__info__request w100 left m-lef-5'>
                                                <p>
                                                    <span className='fz-13'>
                                                        <span className='tx-bold'>Atenção</span>: A Engeart utiliza o WhatsApp <span className='tx-bold'>apenas para se comunicar sobre pedidos e fazer vendas</span>, porém <span className='tx-bold'>não envia nenhum tipo de código</span> para confirmar ou acessar sua conta em nossos canais de venda. Não compartilhe seus dados com ninguém. Em caso de dúvidas, utilize nossos canais digitais de atendimento: <Link to='/atendimento'>Fale conosco</Link>.
                                                    </span>
                                                </p>
                                            </div>
                                            <div className='clear'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <div className='wrapper__payment__request w100 m-top-5'>
                            <div className={(props.responsivoSize && props.responsivoSize > 380) ? 'method__payment__order w50 left order-content p-g-15 m-lef-0' : 'method__payment__order w100 left order-content p-g-15 m-lef-0 m-bot-5'}>
                                <div className='title__method__order m-bot-10'>
                                    <h2 className='fz-16'>Informações de pagamento</h2>
                                </div>
                                <div className={(props.responsivoSize && props.responsivoSize > 280) ? 'content__method__payment d-fx d-al-cen' : 'content__method__payment d-fx d-al-cen d-fw-wp'}>
                                    {
                                        (() => {
                                            if (props.dadosCobranca.billingType == "CREDIT_CARD") {
                                                return (
                                                    <span className='brand__payment p-lef-5 p-rig-5'>
                                                        {
                                                            (() => {
                                                                if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "VISA") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/visa.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "MASTERCARD") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/mastercard.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "AMEX") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/amex.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "ELO") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/elo.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "DINERS") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/diners.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "DISCOVER") {
                                                                    return (<img src="https://www.asaas.com/images/home3/cardPayment/brands/discover.png" className="w100" style={{ padding: '5px', border: '1px solid #ededed', borderRadius: '4px', maxWidth: '3.5rem' }} />);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "HIPERCARD") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/hiper.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "LOSANGO") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/losango.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "CONSTRUCARD") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/construcard.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "BNDES") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/bndes.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                } else if (props.dadosCobranca.creditCard && !isEmptyObject(props.dadosCobranca.creditCard) && props.dadosCobranca.creditCard.creditCardBrand == "BANRISUL") {
                                                                    return (<img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/banrisul.svg" className="w100" style={{maxWidth: '3.5rem'}}/>);
                                                                }
                                                            })()
                                                        }
                                                    </span>
                                                )
                                            } else if (props.dadosCobranca.billingType == "BOLETO") {
                                                return (
                                                    <span className='brand__payment p-lef-5 p-rig-5'>
                                                        <img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/bank-slip.svg" className="w100" style={{maxWidth: '3.5rem'}} />
                                                    </span>
                                                )
                                            } else if (props.dadosCobranca.billingType == "PIX") {
                                                return (
                                                    <span className='brand__payment p-lef-5 p-rig-5'>
                                                        <img src="https://cdn.leroymerlin.com.br/assets/frontend/images/payment-cards/pix.svg" className="w100" style={{maxWidth: '3.5rem'}} />
                                                    </span>
                                                )
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if (props.dadosCobranca.installmentNumber) {
                                                return (
                                                    <span className='info__method__payment m-lef-10 fz-14'>
                                                        - {props.dadosCobranca.installmentNumber}x {props.dadosCobranca.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} {(props.dadosCobranca.installmentNumber > 6) ? "Com juros" : "Sem juros"}
                                                    </span>
                                                )
                                            } else if (!props.dadosCobranca.installmentNumber && props.dadosCobranca.billingType == "CREDIT_CARD") {
                                                return (
                                                    <span className='info__method__payment m-lef-10 fz-14'>
                                                        - 1x {props.dadosCobranca.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </span>
                                                )
                                            } else {
                                                return (
                                                    <span className='info__method__payment m-lef-10 fz-14'>
                                                        - {props.dadosCobranca.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </span>
                                                )
                                            }
                                        })()
                                    }
                                </div>
                            </div>
                            <div className={(props.responsivoSize && props.responsivoSize > 380) ? 'method__payment__order w50-c right order-content p-g-15 m-rig-0' : 'method__payment__order w100 right order-content p-g-15 m-rig-0'}>
                                <div className='title__method__order m-bot-15'>
                                    <h2 className='fz-16'>Informações gerais</h2>
                                </div>
                                <div className='content__shapes__payment d-fx d-al-cen d-fw-wp'>
                                    <span className='info__method__payment fz-16 tx-lig d-bk w100 m-bot-10'>
                                        Frete: <strong>Grátis</strong>
                                    </span>
                                    {
                                        (props.dadosCobranca.discount && props.dadosCobranca.discount.value && props.dadosCobranca.discount.value > 0)
                                            ?
                                            <span className='info__method__payment fz-16 tx-lig d-bk w100 m-bot-10 info-primary'>
                                                Desconto: <strong>{props.dadosCobranca.discount.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                            </span>
                                            : null
                                    }
                                    <span className='info__method__payment fz-16 tx-lig d-bk w100'>
                                        Total: <strong>{
                                            (() => {
                                                if (props.dadosCobranca.installmentNumber) {
                                                    return (
                                                        // ((props.dadosCobranca.value * props.dadosCobranca.installmentNumber) - (props.dadosCobranca.discount && props.dadosCobranca.discount.value && props.dadosCobranca.discount.value > 0) ? props.dadosCobranca.discount.value : 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                        (props.dadosCobranca.value * props.dadosCobranca.installmentNumber).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                    );
                                                } else {
                                                    return (
                                                        // (props.dadosCobranca.value - (props.dadosCobranca.discount && props.dadosCobranca.discount.value && props.dadosCobranca.discount.value > 0) ? props.dadosCobranca.discount.value : null)
                                                        // .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                        props.dadosCobranca.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                    );
                                                }
                                            })()
                                        }</strong>
                                    </span>
                                </div>
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default PedidoSingle;