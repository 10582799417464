import { useEffect, useState, useMemo } from 'react';
import { db } from '../../../../../firebase';
import { Link } from 'react-router-dom';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from './Arrows';
import { TestimonialLazyload } from './Lazyload';
import toast from 'react-hot-toast';
import logoIcon from '../../../../../images/icones/jpg/logo_icone.jpg'

function Depoimentos(props) {

    const [depoimentos, setDepoimentos] = useState([]); // DEPOIMENTOS DOS CLIENTES
    const [lazyload, setLazyload] = useState(true); // LAZYLOAD

    useEffect(() => {
        // PEGAR DADOS DO SLIDE E INSERIR NA SESSÃO
        if (!sessionStorage.getItem("depoimentos")) {
            if (!depoimentos || !depoimentos.length) {
                (async () => {
                    try {
                        const depoimentosRef = db.collection("relatorios").doc("UX").collection("depoimentos").where("publicar", "==", true).limit(5);
                        const snapshot = await depoimentosRef.get();
                        if (snapshot.empty) {
                            toast.error("Não existe depoimentos disponíveis no momento!");
                            return;
                        }
                        var arrDepoimentos = ([]);
                        snapshot.forEach(doc => {
                            arrDepoimentos.unshift(doc.data());
                        });

                        sessionStorage.setItem('depoimentos',JSON.stringify(arrDepoimentos));
                        setDepoimentos(arrDepoimentos);
                    } catch (error) {
                        toast.error("Ocorreu um erro ao recuperar os depoimentos!");
                        console.log(error);
                    }
                })()
            }
        } else {
            // RECUPERAR DADOS SALVOS NA SESSÃO
            let dadosDepoimentos = JSON.parse(sessionStorage.getItem('depoimentos'));
            setDepoimentos(dadosDepoimentos);
        }
    }, [])

    useMemo(() => {
        if (depoimentos && depoimentos.length) {
            setLazyload(false);
        }
    }, [depoimentos]);

    let rows = [];
    for (let i = 1; i <= 5; i++) {
        rows.push(<TestimonialLazyload chave={i} />)
    }

    function visualizarDepoimento(e, dados) {
        e.preventDefault();

        props.setOpenModal({ status: true, tipo: "visualizar_depoimento", depoimento: {nome: dados.nome, profissao: dados.profissao, titulo: dados.titulo, conteudo: dados.depoimento}})
    }

    return (
        <section className="promos-departments p-rlt container__content z-less">
            <div className="center">
                <h2 className='sub-title d-bk'>Depoimentos dos clientes</h2>
                <div class="outerdiv w100">
                    <div className="innerdiv" style={(depoimentos.length >= 3) ? {gridTemplateRows: 'repeat(2,22rem)'} : null}>
                    {
                        (!lazyload)
                            ?
                                (depoimentos && depoimentos.length > 0)
                                    ?
                                        depoimentos.map((dadosDepoimentos, index) => {
                                            if (index !== 1) {
                                                return (
                                                    <div id={index} className={`div${dadosDepoimentos.orderId} eachdiv`}>
                                                        <div class="userdetails">
                                                            <div class="imgbox">
                                                                <img src={logoIcon} alt={dadosDepoimentos.nome} title={dadosDepoimentos.nome} />
                                                            </div>
                                                            <div class="detbox">
                                                                <p class="name">{dadosDepoimentos.nome}</p>
                                                                <p class="designation">{dadosDepoimentos.profissao}</p>
                                                            </div>
                                                        </div>
                                                        <div class="review">
                                                            <h4>{dadosDepoimentos.titulo}</h4>
                                                            <p style={{maxHeight: '175px', overflow: 'hidden'}}>" {dadosDepoimentos.depoimento} "</p>
                                                            <button style={{cursor: 'pointer', background: 'none', fontWeight: 'bold', color: '#fff'}} onClick={(e) => visualizarDepoimento(e, dadosDepoimentos)}>Leia mais</button>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div id={index} className={`div${dadosDepoimentos.orderId} eachdiv`}>
                                                        <div class="userdetails">
                                                            <div class="imgbox">
                                                                <img src={logoIcon} alt={dadosDepoimentos.nome} title={dadosDepoimentos.nome} />
                                                            </div>
                                                            <div class="detbox">
                                                                <p class="name">{dadosDepoimentos.nome}</p>
                                                                <p class="designation">{dadosDepoimentos.profissao}</p>
                                                            </div>
                                                        </div>
                                                        <div class="review">
                                                            <h4>{dadosDepoimentos.titulo}</h4>
                                                            <p style={{maxHeight: '430px', overflow: 'hidden'}}>" {dadosDepoimentos.depoimento} "</p>
                                                            <button style={{cursor: 'pointer', background: 'none', fontWeight: 'bold', color: '#fff'}} onClick={(e) => visualizarDepoimento(e, dadosDepoimentos)}>Leia mais</button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    : null
                            : rows


                    }
                    </div>
                </div>
            </div>{/* center */}
        </section>
    )
}

export default Depoimentos;