import { useEffect, useMemo, useState } from "react";
import toast, { ToastBar } from "react-hot-toast";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { auth, db } from "../../../../firebase";
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FaUserCheck, FaUserSecret, FaUser } from 'react-icons/fa';
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri';
import { MdOutlineArrowBackIos } from 'react-icons/md';

import depoimento from '../../../../images/icones/svg/depoimento.svg'
import { isEmbedded } from "react-device-detect";
import { isEmptyObject } from "jquery";

function Convite(props) {
    let navigate = useNavigate();
    let { tipoConvite, tokenRepresentante } = useParams();

    // RECUEPERAÇÃO DE PARAMETROS DA QUERY
    let query = useQuery();
    // const tipo = query.get("type"); // TIPO DE CONVITE
    const token = query.get("code"); // TOKEN DO ANFITRIÃO DO CONVITE

    // HOOKS - CONVITE
    const [convite, setConvite] = useState([]);

    // HOOKS - DEPOIMENTO
    const [depoimentoConteudo, setDepoimentoConteudo] = useState('');
    const [depoimentoTitulo, setDepoimentoTitulo] = useState('');
    const [profissao, setProfissao] = useState('');

    // HOOKS - NOME
    const [name, setName] = useState();
    const [nameMessage, setNameMessage] = useState([]);
    const [nameValid, setNameValid] = useState(false);

    // HOOKS - ANONIMO
    const [anonimo, setAnonimo] = useState(false);


    // FUNÇÃO DE QUERY PARA ALTERAÇÃO DE SENHA
    function useQuery() {
        const { search } = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    }

    useMemo(async () => {
        await recuperarConvite(token);
    }, [])

    async function recuperarConvite(id) {
        if (!convite.length && id && id.length) {
            try {
                await db.collection("convites").onSnapshot((data) => {
                    var arr = ([]);
                    data.docs.map((dadosRecuperados) => {
                        if (dadosRecuperados.id === token) {
                            arr.push(dadosRecuperados.data())
                        } else {
                            toast.error("Não conseguimos identificar o tipo do convite associado a este link, solicite um novo convite ou tente novamente mais tarde!");
                            navigate("/");
                        }
                    })
                    setConvite(arr)
                })
            } catch (error) {
                toast.error("Não foi possível recuperar os dados do convite");
            }
        } else if ((tipoConvite && tipoConvite.length && tipoConvite === 'depoimento') && (tokenRepresentante && tokenRepresentante.length)) {
            const ref = db.collection('usuarios');
            const snapshot = await ref.where('representante', '==', true).where('token_representante', '==', tokenRepresentante).get();
            if (snapshot.empty) {
                toast.error("Não conseguimos localizar nenhum representante com esse número!");
                return;
            }

            let tokenRep =  ([]);
            let dataRep = ([]);

            snapshot.forEach(doc => {
                tokenRep.push(doc.id)
                dataRep.push(doc.data())
                // console.log(doc.id, '=>', doc.data());
            });

            
            tokenRep = tokenRep[0];
            dataRep = dataRep[0];

            const ref2 = db.collection('convites');
            const snapshot2 = await ref2.where('anfitriao', '==', tokenRep).where('tipo', '==', tipoConvite).get();
            if (snapshot2.empty) {
                toast.error("Não conseguimos localizar nenhum representante com esse número!");
                return;
            }

            let tokenRep2 = ([]);
            let dataRep2 = ([]);

            snapshot2.forEach(doc => {
                tokenRep2.push(doc.id)
                dataRep2.push(doc.data())
                // console.log(doc.id, '=>', doc.data());
            });
            dataRep2[0].tokenConvite = tokenRep2[0];
            setConvite(dataRep2[0]);

        } else {
            toast.error("Não conseguimos identificar o tipo do convite associado a este link, solicite um novo convite ou tente novamente mais tarde!");
            navigate("/");
        }
    }

    // FUNÇÕES DE DEPOIMENTO
    // VALIDAR NOME
    useMemo(() => {
        if (name) {
            // VERIFICAÇÃO DE LETRAS EM SENHA
            var padrao = /[^a-zà-ú\s-]/gi;

            var valida_nome = name.match(padrao);

            if (valida_nome) {
                setNameValid(false);
                setNameMessage([{
                    'status': 'error',
                    'message': 'O nome deve conter apenas letras'
                }])
            } else {
                //O NOME É VÁLIDO CONTEM APENAS LETRAS -> VERIFICAR SE O NOME É COMPLETO
                var arrName = name.split(' ');
                if (arrName.length <= 1) {
                    // O USUARIO NÃO PASSOU O NOME COMPLETO
                    setNameValid(false);
                    setNameMessage([{
                        'status': 'error',
                        'message': 'O nome deve ser completo'
                    }])
                } else {
                    // O USUARIO PASSOU O NOME COMPLETO
                    setNameValid(true);
                    setNameMessage([])
                }
            }
        }
    }, [name])

    // FUNÇÃO VISUALIZAR DEPOIMENTO 
    function visualizarDepoimento(e) {
        e.preventDefault();

        if (!anonimo) { // O DEPOIMENTO FOI IDENTIFICADO
            if (!name || !name.length) {
                toast.error("O seu nome deve ser informado");
            } else if (!nameValid) {
                toast.error("O nome informado não é válido");
            } else if (!depoimentoTitulo || !depoimentoTitulo.length) {
                toast.error("O título do seu depoimento deve ser informado")
            } else if (!depoimentoConteudo || !depoimentoConteudo.length) {
                toast.error("O depoimento não pode ser vázio")
            } else { // Está tudo ok e podemos porsseguir com o cadastro do depoimento
                props.setOpenModal({ status: true, tipo: "depoimento", depoimento: { nome: name, profissao: profissao, titulo: depoimentoTitulo, conteudo: depoimentoConteudo, convite: (!token && convite && convite.tokenConvite && convite.tokenConvite.length) ? convite.tokenConvite : token } })
            }
        } else {
            if (!depoimentoTitulo || !depoimentoTitulo.length) {
                toast.error("O título do seu depoimento deve ser informado")
            } else if (!depoimentoConteudo || !depoimentoConteudo.length) {
                toast.error("O depoimento não pode ser vázio")
            } else { // Está tudo ok e podemos porsseguir com o cadastro do depoimento
                props.setOpenModal({ status: true, tipo: "depoimento", depoimento: { titulo: depoimentoTitulo, conteudo: depoimentoConteudo, convite: (!token && convite && convite.tokenConvite && convite.tokenConvite.length) ? convite.tokenConvite : token } })
            }
        }
    }

    useEffect(() => {
        if ((!name || !name.length) && (props.user && !isEmptyObject(props.user))) {
            setName(props.user.full_name);
        }
    },[])

    return (
        <>
            <div className="center">
                <div className="grid second-nav">
                    <div className="column-xs-12">
                        <nav>
                            {
                                (props.responsivoSize && props.responsivoSize >= 600)
                                    ?
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Meu Depoimento</li>
                                    </ol>
                                    :
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item">
                                            <Link to="/login" className='d-fx d-al-cen'>
                                                <MdOutlineArrowBackIos className='m-rig-5' />
                                                Início
                                            </Link>
                                        </li>
                                    </ol>
                            }
                        </nav>
                    </div>
                    {/* BOX FORMULARIO CONTATO */}
                    <div className="w100 d-fx d-al-cen d-fw-wp m-bot-20">
                        <div className={(props.responsivoSize && props.responsivoSize > 380) ? 'w50' : 'w100 p-g-10'}>
                            <div className="box-header-proj-single">
                                <h1>Diga como foi sua experiência em nosso site</h1>
                                <p className="fz-12 m-top-10 m-bot-10">* Ajude outros usuários dizendo como foi sua expriencia navegando em nosso site e sinta-se a vontade para dizer como podemos melhorar sua expriência em nosso site.</p>
                                <p className="fz-11 m-top-10 m-bot-10 ">Escolha abaixo como deseja prestar o seu depoimento.</p>
                            </div>{/*box-header-proj-single*/}
                            <div className='box-opts-acount'>
                                <button className={(anonimo) ? 'otp-acount w50-abs fz-15 default' : 'otp-acount w50-abs fz-15 active'} onClick={(e) => setAnonimo(false)}>
                                    <FaUserCheck />
                                    <span className='m-lef-5'>Identificar</span>
                                </button>
                                {
                                    (props.responsivoSize && props.responsivoSize > 0)
                                        ?
                                        <button className={(anonimo) ? 'otp-acount w50-abs fz-15 active' : 'otp-acount w50-abs fz-15 default'} onClick={(e) => setAnonimo(true)}>
                                            <FaUserSecret />
                                            <span className='m-lef-5'>Anônimo</span>
                                        </button>
                                        : null
                                }
                            </div>
                            {
                                (anonimo) // DEPOIMENTO ANONIMO
                                    ?
                                    <form id="form_depoimento" className='singup-person' onSubmit={(e) => visualizarDepoimento(e)}>
                                        <div className='box-input-single m-top-20'>
                                            <label>
                                                <div className='title-input d-fx d-al-cen'>
                                                    <span className='fz-12 m-rig-5'>Título do Depoimento</span>
                                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                </div>
                                                <div className='helper-input d-nn'>
                                                    <h3>Seu CPF será usado para:</h3>
                                                    <ul>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                    </ul>
                                                </div>{/*Helper*/}
                                            </label>
                                            <input className='input-form w100 fz-16 m-top-5' type='text' name="titulo" placeholder='Título do seu depoimento' value={depoimentoTitulo} onChange={(ev) => setDepoimentoTitulo(ev.target.value)} />
                                        </div>{/*box-input-single */}

                                        <div className='box-input-single m-top-20'>
                                            <label>
                                                <div className='title-input d-fx d-al-cen'>
                                                    <span className='fz-12 m-rig-5'>Depoimento</span>
                                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                </div>
                                            </label>
                                            {/* <input id='telefone-cadastro' className='input-form w100 fz-16 m-top-5' type='text' name="nome" placeholder='(99) 99999-9999' /> */}
                                            <textarea className='input-form w100 fz-16 m-top-5' style={{ maxWidth: '100%', resize: 'vertical', lineHeight: '1.5' }} value={depoimentoConteudo} onChange={(ev) => setDepoimentoConteudo(ev.target.value)} placeholder="Seu Depoimento">
                                                {
                                                    depoimentoConteudo
                                                }
                                            </textarea>
                                        </div>{/*box-input-single */}

                                        <button className="button button-full button-primary margin-top align-left">
                                            Concordo e quero prosseguir
                                        </button>
                                        <div className='aviso-termos m-top-10 helper'>
                                            Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                                        </div>
                                    </form>
                                    : // INDENTIFICADO
                                    <form id="form_depoimento" className='singup-person' onSubmit={(e) => visualizarDepoimento(e)}>
                                        {/* OPÇÕES GERAIS - DADOS PESSOAIS E CONTATO */}
                                        <div className='box-input-single m-top-20'>
                                            <label>
                                                <div className='title-input d-fx d-al-cen'>
                                                    <span className='fz-12 m-rig-5'>Nome Completo</span>
                                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                </div>
                                                <div className='helper-input d-nn'>
                                                    <h3>Seu CPF será usado para:</h3>
                                                    <ul>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                    </ul>
                                                </div>{/*Helper*/}
                                            </label>
                                            <input className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                                            {
                                                (nameMessage)
                                                    ?
                                                    nameMessage.map((infoStatus) => {
                                                        if (infoStatus.status == 'error') {
                                                            return (
                                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-invalid'><TiInfo /></span>
                                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        } else if (infoStatus.status == 'success') {
                                                            return (
                                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-valid'><TiInfo /></span>
                                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    : null
                                            }
                                        </div>{/*box-input-single */}

                                        <div className='box-input-single m-top-20'>
                                            <label>
                                                <div className='title-input d-fx d-al-cen'>
                                                    <span className='fz-12 m-rig-5'>Profissão</span>
                                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                </div>
                                                <div className='helper-input d-nn'>
                                                    <h3>Seu CPF será usado para:</h3>
                                                    <ul>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                    </ul>
                                                </div>{/*Helper*/}
                                            </label>
                                            <input id="nome-cadastro-singup" className={(profissao && !profissao.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="profissao" placeholder='Sua Profissão' value={profissao} onChange={(ev) => setProfissao(ev.target.value)} />
                                        </div>{/*box-input-single */}

                                        <div className='box-input-single m-top-20'>
                                            <label>
                                                <div className='title-input d-fx d-al-cen'>
                                                    <span className='fz-12 m-rig-5'>Título do Depoimento</span>
                                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                </div>
                                                <div className='helper-input d-nn'>
                                                    <h3>Seu CPF será usado para:</h3>
                                                    <ul>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                        <li>
                                                            Te Identificar
                                                        </li>
                                                    </ul>
                                                </div>{/*Helper*/}
                                            </label>
                                            <input className='input-form w100 fz-16 m-top-5' type='text' name="titulo" placeholder='Título do seu depoimento' value={depoimentoTitulo} onChange={(ev) => setDepoimentoTitulo(ev.target.value)} />
                                        </div>{/*box-input-single */}

                                        <div className='box-input-single m-top-20'>
                                            <label>
                                                <div className='title-input d-fx d-al-cen'>
                                                    <span className='fz-12 m-rig-5'>Depoimento</span>
                                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                </div>
                                            </label>
                                            {/* <input id='telefone-cadastro' className='input-form w100 fz-16 m-top-5' type='text' name="nome" placeholder='(99) 99999-9999' /> */}
                                            <textarea className='input-form w100 fz-16 m-top-5' style={{ maxWidth: '100%', resize: 'vertical', lineHeight: '1.5' }} value={depoimentoConteudo} onChange={(ev) => setDepoimentoConteudo(ev.target.value)} placeholder="Seu Depoimento">
                                                {
                                                    depoimentoConteudo
                                                }
                                            </textarea>
                                        </div>{/*box-input-single */}

                                        <button className="button button-full button-primary margin-top align-left">
                                            Concordo e quero prosseguir
                                        </button>
                                        <div className='aviso-termos m-top-10 helper'>
                                            Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                                        </div>
                                    </form>
                            }
                        </div>

                        {
                            (props.responsivoSize && props.responsivoSize > 380)
                                ?
                                < div className="w30" style={{ margin: 'auto' }}>
                                    <img src={depoimento} />
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Convite;