function CardLazyload(params) {
    return (
        <div class="card card-lazyload p-rlt">
            <div className='options__loading p-rlt'>
                <div className='options__single__loading bg__lazyload'></div>
                <div className='options__single__loading bg__lazyload'></div>
                <div className='options__single__loading bg__lazyload'></div>
                <div className='options__single__loading bg__lazyload'></div>
            </div>
            <div className='title__loading p-abs bg__lazyload'></div>
            <div className='content__loading p-abs bg__lazyload'></div>
        </div>
    )
}

function WidgetLazyload(params) {
    return (
        <div class="widget-lazyload p-rlt">
            <div className='widget__loading p-abs bg__lazyload'></div>
            <div className='title__loading p-abs bg__lazyload'></div>
        </div>
    )
}

function BannerLazyload(params) {
    return (
        <div class="banner-lazyload p-rlt w50 bg__lazyload m-top-25 m-bot-25"></div>
    )
}

function BoxLazyload(params) {
    return (
        <div class="box-lazyload p-rlt bg__lazyload"></div>
    )
}

function TestimonialLazyload(props) {
    return (
        <div className={`depoimentos-lazyload p-rlt bg__lazyload eachdiv div${props.chave}`}></div>
    )
}

function FAQLazyload(props) {
    return (
        <div class="faq-lazyload p-rlt bg__lazyload w100 m-top-15"></div>    
    )
}

function BoxDecoradosLazyload(props) {
    return (
        <div class="widget-lazyload p-rlt">
            <div className='widget__loading p-abs bg__lazyload' style={{borderRadius: '50%'}}></div>
            <div className='title__loading p-abs bg__lazyload'></div>
        </div>  
    )
}
export { CardLazyload, WidgetLazyload, BannerLazyload, BoxLazyload, TestimonialLazyload, FAQLazyload, BoxDecoradosLazyload };