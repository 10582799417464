import { Link } from 'react-router-dom'; // IMPORTAÇÃO DE REACT ROUTER
// import Api from "../../../../../../services/Api";
import axios from "axios";
import InputMask from "react-input-mask"; // MASCARA DE INPUT

// IMPORTAÇÃO DE ICONES
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo, TiInputChecked } from 'react-icons/ti';
import { useEffect } from "react";
import { toast } from "react-hot-toast";

// REACT ROUTER DOM
import { useNavigate } from 'react-router-dom';
import { db, auth } from "../../../../../../firebase";

// IMAGENS
import brandModel from "../../../../../../images/icones/png/cartao-bandeira-mastercard.png"
import contactUs from "../../../../../../images/icones/svg/promocao.svg"
import { isArray, isEmptyObject } from "jquery";

const { useState, useMemo } = require("react");

function FormPaymentPIX(props) {
    let navigate = useNavigate();
    const [produtos, setProdutos] = (props.cart);

    function sendFormPayment(e) {
        e.preventDefault();
        // alert("Enviar formulario de PIX");
        props.setBtnPaymentDisabled(true);
        /* */
        let valorCobranca = totalFinal();
        // axios.post('https://config.eengeart.com/pagamentos/boleto_pix', {
        axios.post('https://eengeart.com.br/pagamentos/boleto_pix', {
        // axios.post('https://localhost:443/pagamentos/boleto_pix', {
            dados_cobranca: {
                billingType: "PIX",
                totalValue: valorCobranca,
                tipo_transacao: props.paymentMethod,
                description: (() => {
                    var produtos = ([]);
                    props.cart.map((dadosProdutos) => {
                        produtos.unshift(dadosProdutos.titulo + ' • E' + dadosProdutos.id + 'A');
                    })
                    produtos = produtos.toString().replace(/,/g, '\n');
                    return (produtos);
                })(),
                externalReference: Math.floor(Math.random() * Date.now()),
                vencimento: (() => {
                    var data = new Date();
                    console.log(data.getDate()); // 14
                    data.setDate(data.getDate() + 5); // AQUI ESTOU ADICIONANDO O NUMERO DE DIAS PARA O VENCIMENTO

                    // console.log(data.getDay());
                    // console.log(data.toLocaleDateString("pt-BR")); // 19
                    if (data.getDay() == 6) {
                        data.setDate(data.getDate() + 2);
                        // console.log(data.toLocaleDateString("pt-BR")); // 19
                        var dataBrasileira = data.toLocaleDateString("pt-BR");
                        var dataSeparada = dataBrasileira.split("/");
                        var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                        return (novaData)
                    } else if (data.getDay() == 0) {
                        data.setDate(data.getDate() + 1);
                        // console.warn(data.toLocaleDateString("pt-BR")); // 19
                        var dataBrasileira = data.toLocaleDateString("pt-BR");
                        var dataSeparada = dataBrasileira.split("/");
                        var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                        return (novaData)
                    } else {
                        // console.error(data.toLocaleDateString("pt-BR")); // 19
                        var dataBrasileira = data.toLocaleDateString("pt-BR");
                        var dataSeparada = dataBrasileira.split("/");
                        var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                        return (novaData)
                    }
                })()
            },
            dados_comprador: props.user,
            dados_contato: {
                endereco: props.enderecoComprador[0],
                telefones: {
                    principal: props.telefoneComprador
                }
            },
            dados_pedido: (() => {
                var produtos = ([]);
                props.cart.map((dadosProdutos) => {
                    produtos.unshift(dadosProdutos);
                })
                return (produtos)
            })()
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error) { // EXISTE ERRO
                        if (response.data.error == "Error" && !isArray(response.data.message)) {
                            toast.error("Ops! Ocorreu um erro ao processar seu pagamento :(");
                            toast.error(response.data.message);
                        } else if (response.data.error == "Error" && isArray(response.data.message)) {
                            toast.error("Ops! Ocorreu um erro ao processar seu pagamento :(");
                            for (let i = 0; i < response.data.message.length; i++) {
                                toast.error(response.data.message[i].description);
                            }
                        } else {
                            toast.error("Ops! Ocorreu um erro ao processar seu pagamento :(");
                        }
                    } else if (response.data.success) {
                        // CADASTRAR TOKENIZACAO DE CARTÃO DE CREDITO
                        // console.log(response);
                        // CADASTRAR DADOS DO CARTAO DO USUARIO
                        var arrPedido = response.data.message;
                        arrPedido.dados_pedido = props.cart;
                        // console.warn(arrPedido);
                        auth.onAuthStateChanged((val) => {
                            db.collection("usuarios").doc(val.uid)
                                .collection("pedidos")
                                .add(arrPedido)
                                .then(() => {
                                    // console.log(response.data)
                                    toast.success("Pagamento efetuado com sucesso!");
                                    navigate("/pedido/pagamento/sucesso/" + response.data.message.externalReference);
                                })
                                .catch((error) => {
                                    toast.error(error);
                                })
                        })
                    }
                }
            })
            .catch((err) => {
                // setBtnDisabled(false)
                console.error("ops! ocorreu um erro: ", err);
            })
            .finally(() => {
                props.setBtnPaymentDisabled(false);
            })
        /*  */
    }

    function totalFinal() {
        let amountProduct = 0; // SOMA DE VALORES DOS PRODUTOS
        let amountShipping = 0; // SOMA DE FRETE DOS PRODUTOS
        let amountDiscount = 0; // CALCULO DE DESCONTOS DOS PRODUTOS

        // LOOPING SOMAR VALORES DOS PRODUTOS
        props.cart.map((i) => {
            amountProduct += i.precoTabela * i.quantidade
        })

        // LOOPING SOMAR FRETE DOS PRODUTOS
        props.cart.map((i) => {
            amountShipping += i.frete.online.preco
        })

        // LOOPING SOMAR DESCONTO DOS PRODUTOS
        props.cart.map((i) => {
            amountDiscount += ((i.precoTabela / 100) * i.desconto) * i.quantidade;
        })

        return ((amountProduct + amountShipping) - amountDiscount);
    }

    return (
        <>
            <form id='dados-form-pagamento-pix' className='singup-person w100' onSubmit={(e) => sendFormPayment(e)}>

                <section className='p-g-20 m-top-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt w100' style={{ background: 'rgba(0, 109, 255, 0.06)', borderRadius: '8px' }}>
                    <div className='content-infos__retirada tx-black w100'>
                        <h2 className='fz-16 m-bot-5'><strong className='info-primary'>Pagar com o PIX é simples, rápido e seguro</strong></h2>
                        <ul style={{ listStyle: 'none' }}>
                            <li className='fz-14 m-bot-5'>Tão simples que com a câmera do celular você consegue realizar o pagamento!</li>
                            <li className='fz-14 m-bot-5'>Tão rápido que nós confirmamos seu pagamento dentro de 15 segundos.</li>
                            <li className='fz-14 m-bot-5'>E o melhor, como o PIX é instantâneo o prazo de entrega começa a contar imediatamente após o pagamento.</li>
                        </ul>
                    </div>
                </section>

                <section className='p-g-20 m-top-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt w100' style={{ background: '#e5e5e5', borderRadius: '8px' }}>
                    <div className='content-infos__retirada tx-black w100'>
                        <h2 className='fz-16 m-bot-5'><strong className='info-primary'>Mas atenção!</strong></h2>
                        <ol className='m-lef-15'>
                            <li className='fz-14 m-bot-5'>Quando você escolhe realizar o pagamento via PIX o valor do seu pedido não poderá ser parcelado.</li>
                            <li className='fz-14 m-bot-5'>Para a sua segurança, geramos códigos Pix exclusivos para cada pedido. Então, caso você não consiga realizar o pagamento até a expiração do código, não se preocupe, faremos o cancelamento automático.</li>
                            <li className='fz-14 m-bot-5'>Em casos de cancelamento por expiração do código, você pode tentar repetir a compra, nosso site estará sempre disponível para você realizar um novo pedido.</li>
                        </ol>

                        <h2 className='fz-16 m-bot-5 m-top-20'><strong className='info-primary'>Para acesso</strong></h2>
                        <ul style={{ listStyle: 'none' }}>
                            <li className='fz-14 m-bot-5'>Você também poderá acessar o seu código PIX desse pedido através da página "Meus Pedidos" ou nas comunicações enviadas por e-mail.</li>
                        </ul>
                    </div>
                </section>
            </form>
        </>
    )
}

export default FormPaymentPIX;