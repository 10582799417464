import '../Assets/styles/css/Main.css';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';


import $ from 'jquery';

// IMPORTAÇÃO DE IMAGENS
import whats from '../../../../images/icones/svg/whats.svg';
import slide from '../../../../images/projetos/png/slide04.png';
import promo from '../../../../images/projetos/png/Projetos Prontos.png';
import home from '../../../../images/projetos/png/home1.png';
import promo2 from '../../../../images/projetos/jpg/promo.jpg';
import promoBox from '../../../../images/projetos/jpg/promo_380x400.jpg';

// REACT ICONS
import { TbDimensions } from 'react-icons/tb';
import { AiOutlineMinusCircle, AiOutlinePlusCircle, AiFillInfoCircle } from 'react-icons/ai';
import { BsChevronRight, BsCalendarRange } from 'react-icons/bs';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from '../Assets/components/Arrows';

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'



import firebase from 'firebase';
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import axios from 'axios';
import toast from 'react-hot-toast';
import { db } from '../../../../firebase';
import SlidesOfertasProjetos from '../Assets/components/SlidesOfertasProjetos';
import { CarouselTerrenos } from '../Assets/components/CarouselTerrenos';
import CardsProjetos from '../Assets/components/CardsProjetos';
import BoxDecoracao from '../Assets/components/BoxDecoracao';
import Newsletter from '../Assets/components/Newsletter';
import Depoimentos from '../Assets/components/Depoimentos';
import FAQProjetosProntos from '../Assets/components/FAQProjetosProntos';
import CuponsOfertas from '../Assets/components/CuponsOfertas';
import BoxProjetosDecorados from '../Assets/components/BoxProjetosDecorados';
import SlidesOfertasGerais from '../Assets/components/SlidesOfertasGerais';

function Projetos(props) {


    const [bgSlideHome, setbgSlideHome] = useState([]);
    const [dataDec, setDataDec] = useState([]);

    const [data, setData] = useState([]);
    const carousel = useRef(null);
    const [projetos, setProjetos] = useState([]);
    const [visualizacao, setVisualizacao] = useState([]);
    const [favoritados, setFavoritados] = useState([]);

    // useEffect(() => {
    //     // PEGANDO TODOS OS PROJETOS
    //     db.collection("produtos").doc("projetos").collection("residencial").where("status", "==", true).onSnapshot((infos) => {
    //         var arr = ([]);
    //         infos.docs.map((dados) => {
    //             arr.unshift(dados.data());
    //         })
    //         setProjetos(arr);
    //     })

    //     db.collection("relatorios").doc("visualizacoes").collection("projetos").onSnapshot((allViews) => {
    //         var arr = ([]);
    //         allViews.docs.map((dataViews) => {
    //             arr.unshift(dataViews.data());
    //         })
    //         setVisualizacao(arr);
    //     })

    //     db.collection("relatorios").doc("visualizacoes").collection("favoritos").onSnapshot((allLike) => {
    //         var arr = ([]);
    //         allLike.docs.map((dataLike) => {
    //             arr.unshift(dataLike.data());
    //         })
    //         setFavoritados(arr);
    //     })
    // }, []);
    // console.log("Página de projetos")
    return (

        <>
            <Helmet title="Engeart • Projetos" />

            {/* SLIDES - PROJETOS */}
            <SlidesOfertasGerais />

            {/* SESSÃO - TERRENOS - PROJETOS */}
            <CarouselTerrenos />

            {/* SESSÃO - PROJETOS DECORADOS */}
            <BoxProjetosDecorados responsivoSize={props.responsivoSize} />

            {/* SESSÃO - ULTIMAS VISUALIZAÇÕES */}
            <div>
                <CardsProjetos user={props.user} setItemFavorito={props.setItemFavorito} setOpenLike={props.setOpenLike} projetos={props.projetos} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} listasFavoritos={props.listasFavoritos} setListasFavoritos={props.setListasFavoritos} favorito={props.favorito} setFavorito={props.setFavorito} visualizacao={visualizacao} favoritados={favoritados} openModal={props.openModal} setOpenModal={props.setOpenModal} />
                <div className='w100 d-fx d-al-cen d-ju-cen'>
                    <Link to="/projetos/projetos-prontos" className='d-fx d-al-cen fz-13 btn-minimalista' style={{maxWidth: '170px'}}>Ver todos os projetos <BsChevronRight /></Link>
                </div>
            </div>

            {/* SESSÃO - DICAS EM DESTAQUE */}
            {/* <BoxDecoracao titulo="Dicas de Arquitetura, Design de Interiores e Paisagismo" /> */}

            {/* SESSÃO - NEWSLETTER */}
            <Newsletter user={props.user} />

            {/* SLIDES - CLASSES - PROJETOS */}
            <SlidesOfertasProjetos tipo="classe" />

            {/* SESSÃO - CUPONS - PROMOÇÕES */}
            <CuponsOfertas />

            {/* SESSÃO - DEPOIMENTOS */}
            <Depoimentos />

            {/* SESSÃO - FAQ - HOME */}
            <FAQProjetosProntos />
        </>
    );
}

export default Projetos;