import '../Assets/styles/css/Main.css';

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE REACT ROUTER
import { Link, useParams, useHref } from 'react-router-dom';

// IMPORTAÇÃO DE IMAGENS

// IMPORTAÇÃO DE ICONES
import axios from 'axios';

//  IMPORTAÇÃO DE COMPONENTES
import CardsAtendimento from '../Assets/components/CardsAtendimento';
import FormAtendimento from '../Assets/components/FormAtendimento';
import InfoLocalizacao from '../Assets/components/InfoLocalizacao';

function Contato(props) {

    let page = window.location.pathname.replace("/", "");

    return (
        <div id='contato'>
            <Helmet title="Engeart • Contato" />
            <div className='projeto-single-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item active m-0">Atendimento</li>
                                    </ol>
                                </nav>
                            </div>

                            <FormAtendimento formularioImagem={true} responsivoSize={props.responsivoSize} cadastros={props.cadastros} />

                            <CardsAtendimento paginaAtual={page} />

                            <InfoLocalizacao pageImage={false} responsivoSize={props.responsivoSize} />
                        </div>
                    </div>
                </div>{/*center*/}
            </div>
        </div>
    );
}

export default Contato;