import '../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from './Templates/Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineFolderView, AiOutlineCheck, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { TbZoomCancel, TbZoomIn, TbZoomOut } from 'react-icons/tb';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaRegEdit } from 'react-icons/fa';
import searchFolder from '../../../../../../images/icones/svg/search-folder.svg';
import searchDocument from '../../../../../../images/icones/svg/search-document.svg';
import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Document, Page, pdfjs } from 'react-pdf';

// GERAR PDF
import jsPDF from "jspdf";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import * as html2pdf from 'html2pdf.js';



import Contrato from './Assets/Components/Contrato';
import { useMemo } from 'react';


function MyContractSingle(props) {
    let { token } = useParams();

    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);
    const [contentContract, setContentContract] = useState([]);

    function assinarContrato(e, usuario) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        // console.log(props.contratoUnico.vencimento_servico)
        props.setOpenModal({ status: true, tipo: "assinatura-contrato", validade: props.contratoUnico.etapas.at(-1).dataFinal, token: props.contratoUnico.token, usuario: usuario, dados_servico: props.contratoUnico })
    }


    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = () => {
        // var element = document.getElementById('divToPrint');
        // html2pdf(element);
    };

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    /*To Prevent right click on screen*/
    // document.addEventListener("contextmenu", (event) => {
    //     event.preventDefault();
    // });

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    useMemo(() => {
        if (token && token.length) {
            if (!props.ccontratoUnico || isEmptyObject(props.contratoUnico)) {
                let arr = ([]);
                if (props.user && !isEmptyObject(props.user) && props.user.representante && props.user.dados_representante && !isEmptyObject(props.user.dados_representante) && props.user.dados_representante.cargo && props.user.dados_representante.cargo === 'CEO') {
                    db.collection("servicos")
                    .where("token", "==", token)
                    .onSnapshot((snap) => {
                        arr.push(
                            snap.docs.map((doc) => {
                                return doc.data();
                            })
                        )
                        props.setContratoUnico(arr[0][0]);
                    })
                } else if (props.user && !isEmptyObject(props.user) && props.user.representante && props.user.dados_representante && props.user.dados_representante && !isEmptyObject(props.user.dados_representante) && props.user.dados_representante.token && props.user.dados_representante.token.length) {
                    db.collection("servicos")
                    .where("token", "==", token)
                    .where("numero_representante_contratado", "==", props.user.dados_representante.token)
                    .onSnapshot((snap) => {
                        arr.push(
                            snap.docs.map((doc) => {
                                return doc.data();
                            })
                        )
                        props.setContratoUnico(arr[0][0]);
                    })
                }  else {
                    db.collection("servicos")
                    .where("token", "==", token)
                    .where("cpf_cnpj_contratante", "==", props.user.cpf)
                    .onSnapshot((snap) => {
                        arr.push(
                            snap.docs.map((doc) => {
                                return doc.data();
                            })
                        )
                        props.setContratoUnico(arr[0][0]);
                    })
                }
            }
        }
    }, [])

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title={`Engeart • Contrato ${(props.contratoUnico && props.contratoUnico.token && props.contratoUnico.token.length) ? props.contratoUnico.token : "Particular"}`} />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        {
                            (() => {
                                if (!props.contratoUnico) {
                                    return (
                                        <div className='d-fx d-al-cen'>
                                            <div className='w65 p-g-25'>
                                                <h2 className='m-bot-20'>Ooops!</h2>
                                                <div style={{ lineHeight: 1.5 }}>
                                                    <p className='fz-16'>Não foi possivel localizarmos o contrato informado :(</p>
                                                    <p className='fz-16'>Parece que o contrato que você está buscando está sendo desenvolvido ou o código localizador passado está errado!</p>
                                                </div>

                                                <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                                                    <Link to="/minha-conta/meus-contratos" className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado">
                                                        <span className=' fz-12'>Meus Contratos</span>
                                                    </Link>

                                                    <Link to="/" className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }}>
                                                        <span className=' fz-12'>Página Inícial</span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='w35'>
                                                <img src={searchFolder} />
                                            </div>
                                        </div>
                                    )
                                } else if (isEmptyObject(props.contratoUnico)) {
                                    return (
                                        <div className='d-fx d-al-cen'>
                                            <div className='w65 p-g-25'>
                                                <h2 className='m-bot-20'>Ooops!</h2>
                                                <div style={{ lineHeight: 1.5 }}>
                                                    <p className='fz-16'>Não foi possivel recuperarmos as informações do contrato informado :(</p>
                                                    <p className='fz-16'>Parece que o contrato que você está buscando está sendo desenvolvido ou o código localizador passado está errado!</p>
                                                </div>

                                                <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                                                    <Link to="/minha-conta/meus-contratos" className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado">
                                                        <span className=' fz-12'>Meus Contratos</span>
                                                    </Link>

                                                    <Link to="/" className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }}>
                                                        <span className=' fz-12'>Página Inícial</span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='w35'>
                                                <img src={searchDocument} />
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='content-account p-g-25 p-bot-0'>
                                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Contrato: <strong>{props.contratoUnico.token}</strong></h1>
                                            <div className='box-forms-singup'>
                                                <div className='wrapper__infos__request p-g-15 m-top-15 m-bot-15'>
                                                    <div className='info__single__request w100 d-fx'>
                                                        <div className='icon__info__request info-primary'>
                                                            <IoIosInformationCircleOutline className='fz-18' />
                                                        </div>
                                                        <div className='message__info__request w100 m-lef-5'>
                                                            <p>
                                                                <span className='fz-13' style={{ fontWeight: '300' }}>
                                                                    <strong className='fz-13'>Atenção</strong>: Leia atentamente o contrato, esta operação não poderá ser alterada ou desfeita após você confirmar a contratação dos serviços.
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (props.contratoUnico.anexo_contrato && props.contratoUnico.anexo_contrato.length)
                                                        ?
                                                        props.contratoUnico.anexo_contrato.map((anexo) => {
                                                            if (anexo.tipo && anexo.tipo === "application/pdf") {
                                                                return (
                                                                    <>
                                                                        <div className='w100 view__anexos__wrapper p-rlt m-top-15 m-bot-15' style={{ padding: '20px 10px', background: '#fff', borderRadius: '8px', border: '1px solid #ccc' }}>
                                                                            <TransformWrapper>
                                                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                                                    <>
                                                                                        <TransformComponent>
                                                                                            <Document
                                                                                                loading="Carregando Documento ..."
                                                                                                noData="O Documento está vázio!"
                                                                                                renderMode={undefined}
                                                                                                file={anexo.diretorio}
                                                                                                onLoadSuccess={onDocumentLoadSuccess}
                                                                                                onLoadProgress={(data) => { console.log("Progresso", data) }}
                                                                                            >
                                                                                                <BrowserView>
                                                                                                    <Page
                                                                                                        scale={(anexo.tipo.split('/')[1] === 'pdf') ? 5 : 2}
                                                                                                        renderTextLayer={false}
                                                                                                        renderAnnotationLayer={false}
                                                                                                        pageNumber={pageNumber}
                                                                                                    />
                                                                                                </BrowserView>
                                                                                                <MobileView>
                                                                                                    <Page
                                                                                                        renderTextLayer={false}
                                                                                                        renderAnnotationLayer={false}
                                                                                                        pageNumber={pageNumber}
                                                                                                    />
                                                                                                </MobileView>
                                                                                            </Document>
                                                                                        </TransformComponent>

                                                                                        <div className="page-tools">
                                                                                            <button
                                                                                                className='btn__tools'
                                                                                                onClick={() => zoomIn()}
                                                                                            >
                                                                                                <TbZoomIn className='fz-18' />
                                                                                            </button>
                                                                                            <button
                                                                                                onClick={() => zoomOut()}
                                                                                            >
                                                                                                <TbZoomOut className='fz-18' />
                                                                                            </button>
                                                                                            <button
                                                                                                onClick={() => resetTransform()}
                                                                                            >
                                                                                                <TbZoomCancel className='fz-18' />
                                                                                            </button>
                                                                                        </div>

                                                                                        <div class="page-controls">
                                                                                            <button
                                                                                                type="button"
                                                                                                disabled={pageNumber <= 1}
                                                                                                onClick={previousPage}
                                                                                                className="Pre"
                                                                                            >‹</button>
                                                                                            <span>{pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}</span>
                                                                                            <button
                                                                                                type="button"
                                                                                                disabled={pageNumber >= numPages}
                                                                                                onClick={nextPage}
                                                                                            >›</button>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </TransformWrapper>
                                                                        </div>
                                                                    </>
                                                                )
                                                            } else if (anexo.tipo && anexo.tipo.split("/")[0] === "image") {
                                                                return;
                                                            }
                                                        })
                                                        :
                                                        <div id='divToPrint' className='content__contract p-bot-35 m-bot-20' ref={reportTemplateRef}>
                                                            <div dangerouslySetInnerHTML={{ __html: props.contratoUnico.contrato }} />
                                                            <div className='m-top-20'>
                                                                <p className='d-fx d-fd-col'>
                                                                    <img src={props.contratoUnico.assinatura_contratado} style={{ width: 'fit-content', maxWidth: '320px', marginBottom: '-20px' }} />
                                                                    _________________________________________
                                                                </p>
                                                                <p style={{ textTransform: 'uppercase' }}>CONTRATADA: {props.contratoUnico.contratado}</p>
                                                                <p style={{ textTransform: 'uppercase' }}>
                                                                    {
                                                                        (() => {
                                                                            var cpf_cnpj = props.contratoUnico.cpf_cnpj_contratado.toString();
                                                                            if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                return (`CNPJ: ${new_cnpj}`);
                                                                            } else {
                                                                                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                return (`CPF: ${new_cnpj}`);
                                                                            }
                                                                        })()
                                                                    }
                                                                </p>
                                                                <p style={{ textTransform: 'uppercase' }}>NOME DO REPRESENTANTE: {props.contratoUnico.representante_contratado}</p>
                                                                <p style={{ textTransform: 'uppercase' }}>CÓDIGO DO REPRESENTANTE: {props.contratoUnico.numero_representante_contratado}</p>
                                                                <p><br /></p>
                                                                <p><br /></p>
                                                                <p><br /></p>
                                                                <p><br /></p>
                                                                <p><br /></p>
                                                                <p className='d-fx d-fd-col'>
                                                                    <img src={props.contratoUnico.assinatura_contratante} style={{ width: 'fit-content', maxWidth: '320px', marginBottom: '-20px' }} />
                                                                    _________________________________________
                                                                </p>
                                                                <p style={{ textTransform: 'uppercase' }}>CONTRATANTE: {props.contratoUnico.contratante}</p>
                                                                <p style={{ textTransform: 'uppercase' }}>
                                                                    {
                                                                        (() => {
                                                                            var cpf_cnpj = props.contratoUnico.cpf_cnpj_contratante.toString();
                                                                            if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
                                                                                return (`CNPJ: ${new_cnpj}`);
                                                                            } else {
                                                                                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                                                                                return (`CPF: ${new_cnpj}`);
                                                                            }
                                                                        })()
                                                                    }
                                                                </p>
                                                                {
                                                                    (props.contratoUnico && props.contratoUnico.rg && props.contratoUnico.rg.length)
                                                                        ?
                                                                        <p style={{ textTransform: 'uppercase' }}>
                                                                            {
                                                                                (() => {
                                                                                    var rg = props.contratoUnico.rg_contratante.toString();
                                                                                    var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                    return (`RG: ${new_rg}`);
                                                                                })()
                                                                            }
                                                                        </p>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                }

                                                {
                                                    (props.user && !isEmptyObject(props.user) && props.user.representante && props.contratoUnico && !props.contratoUnico.assinatura_contratado)
                                                        ?
                                                        <div className='footer__contract m-top-15 m-bot-15 d-fx d-ju-rig'>
                                                            {console.log(props.contratoUnico)}
                                                            <button class="continue-application" onClick={(el) => assinarContrato(el, "representante")}>
                                                                <div>
                                                                    <div class="pencil"></div>
                                                                    <div class="folder">
                                                                        <div class="top">
                                                                            <svg viewBox="0 0 24 27">
                                                                                <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <div class="paper"></div>
                                                                    </div>
                                                                </div>
                                                                Assinatura do Representante
                                                            </button>
                                                        </div>
                                                        : null
                                                }

                                                {
                                                    (props.user && !isEmptyObject(props.user) && props.user.cpf === props.contratoUnico.cpf_cnpj_contratante && props.contratoUnico.status && props.contratoUnico.status === 'pendente')
                                                        ?
                                                        <div className='footer__contract m-top-15 m-bot-15 d-fx d-ju-rig'>
                                                            <button class="continue-application" onClick={(el) => assinarContrato(el, "contratante")}>
                                                                <div>
                                                                    <div class="pencil"></div>
                                                                    <div class="folder">
                                                                        <div class="top">
                                                                            <svg viewBox="0 0 24 27">
                                                                                <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <div class="paper"></div>
                                                                    </div>
                                                                </div>
                                                                Confirmar Contratação
                                                            </button>
                                                        </div>
                                                        : null
                                                }

                                                {
                                                    (props.contratoUnico.status && props.contratoUnico.status === 'ativo' && 1 === 2)
                                                        ?
                                                        <div className='footer__contract m-top-15 m-bot-15 d-fx d-ju-rig'>
                                                            <button class="continue-application" onClick={handleGeneratePdf}>
                                                                <div>
                                                                    <div class="pencil"></div>
                                                                    <div class="folder">
                                                                        <div class="top">
                                                                            <svg viewBox="0 0 24 27">
                                                                                <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <div class="paper"></div>
                                                                    </div>
                                                                </div>
                                                                Baixar Contrato
                                                            </button>
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                        </div>
                                    );
                                }
                            })()
                        }
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );
}

export default MyContractSingle;