import '../../../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import '../../../../../../../Assets/styles/css/Buttons.css';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useMemo, useState } from 'react';
import { isEmptyObject } from 'jquery';
import toast from 'react-hot-toast';
import { db } from '../../../../../../../../../../firebase';
import { GiConsoleController } from 'react-icons/gi';
import firebase from 'firebase';

function BudgetForm_revisao(props) {
    const [orcamento, setOrcamento] = useState([]);

    useMemo(() => {
        if (!props.user || isEmptyObject(props.user)) { // VERIFICAÇÃO DE USUÁRIO (RESPONSAVEL TÉCNICO) LOGADO
            toast.error("Não foi possivel recuperar as informações do responsável pelo orçamento");
        } else if (!props.budgetTitulo || !props.budgetTitulo.length) { // TITULO DO ORÇAMENTO
            toast.error("Não foi possivel recuperar o título do serviço para o orçamento");
        } else if (!props.categoriaSelecionada || isEmptyObject(props.categoriaSelecionada) || !props.categoriaSelecionada.categoria.length) { // FINALIDADE ORÇAMENTO
            toast.error("Não foi possivel recuperar a finalizade do serviço para o orçamento");
        } else if (!props.budgetTipoSelecionado || isEmptyObject(props.budgetTipoSelecionado) || !props.categoriaSelecionada.categoria.length) { // FINALIDADE ORÇAMENTO
            toast.error("Não foi possivel recuperar a modalidade do serviço para o orçamento");
        } else if (!props.budgetTipoExecucao || !props.budgetTipoExecucao.length) { // TIPO DE EXECUÇÃO
            toast.error("Não foi possivel recuperar o tipo de execução do serviço para o orçamento");
        } else if (!props.budgetArea || !props.budgetArea.length) { // ÁREA DO SERVIÇO
            toast.error("Não foi possivel recuperar a área do serviço para o orçamento");
        } else if (!props.budgetDescricao || !props.budgetDescricao.length) { // DESCRIÇÃO DO SERVIÇO
            toast.error("Não foi possivel recuperar a descrição do serviço para o orçamento");
        } else if (!props.budgetValidade || !props.budgetValidade.length) { // VALIDADE DO ORCAMENTO
            toast.error("Não foi possivel recuperar a validade do orçamento");
        } else if (!props.budgetEnderecoCliente || isEmptyObject(props.budgetEnderecoCliente)) { // ENDEREÇO DO CLIENTE
            toast.error("Não foi possivel recuperar o endereço do cliente para o orçamento");
        } else if (!props.clienteSelecionado || isEmptyObject(props.clienteSelecionado)) { // DADOS DO CLIENTE
            toast.error("Não foi possivel recuperar as informações do cliente para o orçamento");
        } else if (!props.budgetEtapas || !props.budgetEtapas.length) { // ETAPAS DO SERVIÇO
            toast.error("Não foi possivel recuperar as etapas do serviço para o orçamento");
        } else if (!props.budgetPagamentos || !props.budgetPagamentos.length) { // ETAPAS DE PAGAMENTO DO SERVIÇO
            toast.error("Não foi possivel recuperar as etapas de pagamento do serviço para o orçamento");
        } else if (!props.budgetRequisitos || !props.budgetRequisitos.length) { // REQUISITOS DO SERVIÇO
            toast.error("Não foi possivel recuperar os requisitos do serviço para o orçamento");
        } else if (!props.budgetGarantia || !props.budgetGarantia.length) { // RESALVAS DA GARANTIA DO SERVIÇO
            toast.error("Não foi possivel recuperar as resalvas da garantia do serviço para o orçamento");
        } else if (!props.budgetEnderecoServico || isEmptyObject(props.budgetEnderecoServico)) { // ENDEREÇO DO SERVIÇO
            toast.error("Não foi possivel recuperar o endereço do serviço para o orçamento");
        } else {
            var dataBudget = ({
                cargo_representante: (props.user.representante && props.user.dados_representante && !isEmptyObject(props.user.dados_representante) && props.user.dados_representante.cargo && props.user.dados_representante.cargo.length) ? props.user.dados_representante.cargo : "Representante",
                validade_orcamento: props.budgetValidade,
                metodo_pagamento: null,
                titulo_servico: props.budgetTitulo,
                aprovacao_orcamento: null,
                objeto_contratado: (props.user.representante && props.user.dados_representante && !isEmptyObject(props.user.dados_representante) && props.user.dados_representante.tipo_legal && props.user.dados_representante.tipo_legal.length) ? props.user.dados_representante.tipo_legal : "Privado",
                contratante: (props.clienteSelecionado.full_name && props.clienteSelecionado.full_name.length) ? props.clienteSelecionado.full_name : "Anônimo",
                telefone: (props.clienteSelecionado.telefone && props.clienteSelecionado.telefone.length) ? props.clienteSelecionado.telefone : null,
                email: (props.clienteSelecionado.email && props.clienteSelecionado.email.length) ? props.clienteSelecionado.email : null,
                etapas_pagamento: props.budgetPagamentos,
                status: "pendente",
                endereco_contratante: props.budgetEnderecoCliente,
                gerenciamento: (props.budgetGerenciamento) ? props.budgetGerenciamento : false,
                cpf_cnpj_contratante: (props.clienteSelecionado.cpf && props.clienteSelecionado.cpf.length) ? props.clienteSelecionado.cpf : null,
                acompanhamento: (props.budgetAcompanhamento) ? props.budgetAcompanhamento : false,
                numero_representante_contratado: (props.user.representante && props.user.dados_representante && !isEmptyObject(props.user.dados_representante) && props.user.dados_representante.token && props.user.dados_representante.token.length) ? props.user.dados_representante.token : null,
                cpf_cnpj_contratado: (props.user.representante && props.user.dados_representante && !isEmptyObject(props.user.dados_representante) && props.user.dados_representante.dados_empresa.cpf_cnpj && props.user.dados_representante.dados_empresa.cpf_cnpj.length) ? props.user.dados_representante.dados_empresa.cpf_cnpj : null,
                etapas_sem_garantia: props.budgetGarantia,
                contratado: (props.user.representante && props.user.dados_representante && !isEmptyObject(props.user.dados_representante) && props.user.dados_representante.dados_empresa && !isEmptyObject(props.user.dados_representante.dados_empresa) && props.user.dados_representante.dados_empresa.fantasia && props.user.dados_representante.dados_empresa.fantasia.length) ? props.user.dados_representante.dados_empresa.fantasia : "Representante Engeart",
                observacoes: props.budgetObservacoes,
                expedicao: null,
                tipo_execucao: props.budgetTipoExecucao,
                rg_contratante: (props.clienteSelecionado.rg && props.clienteSelecionado.rg.length) ? props.clienteSelecionado.rg : null,
                token: `E${Date.now()}B`,
                "endereco_contratado": (props.user.representante && props.user.dados_representante && !isEmptyObject(props.user.dados_representante) && props.user.dados_representante.dados_empresa && !isEmptyObject(props.user.dados_representante.dados_empresa) && props.user.dados_representante.dados_empresa.endereco && !isEmptyObject(props.user.dados_representante.dados_empresa.endereco)) ? props.user.dados_representante.dados_empresa.endereco : null,
                requisitos_servico: props.budgetRequisitos,
                compras: (props.budgetCompras) ? props.budgetCompras : false,
                area_servico: props.budgetArea,
                categoria_servico: (props.categoriaSelecionada.categoria && props.categoriaSelecionada.categoria.length) ? props.categoriaSelecionada.categoria : null,
                etapas_servico: props.budgetEtapas,
                endereco_servico: props.budgetEnderecoServico,
                descricao_servico: props.budgetDescricao,
                representante_contratado: (props.user.full_name && props.user.full_name.length) ? props.user.full_name : "Anônimo",
                tipo_servico: (props.budgetTipoSelecionado.tipo && props.budgetTipoSelecionado.tipo.length) ? props.budgetTipoSelecionado.tipo : null
            });
            setOrcamento(dataBudget);
        }
        console.log(orcamento);
    }, [])

    function salvarOrcamento(e) {
        e.preventDefault();

        db.collection("orcamentos")
            .add(orcamento)
            .then((data) => {
                db.collection("orcamentos")
                    .doc(data.id)
                    .update("expedicao",firebase.firestore.FieldValue.serverTimestamp())
                    .then(() => {
                        toast.success("Orçamento salvo com sucesso!");
                        props.setSlideCard(props.slideCard + 1);
                    })
            })
            .catch((err) => {
                console.log("Erro ao salvar o orçamento", "=>", err);
                toast.error("Houve um erro ao salvar o orçamento!");
            })
    }
    return (
        <div id="budget_form_categoria">
            <div className='card__info__budget__single'>
                <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                    <h2 className='b-bot-medium'>Revisão do Orçamento</h2>
                    <div className='wrapper__infos__request p-g-15 m-bot-5'>
                        <div className='info__single__request w100 d-fx'>
                            <div className='icon__info__request info-primary left'>
                                <IoIosInformationCircleOutline className='fz-18' />
                            </div>
                            <div className='message__info__request w100 left m-lef-5'>
                                <p>
                                    <span className='fz-13'>
                                        Revise o orçamento atentamente, após a finalização do orçamento não seá mais possivel editar as informações.
                                    </span>
                                </p>
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>
                </div>
                <div className='wrapper__revisar__orcamento'>
                    <div className='wrapper__itens__request m-top-20 d-fx d-fw-wp d-ju-sp-bt'>

                        <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}> {/* DADOS DO SERVIÇO */}
                            <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Serviço</span>
                            {
                                (orcamento && orcamento.objeto_contratado && orcamento.objeto_contratado.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Objeto de Contratação:</strong> <span style={{ textTransform: 'capitalize' }}>{orcamento.objeto_contratado}</span>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.tipo_contratado && orcamento.tipo_contratado.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Tipo de Serviço:</strong> <span style={{ textTransform: 'capitalize' }}>{orcamento.tipo_contratado}</span>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.categoria_servico && orcamento.categoria_servico.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Categoria de Serviço:</strong> <span style={{ textTransform: 'capitalize' }}>{orcamento.categoria_servico}</span>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.descricao_servico && orcamento.descricao_servico.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Descrição de Serviço:</strong> <span style={{ textTransform: 'capitalize' }}>{orcamento.descricao_servico}</span>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.tipo_execucao && orcamento.tipo_execucao.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Tipo de Execução:</strong> <span style={{ textTransform: 'capitalize' }}>{orcamento.tipo_execucao}</span>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.endereco_servico && !isEmptyObject(orcamento.endereco_servico))
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Endereço:</strong> {`${orcamento.endereco_servico.logradouro}, ${orcamento.endereco_servico.numero}, ${orcamento.endereco_servico.bairro} - ${orcamento.endereco_servico.cidade}, - ${orcamento.endereco_servico.estado}, CEP ${(() => {
                                            var new_cep = orcamento.endereco_servico.cep.toString();
                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                        })()}`}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.etapas_pagamento && orcamento.etapas_pagamento.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Quantidade de Etapas de Pagamento:</strong> {orcamento.etapas_pagamento.length}
                                    </span>
                                    : null
                            }
                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                <strong>Acompanhamento de Serviço:</strong> {(orcamento && orcamento.acompanhamento) ? "Ativo" : "Não Incluso"}
                            </span>
                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                <strong>Gerênciamento de Serviço:</strong> {(orcamento && orcamento.gerenciamento) ? "Ativo" : "Não Incluso"}
                            </span>
                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                <strong>Compra de Materiais do Serviço:</strong> {(orcamento && orcamento.compras) ? "Ativo" : "Não Incluso"}
                            </span>
                            {
                                (orcamento && orcamento.etapas_servico && orcamento.etapas_servico.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Etapas do Serviço:</strong>
                                        <ul style={{ marginLeft: '20px' }}>
                                            {
                                                orcamento.etapas_servico.map((obs, index) => {
                                                    return (<li key={index} className="m-top-5">{obs}</li>)
                                                })
                                            }
                                        </ul>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.etapas_pagamento && orcamento.etapas_pagamento.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Etapas de Pagamentos:</strong>
                                        <ul style={{ marginLeft: '20px' }}>
                                            {
                                                orcamento.etapas_pagamento.map((obs, index) => {
                                                    return (
                                                        <li key={index} className="m-top-15">
                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                <strong>Etapa:</strong> {index + 1}
                                                            </span>
                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                <strong>Descrição:</strong> {obs.descricao_pag}
                                                            </span>
                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                <strong>Valor:</strong> {(obs.valor_pag && obs.valor_pag.length) ? parseInt(obs.valor_pag).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ *****,**"}
                                                            </span>
                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                <strong>Vencimento:</strong> {(obs.data_pag && obs.data_pag.length) ? new Date(obs.data_pag).toLocaleDateString("pt-BR") : "R$ *****,**"}
                                                            </span>
                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                <strong>Percentual Financeiro:</strong>
                                                                <span className='m-lef-5'>
                                                                    {
                                                                        (() => {
                                                                            var valorTotal = 0;
                                                                            orcamento.etapas_pagamento.map((val) => {
                                                                                valorTotal += parseInt(val.valor_pag);
                                                                            })
                                                                            var percentalUnico = ((parseInt(obs.valor_pag) * 100) / valorTotal).toFixed(0);
                                                                            return (`${percentalUnico}%`);
                                                                        })()
                                                                    }
                                                                </span>
                                                            </span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.etapas_pagamento && orcamento.etapas_pagamento.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Valor Total do Serviço:</strong>
                                        <span className='m-lef-5'>
                                            {
                                                (() => {
                                                    var valorTotal = 0;
                                                    props.budgetPagamentos.map((val) => {
                                                        valorTotal += parseInt(val.valor_pag);
                                                    })
                                                    return (`${valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`);
                                                })()
                                            }
                                        </span>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.requisitos_servico && orcamento.requisitos_servico.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Requisitos para o Serviço:</strong>
                                        <ul style={{ marginLeft: '20px' }}>
                                            {
                                                orcamento.requisitos_servico.map((obs, index) => {
                                                    return (<li key={index} className="m-top-5">{obs}</li>)
                                                })
                                            }
                                        </ul>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.etapas_sem_garantia && orcamento.etapas_sem_garantia.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Limites da Garantia para o Serviço:</strong>
                                        <ul style={{ marginLeft: '20px' }}>
                                            {
                                                orcamento.etapas_sem_garantia.map((obs, index) => {
                                                    return (<li key={index} className="m-top-5">{obs}</li>)
                                                })
                                            }
                                        </ul>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.observacoes && orcamento.observacoes.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Observações:</strong>
                                        <ul style={{ marginLeft: '20px' }}>
                                            {
                                                orcamento.observacoes.map((obs, index) => {
                                                    return (<li key={index} className="m-top-5">{obs}</li>)
                                                })
                                            }
                                        </ul>
                                    </span>
                                    : null
                            }
                        </div>
                        <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                            <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratante</span>
                            {
                                (orcamento && orcamento.contratante && orcamento.contratante.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Contratante:</strong> {orcamento.contratante}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.cpf_cnpj_contratante)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        {
                                            (() => {
                                                var cpf_cnpj = orcamento.cpf_cnpj_contratante.toString();
                                                if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                    var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                    return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                } else {
                                                    var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                    return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                }
                                            })()
                                        }
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.rg_contratante)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        {
                                            (() => {
                                                var rg = orcamento.rg_contratante.toString();
                                                var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                return (<span><strong>RG:</strong> {new_rg}</span>);
                                            })()
                                        }
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.endereco_contratante && !isEmptyObject(orcamento.endereco_contratante))
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Endereço:</strong> {`${orcamento.endereco_contratante.logradouro}, ${orcamento.endereco_contratante.numero}, ${orcamento.endereco_contratante.bairro} - ${orcamento.endereco_contratante.cidade}, - ${orcamento.endereco_contratante.estado}, CEP ${(() => {
                                            var new_cep = orcamento.endereco_contratante.cep.toString();
                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                        })()}`}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.email && orcamento.email.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>E-mail:</strong> {orcamento.email}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.telefone && orcamento.telefone.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Telefone:</strong> {orcamento.telefone}
                                    </span>
                                    : null
                            }
                        </div>
                        <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}> {/* DADOS DO CONTRATADO */}
                            <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratado</span>
                            {
                                (orcamento && orcamento.contratado && orcamento.contratado.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Empresa:</strong> {orcamento.contratado}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.cpf_cnpj_contratado && orcamento.cpf_cnpj_contratado)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        {
                                            (() => {
                                                var cpf_cnpj = orcamento.cpf_cnpj_contratado.toString();
                                                if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                    var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                    return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                } else {
                                                    var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                    return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                }
                                            })()
                                        }
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.representante_contratado && orcamento.representante_contratado.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Nome do Representante:</strong> {orcamento.representante_contratado}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.numero_representante_contratado)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Código do Representante:</strong> {orcamento.numero_representante_contratado}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.objeto_contratado && orcamento.objeto_contratado.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Objeto de Contratação:</strong> <span style={{ textTransform: 'capitalize' }}>{orcamento.objeto_contratado}</span>
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.tipo_servico && orcamento.tipo_servico.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Tipo de Serviço:</strong> {orcamento.tipo_servico}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.categoria_servico && orcamento.categoria_servico.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Categoria de Serviço:</strong> {orcamento.categoria_servico}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.descricao_servico && orcamento.descricao_servico.length)
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Descrição de Serviço:</strong> {orcamento.descricao_servico}
                                    </span>
                                    : null
                            }
                            {
                                (orcamento && orcamento.endereco_contratado && !isEmptyObject(orcamento.endereco_contratado))
                                    ?
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Endereço:</strong> {`${orcamento.endereco_contratado.logradouro}, ${orcamento.endereco_contratado.numero}, ${orcamento.endereco_contratado.bairro} - ${orcamento.endereco_contratado.cidade}, - ${orcamento.endereco_contratado.estado}, CEP ${(() => {
                                            var new_cep = orcamento.endereco_contratado.cep.toString();
                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                        })()}`}
                                    </span>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                <button
                    className="cart"
                    style={{ marginTop: 0, textTransform: 'uppercase' }}
                    onClick={(e) => salvarOrcamento(e)}
                    disabled={(!orcamento || isEmptyObject(orcamento)) ? true : false}
                >
                    <span className=' fz-12'>Próximo</span>
                </button>
            </div>
        </div>
    );

}

export default BudgetForm_revisao;