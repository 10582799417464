import { useEffect, useState } from 'react';
import { db } from '../../../../../firebase';

import slide from '../../../../../images/projetos/png/slide04.png';
import { Link } from 'react-router-dom';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from './Arrows';

import { FaBan, FaStar, FaShareSquare, FaEye, FaHeart, FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp, FaTelegramPlane, FaCalendarDay } from 'react-icons/fa';
import { AiFillLike, AiOutlineClose, AiFillInfoCircle } from 'react-icons/ai';
import { BsLink45Deg, BsFillImageFill } from 'react-icons/bs';
import { MdOutlineMoreHoriz } from 'react-icons/md';

import toast from 'react-hot-toast';
import { CardLazyload } from './Lazyload';
import UXReagir from './UXReagir'
import UXAvaliar from './UXAvaliar';

function CardsProjetos(props) {

    const [lazyload, setLazyload] = useState(true); // LAZYLOAD

    const [visualizacao, setVisualizacao] = useState([]);
    const [favoritados, setFavoritados] = useState([]);

    useEffect(() => {
        if (props.projetos && props.projetos.length > 0) {
            setLazyload(false);
        }
    }, [props.projetos])

    function pegaAvaliacao(id) {
        // console.log(props.itensAvaliados);
        // const found = props.itensAvaliados.find(element => element === id);
        // if (found) {
        //     return true;
        // } else {
        //     return false;
        // }
    }

    function getCheckLike(id) {
        const found = props.itensFavoritos.find(element => element === id);
        if (found) {
            return true;
            {/* alert("Existe"); */ }
        } else {
            return false;
            {/* console.log('Not Found'); */ }
        }
    }

    function removerItemFavorito(el, item, id, orderId) {
        el.preventDefault();

        // console.log(id);

        // CRIANDO VARIÁVEIS - OK
        // var novoFavorito = favorito;
        var novoFavorito = props.listasFavoritos;
        var idLista = null;
        var codLista = null;

        // PUXANDO O ID DA LISTA - OK
        // console.log(novoFavorito);
        props.favorito.map((dados) => {
            if (dados.item.orderId == id) {
                idLista = dados.id_lista_original;
                codLista = dados.id_lista_generico;
            }
        })
        // console.log(idLista);
        // console.log(novoFavorito);

        var newArrLike = new Array();

        novoFavorito.map((dadosFavoritos) => {
            // console.log(dadosFavoritos);
            if (dadosFavoritos.id_lista === idLista) {
                // console.log(dadosFavoritos.id_lista);
                for (let i = 0; i < dadosFavoritos.itens.length; i++) {
                    const element = dadosFavoritos.itens;
                    // if (element[i].id_item === id) {
                    //     console.log(element.indexOf(id));
                    // }
                    newArrLike.push(element[i].id_item)
                }
            }
        })
        // console.log(newArrLike.indexOf(id));
        var posicaoElemento = newArrLike.indexOf(id);
        // console.log(posicaoElemento)

        var addArrLike = new Array();

        novoFavorito.map((novosDados) => {
            // console.log(novosDados);
            if (novosDados.id_lista === idLista) {
                addArrLike.unshift(novosDados.itens);
            }
        })
        // console.log(addArrLike[0])
        var arr1 = addArrLike[0];
        arr1.splice(posicaoElemento, 1);

        // console.log(arr1);

        // INSERIR NO DB
        db.collection('usuarios').doc(props.user.id).collection('favoritos').doc('grupos').collection("listas").doc(idLista).update({
            itens: arr1
        }).then(() => {
            var jobskill_query = db.collection("relatorios").doc("visualizacoes").collection("favoritos").where("id_lista_favorito_original", "==", idLista).where("id", "==", orderId);
            jobskill_query.get().then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    doc.ref.delete();
                });
            });
            toast.success('O item foi removido dos seus favoritos!');
            setTimeout(() => {
                pegaItensFavoritos();
            }, 1000);
        })
    }

    function pegaItensFavoritos() {
        db.collection('usuarios').doc(props.user.id).collection('favoritos').doc('grupos').collection("listas").onSnapshot((listas) => {
            // var allLists = new Array();
            var itensFavoritos = new Array();
            listas.docs.map((subListas) => {
                subListas.data().itens.map((itensLista) => {
                    itensFavoritos.push(itensLista.item.orderId);
                    // console.log(itensLista.item.id)
                    // if(itensLista.item.id == id){
                    //     // alert("É igual" + id);
                    //     validacao = true;
                    // }else{
                    //     validacao = false;
                    // }
                })
            })
            props.setItensFavoritos(itensFavoritos);
        })

    }

    function abrirListaFavoritos(el, tipo, item) {
        el.preventDefault();
        props.setItemFavorito({
            tipo: tipo,
            item: item
        })
        props.setOpenLike((prevState) => !prevState);
    }

    function pegaVisualizacoes(orderId) {
        var contador = 0;
        props.visualizacao.map((dadosVisualizacao) => {
            if (dadosVisualizacao.id == orderId) {
                contador++;
            }
        })
        return (contador)
    }

    function pegaFavoritados(orderId) {
        var contador = 0;
        props.favoritados.map((dadosFavoritados) => {
            if (dadosFavoritados.id == orderId) {
                contador++;
            }
        })
        return (contador)
    }

    let rows = [];
    for (let i = 0; i <= 9; i++) {
        rows.push(<CardLazyload />)
    };

    let breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 600, itemsToShow: 2 },
        { width: 900, itemsToShow: 3 }
    ]

    return (
        <section className="cards container__content z-less">
            <div className='center p-rlt tx-black'>
                {
                    (props.titulo)
                        ?
                        <h2 className='sub-title d-bk'>{props.titulo}</h2>
                        :
                        <h2 className='sub-title d-bk'>Projetos de maior sucesso na sua região</h2>
                }
                <Carousel
                    itemsToShow={3}
                    className="w100"
                    renderArrow={({ type, onClick, isEdge }) => {
                        const pointer = type === consts.PREV ? <ArrowLeft /> : <ArrowRight />
                        return (
                            <button className='btn__controller__carousel' onClick={onClick} disabled={isEdge} style={{ zIndex: 1 }}>
                                {pointer}
                            </button>
                        )
                    }}
                    breakPoints={breakPoints}
                >
                    {
                        (!lazyload)
                            ?
                            props.projetos.map((itens, index) => {
                                return (
                                    <article key={index} className={`card card--${index}`}>
                                        <div className="card__info-hover">
                                            <div className='left w10 p-rlt d-fx' style={{ zIndex: 99, flexDirection: 'column' }}>
                                                <a title='Favoritar' onClick={(e) => toast.error("Esta função está em desenvolvimento")}>
                                                    <FaHeart className="fz-20 m-bot-5" style={{ color: 'var(--grey)' }} />
                                                </a>
                                                {/*
                                                    (getCheckLike(itens.orderId))
                                                        ?
                                                        <a id={itens.orderId} title='Remover favorito' onClick={(el) => { removerItemFavorito(el, itens, itens.orderId, itens.orderId) }}>
                                                            <FaHeart className="fz-20 m-bot-5" style={{ color: 'red' }} />
                                                        </a>
                                                        :
                                                        <a id={itens.orderId} title='Favoritar' onClick={(el) => abrirListaFavoritos(el, 'projeto', itens)}>
                                                            <FaHeart className="fz-20 m-bot-5" style={{ color: 'var(--grey)' }} />
                                                        </a>
                                                */}
                                                {/* <a title='Compartilhar' onClick={(el) => abrirModalCompartilhar(el, itens.orderId, props.openModal, props.setOpenModal)}> */}
                                                <a title='Compartilhar' onClick={(el) => props.setOpenModal({ "status": true, "code": itens.orderId, "categoria": "projetos_prontos_individual", "tipo": "share", "link": `/projetos/${itens.orderId}` })}>
                                                    <FaShareSquare className="fz-20 m-bot-5" style={{ color: 'var(--grey)' }} />
                                                </a>

                                                {/* <UXReagir usuario={props.user} produto={itens} /> */}

                                                {/* <UXAvaliar usuario={props.user} produto={itens} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} /> */}
                                            </div>
                                            <div className="card__clock-info right d-fx d-al-cen">
                                                {
                                                    (props.visualizacao && props.visualizacao.length > 0)
                                                        ?
                                                        <div className='d-fx d-al-cen'>
                                                            <FaEye className="m-lef-5 fz-13" />
                                                            <span className="card__time m-rig-5 tx-10">
                                                                {pegaVisualizacoes(itens.orderId)}
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                •
                                                {
                                                    (props.favoritados && props.favoritados.length > 0)
                                                        ?
                                                        <div className='d-fx d-al-cen'>
                                                            <FaHeart className="m-lef-5 fz-13" />
                                                            <span className="card__time m-lef-5 tx-10">
                                                                {pegaFavoritados(itens.orderId)}
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className='clear'></div>

                                            <div className='infos__project__card p-abs'>
                                                <h3 className='tx-cen fz-14 m-bot-5 d-fx d-al-cen d-ju-cen w100'>
                                                    <AiFillInfoCircle className='fz-12 m-rig-5' />
                                                    Informações
                                                </h3>
                                                <div className='info__single__card b-bot-small'>
                                                    <span className='info__title__card w100 d-fx d-ju-cen p-bot-5 m-bot-5 fz-12'>Terreno (L x C)</span>
                                                    <div className='info__valores d-fx d-ju-sp-ar'>
                                                        <span className='fz-12'>{(itens.larguraTerreno) ? itens.larguraTerreno.toFixed(2).toString().replace(".", ",") : itens.comprimentoTerreno}</span>
                                                        <span className='info_divider'>X</span>
                                                        <span className='fz-12'>{(itens.comprimentoTerreno) ? itens.comprimentoTerreno.toFixed(2).toString().replace(".", ",") : itens.comprimentoTerreno}</span>
                                                    </div>
                                                </div>
                                                <div className='info__single__card b-bot-small'>
                                                    <span className='info__title__card w50 d-fx d-ju-cen left p-bot-5 m-bot-5 fz-12'>Dormitórios</span>
                                                    <span className='info__title__card w50 d-fx d-ju-cen right p-bot-5 m-bot-5 fz-12'>Banheiros</span>
                                                    <div className='clear'></div>
                                                    <div className='info__valores d-fx d-ju-sp-ar'>
                                                        <span className='fz-12'>{itens.quarto}</span>
                                                        <span className='fz-12'>{itens.banheiro}</span>
                                                    </div>
                                                </div>
                                                <div className='info__single__card'>
                                                    <span className='info__title__card w50 d-fx d-ju-cen left p-bot-5 m-bot-5 fz-12'>Garagem</span>
                                                    <span className='info__title__card w50 d-fx d-ju-cen right p-bot-5 m-bot-5 fz-12'>Área</span>
                                                    <div className='clear'></div>
                                                    <div className='info__valores d-fx d-ju-sp-ar'>
                                                        <span className='fz-12'>{itens.garagemVagas}</span>
                                                        <span className='fz-12'>{itens.areaTotal}m²</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card__img"></div>
                                        <Link to={`/projetos/${itens.orderId}`} className="card_link m-0">
                                            <div className="card__img--hover" style={{ backgroundImage: `url(${itens.capa})` }}></div>
                                        </Link>
                                        <div className="card__info p-g-20">
                                            <Link to={`/projetos/${itens.orderId}`} >
                                                <span className="card__category">{itens.tipo}</span>
                                                <h3 className="card__title">{itens.titulo}</h3>
                                                <span className="card__by">de <a href="#" className="card__author" title="author">Engeart</a></span>
                                            </Link>
                                        </div>
                                    </article>
                                );
                            })
                            :
                            rows
                    }

                </Carousel>
            </div>
        </section>
    )
}

export default CardsProjetos;