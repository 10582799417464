import '../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiNotebook } from 'react-icons/gi';
import { AiOutlineFolderView, AiOutlineCheck, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdHomeFilled, MdHomeWork } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaMoneyBill } from 'react-icons/fa';

import { BsCreditCard2Front, BsCashCoin } from 'react-icons/bs';
import { ImQrcode } from 'react-icons/im';
import { GrTransaction } from 'react-icons/gr';
import { FcMoneyTransfer } from 'react-icons/fc';
import { RiHomeGearLine } from 'react-icons/ri';
import { FaBarcode, FaApplePay, FaGooglePay, FaStar } from 'react-icons/fa';


import contractOkay from '../../../../../../../../images/icones/svg/Construction costs-pana.svg';
import budgetOkay from '../../../../../../../../images/icones/svg/Awards-rafiki.svg';
import searchFolder from '../../../../../../../../images/icones/svg/search-folder.svg';
import searchDocument from '../../../../../../../../images/icones/svg/search-document.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';
import BudgetForm_categoria from './Assets/Components/BudgetForm_categoria';
import BudgetForm_cliente from './Assets/Components/BudgetForm_cliente';
import BudgetForm_etapas from './Assets/Components/BudgetForm_etapas';
import BudgetForm_pagamentos from './Assets/Components/BudgetForm_pagamentos';
import BudgetForm_garantia from './Assets/Components/BudgetForm_garantia';
import BudgetForm_requisitos from './Assets/Components/BudgetForm_requisitos';
import BudgetForm_enderecoServico from './Assets/Components/BudgetForm_enderecoServico';
import BudgetForm_adicionais from './Assets/Components/BudgetForm_adicionais';
import BudgetForm_revisao from './Assets/Components/BudgetForm_revisao';
import BudgetForm_sucesso from './Assets/Components/BudgetForm_sucesso';

function PC_ADM_CriarOrcamento(props) {

    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);
    const [slideCard, setSlideCard] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('');

    const [dadosOrcamento, setDadosOrcamento] = useState([{
        categoria_servico: null
    }]);
    // INFORMAÇÕES PRIMÁRIAS - REPRESENTANTE
    const [categoriaSelecionada, setCategoriaSelecionada] = useState([]); // categoria_servico
    const [budgetTitulo, setBudgetTitulo] = useState(''); // titulo_servico
    const [budgetDescricao, setBudgetDescricao] = useState(''); // descricao_servico
    const [budgetTipoSelecionado, setBudgetTipoSelecionado] = useState([]); // tipo_servico
    const [budgetArea, setBudgetArea] = useState(''); // area_servico
    const [budgetTipoExecucao, setBudgetTipoExecucao] = useState(''); // tipo_execucao
    const [budgetValidade, setBudgetValidade] = useState(''); // validade_orcamento

    // INFORMAÇÕES SECUNDÁRIAS - CLIENTE
    const [budgetCPFCNPJCliente, setBudgetCPFCNPJCliente] = useState([]); // cpf_cnpj_contratante
    const [budgetRGCliente, setBudgetRGCliente] = useState([]); // rg_contratante
    const [budgetEnderecoCliente, setBudgetEnderecoCliente] = useState([]); // endereco_contratado
    const [clienteSelecionado, setClienteSelecionado] = useState([]); // TODOS OS DADOS PESSOAIS DO CLIENTE

    // INFORMAÇÕES TERCIARIAS - ETAPAS DO SERVIÇO
    const [budgetEtapas, setBudgetEtapas] = useState([]); // etapas_servico

    // INFORMAÇÕES TERCIARIAS - ETAPAS DO PAGAMENTO
    const [budgetPagamentos, setBudgetPagamentos] = useState([]); // etapas_pagamento

    // INFORMAÇÕES TERCIARIAS - ETAPAS DA EXEÇÃO DA GARANTIA
    const [budgetGarantia, setBudgetGarantia] = useState([]); // etapas_sem_garantia

    // INFORMAÇÕES TERCIARIAS - ETAPAS DA EXEÇÃO DA GARANTIA
    const [budgetRequisitos, setBudgetRequisitos] = useState([]); // requisitos_servico

    // INFORMAÇÕES TERCIARIAS - ETAPAS DA EXEÇÃO DA GARANTIA
    const [budgetEnderecoServicoStatus, setBudgetEnderecoServicoStatus] = useState(false); // Endereço do Serviço (true = Aproveitar || false = cadastrar novo endereço)

    // INFORMAÇÕES TERCIARIAS - SERVIÇOS ADICIONAIS
    const [budgetGerenciamento, setBudgetGerenciamento] = useState(false); // gerenciamento
    const [budgetAcompanhamento, setBudgetAcompanhamento] = useState(false); // acompanhamento
    const [budgetCompras, setBudgetCompras] = useState(false); // compras
    const [budgetObservacoes, setBudgetObservacoes] = useState([]); // observacoes
    // const [budgetTitulo, setBudgetTitulo] = useState(''); // titulo_servico
    // const [budgetDescricao, setBudgetDescricao] = useState(''); // descricao_servico
    // const [budgetTipoSelecionado, setBudgetTipoSelecionado] = useState([]); // tipo_servico


    // console.log({
    //     categoria_servico: categoriaSelecionada,
    //     titulo_servico: budgetTitulo,
    //     descricao_servico: budgetDescricao,
    //     tipo_servico: budgetTipoSelecionado
    // })
    const [orcamentos, setOrcamentos] = useState([]);


    // DADOS DO FORMULÁRIO
    const [usuarioCadastrado, setUsuarioCadastrado] = useState(false); // VERIFICAR SE O USARIO ESTÁ CADASTRADO NO SITE ? ABRIR SELEÇÃO DE USUARIO : ABRIR MODAL DE CADASTRAMENTO DE USUARIO

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Área do Administrador" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Criar Orçamento</h1>
                            <div className='box-forms-singup'>
                                <div className='d-fx d-al-cen'>
                                    <div className={(props.responsivoSize && props.responsivoSize > 481) ? 'w65 p-g-25' : 'w100'}>
                                        <div className='wrapper__card__info__budget'>
                                            {
                                                (() => {
                                                    if (slideCard === 0) { // Informações Iniciais do Serviço
                                                        return (
                                                            <BudgetForm_categoria
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                budgetTitulo={budgetTitulo} setBudgetTitulo={setBudgetTitulo}
                                                                categoriaSelecionada={categoriaSelecionada} setCategoriaSelecionada={setCategoriaSelecionada}
                                                                budgetTipoSelecionado={budgetTipoSelecionado} setBudgetTipoSelecionado={setBudgetTipoSelecionado}
                                                                budgetTipoExecucao={budgetTipoExecucao} setBudgetTipoExecucao={setBudgetTipoExecucao}
                                                                budgetArea={budgetArea} setBudgetArea={setBudgetArea}
                                                                budgetDescricao={budgetDescricao} setBudgetDescricao={setBudgetDescricao}
                                                                budgetValidade={budgetValidade} setBudgetValidade={setBudgetValidade}
                                                            />
                                                        )
                                                    } else if (slideCard === 1) { // Dados do cliente
                                                        return (
                                                            <BudgetForm_cliente
                                                                responsivoSize={props.responsivoSize}
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                user={props.user}
                                                                budgetCPFCNPJCliente={budgetCPFCNPJCliente} setBudgetCPFCNPJCliente={setBudgetCPFCNPJCliente}
                                                                budgetRGCliente={budgetRGCliente} setBudgetRGCliente={setBudgetRGCliente}
                                                                budgetEnderecoCliente={budgetEnderecoCliente} setBudgetEnderecoCliente={setBudgetEnderecoCliente}
                                                                clienteSelecionado={clienteSelecionado} setClienteSelecionado={setClienteSelecionado}
                                                                openModal={props.openModal} setOpenModal={props.setOpenModal}
                                                                orcamentoCadastroEnderecoCliente={props.orcamentoCadastroEnderecoCliente} setOrcamentoCadastroEnderecoCliente={props.setOrcamentoCadastroEnderecoCliente}
                                                            />
                                                        )
                                                    } else if (slideCard === 2) { // Etapas do Serviço
                                                        return (
                                                            <BudgetForm_etapas
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                budgetEtapas={budgetEtapas} setBudgetEtapas={setBudgetEtapas}
                                                            />
                                                        )
                                                    } else if (slideCard === 3) { // Etapas de Pagamentos
                                                        return (
                                                            <BudgetForm_pagamentos
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                budgetPagamentos={budgetPagamentos} setBudgetPagamentos={setBudgetPagamentos}
                                                            />
                                                        )
                                                    } else if (slideCard === 4) { // Requisitos do Serviço
                                                        return (
                                                            <BudgetForm_requisitos
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                budgetRequisitos={budgetRequisitos} setBudgetRequisitos={setBudgetRequisitos}
                                                            />
                                                        )
                                                    } else if (slideCard === 5) { // Limites / Resalvas / Excessões da garantia
                                                        return (
                                                            <BudgetForm_garantia
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                budgetGarantia={budgetGarantia} setBudgetGarantia={setBudgetGarantia}
                                                            />
                                                        )
                                                    } else if (slideCard === 6) { // Endereço do Serviço
                                                        return (
                                                            <BudgetForm_enderecoServico
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                setOpenModal={props.setOpenModal}
                                                                budgetEnderecoServico={props.budgetEnderecoServico} setBudgetEnderecoServico={props.setBudgetEnderecoServico}
                                                                budgetEnderecoServicoStatus={budgetEnderecoServicoStatus} setBudgetEnderecoServicoStatus={setBudgetEnderecoServicoStatus}
                                                                budgetEnderecoCliente={budgetEnderecoCliente}
                                                            />
                                                        )
                                                    } else if (slideCard === 7) { // Contratações de Serviços Adicionais
                                                        return (
                                                            <BudgetForm_adicionais
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                budgetGerenciamento={budgetGerenciamento} setBudgetGerenciamento={setBudgetGerenciamento}
                                                                budgetAcompanhamento={budgetAcompanhamento} setBudgetAcompanhamento={setBudgetAcompanhamento}
                                                                budgetCompras={budgetCompras} setBudgetCompras={setBudgetCompras}
                                                                budgetObservacoes={budgetObservacoes} setBudgetObservacoes={setBudgetObservacoes}
                                                            />
                                                        )
                                                    } else if (slideCard === 8) { // Revisão do Orçamento
                                                        return (
                                                            <BudgetForm_revisao
                                                                // Propiedade do responsavel técnico logado que está efetuando o orcamento 
                                                                user={props.user}
                                                                // Slide do usuário
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                // Informações Iniciais do Serviço
                                                                budgetTitulo={budgetTitulo} setBudgetTitulo={setBudgetTitulo}
                                                                categoriaSelecionada={categoriaSelecionada} setCategoriaSelecionada={setCategoriaSelecionada}
                                                                budgetTipoSelecionado={budgetTipoSelecionado} setBudgetTipoSelecionado={setBudgetTipoSelecionado}
                                                                budgetTipoExecucao={budgetTipoExecucao} setBudgetTipoExecucao={setBudgetTipoExecucao}
                                                                budgetArea={budgetArea} setBudgetArea={setBudgetArea}
                                                                budgetDescricao={budgetDescricao} setBudgetDescricao={setBudgetDescricao}
                                                                budgetValidade={budgetValidade} setBudgetValidade={setBudgetValidade}
                                                                // Dados do clientebudgetCPFCNPJCliente={budgetCPFCNPJCliente} setBudgetCPFCNPJCliente={setBudgetCPFCNPJCliente}
                                                                budgetRGCliente={budgetRGCliente} setBudgetRGCliente={setBudgetRGCliente}
                                                                budgetEnderecoCliente={budgetEnderecoCliente} setBudgetEnderecoCliente={setBudgetEnderecoCliente}
                                                                clienteSelecionado={clienteSelecionado} setClienteSelecionado={setClienteSelecionado}
                                                                // Etapas do Serviço
                                                                budgetEtapas={budgetEtapas} setBudgetEtapas={setBudgetEtapas}
                                                                // Etapas de Pagamentos
                                                                budgetPagamentos={budgetPagamentos} setBudgetPagamentos={setBudgetPagamentos}
                                                                // Requisitos do Serviço
                                                                budgetRequisitos={budgetRequisitos} setBudgetRequisitos={setBudgetRequisitos}
                                                                // Limites / Resalvas / Excessões da garantia
                                                                budgetGarantia={budgetGarantia} setBudgetGarantia={setBudgetGarantia}
                                                                // Endereço do Serviço
                                                                budgetEnderecoServico={props.budgetEnderecoServico} setBudgetEnderecoServico={props.setBudgetEnderecoServico}
                                                                budgetEnderecoServicoStatus={budgetEnderecoServicoStatus} setBudgetEnderecoServicoStatus={setBudgetEnderecoServicoStatus}
                                                                // Contratações de Serviços Adicionais
                                                                budgetGerenciamento={budgetGerenciamento} setBudgetGerenciamento={setBudgetGerenciamento}
                                                                budgetAcompanhamento={budgetAcompanhamento} setBudgetAcompanhamento={setBudgetAcompanhamento}
                                                                budgetCompras={budgetCompras} setBudgetCompras={setBudgetCompras}
                                                                budgetObservacoes={budgetObservacoes} setBudgetObservacoes={setBudgetObservacoes}
                                                            />
                                                        )
                                                    } else if (slideCard === 9) { // Orçamento salvo com sucesso
                                                        return (
                                                            <BudgetForm_sucesso
                                                                // Propiedade do responsavel técnico logado que está efetuando o orcamento 
                                                                user={props.user}
                                                                // Slide do usuário
                                                                slideCard={slideCard} setSlideCard={setSlideCard}
                                                                // Informações Iniciais do Serviço
                                                                budgetTitulo={budgetTitulo} setBudgetTitulo={setBudgetTitulo}
                                                                categoriaSelecionada={categoriaSelecionada} setCategoriaSelecionada={setCategoriaSelecionada}
                                                                budgetTipoSelecionado={budgetTipoSelecionado} setBudgetTipoSelecionado={setBudgetTipoSelecionado}
                                                                budgetTipoExecucao={budgetTipoExecucao} setBudgetTipoExecucao={setBudgetTipoExecucao}
                                                                budgetArea={budgetArea} setBudgetArea={setBudgetArea}
                                                                budgetDescricao={budgetDescricao} setBudgetDescricao={setBudgetDescricao}
                                                                budgetValidade={budgetValidade} setBudgetValidade={setBudgetValidade}
                                                                // Dados do cliente
                                                                budgetCPFCNPJCliente={budgetCPFCNPJCliente} setBudgetCPFCNPJCliente={setBudgetCPFCNPJCliente}
                                                                budgetRGCliente={budgetRGCliente} setBudgetRGCliente={setBudgetRGCliente}
                                                                budgetEnderecoCliente={budgetEnderecoCliente} setBudgetEnderecoCliente={setBudgetEnderecoCliente}
                                                                clienteSelecionado={clienteSelecionado} setClienteSelecionado={setClienteSelecionado}
                                                                // Etapas do Serviço
                                                                budgetEtapas={budgetEtapas} setBudgetEtapas={setBudgetEtapas}
                                                                // Etapas de Pagamentos
                                                                budgetPagamentos={budgetPagamentos} setBudgetPagamentos={setBudgetPagamentos}
                                                                // Requisitos do Serviço
                                                                budgetRequisitos={budgetRequisitos} setBudgetRequisitos={setBudgetRequisitos}
                                                                // Limites / Resalvas / Excessões da garantia
                                                                budgetGarantia={budgetGarantia} setBudgetGarantia={setBudgetGarantia}
                                                                // Endereço do Serviço
                                                                budgetEnderecoServico={props.budgetEnderecoServico} setBudgetEnderecoServico={props.setBudgetEnderecoServico}
                                                                budgetEnderecoServicoStatus={budgetEnderecoServicoStatus} setBudgetEnderecoServicoStatus={setBudgetEnderecoServicoStatus}
                                                                // Contratações de Serviços Adicionais
                                                                budgetGerenciamento={budgetGerenciamento} setBudgetGerenciamento={setBudgetGerenciamento}
                                                                budgetAcompanhamento={budgetAcompanhamento} setBudgetAcompanhamento={setBudgetAcompanhamento}
                                                                budgetCompras={budgetCompras} setBudgetCompras={setBudgetCompras}
                                                                budgetObservacoes={budgetObservacoes} setBudgetObservacoes={setBudgetObservacoes}
                                                            />
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                                (slideCard > 0 && slideCard < 9)
                                                    ?
                                                    <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={() => setSlideCard(slideCard - 1)}>
                                                        <span className=' fz-12'>Anterior</span>
                                                    </button>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    {
                                        (props.responsivoSize && props.responsivoSize > 481)
                                            ?
                                            <div className='w35'>
                                                <img src={(slideCard && slideCard === 9) ? budgetOkay : contractOkay} />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );

}

export default PC_ADM_CriarOrcamento;