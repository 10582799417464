import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

// ICONES
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { db } from '../../../../../../../firebase';

function FiltroPavimentos(props) {
    const [expanded, setExpanded] = useState(false);

    function isFilter(filterName) {
        if (props.filtrosParametros.includes(filterName)) {
            return true;
        } else {
            return false;
        }
    }

    function filterProjects(el, parametro, valorParametro) {
        var todosParametros = (props.filtrosParametros);
        var todosElementos = (props.filtrosSelecionados);
        var elementoFiltro = {
            parametro: parametro,
            valorParametro: valorParametro
        }

        if (todosParametros && todosParametros.length > 0) {
            if (isFilter(parametro) == false) {
                todosParametros.push(parametro)
                todosElementos.push(elementoFiltro)
            } else {
                var contador = 1;
                for (var i = 0; i < todosElementos.length; i++) {
                    if (todosElementos[i].parametro == parametro) {
                        if (contador == 1) {
                            todosElementos[i].valorParametro = valorParametro
                        } else {
                            contador++;
                        }
                    }
                }
            }
        } else {
            todosParametros.push(parametro)
            todosElementos.push(elementoFiltro)
        }
        
        const produtos = props.projetos;

        if (todosParametros.length == 1) {
            var produtosFiltrados = produtos.filter(produto => produto[todosElementos[0].parametro] == todosElementos[0].valorParametro)
            props.setFiltrados(produtosFiltrados);
        } else if (todosParametros.length == 2) {
            var produtosFiltrados = produtos.filter(produto => produto[todosElementos[0].parametro] == todosElementos[0].valorParametro)
                .filter(produto => produto[todosElementos[1].parametro] == todosElementos[1].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (todosParametros.length == 3) {
            var produtosFiltrados = produtos.filter(produto => produto[todosElementos[0].parametro] == todosElementos[0].valorParametro)
                .filter(produto => produto[todosElementos[1].parametro] == todosElementos[1].valorParametro)
                .filter(produto => produto[todosElementos[2].parametro] == todosElementos[2].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (todosParametros.length == 4) {
            var produtosFiltrados = produtos.filter(produto => produto[todosElementos[0].parametro] == todosElementos[0].valorParametro)
                .filter(produto => produto[todosElementos[1].parametro] == todosElementos[1].valorParametro)
                .filter(produto => produto[todosElementos[2].parametro] == todosElementos[2].valorParametro)
                .filter(produto => produto[todosElementos[3].parametro] == todosElementos[3].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (todosParametros.length == 5) {
            var produtosFiltrados = produtos.filter(produto => produto[todosElementos[0].parametro] == todosElementos[0].valorParametro)
                .filter(produto => produto[todosElementos[1].parametro] == todosElementos[1].valorParametro)
                .filter(produto => produto[todosElementos[2].parametro] == todosElementos[2].valorParametro)
                .filter(produto => produto[todosElementos[3].parametro] == todosElementos[3].valorParametro)
                .filter(produto => produto[todosElementos[4].parametro] == todosElementos[4].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (todosParametros.length == 6) {
            var produtosFiltrados = produtos.filter(produto => produto[todosElementos[0].parametro] == todosElementos[0].valorParametro)
                .filter(produto => produto[todosElementos[1].parametro] == todosElementos[1].valorParametro)
                .filter(produto => produto[todosElementos[2].parametro] == todosElementos[2].valorParametro)
                .filter(produto => produto[todosElementos[3].parametro] == todosElementos[3].valorParametro)
                .filter(produto => produto[todosElementos[4].parametro] == todosElementos[4].valorParametro)
                .filter(produto => produto[todosElementos[5].parametro] == todosElementos[5].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (todosParametros.length == 7) {
            var produtosFiltrados = produtos.filter(produto => produto[todosElementos[0].parametro] == todosElementos[0].valorParametro)
                .filter(produto => produto[todosElementos[1].parametro] == todosElementos[1].valorParametro)
                .filter(produto => produto[todosElementos[2].parametro] == todosElementos[2].valorParametro)
                .filter(produto => produto[todosElementos[3].parametro] == todosElementos[3].valorParametro)
                .filter(produto => produto[todosElementos[4].parametro] == todosElementos[4].valorParametro)
                .filter(produto => produto[todosElementos[5].parametro] == todosElementos[5].valorParametro)
                .filter(produto => produto[todosElementos[6].parametro] == todosElementos[6].valorParametro);
            props.setFiltrados(produtosFiltrados);
        } else if (todosParametros.length == 8) {
            var produtosFiltrados = produtos.filter(produto => produto[todosElementos[0].parametro] == todosElementos[0].valorParametro)
                .filter(produto => produto[todosElementos[1].parametro] == todosElementos[1].valorParametro)
                .filter(produto => produto[todosElementos[2].parametro] == todosElementos[2].valorParametro)
                .filter(produto => produto[todosElementos[3].parametro] == todosElementos[3].valorParametro)
                .filter(produto => produto[todosElementos[4].parametro] == todosElementos[4].valorParametro)
                .filter(produto => produto[todosElementos[5].parametro] == todosElementos[5].valorParametro)
                .filter(produto => produto[todosElementos[6].parametro] == todosElementos[6].valorParametro)
                .filter(produto => produto[todosElementos[7].parametro] == todosElementos[7].valorParametro);
            props.setFiltrados(produtosFiltrados);
        }
    }

    return (
        <div id="filter_1" className="box-filter-single f-pav m-top-10 m-bot-10" dataShow='false'>
            <div className="title-filter-single p-g-15" onClick={() => setExpanded(!expanded)}>
                <h3 className="left">Pavimentos</h3>
                {/* <a className="right" href="javascript:void(0)" onclick="abrirFiltroSingle(this)" dataId="1"> */}
                <a className="right" href="javascript:void(0)">
                    {
                        (expanded)
                            ?
                            <BsChevronUp />
                            :
                            <BsChevronDown />
                    }
                </a>
            </div>

            <motion.div
                style={{ transformOrigin: "top", overflow: "hidden" }}
                animate={{ height: expanded ? "auto" : 0 }}
                className="box-opts-filter"
            >
                {
                    (expanded)
                        ?
                        <div className='wrapper__pavimento m-top-0 m-g-10 b-top-small'>
                            <label onClick={(e) => filterProjects(e, "pavimentoQnt", 1)} className="w100 left m-lef-0 m-g-5">
                                <input type="radio" name="pavimento" value="Térreo" />
                                <span>Térreo</span>
                            </label>
                            <label onClick={(e) => filterProjects(e, "pavimentoQnt", 2)} className="w100 left m-lef-0 m-g-5">
                                <input type="radio" name="pavimento" value="Sobrado" />
                                <span>Sobrado</span>
                            </label>
                            <div className="clear"></div>
                        </div>
                        : null
                }
            </motion.div>
        </div>
    )
}

export default FiltroPavimentos;