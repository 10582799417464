import { Link } from 'react-router-dom';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { useMemo, useState } from 'react';
import { db, auth } from '../../../../firebase';
import toast from 'react-hot-toast';

function Forgot(props) {

    // HOOKS - EMAIL
    const [email, setEmail] = useState();
    const [emailMessage, setEmailMessage] = useState([]);
    const [emailValid, setEmailValid] = useState(false);

    useMemo(() => {
        if (email && email.length > 0) {
            function IsEmail(email) {
                var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                if (email == '' || !er.test(email)) { return false; }
                else { return true; }
            }
            if (!IsEmail(email)) {
                setEmailValid(false);
                setEmailMessage([{
                    'status': 'error',
                    'message': 'O e-mail informado não é válido'
                }])
            } else {
                setEmailValid(true);
                setEmailMessage([]);
            }
        }
    }, [email])

    // VALIDAR INFORMAÇÕES DO USUÁRIO E RECUPERAR SENHA
    async function forgotPassword(e) {
        e.preventDefault();

        if (!email.length) { // O EMAIL ESTÁ VÁZIO
            toast.error("O e-mail não pode ser vázio");
        } else if (!emailValid) { // O FORMATO DO EMAIL É INVÁLIDO
            toast.error("O formato do e-mail informado não é válido");
        } else { // TUDO CERTO VAMOS VERIFICAR SE O EMAIL ESTÁ  CADASTRADO NO BD
            try {
                const ref = db.collection("cadastros").where("email", "==", email);
                const snapshot = await ref.get();
                if (snapshot.empty) { // NÃO FOI ENCONTRADO NENHUM USUARIO COM O EMAIL INFORMADO
                    toast.error("O e-mail informado não está registrado em nosso site");
                    return;
                } else { // ENCONTRAMOS UM USUÁRIO COM O EMAIL INFORMADO -> VAMOR RECUPERAR A SENHA DESSE EMAIL
                    await auth.sendPasswordResetEmail(email)
                        .then(() => {
                            toast.success('E-mail de recuperação enviado!\nVerifique sua caixa de e-mail');
                        })
                        .catch((error) => {
                            var errorCode = error.code;
                            var errortype = error.code.split('/');
                            var errorMessage = error.message;
                            // console.log(errorCode);
                            // console.log(errorMessage);
                            if (errortype[1] == "user-not-found") {
                                toast.error("O usuário não foi encontrado ou não existe");
                            } else {
                                toast.error("Ocorreu um erro ao redefinir sua senha");
                            }
                        });
                }

            } catch (error) {
                toast.error("Ocorreu um erro ao buscar o e-mail de recuperação");
                // console.log(error);
            }
        }
    }

    return (
        <>
            <div className='center'>
                <div className="grid second-nav">
                    <div className="column-xs-12">
                        <nav>
                            {
                                (props.responsivoSize && props.responsivoSize >= 600)
                                    ?
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/login">Entrar</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Esqueceu a senha</li>
                                    </ol>
                                    :
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item">
                                            <Link to="/login" className='d-fx d-al-cen'>
                                                <MdOutlineArrowBackIos className='m-rig-5' />
                                                Entrar
                                            </Link>
                                        </li>
                                    </ol>
                            }
                        </nav>
                    </div>
                    <form id='form_forgot_password' className='w55' style={{ margin: '0 auto 20px' }} onSubmit={(e) => forgotPassword(e)}>

                        <h1 className="fz-25 info-primary">Esqueci a senha</h1>

                        <p className="m-top-10 tx-grey-dark fz-16">Por favor, insira seu e-mail abaixo e nós enviaremos um link para você redefinir sua senha.</p>

                        <div className='box-input-single m-top-10'>
                            <label>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id='email-login-page' className={(email && !emailValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='email' name="email" placeholder='seuemail@email.com' value={email} onChange={(ev) => setEmail(ev.target.value)} />
                            {
                                (emailMessage)
                                    ?
                                    emailMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                            <i className='fz-12' style={{ userSelect: 'none' }}>Ex: nome@email.com</i>
                        </div>{/*box-input-single */}

                        <button class="button button-full button-primary tx-lef m-top-15">
                            Entrar
                            <div class="right">
                                {/* <FontAwesomeIcon className="glyph" icon={faAngleDoubleRight} /> */}
                            </div>
                        </button>

                        <div class="d-fx d-fd-col d-fw-wp m-top-10">
                            <Link to="/login" class="fz-14 tx-grey-dark m-bot-10" style={{ textDecoration: 'underline' }}>
                                Já possuo um cadastro
                            </Link>
                            <Link to="/login/sing-up" class="fz-14 tx-grey-dark" style={{ textDecoration: 'underline' }}>
                                Quero me cadastrar
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Forgot;