import { useEffect, useState, useMemo } from 'react';
// IMPORTAÇÃO DE REACT ROUTER
import { Link } from 'react-router-dom';
// TOATS - ALERTAS
import toast from 'react-hot-toast';

// FIREBASE
import { db } from '../../../../../firebase';
import firebase from 'firebase';
import InputMask from "react-input-mask"; // MASCARA DE INPUT

// IMPORTAÇ~ODE ICONES
import { HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';

// IMPORTAÇÃO DE IMAGENS
import contactUs from '../../../../../images/icones/svg/contato.svg';

function FormAtendimento(props) {
    const [assunto, setAssunto] = useState(null);
    const [observacao, setObservacao] = useState(null);

    // HOOKS - NOME
    const [name, setName] = useState();
    const [nameMessage, setNameMessage] = useState([]);
    const [nameValid, setNameValid] = useState(false);

    // HOOKS - EMAIL
    const [email, setEmail] = useState();
    const [emailCadastrado, setEmailCadastrado] = useState(false);
    const [emailMessage, setEmailMessage] = useState([]);
    const [emailValid, setEmailValid] = useState(false);

    // HOOKS - TELEFONE
    const [phone, setPhone] = useState();
    const [phoneMessage, setPhoneMessage] = useState([]);
    const [phoneValid, setPhoneValid] = useState(false);
    const [phoneDDDValid, setPhoneDDDValid] = useState(false);

    useMemo(() => {
        if (name) {
            // VERIFICAÇÃO DE LETRAS EM SENHA
            var padrao = /[^a-zà-ú\s-]/gi;

            var valida_nome = name.match(padrao);

            if (valida_nome) {
                setNameValid(false);
                setNameMessage([{
                    'status': 'error',
                    'message': 'O nome deve conter apenas letras'
                }])
            } else {
                //O NOME É VÁLIDO CONTEM APENAS LETRAS -> VERIFICAR SE O NOME É COMPLETO
                var arrName = name.split(' ');
                if (arrName.length <= 1) {
                    // O USUARIO NÃO PASSOU O NOME COMPLETO
                    setNameValid(false);
                    setNameMessage([{
                        'status': 'error',
                        'message': 'O nome deve ser completo'
                    }])
                } else {
                    // O USUARIO PASSOU O NOME COMPLETO
                    setNameValid(true);
                    setNameMessage([])
                }
            }
        }
    }, [name])

    useMemo(() => {
        if (email && email.length > 0) {
            function IsEmail(email) {
                var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                if (email == '' || !er.test(email)) { return false; }
                else { return true; }
            }
            if (!IsEmail(email)) {
                setEmailValid(false);
                setEmailMessage([{
                    'status': 'error',
                    'message': 'O e-mail informado não é válido'
                }])
            } else {
                setEmailValid(true);
                setEmailMessage([])
                // VALIDANDO CADASTRO EXISTENTE
                var usuariosCadastrados = props.cadastros.filter(produto => produto.email == email);
                if (usuariosCadastrados.length > 0) {
                    // EXISTE UM email JÁ CADASTRADO NO BANCO DE DADOS -> MOSTRAR MENSAGEM DE ERRO
                    setEmailCadastrado(true);
                } else {
                    // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
                    setEmailCadastrado(false);
                }

            }
        }

    }, [email])

    useMemo(() => {
        if (phone) {
            var phoneDDD = phone.replace(/([()])/g, '').replace("_", '').split(" ");

            if (phoneDDD[0].length == 2) { // VERIFICAR EXISTENCIA DO DDD
                function getStateFromDDD(ddd) {

                    let ddds = {
                        "AC": ["68"],
                        "AL": ["82"],
                        "AM": ["92", "97"],
                        "AP": ["96"],
                        "BA": ["71", "73", "74", "75", "77"],
                        "CE": ["85", "88"],
                        "DF": ["61"],
                        "ES": ["27", "28"],
                        "GO": ["62", "64"],
                        "MA": ["98", "99"],
                        "MG": ["31", "32", "33", "34", "35", "37", "38"],
                        "MS": ["67"],
                        "MT": ["65", "66"],
                        "PA": ["91", "93", "94"],
                        "PB": ["83"],
                        "PE": ["81", "87"],
                        "PI": ["86", "89"],
                        "PR": ["41", "42", "43", "44", "45", "46"],
                        "RJ": ["21", "22", "24"],
                        "RN": ["84"],
                        "RO": ["69"],
                        "RR": ["95"],
                        "RS": ["51", "53", "54", "55"],
                        "SC": ["47", "48", "49"],
                        "SE": ["79"],
                        "SP": ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        "TO": ["63"]
                    }

                    for (let index in ddds) {
                        if (ddds[index].includes(ddd)) {
                            return true;
                        }
                    }
                }

                var retornoDDD = getStateFromDDD(phoneDDD[0]);
                console.log(retornoDDD);

                if (retornoDDD) {
                    setPhoneDDDValid(true)
                    setPhoneValid(true);
                } else {
                    setPhoneDDDValid(false)
                    setPhoneValid(false);
                }
            }

            var padrao = /(\d+)| /g;

            if (!phone.match(padrao)) {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([{
                    'status': 'error',
                    'message': 'O telefone deve conter apenas números'
                }]);
            } else {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([]);
            }
        }
    }, [phone])

    function validatePhone() {
        let newPhoneClear = phone.replace(/[^\d]+/g, '');
        if (newPhoneClear.length < 11) {
            setPhoneValid(false);
            setPhoneMessage([{
                'status': 'error',
                'message': 'O telefone deve conter o DDD'
            }]);
        } else {
            setPhoneValid(true);
            setPhoneMessage([]);
        }
    }

    function cadastrarAtendimento(el) {
        el.preventDefault();

        if (!assunto) {
            toast.error("O assunto do contato deve ser informado");
        } else if (!name || name.length <= 0) {
            toast.error("Você precisa informar seu nome completo");
        } else if (!nameValid) {
            toast.error("O nome informado não é válido");
        } else if (!email || email.length <= 0) {
            toast.error("Você precisa informar seu e-mail completo");
        } else if (!emailValid) {
            toast.error("O e-mail informado não é válido");
        } else if (!phone || phone.length <= 0) {
            toast.error("Você precisa informar seu telefone completo");
        } else if (!phoneDDDValid) {
            toast.error("O DDD do telefone informado não é válido");
        } else if (!phoneValid) {
            toast.error("O telefone informado não é válido");
        } else {
            db.collection('SAC').add({
                categoria: "atendimento",
                perfil: 'publico', // PUBLICO = Suporte de atendimento a quem compra no site || PRIVADO = Suporte para quem é profissional ou parceiro engeart
                assunto: assunto,
                nome: name,
                email: email,
                telefone: phone,
                observacao: observacao,
                data_envio: firebase.firestore.FieldValue.serverTimestamp(),
                usuario_cadastrado: emailCadastrado,
                status: 'pendente'
            }).then(() => {
                toast.success(name + " seu contato foi registrado com sucesso!");
                setAssunto(null);
                setName(null);
                setEmail(null);
                setPhone(null);
                setObservacao(null);
            }).catch((error) => {
                toast.error("Ocorreu um erro ao registrar seu contato, tente novamente");
                console.log(error);
            })
        }
    }

    if (props.formularioImagem) {
        return (
            <div className="grid product d-fx m-bot-25 d-al-cen content__formulario__imagem" style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column-reverse' } : null}>
                <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__formulario__imagem column-xs-12 column-md-5 w40 m-0 left" : "wrapper__formulario__imagem column-xs-12 column-md-5 w100 m-0"}>
                    <div className="box-header-proj-single b-bot">
                        <h1>Diga como podemos ajudar</h1>
                        <p className="fz-12 m-top-10 m-bot-10">* Fique a vontade para solicita ajuda, tirar dúvidas, ou solicitar um suporte totalmente personalizado e privativo.</p>
                        <p className="fz-11 m-top-10 m-bot-10 ">Para reembolso, reclamações e etc, <Link to="/ouvidoria" className='fz-11' style={{ color: 'var(--dark-grey-blue)' }} href="javascript:void(0)">clique aqui</Link>.</p>
                    </div>{/*box-header-proj-single*/}

                    {/* BOX FORMULARIO CONTATO */}

                    <form id='dados-form-atendimento' className='singup-person' onSubmit={(el) => cadastrarAtendimento(el)}>
                        <div className='box-input-single'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Assunto</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <input id='dados-contato-assunto' className='input-form w100 fz-16 m-top-5' type='text' name="assunto" placeholder='Seu assunto' value={assunto} onChange={(e) => setAssunto(e.target.value)} />
                            {
                                (!assunto)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>Por favor, digite o assunto em que podemos ajudar</span>
                                    </div>
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Nome Completo</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id="nome-cadastro-singup" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                            {
                                (nameMessage)
                                    ?
                                    nameMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>E-mail</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id='email-cadastro-singup' className={(email && !emailValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='email' name="email" placeholder='seuemail@email.com' value={email} onChange={(ev) => setEmail(ev.target.value)} />
                            {
                                (emailMessage)
                                    ?
                                    emailMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Telefone</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <InputMask id='telefone-cadastro-singup' mask="(99) 99999-9999" placeholder='Insira seu telefone' className={(phone && phone.match('[0-9]') && !phoneValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} value={phone} onChange={(ev) => setPhone(ev.target.value)} onBlur={() => validatePhone()} />
                            <div>
                                {
                                    (!phoneDDDValid)
                                        ?
                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                            <span className='icon-invalid'><TiInfo /></span>
                                            <span className='text-invalid m-lef-5'>O DDD informado não é válido</span>
                                        </div>
                                        : null
                                }
                            </div>
                            <div>
                                {
                                    (phoneMessage)
                                        ?
                                        phoneMessage.map((infoStatus) => {
                                            if (infoStatus.status == 'error') {
                                                return (
                                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-invalid'><TiInfo /></span>
                                                        <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            } else if (infoStatus.status == 'success') {
                                                return (
                                                    <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-valid'><TiInfo /></span>
                                                        <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            }
                                        })
                                        : null
                                }
                            </div>
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Observação</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            {/* <input id='telefone-cadastro' className='input-form w100 fz-16 m-top-5' type='text' name="nome" placeholder='(99) 99999-9999' /> */}
                            <textarea className='input-form w100 fz-14 m-top-5' style={{ maxWidth: '100%', resize: 'vertical' }} value={observacao} onChange={(ev) => setObservacao(ev.target.value)}>
                                {
                                    observacao
                                }
                            </textarea>
                        </div>{/*box-input-single */}


                        <button className="button button-full button-primary margin-top align-left">
                            Concordo e quero prosseguir
                        </button>
                        <div className='aviso-termos m-top-10 helper'>
                            Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                        </div>
                    </form>
                </div>
                <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__imagem__formulario w50 d-fx d-al-cen d-ju-cen m-top-0 right m-bot-0" : "wrapper__imagem__formulario w100 d-fx d-al-cen d-ju-cen m-top-0 m-bot-0"}>
                    <img src={contactUs} style={(props.responsivoSize && props.responsivoSize < 350 && props.responsivoSize > 768) ? { width: '100%' } : { maxWidth: '80%' }} />
                </div>
                <div className="clear"></div>
            </div>
        )
    } else {
        return (
            <div className="grid product d-fx m-bot-25 d-al-cen content__formulario__imagem" style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column-reverse' } : null}>
                <div className="wrapper__formulario__imagem column-xs-12 column-md-5 w100 m-0">
                    <div className="box-header-proj-single b-bot">
                        <h1>Diga como podemos ajudar</h1>
                        <p className="fz-12 m-top-10 m-bot-10">* Fique a vontade para solicita ajuda, tirar dúvidas, ou solicitar um suporte totalmente personalizado e privativo.</p>
                        <p className="fz-11 m-top-10 m-bot-10 ">Para reembolso, reclamações e etc, <Link to="/ouvidoria" className='fz-11' style={{ color: 'var(--dark-grey-blue)' }} href="javascript:void(0)">clique aqui</Link>.</p>
                    </div>{/*box-header-proj-single*/}

                    {/* BOX FORMULARIO CONTATO */}

                    <form id='dados-form-atendimento' className='singup-person' onSubmit={(el) => cadastrarAtendimento(el)}>
                        <div className='box-input-single'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Assunto</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <input id='dados-contato-assunto' className='input-form w100 fz-16 m-top-5' type='text' name="assunto" placeholder='Seu assunto' value={assunto} onChange={(e) => setAssunto(e.target.value)} />
                            {
                                (!assunto)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>Por favor, digite o assunto em que podemos ajudar</span>
                                    </div>
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Nome Completo</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id="nome-cadastro-singup" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                            {
                                (nameMessage)
                                    ?
                                    nameMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>E-mail</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id='email-cadastro-singup' className={(email && !emailValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='email' name="email" placeholder='seuemail@email.com' value={email} onChange={(ev) => setEmail(ev.target.value)} />
                            {
                                (emailMessage)
                                    ?
                                    emailMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Telefone</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <InputMask id='telefone-cadastro-singup' mask="(99) 99999-9999" placeholder='Insira seu telefone' className={(phone && phone.match('[0-9]') && !phoneValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} value={phone} onChange={(ev) => setPhone(ev.target.value)} onBlur={() => validatePhone()} />
                            <div>
                                {
                                    (!phoneDDDValid)
                                        ?
                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                            <span className='icon-invalid'><TiInfo /></span>
                                            <span className='text-invalid m-lef-5'>O DDD informado não é válido</span>
                                        </div>
                                        : null
                                }
                            </div>
                            <div>
                                {
                                    (phoneMessage)
                                        ?
                                        phoneMessage.map((infoStatus) => {
                                            if (infoStatus.status == 'error') {
                                                return (
                                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-invalid'><TiInfo /></span>
                                                        <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            } else if (infoStatus.status == 'success') {
                                                return (
                                                    <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-valid'><TiInfo /></span>
                                                        <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            }
                                        })
                                        : null
                                }
                            </div>
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Observação</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            {/* <input id='telefone-cadastro' className='input-form w100 fz-16 m-top-5' type='text' name="nome" placeholder='(99) 99999-9999' /> */}
                            <textarea className='input-form w100 fz-14 m-top-5' style={{ maxWidth: '100%', resize: 'vertical' }} value={observacao} onChange={(ev) => setObservacao(ev.target.value)}>
                                {
                                    observacao
                                }
                            </textarea>
                        </div>{/*box-input-single */}


                        <button className="button button-full button-primary margin-top align-left">
                            Concordo e quero prosseguir
                        </button>
                        <div className='aviso-termos m-top-10 helper'>
                            Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default FormAtendimento