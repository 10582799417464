
import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { BiCurrentLocation } from 'react-icons/bi';
import { MdOutlineEditLocationAlt } from 'react-icons/md';
import { copiarLinkCompartilhamento } from '../funcoes/FuncoesCompartilhamento';
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import { useEffect, useState } from 'react';
import axios from 'axios';
import { isEmptyObject } from 'jquery';
import { fecharModalLocalizacao } from '../funcoes/FuncoesLocalizacao';
import { fecharModal } from '../funcoes/FuncoesGerais';


function ModalLocalizacao(props) {

    const [localizacaoCEP, setLocalizacaoCEP] = useState("");
    const [location, setLocation] = useState([]);
    const [locationIP, setLocationIP] = useState([]);
    const [locationLat, setLocationLat] = useState(null);
    const [locationLong, setLocationLong] = useState(null);
    const [locationCity, setLocationCity] = useState(null);
    const [locationIPStatus, setLocationIPStatus] = useState(false);
    const [locationStatus, setLocationStatus] = useState(false);
    const [cidadesEstados, setCidadesEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [cityValid, setCityValid] = useState(false);

    useEffect(() => {
        var novaLocalizacao = parseInt(localizacaoCEP.replace("-", "").replace(".", ""));
        var tamanhoCEP = String(novaLocalizacao);

        if (tamanhoCEP.length == 8) {
            axios.get(`https://viacep.com.br/ws/${novaLocalizacao}/json/`).then(data => {
                setLocation(data.data);
            })
        } else {
            setLocation([]);
        }
    }, [localizacaoCEP])

    function definirLocalizacaoCEP(el, cidade, estado) {
        el.preventDefault();
        // console.log(location);

        // props.setLocation({
        //     IPv4: props.location.IPv4,
        //     city: cidade,
        //     country_code: props.location.country_code,
        //     country_name: props.location.country_name,
        //     latitude: props.location.latitude,
        //     longitude: props.location.longitude,
        //     postal: localizacaoCEP,
        //     state: estado,
        //     state_name: props.location.state
        // })
        
        props.setLocation({
            'IPv4': '',
            'city': location.localidade,
            'state': location.uf,
            'latitude': '',
            'longitude': '',
            'localidade': ''
        });
        
        // console.log(props.location);
        fecharModalLocalizacao();
        // fecharModal();
    }

    function fechaModalLocalizacao(el) {
        el.preventDefault();

        fecharModal();
        setTimeout(() => { props.setOpenModal(null) }, 1000);
    }



    async function getIpClient() {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            setLocationIP(response.data.ip);
        } catch (error) {
            console.error(error);
        }
    }


    // Step 1: Get user coordinates
    async function getCoordintes() {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };

        function success(pos) {
            var crd = pos.coords;
            var lat = crd.latitude.toString();
            var lng = crd.longitude.toString();
            var coordinates = [lat, lng];
            getCity(coordinates)
            return;

        }

        function error(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        }

        await navigator.geolocation.getCurrentPosition(success, error, options);
    }

    // Step 2: Get city name
    async function getCity(coordinates) {
        var xhr = new XMLHttpRequest();
        var lat = coordinates[0];
        setLocationLat(lat)
        var lng = coordinates[1];
        setLocationLong(lng)

        // Paste your LocationIQ token below.
        await xhr.open('GET', "https://us1.locationiq.com/v1/reverse.php?key=pk.bbaf03714dedd10a5eeed44c95a75acc&lat=" + lat + "&lon=" + lng + "&format=json", true);
        xhr.send();
        xhr.onreadystatechange = processRequest;
        xhr.addEventListener("readystatechange", processRequest, false);

        function processRequest() {
            if (xhr.readyState == 4 && xhr.status == 200) {
                var response = JSON.parse(xhr.responseText);
                var city = (response.address.city) ? response.address.city : response.address.city_district;
                // console.log(city);
                setLocationCity(city);
                return;
            }
        }
    }

    async function getCityState() {
        if (locationCity && locationCity.length > 0) {
            let tempCity = locationCity;
            var clearName = tempCity.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            clearName = clearName.replace(/ /g, "-").toLocaleLowerCase();

            const cityState = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${clearName}`);
            setCidadesEstados(cityState.data);
        } else {
            console.log(locationCity);
        }
    }

    function pegaNovaLocalizacao(el) {
        el.preventDefault();
        getIpClient();

        getCoordintes();

        getCityState();
    }

    useEffect(() => {
        // if (cidadesEstados) {
        //     console.log(cidadesEstados)
        // }

        // console.warn(props.location)
    }, [cidadesEstados])

    useEffect(() => {
        if (locationIP) {
            if (locationIP.length > 0) {
                props.setLocation({
                    'IPv4': locationIP,
                    'city': locationCity,
                    'state': (cidadesEstados.microrregiao.mesorregiao.UF.sigla) ? cidadesEstados.microrregiao.mesorregiao.UF.sigla : null,
                    'latitude': locationLat,
                    'longitude': locationLong,
                    'localidade': cidadesEstados
                });
            }
        }
    }, [cidadesEstados])
    return (
        <div class="popup" style={{ zIndex: 9999, position: 'fixed', maxWidth: '566px' }}>
            <div className='header-popup'>
                <span>Onde você está?</span>
                <a class="close-popup" onClick={(el) => fechaModalLocalizacao(el)}><AiOutlineClose /></a>
            </div>
            <div class="content">
                <p>Dessa forma você terá acesso aos produtos e ofertas da sua região.</p>
                <button
                    type="button"
                    class="css-1fw07hd-button-button--stretch m-top-10 m-bot-30"
                    // onClick={(el) => pegaNovaLocalizacao(el)}
                    onClick={(el) => {
                        axios.get('https://ipapi.co/json/').then(resp => {
                            // console.log(resp.data)
                            props.setLocation({
                                'IPv4': resp.data.ip,
                                'city': resp.data.city,
                                'state': resp.data.region_code,
                                'latitude': resp.data.latitude,
                                'longitude': resp.data.longitude,
                                'localidade': resp.data.country_code
                            });
                            fechaModalLocalizacao(el)
                        }).catch((err) => {console.log("Erro:",err)});
                    }}
                >
                    <svg role="presentation" class="css-ev9b97-button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                        <defs>
                            <path id="a" d="M0 .001h18.41v18.41H0z"></path>
                        </defs>
                        <g fill-rule="evenodd">
                            <path d="M6.34 19.26l3.034 4.739 3.15-4.824c-1.021.33-2.111.507-3.235.507-1.022 0-2.02-.144-2.948-.422M4.415 11.234h9.774l-4.84-4.883z"></path>
                            <g transform="translate(0 -.001)">
                                <path d="M9.205 16.41C5.232 16.41 2 13.178 2 9.205c0-3.972 3.232-7.204 7.205-7.204s7.205 3.232 7.205 7.204c0 3.973-3.232 7.205-7.205 7.205m0-16.409C4.13.001 0 4.131 0 9.205c0 5.076 4.13 9.205 9.205 9.205 5.076 0 9.205-4.129 9.205-9.205 0-5.075-4.129-9.204-9.205-9.204" mask="url(#b)"></path>
                            </g>
                        </g>
                    </svg>
                    Utilizar localização automática
                </button>
                <p>Ou buscar pelo CEP</p>
                <div class="content-input w100 d-fx d-ju-sp-bt box-input-single">
                    <InputMask className='input-form w100 fz-16 m-top-5' type='text' name="cep" mask="99999-999" placeholder='Digite seu CEP' value={localizacaoCEP} onChange={(ev) => setLocalizacaoCEP(ev.target.value)} />
                </div>
                {
                    (!isEmptyObject(location))
                        ?
                        <div>
                            <p className='tx-cen m-top-10 d-fx d-al-cen d-ju-cen w100 tx-blue-dark'>
                                <BiCurrentLocation />
                                <strong className='fz-16 tx-lig m-lef-5'>{location.localidade}</strong>
                                <strong className='fz-16 tx-lig m-lef-5'>-</strong>
                                <strong className='fz-16 tx-lig m-lef-5'>{location.uf}</strong>
                            </p>
                            <button class="button button-full button-primary margin-top align-left" onClick={(el) => definirLocalizacaoCEP(el, location.localidade, location.uf)}>Confirmar localizacao e prosseguir</button>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default ModalLocalizacao;