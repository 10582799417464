import { useState } from 'react';
import { motion } from 'framer-motion';

// ICONES
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { db } from '../../../../../../../firebase';

function FiltroOrdenacao(props) {
    
    const [expanded, setExpanded] = useState(false);

    function orderProjects(orderBy) {

        var byPrice = props.data.slice(0);

        if (orderBy == 'asc') {
            byPrice.sort(function (a, b) {
                return a.precoFinal - b.precoFinal;
            });
        } else if (orderBy == 'desc') {
            byPrice.sort(function (a, b) {
                return b.precoFinal - a.precoFinal;
            });
        }
        props.setData(byPrice);
    }

    return(
        <div className="box-filter-single f-tipo m-top-10 m-bot-10" dataShow='false'>
            <div className="title-filter-single p-g-15" onClick={() => setExpanded(!expanded)}>
                <h3 className="left">Ordenar por</h3>
                <a className="right" href="javascript:void(0)">
                    {
                        (expanded)
                        ?
                        <BsChevronUp />
                        :
                        <BsChevronDown />
                    }
                </a>
            </div>
            
            <motion.div
                style={{ transformOrigin: "top", overflow: "hidden" }}
                animate={{ height: expanded ? "auto" : 0 }}
                className="box-opts-filter"
            >
                <div className="wrapper__ordem-preco m-top-0 m-g-10 b-top-small">
                    <label className="w100 left m-lef-0 m-g-5" onClick={()=>orderProjects('desc')}>
                        <input type="radio" name="ordem_preco" value="Maior" />
                        <span>Maior Preço</span>
                    </label>
                    
                    <label className="w100 left m-lef-0 m-g-5" onClick={()=>orderProjects('asc')}>
                        <input type="radio" name="ordem_preco" value="Menor" />
                        <span>Menor Preço</span>
                    </label>
                    <div className="clear"></div>
                </div>
            </motion.div>
        </div>
    )
}

export default FiltroOrdenacao;