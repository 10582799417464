// TODO: MONTAR FORMULÁRIO PARA CRIAÇÃO DE CONTA PARA O CLIENTE

import '../../../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../../../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiNotebook } from 'react-icons/gi';
import { AiOutlineSearch, AiFillEdit, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdHomeFilled, MdHomeWork } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaMoneyBill } from 'react-icons/fa';

import { BsCreditCard2Front, BsTrash } from 'react-icons/bs';
import { ImQrcode } from 'react-icons/im';
import { GiSave } from 'react-icons/gi';
import { FcMoneyTransfer } from 'react-icons/fc';
import { RiHomeGearLine } from 'react-icons/ri';
import { FaBarcode, FaApplePay, FaGooglePay, FaStar } from 'react-icons/fa';


import contractOkay from '../../../../../../../../../../images/icones/svg/Construction costs-pana.svg';
import searchFolder from '../../../../../../../../../../images/icones/svg/search-folder.svg';
import searchDocument from '../../../../../../../../../../images/icones/svg/search-document.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';
import FormSingUp from '../../../../../../../Assets/components/FormSingUp';

function BudgetForm_cliente(props) {
    const [dadosCliente, setDadosCliente] = useState([]);

    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');


    const [orcamentos, setOrcamentos] = useState([]);

    // DADOS DO FORMULÁRIO
    const [usuarioCadastrado, setUsuarioCadastrado] = useState(false); // VERIFICAR SE O USARIO ESTÁ CADASTRADO NO SITE ? ABRIR SELEÇÃO DE USUARIO : ABRIR MODAL DE CADASTRAMENTO DE USUARIO

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user) && props.user.representante) {
            db.collection("orcamentos")
                .where("numero_representante_contratado", "==", props.user.token_representante)
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })

            // console.log(orcamentos)
        }
    }, [])

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }

    // FUNÇÕES DE SELECT PERSONALIZADO - CATEGORIAS
    const [novaCategoriaStatus, setNovaCategoriaStatus] = useState(false);
    const [novaCategoria, setNovaCategoria] = useState([]);

    const [toggleOpenOptions, setToggleOpenOptions] = useState(false);


    // FUNÇÕES DE SELECT PERSONALIZADO - TIPOS
    const [novoTipoStatus, setNovoTipoStatus] = useState(false);
    const [novoTipo, setNovoTipo] = useState([]);

    const [toggleOpenOptionsTipo, setToggleOpenOptionsTipo] = useState(false);

    function typingSearchCategoria(e) {
        filterListCategoria(e.target.value);
    };

    const filterListCategoria = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.categoria").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function typingSearchTipo(e) {
        filterListTipo(e.target.value);
    };

    const filterListTipo = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.tipo").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function convertStringToSlug(string) {
        string = string.replace(/^\s+|\s+$/g, '')
        string = string.toLowerCase()

        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'

        for (let i = 0, l = from.length; i < l; i++) {
            string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        string = string.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')

        return string
    }

    function definirNovaCategoria(val) {
        setNovaCategoria({
            categoria: val,
            slug_categoria: convertStringToSlug(val),
            campo: "categoria-orcamento"
        });
    }

    function definirNovoTipo(val) {
        setNovoTipo({
            tipo: val,
            slug_tipo: convertStringToSlug(val),
            campo: "tipo-orcamento"
        });
    }

    // FUNÇÕES INICIAIS - RECUPERAÇÃO DE CATEGORIAS
    const [categorias, setCategorias] = useState([]);
    const [tipos, setTipos] = useState([]);

    useMemo(async () => {
        db.collection("arquivos")
            .doc("documentos")
            .collection("complementos")


        const ref = db.collection('arquivos').doc("documentos").collection("complementos");
        const snapshot = await ref.get();
        if (snapshot.empty) {
            toast.error('Não foi possivel recuperar as categorias de orçamentos.');
            return;
        }

        var contentCategorias = ([]);
        var contentTipos = ([]);

        snapshot.forEach(doc => {
            if (doc.data().campo === 'categoria-orcamento') {
                contentCategorias.unshift(doc.data());
            } else if (doc.data().campo === 'tipo-orcamento') {
                contentTipos.unshift(doc.data());
            }
        });

        setCategorias(contentCategorias);
        setTipos(contentTipos);
    }, []);



    // AVANÇAR PROGRESSO DE CRIAÇÃO DE ORÇAMENTO
    const [saveCategoriaStatus, setSaveCategoriaStatus] = useState(false);

    const [saveTipoStatus, setSaveTipoStatus] = useState(false);

    function avancarOrcamento() {
        if (!props.clienteSelecionado || isEmptyObject(props.clienteSelecionado)) {
            toast.error("Houve um erro ao salvar o cliente selecionado, tente selecionar novamente!");
        } else {
            props.setBudgetCPFCNPJCliente(props.clienteSelecionado.cpf);
            props.setBudgetRGCliente(props.clienteSelecionado.rg);
            props.setBudgetEnderecoCliente((props.clienteSelecionado.enderecos && props.clienteSelecionado.enderecos.length) ? props.clienteSelecionado.enderecos[0] : props.orcamentoCadastroEnderecoCliente[0]);
            toast.success("Cliente selecionado com sucesso!");
            props.setSlideCard(props.slideCard + 1);
            console.log(props.budgetEnderecoCliente);
        }
    }


    // FORMATAR CPF / CPNJ CO CLIENTE
    function formatarCPFCNPJ(val) {
        setDadosCliente([]);

        var cpf_cnpj = val.target.value.toString();
        var cpf_cnpj_clear = val.target.value.replace(/[^0-9]/g, "");
        if (cpf_cnpj_clear.length <= 14) {
            if (cpf_cnpj_clear.length && cpf_cnpj_clear.length > 11) {
                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
                props.setBudgetCPFCNPJCliente(new_cnpj);
            } else {
                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                props.setBudgetCPFCNPJCliente(new_cnpj);
            }
        }
    }

    // FORMATAR RG CO CLIENTE
    function formatarRG(val) {
        var rg = val.target.value.toString();
        var rg_clear = val.target.value.replace(/[^0-9]/g, "");
        if (rg_clear.length) {
            var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
            props.setBudgetRGCliente(new_rg);
        }
    }

    // BUSCAR CLIENTE POR CPF OU CNPJ
    const [dadosRG, setDadosRG] = useState([]);
    const [emptyClient, setEmptyClient] = useState(false);
    const [tokenCliente, setTokenCliente] = useState('');
    const [conflitoRepresentante, setconflitoRepresentante] = useState(false);

    async function buscarCliente(e) {
        e.preventDefault();

        var cpf_cnpj_clear = props.budgetCPFCNPJCliente.replace(/[^0-9]/g, "");
        if (cpf_cnpj_clear.length > 0 && cpf_cnpj_clear.length < 15) {
            const ref = db.collection('usuarios');
            const snapshot = await ref.where('cpf', '==', cpf_cnpj_clear).get();
            if (snapshot.empty) {
                console.log('No matching documents.');
                setEmptyClient(true);
                setDadosCliente([]);
                return;
            }
            var dataCliente = ([]);
            var idCliente = ([]);
            snapshot.forEach(doc => {
                dataCliente.unshift(doc.data());
                idCliente.unshift(doc.id);
            });
            console.log("Cliente", "=>", dataCliente);
            console.log("Representante", "=>", props.user);
            if (dataCliente.length && !isEmptyObject(props.user) && (props.user.representante && props.user.dados_representante && !isEmptyObject(props.user.dados_representante) && props.user.dados_representante.cargo && props.user.dados_representante.cargo.length && props.user.dados_representante.cargo !== "CEO") && (dataCliente[0].cpf === props.user.cpf)) {
                toast.error("Conflito de interesses!");
            } else {
                setconflitoRepresentante(false);
            }
            setTokenCliente(idCliente);
            setEmptyClient(false);
            setDadosCliente(dataCliente);
        }
    }

    // EDITAR RG DO CLIENTE
    const [editRGStatus, setEditRGStatus] = useState(false);
    const [editRGTyping, setEditRGTyping] = useState([]);

    async function salvarRGCliente(e, token) {
        e.preventDefault();

        if (props.budgetRGCliente && props.budgetRGCliente.length) {
            var new_rg = props.budgetRGCliente.replace(/[^0-9]/g, "");


            const ref = db.collection('usuarios');
            const snapshot = await ref.where('rg', '==', new_rg).get();
            if (snapshot.empty) {
                db.collection("usuarios")
                    .doc(token)
                    .update("rg", new_rg)
                    .then(() => {
                        dadosCliente[0].rg = new_rg;
                        setEditRGStatus(false);
                        toast.success("RG Cadastrado com Sucesso!")
                    })
                    .catch(() => {
                        toast.error("Ocorreu um erro ao slavar o RG");
                    })
            } else {
                toast.error("O RG informado já está em uso");
            }
        }
    }

    // EDITAR ENDEREÇO DO CLIENTE
    const [editEnderecoStatus, setEditEnderecoStatus] = useState(false);



    // FUNÇOÕES DO ENDEREÇO (ENDERECOS)
    function abrirModalAddress(e) {
        let modalAdress = $('#modal_cadastro__endereco'); // BG
        let modalContentAdress = $("#modal_content_endereco"); // CONTENT
        modalAdress.slideToggle(500);
        setTimeout(() => {
            modalContentAdress.slideDown();
        }, 500);
    }

    function fecharModalAddress(e) {
        let modalPhone = $('#modal_cadastro__endereco'); // BG
        let modalContent = $("#modal_cadastro__endereco .modal-content"); // CONTENT
        modalContent.slideUp(500);
        setTimeout(() => {
            modalPhone.slideToggle();
            document.getElementById('form_address_modal').reset();
        }, 500);
    }

    // SELECIONAR CLIENTE
    function selecionarCliente(e, val) {
        e.preventDefault();

        props.setClienteSelecionado(val);

        setDadosCliente([]);
        props.setBudgetCPFCNPJCliente([]);
        console.log(props.clienteSelecionado)
    }

    console.log(dadosCliente);
    return (
        <div id="budget_form_cliente">
            <div className='card__info__budget__single'>
                <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                    <h2 className='b-bot-medium'>Informações Pessoais do Cliente</h2>
                    <p className='fz-14'>Preencha as infomações do cliente a seguir para prosseguir com a criação do novo orçamento.</p>
                </div>
                <div className='wrapper__form__criar__orcamento'>
                    {
                        (props.clienteSelecionado && !isEmptyObject(props.clienteSelecionado))
                            ?
                            <div>
                                <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-10 p-g-15 w100' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                    <div className='p-rlt b-bot-medium w100'>
                                        <div className='d-fx d-al-cen m-bot-10 d-ju-cen'>
                                            <h2 className='fz-18 tx-cen'>Cliente: {props.clienteSelecionado.full_name}</h2>
                                            <button
                                                className='p-abs'
                                                style={{ right: 0, background: 'none', cursor: 'pointer' }}
                                                title="Apagar a Seleção do Cliente"
                                                onClick={() => { props.setClienteSelecionado([]) }}
                                            >
                                                <BsTrash
                                                    className='fz-18'
                                                    style={{ color: '#ff0000' }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                {
                                                    (() => {
                                                        var cpf_cnpj = props.clienteSelecionado.cpf.toString();
                                                        if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                            var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
                                                            return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                        } else {
                                                            var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                                                            return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                        }
                                                    })()
                                                }
                                            </span>
                                        </span>

                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                            <strong>E-mail:</strong> {props.clienteSelecionado.email}
                                        </span>

                                        {
                                            (props.clienteSelecionado.rg && props.clienteSelecionado.rg.length)
                                                ?
                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                    {
                                                        (() => {
                                                            var rg = props.clienteSelecionado.rg.toString();
                                                            var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                                                            return (<span><strong>RG:</strong> {new_rg}</span>);
                                                        })()
                                                    }
                                                </span>
                                                :
                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                    <span className='d-fx d-al-cen'>
                                                        <strong>RG:</strong>
                                                        <span className='m-lef-5' style={{ color: '#ff0000', textTransform: 'uppercase', fontWeight: 'bold' }}>
                                                            {
                                                                (editRGStatus)
                                                                    ?
                                                                    <input
                                                                        type='text'
                                                                        name='rg'
                                                                        value={(props.budgetRGCliente && props.budgetRGCliente.length) ? props.budgetRGCliente : null}
                                                                        onChange={(e) => formatarRG(e)}
                                                                        style={{ padding: '3px 5px', borderRadius: '3px', border: '1px solid #ccc' }}
                                                                    />
                                                                    :
                                                                    "Não Informado (Opcional)"
                                                            }
                                                        </span>

                                                        {
                                                            (editRGStatus)
                                                                ?
                                                                <span
                                                                    className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #1f8900', padding: '3px 5px', borderRadius: '3px', background: "#1f890020", cursor: 'pointer' }}
                                                                    onClick={(e) => salvarRGCliente(e, tokenCliente[0])}
                                                                >
                                                                    Salvar <GiSave className='fz-14 m-lef-5' style={{ color: "#1f8900" }} />
                                                                </span>
                                                                :
                                                                <span
                                                                    className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #dba700', padding: '3px 5px', borderRadius: '3px', background: "#dba70020", cursor: 'pointer' }}
                                                                    onClick={() => setEditRGStatus(true)}
                                                                >
                                                                    Editar <AiFillEdit className='fz-14 m-lef-5' style={{ color: "#896900" }} />
                                                                </span>
                                                        }
                                                    </span>
                                                </span>
                                        }

                                        {
                                            ((props.clienteSelecionado.enderecos && props.clienteSelecionado.enderecos.length) || (props.orcamentoCadastroEnderecoCliente && props.orcamentoCadastroEnderecoCliente.length))
                                                ?
                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                    <strong>Endereço (Principal):</strong> {
                                                        ((props.clienteSelecionado.enderecos && props.clienteSelecionado.enderecos.length) && (!props.orcamentoCadastroEnderecoCliente || !props.orcamentoCadastroEnderecoCliente.length))
                                                            ?
                                                            props.clienteSelecionado.enderecos.map((dadosEndereco) => {
                                                                if (dadosEndereco.principal) {
                                                                    return (
                                                                        `${dadosEndereco.tipo_logradouro} ${dadosEndereco.logradouro}, ${dadosEndereco.numero}, ${dadosEndereco.bairro} - ${dadosEndereco.cidade}, - ${dadosEndereco.estado}, CEP ${(() => {
                                                                            var new_cep = dadosEndereco.cep.toString();
                                                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                        })()}`
                                                                    )
                                                                }
                                                            })
                                                            :
                                                            props.orcamentoCadastroEnderecoCliente.map((dadosEndereco) => {
                                                                if (dadosEndereco.principal) {
                                                                    return (
                                                                        `${dadosEndereco.tipo_logradouro} ${dadosEndereco.logradouro}, ${dadosEndereco.numero}, ${dadosEndereco.bairro} - ${dadosEndereco.cidade}, - ${dadosEndereco.estado}, CEP ${(() => {
                                                                            var new_cep = dadosEndereco.cep.toString();
                                                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                        })()}`
                                                                    )
                                                                }
                                                            })
                                                    }
                                                </span>
                                                :
                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                    <span className='d-fx d-al-cen'>
                                                        <strong>Endereço:</strong>
                                                        <span className='m-lef-5' style={{ color: '#ff0000', textTransform: 'uppercase', fontWeight: 'bold' }}>
                                                            {
                                                                (editRGStatus)
                                                                    ?
                                                                    <input
                                                                        type='text'
                                                                        name='endereco'
                                                                        value={(props.budgetRGCliente && props.budgetRGCliente.length) ? props.budgetRGCliente : null}
                                                                        onChange={(e) => formatarRG(e)}
                                                                        style={{ padding: '3px 5px', borderRadius: '3px', border: '1px solid #ccc' }}
                                                                    />
                                                                    :
                                                                    "Não Informado"
                                                            }
                                                        </span>

                                                        {
                                                            (editEnderecoStatus)
                                                                ?
                                                                <span
                                                                    className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #1f8900', padding: '3px 5px', borderRadius: '3px', background: "#1f890020", cursor: 'pointer' }}
                                                                    onClick={(e) => salvarRGCliente(e, tokenCliente[0])}
                                                                >
                                                                    Salvar <GiSave className='fz-14 m-lef-5' style={{ color: "#1f8900" }} />
                                                                </span>
                                                                :
                                                                <span
                                                                    className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #dba700', padding: '3px 5px', borderRadius: '3px', background: "#dba70020", cursor: 'pointer' }}
                                                                    onClick={() => props.setOpenModal({ status: true, tipo: "cadastrar-endereco", token: tokenCliente[0], dadosCliente: props.clienteSelecionado, andamento: false })}
                                                                >
                                                                    Editar <AiFillEdit className='fz-14 m-lef-5' style={{ color: "#896900" }} />
                                                                </span>
                                                        }
                                                    </span>
                                                </span>
                                        }


                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                            <div className='d-fx d-al-cen'>
                                                <strong>Status:</strong>
                                                <div className='order__item__request info-default m-lef-5'>
                                                    <div className={(props.clienteSelecionado && !isEmptyObject(props.clienteSelecionado)) ? 'med-badge active-badge-success' : 'med-badge'} style={{ textTransform: 'capitalize' }}>
                                                        {
                                                            (props.clienteSelecionado && !isEmptyObject(props.clienteSelecionado))
                                                                ?
                                                                "Selecionado"
                                                                :
                                                                "Pendente"
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            :
                            <form className='form__criar__orcamento'>
                                <div className='box-input-single m-top-20'>
                                    <label>
                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                            <span className='fz-12 m-rig-5'>CPF ou CNPJ do Cliente</span>
                                            <span className='icon-info-add p-rlt'>
                                                <IoIosInformationCircleOutline />
                                                <div className='helper-input tx-black d-nn'>
                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                        <span class="css-1i1x8nz">
                                                            Seu CPF será usado para:
                                                        </span>
                                                        <div class="css-1l7kih-stack">
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                    </div>
                                                </div>{/*Helper*/}
                                            </span>
                                        </div>
                                    </label>
                                    <div className='p-rlt'>
                                        <input
                                            className={(!props.budgetCPFCNPJCliente || !props.budgetCPFCNPJCliente.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                            type='text'
                                            name="cpf_cnpj"
                                            placeholder='999.999.999-99'
                                            value={(props.budgetCPFCNPJCliente && props.budgetCPFCNPJCliente.length) ? props.budgetCPFCNPJCliente : null}
                                            onChange={(ev) => formatarCPFCNPJ(ev)}
                                        />
                                        <button
                                            className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%', background: 'none', cursor: 'pointer', }}
                                            title="Buscar cliente por CPF ou CNPJ"
                                            onClick={(e) => buscarCliente(e)}
                                        >
                                            <AiOutlineSearch className='fz-20' style={{ color: 'var(--logo-color-dark)' }} />
                                        </button>

                                    </div>
                                    {
                                        (!props.budgetCPFCNPJCliente || !props.budgetCPFCNPJCliente.length)
                                            ?
                                            <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                <span className='icon-invalid'><TiInfo /></span>
                                                <span className='text-invalid m-lef-5'>O CPF ou CNPJ do cliente não pode ser vázio!</span>
                                            </div>
                                            : null
                                    }
                                    {
                                        (dadosCliente && (dadosCliente.length))
                                            ?
                                            <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                <span className='icon-valid'><TiInfo /></span>
                                                <span className='text-valid m-lef-5'>A busca encontrou {dadosCliente.length} resultado para este {(() => {
                                                    var cpf_cnpj_clear = props.budgetCPFCNPJCliente.replace(/[^0-9]/g, "");
                                                    if (cpf_cnpj_clear.length && cpf_cnpj_clear.length > 11) {
                                                        return "CNPJ";
                                                    } else {
                                                        return "CPF";
                                                    }
                                                })()}!</span>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    (emptyClient && props.budgetCPFCNPJCliente && props.budgetCPFCNPJCliente.length)
                                                        ?
                                                        <div>
                                                            <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                <span className='text-invalid m-lef-5'>A busca não encontrou nenhum resultado para este {(() => {
                                                                    var cpf_cnpj_clear = props.budgetCPFCNPJCliente.replace(/[^0-9]/g, "");
                                                                    if (cpf_cnpj_clear.length && cpf_cnpj_clear.length > 11) {
                                                                        return "CNPJ";
                                                                    } else {
                                                                        return "CPF";
                                                                    }
                                                                })()}!</span>
                                                            </div>
                                                            <div className='m-top-15'>
                                                                <FormSingUp
                                                                    responsivoSize={props.responsivoSize}
                                                                    setUser={props.setUser}
                                                                    criador="representante"
                                                                    setDadosCliente={setDadosCliente}
                                                                    setTokenCliente={setTokenCliente}
                                                                />
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                    }
                                </div>
                                {
                                    (dadosCliente && dadosCliente.length)
                                        ?
                                        <div>
                                            {
                                                (dadosCliente.length === 1)
                                                    ?
                                                    <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-10 p-g-15 w100' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                        <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Cliente: {dadosCliente[0].full_name}</h2>
                                                        <div>
                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                    {
                                                                        (() => {
                                                                            var cpf_cnpj = dadosCliente[0].cpf.toString();
                                                                            if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
                                                                                return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                            } else {
                                                                                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                                                                                return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                            }
                                                                        })()
                                                                    }
                                                                </span>
                                                            </span>

                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                <strong>E-mail:</strong> {dadosCliente[0].email}
                                                            </span>

                                                            {
                                                                (dadosCliente[0].rg && dadosCliente[0].rg.length)
                                                                    ?
                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                        {
                                                                            (() => {
                                                                                props.setBudgetRGCliente(dadosCliente[0].rg);
                                                                                var rg = dadosCliente[0].rg.toString();
                                                                                var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                                                                                return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                            })()
                                                                        }
                                                                    </span>
                                                                    :
                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                        <span className='d-fx d-al-cen'>
                                                                            <strong>RG:</strong>
                                                                            <span className='m-lef-5' style={{ color: '#ff0000', textTransform: 'uppercase', fontWeight: 'bold' }}>
                                                                                {
                                                                                    (editRGStatus)
                                                                                        ?
                                                                                        <input
                                                                                            type='text'
                                                                                            name='rg'
                                                                                            value={(props.budgetRGCliente && props.budgetRGCliente.length) ? props.budgetRGCliente : null}
                                                                                            onChange={(e) => formatarRG(e)}
                                                                                            style={{ padding: '3px 5px', borderRadius: '3px', border: '1px solid #ccc' }}
                                                                                        />
                                                                                        :
                                                                                        <span>Não Informado <small style={{ textTransform: 'lowercase' }}>(Opcional)</small></span>
                                                                                }
                                                                            </span>

                                                                            {
                                                                                (editRGStatus)
                                                                                    ?
                                                                                    <span
                                                                                        className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #1f8900', padding: '3px 5px', borderRadius: '3px', background: "#1f890020", cursor: 'pointer' }}
                                                                                        onClick={(e) => salvarRGCliente(e, tokenCliente[0])}
                                                                                    >
                                                                                        Salvar <GiSave className='fz-14 m-lef-5' style={{ color: "#1f8900" }} />
                                                                                    </span>
                                                                                    :
                                                                                    <span
                                                                                        className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #dba700', padding: '3px 5px', borderRadius: '3px', background: "#dba70020", cursor: 'pointer' }}
                                                                                        onClick={() => setEditRGStatus(true)}
                                                                                    >
                                                                                        Editar <AiFillEdit className='fz-14 m-lef-5' style={{ color: "#896900" }} />
                                                                                    </span>
                                                                            }
                                                                        </span>
                                                                    </span>
                                                            }

                                                            {
                                                                ((dadosCliente[0].enderecos && dadosCliente[0].enderecos.length) || (props.orcamentoCadastroEnderecoCliente && props.orcamentoCadastroEnderecoCliente.length))
                                                                    ?
                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                        <strong>Endereço (Principal):</strong> {
                                                                            ((dadosCliente[0].enderecos && dadosCliente[0].enderecos.length) && (!props.orcamentoCadastroEnderecoCliente || !props.orcamentoCadastroEnderecoCliente.length))
                                                                                ?
                                                                                dadosCliente[0].enderecos.map((dadosEndereco) => {
                                                                                    if (dadosEndereco.principal) {
                                                                                        return (
                                                                                            `${dadosEndereco.tipo_logradouro} ${dadosEndereco.logradouro}, ${dadosEndereco.numero}, ${dadosEndereco.bairro} - ${dadosEndereco.cidade}, - ${dadosEndereco.estado}, CEP ${(() => {
                                                                                                var new_cep = dadosEndereco.cep.toString();
                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                            })()}`
                                                                                        )
                                                                                    }
                                                                                })
                                                                                :
                                                                                props.orcamentoCadastroEnderecoCliente.map((dadosEndereco) => {
                                                                                    if (dadosEndereco.principal) {
                                                                                        return (
                                                                                            `${dadosEndereco.tipo_logradouro} ${dadosEndereco.logradouro}, ${dadosEndereco.numero}, ${dadosEndereco.bairro} - ${dadosEndereco.cidade}, - ${dadosEndereco.estado}, CEP ${(() => {
                                                                                                var new_cep = dadosEndereco.cep.toString();
                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                            })()}`
                                                                                        )
                                                                                    }
                                                                                })
                                                                        }
                                                                    </span>
                                                                    :
                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                        <span className='d-fx d-al-cen'>
                                                                            <strong>Endereço:</strong>
                                                                            <span className='m-lef-5' style={{ color: '#ff0000', textTransform: 'uppercase', fontWeight: 'bold' }}>
                                                                                Não Informado
                                                                            </span>

                                                                            {
                                                                                (editEnderecoStatus)
                                                                                    ?
                                                                                    <span
                                                                                        className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #1f8900', padding: '3px 5px', borderRadius: '3px', background: "#1f890020", cursor: 'pointer' }}
                                                                                        onClick={(e) => salvarRGCliente(e, tokenCliente[0])}
                                                                                    >
                                                                                        Salvar <GiSave className='fz-14 m-lef-5' style={{ color: "#1f8900" }} />
                                                                                    </span>
                                                                                    :
                                                                                    <span
                                                                                        className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #dba700', padding: '3px 5px', borderRadius: '3px', background: "#dba70020", cursor: 'pointer' }}
                                                                                        onClick={() => props.setOpenModal({ status: true, tipo: "cadastrar-endereco", token: tokenCliente[0], dadosCliente: dadosCliente[0], andamento: false })}
                                                                                    >
                                                                                        Editar <AiFillEdit className='fz-14 m-lef-5' style={{ color: "#896900" }} />
                                                                                    </span>
                                                                            }
                                                                        </span>
                                                                    </span>
                                                            }
                                                        </div>
                                                        <div className='w100 d-fx d-al-cen d-ju-sp-bt'>
                                                            <button
                                                                class="button button-full button-primary tx-cen w50"
                                                                onClick={(e) => selecionarCliente(e, dadosCliente[0])}
                                                                disabled={(() => {
                                                                    if ((dadosCliente[0].enderecos && dadosCliente[0].enderecos.length) || (props.orcamentoCadastroEnderecoCliente && props.orcamentoCadastroEnderecoCliente.length)) {
                                                                        return false;
                                                                    } else {
                                                                        return true;
                                                                    }
                                                                })()}
                                                            >Selecionar Cliente e Prosseguir Para Endereço</button>
                                                        </div>
                                                    </div>
                                                    :
                                                    dadosCliente.map((dataClient, index) => {
                                                        return (
                                                            <div key={index} className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-10 p-g-15 w100' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Cliente: {dataClient.full_name}</h2>
                                                                <div>
                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                            {
                                                                                (() => {
                                                                                    var cpf_cnpj = dataClient.cpf.toString();
                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                    } else {
                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                    }
                                                                                })()
                                                                            }
                                                                        </span>
                                                                    </span>

                                                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                        <strong>E-mail:</strong> {dataClient.email}
                                                                    </span>

                                                                    {
                                                                        (dataClient.rg && dataClient.rg.length)
                                                                            ?
                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                {
                                                                                    (() => {
                                                                                        props.setBudgetRGCliente(dataClient.rg);
                                                                                        var rg = dataClient.rg.toString();
                                                                                        var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                                                                                        return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                                    })()
                                                                                }
                                                                            </span>
                                                                            :
                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                <span className='d-fx d-al-cen'>
                                                                                    <strong>RG:</strong>
                                                                                    <span className='m-lef-5' style={{ color: '#ff0000', textTransform: 'uppercase', fontWeight: 'bold' }}>
                                                                                        {
                                                                                            (editRGStatus)
                                                                                                ?
                                                                                                <input
                                                                                                    type='text'
                                                                                                    name='rg'
                                                                                                    value={(props.budgetRGCliente && props.budgetRGCliente.length) ? props.budgetRGCliente : null}
                                                                                                    onChange={(e) => formatarRG(e)}
                                                                                                    style={{ padding: '3px 5px', borderRadius: '3px', border: '1px solid #ccc' }}
                                                                                                />
                                                                                                :
                                                                                                <span>Não Informado <small style={{ textTransform: 'lowercase' }}>(Opcional)</small></span>
                                                                                        }
                                                                                    </span>

                                                                                    {
                                                                                        (editRGStatus)
                                                                                            ?
                                                                                            <span
                                                                                                className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #1f8900', padding: '3px 5px', borderRadius: '3px', background: "#1f890020", cursor: 'pointer' }}
                                                                                                onClick={(e) => salvarRGCliente(e, tokenCliente[0])}
                                                                                            >
                                                                                                Salvar <GiSave className='fz-14 m-lef-5' style={{ color: "#1f8900" }} />
                                                                                            </span>
                                                                                            :
                                                                                            <span
                                                                                                className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #dba700', padding: '3px 5px', borderRadius: '3px', background: "#dba70020", cursor: 'pointer' }}
                                                                                                onClick={() => setEditRGStatus(true)}
                                                                                            >
                                                                                                Editar <AiFillEdit className='fz-14 m-lef-5' style={{ color: "#896900" }} />
                                                                                            </span>
                                                                                    }
                                                                                </span>
                                                                            </span>
                                                                    }

                                                                    {
                                                                        ((dataClient.enderecos && dataClient.enderecos.length) || (props.orcamentoCadastroEnderecoCliente && props.orcamentoCadastroEnderecoCliente.length))
                                                                            ?
                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                <strong>Endereço (Principal):</strong> {
                                                                                    ((dataClient.enderecos && dataClient.enderecos.length) && (!props.orcamentoCadastroEnderecoCliente || !props.orcamentoCadastroEnderecoCliente.length))
                                                                                        ?
                                                                                        dataClient.enderecos.map((dadosEndereco) => {
                                                                                            if (dadosEndereco.principal) {
                                                                                                return (
                                                                                                    `${dadosEndereco.tipo_logradouro} ${dadosEndereco.logradouro}, ${dadosEndereco.numero}, ${dadosEndereco.bairro} - ${dadosEndereco.cidade}, - ${dadosEndereco.estado}, CEP ${(() => {
                                                                                                        var new_cep = dadosEndereco.cep.toString();
                                                                                                        return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                    })()}`
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                        :
                                                                                        props.orcamentoCadastroEnderecoCliente.map((dadosEndereco) => {
                                                                                            return (
                                                                                                `${dadosEndereco.tipo_logradouro} ${dadosEndereco.logradouro}, ${dadosEndereco.numero}, ${dadosEndereco.bairro} - ${dadosEndereco.cidade}, - ${dadosEndereco.estado}, CEP ${(() => {
                                                                                                    var new_cep = dadosEndereco.cep.toString();
                                                                                                    return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                })()}`
                                                                                            )
                                                                                        })
                                                                                }
                                                                            </span>
                                                                            :
                                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                <span className='d-fx d-al-cen'>
                                                                                    <strong>Endereço:</strong>
                                                                                    <span className='m-lef-5' style={{ color: '#ff0000', textTransform: 'uppercase', fontWeight: 'bold' }}>
                                                                                        Não Informado
                                                                                    </span>

                                                                                    {
                                                                                        (editEnderecoStatus)
                                                                                            ?
                                                                                            <span
                                                                                                className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #1f8900', padding: '3px 5px', borderRadius: '3px', background: "#1f890020", cursor: 'pointer' }}
                                                                                                onClick={(e) => salvarRGCliente(e, tokenCliente[0])}
                                                                                            >
                                                                                                Salvar <GiSave className='fz-14 m-lef-5' style={{ color: "#1f8900" }} />
                                                                                            </span>
                                                                                            :
                                                                                            <span
                                                                                                className='d-fx d-al-cen m-lef-5' style={{ border: '1px solid #dba700', padding: '3px 5px', borderRadius: '3px', background: "#dba70020", cursor: 'pointer' }}
                                                                                                onClick={() => props.setOpenModal({ status: true, tipo: "cadastrar-endereco", token: tokenCliente[0], dadosCliente: dataClient, andamento: false })}
                                                                                            >
                                                                                                Editar <AiFillEdit className='fz-14 m-lef-5' style={{ color: "#896900" }} />
                                                                                            </span>
                                                                                    }
                                                                                </span>
                                                                            </span>
                                                                    }
                                                                </div>
                                                                <div className='w100 d-fx d-al-cen d-ju-sp-bt'>
                                                                    <button
                                                                        class="button button-full button-primary tx-cen w50"
                                                                        onClick={(e) => selecionarCliente(e, dataClient)}
                                                                        disabled={(() => {
                                                                            if ((dataClient.enderecos && dataClient.enderecos.length) || (props.orcamentoCadastroEnderecoCliente && props.orcamentoCadastroEnderecoCliente.length)) {
                                                                                return false;
                                                                            } else {
                                                                                return true;
                                                                            }
                                                                        })()}
                                                                    >Selecionar Cliente e Prosseguir Para Endereço</button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                            }
                                        </div>
                                        : null
                                }
                            </form>
                    }
                </div>
            </div>

            <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                <button
                    className="cart"
                    style={{ marginTop: 0, textTransform: 'uppercase' }}
                    onClick={() => avancarOrcamento()}
                    disabled={(() => {
                        if (!props.clienteSelecionado || isEmptyObject(props.clienteSelecionado)) {
                            return true;
                        } else {
                            return false;
                        }
                    })()}
                >
                    <span className=' fz-12'>Próximo</span>
                </button>
            </div>
        </div>
    );

}

export default BudgetForm_cliente;