import { useEffect, useState, useMemo } from 'react';
import { db } from '../../../../../firebase';
import { Link } from 'react-router-dom';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from './Arrows';
import { WidgetLazyload } from './Lazyload';
import toast from 'react-hot-toast';

function WidgetDepartamentos(props) {
    const [ofertasDepartamentos, setOfertasDepartamentos] = useState([]); // CUPONS DE OFERTAS
    const [lazyload, setLazyload] = useState(true); // LAZYLOAD

    useEffect(() => {
        // PEGAR DADOS DO SLIDE E INSERIR NA SESSÃO
        if (!sessionStorage.getItem("departamentos_ofertas")) {
            if (!ofertasDepartamentos || !ofertasDepartamentos.length) {
                (async () => {
                    try {
                        const cuponsRef = db.collection("marketing").doc("ofertas").collection("departamentos").where("status", "==", true);
                        const snapshot = await cuponsRef.get();
                        if (snapshot.empty) {
                            toast.error("Não existe cupom de oferta disponível no momento!");
                            return;
                        }
                        var arrCupons = ([]);
                        snapshot.forEach(doc => {
                            arrCupons.unshift(doc.data());
                        });

                        sessionStorage.setItem('departamentos_ofertas', JSON.stringify(arrCupons));
                        setOfertasDepartamentos(arrCupons);
                    } catch (error) {
                        toast.error("Ocorreu um erro ao recuperar os slides de ofertas!");
                        console.log(error);
                    }
                })()
            }
        } else {
            // RECUPERAR DADOS SALVOS NA SESSÃO
            let dadosCupons = JSON.parse(sessionStorage.getItem('departamentos_ofertas'));
            setOfertasDepartamentos(dadosCupons);
        }
    }, [])

    useMemo(() => {
        if (ofertasDepartamentos && ofertasDepartamentos.length) {
            setLazyload(false);
        } 
    }, [ofertasDepartamentos]);

    let rows = [];
    for (let i = 0; i <= 7; i++) {
        rows.push(<WidgetLazyload />)
    }

    let breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 300, itemsToShow: 2 },
        { width: 500, itemsToShow: 3 },
        { width: 600, itemsToShow: 4 },
    ]

    return (
        <section className="widget-wrapper container__content p-rlt z-less">
            <div className="center p-rlt">
                <h2 className='sub-title d-bk'>Navegue por Departamento</h2>
                <Carousel
                    breakPoints={breakPoints}
                    itemsToShow={8}
                    className="w100 hide-dots p-rlt"
                    renderArrow={({ type, onClick, isEdge }) => {
                        const pointer = type === consts.PREV ? <ArrowLeft /> : <ArrowRight />
                        return (
                            <button className='btn__controller__carousel' onClick={onClick} disabled={isEdge} style={{ zIndex: 1 }}>
                                {pointer}
                            </button>
                        )
                    }}
                >
                    {
                        (!lazyload)
                            ?
                            (ofertasDepartamentos && ofertasDepartamentos.length > 0)
                                ?
                                ofertasDepartamentos.map((dadosDepartamentos) => {
                                    return (
                                        <div className="swiper-slide-single m-rig-15">
                                            <a href="javascript:void(0)" title="Seleção de Cupons" itemprop="url">
                                                <div className="box-swiper-single">
                                                    <div className="capa-swiper-single">
                                                        {
                                                            (() => {
                                                                if (dadosDepartamentos.icone_tipo == 'image') {
                                                                    return (
                                                                        <Link to={dadosDepartamentos.link}>
                                                                            <img
                                                                                className='capa-widget-single'
                                                                                src={dadosDepartamentos.icone}
                                                                                alt={dadosDepartamentos.titulo}
                                                                                title={dadosDepartamentos.titulo}
                                                                                itemprop={dadosDepartamentos.icone_tipo}
                                                                                style={{borderRadius: '8px'}}
                                                                            />
                                                                        </Link>
                                                                    )
                                                                } else if (dadosDepartamentos.icone_tipo == 'vetor') {
                                                                    return (
                                                                        <div width="125" height="125" class="css-vp7bse-category-carousel__thumb-category-carousel__thumb-svg-thumb__icon e1u7pvy50" style={{ backgroundColor: `${dadosDepartamentos.fundo}` }}>
                                                                            <svg viewBox="0 0 72 64" fill="currentColor" aria-hidden="true">
                                                                                <path fill-rule="nonzero" d={dadosDepartamentos.icone}></path>
                                                                            </svg>
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                    <div className="title-swiper-single fz-14 m-top-5">
                                                        <h3 itemprop="name" >{dadosDepartamentos.titulo}</h3>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })
                                : null
                            :
                            rows
                    }
                </Carousel>
            </div>{/* center */}
        </section>
    )
}

export { WidgetDepartamentos }