import '../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from './Templates/Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineFolderView, AiOutlineCheck, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaRegEdit } from 'react-icons/fa';
import contractOkay from '../../../../../../images/icones/svg/contract-okay.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';

function MyContract(props) {

    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);


    const [contratos, setContratos] = useState([]);

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user)) {
            const cpf_user = props.user.cpf.replace(/[^\d]+/g, '');

            // db.collection("contratos")
            //     .where("cpf_cnpj_contratante", "==", cpf_user)
            //     .onSnapshot((snap) => {
            //         setContratos(
            //             snap.docs.map((doc) => {
            //                 return doc.data();
            //             })
            //         )
            //     })

            db.collection("servicos")
                .where("cpf_cnpj_contratante", "==", cpf_user)
                .onSnapshot((snap) => {
                    setContratos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })
        }
    }, [])

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }


    String.prototype.extenso = function (c) { // Função para converter número no formato string para número por extenso.
        var ex = [
            ["zero", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"],
            ["dez", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"],
            ["cem", "cento", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"],
            ["mil", "milhão", "bilhão", "trilhão", "quadrilhão", "quintilhão", "sextilhão", "setilhão", "octilhão", "nonilhão", "decilhão", "undecilhão", "dodecilhão", "tredecilhão", "quatrodecilhão", "quindecilhão", "sedecilhão", "septendecilhão", "octencilhão", "nonencilhão"]
        ];
        var a, n, v, i, n = this.replace(c ? /[^,\d]/g : /\D/g, "").split(","), e = " e ", $ = "real", d = "centavo", sl;
        for (var f = n.length - 1, l, j = -1, r = [], s = [], t = ""; ++j <= f; s = []) {
            j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2));
            if (!(a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g), v = l % 3 ? [v.slice(0, l % 3)] : [], v = a ? v.concat(a) : v).length) continue;
            for (a = -1, l = v.length; ++a < l; t = "") {
                if (!(i = v[a] * 1)) continue;
                i % 100 < 20 && (t += ex[0][i % 100]) ||
                    i % 100 + 1 && (t += ex[1][(i % 100 / 10 >> 0) - 1] + (i % 10 ? e + ex[0][i % 10] : ""));
                s.push((i < 100 ? t : !(i % 100) ? ex[2][i == 100 ? 0 : i / 100 >> 0] : (ex[2][i / 100 >> 0] + e + t)) +
                    ((t = l - a - 2) > -1 ? " " + (i > 1 && t > 0 ? ex[3][t].replace("ão", "ões") : ex[3][t]) : ""));
            }
            a = ((sl = s.length) > 1 ? (a = s.pop(), s.join(" ") + e + a) : s.join("") || ((!j && (n[j + 1] * 1 > 0) || r.length) ? "" : ex[0][0]));
            a && r.push(a + (c ? (" " + (v.join("") * 1 > 1 ? j ? d + "s" : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is") : j ? d : $)) : ""));
        }
        return r.join(e);
    }

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Meus Contratos" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Meus Contratos</h1>
                            <div className='box-forms-singup'>
                                {/* <form className='dados-pessoais-usuario' onSubmit={(e) => atualizarDadosPessoais(e)}> */}
                                {
                                    (contratos && contratos.length)
                                        ?
                                        <div className='dados-pessoais-usuario'>
                                            {/* <h2 className='fz-20 tx-norm tx-blue-dark m-bot-20'>Contratos Pendentes</h2> */}
                                            <div className='box-requests w100 m-bot-20'>
                                                {
                                                    contratos.map((dadosContrato, index) => {
                                                        return (
                                                            <motion.div
                                                                key={index}
                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                animate={{ height: expandedSummary ? "auto" : expandedSummarySize }}
                                                                className='description-page__content'
                                                            >
                                                                <div className={(expandedSummary) ? 'wrapper__request__single w100 p-g-10 is-open' : 'wrapper__request__single w100 p-g-10'}>
                                                                    {/* DADOS PRINCIPAIS */}
                                                                    <div className='order__title__request p-top-10 p-bot-15 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                        <div className='order__item__request info-primary'>
                                                                            <strong>
                                                                                Contrato {dadosContrato.token}
                                                                            </strong>
                                                                        </div>
                                                                        {
                                                                            (props.responsivoSize && props.responsivoSize > 460)
                                                                                ?
                                                                                <div className='order__item__request info-default'>
                                                                                    <small className='d-bk m-bot-5'>
                                                                                        Data da expedição
                                                                                    </small>
                                                                                    <strong className='d-bk'>
                                                                                        {
                                                                                            dadosContrato.expedicao.toDate().toLocaleDateString("pt-BR")
                                                                                        }
                                                                                    </strong>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        {
                                                                            (props.responsivoSize && props.responsivoSize > 600 && dadosContrato.etapas[0].dataAprovacao)
                                                                                ?
                                                                                <div className='order__item__request info-default'>
                                                                                    <small className='d-bk m-bot-5'>
                                                                                        Data da aprovação
                                                                                    </small>
                                                                                    <strong className='d-bk'>
                                                                                        {
                                                                                            dadosContrato.etapas[0].dataAprovacao.toDate().toLocaleDateString("pt-BR")
                                                                                        }
                                                                                    </strong>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        <div className='order__item__request info-default'>
                                                                            <div className={(dadosContrato.status === "aprovado") ? 'med-badge active-badge-success' : 'med-badge'} style={{ textTransform: 'capitalize' }}>
                                                                                {
                                                                                    dadosContrato.status
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <buttom className="order__item__request info-primary btn__show__request" onClick={() => setExpandedSummary(!expandedSummary)}>
                                                                            <BsChevronDown className='fz-20' />
                                                                        </buttom>
                                                                    </div>

                                                                    {/* SUB DADOS */}
                                                                    <div className='order__body__request'>
                                                                        <div className='content__request d-fx d-fd-col'>
                                                                            <motion.div
                                                                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                                                                animate={{ height: expandedSubSummary ? "auto" : "70px" }}
                                                                                className='description-page__content'
                                                                            >
                                                                                <div className={(expandedSubSummary) ? 'wrapper__order__request order-content p-g-15 open' : 'wrapper__order__request order-content p-g-15'}> {/* open */}
                                                                                    <div className='order__title__request w100 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                                                                        <div className='delivery__order__type info-primary'>
                                                                                            <strong>Dados do Contrato</strong>
                                                                                        </div>
                                                                                        {
                                                                                            (props.responsivoSize && props.responsivoSize > 450)
                                                                                                ?

                                                                                                <div className='delivery__order__date info-default'>
                                                                                                    <small className='d-bk m-bot-5'>
                                                                                                        Vencimento do Contrato
                                                                                                    </small>
                                                                                                    <strong className='d-bk tx-norm fz-13'>
                                                                                                        {
                                                                                                            new Date(dadosContrato.etapas.at(-1).dataFinal).toLocaleDateString("pt-BR")
                                                                                                        }
                                                                                                    </strong>
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        <buttom className='delivery__order__action info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                                                                            <BsChevronDown className='fz-20' />
                                                                                        </buttom>
                                                                                    </div>
                                                                                    <div className='delivery__body__request m-top-20'>
                                                                                        <div className='wrapper__itens__request m-top-20 d-fx d-fw-wp d-ju-sp-bt'>
                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratado</span>
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.contratado && dadosContrato.contratado.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Empresa:</strong> {dadosContrato.contratado}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratado && dadosContrato.cpf_cnpj_contratado)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            {
                                                                                                                (() => {
                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratado.toString();
                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                    } else {
                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                    }
                                                                                                                })()
                                                                                                            }
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.representante_contratado && dadosContrato.representante_contratado.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Nome do Representante:</strong> {dadosContrato.representante_contratado}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.numero_representante_contratado)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Código do Representante:</strong> {dadosContrato.numero_representante_contratado}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Objeto de Contratação:</strong> Empresarial
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.tipo_servico && dadosContrato.tipo_servico.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Tipo de Contratação:</strong> {dadosContrato.tipo_servico}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Categoria de Contratação:</strong> {dadosContrato.categoria_servico}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Descrição de Contratação:</strong> {dadosContrato.descricao_servico}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.endereco_contratado && !isEmptyObject(dadosContrato.endereco_contratado))
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Endereço:</strong> {`${dadosContrato.endereco_contratado.logradouro}, ${dadosContrato.endereco_contratado.numero}, ${dadosContrato.endereco_contratado.bairro} - ${dadosContrato.endereco_contratado.cidade}, - ${dadosContrato.endereco_contratado.estado}, CEP ${(() => {
                                                                                                                var new_cep = dadosContrato.endereco_contratado.cep.toString();
                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                            })()}`}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratante</span>
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.contratante && dadosContrato.contratante.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Contratante:</strong> {dadosContrato.contratante}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.cpf_cnpj_contratante)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            {
                                                                                                                (() => {
                                                                                                                    var cpf_cnpj = dadosContrato.cpf_cnpj_contratante.toString();
                                                                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                                                                    } else {
                                                                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                                                                    }
                                                                                                                })()
                                                                                                            }
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.rg_contratante)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            {
                                                                                                                (() => {
                                                                                                                    var rg = dadosContrato.rg_contratante.toString();
                                                                                                                    var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                                                                    return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                                                                })()
                                                                                                            }
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.estado_civil_contratante && dadosContrato.estado_civil_contratante.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Estado Civil:</strong> {dadosContrato.estado_civil_contratante}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.nome_conjuge_contratante && dadosContrato.nome_conjuge_contratante.length && dadosContrato.estado_civil_contratante && dadosContrato.estado_civil_contratante.length && dadosContrato.estado_civil_contratante === 'Casado(a)')
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Nome do Conjuge:</strong> {dadosContrato.nome_conjuge_contratante}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.endereco_contratante && !isEmptyObject(dadosContrato.endereco_contratante))
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Endereço:</strong> {`${(dadosContrato.endereco_contratante.tipo_logradouro && dadosContrato.endereco_contratante.tipo_logradouro.length) ? dadosContrato.endereco_contratante.tipo_logradouro : ''} ${dadosContrato.endereco_contratante.logradouro}, ${dadosContrato.endereco_contratante.numero}, ${dadosContrato.endereco_contratante.bairro} - ${dadosContrato.endereco_contratante.cidade}, - ${dadosContrato.endereco_contratante.estado}, CEP ${(() => {
                                                                                                                var new_cep = dadosContrato.endereco_contratante.cep.toString();
                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                            })()}`}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                            <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                                                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados da Contratação</span>
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.objeto_contratado && dadosContrato.objeto_contratado.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Objeto de Contratação:</strong> <span style={{ textTransform: 'capitalize' }}>{dadosContrato.objeto_contratado}</span>
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.tipo_servico && dadosContrato.tipo_servico.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Tipo de Contratação:</strong> <span style={{ textTransform: 'capitalize' }}>{dadosContrato.tipo_servico}</span>
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.categoria_servico && dadosContrato.categoria_servico.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Categoria de Contratação:</strong> {dadosContrato.categoria_servico}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.descricao_servico && dadosContrato.descricao_servico.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Descrição de Contratação:</strong> {dadosContrato.descricao_servico}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.tipo_execucao && dadosContrato.tipo_execucao.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Tipo de Execução:</strong> {dadosContrato.tipo_execucao}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.endereco_servico && !isEmptyObject(dadosContrato.endereco_servico))
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Endereço:</strong> {`${(dadosContrato.endereco_servico.tipo_logradouro && dadosContrato.endereco_servico.tipo_logradouro.length) ? dadosContrato.endereco_servico.tipo_logradouro : ''} ${dadosContrato.endereco_servico.logradouro}, ${dadosContrato.endereco_servico.numero}, ${dadosContrato.endereco_servico.bairro} - ${dadosContrato.endereco_servico.cidade}, - ${dadosContrato.endereco_servico.estado}, CEP ${(() => {
                                                                                                                var new_cep = dadosContrato.endereco_servico.cep.toString();
                                                                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                                            })()}`}
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.etapas.at(-1).dataFinal)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Previsão de Finalização do Serviço: </strong>
                                                                                                            {
                                                                                                                new Date(dadosContrato.etapas.at(-1).dataFinal).toLocaleDateString("pt-BR")
                                                                                                            }
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.etapas_pagamento && dadosContrato.etapas_pagamento.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Quantidade de Etapas de Pagamento:</strong> {dadosContrato.etapas_pagamento.length}
                                                                                                            <ol style={{ marginLeft: '20px' }}>
                                                                                                                {
                                                                                                                    dadosContrato.etapas_pagamento.map((etapa, index) => {
                                                                                                                        return (
                                                                                                                            <li
                                                                                                                                key={index}
                                                                                                                                style={{ textTransform: 'capitalize' }}
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    parseInt(etapa.valor_pag).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                                                                                                }
                                                                                                                                <span className='m-lef-5'>
                                                                                                                                    ({
                                                                                                                                        (parseInt(etapa.valor_pag).toString()).extenso(true)
                                                                                                                                    })
                                                                                                                                </span>
                                                                                                                            </li>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </ol>
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.etapas_pagamento && dadosContrato.etapas_pagamento.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Valor Total do Pagamento: </strong>
                                                                                                            <span style={{ textTransform: 'capitalize' }}>
                                                                                                                {
                                                                                                                    (() => {
                                                                                                                        var contador = 0;
                                                                                                                        dadosContrato.etapas_pagamento.map((etapa) => {
                                                                                                                            contador += parseInt(etapa.valor_pag);
                                                                                                                        })
                                                                                                                        return (contador.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) + " (" + (contador.toString()).extenso(true) + ")")
                                                                                                                    })()
                                                                                                                }
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                    <strong>Acompanhamento de Serviço:</strong> {(dadosContrato && dadosContrato.acompanhamento) ? "Ativo" : "Não Incluso"}
                                                                                                </span>
                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                    <strong>Gerênciamento de Serviço:</strong> {(dadosContrato && dadosContrato.gerenciamento) ? "Ativo" : "Não Incluso"}
                                                                                                </span>
                                                                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                    <strong>Compra de Materiais do Serviço:</strong> {(dadosContrato && dadosContrato.compras) ? "Ativo" : "Não Incluso"}
                                                                                                </span>
                                                                                                {
                                                                                                    (dadosContrato && dadosContrato.observacoes && dadosContrato.observacoes.length)
                                                                                                        ?
                                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                                            <strong>Observações:</strong>
                                                                                                            <ul style={{ marginLeft: '20px' }}>
                                                                                                                {
                                                                                                                    dadosContrato.observacoes.map((obs) => {
                                                                                                                        return (<li>{obs}</li>)
                                                                                                                    })
                                                                                                                }
                                                                                                            </ul>
                                                                                                        </span>
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='wrapper__infos__request p-g-15 m-top-25 m-bot-5'>
                                                                                            <div className='info__single__request w100 d-fx'>
                                                                                                <div className='icon__info__request info-primary left'>
                                                                                                    <IoIosInformationCircleOutline className='fz-18' />
                                                                                                </div>
                                                                                                <div className='message__info__request w100 left m-lef-5'>
                                                                                                    <p>
                                                                                                        <span className='fz-13'>
                                                                                                            <span className='tx-bold'>Atenção</span>: A Engeart utiliza o WhatsApp <span className='tx-bold'>apenas para se comunicar sobre pedidos e fazer vendas</span>, porém <span className='tx-bold'>não envia nenhum tipo de código</span> para confirmar ou acessar sua conta em nossos canais de venda. Não compartilhe seus dados com ninguém. Em caso de dúvidas, utilize nossos canais digitais de atendimento: <Link to='/atendimento'>Fale conosco</Link>.
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='clear'></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </motion.div>
                                                                            <div className='w100 d-fx m-top-15' style={{ justifyContent: 'flex-end' }}>

                                                                                <Link to={`/minha-conta/meus-contratos/${dadosContrato.token}`} className='view-button view-btn-secondary' onClick={(e) => props.setContratoUnico(dadosContrato)}>
                                                                                    <AiOutlineFolderView className='fz-24' /> Visualizar Contrato
                                                                                </Link>

                                                                                {
                                                                                    (1 === 2)
                                                                                        ?
                                                                                        <button className='view-button view-btn-primary' style={{ marginRight: 0 }} disabled={true}>
                                                                                            <HiOutlineFolderDownload className='fz-24' /> Baixar Contrato
                                                                                        </button>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            {/* <button className='button button-full button-primary m-top-15' onClick={(el) => assinarContrato(el)}>Li e aceito todos os termos do contrato e permito a execução dos serviços contratados</button> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </motion.div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className='d-fx d-al-cen'>
                                            <div className='w65 p-g-25'>
                                                <h2 className='m-bot-20'>Hey!</h2>
                                                <div style={{ lineHeight: 1.5 }}>
                                                    <p className='fz-16'>Não foi possivel localizarmos nenhum contrato para o CPF do usuário logado :(</p>
                                                    <p className='fz-16'>Parece que o login foi realizado com o CPF de alguém que não seja o contratante principal!</p>
                                                    <div className='m-top-15 p-g-10' style={{ backgroundColor: "#f5f5f5", borderRadius: '6px' }}>
                                                        <p className='fz-14 m-bot-10'>O contratante principal já possui uma conta? Clique em sair e crie uma nova conta totalmente gratuita!</p>
                                                        <p className='fz-14'>Você é o contratante principal e está tendo problemas para visualizar seu contrato? Clique <Link to="/ouvidoria">aqui</Link>!</p>
                                                    </div>
                                                </div>

                                                <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                                                    <Link to="/" className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }}>
                                                        <span className=' fz-12'>Página Inícial</span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='w35'>
                                                <img src={contractOkay} />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );

}

export default MyContract;