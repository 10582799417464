import '../Assets/styles/css/Main.css';


// IMPORTAÇÃO DE REACT ROUTER
import { Link } from 'react-router-dom';

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import contactUs from '../../../../images/icones/svg/Cell phone-bro.svg';
import vendas from '../../../../images/icones/svg/vendas.svg';
import iconSuport from '../../../../images/icones/png/icon-suport.png';
import iconLove from '../../../../images/icones/png/icon-love.png';
import iconAlert from '../../../../images/icones/png/icon-alert.png';
import atendimento from '../../../../images/projetos/jpg/atendimento.jpg';

// IMPORTAÇÃO DE ICONES
import { MdHomeWork, MdHomeFilled } from 'react-icons/md';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo, TiInputChecked } from 'react-icons/ti';
import { AiOutlineMail, AiOutlinePhone, AiOutlineClockCircle, AiOutlineClose } from 'react-icons/ai';
import { BsChevronRight, BsCalendarRange } from 'react-icons/bs';
import { BiMapPin } from 'react-icons/bi';
import { db } from '../../../../firebase';
import firebase from 'firebase';
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import axios from 'axios';
import { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import $ from 'jquery';
import FormVendas from '../Assets/components/FormVendas';
import CardsAtendimento from '../Assets/components/CardsAtendimento';
import InfoLocalizacao from '../Assets/components/InfoLocalizacao';

function abrirModalSelecaoFavoritos(e) {
    let modalPhone = $('#modal_selecao_favorito'); // BG
    let modalContent = $("#modal_selecao_favorito .modal-content"); // CONTENT
    modalPhone.slideToggle(500);
    setTimeout(() => {
        modalContent.slideDown();
    }, 500);
}

function fecharModalSelecaoFavoritos(e) {
    let modalPhone = $('#modal_selecao_favorito'); // BG 
    let modalContent = $("#modal_selecao_favorito .modal-content"); // CONTENT
    modalContent.slideUp(500);
    setTimeout(() => {
        modalPhone.slideToggle();
        document.getElementById('form_selecao_favoritos_modal').reset();
    }, 500);
}

function Vendas(props) {

    let page = window.location.pathname.replace("/", "");

    return (
        <div id='vendas'>
            <Helmet title="Engeart • Canal de Vendas" />
            <div className='projeto-single-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item active m-0">Canal de Vendas</li>
                                    </ol>
                                </nav>
                            </div>
                            <FormVendas projetos={props.projetos} formularioImagem={true} responsivoSize={props.responsivoSize} cadastros={props.cadastros} paginaAtual={page} />

                            <CardsAtendimento paginaAtual={page} />

                            <InfoLocalizacao pageImage={true} responsivoSize={props.responsivoSize} />
                        </div>
                    </div>
                </div>{/*center*/}
            </div>
        </div>
    );
}

export default Vendas;