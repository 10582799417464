import { useEffect, useState, useMemo } from 'react';
// IMPORTAÇÃO DE REACT ROUTER
import { Link } from 'react-router-dom';
// TOATS - ALERTAS
import toast from 'react-hot-toast';

// FIREBASE
import { db } from '../../../../../firebase';
import firebase from 'firebase';
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import $ from 'jquery';

// IMPORTAÇÃO DE ICONES
import { MdHomeWork, MdHomeFilled } from 'react-icons/md';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo, TiInputChecked } from 'react-icons/ti';
import { AiOutlineMail, AiOutlinePhone, AiOutlineClockCircle, AiOutlineClose } from 'react-icons/ai';

// IMPORTAÇÃO DE IMAGENS
import contactUs from '../../../../../images/icones/svg/promocao.svg';

function FormPromocoes(props) {
    const [assunto, setAssunto] = useState(null);

    // HOOKS - NOME
    const [name, setName] = useState();
    const [nameMessage, setNameMessage] = useState([]);
    const [nameValid, setNameValid] = useState(false);

    // HOOKS - EMAIL
    const [email, setEmail] = useState();
    const [emailCadastrado, setEmailCadastrado] = useState(false);
    const [emailMessage, setEmailMessage] = useState([]);
    const [emailValid, setEmailValid] = useState(false);

    // HOOKS - TELEFONE
    const [phone, setPhone] = useState();
    const [phoneMessage, setPhoneMessage] = useState([]);
    const [phoneValid, setPhoneValid] = useState(false);
    const [phoneDDDValid, setPhoneDDDValid] = useState(false);

    // HOOKS - ADICIONAIS
    const [data, setData] = useState([]);
    const [favoritos, setFavoritos] = useState([]);

    useMemo(() => {
        if (name) {
            // VERIFICAÇÃO DE LETRAS EM SENHA
            var padrao = /[^a-zà-ú\s-]/gi;

            var valida_nome = name.match(padrao);

            if (valida_nome) {
                setNameValid(false);
                setNameMessage([{
                    'status': 'error',
                    'message': 'O nome deve conter apenas letras'
                }])
            } else {
                //O NOME É VÁLIDO CONTEM APENAS LETRAS -> VERIFICAR SE O NOME É COMPLETO
                var arrName = name.split(' ');
                if (arrName.length <= 1) {
                    // O USUARIO NÃO PASSOU O NOME COMPLETO
                    setNameValid(false);
                    setNameMessage([{
                        'status': 'error',
                        'message': 'O nome deve ser completo'
                    }])
                } else {
                    // O USUARIO PASSOU O NOME COMPLETO
                    setNameValid(true);
                    setNameMessage([])
                }
            }
        }
    }, [name])

    useMemo(() => {
        if (email && email.length > 0) {
            function IsEmail(email) {
                var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                if (email == '' || !er.test(email)) { return false; }
                else { return true; }
            }
            if (!IsEmail(email)) {
                setEmailValid(false);
                setEmailMessage([{
                    'status': 'error',
                    'message': 'O e-mail informado não é válido'
                }])
            } else {
                setEmailValid(true);
                setEmailMessage([])
                // VALIDANDO CADASTRO EXISTENTE
                var usuariosCadastrados = props.cadastros.filter(produto => produto.email == email);
                if (usuariosCadastrados.length > 0) {
                    // EXISTE UM email JÁ CADASTRADO NO BANCO DE DADOS -> MOSTRAR MENSAGEM DE ERRO
                    setEmailCadastrado(true);
                } else {
                    // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
                    setEmailCadastrado(false);
                }

            }
        }

    }, [email])

    useMemo(() => {
        if (phone) {
            var phoneDDD = phone.replace(/([()])/g, '').replace("_", '').split(" ");

            if (phoneDDD[0].length == 2) { // VERIFICAR EXISTENCIA DO DDD
                function getStateFromDDD(ddd) {

                    let ddds = {
                        "AC": ["68"],
                        "AL": ["82"],
                        "AM": ["92", "97"],
                        "AP": ["96"],
                        "BA": ["71", "73", "74", "75", "77"],
                        "CE": ["85", "88"],
                        "DF": ["61"],
                        "ES": ["27", "28"],
                        "GO": ["62", "64"],
                        "MA": ["98", "99"],
                        "MG": ["31", "32", "33", "34", "35", "37", "38"],
                        "MS": ["67"],
                        "MT": ["65", "66"],
                        "PA": ["91", "93", "94"],
                        "PB": ["83"],
                        "PE": ["81", "87"],
                        "PI": ["86", "89"],
                        "PR": ["41", "42", "43", "44", "45", "46"],
                        "RJ": ["21", "22", "24"],
                        "RN": ["84"],
                        "RO": ["69"],
                        "RR": ["95"],
                        "RS": ["51", "53", "54", "55"],
                        "SC": ["47", "48", "49"],
                        "SE": ["79"],
                        "SP": ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        "TO": ["63"]
                    }

                    for (let index in ddds) {
                        if (ddds[index].includes(ddd)) {
                            return true;
                        }
                    }
                }

                var retornoDDD = getStateFromDDD(phoneDDD[0]);
                console.log(retornoDDD);

                if (retornoDDD) {
                    setPhoneDDDValid(true)
                    setPhoneValid(true);
                } else {
                    setPhoneDDDValid(false)
                    setPhoneValid(false);
                }
            }

            var padrao = /(\d+)| /g;

            if (!phone.match(padrao)) {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([{
                    'status': 'error',
                    'message': 'O telefone deve conter apenas números'
                }]);
            } else {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([]);
            }
        }
    }, [phone])

    function validatePhone() {
        let newPhoneClear = phone.replace(/[^\d]+/g, '');
        if (newPhoneClear.length < 11) {
            setPhoneValid(false);
            setPhoneMessage([{
                'status': 'error',
                'message': 'O telefone deve conter o DDD'
            }]);
        } else {
            setPhoneValid(true);
            setPhoneMessage([]);
        }
    }

    function abrirModalSelecaoFavoritos(e) {
        let modalPhone = $('#modal_selecao_favorito'); // BG
        let modalContent = $("#modal_selecao_favorito .modal-content"); // CONTENT
        modalPhone.slideToggle(500);
        setTimeout(() => {
            modalContent.slideDown();
        }, 500);
    }

    function fecharModalSelecaoFavoritos(e) {
        let modalPhone = $('#modal_selecao_favorito'); // BG 
        let modalContent = $("#modal_selecao_favorito .modal-content"); // CONTENT
        modalContent.slideUp(500);
        setTimeout(() => {
            modalPhone.slideToggle();
            document.getElementById('form_selecao_favoritos_modal').reset();
        }, 500);
    }

    function sumFavortitos(el) {
        el.preventDefault();
        var checkBoxes = document.querySelectorAll(".card__input");
        var selecionados = 0;
        var arr = new Array();
        checkBoxes.forEach(function (el) {

            if (el.checked) {
                // console.log(el.value);
                arr.push(el.value);
                selecionados++;
            }

        });
        setFavoritos(arr);
        // console.log(selecionados);
    }

    // FUNÇÕES DE SELECT PERSONALIZADO

    const selected = document.querySelector(".selected__option");
    const optionsContainer = document.querySelector(".options-container");
    const searchBox = document.querySelector(".search-box input");

    const optionsList = document.querySelectorAll(".option");

    function selectedMain(val) {
        console.log(document.querySelector(".options-container"))
        document.querySelector(".options-container." + val).classList.toggle("active");

        document.querySelector("." + val + ".search-box input").value = "";
        filterList("");

        if (document.querySelector(".options-container." + val).classList.contains("active")) {
            document.querySelector("." + val + ".search-box input").focus();
        }
    };
    function selectedOption(o, val) {
        document.querySelector(".selected__option." + val).innerHTML = o.target.querySelector("label").innerHTML;
        document.querySelector(".options-container." + val).classList.remove("active");
    };
    function selectedOptionSon(o, val) {
        document.querySelector(".selected__option." + val).innerHTML = o.target.parentNode.querySelector("label").innerHTML;
        document.querySelector(".options-container." + val).classList.remove("active");
    };

    function typingSearch(e) {
        filterList(e.target.value);
    };

    const filterList = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        optionsList.forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function cadastrarAtendimento(el) {
        el.preventDefault();

        if (!name || name.length <= 0) {
            toast.error("Você precisa informar seu nome completo");
        } else if (!nameValid) {
            toast.error("O nome informado não é válido");
        } else if (!email || email.length <= 0) {
            toast.error("Você precisa informar seu e-mail completo");
        } else if (!emailValid) {
            toast.error("O e-mail informado não é válido");
        } else if (!phone || phone.length <= 0) {
            toast.error("Você precisa informar seu telefone completo");
        } else if (!phoneDDDValid) {
            toast.error("O DDD do telefone informado não é válido");
        } else if (!phoneValid) {
            toast.error("O telefone informado não é válido");
        } else {
            db.collection('SAC').add({
                categoria: props.paginaAtual,
                perfil: 'publico',
                nome: name,
                email: email,
                telefone: phone,
                data_envio: firebase.firestore.FieldValue.serverTimestamp(),
                usuario_cadastrado: emailCadastrado,
                status: 'pendente'
            }).then(() => {
                toast.success(name + " seu interesse foi registrado com sucesso!");
                setName(null);
                setEmail(null);
                setPhone(null);
            }).catch((error) => {
                toast.error("Ocorreu um erro ao registrar, tente novamente");
                console.log(error);
            })
        }
    }

    if (props.formularioImagem) {
        return (
            <div className="grid product d-fx m-bot-25 d-al-cen content__formulario__imagem" style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column-reverse' } : null}>
                <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__formulario__imagem column-xs-12 column-md-5 w40 m-0 left" : "wrapper__formulario__imagem column-xs-12 column-md-5 w100 m-0"}>
                    <div className="box-header-proj-single b-bot">
                        <h1>Fique por dentro das novidades e promoções</h1>
                        <p className="fz-12 m-top-10 m-bot-10">* Fique a vontade para solicita ajuda, tirar dúvidas, ou solicitar um suporte totalmente personalizado e privativo.</p>
                        <p className="fz-11 m-top-10 m-bot-10 ">Para reembolso, reclamações e etc, <Link to="/ouvidoria" className='fz-11' style={{ color: 'var(--dark-grey-blue)' }} href="javascript:void(0)">clique aqui</Link>.</p>
                    </div>{/*box-header-proj-single*/}

                    {/* BOX FORMULARIO CONTATO */}

                    <form id='dados-form-atendimento' className='singup-person' onSubmit={(el) => cadastrarAtendimento(el)}>
                        <div className='box-input-single m-top-15 d-nn'> {/* PROJETO FAVORITO */}
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Possui interesse em algum departamento?</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <div className='termos-news-single d-fx d-ju-sp-ev d-fw-wp w100 m-top-10'>
                                <div className='canais-single w100 m-top-10 m-bot-10'>
                                    <input id='vendas-projeto-favorito-positivo' type='radio' name="projeto_favorito" value="sim" onClick={(e) => abrirModalSelecaoFavoritos(e)} />
                                    <label for="vendas-projeto-favorito-positivo">Sim, quero selecionar o departamento</label>
                                </div>
                                <div className='canais-single w100 m-top-10 m-bot-10'>
                                    <input id='vendas-projeto-favorito-negativo' type='radio' name="projeto_favorito" value="nao" />
                                    <label for="vendas-projeto-favorito-negativo">Não, quero receber todas nas novidades e promoções</label>
                                </div>
                            </div>{/*termos-news-single*/}
                            {
                                (favoritos && favoritos.length > 0)
                                    ?
                                    <div className='info-valid d-fx d-al-cen'>
                                        <span className='icon-valid m-top-5'><TiInputChecked className='fz-18' /></span>
                                        <span className='text-valid m-lef-5'>Você selecionou <strong><em>{favoritos.length} Itens</em></strong>.</span>
                                    </div>
                                    : null
                            }

                            {/* MODAL SELEÇÃO DE PROJETO FAVORITO */}
                            <div id='modal_selecao_favorito' class="modal modal-enter modal-show d-nn">
                                <div id='modal_content_selecao_favorito' class="modal-content modal-content-md modal-content-enter modal-content-show d-nn">
                                    <div class="modal-body">
                                        <div class="container-fluid" data-component="validation">
                                            <div class="modal-title">Qual departamento deseja receber avisos de ofertas?</div>
                                            <form id='form_selecao_favoritos_modal' class="row__modal">

                                                <div class="w100"> {/* APELIDO DO ENDEREÇO DO USUÁRIO */}
                                                    <div className='box-input-single m-top-20'>
                                                        <label>
                                                            <div className='title-input d-fx d-al-cen p-rlt'>
                                                                <span className='fz-12 m-rig-5'>Buscar por nome</span>
                                                                <span className='icon-info-add p-rlt'>
                                                                    <IoIosInformationCircleOutline />
                                                                    <div className='helper-input tx-black d-nn'>
                                                                        <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                            <span class="css-1i1x8nz">
                                                                                Seu CPF será usado para:
                                                                            </span>
                                                                            <div class="css-1l7kih-stack">
                                                                                <div>
                                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                        <div>
                                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                        <div>
                                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                        <div>
                                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                        <div>
                                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                        </div>
                                                                    </div>{/*Helper*/}
                                                                </span>
                                                            </div>
                                                        </label>
                                                        <input id="surname-address-modal-dados" className='input-form w100 fz-16 m-top-5' type='text' name="apelido-address" placeholder='Projeto residencial com ofurô' />
                                                    </div>{/*box-input-single */}
                                                </div>

                                                <div class="w100"> {/* RESULTADOS DA BUSCA */}
                                                    <div class="box__interesse__area">

                                                        {data.map((item) => {
                                                            return (
                                                                <label class="card__interesse__area__user">
                                                                    <input className="card__input" type="checkbox" name='dados-proj_pronto' style={{ display: 'none' }} value={item.id} />
                                                                    <div class="card__body">
                                                                        <div class="card__body-cover">
                                                                            <img class="card__body-cover-image" src="https://images.unsplash.com/photo-1640622657236-e83b28df8e01?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" />
                                                                            <span class="card__body-cover-checkbox">
                                                                                <svg class="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
                                                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                        <div class="card__body-header">
                                                                            <h2 class="card__body-header-title fz-16">{item.name}</h2>
                                                                            <div class="card__body-header-subtitle">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 modal-footer right m-top-5">
                                                    <button class="btn-default button-text" type="button" data-trigger="close" onClick={(e) => abrirModalSelecaoFavoritos(e)}>Cancelar</button>
                                                    <button class="btn-default button-primary" type="button" onClick={(e) => sumFavortitos(e)}>Salvar seleção</button>
                                                </div>
                                                <div className='clear'></div>
                                            </form>
                                        </div>
                                    </div>
                                    <label>
                                        <AiOutlineClose className='modal-close' onClick={(e) => abrirModalSelecaoFavoritos(e)} />
                                    </label>
                                </div>
                            </div>
                        </div>{/*box-input-single */}

                        <div class="box-input-single m-top-20 d-nn">
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Possui interesse em algum departamento?</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>

                            <div class="select-box">
                                <div class="options-container departamento">
                                    <div class="search-box">
                                        <input className='input-form' type="text" placeholder="Busque aqui a opção desejada..." onKeyUp={(e) => typingSearch(e)} />
                                    </div>

                                    <div class="option" onClick={(o) => selectedOption(o, "departamento")}>
                                        <input type="radio" class="radio" id="personalizar_departamento_adaptar_projeto" name="departamento" value="todos" />
                                        <label for="personalizar_departamento_adaptar_projeto" onClick={(o) => selectedOptionSon(o, "departamento")}>Todos os departamentos</label>
                                    </div>

                                    <div class="option" onClick={(o) => selectedOption(o, "departamento")}>
                                        <input type="radio" className="radio m-top-5" id="personalizar_departamento_novo_projeto" name="departamento" value="projetos" />
                                        <label for="personalizar_departamento_novo_projeto" onClick={(o) => selectedOptionSon(o, "departamento")}>Projetos</label>
                                    </div>

                                    <div class="option" onClick={(o) => selectedOption(o, "departamento")}>
                                        <input type="radio" class="radio" id="personalizar_departamento_alterar_projeto" name="departamento" value="decoracao" />
                                        <label for="personalizar_departamento_alterar_projeto" onClick={(o) => selectedOptionSon(o, "departamento")}>Itens de Decoração</label>
                                    </div>

                                    <div class="option" onClick={(o) => selectedOption(o, "departamento")}>
                                        <input type="radio" class="radio" id="personalizar_departamento_adaptar_projeto" name="departamento" value="materiais" />
                                        <label for="personalizar_departamento_adaptar_projeto" onClick={(o) => selectedOptionSon(o, "departamento")}>Materiais de Contrução</label>
                                    </div>
                                </div>

                                <div class="selected__option departamento input-form" onClick={() => selectedMain("departamento")}>
                                    Selecione o departamento desejado
                                </div>

                                {/* <input id='personalizar_orcamento_finalidade' type="text" name="personalizar_orcamento_finalidade" value={orcamentoFinalidade} readOnly disabled style={{ display: "none" }} /> */}
                            </div>
                            <div className='info-invalid d-fx d-al-cen m-top-5'>
                                <span className='icon-invalid'><TiInfo /></span>
                                <span className='text-invalid m-lef-5'>Nenhuma opção para departamento selecionada</span>
                            </div>
                        </div>

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Nome Completo</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <input id="nome-cadastro-singup" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                            {
                                (nameMessage)
                                    ?
                                    nameMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>E-mail</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <input id='email-cadastro-singup' className={(email && !emailValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='email' name="email" placeholder='seuemail@email.com' value={email} onChange={(ev) => setEmail(ev.target.value)} />
                            {
                                (emailMessage)
                                    ?
                                    emailMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Telefone</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <InputMask id='telefone-cadastro-singup' mask="(99) 99999-9999" placeholder='Insira seu telefone' className={(phone && phone.match('[0-9]') && !phoneValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} value={phone} onChange={(ev) => setPhone(ev.target.value)} onBlur={() => validatePhone()} />
                            <div>
                                {
                                    (!phoneDDDValid)
                                        ?
                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                            <span className='icon-invalid'><TiInfo /></span>
                                            <span className='text-invalid m-lef-5'>O DDD informado não é válido</span>
                                        </div>
                                        : null
                                }
                            </div>
                            <div>
                                {
                                    (phoneMessage)
                                        ?
                                        phoneMessage.map((infoStatus) => {
                                            if (infoStatus.status == 'error') {
                                                return (
                                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-invalid'><TiInfo /></span>
                                                        <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            } else if (infoStatus.status == 'success') {
                                                return (
                                                    <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-valid'><TiInfo /></span>
                                                        <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            }
                                        })
                                        : null
                                }
                            </div>
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-15 d-nn'> {/* MEIOS DE COMUNICAÇÃO */}
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Selecione o método que podemos entrar em contato</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <div className='termos-news-single d-fx d-ju-sp-ev d-fw-wp w100 m-top-10'>
                                <div className='canais-single w100 m-bot-10'>
                                    <input id='newsletter-email-dados' type='checkbox' name="contact_email" />
                                    <label for="newsletter-email-dados">E-mail</label>
                                </div>
                                <div className='canais-single w100 m-top-10 m-bot-10'>
                                    <input id='newsletter-sms-dados' type='checkbox' name="contact_sms" />
                                    <label for="newsletter-sms-dados">SMS</label>
                                </div>
                                <div className='canais-single w100 m-top-10 m-bot-10'>
                                    <input id='newsletter-whatsapp-dados' type='checkbox' name="contact_whats" />
                                    <label for="newsletter-whatsapp-dados">WhatsApp</label>
                                </div>
                                <div className='canais-single w100 m-top-10 m-bot-10'>
                                    <input id='newsletter-phone-dados' type='checkbox' name="contact_phone" />
                                    <label for="newsletter-phone-dados">Contato telefônico</label>
                                </div>
                            </div>{/*termos-news-single*/}
                        </div>{/*box-input-single */}


                        <button className="button button-full button-primary margin-top align-left">
                            Concordo e quero prosseguir
                        </button>
                        <div className='aviso-termos m-top-10 helper'>
                            Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                        </div>
                    </form>
                </div>
                <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__imagem__formulario w50 d-fx d-al-cen d-ju-cen m-top-0 right m-bot-0" : "wrapper__imagem__formulario w100 d-fx d-al-cen d-ju-cen m-top-0 m-bot-0"}>
                    <img src={contactUs} style={(props.responsivoSize && props.responsivoSize < 350 || props.responsivoSize > 768) ? { width: '100%' } : { maxWidth: '80%' }} />
                </div>
                <div className="clear"></div>
            </div>
        )
    } else {
        return (
            <div className="grid product d-fx m-bot-25 d-al-cen content__formulario__imagem" style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column-reverse' } : null}>
                <div className="wrapper__formulario__imagem column-xs-12 column-md-5 w100 m-0">
                    <div className="box-header-proj-single b-bot">
                        <h1>Diga como podemos ajudar</h1>
                        <p className="fz-12 m-top-10 m-bot-10">* Fique a vontade para solicita ajuda, tirar dúvidas, ou solicitar um suporte totalmente personalizado e privativo.</p>
                        <p className="fz-11 m-top-10 m-bot-10 ">Para reembolso, reclamações e etc, <Link to="/ouvidoria" className='fz-11' style={{ color: 'var(--dark-grey-blue)' }} href="javascript:void(0)">clique aqui</Link>.</p>
                    </div>{/*box-header-proj-single*/}

                    {/* BOX FORMULARIO CONTATO */}

                    <form id='dados-form-atendimento' className='singup-person' onSubmit={(el) => cadastrarAtendimento(el)}>
                        <div className='box-input-single'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Assunto</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <input id='dados-contato-assunto' className='input-form w100 fz-16 m-top-5' type='text' name="assunto" placeholder='Seu assunto' value={assunto} onChange={(e) => setAssunto(e.target.value)} />
                            {
                                (!assunto)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>Por favor, digite o assunto em que podemos ajudar</span>
                                    </div>
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Nome Completo</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id="nome-cadastro-singup" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                            {
                                (nameMessage)
                                    ?
                                    nameMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>E-mail</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id='email-cadastro-singup' className={(email && !emailValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='email' name="email" placeholder='seuemail@email.com' value={email} onChange={(ev) => setEmail(ev.target.value)} />
                            {
                                (emailMessage)
                                    ?
                                    emailMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Telefone</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <InputMask id='telefone-cadastro-singup' mask="(99) 99999-9999" placeholder='Insira seu telefone' className={(phone && phone.match('[0-9]') && !phoneValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} value={phone} onChange={(ev) => setPhone(ev.target.value)} onBlur={() => validatePhone()} />
                            <div>
                                {
                                    (!phoneDDDValid)
                                        ?
                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                            <span className='icon-invalid'><TiInfo /></span>
                                            <span className='text-invalid m-lef-5'>O DDD informado não é válido</span>
                                        </div>
                                        : null
                                }
                            </div>
                            <div>
                                {
                                    (phoneMessage)
                                        ?
                                        phoneMessage.map((infoStatus) => {
                                            if (infoStatus.status == 'error') {
                                                return (
                                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-invalid'><TiInfo /></span>
                                                        <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            } else if (infoStatus.status == 'success') {
                                                return (
                                                    <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-valid'><TiInfo /></span>
                                                        <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            }
                                        })
                                        : null
                                }
                            </div>
                        </div>{/*box-input-single */}

                        <button className="button button-full button-primary margin-top align-left">
                            Concordo e quero prosseguir
                        </button>
                        <div className='aviso-termos m-top-10 helper'>
                            Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default FormPromocoes