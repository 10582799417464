import { useEffect, useState, useMemo } from 'react';
import { db } from '../../../../../firebase';
import { BoxDecoradosLazyload } from './Lazyload';


import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from './Arrows';
import { WidgetLazyload } from './Lazyload';
import toast from 'react-hot-toast';

function BoxProjetosDecorados(props) {

    const [projetosDecorados, setProjetosDecorados] = useState([]); // IMAGENS DA PROMOÇÃO
    const [lazyload, setLazyload] = useState(true); // LAZYLOAD

    useEffect(() => {
        // PEGAR DADOS DO SLIDE E INSERIR NA SESSÃO
        if (!sessionStorage.getItem("cupons_projetosDecorados")) {
            if (!projetosDecorados || !projetosDecorados.length) {
                (async () => {
                    try {
                        const ref = db.collection("marketing").doc("ofertas").collection("estilos").where("status", "==", true).where("seguimento_primario", "==", "projetos").orderBy("orderId", "desc").limit(10);
                        const snapshot = await ref.get();
                        if (snapshot.empty) {
                            toast.error("Não existe terrenos disponível no momento!");
                            return;
                        }
                        var arr = ([]);
                        snapshot.forEach(doc => {
                            arr.unshift(doc.data());
                        });

                        sessionStorage.setItem('cupons_projetosDecorados',JSON.stringify(arr));
                        setProjetosDecorados(arr);
                    } catch (error) {
                        toast.error("Ocorreu um erro ao recuperar os projetos decorados!");
                        console.log(error);
                    }
                })()
            }
        } else {
            // RECUPERAR DADOS SALVOS NA SESSÃO
            let dadosProjetosDecorados = JSON.parse(sessionStorage.getItem('cupons_projetosDecorados'));
            setProjetosDecorados(dadosProjetosDecorados);
        }
    }, [])

    useMemo(() => {
        if (projetosDecorados && projetosDecorados.length) {
            setLazyload(false);
        }
    }, [projetosDecorados]);

    async function getOffers() {
        // PEGANDO PACOTES DE OFERTAS
        await db.collection("marketing").doc("ofertas").collection("estilos").where("status", "==", true).where("seguimento_primario", "==", "projetos").orderBy("orderId", "desc").limit(10).onSnapshot((infos) => {
            var arr = ([]);
            infos.docs.map((dados) => {
                arr.unshift(dados.data());
            })
            setProjetosDecorados(arr);
        })
    }

    let rows = [];
    for (let i = 1; i <= 5; i++) {
        rows.push(<BoxDecoradosLazyload />)
    }

    let breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 300, itemsToShow: 2 },
        { width: 500, itemsToShow: 3 },
        { width: 600, itemsToShow: 4 },
    ]

    return (

        <section className="widget-wrapper p-rlt p-top-25 p-bot-25 z-less">
            <div className="center p-rlt">
                <h2 className='sub-title d-bk'>Estilos de projetos</h2>
                {
                    (props.responsivoSize && props.responsivoSize >= 800)
                        ?
                        <ul className='wrapper__decor__dream d-fx d-fw-wp d-ju-sp-bt'>
                            {
                                (!lazyload)
                                    ?
                                    (projetosDecorados && projetosDecorados.length > 0)
                                        ?
                                        projetosDecorados.map((dadosOferta, index) => {
                                            return (
                                                <li key={index} className='decor__dream__single w20-c m-bot-40 d-bk'>
                                                    <div className='content__img__decor__dream w100 d-fx d-ju-cen'>
                                                        <img src={dadosOferta.capa} />
                                                    </div>
                                                    <p className='fz-18 w100 tx-cen'>{dadosOferta.titulo}</p>
                                                </li>
                                            )
                                        })
                                        : null
                                    :
                                    <div className='w100 d-fx d-fw-wp d-ju-cen'>
                                        <div className='d-fx d-ju-sp-bt w100 m-bot-20'>{rows}</div>
                                        <div className='d-fx d-ju-sp-bt w100 m-top-20'>{rows}</div>
                                    </div>
                            }
                        </ul>
                        :
                        <Carousel
                            breakPoints={breakPoints}
                            itemsToShow={4}
                            className="w100 hide-dots p-rlt"
                            renderArrow={({ type, onClick, isEdge }) => {
                                const pointer = type === consts.PREV ? <ArrowLeft /> : <ArrowRight />
                                return (
                                    <button className='btn__controller__carousel' onClick={onClick} disabled={isEdge} style={{ zIndex: 1 }}>
                                        {pointer}
                                    </button>
                                )
                            }}
                        >
                            {
                                (!lazyload)
                                    ?
                                    (projetosDecorados && projetosDecorados.length > 0)
                                        ?
                                        projetosDecorados.map((dadosOferta) => {
                                            return (
                                                <li className='decor__dream__single m-bot-40 d-bk'>
                                                    <div className='content__img__decor__dream w100 d-fx d-ju-cen'>
                                                        <img src={dadosOferta.capa} />
                                                    </div>
                                                    <p className='fz-18 w100 tx-cen'>{dadosOferta.titulo}</p>
                                                </li>
                                            )
                                        })
                                        : null
                                    :
                                    <div className='w100 d-fx d-fw-wp d-ju-cen'>
                                        <div className='d-fx d-ju-sp-bt w100 m-bot-20'>{rows}</div>
                                        <div className='d-fx d-ju-sp-bt w100 m-top-20'>{rows}</div>
                                    </div>
                            }
                        </Carousel>
                }
            </div>{/* center */}
        </section>
    )
}

export default BoxProjetosDecorados