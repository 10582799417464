import '../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from './Templates/Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineHeart, AiOutlineCheck, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdOtherHouses } from 'react-icons/md';
import { HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaRegEdit } from 'react-icons/fa';
import { useEffect, useState, useMemo } from 'react';

function MyAccount(props) {

    // HOOKS - NOME
    const [name, setName] = useState((props.user && !isEmptyObject(props.user) && props.user.full_name) ? props.user.full_name : null);
    const [nameMessage, setNameMessage] = useState([]);
    const [nameValid, setNameValid] = useState(true);

    // HOOKS - GENERO
    const [genero, setGenero] = useState();

    // HOOKS - NASCIMENTO
    const [nascimento, setNascimento] = useState((props.user && !isEmptyObject(props.user) && props.user.birthdate) ? props.user.birthdate : null);
    const [nascimentoStatus, setNascimentoStatus] = useState([]);

    async function forgotPassword(e) {
        e.preventDefault();
        //redefinir Senha
        await auth.sendPasswordResetEmail(props.user.email)
            .then(() => {
                toast.success('Verifique sua caixa de e-mail.');
            })
            .catch((error) => {
                var errorCode = error.code;
                var errortype = error.code.split('/');
                var errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
                if (errortype[1] == "user-not-found") {
                    toast.error("O usuário não foi encontrado ou não existe");
                } else {
                    toast.error("Ocorreu um erro ao redefinir sua senha");
                }
            });
    }

    function atualizarDados(e) {
        e.preventDefault();
        toast.error("Esta função está em desenvolvimento");
    }

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Meus dados" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Meus Dados</h1>
                            <div className='box-forms-singup'>
                                {/* <form className='dados-pessoais-usuario' onSubmit={(e) => atualizarDadosPessoais(e)}> */}
                                <form className='dados-pessoais-usuario' onSubmit={(e) => atualizarDados(e)}>
                                    <h2 className='fz-20 tx-norm tx-blue-dark m-bot-20'>Dados Básicos</h2>

                                    <button className='button button-full button-primary' onClick={(el) => forgotPassword(el)}>Alterar Senha</button>

                                    <div className='box-input-single m-top-20'> {/* NOME DO USUÁRIO */}
                                        <label>
                                            <div className='title-input d-fx d-al-cen'>
                                                <span className='fz-12 m-rig-5'>Nome Completo</span>
                                                <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                            </div>
                                            <div className='helper-input d-nn'>
                                                <h3>Seu CPF será usado para:</h3>
                                                <ul>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                </ul>
                                            </div>{/*Helper*/}
                                        </label>
                                        <input id="nome-dados" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' disabled={true} readOnly={true} value={name} onChange={(ev) => setName(ev.target.value)} />
                                        {
                                            (nameMessage)
                                                ?
                                                nameMessage.map((infoStatus) => {
                                                    if (infoStatus.status == 'error') {
                                                        return (
                                                            <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                            </div>
                                                        )
                                                    } else if (infoStatus.status == 'success') {
                                                        return (
                                                            <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                <span className='icon-valid'><TiInfo /></span>
                                                                <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                : null
                                        }
                                    </div>{/*box-input-single */}

                                    <div className='box-input-single m-top-20 b-bot'> {/* CPF DO USUÁRIO || DESABILITADO */}
                                        <label>
                                            <div className='title-input d-fx d-al-cen p-rlt'>
                                                <span className='fz-12 m-rig-5'>CPF</span>
                                                <span className='icon-info-add'>
                                                    <IoIosInformationCircleOutline />
                                                    <div className='helper-input tx-black d-nn' style={{ pointerEvents: 'none', zIndex: 9999, position: 'absolute', inset: '0px auto auto 0px', margin: '0px', left: '15%', top: '-15px' }}>
                                                        <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                            <span class="css-1i1x8nz">
                                                                Seu CPF será usado para:
                                                            </span>
                                                            <div class="css-1l7kih-stack">
                                                                <div>
                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                        <div>
                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                        <div>
                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                        <div>
                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                        <div>
                                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                        </div>
                                                                        <div>
                                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                        </div>
                                                    </div>{/*Helper*/}
                                                </span>
                                            </div>
                                        </label>
                                        {/* <input id='cpf-cadastro' className='input-form w100 fz-16 m-top-5' type='text' name="cpf" placeholder='999.999.999-99' value={(props.user.cpf) ? props.user.cpf : null} disabled="disabled" /> */}
                                        <InputMask id='cpf-dados' className='input-form w100 fz-16 m-top-5' mask="999.999.999-99" value={props.user.cpf} disabled="disabled" placeholder='999.999.999-99' />
                                        <div className='info-invalid d-fx d-al-cen m-top-10'>
                                            <span className='icon-invalid'><TiInfo /></span>
                                            <span className='text-invalid m-lef-5'>O CPF não pode ser alterado</span>
                                        </div>
                                    </div>{/*box-input-single */}

                                    <button id='btn_send_form_dados' className='button button-full button-primary m-top-20 m-bot-20 d-fx d-al-cen'>
                                        {/* <BsSave className="m-rig-5" /> */}
                                        <FaRegEdit className="m-rig-5"/>
                                        Editar dados
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );
}

export default MyAccount;