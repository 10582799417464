// LINKS
import { Link, useLocation } from 'react-router-dom';

// IMPORTAÇÃO DE IMAGENS
// ICONES
import icon1P from '../../../../../images/icones/png/i01.png';
import icon1C from '../../../../../images/icones/png/i01-cor.png';
import icon2P from '../../../../../images/icones/png/i02.png';
import icon2C from '../../../../../images/icones/png/i02-cor.png';
import icon3P from '../../../../../images/icones/png/i03.png';
import icon3C from '../../../../../images/icones/png/i03-cor.png';
import icon4P from '../../../../../images/icones/png/i04.png';
import icon4C from '../../../../../images/icones/png/i04-cor.png';
import bloqueioCopia from '../../Assets/funcoes/bloqueioCopia';

function CardProdutos(props) {
    return (
        <div className={(props.filtrados && (props.filtrados.length <= 1 || props.filtrados.length >= 3)) ? 'wrapper__projetos w100 d-fx d-ju-sp-bt d-fw-wp' : 'wrapper__projetos w100 d-fx d-ju-lef d-fw-wp'}>
            {
                (props.filtrados && props.filtrados.length)
                    ?
                    props.filtrados.map((infoProjeto, index) => {
                        return (
                            <div key={index} id={infoProjeto.orderId} className={(props.filtrados.length > 3) ? "box-card-projeto-single anime m-top-2" : "box-card-projeto-single anime"} style={(props.filtrados.length > 1 && props.filtrados.length < 3) ? { margin: '0 2%' } : null}>
                                <div className="header-card" onDragStart={e => { e.preventDefault() }} onContextMenu={(e) => bloqueioCopia(e, props.setOpenModal)}>
                                    <div className="card-preview">
                                        <span className='classificacao__desconto'>
                                            <strong>-15% OFF</strong>
                                        </span>
                                        <div className="slides">
                                            <img src={infoProjeto.capa} alt={infoProjeto.titulo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="body-card">
                                    <div className="title-card">
                                        <h2 style={{ textTransform: 'uppercase' }}>{infoProjeto.titulo}</h2>
                                    </div>
                                    <div className="description-card">
                                        <h3>{infoProjeto.precoFinal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h3>
                                        <p style={{ textTransform: 'uppercase' }}>{infoProjeto.tipo} • {(infoProjeto.pavimentoQnt > 1) ? 'Sobrado' : 'Térreo'} • E{infoProjeto.orderId}A</p>
                                    </div>
                                    {
                                        (props.responsivoSize && props.responsivoSize >= 320)
                                            ?
                                            <div className="infos-card d-fw-wp">
                                                <div className="w50 left">
                                                    <div className="infos-single">
                                                        <div className="inline-item">
                                                            <img className="img-pb" src={icon1P} alt="" />
                                                            <img className="img-cor" src={icon1C} alt="" />
                                                            <b className='d-fx d-al-cen d-ju-sp-ev'>{infoProjeto.larguraTerreno}<small> X </small>{infoProjeto.comprimentoTerreno}</b>
                                                            <span> • Terreno</span>
                                                        </div>
                                                    </div>
                                                    <div className="infos-single">
                                                        <div className="inline-item">
                                                            <img className="img-pb" src={icon2P} alt="" />
                                                            <img className="img-cor" src={icon2C} alt="" />
                                                            <b>{infoProjeto.quarto}</b>
                                                            <span> • Quartos</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w50 right">
                                                    <div className="infos-single">
                                                        <div className="inline-item">
                                                            <img className="img-pb" src={icon3P} alt="" />
                                                            <img className="img-cor" src={icon3C} alt="" />
                                                            <b>{infoProjeto.banheiro}</b>
                                                            <span> • Banheiros</span>
                                                        </div>
                                                    </div>
                                                    <div className="infos-single">
                                                        <div className="inline-item">
                                                            <img className="img-pb" src={icon4P} alt="" />
                                                            <img className="img-cor" src={icon4C} alt="" />
                                                            <b>{infoProjeto.garagemVagas}</b>
                                                            <span> • Garagem</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>

                                            :
                                            <div className="infos-card d-fw-wp">
                                                <div className="w100">
                                                    <div className="infos-single w100 d-ju-cen">
                                                        <div className="inline-item d-fw-wp d-ju-cen">
                                                            <div className='icon__card__preview w100'>
                                                                <img className="img-pb" src={icon1P} alt="" />
                                                                <img className="img-cor" src={icon1C} alt="" />
                                                            </div>
                                                            <b className='d-fx d-al-cen d-ju-sp-ev'>{infoProjeto.larguraTerreno}<small> X </small>{infoProjeto.comprimentoTerreno}</b>
                                                            <span> • Terreno</span>
                                                        </div>
                                                    </div>
                                                    <div className="infos-single w100 d-ju-cen">
                                                        <div className="inline-item d-fw-wp d-ju-cen">
                                                            <div className='icon__card__preview w100'>
                                                                <img className="img-pb" src={icon2P} alt="" />
                                                                <img className="img-cor" src={icon2C} alt="" />
                                                            </div>
                                                            <b>{infoProjeto.quarto}</b>
                                                            <span> • Quartos</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w100">
                                                    <div className="infos-single w100 d-ju-cen m-top-0">
                                                        <div className="inline-item d-fw-wp d-ju-cen">
                                                            <div className='icon__card__preview w100'>
                                                                <img className="img-pb" src={icon3P} alt="" />
                                                                <img className="img-cor" src={icon3C} alt="" />
                                                            </div>
                                                            <b>{infoProjeto.banheiro}</b>
                                                            <span> • Banheiros</span>
                                                        </div>
                                                    </div>
                                                    <div className="infos-single w100 d-ju-cen">
                                                        <div className="inline-item d-fw-wp d-ju-cen">
                                                            <div className='icon__card__preview w100'>
                                                                <img className="img-pb" src={icon4P} alt="" />
                                                                <img className="img-cor" src={icon4C} alt="" />
                                                            </div>
                                                            <b>{infoProjeto.garagemVagas}</b>
                                                            <span> • Garagem</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>
                                    }
                                    <div className="aviso-promo">
                                        <p>Projetos em até <b>6x sem juros</b> e parcelas de até <b>18x</b></p>
                                    </div>
                                </div>
                                <Link to={`/projetos/${infoProjeto.orderId}`} className="footer-card">
                                    <p className="text-footer">
                                        Ver Projeto
                                    </p>
                                </Link>
                            </div>
                        );
                    })
                    : null
            }
        </div>
    )
}

export default CardProdutos;