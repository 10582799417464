// IMPORTAÇÃO DE ICONES
import { AiOutlineMail, AiOutlinePhone, AiOutlineClockCircle } from 'react-icons/ai';
import { BsChevronRight, BsCalendarRange } from 'react-icons/bs';
import { MdOutlineShareLocation } from 'react-icons/md';
import { BiMapPin } from 'react-icons/bi';

// IMPORTAÇÃO DE IMAGENS
import atendimento from '../../../../../images/projetos/jpg/atendimento.jpg';

function InfoLocalizacao(props) {
    return (
        <div className="grid product d-fx d-ju-cen" style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column' } : null}>
            {
                (props.pageImage)
                    ?
                    <div className={(props.responsivoSize && props.responsivoSize < 768) ? "w100 d-fx d-ju-cen m-bot-25" : "w60 d-fx d-ju-cen"}>
                        <img className={(props.responsivoSize && props.responsivoSize < 768) ? "w60" : "w100"} src={atendimento} alt="" />
                    </div>
                    : null
            }

            <div className={(props.responsivoSize && props.responsivoSize < 768) ? "w100 d-fx d-ju-cen m-bot-25" : "w60 d-fx d-ju-cen"} style={{ flexDirection: 'column' }}>
                <div className="box-infos-empresa b-bot">
                    <h1 className="w100" style={{ textAlign: 'center' }}>Atendimento e Funcionamento</h1>
                </div>
                <div className={(props.responsivoSize && props.responsivoSize < 768) ? "content-location m-g-0 d-fx d-fw-wp d-ju-cen" : "content-location"} style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column', alignContent: 'center' } : null}>
                    <h4><BiMapPin /> São José do Rio Preto - SP</h4>
                    <h4>Atendimento</h4>
                    <p><MdOutlineShareLocation /> Atendemos em todo Brasil</p>
                    <p><BsCalendarRange /> Segunda à Sexta</p>
                    <p className="b-bot"><AiOutlineClockCircle /> 09:00 AM - 18:00 PM</p>
                    <p><AiOutlinePhone /> +55 (17) 99655-5652</p>
                    <p><AiOutlineMail /> contato@eengeart.com</p>
                    <small className='fz-12' style={{ fontStyle: 'italic' }}>* SOMOS E-COMMERCE - NÃO TEMOS ATENDIMENTO LOCAL</small>
                </div>
            </div>
        </div>
    )
}

export default InfoLocalizacao;