
import { AiOutlineClose, AiOutlineReload, AiOutlineClear } from 'react-icons/ai';
import { fecharModal } from '../funcoes/FuncoesGerais';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { GiSave } from 'react-icons/gi';
import { useMemo, useState } from 'react';
import logoIcon from '../../../../../images/icones/jpg/logo_icone.jpg';
import { auth, db, fs } from '../../../../../firebase';
import toast from 'react-hot-toast';


// IMPORTAÇÃO PARA ASSINATURA
// import { createRoot } from 'react-dom/client'
import { useEffect, useRef } from 'react';
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getComponent, registerLicense } from '@syncfusion/ej2-base';
import { updateSampleSection } from '../../../../../common/sample-base';
// import SignaturePad from 'react-signature-pad-wrapper'
import SignaturePad from "react-signature-canvas";
import firebase from 'firebase';


function ModalAprovarOrcamento(props) {

    console.log(props)
    let navigate = useNavigate();
    const [lazyload, setLazyload] = useState(false); // LAZYLOAD
    const [depoimentos, setDepoimentos] = useState([]); // DEPOIMENTOS DOS CLIENTES
    const [senha, setSenha] = useState(''); // SENHA
    const [token, setToken] = useState(''); // Token ID Firestore
    const [senhaValid, setSenhaValid] = useState(false) // SENHA VÁLIDA
    const [signatureValid, setSignatureValid] = useState(false) // ASSINATURA SALVA
    const [signatureSaved, setSignatureSaved] = useState(false) // ASSINATURA SALVA
    const [signatureContent, setSignatureContent] = useState([]) // ASSINATURA SALVA
    const [contratacaoAtiva, setContratacaoAtiva] = useState(false) // ASSINATURA SALVA
    const [contratacaoAvisada, setContratacaoAvisada] = useState(false) // ASSINATURA SALVA

    function fechaModalPreviewDepoimento(el) {
        el.preventDefault();

        fecharModal();
        setTimeout(() => { props.setOpenModal(null) }, 1000);
    }

    function validarSenha(e) {
        e.preventDefault();

        if (!senha || !senha.length) {
            toast.error("A senha não pode ser vázia");
        } else { // PASSOU EM TODAS AS VALIDAÇÕES E VAMOS REALIZAR O LOGIN
            auth.signInWithEmailAndPassword(props.user.email, senha)
                .then(async () => {
                    const contractRef = db.collection('orcamentos');
                    const snapshot = await contractRef.where("token", "==", props.openModal.token).get();
                    if (snapshot.empty) {
                        console.log('No matching documents.');
                        return;
                    }
                    console.log(snapshot);
                    snapshot.forEach(doc => {
                        db.collection('orcamentos')
                            .doc(doc.id)
                            .update({ "status": 'aprovado', "aprovacao_orcamento": firebase.firestore.FieldValue.serverTimestamp(), "metodo_pagamento": props.openModal.metodo_pagamento})
                            .then(() => {
                                fechaModalPreviewDepoimento(e);
                                setContratacaoAtiva(true);
                                toast.success('A aprovação do orçamento dos serviços foi realizada com sucesso!');
                                navigate('/minha-conta/meus-orcamentos')
                            })
                            .catch((err) => {
                                setContratacaoAtiva(false);
                                toast.error('Ocorreu um erro');
                                console.log(err)
                            })
                    });
                })
                .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    if (errorCode === 'auth/invalid-email') {
                        toast.error("O endereço de e-mail não é válido")
                    } else if (errorCode === 'auth/user-disabled') {
                        toast.error("O usuário correspondente ao e-mail fornecido foi desabilitado")
                    } else if (errorCode === 'auth/user-not-found') {
                        toast.error('Não houve usuário correspondente ao e-mail fornecido');
                    } else if (errorCode === 'auth/wrong-password') {
                        toast.error("Usuário ou senha inválidos");
                    } else if (errorCode === 'auth/too-many-requests') {
                        toast.error("O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login com falha. Você pode restaurá-la imediatamente redefinindo sua senha ou pode tentar novamente mais tarde.");
                    } else {
                        toast.error(errorMessage);
                    }
                    console.log(error);
                })
        }
    }

    return (
        <div class="popup" style={{ zIndex: 9999, position: 'fixed', maxWidth: '566px' }}>
            <div className='header-popup'>
                <span>Confirmar Aprovação de Proposta de Orçamento</span>
                <a class="close-popup" onClick={(el) => fechaModalPreviewDepoimento(el)}><AiOutlineClose /></a>
            </div>
            <div className='wrapper__infos__request p-g-15 m-top-25 m-bot-5'>
                <div className='info__single__request w100 d-fx'>
                    <div className='icon__info__request info-primary left'>
                        <IoIosInformationCircleOutline className='fz-18' />
                    </div>
                    <div className='message__info__request w100 m-lef-5'>
                        <p>
                            <span className='fz-13' style={{ fontWeight: '300' }}>
                                <strong className='fz-13'>Atenção</strong>: Ao confirmar a aprovação desta proposta de orçamento seu contrato será desenvolvido e disponibilizado em até 5 dias úteis.
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="content">
                <div className='box-password-contract'>
                    <div className='box-input-single m-top-20'>
                        <label>
                            <div className='title-input d-fx d-al-cen p-rlt'>
                                <strong className='fz-12 m-rig-5'>Digite sua senha do site</strong>
                                <strong className='icon-info-add p-rlt'>
                                    <IoIosInformationCircleOutline />
                                    <div className='helper-input tx-black d-nn'>
                                        <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                            <strong class="css-1i1x8nz">
                                                Seu CPF será usado para:
                                            </strong>
                                            <div class="css-1l7kih-stack">
                                                <div>
                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                        <div>
                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                        </div>
                                                        <div>
                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin fz-13">Te identificar</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                        <div>
                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                        </div>
                                                        <div>
                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin fz-13">Acessar sua conta</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                        <div>
                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                        </div>
                                                        <div>
                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin fz-13">Consultar pedidos</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                        <div>
                                                            <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                        </div>
                                                        <div>
                                                            <p class="css-1dwd66e-text-text--kilo-heading--no-margin fz-13">Realizar transações financeiras</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                        </div>
                                    </div>{/*Helper*/}
                                </strong>
                            </div>
                        </label>
                        <input id="nome-cadastro-singup" className={(senha && !senhaValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="senha" placeholder='Sua senha' value={senha} onChange={(e) => setSenha(e.target.value)} />
                        {
                            (senhaValid)
                                ?
                                senhaValid.map((infoStatus) => {
                                    if (infoStatus.status == 'error') {
                                        return (
                                            <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                <strong className='icon-invalid'><TiInfo /></strong>
                                                <strong className='text-invalid m-lef-5'>{infoStatus.message}</strong>
                                            </div>
                                        )
                                    } else if (infoStatus.status == 'success') {
                                        return (
                                            <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                <strong className='icon-valid'><TiInfo /></strong>
                                                <strong className='text-valid m-lef-5'>{infoStatus.message}</strong>
                                            </div>
                                        )
                                    }
                                })
                                : null
                        }
                    </div>{/*box-input-single */}
                </div>
            </div>
            <div className='w60 right tx-rig'>
                <button class="button__payment" onClick={(e) => validarSenha(e)}>
                    <span class="button__payment__text">
                        <span className='fz-12' style={{ textTransform: 'uppercase', verticalAlign: 'middle', fontSize: '12px', fontWeight: 'lighter' }}>Aprovar Orçamento</span>
                    </span>
                    <svg class="button__payment__svg" role="presentational" viewBox="0 0 600 600">
                        <defs>
                            <clipPath id="myClip">
                                <rect x="0" y="0" width="100%" height="50%" />
                            </clipPath>
                        </defs>
                        <g clip-path="url(#myClip)">
                            <g id="money">
                                <path d="M441.9,116.54h-162c-4.66,0-8.49,4.34-8.62,9.83l.85,278.17,178.37,2V126.37C450.38,120.89,446.56,116.52,441.9,116.54Z" fill="#699e64" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                                <path d="M424.73,165.49c-10-2.53-17.38-12-17.68-24H316.44c-.09,11.58-7,21.53-16.62,23.94-3.24.92-5.54,4.29-5.62,8.21V376.54H430.1V173.71C430.15,169.83,427.93,166.43,424.73,165.49Z" fill="#699e64" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                            </g>
                            <g id="creditcard">
                                <path d="M372.12,181.59H210.9c-4.64,0-8.45,4.34-8.58,9.83l.85,278.17,177.49,2V191.42C380.55,185.94,376.75,181.57,372.12,181.59Z" fill="#a76fe2" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                                <path d="M347.55,261.85H332.22c-3.73,0-6.76-3.58-6.76-8v-35.2c0-4.42,3-8,6.76-8h15.33c3.73,0,6.76,3.58,6.76,8v35.2C354.31,258.27,351.28,261.85,347.55,261.85Z" fill="#ffdc67" />
                                <path d="M249.73,183.76h28.85v274.8H249.73Z" fill="#323c44" />
                            </g>
                        </g>
                        <g id="wallet">
                            <path d="M478,288.23h-337A28.93,28.93,0,0,0,112,317.14V546.2a29,29,0,0,0,28.94,28.95H478a29,29,0,0,0,28.95-28.94h0v-229A29,29,0,0,0,478,288.23Z" fill="#a4bdc1" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                            <path d="M512.83,382.71H416.71a28.93,28.93,0,0,0-28.95,28.94h0V467.8a29,29,0,0,0,28.95,28.95h96.12a19.31,19.31,0,0,0,19.3-19.3V402a19.3,19.3,0,0,0-19.3-19.3Z" fill="#a4bdc1" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                            <path d="M451.46,435.79v7.88a14.48,14.48,0,1,1-29,0v-7.9a14.48,14.48,0,0,1,29,0Z" fill="#a4bdc1" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                            <path d="M147.87,541.93V320.84c-.05-13.2,8.25-21.51,21.62-24.27a42.71,42.71,0,0,1,7.14-1.32l-29.36-.63a67.77,67.77,0,0,0-9.13.45c-13.37,2.75-20.32,12.57-20.27,25.77l.38,221.24c-1.57,15.44,8.15,27.08,25.34,26.1l33-.19c-15.9,0-28.78-10.58-28.76-25.93Z" fill="#7b8f91" />
                            <path d="M148.16,343.22a6,6,0,0,0-6,6v92a6,6,0,0,0,12,0v-92A6,6,0,0,0,148.16,343.22Z" fill="#323c44" />
                        </g>
                    </svg>
                </button>
            </div>
            <div className='clear'></div>
        </div>
    )
}

export default ModalAprovarOrcamento;