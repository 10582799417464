// TODO: MONTAR FORMULÁRIO PARA CRIAÇÃO DE CONTA PARA O CLIENTE

import '../../../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../../../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiNotebook } from 'react-icons/gi';
import { AiOutlineSearch, AiFillEdit, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdHomeFilled, MdHomeWork } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaMoneyBill } from 'react-icons/fa';

import { BsCreditCard2Front, BsTrash } from 'react-icons/bs';
import { ImQrcode } from 'react-icons/im';
import { GiSave } from 'react-icons/gi';
import { FcMoneyTransfer } from 'react-icons/fc';
import { RiHomeGearLine } from 'react-icons/ri';
import { FaBarcode, FaApplePay, FaGooglePay, FaStar } from 'react-icons/fa';


import contractOkay from '../../../../../../../../../../images/icones/svg/Construction costs-pana.svg';
import searchFolder from '../../../../../../../../../../images/icones/svg/search-folder.svg';
import searchDocument from '../../../../../../../../../../images/icones/svg/search-document.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';

function BudgetForm_enderecoServico(props) {

    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');


    const [orcamentos, setOrcamentos] = useState([]);

    // DADOS DO FORMULÁRIO
    const [usuarioCadastrado, setUsuarioCadastrado] = useState(false); // VERIFICAR SE O USARIO ESTÁ CADASTRADO NO SITE ? ABRIR SELEÇÃO DE USUARIO : ABRIR MODAL DE CADASTRAMENTO DE USUARIO

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user) && props.user.representante) {
            db.collection("orcamentos")
                .where("numero_representante_contratado", "==", props.user.token_representante)
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })

            // console.log(orcamentos)
        }
    }, [])

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }

    // FUNÇÕES DE SELECT PERSONALIZADO - CATEGORIAS
    const [novaCategoriaStatus, setNovaCategoriaStatus] = useState(false);
    const [novaCategoria, setNovaCategoria] = useState([]);

    const [toggleOpenOptions, setToggleOpenOptions] = useState(false);


    // FUNÇÕES DE SELECT PERSONALIZADO - TIPOS
    const [novoTipoStatus, setNovoTipoStatus] = useState(false);
    const [novoTipo, setNovoTipo] = useState([]);

    const [toggleOpenOptionsTipo, setToggleOpenOptionsTipo] = useState(false);

    function typingSearchCategoria(e) {
        filterListCategoria(e.target.value);
    };

    const filterListCategoria = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.categoria").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function typingSearchTipo(e) {
        filterListTipo(e.target.value);
    };

    const filterListTipo = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.tipo").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function convertStringToSlug(string) {
        string = string.replace(/^\s+|\s+$/g, '')
        string = string.toLowerCase()

        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'

        for (let i = 0, l = from.length; i < l; i++) {
            string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        string = string.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')

        return string
    }

    function definirNovaCategoria(val) {
        setNovaCategoria({
            categoria: val,
            slug_categoria: convertStringToSlug(val),
            campo: "categoria-orcamento"
        });
    }

    function definirNovoTipo(val) {
        setNovoTipo({
            tipo: val,
            slug_tipo: convertStringToSlug(val),
            campo: "tipo-orcamento"
        });
    }

    // FUNÇÕES INICIAIS - RECUPERAÇÃO DE CATEGORIAS
    const [categorias, setCategorias] = useState([]);
    const [tipos, setTipos] = useState([]);

    useMemo(async () => {
        db.collection("arquivos")
            .doc("documentos")
            .collection("complementos")


        const ref = db.collection('arquivos').doc("documentos").collection("complementos");
        const snapshot = await ref.get();
        if (snapshot.empty) {
            toast.error('Não foi possivel recuperar as categorias de orçamentos.');
            return;
        }

        var contentCategorias = ([]);
        var contentTipos = ([]);

        snapshot.forEach(doc => {
            if (doc.data().campo === 'categoria-orcamento') {
                contentCategorias.unshift(doc.data());
            } else if (doc.data().campo === 'tipo-orcamento') {
                contentTipos.unshift(doc.data());
            }
        });

        setCategorias(contentCategorias);
        setTipos(contentTipos);
    }, []);



    // AVANÇAR PROGRESSO DE CRIAÇÃO DE ORÇAMENTO
    const [saveCategoriaStatus, setSaveCategoriaStatus] = useState(false);

    const [saveTipoStatus, setSaveTipoStatus] = useState(false);


    // FORMATAR CPF / CPNJ CO CLIENTE
    function formatarCPFCNPJ(val) {
        setDadosCliente([]);

        var cpf_cnpj = val.target.value.toString();
        var cpf_cnpj_clear = val.target.value.replace(/[^0-9]/g, "");
        if (cpf_cnpj_clear.length <= 14) {
            if (cpf_cnpj_clear.length && cpf_cnpj_clear.length > 11) {
                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
                props.setBudgetCPFCNPJCliente(new_cnpj);
            } else {
                var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                props.setBudgetCPFCNPJCliente(new_cnpj);
            }
        }
    }

    // FORMATAR RG CO CLIENTE
    function formatarRG(val) {
        var rg = val.target.value.toString();
        var rg_clear = val.target.value.replace(/[^0-9]/g, "");
        if (rg_clear.length) {
            var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
            props.setBudgetRGCliente(new_rg);
        }
    }

    // BUSCAR CLIENTE POR CPF OU CNPJ
    const [dadosCliente, setDadosCliente] = useState([]);
    const [tokenCliente, setTokenCliente] = useState('');

    async function buscarCliente(e) {
        e.preventDefault();

        var cpf_cnpj_clear = props.budgetCPFCNPJCliente.replace(/[^0-9]/g, "");
        if (cpf_cnpj_clear.length > 0 && cpf_cnpj_clear.length < 15) {
            const ref = db.collection('usuarios');
            const snapshot = await ref.where('cpf', '==', cpf_cnpj_clear).get();
            if (snapshot.empty) {
                console.log('No matching documents.');
                setDadosCliente([]);
                return;
            }
            var dataCliente = ([]);
            var idCliente = ([]);
            snapshot.forEach(doc => {
                dataCliente.unshift(doc.data());
                idCliente.unshift(doc.id);
            });
            setTokenCliente(idCliente);
            setDadosCliente(dataCliente);
        }
    }

    // EDITAR RG DO CLIENTE
    const [editRGStatus, setEditRGStatus] = useState(false);

    async function salvarRGCliente(e, token) {
        e.preventDefault();

        if (props.budgetRGCliente && props.budgetRGCliente.length) {
            var new_rg = props.budgetRGCliente.replace(/[^0-9]/g, "");


            const ref = db.collection('usuarios');
            const snapshot = await ref.where('rg', '==', new_rg).get();
            if (snapshot.empty) {
                db.collection("usuarios")
                    .doc(token)
                    .update("rg", new_rg)
                    .then(() => {
                        dadosCliente[0].rg = new_rg;
                        setEditRGStatus(false);
                        toast.success("RG Cadastrado com Sucesso!")
                    })
                    .catch(() => {
                        toast.error("Ocorreu um erro ao slavar o RG");
                    })
            } else {
                toast.error("O RG informado já está em uso");
            }
        }
    }

    // EDITAR ENDEREÇO DO CLIENTE
    const [editEnderecoStatus, setEditEnderecoStatus] = useState(false);



    // FUNÇOÕES DO ENDEREÇO (ENDERECOS)
    function abrirModalAddress(e) {
        let modalAdress = $('#modal_cadastro__endereco'); // BG
        let modalContentAdress = $("#modal_content_endereco"); // CONTENT
        modalAdress.slideToggle(500);
        setTimeout(() => {
            modalContentAdress.slideDown();
        }, 500);
    }

    function fecharModalAddress(e) {
        let modalPhone = $('#modal_cadastro__endereco'); // BG
        let modalContent = $("#modal_cadastro__endereco .modal-content"); // CONTENT
        modalContent.slideUp(500);
        setTimeout(() => {
            modalPhone.slideToggle();
            document.getElementById('form_address_modal').reset();
        }, 500);
    }

    // SELECIONAR CLIENTE
    function selecionarCliente(e, val) {
        e.preventDefault();

        props.setClienteSelecionado(val);

        setDadosCliente([]);
        props.setBudgetCPFCNPJCliente([]);
        console.log(props.clienteSelecionado)
    }

    function aproveitarEndereco() {
        props.setBudgetEnderecoServicoStatus(true);
        props.setBudgetEnderecoServico(props.budgetEnderecoCliente);
    }

    function novoEndereco() {
        props.setBudgetEnderecoServicoStatus(false);
        props.setBudgetEnderecoServico([]);
        props.setOpenModal({ status: true, tipo: "cadastrar-endereco", categoria: "endereco_servico" })
    }
    return (
        <div id="budget_form_cliente">
            <div className='card__info__budget__single'>
                <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                    <h2 className='b-bot-medium'>Cadastro de Endereço do Serviço</h2>
                    <p className='fz-14'>Preencha as infomações do cliente a seguir para prosseguir com a criação do novo orçamento.</p>
                </div>
                <div className='wrapper__form__criar__orcamento'>
                    <form className='form__criar__orcamento'>
                        <div className='box-input-single m-top-15'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Utilizar mesmo endereço do cliente?</span>
                                </div>
                            </label>
                            <div className='termos-news-single d-fx d-ju-sp-ev d-fw-wp w100 m-top-10'>

                                <div className='wrapper__infos__request p-g-15 m-bot-5'>
                                    <div className='info__single__request w100 d-fx'>
                                        <div className='icon__info__request info-primary left'>
                                            <IoIosInformationCircleOutline className='fz-18' />
                                        </div>
                                        <div className='message__info__request w100 left m-lef-5'>
                                            <p>
                                                <span className='fz-13'>
                                                    {
                                                        (props.budgetEnderecoCliente && !isEmptyObject(props.budgetEnderecoCliente))
                                                            ?
                                                            <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                <strong>Endereço:</strong> {`${props.budgetEnderecoCliente.logradouro}, ${props.budgetEnderecoCliente.numero}, ${props.budgetEnderecoCliente.bairro} - ${props.budgetEnderecoCliente.cidade}, - ${props.budgetEnderecoCliente.estado}, CEP ${(() => {
                                                                    var new_cep = props.budgetEnderecoCliente.cep.toString();
                                                                    return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                })()}`}
                                                            </span>
                                                            : null
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                        <div className='clear'></div>
                                    </div>
                                </div>
                                <div className='canais-single w100 m-top-10 m-bot-10' onClick={() => aproveitarEndereco()}>
                                    <input id='vendas-projeto-favorito-positivo' type='radio' name="projeto_favorito" value="sim" checked={(props.budgetEnderecoServicoStatus) ? true : false} />
                                    <label for="vendas-projeto-favorito-positivo">Sim</label>
                                </div>
                                <div className='canais-single w100 m-top-10 m-bot-10' onClick={() => novoEndereco()}>
                                    <input id='vendas-projeto-favorito-negativo' type='radio' name="projeto_favorito" value="nao" checked={(props.budgetEnderecoServicoStatus) ? false : true} />
                                    <label for="vendas-projeto-favorito-negativo">Não</label>
                                </div>
                            </div>{/*termos-news-single*/}
                        </div>{/*box-input-single */}

                        {
                            (props.budgetEnderecoServico && !isEmptyObject(props.budgetEnderecoServico))
                                ?
                                <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-10 p-g-15 w100' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                    <div className='p-rlt b-bot-medium w100'>
                                        <div className='d-fx d-al-cen m-bot-10 d-ju-cen'>
                                            <h2 className='fz-18 tx-cen'>Endereço do Serviço</h2>
                                        </div>
                                    </div>
                                    <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                        <strong>Endereço:</strong> {`${props.budgetEnderecoServico.logradouro}, ${props.budgetEnderecoServico.numero}, ${props.budgetEnderecoServico.bairro} - ${props.budgetEnderecoServico.cidade}, - ${props.budgetEnderecoServico.estado}, CEP ${(() => {
                                            var new_cep = props.budgetEnderecoServico.cep;
                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                        })()}`}
                                    </span>
                                </div>
                                : null
                        }
                    </form>
                </div>
            </div>

            <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                <button
                    className="cart"
                    style={{ marginTop: 0, textTransform: 'uppercase' }}
                    onClick={() => {
                        toast.success("Endereço do serviço salvo com sucesso!");
                        props.setSlideCard(props.slideCard + 1);
                    }}
                    disabled={(() => {
                        if (!props.budgetEnderecoServico) {
                            return true;
                        } else if (isEmptyObject(props.budgetEnderecoServico)) {
                            return true;
                        } else {
                            return false;
                        }
                    })()}
                >
                    <span className=' fz-12'>Próximo</span>
                </button>
            </div>
        </div>
    );

}

export default BudgetForm_enderecoServico;