import { Link, useLocation, useParams } from 'react-router-dom'; // IMPORTAÇÃO DE REACT ROUTER
import '../Assets/styles/css/Main.css';
import { useEffect, useState, useRef, useMemo } from 'react';
import Helmet from 'react-helmet'; // IMPORTAÇÃO DE MANIPULADOR DE TITLE

// IMPORTAÇÃO DE IMAGENS
import addCart from '../../../../images/icones/gif/Add to Cart.gif';
import emptyCart from '../../../../images/icones/svg/empty-cart.svg';
import iconSuport from '../../../../images/icones/png/icon-suport.png';
import iconLove from '../../../../images/icones/png/icon-love.png';
import iconAlert from '../../../../images/icones/png/icon-alert.png';
import atendimento from '../../../../images/projetos/jpg/atendimento.jpg';

// IMPORTAÇÃO DE ICONES
import { HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { AiOutlineMail, AiOutlinePhone, AiOutlineClockCircle } from 'react-icons/ai';
import { BsChevronRight, BsCalendarRange } from 'react-icons/bs';
import { BiMapPin } from 'react-icons/bi';





import { AiOutlineCheck, AiFillInfoCircle } from 'react-icons/ai';
import { FaEye, FaHeart, FaShareSquare, FaStar } from 'react-icons/fa';

// IMPORTAÇÃO DE IMAGENS
import whats from '../../../../images/icones/svg/whats.svg';
import slide from '../../../../images/projetos/png/slide04.png';
import promo from '../../../../images/projetos/png/Projetos Prontos.png';
import home from '../../../../images/projetos/png/home1.png';
import promo2 from '../../../../images/projetos/jpg/promo.jpg';
import promoBox from '../../../../images/projetos/jpg/promo_380x400.jpg';

// IMPORTAÇÃO DE CARROSEL DE IMAGENS
// ESTILO - CARROSEL
// import 'react-multi-carousel/lib/styles.css';
// FUNÇÃO - CARROSSEL
// import Carousel from 'react-multi-carousel';



function ComingSoon(props) {
    
    const toggleDrawer = () => {
        props.setIsOpen((prevState) => !prevState);
    }

    const [data,setData] = useState([]);
	// const carousel = useRef(null);

    const [invalid, setInvalid] = useState(null);

    let urlLocation = useLocation().pathname.replace('/','');

    let { id } = useParams();

    // HOOKS - NOME
    const [name, setName] = useState();
    const [nameMessage, setNameMessage] = useState([]);
    const [nameValid, setNameValid] = useState(false);

    // HOOKS - EMAIL
    const [email, setEmail] = useState();
    const [emailMessage, setEmailMessage] = useState([]);
    const [emailValid, setEmailValid] = useState(false);
    

    function validaFomulario(e) {
        e.preventDefault();

        if (!nameValid) {
            alert('O Nome não é válido!');
        } else if (!emailValid) {
            alert('O e-mail não é válido!');
        }
    }

    useMemo(() => {
        if (name) {
            // VERIFICAÇÃO DE LETRAS EM SENHA
            var padrao = /[^a-zà-ú\s-]/gi;

            var valida_nome = name.match(padrao);

            if (valida_nome) {
                setNameValid(false);
                setNameMessage([{
                    'status': 'error',
                    'message': 'O nome deve conter apenas letras'
                }])
            } else {
                //O NOME É VÁLIDO CONTEM APENAS LETRAS -> VERIFICAR SE O NOME É COMPLETO
                var arrName = name.split(' ');
                if (arrName.length <= 1) {
                    // O USUARIO NÃO PASSOU O NOME COMPLETO
                    setNameValid(false);
                    setNameMessage([{
                        'status': 'error',
                        'message': 'O nome deve ser completo'
                    }])
                } else {
                    // O USUARIO PASSOU O NOME COMPLETO
                    setNameValid(true);
                    setNameMessage([])
                }
            }
        }
    }, [name])

    useMemo(() => {
        if (email && email.length > 0) {
            function IsEmail(email) {
                var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                if (email == '' || !er.test(email)) { return false; }
                else { return true; }
            }
            if (!IsEmail(email)) {
                setEmailValid(false);
                setEmailMessage([{
                    'status': 'error',
                    'message': 'O e-mail informado não é válido'
                }])
            } else {
                // VALIDANDO CADASTRO EXISTENTE
                    var usuariosCadastrados = props.cadastros.filter(produto => produto.email == email);
                    if (usuariosCadastrados.length > 0) {
                        // EXISTE UM email JÁ CADASTRADO NO BANCO DE DADOS -> MOSTRAR MENSAGEM DE ERRO
                        setEmailValid(true);
                        setEmailMessage([{
                            'status': 'error',
                            'message': 'O e-mail informado já está vinculado a uma conta existente'
                        }]);
                    } else {
                        // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
                        setEmailValid(true);
                        setEmailMessage([]);
                    }

            }
        }

    }, [email])
    return (
        <div className='wrapper-singup d-ju-cen' style={(props.responsivoSize && props.responsivoSize > 768) ? {flexDirection: 'row'} : {flexDirection: 'row', padding: '10px'}}>
            <div className='content-singup'>
                <div className='center'>
                    <div className={(props.responsivoSize && props.responsivoSize < 768) ? 'w100 left p-g-25' : 'w50 left p-g-25'} style={{ background: 'rgb(255 255 255 / 95%)', borderRadius: 8, border: '1px solid #ddd' }}>
                        <h2 className='m-bot-20'>Em breve</h2>
                        <p className="tx-lig fz-14 tx-grey" style={{lineHeight: '1.3'}}>
                            A página 
                            <b style={{textTransform: 'capitalize', margin: '0 3px'}}>
                            {
                                (()=>{
                                    if (id) {
                                        return id
                                    } else if (props.commingSoon) {
                                        return props.commingSoon;
                                    }
                                })()
                            }
                            </b>
                             está sendo desenvolvida por nossa equipe e será disponibilizada muito em breve, caso deseje, preencha o formulário abaixo e entraremos em contato para avisa-lo sobre o lançamento desta página.
                        </p>
                        <div className='box-forms-singup'>
                            <form id="form__created__account__person" className='singup-person'>

                                <div className='box-input-single m-top-20'>
                                    <label>
                                        <div className='title-input d-fx d-al-cen'>
                                            <span className='fz-12 m-rig-5'>Nome Completo</span>
                                            <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                        </div>
                                        <div className='helper-input d-nn'>
                                            <h3>Seu CPF será usado para:</h3>
                                            <ul>
                                                <li>
                                                    Te Identificar
                                                </li>
                                                <li>
                                                    Te Identificar
                                                </li>
                                                <li>
                                                    Te Identificar
                                                </li>
                                                <li>
                                                    Te Identificar
                                                </li>
                                            </ul>
                                        </div>{/*Helper*/}
                                    </label>
                                    <input id="nome-cadastro-singup" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                                    {
                                        (nameMessage)
                                            ?
                                            nameMessage.map((infoStatus) => {
                                                if (infoStatus.status == 'error') {
                                                    return (
                                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                            <span className='icon-invalid'><TiInfo /></span>
                                                            <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                        </div>
                                                    )
                                                } else if (infoStatus.status == 'success') {
                                                    return (
                                                        <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                            <span className='icon-valid'><TiInfo /></span>
                                                            <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                        </div>
                                                    )
                                                }
                                            })
                                            : null
                                    }
                                </div>{/*box-input-single */}

                                <div className='box-input-single m-top-20'>
                                    <label>
                                        <div className='title-input d-fx d-al-cen'>
                                            <span className='fz-12 m-rig-5'>E-mail</span>
                                            <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                        </div>
                                        <div className='helper-input d-nn'>
                                            <h3>Seu CPF será usado para:</h3>
                                            <ul>
                                                <li>
                                                    Te Identificar
                                                </li>
                                                <li>
                                                    Te Identificar
                                                </li>
                                                <li>
                                                    Te Identificar
                                                </li>
                                                <li>
                                                    Te Identificar
                                                </li>
                                            </ul>
                                        </div>{/*Helper*/}
                                    </label>
                                    <input id='email-cadastro-singup' className={(email && !emailValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='email' name="email" placeholder='seuemail@email.com' value={email} onChange={(ev) => setEmail(ev.target.value)} />
                                    {
                                        (emailMessage)
                                            ?
                                            emailMessage.map((infoStatus) => {
                                                if (infoStatus.status == 'error') {
                                                    return (
                                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                            <span className='icon-invalid'><TiInfo /></span>
                                                            <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                        </div>
                                                    )
                                                } else if (infoStatus.status == 'success') {
                                                    return (
                                                        <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                            <span className='icon-valid'><TiInfo /></span>
                                                            <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                        </div>
                                                    )
                                                }
                                            })
                                            : null
                                    }
                                </div>{/*box-input-single */}

                                <div className='box-opts-add b-top'>
                                    <div className='termos-news-single d-fx w100 m-top-20'>
                                        <input id='newsletter-cadastro-singup' type='checkbox' name="newsletter" />
                                        <label for="newsletter-cadastro-singup" className='infos-programa m-lef-10' style={{ cursor: 'pointer' }}>
                                            <h4>Receber comunicação da Engeart</h4>
                                            <span className='small'>Escolha em quais canais você prefere receber</span>
                                        </label>
                                    </div>{/*termos-news-single*/}
                                    <div className='termos-news-single d-fx d-ju-sp-ev w100 m-top-20 d-fw-wp'>
                                        <div className='canais-single d-fx d-al-cen m-top-5 m-bot-5'>
                                            <input id='newsletter-email-cadastro-singup' type='checkbox' name="news_email" />
                                            <label for="newsletter-email-cadastro-singup" className='m-lef-5' style={{ cursor: 'pointer' }}>E-mail</label>
                                        </div>
                                        <div className='canais-single d-fx d-al-cen m-top-5 m-bot-5'>
                                            <input id='newsletter-sms-cadastro-singup' type='checkbox' name="news_sms" />
                                            <label for="newsletter-sms-cadastro-singup" className='m-lef-5' style={{ cursor: 'pointer' }}>SMS</label>
                                        </div>
                                        <div className='canais-single d-fx d-al-cen m-top-5 m-bot-5'>
                                            <input id='newsletter-whatsapp-cadastro-singup' type='checkbox' name="news_whats" />
                                            <label for="newsletter-whatsapp-cadastro-singup" className='m-lef-5' style={{ cursor: 'pointer' }}>WhatsApp</label>
                                        </div>
                                    </div>{/*termos-news-single*/}
                                </div>
                                <button class="button button-full button-primary margin-top align-left btn__send__form">
                                    Concordo e quero ser avisado
                                </button>
                                <div className='aviso-termos m-top-10 helper'>
                                    Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;