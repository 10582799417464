import $ from 'jquery';
import toast from 'react-hot-toast';

function abrirModalBlockCopyright() { 
    let modalPhone = $('#modal_copyright'); // BG
    let modalContent = $("#modal_copyright .popup"); // CONTENT
    modalPhone.slideDown(500);
    setTimeout(() => {
        modalContent.slideDown();
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function abrirModalVisualizarDepoimento() { 
    let modalPhone = $('#modal_visualizar_depoimento'); // BG
    let modalContent = $("#modal_visualizar_depoimento .popup"); // CONTENT
    modalPhone.slideDown(500);
    setTimeout(() => {
        modalContent.slideDown();
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function abrirModalPreviewDepoimento() { 
    let modalPhone = $('#modal_depoimento'); // BG
    let modalContent = $("#modal_depoimento .popup"); // CONTENT
    modalPhone.slideDown(500);
    setTimeout(() => {
        modalContent.slideDown();
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function abrirModalLocalizacao() { 
    let modalPhone = $('#modal_compartilhamento_localizacao'); // BG
    let modalContent = $("#modal_compartilhamento_localizacao .popup"); // CONTENT
    modalPhone.slideDown(500);
    setTimeout(() => {
        modalContent.slideDown();
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function abrirModalAssinaturaContrato() { 
    let modalPhone = $('#modal_contrato'); // BG
    let modalContent = $("#modal_contrato .popup"); // CONTENT
    modalPhone.slideDown(500);
    setTimeout(() => {
        modalContent.slideDown();
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function abrirModalAprovarOrcamento() { 
    let modalPhone = $('#modal_orcamento'); // BG
    let modalContent = $("#modal_orcamento .popup"); // CONTENT
    modalPhone.slideDown(500);
    setTimeout(() => {
        modalContent.slideDown();
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function abrirModalCadastrarEndereco() {
    let modalPhone = $('#modal_endereco'); // BG
    let modalContent = $("#modal_endereco .popup"); // CONTENT
    modalPhone.slideDown(500);
    setTimeout(() => {
        modalContent.slideDown();
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function abrirModalAprovarFaseServico() {
    let modalPhone = $('#modal_aprovacao_fase_servico'); // BG
    let modalContent = $("#modal_aprovacao_fase_servico .popup"); // CONTENT
    modalPhone.slideDown(500);
    setTimeout(() => {
        modalContent.slideDown(); 
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function abrirModalRecortarFoto() {
    let modalPhone = $('#modal_edicao_recortar_foto'); // BG
    let modalContent = $("#modal_edicao_recortar_foto .popup"); // CONTENT
    modalPhone.slideDown(500);
    setTimeout(() => {
        modalContent.slideDown();
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function fecharModalLocalizacao() {
    let modalPhone = $('#modal_compartilhamento_localizacao'); // BG
    let modalContent = $("#modal_compartilhamento_localizacao .popup"); // CONTENT
    if (modalPhone.css('display') == 'block') {
        modalContent.slideUp(500);
        setTimeout(() => {
            modalPhone.slideUp();
            // modalContent.currentTarget.classList.toggle('show');
        }, 500);
    }
}

export { fecharModalLocalizacao, abrirModalLocalizacao, abrirModalPreviewDepoimento, abrirModalVisualizarDepoimento, abrirModalBlockCopyright, abrirModalAssinaturaContrato, abrirModalAprovarOrcamento, abrirModalCadastrarEndereco, abrirModalAprovarFaseServico, abrirModalRecortarFoto };