
import { Link, useNavigate } from 'react-router-dom'; // IMPORTAÇÃO DE REACT ROUTER
import '../Assets/styles/css/Main.css';
import { useEffect, useState, useMemo } from 'react';
import Helmet from 'react-helmet'; // IMPORTAÇÃO DE MANIPULADOR DE TITLE
import InputMask from 'react-input-mask';

import Subtotal from './Components/Pagamentos/Subtotal';
import $ from 'jquery'





// IMPORTAÇÃO DE IMAGENS
import addCart from '../../../../images/icones/gif/Add to Cart.gif';
import emptyCart from '../../../../images/icones/svg/empty-cart.svg';
import iconSuport from '../../../../images/icones/png/icon-suport.png';
import iconLove from '../../../../images/icones/png/icon-love.png';
import iconAlert from '../../../../images/icones/png/icon-alert.png';
import atendimento from '../../../../images/projetos/jpg/atendimento.jpg';

// IMPORTAÇÃO DE ICONES
import { HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { AiOutlineMail, AiOutlinePhone, AiOutlineClockCircle, AiOutlineClose } from 'react-icons/ai';
import { BsChevronRight, BsCalendarRange } from 'react-icons/bs';
import { BiMapPin } from 'react-icons/bi';






import { AiOutlineMinusCircle, AiFillInfoCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { FaEye, FaHeart, FaShareSquare, FaStar } from 'react-icons/fa';

// IMPORTAÇÃO DE IMAGENS
import whats from '../../../../images/icones/svg/whats.svg';
import slide from '../../../../images/projetos/png/slide04.png';
import promo from '../../../../images/projetos/png/Projetos Prontos.png';
import home from '../../../../images/projetos/png/home1.png';
import promo2 from '../../../../images/projetos/jpg/promo.jpg';
import promoBox from '../../../../images/projetos/jpg/promo_380x400.jpg';

// IMPORTAÇÃO DE CARROSEL DE IMAGENS
// ESTILO - CARROSEL
// import 'react-multi-carousel/lib/styles.css';
// FUNÇÃO - CARROSSEL
// import Carousel from 'react-multi-carousel';
import { db, auth } from '../../../../firebase';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import firebase from 'firebase';


function Entrega(props) {
    // var uid = auth.currentUser.uid;
    let navigate = useNavigate();

    const toggleDrawer = () => {
        props.setIsOpen((prevState) => !prevState);
    }

    const [telefone, setTelefone] = useState(null);
    const [enderecoSelecionado, setEnderecoSelecionado] = useState(null);

    // HOOKS - TELEFONE
    const [phone, setPhone] = useState('17997117422');
    const [phoneMessage, setPhoneMessage] = useState([]);
    const [phoneValid, setPhoneValid] = useState(false);
    const [phoneDDDValid, setPhoneDDDValid] = useState(false);

    // HOOKS - ENDEREÇO - APELIDO
    const [apelido, setApelido] = useState(null);
    const [apelidoValid, setApelidoValid] = useState(false);

    // HOOKS - ENDEREÇO - CEP
    const [CEP, setCEP] = useState(null);
    const [CEPValid, setCEPValid] = useState(false);

    // HOOKS - ENDEREÇO - TIPO
    const [tipo, setTipo] = useState(null);
    const [tipoValid, setTipoValid] = useState(false);

    // HOOKS - ENDEREÇO - LOGRADOURO
    const [logradouro, setLogradouro] = useState(null);
    const [logradouroValid, setLogradouroValid] = useState(false);

    // HOOKS - ENDEREÇO - NUMERO
    const [numero, setNumero] = useState(null);
    const [numeroValid, setNumeroValid] = useState(false);

    // HOOKS - ENDEREÇO - COMPLEMENTO
    const [complemento, setComplemento] = useState(null);

    // HOOKS - ENDEREÇO - REFERENCIA
    const [referencia, setReferencia] = useState(null);

    // HOOKS - ENDEREÇO - BAIRRO
    const [bairro, setBairro] = useState(null);
    const [bairroValid, setBairroValid] = useState(false);

    // HOOKS - ENDEREÇO - CIDADE
    const [cidade, setCidade] = useState(null);
    const [cidadeValid, setCidadeValid] = useState(false);

    // HOOKS - ENDEREÇO - ESTADO
    const [estado, setEstado] = useState(null);
    const [estadoValid, setEstadoValid] = useState(false);

    // HOOKS - ENDEREÇO - PAIS
    const [pais, setPais] = useState(null);
    const [paisValid, setPaisValid] = useState(false);

    // HOOKS - ENDEREÇO - ADICIONAIS
    const [adicionalPiscina, setAdicionalPiscina] = useState(false);
    const [adicionalJardim, setAdicionalJardim] = useState(false);

    // HOOKS - ENDEREÇO - PRINCIPAL
    const [principal, setPrincipal] = useState(null);

    // HOOKS - ENDEREÇO - COMPRADOR
    const [enderecoComprador, setEnderecoComprador] = useState(null);
    // HOOKS - ENDEREÇO - COMPRADOR - FIREBASE
    const [enderecosExistentes, setEnderecosExistentes] = useState([]);

    let estadosBrasil = [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
    ]

    let logradourosTipo = [
        "Aeroporto",
        "Alameda",
        "Área",
        "Avenida",
        "Campo",
        "Chácara",
        "Colônia",
        "Condomínio",
        "Conjunto",
        "Distrito",
        "Esplanada",
        "Estação",
        "Estrada",
        "Favela",
        "Fazenda",
        "Feira",
        "Jardim",
        "Ladeira",
        "Lago",
        "Lagoa",
        "Largo",
        "Loteamento",
        "Morro",
        "Núcleo",
        "Parque",
        "Passarela",
        "Pátio",
        "Praça",
        "Quadra",
        "Recanto",
        "Residencial",
        "Rodovia",
        "Rua",
        "Setor",
        "Sítio",
        "Travessa",
        "Trecho",
        "Trevo",
        "Vale",
        "Vereda",
        "Via",
        "Viaduto",
        "Viela",
        "Vila",
    ]

    useEffect(() => {

        auth.onAuthStateChanged((val) => {
            db.collection("usuarios").doc(val.uid).collection("dados").doc("contato").collection("enderecos").orderBy("criado", "desc").onSnapshot((todosEnderecos) => {
                var arrEnderecos = ([]);
                todosEnderecos.docs.map((dadosEnderecos) => {
                    arrEnderecos.unshift(dadosEnderecos.data());
                })
                setEnderecosExistentes(arrEnderecos);
                console.log(arrEnderecos)
            })
        })
    }, [props.user])

    useMemo(() => {
        if (phone) {
            var phoneDDD = phone.replace(/([()])/g, '').replace("_", '').split(" ");

            if (phoneDDD[0].length == 2) { // VERIFICAR EXISTENCIA DO DDD
                function getStateFromDDD(ddd) {

                    let ddds = {
                        "AC": ["68"],
                        "AL": ["82"],
                        "AM": ["92", "97"],
                        "AP": ["96"],
                        "BA": ["71", "73", "74", "75", "77"],
                        "CE": ["85", "88"],
                        "DF": ["61"],
                        "ES": ["27", "28"],
                        "GO": ["62", "64"],
                        "MA": ["98", "99"],
                        "MG": ["31", "32", "33", "34", "35", "37", "38"],
                        "MS": ["67"],
                        "MT": ["65", "66"],
                        "PA": ["91", "93", "94"],
                        "PB": ["83"],
                        "PE": ["81", "87"],
                        "PI": ["86", "89"],
                        "PR": ["41", "42", "43", "44", "45", "46"],
                        "RJ": ["21", "22", "24"],
                        "RN": ["84"],
                        "RO": ["69"],
                        "RR": ["95"],
                        "RS": ["51", "53", "54", "55"],
                        "SC": ["47", "48", "49"],
                        "SE": ["79"],
                        "SP": ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        "TO": ["63"]
                    }

                    for (let index in ddds) {
                        if (ddds[index].includes(ddd)) {
                            return true;
                        }
                    }
                }

                var retornoDDD = getStateFromDDD(phoneDDD[0]);
                console.log(retornoDDD);

                if (retornoDDD) {
                    setPhoneDDDValid(true)
                    setPhoneValid(true);
                } else {
                    setPhoneDDDValid(false)
                    setPhoneValid(false);
                }
            }

            var padrao = /(\d+)| /g;

            if (!phone.match(padrao)) {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([{
                    'status': 'error',
                    'message': 'O telefone deve conter apenas números'
                }]);
            } else {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([]);
            }
        }
    }, [phone])

    useMemo(() => {
        if (CEP && CEP.length > 0) {
            var cep = CEP;
            cep = cep.replace(/\.|\-|\_/g, '');
            if (cep.length >= 8) {
                // cep = parseInt(cep);
                axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(data => {
                    console.log(data.data);


                    if (data.data.erro == "true") {
                        setCEPValid(false);
                        console.log(data)
                        // TODO: CONTINUAR A VALIDAÇÃO PARA MOSTRAR QUE O CEP NÃO É VALIDO
                    } else {
                        if (data && data.data && data.data.uf && data.data.uf.length > 0) {
                            setEstado(data.data.uf);
                            document.querySelector(".selected__option.estado").innerHTML = data.data.uf;
                        } else {
                            setEstado("");
                            document.querySelector(".selected__option.estado").innerHTML = "Selecione o estado";
                        }
                        if (data && data.data && data.data.localidade && data.data.localidade.length > 0) {
                            setCidade(data.data.localidade);
                        } else {
                            setCidade("");
                        }
                        if (data && data.data && data.data.bairro && data.data.bairro.length > 0) {
                            setBairro(data.data.bairro);
                        } else {
                            setBairro("");
                        }
                        if (data && data.data && data.data.complemento && data.data.complemento.length > 0) {
                            setComplemento(data.data.complemento);
                        } else {
                            setComplemento("");
                        }
                        if (data && data.data && data.data.logradouro && data.data.logradouro.length > 0) {
                            setLogradouro(data.data.logradouro); // RECUPERAÇÃO DE LOGRADOURO
                            // RECUPERAÇÃO DE TIPO DE LOGRADOURO
                            var tipoLogradouro = data.data.logradouro.split(" ");
                            setTipo(tipoLogradouro[0]);
                            document.querySelector(".selected__option.tipo").innerHTML = tipoLogradouro[0];
                        } else {
                            setLogradouro("");
                            setTipo("");
                            document.querySelector(".selected__option.tipo").innerHTML = "Selecione o tipo";
                        }

                        setCEPValid(true);
                    }
                })
            }
        }
    }, [CEP])

    function validatePhone() {
        let newPhoneClear = phone.replace(/[^\d]+/g, '');
        if (newPhoneClear.length < 11) {
            setPhoneValid(false);
            setPhoneMessage([{
                'status': 'error',
                'message': 'O telefone deve conter o DDD'
            }]);
        } else {
            setPhoneValid(true);
            setPhoneMessage([]);
        }
    }

    // FUNÇOÕES DO ENDEREÇO (ENDERECOS)
    function abrirModalAddress(e) {
        let modalAdress = $('#modal_cadastro__endereco'); // BG
        let modalContentAdress = $("#modal_content_endereco"); // CONTENT
        modalAdress.slideToggle(500);
        setTimeout(() => {
            modalContentAdress.slideDown();
        }, 500);
    }

    function fecharModalAddress(e) {
        let modalPhone = $('#modal_cadastro__endereco'); // BG
        let modalContent = $("#modal_cadastro__endereco .modal-content"); // CONTENT
        modalContent.slideUp(500);
        setTimeout(() => {
            modalPhone.slideToggle();
            document.getElementById('form_address_modal').reset();
        }, 500);
    }

    // FUNÇÕES DO SELECT
    const optionsList = document.querySelectorAll(".option");

    function selectedMain(val) {
        // console.log(document.querySelector(".options-container"))
        document.querySelector(".options-container." + val).classList.toggle("active");
        document.querySelector("." + val + ".search-box input").value = "";
        filterList("");

        if (document.querySelector(".options-container." + val).classList.contains("active")) {
            document.querySelector("." + val + ".search-box input").focus();
        }
    };
    function selectedOption(o, val, tipoLog) {
        if (val == 'tipo') {
            setTipo(tipoLog);
            setTipoValid(true);
        }
        if (val == 'estado') {
            setEstado(tipoLog);
            setEstadoValid(true);
        }
        document.querySelector(".selected__option." + val).innerHTML = o.target.querySelector("label").innerHTML;
        document.querySelector(".options-container." + val).classList.remove("active");
    };
    function selectedOptionSon(o, val, tipoLog) {
        document.querySelector(".selected__option." + val).innerHTML = o.target.parentNode.querySelector("label").innerHTML;
        document.querySelector(".options-container." + val).classList.remove("active");
        setTipo(tipoLog);
    };

    function typingSearch(e) {
        filterList(e.target.value);
    };

    const filterList = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        optionsList.forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function checarApelido() {

    }

    function cadastrarEndereco(e) {
        e.preventDefault();

        if (!apelido || !apelido.length) {
            setApelidoValid(false);
            toast.error("O apelido não pode ser vázio");
        } else if (!CEP || !CEPValid) {
            setCEPValid(false);
            toast.error("O CEP não pode ser vázio e deve ser válido");
        } else if (!tipo || !tipo.length) {
            setTipoValid(false);
            toast.error("O tipo de endereço não pode ser vázio");
        } else if (!logradouro || !logradouro.length) {
            setLogradouroValid(false);
            toast.error("O endereço não pode ser vázio");
        } else if (!numero || !numero.length) {
            setNumeroValid(false);
            toast.error("O número não pode ser vázio");
        } else if (!bairro || !bairro.length) {
            setBairroValid(false);
            toast.error("O bairro não pode ser vázio");
        } else if (!cidade || !cidade.length) {
            setCidadeValid(false);
            toast.error("A cidade não pode ser vázia");
        } else if (!estado || !estado.length) {
            setEstadoValid(false);
            toast.error("O estado não pode ser vázio");
        } else {
            // TODOS OS DADOS FORAM INFORMADOS CORRETAMENTE
            // alert("Enviar dados para firebase")
            let enderecoComprador = {
            }

            auth.onAuthStateChanged((val) => {
                db.collection("usuarios")
                    .doc(val.uid)
                    .collection("dados")
                    .doc("contato")
                    .collection("enderecos")
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            if (doc.data().principal == true) {
                                doc.ref.update({
                                    principal: false
                                })
                            }
                        })
                    })
                    .then(() => {
                        db.collection("usuarios").doc(val.uid).collection("dados").doc("contato").collection("enderecos").add({
                            "apelido": apelido,
                            "cep": CEP.replace(".", ""),
                            "tipo_logradouro": tipo,
                            "logradouro": logradouro,
                            "numero": numero,
                            "complemento": complemento,
                            "referencia": referencia,
                            "bairro": bairro,
                            "cidade": cidade,
                            "estado": estado,
                            "adicionais": {
                                "piscina": adicionalPiscina,
                                "jardim": adicionalJardim
                            },
                            "principal": principal,
                            "criado": firebase.firestore.FieldValue.serverTimestamp()
                        }).then(() => {
                            toast.success("Novo endereço cadastrado com sucesso!");
                        }).then(() => {
                            fecharModalAddress()
                        })
                    })
            })
            // db.collection("usuarios").doc(props.user.id).collection("dados").doc("contato").collection("enderecos")
            //     .where("principal", "==", true).update({
            //         principal: false
            //     }).then(() => {
            //     })
            // setEnderecoComprador(enderecoComprador);

        }
    }

    function salvarDadosComprador(el) {
        el.preventDefault();
        if (!enderecoSelecionado && !enderecoSelecionado.length) {
            toast.error("O endereço precisa ser selecionado para prosseguir para o pagamento!")
        } else if (!phone && !phoneValid) {
            toast.error("O telefone precisa ser válido para prosseguir para o pagamento!");
        } else if (!phoneDDDValid) {
            toast.error("O DDD do telefone precisa ser válido para prosseguir para o pagamento!");
        } else {
            props.setEnderecoComprador(enderecoSelecionado);
            props.setTelefoneComprador(phone)
            navigate("/pedido/pagamento");
        }
    }
    return (
        <div id='entrega' className={(props.responsivoSize && (props.responsivoSize < 1200 && props.responsivoSize > 768)) ? 'p-lef-10 p-rig-10' : null}>
            <Helmet title="Engeart • Meu Pedido • Entrega" />
            <div className='projeto-single-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/carrinho">Carrinho</Link>
                                        </li>
                                        <li className="breadcrumb-item active m-0">Entrega</li>
                                    </ol>
                                </nav>
                            </div>
                            <section className='p-g-20 m-top-10 d-fx d-al-cen d-ju-sp-bt w70'>
                                <div className='content-principal'>
                                    {
                                        (props.user)
                                            ?
                                            <h1 className='fz-30 m-bot-10 tx-lig' style={{ color: '#000' }}>Olá, <span style={{ textTransform: 'uppercase' }}>{props.user.nome}</span></h1>
                                            :
                                            <h1 className='fz-30 m-bot-10 tx-lig' style={{ color: '#000' }}>Olá</h1>
                                    }
                                    <p className='fz-20'>Onde você quer receber sua compra?</p>
                                </div>
                            </section>

                            <section className={(props.responsivoSize && props.responsivoSize > 768) ? 'd-fx m-top-20' : 'd-fx d-fw-wp'} style={(props.responsivoSize && props.responsivoSize < 768) ? { padding: '1.563rem 1rem 1.563rem 1rem' } : null}>
                                <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'content-title p-g-20 w70' : 'content-title p-g-20 w100'}>
                                    <div className='contato__endereco'>
                                        <div className='dados__contento__principal w100 b-bot'>
                                            {
                                                (enderecosExistentes)
                                                    ?
                                                    enderecosExistentes.map((dadosEndereco, index) => {
                                                        return (


                                                            <div key={index} className="input-button selecao__enderecos__checkout radio-item d-fx d-al-cen p-rlt w100 m-g-5" style={{ padding: 0 }}>
                                                                <input type="radio" name="address-selected" id={"address-selected-" + index} value={dadosEndereco} data-validate="radio"
                                                                    onClick={
                                                                        (e) => {
                                                                            $(".selecao__enderecos__checkout.active").removeClass("active")
                                                                            $(e.target).closest("div").addClass("active")
                                                                            setEnderecoSelecionado([dadosEndereco])
                                                                        }
                                                                    }
                                                                    style={(props.responsivoSize && props.responsivoSize > 220) ? { transform: 'translateY(-50%)' } : { opacity: 0 }}
                                                                />
                                                                <label for={"address-selected-" + index} className="label d-al-cen" style={(props.responsivoSize && props.responsivoSize > 220) ? { textTransform: "uppercase", display: 'flex', border: 'none', background: 'none' } : { textTransform: "uppercase", display: 'flex', border: 'none', background: 'none', padding: '5px', margin: 0 }}>
                                                                    {dadosEndereco.apelido} • {dadosEndereco.tipo_logradouro} {dadosEndereco.logradouro},  {dadosEndereco.cidade} - {dadosEndereco.estado}

                                                                    {
                                                                        (props.responsivoSize && props.responsivoSize > 650 && dadosEndereco.principal)
                                                                            ?
                                                                            <div className='p-abs bookmarks_infos d-fx d-al-cen' style={{ right: '5px' }}>
                                                                                <span className='fz-11 tx-grey' style={{ background: 'rgba(225,225,225,.5)', padding: '3px 10px 3px 15px', borderRadius: '20px', textTransform: "capitalize" }}>Principal</span>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </label>
                                                            </div>
                                                        )
                                                    })
                                                    : null
                                            }
                                            {
                                                (enderecoSelecionado)
                                                    ?
                                                    enderecoSelecionado.map((dadosEnderecoSelecionado) => {
                                                        return (
                                                            <div className='w100 d-fx d-ju-sp-bt m-top-15'>
                                                                <div className='dados__telefone__principal'>
                                                                    {/* <span className='tx-black fz-16 d-bk' style={{textTransform: 'uppercase'}}>{props.user.full_name}</span> */}
                                                                    <span id="number_phone_main" className='tx-grey fz-14 d-bk'></span>
                                                                </div>
                                                                <div className='dados__endereco__principal'>
                                                                    <h3 className='tx-destaque'>{dadosEnderecoSelecionado.apelido}</h3>
                                                                    <span className='tx-black fz-16 d-bk m-top-10' style={{ textTransform: 'uppercase' }}>CEP: {dadosEnderecoSelecionado.cep} - {dadosEnderecoSelecionado.cidade} - {dadosEnderecoSelecionado.estado}</span>
                                                                    <span className='tx-black fz-16 d-bk' style={{ textTransform: 'uppercase' }}>{dadosEnderecoSelecionado.tipo_logradouro} {dadosEnderecoSelecionado.logradouro}, {dadosEnderecoSelecionado.numero} - {dadosEnderecoSelecionado.bairro}</span>
                                                                    <span className='tx-grey fz-12 d-bk'>Este será também seu endereço de cobrança</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </div>
                                        <button className="personalizado m-lef-5 m-rig-5 m-top-0" style={{ width: '100%' }} onClick={(e) => abrirModalAddress(e)}>
                                            Utilizar outro endereço
                                        </button>

                                        <div id='modal_cadastro__endereco' class="modal modal-enter modal-show d-nn">
                                            <div id='modal_content_endereco' class="modal-content modal-content-md modal-content-enter modal-content-show d-nn">
                                                <div class="modal-body">
                                                    <div class="container-fluid" data-component="validation">
                                                        <div class="modal-title">Adicionar endereço</div>
                                                        <form id='form_address_modal' class="row__modal">
                                                            <div class="w100"> {/* APELIDO DO ENDEREÇO DO USUÁRIO - OK */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="surname-address-modal-dados">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>Apelido do endereço</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                    <input id="surname-address-modal-dados" className='input-form w100 fz-16 m-top-5' type='text' name="apelido-address" value={apelido} placeholder='Ex: Casa dos pais, casa da praia, trabalho...' onChange={(ev) => setApelido(ev.target.value)} onBlur={() => checarApelido()} />
                                                                    {
                                                                        (() => {
                                                                            if (!apelidoValid) {
                                                                                if (!apelido || !apelido.length) {
                                                                                    return (
                                                                                        <div className='info-invalid d-fx d-al-cen m-top-10'>
                                                                                            <span className='icon-invalid'><TiInfo /></span>
                                                                                            <span className='text-invalid m-lef-5'>O apelido do endereço não é válido</span>
                                                                                        </div>
                                                                                    )
                                                                                } else {
                                                                                    setApelidoValid(true);
                                                                                }
                                                                            }
                                                                        })()
                                                                    }
                                                                </div>{/*box-input-single */}
                                                            </div>

                                                            <div class="w100"> {/* CEP DO ENDEREÇO DO USUÁRIO - OK */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="surname-address-modal-dados">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>CEP</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                    <InputMask id='zipcode-address-modal-dados' className='input-form w100 fz-16 m-top-5' mask="99.999-999" value={CEP} placeholder='99.999-999' onChange={(ev) => setCEP(ev.target.value)} />
                                                                    {
                                                                        (() => {
                                                                            if (!CEP || CEP.replace(/\.|\-|\_/g, '').length <= 0 || !CEPValid) {
                                                                                return (
                                                                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                                                                        <span className='icon-invalid'><TiInfo /></span>
                                                                                        <span className='text-invalid m-lef-5'>O CEP informado não é válido</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()
                                                                    }
                                                                </div>{/*box-input-single */}
                                                            </div>

                                                            <div class="w30 left p-rig-15"> {/* TIPO DO LOGRADOURO DO ENDEREÇO DO USUÁRIO */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="address_type">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>Tipo de endereço</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>

                                                                    <div class="select-box">
                                                                        <div class="options-container tipo">
                                                                            <div class="search-box">
                                                                                <input className='input-form' type="text" placeholder="Busque aqui a opção desejada..." onChange={(e) => typingSearch(e)} />
                                                                            </div>
                                                                            {
                                                                                (logradourosTipo)
                                                                                    ?
                                                                                    logradourosTipo.map((dadosLogradouro) => {
                                                                                        return (
                                                                                            <div class="option" onClick={(o) => selectedOption(o, "tipo", dadosLogradouro)}>
                                                                                                <input type="radio" class="radio" name="parcelamento" value={dadosLogradouro} />
                                                                                                <label onClick={(o) => selectedOptionSon(o, "parcelamento", dadosLogradouro)}>
                                                                                                    {dadosLogradouro}
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                    : null
                                                                            }
                                                                        </div>

                                                                        <div class="selected__option tipo input-form" onClick={() => selectedMain("tipo")}>
                                                                            Selecione o tipo
                                                                        </div>

                                                                        <input id='tipo_lograoduro' type="text" name="tipo_lograoduro" value={tipo} readOnly disabled style={{ display: "none" }} />
                                                                    </div>
                                                                    {
                                                                        (!tipo || tipo.length <= 0 || !tipoValid)
                                                                            ?
                                                                            <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                                <span className='text-invalid m-lef-5'>Nenhuma opção de tipo de endereço selecionado</span>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>{/*box-input-single */}
                                                            </div>
                                                            <div class="w70 right p-lef-15"> {/* LOGRADOURO DO ENDEREÇO DO USUÁRIO */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="address-modal-dados">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>Logradouro (Endreço)</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                    <input id="address-modal-dados" class="input-form w100 fz-16 m-top-5" type="text" name="public-place-address" value={logradouro} placeholder="Endereço" onChange={(ev) => setLogradouro(ev.target.value)} onBlur={() => (logradouro && logradouro.length > 0) ? setLogradouroValid(true) : setLogradouroValid(false)} />
                                                                    {
                                                                        (() => {
                                                                            if (logradouro && logradouro.length <= 0 || !logradouroValid) {
                                                                                return (
                                                                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                                                                        <span className='icon-invalid'><TiInfo /></span>
                                                                                        <span className='text-invalid m-lef-5'>O logradouro não pode ser vázio</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()
                                                                    }
                                                                </div>{/*box-input-single */}
                                                            </div>
                                                            <div className='clear'></div>

                                                            <div class="w30 left p-rig-15"> {/* NÚMERO DO ENDEREÇO DO USUÁRIO */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="number-address-modal-dados">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>Número de endereço</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                    <input id="number-address-modal-dados" class="input-form w100 fz-16 m-top-5" type="number" name="number-address" value={numero} placeholder="Número" onChange={(ev) => setNumero(ev.target.value)} onBlur={() => (numero && numero.length > 0) ? setNumeroValid(true) : setNumeroValid(false)} ></input>
                                                                    {
                                                                        (() => {
                                                                            if (numero && numero.length <= 0 || !numeroValid) {
                                                                                return (
                                                                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                                                                        <span className='icon-invalid'><TiInfo /></span>
                                                                                        <span className='text-invalid m-lef-5'>O número da residência não pode ser vázio</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()
                                                                    }
                                                                </div>{/*box-input-single */}
                                                            </div>
                                                            <div class="w70 right p-lef-15"> {/* COMPLEMENTO DO ENDEREÇO DO USUÁRIO */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="complement-modal-dados">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>Complemento</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                    <input id="complement-modal-dados" class="input-form w100 fz-16 m-top-5" type="text" name="complement-address" value={complemento} placeholder="Complemento" onChange={(ev) => setComplemento(ev.target.value)} />
                                                                </div>{/*box-input-single */}
                                                            </div>
                                                            <div className='clear'></div>

                                                            <div class="w100"> {/* REFERÊNCIA DO ENDEREÇO DO USUÁRIO */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="reference-address-modal-dados">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>Referência</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                    <input id="reference-address-modal-dados" class="input-form w100 fz-16 m-top-5" type="text" name="reference-address" value={referencia} placeholder="Existe algum ponto conhecido próximo ao endereço?" onChange={(ev) => setReferencia(ev.target.value)} />
                                                                </div>{/*box-input-single */}
                                                            </div>

                                                            <div class="w100"> {/* BAIRRO DO ENDEREÇO DO USUÁRIO */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="district-address-modal-dados">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>Bairro</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                    <input id='district-address-modal-dados' className='input-form w100 fz-16 m-top-5' type='text' name='district-address' value={bairro} placeholder='Em qual bairro está localizado o endereço?' onChange={(ev) => setBairro(ev.target.value)} onBlur={() => (bairro && bairro.length) ? setBairroValid(true) : setBairroValid(false)} />
                                                                    {
                                                                        (() => {
                                                                            if (bairro && bairro.length <= 0 || !bairroValid) {
                                                                                return (
                                                                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                                                                        <span className='icon-invalid'><TiInfo /></span>
                                                                                        <span className='text-invalid m-lef-5'>O bairro não pode ser vázio</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()
                                                                    }
                                                                </div>{/*box-input-single */}
                                                            </div>

                                                            <div class="w70 left p-rig-15"> {/* CIDADE DO ENDEREÇO DO USUÁRIO */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="city-address-modal-dados">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>Cidade</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                    <input id="city-address-modal-dados" class="input-form w100 fz-16 m-top-5" type="text" name="city-address" placeholder="Cidade" value={cidade} onChange={(ev) => setCidade(ev.target.value)} onBlur={() => (cidade && cidade.length) ? setCidadeValid(true) : setCidadeValid(false)} />
                                                                    {
                                                                        (() => {
                                                                            if (cidade && cidade.length <= 0 || !cidadeValid) {
                                                                                return (
                                                                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                                                                        <span className='icon-invalid'><TiInfo /></span>
                                                                                        <span className='text-invalid m-lef-5'>A cidade da residência não pode ser vázio</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()
                                                                    }
                                                                </div>{/*box-input-single */}
                                                            </div>

                                                            <div class="w30 left p-rig-15"> {/* TIPO DO LOGRADOURO DO ENDEREÇO DO USUÁRIO */}
                                                                <div className='box-input-single m-top-20'>
                                                                    <label for="address_type">
                                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                                            <span className='fz-12 m-rig-5'>Estado</span>
                                                                            <span className='icon-info-add p-rlt'>
                                                                                <IoIosInformationCircleOutline />
                                                                                <div className='helper-input tx-black d-nn'>
                                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                        <span class="css-1i1x8nz">
                                                                                            Seu CPF será usado para:
                                                                                        </span>
                                                                                        <div class="css-1l7kih-stack">
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                                    <div>
                                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                                    </div>
                                                                                </div>{/*Helper*/}
                                                                            </span>
                                                                        </div>
                                                                    </label>

                                                                    <div class="select-box">
                                                                        <div class="options-container estado">
                                                                            <div class="search-box">
                                                                                <input className='input-form' type="text" name="estadosBrasileiros" placeholder="Busque aqui o seu estado..." onChange={(e) => typingSearch(e)} />
                                                                            </div>

                                                                            {
                                                                                (estadosBrasil && estadosBrasil.length)
                                                                                    ?
                                                                                    estadosBrasil.map((dataStates) => {
                                                                                        return (
                                                                                            <div className={(estado == dataStates) ? "option active" : "option"} onClick={(o) => selectedOption(o, "estado", dataStates)}>
                                                                                                <input type="radio" class="radio" name="estado" value={dataStates} />
                                                                                                <label onClick={(o) => selectedOptionSon(o, "estado", dataStates)}>
                                                                                                    {dataStates}
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>

                                                                        <div class="selected__option estado input-form" onClick={() => selectedMain("estado")}>
                                                                            Selecione o estado
                                                                        </div>

                                                                        <input id='estado_lograoduro' type="text" name="estado_lograoduro" value={estado} readOnly disabled style={{ display: "none" }} />
                                                                    </div>
                                                                    {
                                                                        (!estado || estado.length <= 0)
                                                                            ?
                                                                            <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                                <span className='text-invalid m-lef-5'>Nenhuma opção de estado foi selecionada</span>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>{/*box-input-single */}
                                                            </div>
                                                            <div className='clear'></div>

                                                            <div className='box-input-single m-top-20'> {/* ADICIONAIS DO ENDEREÇO DO USUÁRIO */}
                                                                <div className='title-input d-fx d-al-cen p-rlt'>
                                                                    <span className='fz-12 m-rig-5'>Possui informações adicionais</span>
                                                                    <span className='icon-info-add'>
                                                                        <IoIosInformationCircleOutline />
                                                                        <div className='helper-input tx-black d-nn' style={{ pointerEvents: 'none', zIndex: 9999, position: 'absolute', inset: '0px auto auto 0px', margin: '0px', left: '15%', top: '-15px' }}>
                                                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                <span class="css-1i1x8nz">
                                                                                    Seu CPF será usado para:
                                                                                </span>
                                                                                <div class="css-1l7kih-stack">
                                                                                    <div>
                                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                            <div>
                                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                            </div>
                                                                                            <div>
                                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                            <div>
                                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                            </div>
                                                                                            <div>
                                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                            <div>
                                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                            </div>
                                                                                            <div>
                                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                            <div>
                                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                            </div>
                                                                                            <div>
                                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                            </div>
                                                                        </div>{/*Helper*/}
                                                                    </span>
                                                                </div>
                                                                <div class="input-button m-g-5">
                                                                    <input type="checkbox" name="add-info-address" value="poll" id="add-info-pool" checked={adicionalPiscina} onClick={() => setAdicionalPiscina((prevState) => !prevState)} />
                                                                    <label class="label" for="add-info-pool">Possuo piscina neste endereço</label>
                                                                </div>
                                                                <div class="input-button m-g-5">
                                                                    <input type="checkbox" name="add-info-address" value="garden" id="add-info-garden" checked={adicionalJardim} onClick={() => setAdicionalJardim((prevState) => !prevState)} />
                                                                    <label class="label" for="add-info-garden">Possuo jardim neste endereço</label>
                                                                </div>
                                                            </div>

                                                            <div class="col-xs-12 left">
                                                                <div class="field" data-validation="field">
                                                                    <input id="main_address" type="checkbox" name="adress" value="Endereço Principal" checked={principal} onClick={() => setPrincipal((prevState) => !prevState)} />
                                                                    <label class="label" for="main_address">Este é meu endereço principal</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-12 modal-footer right">
                                                                <button class="btn-default button-text" type="button" data-trigger="close" onClick={(e) => fecharModalAddress(e)}>Cancelar</button>
                                                                <button class="btn-default button-primary" type="button" onClick={(e) => cadastrarEndereco(e)}>Salvar endreço</button>
                                                            </div>
                                                            <div className='clear'></div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <label>
                                                    <AiOutlineClose className='modal-close' onClick={(e) => fecharModalAddress(e)} />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='contato__telefonico w100 m-top-15 b-bot'>
                                        <span className="service-disclaimer fz-14 tx-black">Se necessário entraremos em contato via <strong>ligação</strong>, <strong>SMS</strong> ou <strong>Whatsapp</strong> em seu celular principal para informar qualquer <strong>atualização do seu pedido</strong>.</span>

                                        <div class="w100 m-bot-25">

                                            <div className='box-input-single m-top-20'>
                                                <label>
                                                    <div className='title-input d-fx d-al-cen'>
                                                        <span className='fz-12 m-rig-5'>Telefone</span>
                                                        <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                    </div>
                                                </label>
                                                <InputMask id='telefone-cadastro-singup' mask="(99) 99999-9999" placeholder='Insira seu telefone' className={(phone && phone.match('[0-9]') && !phoneValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} value={phone} onChange={(ev) => setPhone(ev.target.value)} onBlur={() => validatePhone()} />
                                                <div>
                                                    {
                                                        (!phoneDDDValid)
                                                            ?
                                                            <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                <span className='text-invalid m-lef-5'>O DDD informado não é válido</span>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        (phoneMessage)
                                                            ?
                                                            phoneMessage.map((infoStatus) => {
                                                                if (infoStatus.status == 'error') {
                                                                    return (
                                                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                            <span className='icon-invalid'><TiInfo /></span>
                                                                            <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                                        </div>
                                                                    )
                                                                } else if (infoStatus.status == 'success') {
                                                                    return (
                                                                        <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                            <span className='icon-valid'><TiInfo /></span>
                                                                            <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </div>{/*box-input-single */}
                                        </div>
                                    </div>
                                    <div className='tipo__entrega'>
                                        <h2 className='tx-grey-dark'>Entregamos para você:</h2>
                                        <span className='fz-12'>Vendido e entregue por <strong>Engeart</strong></span>
                                        <div className='box__tipos__entrega d-fx d-ju-sp-bt d-fw-wp'>
                                            <div className={(props.responsivoSize && props.responsivoSize > 400) ? "d-fx m-top-10 m-rig-0 m-bot-10 p-rlt radio-item w50 item-selected" : "d-fx m-top-10 m-rig-0 m-bot-10 p-rlt radio-item w100 item-selected"}>
                                                <input type="radio" name="tipo-entrega" id="online-selected" value="" data-validate="radio" checked="checked" style={(props.responsivoSize && props.responsivoSize < 170) ? { display: 'none' } : null} />
                                                <label for="online-selected" className="radio-item-label">
                                                    <span className='d-bk'>Entrega Online | <strong>GRÁTIS</strong></span>
                                                    <span className='d-bk tx-bold fz-13'>Previsão de entrega: Imediáta</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='wrapper__products__cart m-top-25'>
                                        {
                                            (props.cart && props.cart.length)
                                                ?
                                                props.cart.map((dadosProduto) => {
                                                    return (
                                                        <div className='box__single__product b-bot'>
                                                            <div className='content__single__product d-fx d-fw-wp w100 p-rlt'>
                                                                <div className={(props.responsivoSize && props.responsivoSize > 600) ? "image__product w20 m-rig-20 left m-lef-0" : "image__product w100"}>
                                                                    <Link to={`/projetos/${dadosProduto.orderId}`} className='w100'>
                                                                        <img className='w100' src={dadosProduto.capa} />
                                                                    </Link>
                                                                </div>
                                                                <div className={(props.responsivoSize && props.responsivoSize > 600) ? "infos_product w80 right" : "infos_product w100"}>
                                                                    <Link to={`/projetos/${dadosProduto.orderId}`} className={(props.responsivoSize && props.responsivoSize > 240) ? 'title__name__product w100 d-bk' : 'title__name__product w100 d-fx d-fd-col d-fwp-wp fz-14'}>
                                                                        {dadosProduto.titulo}
                                                                        <span className='m-lef-5 m-rig-5'>•</span>
                                                                        Projeto Arquitetônico
                                                                    </Link>
                                                                    <span className='seller__product w100 d-bk fz-12 tx-grey-light'>
                                                                        Cód. do projeto: <strong>E{dadosProduto.orderId}A</strong>
                                                                    </span>
                                                                    {/* <div className={(props.responsivoSize && props.responsivoSize > 600) ? "box__subtotal__single d-fx d-ju-sp-bt w100 m-top-5" : "box__subtotal__single d-fx d-ju-sp-bt w100 m-top-5 d-fd-col"}> */}
                                                                    <div className={(props.responsivoSize && props.responsivoSize > 600) ? "box__subtotal__single d-fx d-ju-sp-bt w100 m-top-5" : "box__subtotal__single d-fx d-ju-sp-bt d-fw-wp w100 m-top-5"}>
                                                                        <div className='d-fx d-al-cen tx-grey-dark fz-14 m-rig-5 d-fw-wp'>
                                                                            <span>Subtotal:</span>
                                                                            <strong className='m-lef-5'>{(dadosProduto.quantidade * dadosProduto.precoTabela).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                                        </div>
                                                                        <div className='d-fx d-al-cen tx-grey-dark fz-14 m-rig-5 d-fw-wp'>
                                                                            <span>Qtde.:</span>
                                                                            <strong className='m-lef-5'>{dadosProduto.quantidade}</strong>
                                                                        </div>
                                                                        <div className='d-fx d-al-cen tx-grey-dark fz-14 m-rig-5 d-fw-wp'>
                                                                            <span>Desconto:</span>
                                                                            <strong className='m-lef-5'>{(((dadosProduto.quantidade * dadosProduto.precoTabela) / 100) * dadosProduto.desconto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                                        </div>
                                                                        <div className='d-fx d-al-cen tx-grey-dark fz-14 m-rig-5 d-fw-wp'>
                                                                            <span>Total:</span>
                                                                            <strong className='m-lef-5'>{(dadosProduto.quantidade * (dadosProduto.precoTabela - ((dadosProduto.precoTabela / 100) * dadosProduto.desconto))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='clear'></div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'w30' : 'w100 m-top-20'} style={(props.responsivoSize && props.responsivoSize > 768) ? { marginLeft: '20px' } : null}>
                                    <Subtotal cart={props.cart} responsivoSize={props.responsivoSize} />
                                    {/* <Link to="/pedido/pagamento" className="button button-full button-primary tx-cen link__disabled" title={(phone && phoneDDDValid && phoneValid) ? "Continuar para pagamento" : "O botão será habilitado após os campos obrigatórios serem preenchidos corretamente"} disabled={(phone && phoneDDDValid && phoneValid) ? false : true}>Continuar para pagamento</Link> */}
                                    <button className="button button-full button-primary tx-cen" title={(phone && phoneDDDValid && phoneValid && enderecoSelecionado && enderecoSelecionado.length) ? "Continuar para pagamento" : "O botão será habilitado após os campos obrigatórios serem preenchidos corretamente"} disabled={(phone && phoneDDDValid && phoneValid && enderecoSelecionado && enderecoSelecionado.length) ? false : true} onClick={(el) => salvarDadosComprador(el)}>
                                        Continuar para pagamento
                                    </button>
                                </div>
                                <div className='clear'></div>
                            </section>
                        </div>
                    </div>
                </div>{/*center*/}
            </div>
        </div>
    );
}

export default Entrega;