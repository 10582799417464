import React from 'react';
import './Assets/styles/css/Footer.css';
import {useEffect, useState} from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';


import { db } from '../../../firebase';
import firebase from 'firebase';
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import axios from 'axios';
import toast from 'react-hot-toast';

import $ from 'jquery';
import { Link } from 'react-router-dom';

function Footer(props){    
    function cadastraContatoRodape(el) {
        el.preventDefault();
        // console.log(details);
        // VARIÁVEIS PARA CADASTRO DE SOLICITAÇÃO DE CONTATO
        let email = document.getElementById('dados-comunicacao-email').value;

        // let myDate = document.getElementById('datetime').value;
        if(props.user){
            db.collection('sac').doc('comunicacao').collection('publico').add({ // PUBLICO = Suporte de atendimento a quem compra no site || PRIVADO = Suporte para quem é profissional ou parceiro engeart
                email: email,
                data_envio: firebase.firestore.FieldValue.serverTimestamp(),
                detalhes: props.location,
                status: 'pendente',
                usuario: props.user
            }).then(() => {
                toast.success(props.user.nome + ', em breve entraremos em contato!');
            }).catch((error) => {
                toast.error(error.message);
            })
        }else{
            db.collection('sac').doc('comunicacao').collection('publico').add({ // PUBLICO = Suporte de atendimento a quem compra no site || PRIVADO = Suporte para quem é profissional ou parceiro engeart
                email: email,
                data_envio: firebase.firestore.FieldValue.serverTimestamp(),
                detalhes:  props.location,
                status: 'pendente'
            }).then(() => {
                toast.success('Por favor, aguarde, em breve entraremos em contato!');
            }).catch((error) => {
                toast.error(error.message);
            })
        }
    }

    return(
        <div id='footer' className="new_footer_area bg_color z-less">
            {/* BOTÃO IR PARA O TOPO */}
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
                </svg>
            </div>
            {/* NAV FOOTER */}
            <div className="new_footer_top">
                <div className="center">
                    <div className="row">
                        <div className="wrapper__single__footer" style={{padding: '5px'}}>
                            <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s" >
                                <h3 className="f-title f_600 t_color f_size_18">Entrar em contato</h3>
                                <p>Não perca nenhuma atualização de nossos novos modelos e extensões!</p>
                                <form className='f_subscribe_two mailchimp' onSubmit={(el) => cadastraContatoRodape(el)}>
                                {/* <form action="#" className="" method="post" novalidate="true" _lpchecked="1"> */}
                                    <input id='dados-comunicacao-email' type="text" name="EMAIL" className="form-control memail" placeholder="seuemail@email.com" />
                                    <button className="btn btn_get btn_get_two" type="submit">Cadastrar</button>
                                    <p className="mchimp-errmessage" style={{display: 'none'}}></p>
                                    <p className="mchimp-sucmessage" style={{display: 'none'}}></p>
                                </form>
                            </div>
                        </div>
                        <div className="wrapper__single__footer">
                            <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                <h3 className="f-title f_600 t_color f_size_18">Download</h3>
                                <ul className="list-unstyled f_list">
                                    <li><Link to="/sobre">Empresa</Link></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>Android App</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>ios App</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>Desktop</a></li>
                                    <li><Link to="/projetos">Projetos</Link></li>
                                    <li><Link to="/criar-projeto">Meus Projetos</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="wrapper__single__footer">
                            <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.6s">
                                <h3 className="f-title f_600 t_color f_size_18">Ajuda</h3>
                                <ul className="list-unstyled f_list">
                                    <li><a href="javascript:void(0)" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>FAQ</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>Termos &amp; condições</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>Comunicando</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>Documentation</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>Política de Suporte</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => toast.error("Esta função está em desenvolvimento")}>Privacidade</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="wrapper__single__footer">
                            <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.8s">
                                <h3 className="f-title f_600 t_color f_size_18">Soluções de equipe</h3>
                                <div className="f_social_icon d-fx d-al-cen d-fw-wp">
                                    <a className='d-fx d-al-cen d-ju-cen' target='_blanc' href="https://www.facebook.com/Eengeart">
                                        <FaFacebook />
                                    </a>
                                    <a className='d-fx d-al-cen d-ju-cen' target='_blanc' href="https://www.instagram.com/_engeart_/">
                                        <FaInstagram />
                                    </a>
                                    <a className='d-fx d-al-cen d-ju-cen' target='_blanc' href="https://twitter.com/_engeart_">
                                        <FaTwitter />
                                    </a>
                                    <a className='d-fx d-al-cen d-ju-cen' target='_blanc' href="https://www.youtube.com/channel/UCP5QE4zz_Y-_UEssfzUNhDA">
                                        <FaYoutube />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bg">
                    <div className="footer_bg_one"></div>
                    <div className="footer_bg_two"></div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="center">
                    <div className="row align-items-center d-ju-cen">
                        <div className="col-lg-6 col-sm-7" style={{textAlign: 'center'}}>
                            <p className="mb-0 f_400">© ENGEART • 2017 - {(()=>{
                                var data = new Date()
                                var ano = data.getFullYear();
                                return (ano)
                                })()} • Todos os direitos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
