import '../../../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../../../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiNotebook } from 'react-icons/gi';
import { AiOutlineFolderView, AiOutlineCheck, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdHomeFilled, MdHomeWork } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaMoneyBill } from 'react-icons/fa';

import { BsCreditCard2Front, BsCashCoin } from 'react-icons/bs';
import { ImQrcode } from 'react-icons/im';
import { GrTransaction } from 'react-icons/gr';
import { FcMoneyTransfer } from 'react-icons/fc';
import { RiHomeGearLine } from 'react-icons/ri';
import { FaBarcode, FaApplePay, FaGooglePay, FaStar } from 'react-icons/fa';


import contractOkay from '../../../../../../../../../../images/icones/svg/Construction costs-pana.svg';
import searchFolder from '../../../../../../../../../../images/icones/svg/search-folder.svg';
import searchDocument from '../../../../../../../../../../images/icones/svg/search-document.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';

function BudgetForm_categoria(props) {

    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [orcamentoVencimento, setOrcamentoVencimento] = useState('');


    const [orcamentos, setOrcamentos] = useState([]);

    // DADOS DO FORMULÁRIO
    const [usuarioCadastrado, setUsuarioCadastrado] = useState(false); // VERIFICAR SE O USARIO ESTÁ CADASTRADO NO SITE ? ABRIR SELEÇÃO DE USUARIO : ABRIR MODAL DE CADASTRAMENTO DE USUARIO

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user) && props.user.representante) {
            db.collection("orcamentos")
                .where("numero_representante_contratado", "==", props.user.token_representante)
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })

            // console.log(orcamentos)
        }
    }, [])

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }

    // FUNÇÕES DE SELECT PERSONALIZADO - CATEGORIAS
    const [novaCategoriaStatus, setNovaCategoriaStatus] = useState(false);
    const [novaCategoria, setNovaCategoria] = useState([]);

    const [toggleOpenOptions, setToggleOpenOptions] = useState(false);


    // FUNÇÕES DE SELECT PERSONALIZADO - TIPOS
    const [novoTipoStatus, setNovoTipoStatus] = useState(false);
    const [novoTipo, setNovoTipo] = useState([]);

    const [toggleOpenOptionsTipo, setToggleOpenOptionsTipo] = useState(false);

    function typingSearchCategoria(e) {
        filterListCategoria(e.target.value);
    };

    const filterListCategoria = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.categoria").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function typingSearchTipo(e) {
        filterListTipo(e.target.value);
    };

    const filterListTipo = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.tipo").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function convertStringToSlug(string) {
        string = string.replace(/^\s+|\s+$/g, '')
        string = string.toLowerCase()

        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'

        for (let i = 0, l = from.length; i < l; i++) {
            string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        string = string.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')

        return string
    }

    function definirNovaCategoria(val) {
        setNovaCategoria({
            categoria: val,
            slug_categoria: convertStringToSlug(val),
            campo: "categoria-orcamento"
        });
    }

    function definirNovoTipo(val) {
        setNovoTipo({
            tipo: val,
            slug_tipo: convertStringToSlug(val),
            campo: "tipo-orcamento"
        });
    }

    // FUNÇÕES INICIAIS - RECUPERAÇÃO DE CATEGORIAS
    const [categorias, setCategorias] = useState([]);
    const [tipos, setTipos] = useState([]);

    useMemo(async () => {
        db.collection("arquivos")
            .doc("documentos")
            .collection("complementos")


        const ref = db.collection('arquivos').doc("documentos").collection("complementos");
        const snapshot = await ref.get();
        if (snapshot.empty) {
            toast.error('Não foi possivel recuperar as categorias de orçamentos.');
            return;
        }

        var contentCategorias = ([]);
        var contentTipos = ([]);

        snapshot.forEach(doc => {
            if (doc.data().campo === 'categoria-orcamento') {
                contentCategorias.unshift(doc.data());
            } else if (doc.data().campo === 'tipo-orcamento') {
                contentTipos.unshift(doc.data());
            }
        });

        setCategorias(contentCategorias);
        setTipos(contentTipos);
    }, []);



    // AVANÇAR PROGRESSO DE CRIAÇÃO DE ORÇAMENTO
    const [saveCategoriaStatus, setSaveCategoriaStatus] = useState(false);

    const [saveTipoStatus, setSaveTipoStatus] = useState(false);

    function avancarOrcamento() {
        if (novaCategoriaStatus && !isEmptyObject(novaCategoria) && novaCategoria.categoria.length && novaCategoria.slug_categoria.length) { // TENHO QUE CRIAR UMA NOVA CATEGORIA E AVANÇAR
            db.collection("arquivos")
                .doc("documentos")
                .collection("complementos")
                .add({
                    categoria: novaCategoria.categoria,
                    slug_categoria: novaCategoria.slug_categoria,
                    campo: 'categoria-orcamento'
                })
                .then(() => {
                    toast.success("Categoria selecionada com sucesso!");
                    props.setCategoriaSelecionada({
                        categoria: novaCategoria.categoria,
                        slug_categoria: novaCategoria.slug_categoria,
                        campo: 'categoria-orcamento'
                    })
                    setNovaCategoria([]);
                    setNovaCategoriaStatus(false);
                    setSaveCategoriaStatus(true);
                })
                .catch((err) => {
                    toast.error("Houve um erro ao salvar a nova categoria, tente novamente!");
                    console.log(err);
                })
        } else if (novoTipoStatus && !isEmptyObject(novoTipo) && novoTipo.tipo.length && novoTipo.slug_tipo.length) { // TENHO QUE CRIAR UMA NOVA CATEGORIA E AVANÇAR
            db.collection("arquivos")
                .doc("documentos")
                .collection("complementos")
                .add({
                    tipo: novoTipo.tipo,
                    slug_tipo: novoTipo.slug_tipo,
                    campo: "tipo-orcamento"
                })
                .then(() => {
                    toast.success("Tipo de serviço selecionado com sucesso!");
                    props.setBudgetTipoSelecionado({
                        tipo: novoTipo.tipo,
                        slug_tipo: novoTipo.slug_tipo,
                        campo: "tipo-orcamento"
                    })
                    setNovoTipo([]);
                    setNovoTipoStatus(false);
                    setSaveTipoStatus(true);
                })
                .catch((err) => {
                    toast.error("Houve um erro ao salvar o novo tipo de serviço, tente novamente!");
                    console.log(err);
                })
        } else {
            if (!props.budgetTitulo || !props.budgetTitulo.length) {
                toast.error("Houve um erro ao salvar o título, tente novamente!");
            } else if (!props.categoriaSelecionada || isEmptyObject(props.categoriaSelecionada) || !props.categoriaSelecionada.categoria.length || !props.categoriaSelecionada.slug_categoria.length) {
                toast.error("Houve um erro ao salvar a finalidade selecionada, tente selecionar novamente!");
                console.log(props.categoriaSelecionada);
            } else if (!props.budgetTipoSelecionado || isEmptyObject(props.budgetTipoSelecionado) || !props.budgetTipoSelecionado.tipo.length || !props.budgetTipoSelecionado.slug_tipo.length) {
                toast.error("Houve um erro ao salvar a modalidade selecionada, tente selecionar novamente!");
            } else if (!props.budgetTipoExecucao || !props.budgetTipoExecucao.length) {
                toast.error("Houve um erro ao salvar a forma de execução, tente novamente!");
            } else if (!props.budgetArea || !props.budgetArea.length) {
                toast.error("Houve um erro ao salvar a área, tente novamente!");
            } else if (!props.budgetDescricao || !props.budgetDescricao.length) {
                toast.error("Houve um erro ao salvar a descrição, tente novamente!");
            } else {
                toast.success("Informações salvas com sucesso!");
                props.setSlideCard(props.slideCard + 1);
            }
        }

        if (saveCategoriaStatus && saveTipoStatus) {
            props.setSlideCard(props.slideCard + 1);
        }
    }
    return (
        <div id="budget_form_categoria">
            <div className='card__info__budget__single'>
                <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                    <h2 className='b-bot-medium'>Novo Orçamento</h2>
                    <p className='fz-14'>Preencha as infomações a seguir para gerar um novo orçamento.</p>
                </div>
                <div className='wrapper__form__criar__orcamento'>
                    <form className='form__criar__orcamento'>
                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Título do Orçamento</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <input
                                className={(!props.budgetTitulo || !props.budgetTitulo.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                type='text'
                                name="titulo_orcamento"
                                placeholder='Reforma Residencial Unifamiliar Térrea'
                                value={(props.budgetTitulo && props.budgetTitulo.length) ? props.budgetTitulo : null}
                                onChange={(ev) => props.setBudgetTitulo(ev.target.value)}
                            />
                            {
                                (!props.budgetTitulo || !props.budgetTitulo.length)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>O Título do orçamento não pode ser vázio!</span>
                                    </div>
                                    : null
                            }
                        </div>

                        <div class="box-input-single m-top-20">
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Selecione a finalizade do orçamento</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>

                            <div class="select-box">
                                <div class={toggleOpenOptions ? "options-container active" : "options-container"}>
                                    <div class="search-box">
                                        <input className='input-form' type="text" placeholder="Busque aqui a opção desejada..." onKeyUp={(e) => typingSearchCategoria(e)} />
                                    </div>

                                    {
                                        (categorias && categorias.length)
                                            ?
                                            categorias.map((dadosCategorias, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        class={(props.categoriaSelecionada && !isEmptyObject(props.categoriaSelecionada) && props.categoriaSelecionada.slug_categoria === dadosCategorias.slug_categoria) ? "option categoria active" : "option categoria"}
                                                        onClick={() => {
                                                            props.setCategoriaSelecionada({ slug_categoria: dadosCategorias.slug_categoria, categoria: dadosCategorias.categoria, campo: dadosCategorias.campo })
                                                            setToggleOpenOptions(!toggleOpenOptions)
                                                            setNovaCategoriaStatus(false);
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            class="radio"
                                                            name={dadosCategorias.slug_categoria}
                                                            value={dadosCategorias.categoria}
                                                        />
                                                        <label>
                                                            {
                                                                dadosCategorias.categoria
                                                            }
                                                        </label>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                    <div
                                        class={(novaCategoriaStatus) ? "option active" : "option"}
                                        onClick={() => {
                                            props.setCategoriaSelecionada({ slug_categoria: "nova_categoria", categoria: "Outra Categoria", campo: "categoria-orcamento" });
                                            setToggleOpenOptions(!toggleOpenOptions);
                                            setNovaCategoriaStatus(true);
                                        }}>
                                        <input type="radio" className="radio m-top-5" name="nova_categoria" />
                                        <label>Outra Categoria</label>
                                    </div>
                                </div>

                                <div class="selected__option input-form" onClick={() => setToggleOpenOptions(!toggleOpenOptions)}>
                                    {
                                        (props.categoriaSelecionada && !isEmptyObject(props.categoriaSelecionada) && props.categoriaSelecionada.categoria)
                                            ?
                                            props.categoriaSelecionada.categoria
                                            : "Selecione a finalidade desejado"
                                    }
                                </div>
                            </div>

                            {
                                (!props.categoriaSelecionada || isEmptyObject(props.categoriaSelecionada))
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-5'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>Nenhuma opção para departamento selecionada</span>
                                    </div>
                                    : null
                            }
                        </div>

                        {
                            (novaCategoriaStatus)
                                ?
                                <div className='box-input-single m-top-20'>
                                    <label>
                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                            <span className='fz-12 m-rig-5'>Nome da nova categoria de finalidade de orçamento</span>
                                            <span className='icon-info-add p-rlt'>
                                                <IoIosInformationCircleOutline />
                                                <div className='helper-input tx-black d-nn'>
                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                        <span class="css-1i1x8nz">
                                                            Seu CPF será usado para:
                                                        </span>
                                                        <div class="css-1l7kih-stack">
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                    </div>
                                                </div>{/*Helper*/}
                                            </span>
                                        </div>
                                    </label>
                                    <input className={(!novaCategoria || isEmptyObject(novaCategoria) || !novaCategoria.categoria.length || !novaCategoria.slug_categoria.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nova_categoria" placeholder='Nova Categoria De Orçamento' value={(novaCategoria && !isEmptyObject(novaCategoria)) ? novaCategoria.categoria : null} onChange={(ev) => definirNovaCategoria(ev.target.value)} />
                                    {
                                        (!novaCategoria || isEmptyObject(novaCategoria) || !novaCategoria.categoria.length || !novaCategoria.slug_categoria.length)
                                            ?
                                            <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                <span className='icon-invalid'><TiInfo /></span>
                                                <span className='text-invalid m-lef-5'>O nome da categoria não pode ser vázio!</span>
                                            </div>
                                            : null
                                    }
                                </div>
                                : null
                        }

                        <div class="box-input-single m-top-20">
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Selecione a modalidade de execução de serviço</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>

                            <div class="select-box">
                                <div class={toggleOpenOptionsTipo ? "options-container active" : "options-container"}>
                                    <div class="search-box">
                                        <input className='input-form' type="text" placeholder="Busque aqui a opção desejada..." onKeyUp={(e) => typingSearchTipo(e)} />
                                    </div>

                                    {
                                        (tipos && tipos.length)
                                            ?
                                            tipos.map((dadosTipo, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        class={(props.budgetTipoSelecionado && !isEmptyObject(props.budgetTipoSelecionado) && props.budgetTipoSelecionado.slug_tipo === dadosTipo.slug_tipo) ? "option tipo active" : "option tipo"}
                                                        onClick={() => {
                                                            props.setBudgetTipoSelecionado({ slug_tipo: dadosTipo.slug_tipo, tipo: dadosTipo.tipo, campo: dadosTipo.campo })
                                                            setToggleOpenOptionsTipo(!toggleOpenOptionsTipo)
                                                            setNovoTipoStatus(false);
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            class="radio"
                                                            name={dadosTipo.slug_tipo}
                                                            value={dadosTipo.tipo}
                                                        />
                                                        <label>
                                                            {
                                                                dadosTipo.tipo
                                                            }
                                                        </label>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                    <div
                                        class={(novoTipoStatus) ? "option active" : "option"}
                                        onClick={() => {
                                            props.setBudgetTipoSelecionado({ slug_tipo: "novo_tipo", tipo: "Outro Tipo", campo: "tipo-orcamento" });
                                            setToggleOpenOptionsTipo(!toggleOpenOptionsTipo);
                                            setNovoTipoStatus(true);
                                        }}>
                                        <input type="radio" className="radio m-top-5" name="novo_tipo" />
                                        <label>Outro Tipo</label>
                                    </div>
                                </div>

                                <div class="selected__option input-form" onClick={() => setToggleOpenOptionsTipo(!toggleOpenOptionsTipo)}>
                                    {
                                        (props.budgetTipoSelecionado && !isEmptyObject(props.budgetTipoSelecionado) && props.budgetTipoSelecionado.tipo)
                                            ?
                                            props.budgetTipoSelecionado.tipo
                                            : "Selecione o tipo de execução de serviço desejada"
                                    }
                                </div>
                            </div>

                            {
                                (!props.budgetTipoSelecionado || isEmptyObject(props.budgetTipoSelecionado))
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-5'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>Nenhuma opção para tipo de execução de serviço selecionada</span>
                                    </div>
                                    : null
                            }
                        </div>

                        {
                            (novoTipoStatus)
                                ?
                                <div className='box-input-single m-top-20'>
                                    <label>
                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                            <span className='fz-12 m-rig-5'>Nome do novo tipo de execução de serviço</span>
                                            <span className='icon-info-add p-rlt'>
                                                <IoIosInformationCircleOutline />
                                                <div className='helper-input tx-black d-nn'>
                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                        <span class="css-1i1x8nz">
                                                            Seu CPF será usado para:
                                                        </span>
                                                        <div class="css-1l7kih-stack">
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                    <div>
                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                    </div>
                                                                    <div>
                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                    </div>
                                                </div>{/*Helper*/}
                                            </span>
                                        </div>
                                    </label>
                                    <input className={(!novoTipo || isEmptyObject(novoTipo) || !novoTipo.tipo.length || !novoTipo.slug_tipo.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nova_categoria" placeholder='Novo tipo de execução de serviço' value={(novoTipo && !isEmptyObject(novoTipo)) ? novoTipo.tipo : null} onChange={(ev) => definirNovoTipo(ev.target.value)} />
                                    {
                                        (!novoTipo || isEmptyObject(novoTipo) || !novoTipo.tipo.length || !novoTipo.slug_tipo.length)
                                            ?
                                            <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                <span className='icon-invalid'><TiInfo /></span>
                                                <span className='text-invalid m-lef-5'>O nome do tipo de execução de serviço não pode ser vázio!</span>
                                            </div>
                                            : null
                                    }
                                </div>
                                : null
                        }

                        <div className='box-input-single m-top-15'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Marque a forma de execução do serviço</span>
                                </div>
                            </label>
                            <div className='termos-news-single d-fx d-ju-sp-ev d-fw-wp w100 m-top-10'>
                                <div className='canais-single w100 m-top-10 m-bot-10' onClick={() => props.setBudgetTipoExecucao("presencial")}>
                                    <input id='execucao-presencial-servico' type='radio' name="forma_servico" value="presencial" checked={(props.budgetTipoExecucao && props.budgetTipoExecucao === "presencial") ? true : false} />
                                    <label for="execucao-presencial-servico">Presencial</label>
                                </div>
                                <div className='canais-single w100 m-top-10 m-bot-10' onClick={() => props.setBudgetTipoExecucao("distancia")}>
                                    <input id='execucao-distancia-servico' type='radio' name="forma_servico" value="distancia" checked={(props.budgetTipoExecucao && props.budgetTipoExecucao === "distancia") ? true : false} />
                                    <label for="execucao-distancia-servico">Distância</label>
                                </div>
                            </div>{/*termos-news-single*/}
                        </div>{/*box-input-single */}



                        <div class="box-input-single m-top-20">
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Digite a área total do serviço</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <input
                                className={(!props.budgetArea || !props.budgetArea.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                type='number'
                                name="area"
                                placeholder='50,00 m²'
                                value={(props.budgetArea && props.budgetArea.length) ? props.budgetArea : null}
                                onChange={(ev) => props.setBudgetArea(ev.target.value)}
                            />
                            {
                                (!props.budgetArea || !props.budgetArea.length)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>A área do serviço não pode ser vázia!</span>
                                    </div>
                                    : null
                            }
                        </div>

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Descrição do Serviço</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <textarea
                                className={(!props.budgetDescricao || !props.budgetDescricao.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                type='text'
                                name="descricao"
                                placeholder='Execução de Obra Residencial'
                                value={(props.budgetDescricao && props.budgetDescricao.length) ? props.budgetDescricao : null}
                                onChange={(ev) => props.setBudgetDescricao(ev.target.value)}
                                style={{ resize: 'vertical' }}
                            >
                            </textarea>
                            {
                                (!props.budgetDescricao || !props.budgetDescricao.length)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>A descrição do serviço não pode ser vázia!</span>
                                    </div>
                                    : null
                            }
                        </div>

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Vencimento do Orçamento</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <div className='p-rlt'>
                                <input
                                    type='datetime-local'
                                    className={(!props.budgetValidade || !props.budgetValidade.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                    name="etapa_descricao"
                                    placeholder='Selecione a data do vencimento'
                                    value={(props.budgetValidade && props.budgetValidade.length) ? props.budgetValidade : null}
                                    onChange={(ev) => props.setBudgetValidade(ev.target.value)}
                                />
                            </div>
                            {
                                (!props.budgetValidade || !props.budgetValidade.length)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>O orçamento deve ter uma data de vencimento!</span>
                                    </div>
                                    : null
                            }
                        </div>
                    </form>
                </div>
            </div>

            <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                <button
                    className="cart"
                    style={{ marginTop: 0, textTransform: 'uppercase' }}
                    onClick={() => avancarOrcamento()}
                    disabled={(() => {
                        if (!props.budgetTitulo || !props.budgetTitulo.length) {
                            return true;
                        } else if (!props.categoriaSelecionada || isEmptyObject(props.categoriaSelecionada) || !props.categoriaSelecionada.categoria.length || !props.categoriaSelecionada.slug_categoria.length) {
                            return true;
                            console.log(props.categoriaSelecionada);
                        } else if (!props.budgetTipoSelecionado || isEmptyObject(props.budgetTipoSelecionado) || !props.budgetTipoSelecionado.tipo.length || !props.budgetTipoSelecionado.slug_tipo.length) {
                            return true;
                        } else if (!props.budgetTipoExecucao || !props.budgetTipoExecucao.length) {
                            return true;
                        } else if (!props.budgetArea || !props.budgetArea.length) {
                            return true;
                        } else if (!props.budgetDescricao || !props.budgetDescricao.length) {
                            return true;
                        } else if ((!props.budgetValidade || !props.budgetValidade.length)) {
                            return true;
                        } else {
                            return false;
                        }
                    })()}
                >
                    <span className=' fz-12'>Próximo</span>
                </button>
            </div>
        </div>
    );

}

export default BudgetForm_categoria;