
import { AiOutlineClose, AiOutlineReload, AiOutlineClear } from 'react-icons/ai';
import { fecharModal } from '../funcoes/FuncoesGerais';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { GiSave } from 'react-icons/gi';
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri';
import { useMemo, useState } from 'react';
import logoIcon from '../../../../../images/icones/jpg/logo_icone.jpg';
import { auth, db, fs } from '../../../../../firebase';
import toast from 'react-hot-toast';


// IMPORTAÇÃO PARA ASSINATURA
// import { createRoot } from 'react-dom/client'
import { useEffect, useRef } from 'react';
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getComponent, registerLicense } from '@syncfusion/ej2-base';
import { updateSampleSection } from '../../../../../common/sample-base';
// import SignaturePad from 'react-signature-pad-wrapper'
import SignaturePad from "react-signature-canvas";


function ModalAssinaturaContrato(props) {

    console.log(props)
    let navigate = useNavigate();
    const [lazyload, setLazyload] = useState(false); // LAZYLOAD
    const [depoimentos, setDepoimentos] = useState([]); // DEPOIMENTOS DOS CLIENTES
    const [senha, setSenha] = useState(''); // SENHA
    const [token, setToken] = useState(''); // Token ID Firestore
    const [senhaValid, setSenhaValid] = useState(false) // SENHA VÁLIDA
    const [signatureValid, setSignatureValid] = useState(false) // ASSINATURA SALVA
    const [signatureSaved, setSignatureSaved] = useState(false) // ASSINATURA SALVA
    const [signatureContent, setSignatureContent] = useState([]) // ASSINATURA SALVA
    const [contratacaoAtiva, setContratacaoAtiva] = useState(false) // ASSINATURA SALVA
    const [contratacaoAvisada, setContratacaoAvisada] = useState(false) // ASSINATURA SALVA
    const [passStateView, setPassStateView] = useState(false);

    function fechaModalPreviewDepoimento(el) {
        el.preventDefault();

        fecharModal();
        setTimeout(() => { props.setOpenModal(null) }, 1000);
    }

    function validarSenha(e) {
        e.preventDefault();
        document.getElementById("btn__signature__progress").disabled = true;

        if (!senha || !senha.length) {
            toast.error("A senha não pode ser vázia");
        } else if (!signatureContent || !signatureContent.length) {
            toast.error("Não foi possivel salvar sua assinatura, por favor assine novamente");
        } else { // PASSOU EM TODAS AS VALIDAÇÕES E VAMOS REALIZAR O LOGIN
            auth.signInWithEmailAndPassword(props.user.email, senha)
                .then(async () => {
                    const contractRef = db.collection('servicos');
                    const snapshot = await contractRef.where("token", "==", props.openModal.token).get();
                    if (snapshot.empty) {
                        // console.log('No matching documents.');
                        toast.error("Não foi possivel localizar os dados do cliente");
                        document.getElementById("btn__signature__progress").disabled = false;
                        return;
                    }
                    // console.log(snapshot);

                    // SEPARAR OS NOVOS OBJETOS DE ETAPAS ATUALIZADOS


                    var newSteps = ([]);
                    newSteps = props.openModal.dados_servico
                    for (let i = 0; i < 0; i++) {
                        newSteps.etapas[i].status = false;
                    }
                    if (0 < (newSteps.etapas.length - 1)) { // DEVE-SE SUBITRAIR POR CONTA DO ARAY COMEÇA NO INDICE 0 E APRESENTAR O TAMANHO TOTAL MAIOR
                        // console.log("Etapa Modal: ",0);
                        // console.log("Etapa Tamanho: ",newSteps.etapas.length);
                        newSteps.etapas[0].status = false;
                        newSteps.etapas[0 + 1].status = true;
                    } else {
                        // console.log("Ultima Etapa: ",newSteps.etapas.length);
                        newSteps.etapas[0].status = true;
                    }
                    newSteps.etapas[0].statusContratado = true;
                    newSteps.etapas[0].statusContratante = true;
                    newSteps.etapas[0].concluido = true;
                    newSteps.etapas[0].dataAprovacao = new Date();

                    snapshot.forEach(doc => {
                        if (props.openModal.usuario && props.openModal.usuario.length && props.openModal.usuario === "representante") { // O REPRESENTANTE ENGEART ESTÁ ASSINANDO O CONTRATO PARA AUTENTICAÇÃO
                            // console.log(doc.id, '=>', doc.data());
                            db.collection('servicos')
                                .doc(doc.id)
                                .update({ "assinatura_contratado": signatureContent })
                                .then(() => {
                                    fechaModalPreviewDepoimento(e);
                                    toast.success('A assinatura do representante foi realizada com sucesso!');
                                    navigate('/minha-conta/meus-contratos')
                                })
                                .catch((err) => {
                                    toast.error('Ocorreu um erro');
                                    document.getElementById("btn__signature__progress").disabled = false;
                                    console.log(err)
                                })
                        } else { // O CONTRATANTE UE ESTÁ ASSINANDO O CONTRATO
                            db.collection('servicos')
                                .doc(doc.id)
                                .update({ "status": 'aprovado', "validade_orcamento": props.openModal.validade, "assinatura_contratante": signatureContent, "etapas": newSteps.etapas })
                                .then(() => {
                                    fechaModalPreviewDepoimento(e);
                                    setContratacaoAtiva(true);
                                    toast.success('A contratação dos serviços foi realizada com sucesso!');
                                    navigate('/minha-conta/meus-contratos')
                                })
                                .catch((err) => {
                                    setContratacaoAtiva(false);
                                    toast.error('Ocorreu um erro');
                                    document.getElementById("btn__signature__progress").disabled = false;
                                    console.log(err)
                                })
                        }
                    });
                })
                .catch(function (error) {
                    document.getElementById("btn__signature__progress").disabled = false;
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    if (errorCode === 'auth/invalid-email') {
                        toast.error("O endereço de e-mail não é válido")
                    } else if (errorCode === 'auth/user-disabled') {
                        toast.error("O usuário correspondente ao e-mail fornecido foi desabilitado")
                    } else if (errorCode === 'auth/user-not-found') {
                        toast.error('Não houve usuário correspondente ao e-mail fornecido');
                    } else if (errorCode === 'auth/wrong-password') {
                        toast.error("Usuário ou senha inválidos");
                    } else if (errorCode === 'auth/too-many-requests') {
                        toast.error("O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login com falha. Você pode restaurá-la imediatamente redefinindo sua senha ou pode tentar novamente mais tarde.");
                    } else {
                        toast.error(errorMessage);
                    }
                    console.log(error);
                })
        }
    }

    // FUNÇÕES ASSINATURA
    registerLicense(
        'Mgo+DSMBaFt/QHRqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSH5Rd0VgWHxbcn1RQw==;Mgo+DSMBPh8sVXJ0S0J+XE9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0VhWH9dcXdQRWFcVA==;ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjW35ec3VWQ2NeVEE=;ODU3MjgyQDMyMzAyZTM0MmUzMEFta2FFaVBkblNEMDBWZldzOHdWUVl0ZjdWcFBydmZBSXh4RkVOaXA4Ykk9;ODU3MjgzQDMyMzAyZTM0MmUzMGdXNHE1eXVER3dORG9pQzE3QnJQU1ZRR1AzQ3dFcERwc2xtcnkwNm9IcUE9;NRAiBiAaIQQuGjN/V0Z+WE9EaFxKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViWX1fcHdURWVfWExz;ODU3Mjg1QDMyMzAyZTM0MmUzMEttZEs2b1g2dEM1VTlzTUNzU3ZjbjhkMEJmaFFSUGluUFlEaEQ2b1lRL0k9;ODU3Mjg2QDMyMzAyZTM0MmUzMG1ldy8xRk1GbWNKZVNtODVlK0JVa3lRdmthSXUwUUMwd2FYZWtEdEdqRXM9;Mgo+DSMBMAY9C3t2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjW35ec3VWQ2RbV0E=;ODU3Mjg4QDMyMzAyZTM0MmUzMEtvY2VPRTEweVMxcklmbi9vT0J5Rzh4VjBnWHJTQ3hoUXJ1dm1wdEU5ZVU9;ODU3Mjg5QDMyMzAyZTM0MmUzMENFdloxUytQVk5TY0w2aG1yRXdqVXlWbVV2TTNkT0drTGMyeGc2cjc5SEk9;ODU3MjkwQDMyMzAyZTM0MmUzMEttZEs2b1g2dEM1VTlzTUNzU3ZjbjhkMEJmaFFSUGluUFlEaEQ2b1lRL0k9'
    );

    const signCanvas = useRef({});

    const clear = () => {
        signCanvas.current.clear();
        setSignatureValid(false);
    }

    const save = () => {

        if (signCanvas.current.isEmpty()) {
            // eslint-disable-next-line no-alert
            alert('Forneça uma assinatura primeiro.');
        } else {
            // console.log(signatureContent);
            signCanvas.current.off();

            setTimeout(() => {
                // console.log(signCanvas.current.getTrimmedCanvas().toDataURL())
                setSignatureContent(signCanvas.current.toDataURL());

                setSignatureSaved(true);
                // console.log(signatureContent);
            }, 100);


            // setTimeout(() => {
            //     console.log(signatureContent.length);
            // }, 200);
        }
    }

    const redo = () => {
        signCanvas.current.on();
        signCanvas.current.clear();
        setSignatureSaved(false);
        setSignatureValid(false);
        setSignatureContent([]);
    }

    const change = () => {
        setSignatureValid(true);
    }

    useEffect(() => {
        if (signatureSaved && (!signatureContent || !signatureContent.length)) {
            toast.error("Não foi possivel salvar sua assinatura, por favor assine novamente");
            signCanvas.current.on();
            signCanvas.current.clear();
            setSignatureSaved(false);
            setSignatureValid(false);
            setSignatureContent([]);
        }
    }, [signatureSaved])


    return (
        <div class="popup" style={{ zIndex: 9999, position: 'fixed', maxWidth: '566px' }}>
            <div className='header-popup'>
                <span>{(props.openModal.usuario && props.openModal.usuario.length && props.openModal.usuario === "representante") ? "Cadastrar Assinatura do Representante" : "Confirmar Contratação"}</span>
                <a class="close-popup" onClick={(el) => fechaModalPreviewDepoimento(el)}><AiOutlineClose /></a>
            </div>
            <div className='wrapper__infos__request p-g-15 m-top-25 m-bot-5'>
                <div className='info__single__request w100 d-fx'>
                    <div className='icon__info__request info-primary left'>
                        <IoIosInformationCircleOutline className='fz-18' />
                    </div>
                    <div className='message__info__request w100 m-lef-5'>
                        <p>
                            <span className='fz-13' style={{ fontWeight: '300' }}>
                                <strong className='fz-13'>Atenção</strong>: Leia atentamente o contrato, esta operação não poderá ser alterada ou desfeita após você confirmar a contratação dos serviços.
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="content">
                <div className='box-password-contract'>
                    <div className='box-input-single m-top-20'>
                        <label>
                            <div className='title-input d-fx d-al-cen p-rlt'>
                                <strong className='fz-12 m-rig-5 m-bot-5'>Desenhe sua assinatura</strong>
                            </div>
                        </label>
                        <div id="signature__drawer" className={(signatureSaved) ? 'saved d-fx d-al-rig' : 'd-fx d-al-rig'} style={(props.responsivoSize && props.responsivoSize > 550) ? { flexDirection: 'row' } : { flexDirection: 'column' }}>
                            <div className={(props.responsivoSize && props.responsivoSize > 550) ? 'w65 p-rlt' : 'w100 p-rlt'}>
                                <SignaturePad
                                    ref={signCanvas}
                                    minWidth={.75}
                                    maxWidth={1}
                                    canvasProps={{ width: 350, height: 100, className: (!signatureValid) ? "signature__canvas invalid" : "signature__canvas", dotSize: 2 }}
                                    onBegin={change}
                                />
                                <div className='p-rlt'>

                                    {
                                        (!signatureValid)
                                            ?
                                            <div className='info-invalid d-fx d-al-cen m-top-10 p-abs'>
                                                <strong className='icon-invalid'><TiInfo /></strong>
                                                <strong className='text-invalid m-lef-5'>A sua assinatura não pode ser em branco</strong>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>

                            <div className={(!signatureValid && (!props.responsivoSize || props.responsivoSize < 550)) ? 'wrapper__btn__drawer m-top-40' : 'wrapper__btn__drawer'}>
                                <button id='signclear' class={(props.responsivoSize && props.responsivoSize > 550) ? "btn__tooltip m-lef-15 btn__simple__clear" : "btn__tooltip btn__simple__clear"} disabled={(signatureValid && !signatureSaved) ? false : true} onClick={clear} style={{ maxWidth: '40px', maxHeight: '40px' }}>
                                    <AiOutlineClear className='fz-25' style={{ padding: '3px', color: '#ed4d4d' }} />
                                    <span class="btn__tooltiptext ">Limpar</span>
                                </button>
                                <button id='signsave' class="btn__tooltip m-lef-15 btn__simple__save" disabled={(signatureValid && !signatureSaved) ? false : true} onClick={save} style={{ maxWidth: '40px', maxHeight: '40px' }}>
                                    <GiSave className='fz-25' style={{ padding: '3px', color: 'green' }} />
                                    <span class="btn__tooltiptext">Salvar</span>
                                </button>
                                <button class="btn__tooltip m-lef-15 btn__simple__reload" disabled={(signatureSaved) ? false : true} onClick={redo} style={{ maxWidth: '40px', maxHeight: '40px' }}>
                                    <AiOutlineReload className='fz-25' style={{ padding: '3px', color: '#333' }} />
                                    <span class="btn__tooltiptext">Refazer</span>
                                </button>
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>{/*box-input-single */}

                    <div className={(props.responsivoSize && props.responsivoSize > 550) ? 'box-input-single m-top-35' : 'box-input-single m-top-10'}>
                        <label>
                            <div className='title-input d-fx d-al-cen p-rlt'>
                                <strong className='fz-12 m-rig-5 m-bot-5'>Digite sua senha do site</strong>
                            </div>
                        </label>

                        <div className='p-rlt'>
                            <input
                                id='senha-cadastro-singup'
                                className={(!senha || !senha.length) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                type={(passStateView) ? 'text' : 'password'}
                                name="senha"
                                placeholder='Insira sua senha'
                                autoComplete='off'
                                value={senha}
                                onChange={(e) => setSenha(e.target.value)}
                            />
                            <button className='btn__toggle__state__password p-abs' onClick={() => setPassStateView((prevState) => !prevState)}>
                                {
                                    (passStateView)
                                        ?
                                        <RiEyeFill />
                                        :
                                        <RiEyeCloseLine />
                                }
                            </button>
                        </div>
                        {
                            (!senha || !senha.length)
                                ?
                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                    <strong className='icon-invalid'><TiInfo /></strong>
                                    <strong className='text-invalid m-lef-5'>A senha não pode ser vázia</strong>
                                </div>
                                : null
                        }
                    </div>{/*box-input-single */}
                </div>
            </div>
            <div className={(props.responsivoSize && props.responsivoSize > 350) ? 'w60 right tx-rig' : 'w100 right tx-rig'}>
                <button id='btn__signature__progress' class="continue-application" onClick={(e) => validarSenha(e)} disabled={(signatureSaved && senha.length) ? false : true}>
                    <div>
                        <div class="pencil"></div>
                        <div class="folder">
                            <div class="top">
                                <svg viewBox="0 0 24 27">
                                    <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                                </svg>
                            </div>
                            <div class="paper"></div>
                        </div>
                    </div>
                    {(props.openModal.usuario && props.openModal.usuario.length && props.openModal.usuario === "representante") ? "Assinar Contrato" : "Confirmar Contratação"}
                </button>
            </div>
            <div className='clear'></div>
        </div>
    )
}

export default ModalAssinaturaContrato;