import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

function DadosPedido(props) {
    const [dadosPedido, setDadosPedido] = useState([]);
    useMemo(async () => {
        // RECEBER DADOS DO PEDIDO
        // console.warn(props.dadosCobranca);

        // axios.post("https://config.eengeart.com/recuperar-pedido", { pedido: props.dadosCobranca.id, referencia: props.dadosCobranca.externalReference })
        try {
            // await axios.get("https://config.eengeart.com/recuperar-pedido?pedido=" + props.dadosCobranca.id)
            await axios.get("http://localhost:5000/recuperar-pedido?pedido=" + props.dadosCobranca.id)
                .then((result) => {
                    if (result.data && result.data.status && result.data.status == 'success') {
                        setDadosPedido(result.data.dados_recuperados);
                    } else if (result.data && result.data.status && result.data.status == 'Error') {
                        toast.error(result.data.message);
                    } else {
                        toast.error("Ocorreu um erro ao recuperar os itens do pedido");
                        console.log(result);
                    }
                })
                .catch((err) => {
                    console.log("Teve Erro: ", err);
                })
        } catch (error) {
            toast.error(error.message)
        }
    }, [])

    return (
        <>
            {
                (dadosPedido && dadosPedido.length)
                    ?
                    dadosPedido.map((infosProduto) => {
                        return (
                            <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'itens__singel__request d-fx d-al-cen d-ju-sp-bt w100 p-bot-15 m-bot-20' : 'itens__singel__request d-fx d-al-cen d-ju-sp-bt d-fw-wp w100 p-bot-15 m-bot-20'}>
                                <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'item__thumb__request w50 d-fx' : 'item__thumb__request w100 d-fx'} style={(props.responsivoSize && props.responsivoSize < 480) ? { flexWrap: 'wrap' } : null}>
                                    <img className={(props.responsivoSize && props.responsivoSize > 480) ? 'img__thumb__product w25 m-rig-20' : 'img__thumb__product w100 m-rig-20 m-bot-10'} src={infosProduto.capa} style={{ borderRadius: '8px', padding: '2px', border: '1px solid #ccc', aspectRatio: '16 / 9', height: 'max-content' }} />
                                    <div className='infos__thumb__product d-fx d-fd-col d-ju-sp-bt'>
                                        <Link to={`/projetos/${infosProduto.cod_produto}`} className='fz-18 d-bk'>{infosProduto.titulo}</Link>
                                        <div className='details__product'>
                                            <small className='d-bk w100 info-default m-top-5 fz-11 b-bot-small'>Cód.: <strong>E{infosProduto.cod_produto}A</strong></small>
                                            <small className='d-bk w100 info-default m-top-5'>Vendido e entregue por <strong>Engeart</strong></small>
                                        </div>
                                    </div>
                                </div>
                                <div className={(props.responsivoSize && props.responsivoSize > 768) ? 'info__item__product w50' : 'info__item__product w100 b-top'}>
                                    <div className={(props.responsivoSize && props.responsivoSize > 380) ? 'box__infos__product d-fx d-ju-sp-bt' : 'box__infos__product d-fx d-ju-sp-bt d-fd-col'}>
                                        <div className={(props.responsivoSize && props.responsivoSize > 380) ? 'info__single__product d-bk' : 'info__single__product d-bk'}>
                                            <div className='info__title__product fz-14 tx-bold tx-grey m-bot-5'>
                                                Qtde.
                                            </div>
                                            <span className='fz-13'>
                                                {infosProduto.quantidade}
                                            </span>
                                        </div>
                                        <div className={(props.responsivoSize && props.responsivoSize > 380) ? 'info__single__product d-bk' : 'info__single__product d-bk m-top-10'}>
                                            <div className='info__title__product fz-14 tx-bold tx-grey m-bot-5'>
                                                Valor unit.
                                            </div>
                                            <span className='fz-13'>
                                                {
                                                    (infosProduto.preco)
                                                        ?
                                                        infosProduto.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                        : null
                                                }
                                            </span>
                                        </div>
                                        <div className={(props.responsivoSize && props.responsivoSize > 380) ? 'info__single__product d-bk' : 'info__single__product d-bk m-top-10'}>
                                            <div className='info__title__product fz-14 tx-bold tx-grey m-bot-5'>
                                                Subtotal
                                            </div>
                                            <span className='fz-13'>
                                                {
                                                    (infosProduto.preco && infosProduto.quantidade)
                                                        ?
                                                        (infosProduto.preco * infosProduto.quantidade).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                        : null
                                                }
                                            </span>
                                        </div>
                                        <div className={(props.responsivoSize && props.responsivoSize > 380) ? 'info__single__product d-bk' : 'info__single__product d-bk m-top-10'}>
                                            <div className='info__title__product fz-14 tx-bold tx-grey m-bot-5'>
                                                Status
                                            </div>
                                            {
                                                (props.dadosCobranca.status)
                                                    ?
                                                    <span className='fz-13'>
                                                        Retirado
                                                    </span>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : null
            }
        </>
    )
}

export default DadosPedido;