// import Api from "../../../../../../services/Api";
import axios from "axios";
import InputMask from "react-input-mask"; // MASCARA DE INPUT

// IMPORTAÇÃO DE ICONES
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo, TiInputChecked } from 'react-icons/ti';
import { useEffect } from "react";
import { toast } from "react-hot-toast";

// REACT ROUTER DOM
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../../../../../../firebase";

// IMAGENS
import brandModel from "../../../../../../images/icones/png/cartao-bandeira-mastercard.png"
import contactUs from "../../../../../../images/icones/svg/promocao.svg"
import { isArray, isEmptyObject } from "jquery";
import CardsCartaoTokenizado from "./CardsCartaoTokenizado";

const { useState, useMemo } = require("react");

function FormPaymentCreditCard(props) {
    let navigate = useNavigate();
    const [walletCards, setWalletCards] = useState([]);

    // HOOKS - NOME
    const [name, setName] = useState();
    const [nameMessage, setNameMessage] = useState([]);
    const [nameValid, setNameValid] = useState(false);

    // HOOKS - CPF
    const [CPF, setCPF] = useState();
    const [CPFMessage, setCPFMessage] = useState([]);
    const [CPFValid, setCPFValid] = useState(false);

    // HOOKS - NUMERO DO CARTÃO
    const [cardNum, setCardNum] = useState();
    const [cardNumMessage, setCardNumMessage] = useState([]);
    const [cardNumValid, setCardNumValid] = useState(false);

    // HOOKS - CVV DO CARTÃO
    const [CVVNum, setCVVNum] = useState();
    const [CVVNumMessage, setCVVNumMessage] = useState([]);
    const [CVVNumValid, setCVVNumValid] = useState(false);

    // HOOKS - CVV DO CARTÃO
    const [mesNum, setMesNum] = useState();
    const [mesNumMessage, setMesNumMessage] = useState([]);
    const [mesNumValid, setMesNumValid] = useState(false);

    // HOOKS - CVV DO CARTÃO
    const [anoNum, setAnoNum] = useState();
    const [anoNumMessage, setAnoNumMessage] = useState([]);
    const [anoNumValid, setAnoNumValid] = useState(false);

    const [parcelasCartao, setParcelasCartao] = useState();
    const [toggleSelect, setToggleSelect] = useState(false);

    // DADOS DE PARCELAMENTO
    const [installmetQnt, setInstallmetQnt] = useState([]);

    // TOKENIZAR CARTAO
    const [tokenizarCartao, setTokenizarCartao] = useState(false);

    // CARTEIRA
    const [carteiraCartoes, setCarteiraCartoes] = useState([]);
    const [cartaoSelecionado, setCartaoSelecionado] = useState([]);
    const [carteiraNovoCartao, setCarteiraNovoCartao] = useState(false);


    const [btnDisabled, setBtnDisabled] = useState(false); // BOTÃO DE TESTE

    useEffect(() => {
        auth.onAuthStateChanged((val) => {
            db.collection("usuarios").doc(val.uid)
                .collection("dados")
                .doc("carteira")
                .collection("cartoes")
                .onSnapshot((dadosCartoes) => {
                    var arrCards = ([]);

                    dadosCartoes.docs.map((dadosCartao) => {
                        arrCards.unshift(dadosCartao.data());
                    })

                    setCarteiraCartoes(arrCards);
                })
        })

    }, [])

    useMemo(() => {
        console.log(carteiraCartoes);
    }, [carteiraCartoes])

    useMemo(() => {
        if (name) {
            // VERIFICAÇÃO DE LETRAS EM SENHA
            var padrao = /[^a-zà-ú\s-]/gi;

            var valida_nome = name.match(padrao);

            if (valida_nome) {
                setNameValid(false);
                setNameMessage([{
                    'status': 'error',
                    'message': 'O nome deve conter apenas letras'
                }])
            } else {
                //O NOME É VÁLIDO CONTEM APENAS LETRAS -> VERIFICAR SE O NOME É COMPLETO
                var arrName = name.split(' ');
                if (arrName.length <= 1) {
                    // O USUARIO NÃO PASSOU O NOME COMPLETO
                    setNameValid(false);
                    setNameMessage([{
                        'status': 'error',
                        'message': 'O nome deve ser completo'
                    }])
                } else {
                    // O USUARIO PASSOU O NOME COMPLETO
                    setNameValid(true);
                    setNameMessage([])
                }
            }
        }
    }, [name])

    useMemo(() => {
        // PEGAR TODOS OS USUARIOS
        if (CPF) {
            // var newCPF = CPF.replace(".", "").replace(".", "").replace("-", "");
            var newCPF = CPF.replace(/[^0-9]/g, "");

            if (newCPF.length == 11) {
                var er = /^[0-9]+$/;
                if (!er.test(newCPF)) {
                    // O CPF CONTEM OUTROS CARACTERES ALEM DE NUEMROS -> MOSTRAR MENSAGEM DE ERRO
                    setCPFMessage([{
                        'status': 'error',
                        'message': 'O CPF deve contem apenas números'
                    }])
                    // console.log(newCPF)
                } else {
                    // O CPF CONTEM APENAS NÚMEROS -> PODEMOS CONTINUAR COM A VALIÇÃO SE ELE É VÁLIDO
                    var cpf = CPF;
                    // Codigo relativo ao video 3

                    function isCPF(cpf = 0) {
                        // console.log(cpf);
                        cpf = cpf.replace(/\.|-/g, "");
                        // console.log(cpf);
                        if (!validaPrimeiroDigito(cpf)) {
                            return false;
                        }
                        if (!validaSegundoDigito(cpf)) {
                            return false;
                        }
                        if (cpf.length != 11 ||
                            cpf == "00000000000" ||
                            cpf == "11111111111" ||
                            cpf == "22222222222" ||
                            cpf == "33333333333" ||
                            cpf == "44444444444" ||
                            cpf == "55555555555" ||
                            cpf == "66666666666" ||
                            cpf == "77777777777" ||
                            cpf == "88888888888" ||
                            cpf == "99999999999") {
                            return false;
                        }
                        return true;

                    }
                    var sum = 0;

                    function validaPrimeiroDigito(cpf = null) {
                        let fDigit = (sumFristDigit(cpf) * 10) % 11;
                        fDigit = (fDigit == 10 || fDigit == 11) ? 0 : fDigit;
                        if (fDigit != cpf[9])
                            return false
                        return true;
                    }
                    function validaSegundoDigito(cpf = null) {
                        let sDigit = (sumSecondDigit(cpf) * 10) % 11;
                        sDigit = (sDigit == 10 || sDigit == 11) ? 0 : sDigit;

                        if (sDigit != cpf[10])
                            return false
                        return true;
                    }


                    function sumFristDigit(cpf, position = 0, sum = 0) {
                        if (position > 9)
                            return 0;
                        return sum + sumFristDigit(cpf, position + 1, cpf[position] * ((cpf.length - 1) - position));
                    }


                    function sumSecondDigit(cpf, position = 0, sum = 0) {
                        if (position > 10)
                            return 0;
                        return sum + sumSecondDigit(cpf, position + 1, cpf[position] * ((cpf.length) - position));
                    }

                    if (!isCPF(cpf)) { // O CPF NÃO É VÁLIDO
                        setCPFMessage([{
                            'status': 'error',
                            'message': 'O CPF informado não é válido'
                        }])
                    } else { // O CPF É NUMÉRICO E VÁLIDO -> PODEMOS CONTINUAR COM A VALIDAÇÃO
                        if (newCPF.length == 11 && props.cadastros) { // VALIDANDO CADASTRO EXISTENTE
                            var produtosEletrodomestico = props.cadastros.filter(produto => produto.cpf == parseInt(newCPF));
                            if (produtosEletrodomestico.length > 0) {
                                // EXISTE UM CPF JÁ CADASTRADO NO BANCO DE DADOS -> MOSTRAR MENSAGEM DE ERRO
                                setCPFMessage([{
                                    'status': 'error',
                                    'message': 'O CPF informado já está vinculado a uma conta existente'
                                }])
                            } else {
                                // NÃO EXISTE NENHUM CPF CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
                                setCPFValid(true);
                                setCPFMessage([]);
                                // {
                                //     'status': 'success',
                                //     'message': 'O CPF informado é válido!'
                                // }
                            }
                        }
                    }
                }
            }
            // if (value.length == 8) {
            //     console.log(value)
            // }

            // VERIFICANDO SE O CPF É VÁLIDO 
        }

    }, [CPF])

    // FUNÇÕES DO SELECT
    const optionsList = document.querySelectorAll(".option");

    function selectedMain(val) {
        document.querySelector(".options-container." + val).classList.toggle("active");

        document.querySelector("." + val + ".search-box input").value = "";
        filterList("");

        if (document.querySelector(".options-container." + val).classList.contains("active")) {
            document.querySelector("." + val + ".search-box input").focus();
        }
    };
    function selectedOption(o, val, qntParc) {
        // document.querySelector(".selected__option." + val).innerHTML = o.target.querySelector("label").innerHTML;
        document.querySelector(".options-container." + val).classList.remove("active");
        setParcelasCartao(qntParc);
        // setToggleSelect(false);
    };
    function typingSearch(e) {
        filterList(e.target.value);
    };

    const filterList = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        optionsList.forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function sendFormPayment(e) {
        e.preventDefault();
        props.setBtnPaymentDisabled(true);
        /* */
        let valorCobranca = totalFinal();
        let valorSemTaxas = valorCobranca;
        let valorComTaxas = (() => {
            var valorCobrancaComTaxas = 0;
            let valorTaxaAM = 1.99; // VALOR DA TAXA AO MES
            var valorParcela = valorCobranca / parcelasCartao; // AQUI TEMOS O VALOR DA PARCELA SEM JUROS
            var valorTaxa = (valorParcela / 100) * valorTaxaAM; // AQUI TEMOS O VALOR DAS TAXAS POR PARCELA MENSAL
            var valorAcumulado = (valorParcela + valorTaxa) * parcelasCartao;
            return valorAcumulado;
        })();

        let infos = {
            dados_cobranca: {
                billingType: "CREDIT_CARD",
                totalValue: (parcelasCartao > 6) ? valorComTaxas : valorSemTaxas,
                installmentCount: parcelasCartao,
                tipo_transacao: props.paymentMethod,
                description: (() => {
                    var produtos = ([]);
                    props.cart.map((dadosProdutos) => {
                        produtos.unshift(dadosProdutos.titulo + ' • E' + dadosProdutos.id + 'A');
                    })
                    produtos = produtos.toString().replace(/,/g, '\n');
                    return (produtos);
                })(),
                externalReference: Math.floor(Math.random() * Date.now()),
                vencimento: (() => {
                    var data = new Date();
                    console.log(data.getDate()); // 14
                    data.setDate(data.getDate() + 5); // AQUI ESTOU ADICIONANDO O NUMERO DE DIAS PARA O VENCIMENTO

                    // console.log(data.getDay());
                    // console.log(data.toLocaleDateString("pt-BR")); // 19
                    if (data.getDay() == 6) {
                        data.setDate(data.getDate() + 2);
                        // console.log(data.toLocaleDateString("pt-BR")); // 19
                        var dataBrasileira = data.toLocaleDateString("pt-BR");
                        var dataSeparada = dataBrasileira.split("/");
                        var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                        return (novaData)
                    } else if (data.getDay() == 0) {
                        data.setDate(data.getDate() + 1);
                        // console.warn(data.toLocaleDateString("pt-BR")); // 19
                        var dataBrasileira = data.toLocaleDateString("pt-BR");
                        var dataSeparada = dataBrasileira.split("/");
                        var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                        return (novaData)
                    } else {
                        // console.error(data.toLocaleDateString("pt-BR")); // 19
                        var dataBrasileira = data.toLocaleDateString("pt-BR");
                        var dataSeparada = dataBrasileira.split("/");
                        var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                        return (novaData)
                    }
                })()
            },
            dados_cartao: (!isEmptyObject(cartaoSelecionado)) ? { card_token: cartaoSelecionado.infosCard }
                : {
                    cpf: CPF,
                    nome: name,
                    cardNumber: cardNum.replace(/ /g, ""),
                    ccvNumber: CVVNum.replace(/[^0-9]/g, ""),
                    expiryMonth: mesNum.replace(/[^0-9]/g, ""),
                    expiryYear: anoNum.replace(/[^0-9]/g, "")
                }
            ,
            dados_comprador: props.user,
            dados_contato: {
                endereco: props.enderecoComprador[0],
                telefones: {
                    principal: props.telefoneComprador
                }
            },
            tokenizar: tokenizarCartao,
            dados_pedido: (() => {
                var produtos = ([]);
                props.cart.map((dadosProdutos) => {
                    produtos.unshift(dadosProdutos);
                })
                return (produtos)
            })()
        }
        // console.log(infos);
        axios.post((!isEmptyObject(cartaoSelecionado)) ? "https://eengeart.com.br/pagamentos/credito/token" : 'https://eengeart.com.br/pagamentos/credito/card', {
            dados_cobranca: {
                billingType: "CREDIT_CARD",
                totalValue: (parcelasCartao > 6) ? valorComTaxas : valorSemTaxas,
                installmentCount: parcelasCartao,
                tipo_transacao: props.paymentMethod,
                description: (() => {
                    var produtos = ([]);
                    props.cart.map((dadosProdutos) => {
                        produtos.unshift(dadosProdutos.titulo + ' • E' + dadosProdutos.id + 'A');
                    })
                    produtos = produtos.toString().replace(/,/g, '\n');
                    return (produtos);
                })(),
                externalReference: Math.floor(Math.random() * Date.now()),
                vencimento: (() => {
                    var data = new Date();
                    console.log(data.getDate()); // 14
                    data.setDate(data.getDate() + 5); // AQUI ESTOU ADICIONANDO O NUMERO DE DIAS PARA O VENCIMENTO

                    // console.log(data.getDay());
                    // console.log(data.toLocaleDateString("pt-BR")); // 19
                    if (data.getDay() == 6) {
                        data.setDate(data.getDate() + 2);
                        // console.log(data.toLocaleDateString("pt-BR")); // 19
                        var dataBrasileira = data.toLocaleDateString("pt-BR");
                        var dataSeparada = dataBrasileira.split("/");
                        var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                        return (novaData)
                    } else if (data.getDay() == 0) {
                        data.setDate(data.getDate() + 1);
                        // console.warn(data.toLocaleDateString("pt-BR")); // 19
                        var dataBrasileira = data.toLocaleDateString("pt-BR");
                        var dataSeparada = dataBrasileira.split("/");
                        var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                        return (novaData)
                    } else {
                        // console.error(data.toLocaleDateString("pt-BR")); // 19
                        var dataBrasileira = data.toLocaleDateString("pt-BR");
                        var dataSeparada = dataBrasileira.split("/");
                        var novaData = dataSeparada[2] + "-" + dataSeparada[1] + "-" + dataSeparada[0];
                        return (novaData)
                    }
                })()
            },
            dados_cartao: (!isEmptyObject(cartaoSelecionado)) ? { card_token: cartaoSelecionado.infosCard }
                : {
                    cpf: CPF,
                    nome: name,
                    cardNumber: cardNum.replace(/ /g, ""),
                    ccvNumber: CVVNum.replace(/[^0-9]/g, ""),
                    expiryMonth: mesNum.replace(/[^0-9]/g, ""),
                    expiryYear: anoNum.replace(/[^0-9]/g, "")
                }
            ,
            dados_comprador: props.user,
            dados_contato: {
                endereco: props.enderecoComprador[0],
                telefones: {
                    principal: props.telefoneComprador
                }
            },
            tokenizar: tokenizarCartao,
            dados_pedido: (() => {
                var produtos = ([]);
                props.cart.map((dadosProdutos) => {
                    produtos.unshift(dadosProdutos);
                })
                return (produtos)
            })()
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error) { // EXISTE ERRO
                        if (response.data.error == "Error" && !isArray(response.data.message)) {
                            toast.error("Ops! Ocorreu um erro ao processar seu pagamento :(");
                            toast.error(response.data.message);
                            props.setBtnPaymentDisabled(false);
                        } else if (response.data.error == "Error" && isArray(response.data.message)) {
                            toast.error("Ops! Ocorreu um erro ao processar seu pagamento :(");
                            for (let i = 0; i < response.data.message.length; i++) {
                                toast.error(response.data.message[i].description);
                            }
                            props.setBtnPaymentDisabled(false);
                        } else {
                            toast.error("Ops! Ocorreu um erro ao processar seu pagamento :(");
                            props.setBtnPaymentDisabled(false);
                        }
                    } else if (response.data.success) {
                        // CADASTRAR TOKENIZACAO DE CARTÃO DE CREDITO
                        if (tokenizarCartao) {
                            // CADASTRAR DADOS DO CARTAO DO USUARIO
                            var arrPedido = response.data.message;
                            arrPedido.dados_pedido = props.cart;
                            auth.onAuthStateChanged(async (val) => {
                                db.collection("usuarios")
                                    .doc(val.uid)
                                    .collection("pedidos")
                                    .add(arrPedido)
                                    .then(() => {
                                        db.collection("usuarios")
                                            .doc(props.user.id)
                                            .collection("dados")
                                            .doc("carteira")
                                            .collection("cartoes")
                                            .add({
                                                token: response.data.message.creditCard.creditCardToken,
                                                numero: response.data.message.creditCard.creditCardNumber,
                                                bandeira: response.data.message.creditCard.creditCardBrand,
                                                vencimento_mes: mesNum.replace(/[^0-9]/g, ""),
                                                vencimento_ano: anoNum.replace(/[^0-9]/g, ""),
                                                cvv: CVVNum.replace(/[^0-9]/g, ""),
                                            })
                                            .then(() => {
                                                toast.success("Pagamento efetuado com sucesso!");
                                                navigate("/pedido/pagamento/sucesso/" + response.data.message.externalReference);
                                            })
                                            .catch((error) => {
                                                toast.error(error);
                                                props.setBtnPaymentDisabled(false);
                                            })
                                    })
                                    .catch((error) => {
                                        toast.error(error);
                                        props.setBtnPaymentDisabled(false);
                                    })
                            });
                        } else {
                            // console.log(response);
                            // CADASTRAR DADOS DO CARTAO DO USUARIO
                            var arrPedido = response.data.message;
                            arrPedido.dados_pedido = props.cart;
                            auth.onAuthStateChanged(async (val) => {
                                db.collection("usuarios")
                                    .doc(val.uid)
                                    .collection("pedidos")
                                    .add(arrPedido)
                                    .then(() => {
                                        toast.success("Pagamento efetuado com sucesso!");
                                        navigate("/pedido/pagamento/sucesso/" + response.data.message.externalReference);
                                    })
                                    .catch((error) => {
                                        toast.error(error);
                                        props.setBtnPaymentDisabled(false);
                                    })
                            })
                        }
                    }
                }
                // if (response && response.data) {
                //     if (!response.data.success && response.data.errors) {
                //         // console.log(response)
                //         // toast.error("Ops! Ocorreu um erro ao processar seu pagamento :(");
                //         for (let i = 0; i < response.data.errors.length; i++) {
                //             toast.error(response.data.errors[i].description);
                //         }
                //         setBtnDisabled(false)
                //     } else {
                //         console.log(response)
                //         toast.success("Pagamento efetuado com sucesso!")
                //         setBtnDisabled(false);

                //         // CADASTRAR DADOS DO CARTAO DO USUARIO
                //         db.collection("usuarios")
                //             .doc(props.user.id)
                //             .collection("pedidos")
                //             .add(
                //                 response.data.asaas_cobranca
                //             )

                //         // CADASTRAR TOKENIZACAO DE CARTÃO DE CREDITO
                //         if (tokenizarCartao) {
                //             db.collection("usuarios")
                //                 .doc(props.user.id)
                //                 .collection("dados")
                //                 .doc("carteira")
                //                 .collection("cartoes")
                //                 .add({
                //                     token: response.data.asaas_tokenizacao_cartao.creditCardToken,
                //                     numero: response.data.asaas_tokenizacao_cartao.creditCardNumber,
                //                     bandeira: response.data.asaas_tokenizacao_cartao.creditCardBrand,
                //                     vencimento_mes: mesNum.replace(/[^0-9]/g, ""),
                //                     vencimento_ano: anoNum.replace(/[^0-9]/g, ""),
                //                     cvv: CVVNum.replace(/[^0-9]/g, ""),
                //                 })
                //         }
                //     }
                // } else {
                //     toast.error("Não foi possivel processar seu pagamento :(")
                // }
                // console.log(response)
                // setBtnDisabled(false)
            })
            .catch((err) => {
                setBtnDisabled(false)
                console.error("ops! ocorreu um erro: ", err);
            });
    }

    function totalFinal() {
        let amountProduct = 0; // SOMA DE VALORES DOS PRODUTOS
        let amountShipping = 0; // SOMA DE FRETE DOS PRODUTOS
        let amountDiscount = 0; // CALCULO DE DESCONTOS DOS PRODUTOS

        // LOOPING SOMAR VALORES DOS PRODUTOS
        props.cart.map((i) => {
            amountProduct += i.precoTabela * i.quantidade
        })

        // LOOPING SOMAR FRETE DOS PRODUTOS
        props.cart.map((i) => {
            amountShipping += i.frete.online.preco
        })

        // LOOPING SOMAR DESCONTO DOS PRODUTOS
        props.cart.map((i) => {
            amountDiscount += ((i.precoTabela / 100) * i.desconto) * i.quantidade;
        })

        return ((amountProduct + amountShipping) - amountDiscount);
    }

    function calcInstallmetQnt() {
        // console.log(props.cart)
        if (props.cart.length) {
            let valorTotal = parseInt(totalFinal().toFixed(2));

            // if (valorTotal < 10 ) {
            //     // APENAS 1X
            // } else if (valorTotal < 15) {
            //     // APENAS 2X
            // } else if (valorTotal < 20) {
            //     // APENAS 3X
            // } else if (valorTotal < 25) {
            //     // APENAS 4X
            // } else if (valorTotal < 30) {
            //     // APENAS 5X
            // } else if (valorTotal < 35) {
            //     // APENAS 6X
            // } else if (valorTotal < 40) {
            //     // APENAS 7X
            // }
            var arr = ([]);
            for (let i = 0; i <= valorTotal; i += 5) {
                if (i > 0 && i <= 90) {
                    arr.push(i)
                }
            }
            var arrInstallmetQnt = ([]);
            for (let i = 1; i <= arr.length; i++) {
                if (i > 0 && i <= 90) {
                    arrInstallmetQnt.push(i)
                }
            }
            return arrInstallmetQnt;
        }
    }

    function calcInstallmetValue(installment) {
        if (props.cart.length) {
            let valorTotal = parseInt(totalFinal().toFixed(2));
            let valorParcelado = (valorTotal / installment);
            let valorTaxaAM = 1.99;
            // if (valorParcelado) {
            //     return valorParcelado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            // }

            // 1- ACHAR O VALOR EM REAIS DA PORCENTAGEM EM CIMA DO TOTAL DA PARCELA
            if (installment > 6) {
                // APLICAR TAXA DE PARCELAMENTO
                let valorTaxa = (valorParcelado / 100) * valorTaxaAM;
                // console.log(valorTaxa);
                valorParcelado = valorParcelado + valorTaxa;
                return valorParcelado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            } else {
                // ENVIAR VALOR SEM TAXA
                return valorParcelado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            }
        }
    }

    function adicionarNovoCartaoCredito(e) {
        e.preventDefault();
        setCarteiraNovoCartao(true);
    }

    // function verificaCodigoCartao() {
    //     if (cartaoSelecionado.infosCard.cvv == CVVNum.replace(/[^0-9]/g, "")) {
    //         setWalletCardCvvValid(true);
    //     } else {
    //         setWalletCardCvvValid(false);
    //     }
    // }

    // console.log(cartaoSelecionado)
    // console.log(walletCardCvvValid)
    // console.log(CVVNum)
    return (
        <>
            <form id='dados-form-pagamento-credito' className='singup-person w100' onSubmit={(e) => sendFormPayment(e)}>
                <div className="d-fx d-fw-wp wrapper-cards m-g-0">
                    {/* <div>
                        <h2 className="fz-22">Selecione um cartão</h2>
                        <CardsCartaoTokenizado carteiraCartoes={carteiraCartoes} cartaoSelecionado={cartaoSelecionado} carteiraNovoCartao={carteiraNovoCartao} setCarteiraNovoCartao={setCarteiraNovoCartao} setCartaoSelecionado={setCartaoSelecionado} parcelasCartao={parcelasCartao} setParcelasCartao={setParcelasCartao} responsivoSize={props.responsivoSize} />
                    </div> */}

                    <div className="w100 d-fx d-al-cen m-top-15">
                        {
                            (!carteiraNovoCartao && (!cartaoSelecionado || !cartaoSelecionado.length))
                                ?
                                <div className="d-fx d-fw-wp w50">
                                    <h2 className="fz-22">Adicionar cartão de crédito</h2>
                                    <button class="card add-card" data-add-card="" onClick={(e) => adicionarNovoCartaoCredito(e)}>
                                        <div>
                                            <span class="add-icon">+</span>
                                            <span className="fz-16">Adicionar cartão</span>
                                        </div>
                                    </button>
                                </div>
                                : null
                        }
                        <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__formulario__imagem column-xs-12 column-md-5 w50 m-0 p-top-0" : "wrapper__formulario__imagem column-xs-12 column-md-5 w100 m-0 p-top-0"}>
                            {
                                (carteiraNovoCartao && (!cartaoSelecionado || !cartaoSelecionado.length))
                                    ?
                                    // JÁ EXISTE CARTÃO CADASTRADO NA CARTEIRA DO USUARIO
                                    <form id='dados-form-pagamento-credito-2' className='singup-person' onSubmit={(e) => sendFormPayment(e)}>
                                        <h2 className="fz-22">Novo Cartão de Crédito</h2>

                                        <div className='box-input-single m-top-20'>
                                            <label>
                                                <div className='title-input d-fx d-al-cen p-rlt'>
                                                    <span className='fz-12 m-rig-5'>CPF do títular do cartão</span>
                                                    <span className='icon-info-add p-rlt'>
                                                        <IoIosInformationCircleOutline />
                                                        <div className='helper-input tx-black d-nn'>
                                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                <span class="css-1i1x8nz">
                                                                    Seu CPF será usado para:
                                                                </span>
                                                                <div class="css-1l7kih-stack">
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                            </div>
                                                        </div>{/*Helper*/}
                                                    </span>
                                                </div>
                                            </label>

                                            {
                                                (() => {

                                                    if (CPFValid && !CPFMessage) {
                                                        return (
                                                            <InputMask id='cpf-cadastro-singup' className='input-form w100 fz-16 m-top-5 valid 1' mask="999.999.999-99" placeholder='999.999.999-99' value={CPF} onChange={(ev) => setCPF(ev.target.value)} />
                                                        )
                                                    } else {
                                                        if (CPFMessage && CPFMessage.length > 0) {
                                                            return (
                                                                CPFMessage.map((infos) => {
                                                                    if (infos.status == 'error') {
                                                                        return (
                                                                            <InputMask id='cpf-cadastro-singup' className='input-form w100 fz-16 m-top-5 invalid 2' mask="999.999.999-99" placeholder='999.999.999-99' value={CPF} onChange={(ev) => setCPF(ev.target.value)} />
                                                                        )
                                                                    } else if (infos.status == 'success') {
                                                                        return (
                                                                            <InputMask id='cpf-cadastro-singup' className='input-form w100 fz-16 m-top-5 valid 3' mask="999.999.999-99" placeholder='999.999.999-99' value={CPF} onChange={(ev) => setCPF(ev.target.value)} />
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <InputMask id='cpf-cadastro-singup' className='input-form w100 fz-16 m-top-5 4' mask="999.999.999-99" placeholder='999.999.999-99' value={CPF} onChange={(ev) => setCPF(ev.target.value)} />
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        } else {
                                                            return (
                                                                <InputMask id='cpf-cadastro-singup' className='input-form w100 fz-16 m-top-5 5' mask="999.999.999-99" placeholder='999.999.999-99' value={CPF} onChange={(ev) => setCPF(ev.target.value)} />
                                                            )
                                                        }
                                                    }
                                                })()
                                            }
                                            {
                                                (CPFMessage)
                                                    ?
                                                    CPFMessage.map((infoStatus) => {
                                                        if (infoStatus.status == 'error') {
                                                            return (
                                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-invalid'><TiInfo /></span>
                                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        } else if (infoStatus.status == 'success') {
                                                            return (
                                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-valid'><TiInfo /></span>
                                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    : null
                                            }
                                        </div>{/*box-input-single */}

                                        <div className='box-input-single m-top-20'>
                                            <label>
                                                <div className='title-input d-fx d-al-cen p-rlt'>
                                                    <span className='fz-12 m-rig-5'>Nome Completo do títular do cartão</span>
                                                    <span className='icon-info-add p-rlt'>
                                                        <IoIosInformationCircleOutline />
                                                        <div className='helper-input tx-black d-nn'>
                                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                <span class="css-1i1x8nz">
                                                                    Seu CPF será usado para:
                                                                </span>
                                                                <div class="css-1l7kih-stack">
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                            </div>
                                                        </div>{/*Helper*/}
                                                    </span>
                                                </div>
                                            </label>
                                            <input id="nome-cadastro-singup" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                                            {
                                                (nameMessage)
                                                    ?
                                                    nameMessage.map((infoStatus) => {
                                                        if (infoStatus.status == 'error') {
                                                            return (
                                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-invalid'><TiInfo /></span>
                                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        } else if (infoStatus.status == 'success') {
                                                            return (
                                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-valid'><TiInfo /></span>
                                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    : null
                                            }
                                        </div>{/*box-input-single */}

                                        <div className='box-input-single m-top-20'>
                                            <label>
                                                <div className='title-input d-fx d-al-cen p-rlt'>
                                                    <span className='fz-12 m-rig-5'>Número do cartão</span>
                                                    <span className='icon-info-add p-rlt'>
                                                        <IoIosInformationCircleOutline />
                                                        <div className='helper-input tx-black d-nn'>
                                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                <span class="css-1i1x8nz">
                                                                    Seu CPF será usado para:
                                                                </span>
                                                                <div class="css-1l7kih-stack">
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                            <div>
                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                            </div>
                                                                            <div>
                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                            </div>
                                                        </div>{/*Helper*/}
                                                    </span>
                                                </div>
                                            </label>

                                            {
                                                (() => {

                                                    if (cardNumValid && !cardNumMessage) {
                                                        return (
                                                            <InputMask id='pagamento-numero-cartao' className='input-form w100 fz-16 m-top-5 valid 1' mask="9999 9999 9999 9999" placeholder='9999 9999 9999 9999' value={cardNum} onChange={(ev) => setCardNum(ev.target.value)} />
                                                        )
                                                    } else {
                                                        if (cardNumMessage && cardNumMessage.length > 0) {
                                                            return (
                                                                cardNumMessage.map((infos) => {
                                                                    if (infos.status == 'error') {
                                                                        return (
                                                                            <InputMask id='pagamento-numero-cartao' className='input-form w100 fz-16 m-top-5 invalid 2' mask="9999 9999 9999 9999" placeholder='9999 9999 9999 9999' value={cardNum} onChange={(ev) => setCardNum(ev.target.value)} />
                                                                        )
                                                                    } else if (infos.status == 'success') {
                                                                        return (
                                                                            <InputMask id='pagamento-numero-cartao' className='input-form w100 fz-16 m-top-5 valid 3' mask="9999 9999 9999 9999" placeholder='9999 9999 9999 9999' value={cardNum} onChange={(ev) => setCardNum(ev.target.value)} />
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <InputMask id='pagamento-numero-cartao' className='input-form w100 fz-16 m-top-5 4' mask="9999 9999 9999 9999" placeholder='9999 9999 9999 9999' value={cardNum} onChange={(ev) => setCardNum(ev.target.value)} />
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        } else {
                                                            return (
                                                                <InputMask id='pagamento-numero-cartao' className='input-form w100 fz-16 m-top-5 5' mask="9999 9999 9999 9999" placeholder='9999 9999 9999 9999' value={cardNum} onChange={(ev) => setCardNum(ev.target.value)} />
                                                            )
                                                        }
                                                    }
                                                })()
                                            }
                                            {
                                                (cardNumMessage)
                                                    ?
                                                    cardNumMessage.map((infoStatus) => {
                                                        if (infoStatus.status == 'error') {
                                                            return (
                                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-invalid'><TiInfo /></span>
                                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        } else if (infoStatus.status == 'success') {
                                                            return (
                                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-valid'><TiInfo /></span>
                                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    : null
                                            }
                                        </div>{/*box-input-single */}

                                        <div className="d-fx d-al-cen d-al-cen d-ju-sp-bt d-fw-wp">
                                            <div className={(props.responsivoSize && props.responsivoSize > 300) ? 'box-input-single m-top-20 w50 m-lef-0' : 'box-input-single m-top-20 w100 m-lef-0'}>
                                                <label>
                                                    <div className='title-input d-fx d-al-cen p-rlt'>
                                                        <span className='fz-12 m-rig-5'>Código de segurança</span>
                                                        <span className='icon-info-add p-rlt'>
                                                            <IoIosInformationCircleOutline />
                                                            <div className='helper-input tx-black d-nn'>
                                                                <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                    <span class="css-1i1x8nz">
                                                                        Seu CPF será usado para:
                                                                    </span>
                                                                    <div class="css-1l7kih-stack">
                                                                        <div>
                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                <div>
                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                </div>
                                                                                <div>
                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                <div>
                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                </div>
                                                                                <div>
                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                <div>
                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                </div>
                                                                                <div>
                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                <div>
                                                                                    <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                </div>
                                                                                <div>
                                                                                    <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                </div>
                                                            </div>{/*Helper*/}
                                                        </span>
                                                    </div>
                                                </label>

                                                {
                                                    (() => {

                                                        if (CVVNumValid && !CVVNumMessage) {
                                                            return (
                                                                <InputMask id='pagamento-cvv-cartao' className='input-form w100 fz-16 m-top-5 valid 1' mask="9999" placeholder='9999' value={CVVNum} onChange={(ev) => setCVVNum(ev.target.value)} />
                                                            )
                                                        } else {
                                                            if (CVVNumMessage && CVVNumMessage.length > 0) {
                                                                return (
                                                                    CVVNumMessage.map((infos) => {
                                                                        if (infos.status == 'error') {
                                                                            return (
                                                                                <InputMask id='pagamento-cvv-cartao' className='input-form w100 fz-16 m-top-5 invalid 2' mask="9999" placeholder='9999' value={CVVNum} onChange={(ev) => setCVVNum(ev.target.value)} />
                                                                            )
                                                                        } else if (infos.status == 'success') {
                                                                            return (
                                                                                <InputMask id='pagamento-cvv-cartao' className='input-form w100 fz-16 m-top-5 valid 3' mask="9999" placeholder='9999' value={CVVNum} onChange={(ev) => setCVVNum(ev.target.value)} />
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <InputMask id='pagamento-cvv-cartao' className='input-form w100 fz-16 m-top-5 4' mask="9999" placeholder='9999' value={CVVNum} onChange={(ev) => setCVVNum(ev.target.value)} />
                                                                            )
                                                                        }
                                                                    })
                                                                )
                                                            } else {
                                                                return (
                                                                    <InputMask id='pagamento-cvv-cartao' className='input-form w100 fz-16 m-top-5 5' mask="9999" placeholder='9999' value={CVVNum} onChange={(ev) => setCVVNum(ev.target.value)} />
                                                                )
                                                            }
                                                        }
                                                    })()
                                                }
                                                {
                                                    (CVVNumMessage)
                                                        ?
                                                        CVVNumMessage.map((infoStatus) => {
                                                            if (infoStatus.status == 'error') {
                                                                return (
                                                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                        <span className='icon-invalid'><TiInfo /></span>
                                                                        <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                                    </div>
                                                                )
                                                            } else if (infoStatus.status == 'success') {
                                                                return (
                                                                    <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                        <span className='icon-valid'><TiInfo /></span>
                                                                        <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        : null
                                                }
                                            </div>{/*box-input-single */}

                                            <div className={(props.responsivoSize && props.responsivoSize > 300) ? "d-fx d-al-cen d-ju-cen w50 m-rig-0" : "d-fx d-al-cen d-ju-cen w100 m-rig-0"} style={(props.responsivoSize && props.responsivoSize < 210) ? { flexWrap: 'wrap' } : null}>
                                                <div className={(props.responsivoSize && props.responsivoSize > 300) ? 'box-input-single m-top-20 w50 m-lef-0' : 'box-input-single m-top-20 w100 m-lef-0'}>
                                                    <label>
                                                        <div className='title-input d-fx d-al-cen d-al-sp-bt p-rlt'>
                                                            <span className='fz-12 m-rig-5'>Mês</span>
                                                            <span className='icon-info-add p-rlt'>
                                                                <IoIosInformationCircleOutline />
                                                                <div className='helper-input tx-black d-nn'>
                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                        <span class="css-1i1x8nz">
                                                                            Seu CPF será usado para:
                                                                        </span>
                                                                        <div class="css-1l7kih-stack">
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                    </div>
                                                                </div>{/*Helper*/}
                                                            </span>
                                                        </div>
                                                    </label>

                                                    {
                                                        (() => {

                                                            if (mesNumValid && !mesNumMessage) {
                                                                return (
                                                                    <InputMask id='pagamento-mes-cartao' className='input-form w100 fz-16 m-top-5 valid 1' mask="99" placeholder='MM' value={mesNum} onChange={(ev) => setMesNum(ev.target.value)} />
                                                                )
                                                            } else {
                                                                if (mesNumMessage && mesNumMessage.length > 0) {
                                                                    return (
                                                                        mesNumMessage.map((infos) => {
                                                                            if (infos.status == 'error') {
                                                                                return (
                                                                                    <InputMask id='pagamento-mes-cartao' className='input-form w100 fz-16 m-top-5 invalid 2' mask="99" placeholder='MM' value={mesNum} onChange={(ev) => setMesNum(ev.target.value)} />
                                                                                )
                                                                            } else if (infos.status == 'success') {
                                                                                return (
                                                                                    <InputMask id='pagamento-mes-cartao' className='input-form w100 fz-16 m-top-5 valid 3' mask="99" placeholder='MM' value={mesNum} onChange={(ev) => setMesNum(ev.target.value)} />
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <InputMask id='pagamento-mes-cartao' className='input-form w100 fz-16 m-top-5 4' mask="99" placeholder='MM' value={mesNum} onChange={(ev) => setMesNum(ev.target.value)} />
                                                                                )
                                                                            }
                                                                        })
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <InputMask id='pagamento-mes-cartao' className='input-form w100 fz-16 m-top-5 5' mask="99" placeholder='MM' value={mesNum} onChange={(ev) => setMesNum(ev.target.value)} />
                                                                    )
                                                                }
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (mesNumMessage)
                                                            ?
                                                            mesNumMessage.map((infoStatus) => {
                                                                if (infoStatus.status == 'error') {
                                                                    return (
                                                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                            <span className='icon-invalid'><TiInfo /></span>
                                                                            <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                                        </div>
                                                                    )
                                                                } else if (infoStatus.status == 'success') {
                                                                    return (
                                                                        <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                            <span className='icon-valid'><TiInfo /></span>
                                                                            <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                            : null
                                                    }
                                                </div>{/*box-input-single */}

                                                <div className={(props.responsivoSize && props.responsivoSize > 300) ? 'box-input-single m-top-20 w50 m-lef-0' : 'box-input-single m-top-20 w100 m-lef-0'}>
                                                    <label>
                                                        <div className='title-input d-fx d-al-cen p-rlt'>
                                                            <span className='fz-12 m-rig-5'>Ano</span>
                                                            <span className='icon-info-add p-rlt'>
                                                                <IoIosInformationCircleOutline />
                                                                <div className='helper-input tx-black d-nn'>
                                                                    <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                        <span class="css-1i1x8nz">
                                                                            Seu CPF será usado para:
                                                                        </span>
                                                                        <div class="css-1l7kih-stack">
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                    <div>
                                                                                        <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                    </div>
                                                                </div>{/*Helper*/}
                                                            </span>
                                                        </div>
                                                    </label>

                                                    {
                                                        (() => {

                                                            if (anoNumValid && !anoNumMessage) {
                                                                return (
                                                                    <InputMask id='pagamento-ano-cartao' className='input-form w100 fz-16 m-top-5 valid 1' mask="9999" placeholder='AAAA' value={anoNum} onChange={(ev) => setAnoNum(ev.target.value)} />
                                                                )
                                                            } else {
                                                                if (anoNumMessage && anoNumMessage.length > 0) {
                                                                    return (
                                                                        anoNumMessage.map((infos) => {
                                                                            if (infos.status == 'error') {
                                                                                return (
                                                                                    <InputMask id='pagamento-ano-cartao' className='input-form w100 fz-16 m-top-5 invalid 2' mask="9999" placeholder='AAAA' value={anoNum} onChange={(ev) => setAnoNum(ev.target.value)} />
                                                                                )
                                                                            } else if (infos.status == 'success') {
                                                                                return (
                                                                                    <InputMask id='pagamento-ano-cartao' className='input-form w100 fz-16 m-top-5 valid 3' mask="9999" placeholder='AAAA' value={anoNum} onChange={(ev) => setAnoNum(ev.target.value)} />
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <InputMask id='pagamento-ano-cartao' className='input-form w100 fz-16 m-top-5 4' mask="9999" placeholder='AAAA' value={anoNum} onChange={(ev) => setAnoNum(ev.target.value)} />
                                                                                )
                                                                            }
                                                                        })
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <InputMask id='pagamento-ano-cartao' className='input-form w100 fz-16 m-top-5 5' mask="9999" placeholder='AAAA' value={anoNum} onChange={(ev) => setAnoNum(ev.target.value)} />
                                                                    )
                                                                }
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (anoNumMessage)
                                                            ?
                                                            anoNumMessage.map((infoStatus) => {
                                                                if (infoStatus.status == 'error') {
                                                                    return (
                                                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                            <span className='icon-invalid'><TiInfo /></span>
                                                                            <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                                        </div>
                                                                    )
                                                                } else if (infoStatus.status == 'success') {
                                                                    return (
                                                                        <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                            <span className='icon-valid'><TiInfo /></span>
                                                                            <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                            : null
                                                    }
                                                </div>{/*box-input-single */}
                                            </div>
                                        </div>

                                        {/* OPÇÕES DE PARCELAMENTO */}
                                        <div class="box-input-single m-top-20">
                                            <label>
                                                <div className='title-input d-fx d-al-cen'>
                                                    <span className='fz-12 m-rig-5'>Selecione um parcelamento:</span>
                                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                </div>
                                            </label>

                                            <div class="select-box">
                                                <div class="options-container parcelamento">
                                                    <div class="search-box d-nn">
                                                        <input className='input-form' type="text" placeholder="Busque aqui a opção desejada..." onKeyUp={(e) => typingSearch(e)} />
                                                    </div>
                                                    {
                                                        (props.cart && props.cart.length)
                                                            ?
                                                            calcInstallmetQnt().map((dados, index) => {
                                                                return (
                                                                    <div key={index} id={`select_installment_${index}`} className={(parcelasCartao && parcelasCartao > 0 && parcelasCartao === dados) ? "option active" : "option"} onClick={(o) => selectedOption(o, "parcelamento", dados)}>
                                                                        <input type="radio" class="radio" name="parcelamento" value="alterar" />
                                                                        <label>
                                                                            {dados}x de {calcInstallmetValue(dados)} {(dados <= 6) ? 'sem juros' : 'com juros (1,99% a.m.)'}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                            : null
                                                    }
                                                </div>

                                                <div class="selected__option parcelamento input-form" onClick={() => selectedMain("parcelamento")}>
                                                    {
                                                        (parcelasCartao && parcelasCartao > 0)
                                                            ?
                                                            <span>{parcelasCartao}x de {calcInstallmetValue(parcelasCartao)} {(parcelasCartao <= 6) ? 'sem juros' : 'com juros (1,99% a.m.)'}</span>
                                                            : "Selecione o número de parcelas"
                                                    }
                                                </div>

                                                <input id='personalizar_parcelamento_finalidade' type="text" name="personalizar_parcelamento_finalidade" value={parcelasCartao} readOnly disabled style={{ display: "none" }} />
                                            </div>
                                            {
                                                (!parcelasCartao || parcelasCartao.length <= 0)
                                                    ?
                                                    <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                        <span className='icon-invalid'><TiInfo /></span>
                                                        <span className='text-invalid m-lef-5'>Nenhuma opção de parcelamento selecionado</span>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>

                                        {/* SALVAR CARTÃO PARA COMPRAS FUTURAS */}
                                        <div className='box-input-single m-top-15'>
                                            <div className='termos-news-single d-fx d-ju-sp-ev d-fw-wp d-fd-col w100 m-top-10'>
                                                <div className='canais-single m-top-10 m-bot-10'>
                                                    <input id='tokenizar-cartao-dados' type='checkbox' name="contact_email" checked={tokenizarCartao} onClick={() => setTokenizarCartao((prevState) => !prevState)} />
                                                    <label for="tokenizar-cartao-dados">Salvar este cartão para compras futuras</label>
                                                </div>
                                            </div>{/*termos-news-single*/}
                                        </div>{/*box-input-single */}

                                        {/* <button className="button button-full button-primary margin-top align-left" disabled={btnDisabled}>
                                            Concordo e quero prosseguir
                                        </button> */}
                                        <div className='aviso-termos m-top-10 helper'>
                                            Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                                        </div>
                                    </form>
                                    : null
                            }
                        </div>
                        {
                            (carteiraNovoCartao && (!cartaoSelecionado || !cartaoSelecionado.length))
                                ?
                                <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__imagem__formulario w50-c d-fx d-al-cen d-ju-cen m-top-0 m-bot-0" : "wrapper__imagem__formulario w100 d-fx d-al-cen d-ju-cen m-top-0 m-bot-0"} style={(props.responsivoSize && props.responsivoSize < 400) ? { display: 'none' } : null}>
                                    <img src={contactUs} style={(props.responsivoSize && props.responsivoSize < 350 || props.responsivoSize > 768) ? { width: '100%' } : { maxWidth: '80%' }} />
                                </div>
                                : null
                        }
                    </div>
                </div>
            </form>
        </>
    )
}

export default FormPaymentCreditCard;