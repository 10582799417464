import { useState } from 'react';
import { motion } from 'framer-motion';
import { AiOutlineMinusCircle, AiOutlinePlusCircle, AiFillInfoCircle, AiOutlineCheck } from 'react-icons/ai';

function BoxDescricaoProduto(props) {

    // const [depoimentos, setDepoimentos] = useState([]); // DEPOIMENTOS DOS CLIENTES
    // const [lazyload, setLazyload] = useState(true); // LAZYLOAD
    const [expanded, setExpanded] = useState(true);

    // useEffect(() => {
    //     // getOffers();
    //     if (depoimentos && depoimentos.length > 0) {
    //         setLazyload(false);
    //     }
    // }, [depoimentos])

    // function getOffers() {
    //     // PEGANDO DEPOIMENTOS DOS CLIENTES
    //     db.collection("relatorios").doc("UX").collection("depoimentos").where("publicar", "==", true).onSnapshot((infos) => {
    //         var arr = ([]);
    //         infos.docs.map((dados) => {
    //             arr.unshift(dados.data());
    //         })
    //         setDepoimentos(arr);
    //     })
    // }

    // let rows = [];
    // for (let i = 1; i <= 5; i++) {
    //     rows.push(<TestimonialLazyload chave={i} />)
    // }

    return (
        <div className="description b-bot is-open">
            <div className='title-description m-bot-10'>
                <h3 className='left'>Descrição</h3>
                <button className='right' style={{ color: 'var(--dark-grey-blue)' }} onClick={() => setExpanded(!expanded)}>
                    {
                        (expanded)
                            ?
                            <AiOutlineMinusCircle className="fz-18 btn-minus" />
                            :
                            <AiOutlinePlusCircle className="fz-18 btn-more" />
                    }
                </button>
                <div className='clear'></div>
            </div>
            <motion.div
                style={{ transformOrigin: "top", overflow: "hidden" }}
                animate={{ height: expanded ? "auto" : '0px' }}
                className="accordion-item content-description fz-14"
            >
                {props.descricao}
            </motion.div>
        </div>
    )
}

export default BoxDescricaoProduto;