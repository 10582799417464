// IMPORTAÇÃO DE REACT ROUTER
import { Link } from 'react-router-dom';

// IMPORTAÇÃO DE IMAGENS
import iconSuport from '../../../../../images/icones/png/icon-suport.png';
import iconLove from '../../../../../images/icones/png/icon-love.png';
import iconAlert from '../../../../../images/icones/png/icon-alert.png';

// IMPORTAÇAO DE ICONES
import { BsChevronRight, BsCalendarRange } from 'react-icons/bs';


function CardsAtendimento(props) {
    return (
        <div className="grid product w100 p-rlt m-bot-50" style={{ textAlign: 'center' }}>
            <h1>Procurando algo em particular?</h1>
            <div className="box-bg-cards-help p-rlt m-top-15">
                <div className="p-abs bg-card"></div>
                {
                    (props.paginaAtual)
                        ?
                        <div>
                            {
                                (() => {
                                    if (props.paginaAtual == 'atendimento') {
                                        return (
                                            <div className="content-cards">
                                                <div className="card-single-help">
                                                    <div className="img-card-help">
                                                        <img className='w100' src={iconSuport} alt="" />
                                                    </div>
                                                    <div className="content-single-card">
                                                        <h2 className='fz-18'>Canal de Vendas</h2>
                                                        <p className='fz-14'>Atendimento exclusivo pronto para lhe solucionar qualquer dúdiva e auxiliar você em todos os passos de sua compra 24 Horas todos os dias da semana.</p>
                                                    </div>
                                                    <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                        <Link to="/vendas" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)">
                                                            <BsChevronRight className='fz-20' />
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="card-single-help">
                                                    <div className="img-card-help">
                                                        <img className='w100' src={iconLove} alt="" />
                                                    </div>
                                                    <div className="content-single-card">
                                                        <h2 className='fz-18'>Ouvidoria</h2>
                                                        <p className='fz-14'>Ajuda especializada para algum problema especifico, reembolso e reclamações.</p>
                                                    </div>
                                                    <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                        <Link to="/ouvidoria" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)"><BsChevronRight className='fz-20' /></Link>
                                                    </div>
                                                </div>

                                                <div className="card-single-help">
                                                    <div className="img-card-help">
                                                        <img className='w100' src={iconAlert} alt="" />
                                                    </div>
                                                    <div className="content-single-card">
                                                        <h2 className='fz-18'>Promoções</h2>
                                                            <p className='fz-14'>Entre em nossa lista exclusiva para ficar por dentro das novidades e promoções.</p>
                                                    </div>
                                                    <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                        <Link to="/promocoes" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)"><BsChevronRight className='fz-20' /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (props.paginaAtual == 'vendas') {
                                        return (
                                            <div className="content-cards">
                                                    <div className="card-single-help">
                                                        <div className="img-card-help">
                                                            <img className='w100' src={iconSuport} alt="" />
                                                        </div>
                                                        <div className="content-single-card">
                                                            <h2 className='fz-18'>Atendimento</h2>
                                                            <p className='fz-14'>Solução de dúvidas sobre um produto ou conteúdo do nosso site.</p>
                                                        </div>
                                                        <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                            <Link to="/atendimento" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)">
                                                                <BsChevronRight className='fz-20' />
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="card-single-help">
                                                        <div className="img-card-help">
                                                            <img className='w100' src={iconLove} alt="" />
                                                        </div>
                                                        <div className="content-single-card">
                                                            <h2 className='fz-18'>Ouvidoria</h2>
                                                            <p className='fz-14'>Ajuda especializada para algum problema especifico, reembolso e reclamações.</p>
                                                        </div>
                                                        <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                            <Link to="/ouvidoria" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)"><BsChevronRight className='fz-20' /></Link>
                                                        </div>
                                                    </div>

                                                    <div className="card-single-help">
                                                        <div className="img-card-help">
                                                            <img className='w100' src={iconAlert} alt="" />
                                                        </div>
                                                        <div className="content-single-card">
                                                            <h2 className='fz-18'>Promoções</h2>
                                                            <p className='fz-14'>Entre em nossa lista exclusiva para ficar por dentro das novidades e promoções.</p>
                                                        </div>
                                                        <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                            <Link to="/promocoes" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)"><BsChevronRight className='fz-20' /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    } else if (props.paginaAtual == 'ouvidoria') {
                                        return (
                                            <div className="content-cards">
                                                    <div className="card-single-help">
                                                        <div className="img-card-help">
                                                            <img className='w100' src={iconSuport} alt="" />
                                                        </div>
                                                        <div className="content-single-card">
                                                        <h2 className='fz-18'>Canal de Vendas</h2>
                                                        <p className='fz-14'>Atendimento exclusivo pronto para lhe solucionar qualquer dúdiva e auxiliar você em todos os passos de sua compra 24 Horas todos os dias da semana.</p>
                                                        </div>
                                                        <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                            <Link to="/vendas" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)">
                                                                <BsChevronRight className='fz-20' />
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="card-single-help">
                                                        <div className="img-card-help">
                                                            <img className='w100' src={iconLove} alt="" />
                                                        </div>
                                                        <div className="content-single-card">
                                                            <h2 className='fz-18'>Atendimento</h2>
                                                            <p className='fz-14'>Solução de dúvidas sobre um produto ou conteúdo do nosso site.</p>
                                                        </div>
                                                        <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                            <Link to="/atendimento" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)"><BsChevronRight className='fz-20' /></Link>
                                                        </div>
                                                    </div>

                                                    <div className="card-single-help">
                                                        <div className="img-card-help">
                                                            <img className='w100' src={iconAlert} alt="" />
                                                        </div>
                                                        <div className="content-single-card">
                                                            <h2 className='fz-18'>Promoções</h2>
                                                            <p className='fz-14'>Entre em nossa lista exclusiva para ficar por dentro das novidades e promoções.</p>
                                                        </div>
                                                        <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                            <Link to="/promocoes" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)"><BsChevronRight className='fz-20' /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    } else if (props.paginaAtual == 'promocoes') {
                                        return (
                                            <div className="content-cards">
                                                    <div className="card-single-help">
                                                        <div className="img-card-help">
                                                            <img className='w100' src={iconSuport} alt="" />
                                                        </div>
                                                        <div className="content-single-card">
                                                        <h2 className='fz-18'>Canal de Vendas</h2>
                                                        <p className='fz-14'>Atendimento exclusivo pronto para lhe solucionar qualquer dúdiva e auxiliar você em todos os passos de sua compra 24 Horas todos os dias da semana.</p>
                                                        </div>
                                                        <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                            <Link to="/vendas" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)">
                                                                <BsChevronRight className='fz-20' />
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="card-single-help">
                                                        <div className="img-card-help">
                                                            <img className='w100' src={iconLove} alt="" />
                                                        </div>
                                                        <div className="content-single-card">
                                                            <h2 className='fz-18'>Ouvidoria</h2>
                                                            <p className='fz-14'>Ajuda especializada para algum problema especifico, reembolso e reclamações.</p>
                                                        </div>
                                                        <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                            <Link to="/ouvidoria" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)"><BsChevronRight className='fz-20' /></Link>
                                                        </div>
                                                    </div>

                                                    <div className="card-single-help">
                                                        <div className="img-card-help">
                                                            <img className='w100' src={iconAlert} alt="" />
                                                        </div>
                                                        <div className="content-single-card">
                                                            <h2 className='fz-18'>Atendimento</h2>
                                                            <p className='fz-14'>Solução de dúvidas sobre um produto ou conteúdo do nosso site.</p>
                                                        </div>
                                                        <div className="go-action-card-single d-fx d-al-cen d-ju-cen">
                                                            <Link to="/atendimento" className="d-fx d-al-cen d-ju-cen" href="javascript:void(0)"><BsChevronRight className='fz-20' /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    }
                                })()
                            }

                        </div>
                        : null
                }
            </div>
            <div className="clear"></div>
        </div>
    )
}

export default CardsAtendimento;