import '../Assets/styles/css/Main.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../firebase.js';
import $ from 'jquery';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri';

// IMPORTAÇÃO DE REACT ROUTER
import { Link, useNavigate } from 'react-router-dom';
import firebase from 'firebase';
import toast from 'react-hot-toast';
import { useEffect, useState, useMemo } from 'react';

// IMPORTAÇÃO DE PÁGINAS
function Login(props) {
    const [password, setPassword] = useState('');
    const [passStateView, setPassStateView] = useState(false);

    // HOOKS - EMAIL
    const [emailCPF, setEmailCPF] = useState();
    const [email, setEmail] = useState();
    const [emailMessage, setEmailMessage] = useState([]);
    const [emailValid, setEmailValid] = useState(false);

    let navigate = useNavigate();

    function isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    function IsEmail(email) {
        var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
        if (email == '' || !er.test(email)) { return false; }
        else { return true; }
    }

    useEffect(() => {
        if (props.user && props.user.length > 0) {
            navigate("/");
        }
    }, [props.user]);

    async function validarEmailCPF(e) {
        e.preventDefault();

        if (emailCPF && emailCPF.length > 0) {
            if (IsEmail(emailCPF)) {
                setEmail(emailCPF);
                setEmailValid(true);
                setEmailMessage([]);
            } else if (isNumber(emailCPF.split("")[0])) {
                var cpf_cnpj_clear = emailCPF.replace(/[^0-9]/g, '');

                // FORMATAR CPF
                var new_cnpj = emailCPF.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
                setEmailCPF(new_cnpj);

                // PUXAR E-MAIL DO BANCO DE DADOS
                const ref = db.collection('usuarios');
                const snapshot = await ref.where("cpf", "==", cpf_cnpj_clear).get();
                var arr = ([]);
                snapshot.forEach(doc => {
                    arr.push(doc.data());
                });
                setEmail(arr[0].email);
                setEmailValid(true);
                setEmailMessage([]);
            } else {
                setEmailValid(false);
                setEmailMessage([{
                    'status': 'error',
                    'message': 'O e-mail ou CPF informado não é válido'
                }])
            }
        }
    }

    // useMemo(() => {
    //     if (emailCPF && emailCPF.length > 0) {
    //         if (isNumber(emailCPF.split("")[0])) {
    //             var cpf_cnpj_clear = emailCPF.replace(/[^0-9]/g, '');
    //             if (cpf_cnpj_clear.length <= 14) {
    //                 if (cpf_cnpj_clear.length && cpf_cnpj_clear.length > 11) {
    //                     var new_cnpj = emailCPF.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
    //                     setEmailCPF(new_cnpj);
    //                 } else {
    //                     var new_cnpj = emailCPF.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4")
    //                     setEmailCPF(new_cnpj);
    //                 }
    //             }
    //         }
    //     }
    // }, [emailCPF])

    async function logar(e) {
        e.preventDefault();
        if (!emailCPF || !emailCPF.length) {
            toast.error("O e-mail ou CPF não pode ser vázio");
        }else if (!password || !password.length) {
            toast.error("A senha não pode ser vázia");
        } else { // PASSOU EM TODAS AS VALIDAÇÕES E VAMOS REALIZAR O LOGIN
            /* */
            (async () => {
                if (isNumber(emailCPF.split("")[0])) {
                    var cpf_cnpj_clear = emailCPF.replace(/[^0-9]/g, '');
                    const ref = db.collection('usuarios');
                    const snapshot = await ref.where("cpf", "==", cpf_cnpj_clear).get();
                    var arr = ([]);
                    snapshot.forEach(doc => {
                        arr.push(doc.data());
                    });
                    setEmail(arr[0].email);
                } else {
                    setEmail(emailCPF);
                }
            })()
            console.log('Email: ', email, 'Senha: ', password)

            if (email && email.length) {
                auth.signInWithEmailAndPassword(email, password)
                    .then((data) => {
                        console.log("Dados de login", "=>", data);
                        if (data.additionalUserInfo.isNewUser) { // O USUÁRIO ESTÁ LOGANDO PELA PRIMEIRA VEZ
                            // console.log(data);
                            // props.setUser(data.user.displayName);
                            var nome = data.user.displayName.split(' ');
                            toast.success("Bem vindo a Engeart, " + nome[0] + '!');

                            // PEGAR DADOS DO USUÁRIO LOGADO
                            (async () => {
                                if (!sessionStorage.getItem("dados_usuario")) {
                                    if (!props.user || !props.user.length) {
                                        try {
                                            const ref = db.collection('usuarios');
                                            const snapshot = await ref.where('email', '==', data.user.email).get();
                                            if (snapshot.empty) {
                                                console.log('No matching documents.');
                                                return;
                                            }
                                            var dataCliente = ([]);
                                            snapshot.forEach(doc => {
                                                dataCliente.unshift(doc.data());
                                            });

                                            sessionStorage.setItem('dados_usuario', JSON.stringify(dataCliente[0]));
                                            props.setUser(dataCliente[0]);
                                        } catch (error) {
                                            toast.error("Ocorreu um erro ao recuperar seus dados!");
                                        }
                                    }
                                } else {
                                    let dadosUsuario = JSON.parse(sessionStorage.getItem('dados_usuario'));
                                    props.setUser(dadosUsuario);
                                }
                            })()
                        } else { // O USUÁRIO ESTÁ LOGANDO NOVAMENTE
                            // console.log(data);
                            // props.setUser(data.user.displayName);
                            var nome = data.user.displayName.split(' ');
                            toast.success('Bem vindo de volta, ' + nome[0] + '!');

                            // PEGAR DADOS DO USUÁRIO LOGADO
                            (async () => {
                                if (!sessionStorage.getItem("dados_usuario")) {
                                    if (!props.user || !props.user.length) {
                                        // console.log(data.user.email);
                                        try {
                                            const ref = db.collection('usuarios');
                                            const snapshot = await ref.where('email', '==', data.user.email).get();
                                            if (snapshot.empty) {
                                                console.log('No matching documents.');
                                                return;
                                            }
                                            var dataCliente = ([]);
                                            snapshot.forEach(doc => {
                                                dataCliente.unshift(doc.data());
                                            });

                                            sessionStorage.setItem('dados_usuario', JSON.stringify(dataCliente[0]));
                                            props.setUser(dataCliente[0]);
                                        } catch (error) {
                                            toast.error("Ocorreu um erro ao recuperar seus dados!");
                                        }
                                    }
                                } else {
                                    let dadosUsuario = JSON.parse(sessionStorage.getItem('dados_usuario'));
                                    props.setUser(dadosUsuario);
                                }
                            })()
                        }
                        navigate("/");
                    })
                    .catch(function (error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        if (errorCode === 'auth/invalid-email') {
                            toast.error("O endereço de e-mail não é válido")
                        } else if (errorCode === 'auth/user-disabled') {
                            toast.error("O usuário correspondente ao e-mail fornecido foi desabilitado")
                        } else if (errorCode === 'auth/user-not-found') {
                            toast.error('Não houve usuário correspondente ao e-mail fornecido');
                        } else if (errorCode === 'auth/wrong-password') {
                            toast.error("Senha inválida");
                        } else if (errorCode === 'auth/too-many-requests') {
                            toast.error("O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login com falha. Você pode restaurá-la imediatamente redefinindo sua senha ou pode tentar novamente mais tarde.");
                        } else {
                            toast.error(errorMessage);
                        }
                        console.log(error);
                    })
            } else {
                toast.error("Não foi possivel recuperar o e-mail vinculado a esta conta, tente novamente")
            }
            /**/
            // .catch((err) => {
            //     console.log(err.message);
            //     toast.error(err.message);
            // })
            // .then((auth) => {
            //     props.setUser(auth.user.displayName);
            //     var nome = auth.user.displayName.split(' ');
            //     toast.success('Bem vindo de volta, ' + nome[0] + '!');
            //     // console.log(auth.user);
            // })
            // .catch((err) => {
            //     console.log(err.message);
            //     // alert();
            //     toast.error(err.message);
            // })
        }
    }

    function logarGitHub() {
        var provider = new firebase.auth.GithubAuthProvider();
        singIn(provider);
    }
    function logarGoogle() {
        var provider = new firebase.auth.GoogleAuthProvider();
        singIn(provider);
    }
    function logarFacebook() {
        var provider = new firebase.auth.FacebookAuthProvider();
        singIn(provider);
    }
    function logarApple() {
        var provider = new firebase.auth.AppleAuthProvider();
        singIn(provider);
    }
    function singIn(provider) {
        firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                // props.setUser(result.additionalUserInfo.username); // LOGIN COM GITHUB
                props.setUser(result.additionalUserInfo.profile.given_name); // LOGIN COM GOOGLE
                toast.success('Bem vindo de volta ' + props.user.nome + '!');
                var token = result.credential.accessToken;
            }).catch((error) => {
                console.log(error);
                alert("Falha na autenticação");
            })
    }

    // function validatePassword() {
    //     if (passLength && passLetter && passNumber && passSequence) {
    //         if (passCharacters) { // A SENHA POSSUI CARACTERES ENTÃO É UMA SENHA VÁLIDA
    //             setPassValid("strong");
    //         } else {
    //             setPassValid("default");
    //         }
    //     } else {
    //         setPassValid("");
    //     }
    // }

    function alterarVisualizacaoSenha(el) {
        el.preventDefault();

        setPassStateView((prevState) => !prevState);
    }

    return (
        <div id='login' className={(props.responsivoSize && props.responsivoSize < 650) ? 'wrapper-login' : 'wrapper-login m-top-30 m-bot-30'}>
            <div className='center d-fx d-fw-wp d-al-lef'>
                <div className={(props.responsivoSize && props.responsivoSize < 650) ? 'box-login-form p-g-10 w100' : 'box-login-form w50 m-top-0'}>
                    <h1 style={{ fontWeight: 'lighter' }}>Faça Login</h1>
                    <div className='form-login-page'>
                        <form autocomplete="off" onSubmit={(e) => logar(e)}>
                            <div data-component="capslock-checker" data-tip-element="[data-capslock-tip]">
                                {/* <div class="field invalid" data-validation="field">
                                   <input type="email" name="login" class="input" id="email-login-page" data-required="" data-validate="required" />
                                    <label for="email" class="label">Digite aqui seu e-mail ou CPF</label>
                                    <span class="helper" aria-describedby="email">Ex: nome@email.com ou 999.999.999-99</span> */}


                                <div className='box-input-single m-top-20'> {/* EMAIL - CPF */}
                                    <label>
                                        <div className='title-input d-fx d-al-cen'>
                                            <span className='fz-12 m-rig-5'>Digite abaixo seu e-mail ou CPF</span>
                                        </div>
                                    </label>
                                    <input
                                        id='email-login-page'
                                        className='input-form w100 fz-16 m-top-5'
                                        type='text'
                                        name="email_cpf"
                                        placeholder='seuemail@email.com ou 999.999.999-99'
                                        value={emailCPF}
                                        onChange={(ev) => setEmailCPF(ev.target.value)}
                                        onBlur={(e) => validarEmailCPF(e)}
                                    />
                                    {
                                        (emailMessage)
                                            ?
                                            emailMessage.map((infoStatus) => {
                                                if (infoStatus.status == 'error') {
                                                    return (
                                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                            <span className='icon-invalid'><TiInfo /></span>
                                                            <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                        </div>
                                                    )
                                                } else if (infoStatus.status == 'success') {
                                                    return (
                                                        <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                            <span className='icon-valid'><TiInfo /></span>
                                                            <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                        </div>
                                                    )
                                                }
                                            })
                                            : null
                                    }
                                </div>{/*box-input-single */}
                                <div className='box-input-single m-top-20'> {/* SENHA */}
                                    <label>
                                        <div className='title-input d-fx d-al-cen'>
                                            <span className='fz-12 m-rig-5'>Digite abaixo sua senha</span>
                                            <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                        </div>
                                        <div className='helper-input d-nn'>
                                            <h3>Seu CPF será usado para:</h3>
                                            <ul>
                                                <li>
                                                    Te Identificar
                                                </li>
                                                <li>
                                                    Te Identificar
                                                </li>
                                                <li>
                                                    Te Identificar
                                                </li>
                                                <li>
                                                    Te Identificar
                                                </li>
                                            </ul>
                                        </div>{/*Helper*/}
                                    </label>
                                    {/* <input id="senha-login-page" className='input-form w100 fz-16 m-top-5' type="password" name="password" autocomplete="off" required='true' placeholder='Digite aqui sua senha' value={password} onChange={(ev) => setPassword(ev.target.value)} /> */}

                                    <div className='p-rlt'>
                                        {/* <input id='senha-cadastro-singup' className='input-form w100 fz-16 m-top-5' type={(passStateView) ? 'text' : 'password'} name="nome" placeholder='Insira sua senha' autoComplete='off' value={password} onChange={(ev) => setPassword(ev.target.value)} onBlur={() => validatePassword()} /> */}
                                        <input
                                            id='senha-login-page'
                                            className='input-form w100 fz-16 m-top-5'
                                            type={(passStateView) ? 'text' : 'password'}
                                            name="senha-login"
                                            placeholder='Insira sua senha'
                                            autoComplete='off'
                                            value={password}
                                            onChange={(ev) => setPassword(ev.target.value)}
                                            onKeyUp={(e) => {
                                                if (e.getModifierState("CapsLock")) {
                                                    $(".caps__lock__active").slideDown();
                                                } else {
                                                    $(".caps__lock__active").slideUp();
                                                }
                                            }}
                                        />
                                        {
                                            (passStateView)
                                                ?
                                                <RiEyeFill className='btn__toggle__state__password p-abs' onClick={(el) => alterarVisualizacaoSenha(el)} />
                                                :
                                                <RiEyeCloseLine className='btn__toggle__state__password p-abs' onClick={(el) => alterarVisualizacaoSenha(el)} />
                                        }
                                    </div>
                                </div>{/*box-input-single */}
                                <div class="col-xs-12">
                                    {/* <a href="https://www.leroymerlin.com.br/user/forgot" class="underlined text-normal small">
                                        Esqueceu a senha?
                                    </a> */}

                                    <Link id='forgot__password__button' to="/login/forgot" class="underlined text-normal small" onFocus={() => document.getElementById("forgot__password__button").style.textDecoration = 'underline'}>
                                        Esqueceu a senha?
                                    </Link>
                                </div>
                                <button class="button button-full button-primary margin-top tx-lef">
                                    Entrar
                                    <div class="right">
                                        {/* <FontAwesomeIcon className="glyph" icon={faAngleDoubleRight} /> */}
                                    </div>
                                </button>
                                <div class="caps__lock__active hidden margin-top notification notification-warning">
                                    A tecla capslock está ativa.
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class={(props.responsivoSize && props.responsivoSize < 650) ? 'box-login-form p-g-10 m-bot-20 w100' : 'box-login w50 m-top-0'}>
                    <h1 style={{ fontWeight: 'lighter' }}>Faça login com suas redes sociais</h1>
                    <div class="field margin-top-double">
                        <div className="d-gd">
                            <div class="col-xs-12 margin-bottom">
                                <a class="social-button social-facebook" onClick={(e) => toast.error("Este meio de login está em desenvolvimento")}>
                                    Iniciar sessão com o Facebook
                                </a>
                            </div>
                            <div class="col-xs-12 margin-bottom">
                                <a class="social-button social-google" onClick={(e) => toast.error("Este meio de login está em desenvolvimento")}>
                                    Iniciar sessão com o Google
                                </a>
                            </div>
                            <div class="col-xs-12 margin-bottom">
                                <a class="social-button social-apple" onClick={(e) => toast.error("Este meio de login está em desenvolvimento")}>
                                    Iniciar sessão com a Apple
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="login-divider">
                        <span>ou</span>
                    </div>
                    <h1 style={{ fontWeight: 'lighter' }}>Cadastre-se</h1>
                    <Link to="/login/sing-up" class="button button-full button-primary margin-top d-ju-sp-bt d-al-cen" style={{ display: 'flex' }}>
                        <span>Cadastrar</span>
                        <AiOutlinePlusCircle className='fz-25' />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Login;