
import { FaFacebookF, FaTwitter, FaInstagram, FaThumbsDown } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
// import { HiHandRaised } from 'react-icons/hi';
import { MdFrontHand } from 'react-icons/md';
import { copiarLinkCompartilhamento } from '../funcoes/FuncoesCompartilhamento';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TbAlertOctagon, TbHandStop } from 'react-icons/tb';
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { isEmptyObject } from 'jquery';
import { fecharModalLocalizacao } from '../funcoes/FuncoesLocalizacao';
import { fecharModal } from '../funcoes/FuncoesGerais';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';


function ModalBlockCopyright(props) {

    const [localizacaoCEP, setLocalizacaoCEP] = useState("");
    const [location, setLocation] = useState([]);
    const [locationIP, setLocationIP] = useState([]);
    const [locationLat, setLocationLat] = useState(null);
    const [locationLong, setLocationLong] = useState(null);
    const [locationCity, setLocationCity] = useState(null);
    const [locationIPStatus, setLocationIPStatus] = useState(false);
    const [locationStatus, setLocationStatus] = useState(false);
    const [cidadesEstados, setCidadesEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [cityValid, setCityValid] = useState(false);

    function fechaModalLocalizacao(el) {
        el.preventDefault();

        fecharModal();
        setTimeout(() => { props.setOpenModal(null) }, 1000);
    }

    // FUNÇÕES PARA RECUPERAR DADOS DO USUÁRIO

    // PASSO 1: RECUPERAR O IP
    async function getIpClient() { // PEGAR IP
        try {
            const responseLocation = await axios.get('http://ipwho.is/');
            setLocation(responseLocation.data);
        } catch (error) {
            console.error(error);
        }
    }

    // PEGAR IP DO CLIENTE    
    useEffect(async () => {
            try {
                if (location || isEmptyObject(location)) {
                    await getIpClient();
                }
            } catch (error) {
                console.log("Ocorreu um erro ao recuperar os dados deste usuário");
            }
    }, [])

    // useEffect(() => {
    //     console.log("A Localização é: ", location);
    //     if (location && location.length) {
    //         console.log("A Localização é: ", location);
    //     } else {
    //         console.log("Ocorreu um erro ao recuperar o IP")
    //     }
    // }, [locationIP])
    return (
        <div class="popup" style={{ zIndex: 9999, position: 'fixed', maxWidth: '566px' }}>
            <div className='icon__header p-abs'>
                {/* <TbAlertOctagon className='tx-white' /> */}
                <div class='octagon animate'>
                    <MdFrontHand className='tx-white' style={{ transform: 'rotate(-45deg)' }} />
                </div>

            </div>
            <div className='header-popup m-top-35 tx-cen w100' style={{ display: 'block' }}>
                <span>AVISO IMPORTANTE!</span>
            </div>

            <div class="content" style={{ lineHeight: 1.5 }}>
                <p>Esse conteúdo está protegido pela lei de direitos autorais (Nº 9610/98).</p>
                <p>Construir uma residência com base nesse material sem efetuar a compra do projeto é expressamente proibido, sob pena de sanções legais.</p>

                <span class="aviso-ip fz-15 tx-cen b-top" style={{ display: 'block', fontWeight: 'lighter' }}>Aviso exibido dia <i>{(() => {
                    var data = new Date()
                    var segundo = data.getSeconds();
                    var minuto = data.getMinutes();
                    var hora = data.getHours();
                    var dia = data.getDate();
                    var mes = (data.getMonth() + 1);
                    var ano = data.getFullYear();
                    var diaFormatado = (dia < 10) ? ('00' + dia).slice(-2) : dia;
                    var mesFormatado = (mes < 10) ? ('00' + mes).slice(-2) : mes;

                    return (diaFormatado + '/' + mesFormatado + '/' + ano + ' | ' + hora + ':' + minuto + ':' + segundo)
                })()}</i> para o IP: <br /><b>{(location && !isEmptyObject(location)) ? location.ip : null}</b></span>

                {
                    (location && !isEmptyObject(location))
                        ?
                        <p className='tx-cen tx-bold'>
                            {location.city} - {location.region_code}
                        </p>
                        : null
                }

                <div className='wrapper__infos__request p-g-15 m-top-10'>
                    <div className='info__single__request w100 d-fx'>
                        <div className='icon__info__request info-primary left'>
                            <IoIosInformationCircleOutline className='fz-18' />
                        </div>
                        <div className='message__info__request w100 m-lef-5'>
                            <p>
                                <span className='fz-13' style={{ fontWeight: '300' }}>
                                    <strong className='fz-13'>Dúvidas?</strong> Clique <Link to='/atendimento' className='info-primary' onClick={(e) => fechaModalLocalizacao(e)}>aqui</Link> para um atendimento ou leia nossos <a href='javascript:void(0)' className='info-primary' onClick={(e) => toast.error("Esta página está em desenvolvimento")}>Termos e Condições</a>.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w100 d-fx d-ju-cen'>
                <button className="btn__confirm__modal" onClick={(e) => fechaModalLocalizacao(e)}>
                    Aceitar e Continuar a Navegação
                </button>
            </div>
        </div>
    )
}

export default ModalBlockCopyright;