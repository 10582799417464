import { Link, useParams } from 'react-router-dom'; // IMPORTAÇÃO DE REACT ROUTER
import Helmet from 'react-helmet'; // IMPORTAÇÃO DE MANIPULADOR DE TITLE
import InputMask from "react-input-mask"; // MASCARA DE INPUT

import { ImQrcode } from 'react-icons/im';
import { CgFileDocument, CgCopy } from 'react-icons/cg';
import { MdVrpano, MdOutlineArrowBack, MdOutlineVrpano, MdMoreVert, MdClose, MdOutlineArrowBackIos } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { auth, db } from '../../../../firebase';

export default function PagamentoSucesso(props) {
    let { pedidoId } = useParams();

    // console.log(pedidoId);
    const [dadosPagamento, setDadosPagamento] = useState([]);
    const [dadosCobranca, setDadosCobranca] = useState([]);
    const [dadosPedido, setDadosPedido] = useState([]);

    const entrega = "envio";
    const formaPagamento = "cartao";

    function copyToClipBoard(e, val) {
        e.preventDefault();

        var content = document.getElementById('codigo_' + val);

        content.select();
        document.execCommand('copy');

        if (val == 'boleto') {
            toast.success("Código de barras copiado!")
        } else if (val == 'PIX') {
            toast.success("Código PIX copiado!")
        }
    }

    useEffect(() => {
        axios.post("https://eengeart.com.br/cobrancas", { numeroReferencia: pedidoId })
        // axios.post("http://localhost:4242/cobrancas", { numeroReferencia: pedidoId })
            .then((result) => {
                if (result.data.dados_pagamento) {
                    setDadosPagamento(result.data.dados_pagamento);
                } else {
                    toast.error("Não foi possivel recuperar as informações de pagamento! :(");
                }

                if (result.data.dados_cobranca) {
                    setDadosCobranca(result.data.dados_cobranca);

                    // RECEBER DADOS DO PEDIDO
                    auth.onAuthStateChanged((val) => {
                        db.collection("usuarios").doc(val.uid)
                            .collection('pedidos')
                            .where("externalReference", "==", result.data.dados_cobranca.externalReference)
                            .onSnapshot((dados) => {
                                var arr = ([]);
                                dados.docs.map((subDados) => {
                                    arr.unshift(subDados.data().dados_pedido);
                                })
                                setDadosPedido(arr[0]);
                            })
                    })

                    // APAGAR PRODUTOS DO CARRINHO
                    auth.onAuthStateChanged((val) => {
                        db.collection("usuarios")
                            .doc(val.uid)
                            .collection('carrinho')
                            .get()
                            .then(function (querySnapshot) {
                                querySnapshot.forEach(function (doc) {
                                    doc.ref.delete();
                                });
                                props.setCart([]);
                                sessionStorage.removeItem('dados_produtosCarrinho');
                            })
                            .then(() => {
                                // toast.success("O Carrinho foi limpo!");
                            });
                    })

                } else {
                    toast.error("Não foi possivel recuperar as informações desta cobrança! :(");
                }
            })
            .catch((error) => {
                toast.error("Ocorreu um erro na requisição!");
                console.log(error);
            })
    }, [props.user])

    function totalDescontos() {
        let amountDiscount = 0; // CALCULO DE DESCONTOS DOS PRODUTOS

        // LOOPING SOMAR DESCONTO DOS PRODUTOS
        dadosPedido.map((i) => {
            amountDiscount += ((i.precoTabela / 100) * i.desconto) * i.quantidade;
        })

        return amountDiscount;
    }

    function subTotal() {
        let amountProduct = 0
        dadosPedido.map((i) => {
            amountProduct += i.precoTabela * i.quantidade
        })
        return amountProduct;
    }

    return (
        <div id='pagamento-sucesso'>
            <Helmet title="Engeart • Comprou com sucesso" />
            <div className='projeto-single-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    {
                                        (props.responsivoSize && props.responsivoSize >= 550)
                                            ?
                                            <ol className="breadcrumb-list">
                                                <li className="breadcrumb-item m-0">
                                                    <Link className="m-0" to="/">Início</Link>
                                                </li>
                                                <li className="breadcrumb-item m-0">
                                                    <Link className="m-0" to="/carrinho">Carrinho</Link>
                                                </li>
                                                <li className="breadcrumb-item m-0">
                                                    <a className="m-0" href='javascript:void(0)' onClick={() => toast.success("Seu pedido será preparado e enviado após a confirmação de pagamento!")}>Entrega</a>
                                                </li>
                                                <li className="breadcrumb-item m-0">
                                                    <a className="m-0" href='javascript:void(0)' onClick={() => toast.success("O pagamento foi realizado com sucesso!")}>Pagamento</a>
                                                </li>
                                                <li className="breadcrumb-item active m-0">Meu Pedido</li>
                                            </ol>
                                            :
                                            <ol className="breadcrumb-list">
                                                <li className="breadcrumb-item">
                                                    <Link to="/carrinho" className='d-fx d-al-cen'>
                                                        <MdOutlineArrowBackIos className='m-rig-5' />
                                                        Carrinho
                                                    </Link>
                                                </li>
                                            </ol>
                                    }
                                </nav>
                            </div>
                            <section className={(props.responsivoSize && props.responsivoSize > 768) ? 'p-g-20 d-fx d-al-cen d-ju-sp-bt w70 left' : 'p-g-10 d-fx d-al-cen d-ju-sp-bt w100 left'}>
                                <div className='content-principal'>
                                    {
                                        (props.user && props.user.nome)
                                            ?
                                            <h1 className='fz-30 m-bot-10 tx-lig' style={{ color: '#000' }}>Pronto, <span style={{ textTransform: 'uppercase' }}>{props.user.nome}</span>. Recebemos seu pedido</h1>
                                            :
                                            <h1 className='fz-30 m-bot-10 tx-lig' style={{ color: '#000' }}>Olá</h1>
                                    }
                                    <p className='fz-16 m-bot-5'>Agora é só aguardar o e-mail de confirmação do pagamento para que seu pedido seja separado e enviado.</p>
                                    <p className='fz-16 m-top-5'>Você consegue acompanhar seu pedido na sessão de <Link to="/minha-conta/meus-pedidos" className='info-primary'>Meus Pedidos</Link> aqui em nosso site ou pelo <a className='info-primary' onClick={e => e.preventDefault()}>app Engeart</a>.</p>
                                </div>
                            </section>

                            <section className={(props.responsivoSize && props.responsivoSize > 768) ? 'p-g-20 m-top-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt w30 right' : 'p-lef-10 p-rig-10 m-top-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt w100'}>
                                <div className='content-infos__pedido tx-black w100 m-bot-20 d-fx d-fw-wp'>
                                    <span className='w100'>
                                        <h2 className='fz-16'>Resumo do Pedido:</h2>
                                    </span>
                                    {
                                        (entrega == 'retirada')
                                            ?
                                            <span className='w100 fz-16'>
                                                Previsão de retirada:
                                                <strong className='m-lef-5'>em até 23 horas</strong>
                                            </span>
                                            :
                                            <span className='w100 fz-16'>
                                                Previsão de entrega:
                                                <strong className='m-lef-5'>1 a 3 dias úteis</strong>
                                            </span>
                                    }
                                    {
                                        (dadosCobranca.discount && dadosCobranca.discount.value <= 0)
                                            ?
                                            <span className='w100 fz-16'>
                                                Subtotal:
                                                {
                                                    (dadosCobranca.value)
                                                        ?
                                                        <strong className='m-lef-5'>{subTotal().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                        : null
                                                }
                                            </span>
                                            :
                                            <span className='w100 fz-16'>
                                                Subtotal:
                                                <strong className='m-lef-5'>
                                                    {subTotal().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                </strong>
                                            </span>
                                    }
                                    <span className='w100 fz-16'>
                                        Frete:
                                        <strong className='m-lef-5'>R$ 0,00</strong>
                                    </span>
                                    <span className='w100 fz-16 info-primary'>
                                        Desconto:
                                        <strong className='m-lef-5'>
                                            {totalDescontos().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </strong>
                                    </span>
                                    {
                                        (() => {
                                            if (dadosCobranca.billingType == 'CREDIT_CARD') {
                                                return (
                                                    <span className='w100 fz-16'>
                                                        Cartão:
                                                        <strong className='m-lef-5'>•••• {dadosCobranca.creditCard.creditCardNumber} <span className='fz-14' style={{ border: 'none' }}>{(dadosCobranca.installmentNumber) ? dadosCobranca.installmentNumber : 1}x <small className='tx-lig'>de</small> {dadosCobranca.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></strong>
                                                    </span>
                                                )
                                            } else if (dadosCobranca.billingType == 'BOLETO') {
                                                return (
                                                    <span className='w100 fz-16'>
                                                        Forma de pagamento:
                                                        <strong className='m-lef-5'>{dadosCobranca.billingType}</strong>
                                                    </span>
                                                )
                                            } else if (dadosCobranca.billingType == 'PIX') {
                                                return (
                                                    <span className='w100 fz-16'>
                                                        Forma de pagamento:
                                                        <strong className='m-lef-5'>{dadosCobranca.billingType}</strong>
                                                    </span>
                                                )
                                            }
                                        })()
                                    }
                                    {
                                        (dadosCobranca.discount && dadosCobranca.discount.value <= 0)
                                            ?
                                            <span className='w100 fz-18'>
                                                {
                                                    (dadosCobranca.value)
                                                        ?
                                                        <strong>Total: {(subTotal() - totalDescontos()).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                        : null
                                                }
                                            </span>
                                            :
                                            <span className='w100 fz-18'>
                                                <strong>Total: {(subTotal() - totalDescontos()).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                            </span>
                                    }

                                </div>
                            </section>

                            <section className={(props.responsivoSize && props.responsivoSize > 768) ? 'p-g-20 m-top-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt w70 left' : 'p-g-20 m-g-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt'} style={{ background: 'rgba(0, 109, 255, 0.06)', borderRadius: '8px' }}>
                                <div className='content-infos__retirada tx-black w100'>
                                    <h2 className='fz-18 m-bot-10'>Número do pedido: <strong className='info-primary'>#{pedidoId}</strong></h2>
                                    <h3 className='fz-14'>Prazos para envios de pedidos:</h3>
                                    <p className='fz-15 m-bot-5'>* O pedido só poderá ser enviado 24h após a aprovação do pagamento</p>
                                    <p className='fz-15 m-bot-5'>- Pedidos com <strong>entrega expressa</strong>, aprovados de segunda a sexta das 8h às 18h serão enviados no mesmo dia</p>
                                    <p className='fz-15 m-bot-5'>- Pedidos com <strong>entrega expressa</strong>, aprovados de domingos e feriados serão enviados no próximo dia útil</p>
                                </div>
                                <div className='content-alertas__retirada w100'>
                                    <div className='alerta__retirada__single w100 p-g-5 m-top-10 m-bot-10' style={{ background: '#f6ce20', borderRadius: '6px' }}>
                                        <span className='fz-14 tx-black m-lef-10'>
                                            Os prazos de entrega e retirada começam a contar após a confirmação de pagamento pela instituição financeira.
                                        </span>
                                    </div>
                                </div>
                            </section>
                            {
                                (dadosCobranca && dadosCobranca.billingType == "BOLETO")
                                    ?

                                    <section className={(props.responsivoSize && props.responsivoSize > 768) ? 'm-top-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt w70 left' : 'p-lef-10 p-rig-10 m-top-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt w100'}>
                                        <div className='w100 p-g-20' style={{ background: '#eeeef0', borderRadius: '8px' }}>
                                            <div className='content-infos__pagamento tx-black w100'>
                                                <InputMask id='codigo_boleto' className='tx-black tx-lig fz-14 tx-cen w100' mask="99999.99999 99999.999999 99999.999999 9 99999999999999" value={(dadosPagamento && dadosPagamento.identificationField) ? dadosPagamento.identificationField : null} readOnly style={{ resize: 'none', background: "none", height: '16px' }} />
                                            </div>
                                        </div>
                                        <div className={(props.responsivoSize && props.responsivoSize > 300) ? 'w100 d-fx d-al-cen d-ju-sp-ar m-top-20' : 'w100 d-fx d-al-cen d-ju-sp-ar d-fd-col m-top-20'}>
                                            <button className={(props.responsivoSize && props.responsivoSize > 300) ? 'w30 personalizado tx-cen d-fx d-fd-col info-primary' : 'w100 personalizado tx-cen d-fx d-fd-col info-primary m-g-10'} style={{ paddingTop: '20px', paddingBottom: '20px', borderRadius: '8px', border: '1px solid #006eff', textTransform: 'initial' }} onClick={(e) => copyToClipBoard(e, "boleto")}>
                                                <span className='w100'>
                                                    <CgCopy className='fz-20' />
                                                </span>
                                                <span className='w100 fz-15 tx-bold m-top-5'>
                                                    Copiar cód. de barra
                                                </span>
                                            </button>
                                            <a href={dadosCobranca.bankSlipUrl} target="_blank" className={(props.responsivoSize && props.responsivoSize > 300) ? 'personalizado w30 tx-cen p-top-20 p-bot-20 d-fx d-fd-col info-primary' : 'personalizado w100 tx-cen p-top-20 p-bot-20 m-g-10 d-fx d-fd-col info-primary'} style={{ paddingTop: '20px', paddingBottom: '20px', borderRadius: '8px', border: '1px solid #006eff', textTransform: 'initial', lineHeight: 'initial' }}>
                                                <span className='w100'>
                                                    <CgFileDocument className='fz-20' />
                                                </span>
                                                <span className='w100 fz-15 tx-bold m-top-5'>
                                                    Visualizar Boleto
                                                </span>
                                            </a>
                                        </div>
                                    </section>
                                    : null
                            }

                            {
                                (dadosCobranca && dadosCobranca.billingType == "PIX")
                                    ?
                                    <section className={(props.responsivoSize && props.responsivoSize > 768) ? 'm-top-20 d-fx d-fw-wp d-ju-sp-bt w70 left' : 'p-lef-10 p-rig-10 m-top-20 d-fx d-fw-wp d-ju-sp-bt w100'}>
                                        <div className='w70'>
                                            <h2 className='fz-20 m-bot-5'><strong className='info-primary'>Pagar com o PIX é rápido e simples:</strong></h2>
                                            <ol className='m-lef-15'>
                                                <li className='fz-14 m-bot-5'>Abra o App ou Site onde você cadastrou suas chaves PIX.</li>
                                                <li className='fz-14 m-bot-5'>Toque no menu correspondente ao PIX dentro do App/Site que você utiliza.</li>
                                                <li className='fz-14 m-bot-5'>Selecione a opção <ImQrcode />  "Pagar QR Code”.</li>
                                                <li className='fz-14 m-bot-5'>Escaneie o código exibido ao lado, nessa página.</li>
                                            </ol>


                                            <h2 className='fz-20 m-top-20'><strong className='tx-grey-dark'>Se preferir, você também pode utilizar o código:</strong></h2>
                                            <ol className=''>
                                                <div className='m-bot-5'>
                                                    <li className='fz-14 m-bot-5 m-lef-15'>Copie o código exibido abaixo.</li>
                                                    <div className='w100' style={{ padding: '.625rem', background: '#eeeef0', borderRadius: '8px', border: '1px solid #d3d2d6' }}>
                                                        <div className='content-infos__pagamento tx-black w100'>
                                                            <input id='codigo_PIX' className='tx-black tx-lig fz-14 tx-cen w100' value={(dadosPagamento && dadosPagamento.payload) ? dadosPagamento.payload : null} readOnly style={{ resize: 'none', background: "none", height: '16px' }} />
                                                        </div>
                                                    </div>
                                                    <button className='w30 personalizado tx-cen d-fx d-fd-col info-primary m-top-15' style={{ borderRadius: '8px', border: '1px solid #006eff', textTransform: 'initial' }} onClick={(e) => copyToClipBoard(e, "PIX")}>
                                                        <span className='w100 fz-15 tx-bold'>
                                                            Copiar código PIX
                                                        </span>
                                                    </button>
                                                </div>
                                                <li className='fz-14 m-bot-5 m-lef-15'>Abra o App ou Site onde você cadastrou suas chaves PIX.</li>
                                                <li className='fz-14 m-bot-5 m-lef-15'>Escolha a opção PIX Copia e Cola.</li>
                                                <li className='fz-14 m-bot-5 m-lef-15'>Cole o código, confira as informações e finalize a compra.</li>
                                            </ol>
                                            <strong className='fz-14'>Depois é só aguardar a confirmação do seu pedido.</strong>
                                        </div>
                                        <div className='w30 d-fx d-ju-cen m-top-10'>
                                            <img src={`data:image/png;base64, ${dadosPagamento.encodedImage}`} width="172" height="172" alt="pix qrcode" style={{ backgroundColor: '#fff', border: '1px solid #e5e5e5', borderRadius: '.25rem', boxShadow: '0 .5rem .75rem #e5e5e5' }} />
                                        </div>
                                    </section>
                                    : null
                            }

                            <section className={(props.responsivoSize && props.responsivoSize > 768) ? 'p-g-20 m-top-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt w70 left' : 'p-g-10 d-fx d-al-cen d-fw-wp d-ju-sp-bt w100'} style={{ paddingLeft: 0 }}>
                                {
                                    (entrega == 'retirada')
                                        ?
                                        <div className='content-infos__entrega tx-black w100 b-bot'>
                                            <h2 className='fz-25 tx-lig'>Retirar na Loja São José do Rio Preto</h2>
                                            <p className='fz-12 m-bot-5'>Rua Duque de Caxias, 1046, Centro - 15150-000, Monte Aprazível - SP</p>
                                        </div>
                                        :
                                        <div className='content-infos__entrega tx-black w100 m-bot-20 b-bot'>
                                            <div className='b-bot'>
                                                <h2 className='fz-25 tx-lig'>Enviamos para você</h2>
                                                <p className='fz-12'>
                                                    O email com os arquivos do seu projeto será enviado automaticamente após a confirmação do pagamento pela instituição financeira.
                                                </p>
                                                <p className='fz-12 m-bot-5'>
                                                    <strong>Lembre-se de verficar a caixa de spam ou a lixeira do seu email.</strong>
                                                </p>
                                            </div>

                                            <div>
                                                <h2 className='fz-25 tx-lig'>
                                                    Envio
                                                    <span className='m-lef-5'>
                                                        {
                                                            (() => {
                                                                if (dadosPedido.length <= 1) {
                                                                    return (
                                                                        dadosPedido.length
                                                                    )
                                                                } else if (dadosPedido.length > 1 && dadosPedido.length < 3) {
                                                                    return (
                                                                        "1 e 2"
                                                                    )
                                                                } else {
                                                                    return (
                                                                        "1 à " + dadosPedido.length
                                                                    )
                                                                }
                                                            })()
                                                        }
                                                    </span>
                                                </h2>
                                                <p className='fz-12 m-bot-5'>Vendido e entregue por Engeart</p>
                                            </div>
                                        </div>
                                }
                                <div className='content-wrapper__pedido tx-black w100'>
                                    {
                                        (dadosPedido && dadosPedido.length)
                                            ?
                                            dadosPedido.map((dadosProduto, index) => {
                                                return (
                                                    <div key={index} className='box__single__product b-bot'>
                                                        <div className='content__single__product d-fx d-fw-wp w100 p-rlt'>
                                                            <div className={(props.responsivoSize && props.responsivoSize > 600) ? "image__product w20 m-rig-20 left m-lef-0" : "image__product w100"}>
                                                                <Link to={`/projetos/${dadosProduto.orderId}`} className='w100'>
                                                                    <img className='w100' src={dadosProduto.capa} />
                                                                </Link>
                                                            </div>
                                                            <div className={(props.responsivoSize && props.responsivoSize > 600) ? "infos_product w80 right" : "infos_product w100"}>
                                                                <Link to={`/projetos/${dadosProduto.orderId}`} className='title__name__product w100 d-bk'>
                                                                    {dadosProduto.titulo}
                                                                    <span className='m-lef-5 m-rig-5'>•</span>
                                                                    Projeto Arquitetônico
                                                                </Link>
                                                                <span className='seller__product w100 d-bk fz-12 tx-grey-light'>
                                                                    Cód. do projeto: <strong>E{dadosProduto.orderId}A</strong>
                                                                </span>
                                                                {/* <div className={(props.responsivoSize && props.responsivoSize > 600) ? "box__subtotal__single d-fx d-ju-sp-bt w100 m-top-5" : "box__subtotal__single d-fx d-ju-sp-bt w100 m-top-5 d-fd-col"}> */}
                                                                <div className={(props.responsivoSize && props.responsivoSize > 600) ? "box__subtotal__single d-fx d-ju-sp-bt w100 m-top-5" : "box__subtotal__single d-fx d-ju-sp-bt d-fw-wp w100 m-top-5"}>
                                                                    <div className='d-fx d-al-cen tx-grey-dark fz-14'>
                                                                        <span>Subtotal:</span>
                                                                        <strong className='m-lef-5'>{(dadosProduto.quantidade * dadosProduto.precoTabela).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                                    </div>
                                                                    <div className='d-fx d-al-cen tx-grey-dark fz-14'>
                                                                        <span>Qtde.:</span>
                                                                        <strong className='m-lef-5'>{dadosProduto.quantidade}</strong>
                                                                    </div>
                                                                    <div className='d-fx d-al-cen tx-grey-dark fz-14'>
                                                                        <span>Desconto:</span>
                                                                        <strong className='m-lef-5'>{(dadosProduto.quantidade * ((dadosProduto.precoTabela / 100) * dadosProduto.desconto)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                                    </div>
                                                                    <div className='d-fx d-al-cen tx-grey-dark fz-14'>
                                                                        <span>Total:</span>
                                                                        <strong className='m-lef-5'>{(dadosProduto.quantidade * (dadosProduto.precoTabela - ((dadosProduto.precoTabela / 100) * dadosProduto.desconto))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='clear'></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                </div>
                            </section>
                        </div>
                        <div className='clear'></div>
                    </div>
                </div>{/*center*/}
            </div>
        </div>
    )
}

// 00020101021226730014br.gov.bcb.pix2551pix-h.asaas.com/pixqrcode/cobv/pay_76575613967995145204000053039865802BR5905ASAAS6009Joinville61088922827162070503***63045E7A