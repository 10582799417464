import FormPaymentCreditCard from "./Pagamentos/FormPaymentCreditCard";
import FormPaymentBoleto from "./Pagamentos/FormPaymentBoleto";
import FormPaymentPIX from "./Pagamentos/FormPaymentPIX";
import contactUs from "../../../../../images/icones/svg/promocao.svg"

function FormPagamento(props) {
    return (
        <>
            <div className="grid product d-fx m-bot-25 d-al-cen content__formulario__imagem" style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column-reverse' } : null}>
                {
                    (() => {
                        if (props.paymentMethod == 'credito') {
                            return <FormPaymentCreditCard user={props.user} enderecoComprador={props.enderecoComprador} telefoneComprador={props.telefoneComprador} cart={props.cart} responsivoSize={props.responsivoSize} paymentMethod={props.paymentMethod} setBtnPaymentDisabled={props.setBtnPaymentDisabled}  />
                        } else if (props.paymentMethod == 'boleto') {
                            return <FormPaymentBoleto user={props.user} enderecoComprador={props.enderecoComprador} telefoneComprador={props.telefoneComprador} cart={props.cart} responsivoSize={props.responsivoSize} paymentMethod={props.paymentMethod} setBtnPaymentDisabled={props.setBtnPaymentDisabled}  />
                        } else if (props.paymentMethod == 'pix') {
                            return <FormPaymentPIX user={props.user} enderecoComprador={props.enderecoComprador} telefoneComprador={props.telefoneComprador} cart={props.cart} responsivoSize={props.responsivoSize} paymentMethod={props.paymentMethod} setBtnPaymentDisabled={props.setBtnPaymentDisabled}  />
                        }
                    })()
                }
            </div>
        </>
    )
}

export default FormPagamento;