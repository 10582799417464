import '../../../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import '../../../../../../../Assets/styles/css/Buttons.css';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useMemo, useState } from 'react';
import { isEmptyObject } from 'jquery';
import toast from 'react-hot-toast';
import { db } from '../../../../../../../../../../firebase';
import { BsCheckCircleFill } from 'react-icons/bs';
import firebase from 'firebase';
import { Link } from 'react-router-dom';

function BudgetForm_sucesso(props) {
    function novoOrcamento(e) {
        e.preventDefault();
        props.setCategoriaSelecionada([])
        props.setBudgetTitulo('')
        props.setBudgetDescricao('')
        props.setBudgetTipoSelecionado([])
        props.setBudgetArea('')
        props.setBudgetTipoExecucao('')
        props.setBudgetValidade('')

        props.setBudgetCPFCNPJCliente([])
        props.setBudgetRGCliente([])
        props.setBudgetEnderecoCliente([])
        props.setClienteSelecionado([])

        props.setBudgetEtapas([])

        props.setBudgetPagamentos([])

        props.setBudgetGarantia([])

        props.setBudgetRequisitos([])

        props.setBudgetEnderecoServicoStatus(false)
        props.setBudgetEnderecoServico([])

        props.setBudgetGerenciamento(false)
        props.setBudgetAcompanhamento(false)
        props.setBudgetCompras(false)
        props.setBudgetObservacoes([])
        
        props.setSlideCard(0);
    }
    return (
        <div id="budget_form_categoria">
            <div className='card__info__budget__single'>
                <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                    <h2 className='b-bot-medium'>Hey Parabéns Orçamento Finalizado!</h2>
                    <div style={{ lineHeight: 1.5 }}>
                        <p className='fz-18 tx-cen'>Parabéns!</p>
                        <p className='fz-16 tx-cen'>Você acabou de finalizar o orçamento com sucesso!</p>
                        <div className='m-top-15 p-g-10' style={{ backgroundColor: "#f5f5f5", borderRadius: '6px' }}>
                            <p className='fz-14'>Deixamos abaixo algumas opções para facilitar seus próximos afazeres.</p>
                        </div>
                    </div>

                    <div className='d-fx d-al-cen d-ju-sp-ev w100'>
                        <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                            <button className="personalizado" style={{ marginTop: 0, textTransform: 'uppercase' }}
                                onClick={(e) => novoOrcamento(e)}
                            >
                                <span className=' fz-12'>Criar Outro Orçamento</span>
                            </button>
                        </div>

                        <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                            <Link to="/minha-conta/administrador/orcamentos" className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }}>
                                <span className=' fz-12'>Painel de Orçamentos</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default BudgetForm_sucesso;