import '../../../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../../../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiSave } from 'react-icons/gi';
import { AiFillEdit, AiOutlineCheck, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdHomeFilled, MdHomeWork } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaMoneyBill } from 'react-icons/fa';

import { BsCreditCard2Front, BsTrash } from 'react-icons/bs';
import { ImQrcode } from 'react-icons/im';
import { GrTransaction } from 'react-icons/gr';
import { FcMoneyTransfer } from 'react-icons/fc';
import { RiHomeGearLine } from 'react-icons/ri';
import { FaBarcode, FaApplePay, FaGooglePay, FaStar } from 'react-icons/fa';


import contractOkay from '../../../../../../../../../../images/icones/svg/Construction costs-pana.svg';
import searchFolder from '../../../../../../../../../../images/icones/svg/search-folder.svg';
import searchDocument from '../../../../../../../../../../images/icones/svg/search-document.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';

function BudgetForm_etapas(props) {

    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');


    const [orcamentos, setOrcamentos] = useState([]);

    // DADOS DO FORMULÁRIO
    const [usuarioCadastrado, setUsuarioCadastrado] = useState(false); // VERIFICAR SE O USARIO ESTÁ CADASTRADO NO SITE ? ABRIR SELEÇÃO DE USUARIO : ABRIR MODAL DE CADASTRAMENTO DE USUARIO

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user) && props.user.representante) {
            db.collection("orcamentos")
                .where("numero_representante_contratado", "==", props.user.token_representante)
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })

            // console.log(orcamentos)
        }
    }, [])

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }

    // FUNÇÕES DE SELECT PERSONALIZADO - CATEGORIAS
    const [novaCategoriaStatus, setNovaCategoriaStatus] = useState(false);
    const [novaCategoria, setNovaCategoria] = useState([]);

    const [toggleOpenOptions, setToggleOpenOptions] = useState(false);


    // FUNÇÕES DE SELECT PERSONALIZADO - TIPOS
    const [novoTipoStatus, setNovoTipoStatus] = useState(false);
    const [novoTipo, setNovoTipo] = useState([]);

    const [toggleOpenOptionsTipo, setToggleOpenOptionsTipo] = useState(false);

    function typingSearchCategoria(e) {
        filterListCategoria(e.target.value);
    };

    const filterListCategoria = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.categoria").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function typingSearchTipo(e) {
        filterListTipo(e.target.value);
    };

    const filterListTipo = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        document.querySelectorAll(".option.tipo").forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    function convertStringToSlug(string) {
        string = string.replace(/^\s+|\s+$/g, '')
        string = string.toLowerCase()

        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'

        for (let i = 0, l = from.length; i < l; i++) {
            string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        string = string.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')

        return string
    }

    function definirNovaCategoria(val) {
        setNovaCategoria({
            categoria: val,
            slug_categoria: convertStringToSlug(val),
            campo: "categoria-orcamento"
        });
    }

    function definirNovoTipo(val) {
        setNovoTipo({
            tipo: val,
            slug_tipo: convertStringToSlug(val),
            campo: "tipo-orcamento"
        });
    }

    // FUNÇÕES INICIAIS - RECUPERAÇÃO DE CATEGORIAS
    const [categorias, setCategorias] = useState([]);
    const [tipos, setTipos] = useState([]);

    // AVANÇAR PROGRESSO DE CRIAÇÃO DE ORÇAMENTO
    const [saveCategoriaStatus, setSaveCategoriaStatus] = useState(false);

    const [saveTipoStatus, setSaveTipoStatus] = useState(false);

    function avancarOrcamento() {
        toast.success("Etapas do serviço salvas com sucesso!");
        props.setSlideCard(props.slideCard + 1);
    }

    // ETPAS DO SERVIÇO
    const [etapa, setEtapa] = useState([]);
    const [editStage, setEditStage] = useState(null);
    const [editStageNew, setEditStageNew] = useState([]);

    function salvarEtapa(e) {
        e.preventDefault();
        var novaEtapa = etapa.replace(/["]/g, '').split(";");
        var newStage = ([]);

        if (props.budgetEtapas && props.budgetEtapas.length) {
            props.budgetEtapas.map((doc) => {
                newStage.push(doc);
            })
        }

        novaEtapa.map((stage) => {
            newStage.push(stage)
        })

        props.setBudgetEtapas(newStage);

        setEtapa(null);

        document.getElementById("myForm").reset();

        setTimeout(() => {
            setEtapa(null);
        }, 1000);
    }

    function apagarEtapa(e, id) {
        e.preventDefault();

        var newStage = ([]);
        if (props.budgetEtapas && props.budgetEtapas.length) {
            props.budgetEtapas.map((doc) => {
                newStage.push(doc);
            })
        }
        newStage.splice(id, 1);
        props.setBudgetEtapas(newStage);

        console.log(newStage);
    }

    function editarEtapa(e, id) {
        e.preventDefault();
        setEditStage(id);
        console.log(editStage);
    }

    function salvarEtapaEditada(e, id, dataOriginal) {
        e.preventDefault();

        if (editStageNew && editStageNew.length) {
            var newEditStage = ([]);
            if (props.budgetEtapas && props.budgetEtapas.length) {
                props.budgetEtapas.map((doc) => {
                    newEditStage.push(doc);
                })
            }
            newEditStage[id] = editStageNew;
            props.setBudgetEtapas(newEditStage);
            setEditStage(null);
            setEditStageNew([]);

            console.log(props.budgetEtapas);
            toast.success(`A etapa de ${editStageNew} foi editada com sucesso!`);
        } else {
            setEditStage(null);
            setEditStageNew([]);
            toast("Nada foi alterado")
        }
    }

    function verificarPreencherEtapas(val) {



    }


    return (
        <div id="budget_form_categoria">
            <div className='card__info__budget__single'>
                <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                    <h2 className='b-bot-medium'>Cadastro de Etapas do Serviço</h2>
                    <p className='fz-14'>Preencha as infomações a seguir para gerar um novo orçamento.</p>
                </div>
                <div className='wrapper__form__criar__orcamento'>
                    <form id="myForm" className='form__criar__orcamento'>
                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen p-rlt'>
                                    <span className='fz-12 m-rig-5'>Nova etapa do serviço</span>
                                    <span className='icon-info-add p-rlt'>
                                        <IoIosInformationCircleOutline />
                                        <div className='helper-input tx-black d-nn'>
                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                <span class="css-1i1x8nz">
                                                    Seu CPF será usado para:
                                                </span>
                                                <div class="css-1l7kih-stack">
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Te identificar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Acessar sua conta</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Consultar pedidos</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                            <div>
                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                            </div>
                                                            <div>
                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Realizar transações financeiras</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                            </div>
                                        </div>{/*Helper*/}
                                    </span>
                                </div>
                            </label>
                            <div className='p-rlt'>
                                <input
                                    id='cadastro_etapas_servico'
                                    className={((!props.budgetEtapas || !props.budgetEtapas.length) && (!etapa || !etapa.length)) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'}
                                    type='text'
                                    name="etapa_servico"
                                    placeholder='Locação de Obra'
                                    value={(etapa && etapa.length) ? etapa : null}
                                    onChange={(ev) => setEtapa(ev.target.value)}
                                    style={{paddingRight: '35px'}}
                                />
                                <button
                                    className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%', background: 'none', cursor: 'pointer', }}
                                    title="Salvar Etapa de Serviço"
                                    onClick={(e) => salvarEtapa(e)}
                                    disabled={(!etapa || !etapa.length) ? true : false}
                                >
                                    <GiSave className='fz-20' style={{ color: 'var(--logo-color-dark)' }} />
                                </button>

                            </div>
                            {
                                (!props.budgetEtapas || !props.budgetEtapas.length)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>O serviço deve ter pelo menos uma etapa!</span>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className='wrapper__infos__request p-g-15 m-bot-5 m-top-10'>
                            <div className='info__single__request w100 d-fx'>
                                <div className='icon__info__request info-primary left'>
                                    <IoIosInformationCircleOutline className='fz-18' />
                                </div>
                                <div className='message__info__request w100 left m-lef-5'>
                                    <p>
                                        <span className='fz-13'>
                                            Para um desempenho maior separe as etapas com ponto e vírgula (<strong>;</strong>) e após digitar todas as etapas clique no icone salvar (<GiSave />) para adicionar todas as etapas de uma vez.
                                        </span>
                                    </p>
                                    <p className='m-top-5'>
                                        <span className='fz-13'>
                                            Ou, digite cada etapa indivuidualmente e clique no icone salvar (<GiSave />).
                                        </span>
                                    </p>
                                    <p className='m-top-5'>
                                        <span className='fz-13'>
                                            O icone salvar (<GiSave />) só será habilitado ao digitar alguma informação no campo "Nova etapa do serviço".
                                        </span>
                                    </p>
                                    <p className='m-top-5'>
                                        <span className='fz-13'>
                                            O botão próximo só será habilitado após salvar ao menos uma etapa para o serviço.
                                        </span>
                                    </p>
                                </div>
                                <div className='clear'></div>
                            </div>
                        </div>
                        {
                            (props.budgetEtapas && props.budgetEtapas.length > 0)
                                ?
                                <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-10 p-g-15 w100' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                    <div className='p-rlt b-bot-medium w100'>
                                        <div className='d-fx d-al-cen m-bot-10 d-ju-cen'>
                                            <h2 className='fz-18 tx-cen'>Etapas do Serviço</h2>
                                            <button
                                                className='p-abs'
                                                style={{ right: 0, background: 'none', cursor: 'pointer' }}
                                                title="Apagar todas as etapas"
                                                onClick={() => { props.setBudgetEtapas([]) }}
                                            >
                                                <BsTrash
                                                    className='fz-18'
                                                    style={{ color: '#ff0000' }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div className='d-fx d-fd-col'>
                                        {
                                            props.budgetEtapas.map((dadosEtapa, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="d-fx d-al-cen"
                                                    >
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>

                                                            <span className='d-fx d-al-cen'>
                                                                <strong>{`Etapa ${index + 1}:`}</strong>
                                                                <span className='m-lef-5'>
                                                                    {
                                                                        (editStage == index)
                                                                            ?
                                                                            <input
                                                                                type='text'
                                                                                name='editar_etapa'
                                                                                value={(editStageNew && !editStageNew.length) ? dadosEtapa : editStageNew}
                                                                                onChange={(e) => setEditStageNew(e.target.value)}
                                                                                style={{ padding: '3px 5px', borderRadius: '3px', border: '1px solid #ccc' }}
                                                                            />
                                                                            :
                                                                            <span>{dadosEtapa}</span>
                                                                    }
                                                                </span>
                                                            </span>

                                                        </span>
                                                        {
                                                            (editStage == index)
                                                                ?
                                                                <button
                                                                    className='d-fx d-al-cen m-lef-5 fz-12' style={{ border: '1px solid #1f8900', padding: '3px 5px', borderRadius: '3px', background: "#1f890020", cursor: 'pointer', maxHeight: '25px' }}
                                                                    onClick={(e) => salvarEtapaEditada(e, index, dadosEtapa)}
                                                                >
                                                                    Salvar <GiSave className='fz-14 m-lef-5' style={{ color: "#1f8900" }} />
                                                                </button>
                                                                :
                                                                <div className='d-fx'>
                                                                    <button
                                                                        className='d-fx d-al-cen m-lef-5 fz-12' style={{ border: '1px solid #dba700', padding: '3px 5px', borderRadius: '3px', background: "#dba70020", cursor: 'pointer', maxHeight: '25px' }}
                                                                        onClick={(e) => editarEtapa(e, index)}
                                                                        disabled={(editStage && editStage != index) ? true : false}
                                                                    >
                                                                        Editar <AiFillEdit className='fz-14 m-lef-5' style={{ color: "#896900" }} />
                                                                    </button>

                                                                    <button
                                                                        className='d-fx d-al-cen m-lef-5 fz-12' style={{ border: '1px solid #ff0000', padding: '3px 5px', borderRadius: '3px', background: "#ff000020", cursor: 'pointer', maxHeight: '25px' }}
                                                                        onClick={(e) => apagarEtapa(e, index)}
                                                                        disabled={(editStage && editStage != index) ? true : false}
                                                                    >
                                                                        Apagar <BsTrash className='fz-14 m-lef-5' style={{ color: "#ff0000" }} />
                                                                    </button>
                                                                </div>
                                                        }
                                                        <span>{console.log(editStage, index)}</span>
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>
                                    <div className='box-input-single'>
                                        <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                            <span className='icon-valid'><TiInfo /></span>
                                            <span className='text-valid m-lef-5'>{`O serviço terá um total de ${props.budgetEtapas.length} etapa${(props.budgetEtapas.length > 1) ? "s" : ""}`}</span>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </form>
                </div>
            </div>

            <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                <button
                    className="cart"
                    style={{ marginTop: 0, textTransform: 'uppercase' }}
                    onClick={() => avancarOrcamento()}
                    disabled={(() => {
                        if (!props.budgetEtapas || !props.budgetEtapas.length) {
                            return true;
                        } else {
                            return false;
                        }
                    })()}
                >
                    <span className=' fz-12'>Próximo</span>
                </button>
            </div>
        </div>
    );

}

export default BudgetForm_etapas;