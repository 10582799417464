import '../Assets/styles/css/Main.css';
import '../Assets/styles/css/MainResponsivo.css';
import '../Assets/styles/css/SelectBox.css';
import { useEffect, useState, useRef } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInfoCircle, faCartPlus, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import { db } from '../../../../firebase';
import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from '../Assets/components/Arrows';

// IMPORTAÇÃO DE IMAGENS
import whats from '../../../../images/icones/svg/whats.svg';
import slide from '../../../../images/projetos/png/slide04.png';
import promo from '../../../../images/projetos/png/Projetos Prontos.png';
import home from '../../../../images/projetos/png/home1.png';
import promo2 from '../../../../images/projetos/jpg/promo.jpg';
import promoBox from '../../../../images/projetos/jpg/promo_380x400.jpg';
import promoBox2 from '../../../../images/projetos/png/oferta01.png';
import custom from '../../../../images/icones/svg/custom.svg';

// IMPORTAÇÃO DE ICONES
import { HiOutlineLightBulb, HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';
import { RiImageEditLine } from 'react-icons/ri';
import { BsFileEarmarkCheck } from 'react-icons/bs';
import { GoPackage } from 'react-icons/go';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { AiOutlineCheck } from 'react-icons/ai';
import { FcDocument, FcMoneyTransfer, FcEditImage, FcRules } from 'react-icons/fc';


// IMPORTAÇÃO DE REACT ROUTER
import { Link } from 'react-router-dom';

// IMPORTAÇÃO DE CARROSEL DE IMAGENS
// ESTILO - CARROSEL
// import 'react-multi-carousel/lib/styles.css';

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'
import { useMemo } from 'react';

import { toast } from 'react-hot-toast';

function ProjetoPersonalizado(props) {
    const [expandedSummary, setExpandedSummary] = useState(false);


    const [orcamentoFinalidade, setOrcamentoFinalidade] = useState(null);
    const [orcamentoArea, setOrcamentoArea] = useState(null);
    const [orcamentoProjetoEspecifico, setOrcamentoProjetoEspecifico] = useState(null);
    const [orcamentoNivelAlteracao, setOrcamentoNivelAlteracao] = useState(null);
    const [orcamentoLarguraTerreno, setOrcamentoLarguraTerreno] = useState(null);
    const [orcamentoComprimentoTerreno, setOrcamentoComprimentoTerreno] = useState(null);
    const [orcamentoObservacao, setOrcamentoObservacao] = useState(null);
    const [descricaoAtiva, setDescricaoAtiva] = useState(0);

    // HOOKS - NOME
    const [name, setName] = useState();
    const [nameMessage, setNameMessage] = useState([]);
    const [nameValid, setNameValid] = useState(false);

    // HOOKS - EMAIL
    const [email, setEmail] = useState();
    const [emailCadastrado, setEmailCadastrado] = useState(false);
    const [emailMessage, setEmailMessage] = useState([]);
    const [emailValid, setEmailValid] = useState(false);

    // HOOKS - TELEFONE
    const [phone, setPhone] = useState();
    const [phoneMessage, setPhoneMessage] = useState([]);
    const [phoneValid, setPhoneValid] = useState(false);
    const [phoneDDDValid, setPhoneDDDValid] = useState(false);

    const arrExemplo = [
        {
            "titulo": "Briefing",
            "descricao": "Através do planos de necessidades do cliente, você irá responder as questões e colocar tudo o que voê precisa para o seu projeto como: dimensão do seu terreno, quantidade de dormitórios, sala, cozinha, sala de jantar integrados e coisas do tipo.",
            "icone_tipo": "svg",
            "icone": "M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z",
            "fundo_tipo": "cor",
            "fundo": "rgb(102, 102, 102)"
        },
        {
            "titulo": "Orçamento",
            "descricao": "Após analisar seu brefing, um de nossos atendentes entrará em contato para solicitar mais algumas informações e elaborar uma proposta de orçamento. Caso você esteja de acordo, seguiremos com a etapa de contrato e aceite.",
            "icone_tipo": "svg",
            "icone": "M20 3c.552 0 1 .448 1 1v1.757l-2 2V5H5v8.1l4-4 4.328 4.329-1.415 1.413L9 11.93l-4 3.999V19h10.533l.708.001 1.329-1.33L18.9 19h.1v-2.758l2-2V20c0 .552-.448 1-1 1H4c-.55 0-1-.45-1-1V4c0-.552.448-1 1-1h16zm1.778 4.808l1.414 1.414L15.414 17l-1.416-.002.002-1.412 7.778-7.778zM15.5 7c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5S14 9.328 14 8.5 14.672 7 15.5 7z",
            "fundo_tipo": "cor",
            "fundo": "rgb(2, 160, 188)"
        },
        {
            "titulo": "Aprove o esboço",
            "descricao": "Com o esboço em mãos você pode analisar o projeto e sugerir mudanças até a aprovação.",
            "icone_tipo": "svg",
            "icone": "M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z",
            "fundo_tipo": "cor",
            "fundo": "rgb(102, 102, 102)"
        },
        {
            "titulo": "Receba o projeto completo",
            "descricao": "Após a sua aprovação, finalizamos o projeto desenvolvendo todos os desenhos contratados.",
            "icone_tipo": "svg",
            "icone": "M20 3c.552 0 1 .448 1 1v1.757l-2 2V5H5v8.1l4-4 4.328 4.329-1.415 1.413L9 11.93l-4 3.999V19h10.533l.708.001 1.329-1.33L18.9 19h.1v-2.758l2-2V20c0 .552-.448 1-1 1H4c-.55 0-1-.45-1-1V4c0-.552.448-1 1-1h16zm1.778 4.808l1.414 1.414L15.414 17l-1.416-.002.002-1.412 7.778-7.778zM15.5 7c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5S14 9.328 14 8.5 14.672 7 15.5 7z",
            "fundo_tipo": "cor",
            "fundo": "rgb(2, 160, 188)"
        }
    ]

    const selected = document.querySelector(".selected__option");
    const optionsContainer = document.querySelector(".options-container");
    const searchBox = document.querySelector(".search-box input");

    const optionsList = document.querySelectorAll(".option");

    function selectedMain(val) {
        console.log(document.querySelector(".options-container"))
        document.querySelector(".options-container." + val).classList.toggle("active");

        document.querySelector("." + val + ".search-box input").value = "";
        filterList("");

        if (document.querySelector(".options-container." + val).classList.contains("active")) {
            document.querySelector("." + val + ".search-box input").focus();
        }
    };
    function selectedOption(o, val) {
        if (val == 'orcamento') {
            setOrcamentoFinalidade(o.target.querySelector("input").value)
        } else if (val == 'area') {
            setOrcamentoArea(o.target.querySelector("input").value)
        } else if (val == 'projeto_especifico') {
            setOrcamentoProjetoEspecifico(o.target.querySelector("input").value)
        } else if (val == 'nivel_alteracao') {
            setOrcamentoNivelAlteracao(o.target.querySelector("input").value)
        } else if (val == 'largura') {
            setOrcamentoLarguraTerreno(o.target.querySelector("input").value)
        } else if (val == 'comprimento') {
            setOrcamentoComprimentoTerreno(o.target.querySelector("input").value)
        }
        document.querySelector(".selected__option." + val).innerHTML = o.target.querySelector("label").innerHTML;
        document.querySelector(".options-container." + val).classList.remove("active");
    };
    function selectedOptionSon(o, val) {
        if (val == 'orcamento') {
            setOrcamentoFinalidade(o.target.parentNode.querySelector("input").value)
        } else if (val == 'area') {
            setOrcamentoArea(o.target.parentNode.querySelector("input").value)
        } else if (val == 'projeto_especifico') {
            setOrcamentoProjetoEspecifico(o.target.parentNode.querySelector("input").value)
        } else if (val == 'nivel_alteracao') {
            setOrcamentoNivelAlteracao(o.target.parentNode.querySelector("input").value)
        } else if (val == 'largura') {
            setOrcamentoLarguraTerreno(o.target.parentNode.querySelector("input").value)
        } else if (val == 'comprimento') {
            setOrcamentoComprimentoTerreno(o.target.parentNode.querySelector("input").value)
        }
        document.querySelector(".selected__option." + val).innerHTML = o.target.parentNode.querySelector("label").innerHTML;
        document.querySelector(".options-container." + val).classList.remove("active");
    };

    function typingSearch(e) {
        filterList(e.target.value);
    };

    const filterList = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        optionsList.forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = "block";
            } else {
                option.style.display = "none";
            }
        });
    };

    // FUNÇÃO PARA GERAR ÁREA DE PROJETOS PARA ORÇAMENTO
    let rows = [];

    for (let i = 10; i <= 800; i += 10) {
        rows.push(
            <div className={(orcamentoArea && orcamentoArea == i) ? "option active" : "option"} onClick={(o) => selectedOption(o, "area")}>
                <input type="radio" className="radio m-top-5" id={`personalizar_orcamento_novo_projeto-area-${i}`} name="area" value={i} />
                <label for={`personalizar_orcamento_novo_projeto-area-${i}`} onClick={(o) => selectedOptionSon(o, "area")}>{i}m²</label>
            </div>
        )
    }



    // FUNÇÃO PARA GERAR ÁREA DE PROJETOS PARA ORÇAMENTO
    let larguraTerrenos = [];

    for (let i = 5; i <= 30; i += .5) {
        larguraTerrenos.push(
            <div className={(orcamentoLarguraTerreno && orcamentoLarguraTerreno == i) ? "option active" : "option"} onClick={(o) => selectedOption(o, "largura")}>
                <input type="radio" className="radio m-top-5" id={`personalizar_orcamento_novo_projeto-largura-${i}`} name="largura" value={i} />
                <label for={`personalizar_orcamento_novo_projeto-largura-${i}`} onClick={(o) => selectedOptionSon(o, "largura")}>{(i).toFixed(2).replace(".", ",")} metros</label>
            </div>
        )
    }

    let comprimentoTerrenos = [];

    for (let i = 15; i <= 40; i += .5) {
        comprimentoTerrenos.push(
            <div className={(orcamentoComprimentoTerreno && orcamentoComprimentoTerreno == i) ? "option active" : "option"} onClick={(o) => selectedOption(o, "comprimento")}>
                <input type="radio" className="radio m-top-5" id={`personalizar_orcamento_novo_projeto-comprimento-${i}`} name="comprimento" value={i} />
                <label for={`personalizar_orcamento_novo_projeto-comprimento-${i}`} onClick={(o) => selectedOptionSon(o, "comprimento")}>{(i).toFixed(2).replace(".", ",")} metros</label>
            </div>
        )
    }



    useMemo(() => {
        if (name) {
            // VERIFICAÇÃO DE LETRAS EM SENHA
            var padrao = /[^a-zà-ú\s-]/gi;

            var valida_nome = name.match(padrao);

            if (valida_nome) {
                setNameValid(false);
                setNameMessage([{
                    'status': 'error',
                    'message': 'O nome deve conter apenas letras'
                }])
            } else {
                //O NOME É VÁLIDO CONTEM APENAS LETRAS -> VERIFICAR SE O NOME É COMPLETO
                var arrName = name.split(' ');
                if (arrName.length <= 1) {
                    // O USUARIO NÃO PASSOU O NOME COMPLETO
                    setNameValid(false);
                    setNameMessage([{
                        'status': 'error',
                        'message': 'O nome deve ser completo'
                    }])
                } else {
                    // O USUARIO PASSOU O NOME COMPLETO
                    setNameValid(true);
                    setNameMessage([])
                }
            }
        }
    }, [name])

    useMemo(() => {
        if (email && email.length > 0) {
            function IsEmail(email) {
                var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                if (email == '' || !er.test(email)) { return false; }
                else { return true; }
            }
            if (!IsEmail(email)) {
                setEmailValid(false);
                setEmailMessage([{
                    'status': 'error',
                    'message': 'O e-mail informado não é válido'
                }])
            } else {
                setEmailValid(true);
                setEmailMessage([])
                // VALIDANDO CADASTRO EXISTENTE
                var usuariosCadastrados = props.cadastros.filter(produto => produto.email == email);
                if (usuariosCadastrados.length > 0) {
                    // EXISTE UM email JÁ CADASTRADO NO BANCO DE DADOS -> MOSTRAR MENSAGEM DE ERRO
                    setEmailCadastrado(true);
                } else {
                    // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
                    setEmailCadastrado(false);
                }

            }
        }

    }, [email])

    useMemo(() => {
        if (phone) {
            var phoneDDD = phone.replace(/([()])/g, '').replace("_", '').split(" ");

            if (phoneDDD[0].length == 2) { // VERIFICAR EXISTENCIA DO DDD
                function getStateFromDDD(ddd) {

                    let ddds = {
                        "AC": ["68"],
                        "AL": ["82"],
                        "AM": ["92", "97"],
                        "AP": ["96"],
                        "BA": ["71", "73", "74", "75", "77"],
                        "CE": ["85", "88"],
                        "DF": ["61"],
                        "ES": ["27", "28"],
                        "GO": ["62", "64"],
                        "MA": ["98", "99"],
                        "MG": ["31", "32", "33", "34", "35", "37", "38"],
                        "MS": ["67"],
                        "MT": ["65", "66"],
                        "PA": ["91", "93", "94"],
                        "PB": ["83"],
                        "PE": ["81", "87"],
                        "PI": ["86", "89"],
                        "PR": ["41", "42", "43", "44", "45", "46"],
                        "RJ": ["21", "22", "24"],
                        "RN": ["84"],
                        "RO": ["69"],
                        "RR": ["95"],
                        "RS": ["51", "53", "54", "55"],
                        "SC": ["47", "48", "49"],
                        "SE": ["79"],
                        "SP": ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        "TO": ["63"]
                    }

                    for (let index in ddds) {
                        if (ddds[index].includes(ddd)) {
                            return true;
                        }
                    }
                }

                var retornoDDD = getStateFromDDD(phoneDDD[0]);
                console.log(retornoDDD);

                if (retornoDDD) {
                    setPhoneDDDValid(true)
                    setPhoneValid(true);
                } else {
                    setPhoneDDDValid(false)
                    setPhoneValid(false);
                }
            }

            var padrao = /(\d+)| /g;

            if (!phone.match(padrao)) {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([{
                    'status': 'error',
                    'message': 'O telefone deve conter apenas números'
                }]);
            } else {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([]);
            }
        }
    }, [phone])

    function validatePhone() {
        let newPhoneClear = phone.replace(/[^\d]+/g, '');
        if (newPhoneClear.length < 11) {
            setPhoneValid(false);
            setPhoneMessage([{
                'status': 'error',
                'message': 'O telefone deve conter o DDD'
            }]);
        } else {
            setPhoneValid(true);
            setPhoneMessage([]);
        }
    }

    function enviarFormularioPersonalizacao(el) {
        el.preventDefault();

        if (!orcamentoFinalidade || orcamentoFinalidade.length <= 0) {
            toast.error("A finalidade do orçamento não foi definida");
        } else if (!orcamentoArea || orcamentoArea.length <= 0) {
            toast.error("A área aproximada da construção não foi definida");
        } else if (!name || name.length <= 0) {
            toast.error("Você precisa informar seu nome completo");
        } else if (!nameValid) {
            toast.error("O nome informado não é válido");
        } else if (!email || email.length <= 0) {
            toast.error("Você precisa informar seu e-mail completo");
        } else if (!emailValid) {
            toast.error("O e-mail informado não é válido");
        } else if (!phone || phone.length <= 0) {
            toast.error("Você precisa informar seu telefone completo");
        } else if (!phoneDDDValid) {
            toast.error("O DDD do telefone informado não é válido");
        } else if (!phoneValid) {
            toast.error("O telefone informado não é válido");
        } else {
            // TUDO ESTÁ OK E PODEMOS REGISTRAR A SOLICITAÇÃO DE ORÇAMENTO NO BD
            db.collection("relatorios").doc("orcamentos").collection("empresa").add({
                "categoria": "projeto_personalizado",
                "finalidade": orcamentoFinalidade,
                "area": orcamentoArea,
                "nome": name,
                "email": email,
                "telefone": phone,
                "observacao": orcamentoObservacao
            })
                .then(() => {
                    toast.success("Solicitação enviada com sucesso!")
                    setOrcamentoFinalidade(null);
                    setOrcamentoArea(null);
                    setOrcamentoObservacao('');
                    setName('');
                    setEmail('');
                    setPhone('');

                })
        }
    }
    return (
        <div id='projetoPersonalizado'>
            <Helmet title="Engeart • Projeto Personalizado" />
            <div className='projeto-personalizado-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item active m-0">Projeto Personalizado</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <h1 className='tx-cen b-bot' style={{ fontWeight: 'lighter' }}>Projeto Personalizado</h1>
                        <div className='description-page w80' style={{ margin: '10px auto' }}>
                            <motion.div
                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                animate={{ height: expandedSummary ? "auto" : "50px" }}
                                className='description-page__content'
                            >
                                <p className='fz-16'>
                                    Projetos residenciais e comerciais personalizados são projetos de arquitetura e design de interiores 100% exclusivos, na qual são concebidos especificamente para atender às necessidades e desejos únicos de um cliente. Em vez de seguir um padrão pré-estabelecido ou usar soluções genéricas, os projetos personalizados são criados a partir do zero pela equipe Engeart para você, levando em consideração as preferências do cliente em termos de estilo, funcionalidade e orçamento, esta é a modalidade para o nosso cliente que não encontrou o procurava entre as opções de projetos prontos e deseja algo único, onde todos os detalhes são pensados sob medida às suas necessidas.
                                </p>
                                <p className='fz-16'>
                                    Em projetos residenciais personalizados, o arquiteto ou designer de interiores trabalha com o cliente para criar um projeto que se adapte perfeitamente às suas necessidades e ao layout do local. Eles podem incluir recursos personalizados, como cozinhas gourmet, quartos adicionais, áreas de entretenimento, etc.
                                </p>
                                <p className='fz-16'>
                                    Já em projetos comerciais personalizados, o objetivo é criar um espaço de trabalho funcional e atraente que reflita a personalidade e as metas da empresa. Isso pode incluir a criação de um layout de escritório específico, a seleção de mobiliário e acabamentos, e a incorporação de tecnologias avançadas para aumentar a eficiência e a produtividade.
                                </p>
                                <p className='fz-16'>
                                    Em ambos os casos, projetos residenciais e comerciais personalizados oferecem aos clientes a oportunidade de ter um espaço único e personalizado que atenda perfeitamente às suas necessidades.
                                </p>
                            </motion.div>
                            <div className='description-page__btn-toggle'>
                                <div className='line-wrapper w100'>
                                    <span onClick={() => setExpandedSummary(!expandedSummary)}>{(!expandedSummary) ? "Ver Mais" : "Ver Menos"}</span>
                                </div>
                            </div>
                        </div>
                        <div className='m-top-20 b-bot'>
                            <h2 className='fz-20 tx-cen'>Solicite o projeto do seu jeito em poucas etapas:</h2>
                            {
                                (props.responsivoSize && props.responsivoSize < 1023)
                                    ?
                                    <Carousel
                                        itemsToShow={1}
                                        pagination={false}
                                        className='options__personalizar d-fx d-ju-sp-ev m-top-20 m-bot-25 p-rlt'
                                        style={{ width: '80%' }}
                                        renderArrow={({ type, onClick, isEdge }) => {
                                            const pointer = type === consts.PREV ? <ArrowLeft /> : <ArrowRight />
                                            return (
                                                <button className='btn__controller__carousel' onClick={onClick} disabled={isEdge} style={{ zIndex: 1 }}>
                                                    {pointer}
                                                </button>
                                            )
                                        }}
                                        onChange={(currentItem, pageIndex) => setDescricaoAtiva(pageIndex)}
                                    >

                                        {
                                            (arrExemplo)
                                                ?
                                                arrExemplo.map((dados, index) => {
                                                    console.log(descricaoAtiva)
                                                    return (
                                                        <li data={index} className={(descricaoAtiva == 0) ? 'option__personalizar__single active p-rlt d-fx d-fd-col d-al-cen d-ju-cen' : 'option__personalizar__single p-rlt d-fx d-fd-col d-al-cen d-ju-cen'}>
                                                            <div className='icon__option_single d-fx d-ju-cen d-al-cen' style={{ background: dados.fundo }}>
                                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="fz-50" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
                                                                        <path d={dados.icone}></path>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <h3 className='tx-cen fz-16 m-top-10 m-bot-10' style={{ color: dados.fundo }}>{dados.titulo}</h3>
                                                        </li>
                                                    )
                                                })
                                                : null
                                        }
                                    </Carousel>
                                    :
                                    <ul className='options__personalizar d-fx d-ju-sp-ev m-top-20 m-bot-25 w80'>
                                        <li className={(descricaoAtiva === "briefing") ? 'option__personalizar__single active p-rlt d-fx d-fd-col d-al-cen d-ju-cen' : 'option__personalizar__single p-rlt d-fx d-fd-col d-al-cen d-ju-cen'} onClick={() => setDescricaoAtiva("briefing")}>
                                            <div className='icon__option_single d-fx d-ju-cen d-al-cen' style={{ background: '#e9e9e9', border: '1px solid var(--grey)' }}>
                                                <FcDocument className='fz-50' />
                                            </div>
                                            <h3 className='tx-cen fz-16 m-top-10 m-bot-10' style={{ color: "var(--grey)", fontWeight: 'bold' }}>Briefing</h3>
                                        </li>
                                        <li className={(descricaoAtiva === "orcamento") ? 'option__personalizar__single active p-rlt d-fx d-fd-col d-al-cen d-ju-cen' : 'option__personalizar__single p-rlt d-fx d-fd-col d-al-cen d-ju-cen'} onClick={() => setDescricaoAtiva("orcamento")}>
                                            <div className='icon__option_single d-fx d-ju-cen d-al-cen' style={{ background: '#e9e9e9', border: '1px solid var(--grey)' }}>
                                                <FcMoneyTransfer className='fz-50' />
                                            </div>
                                            <h3 className='tx-cen fz-16 m-top-10 m-bot-10' style={{ color: "var(--grey)", fontWeight: 'bold' }}>Orçamento</h3>
                                        </li>
                                        <li className={(descricaoAtiva === "inicio_projeto") ? 'option__personalizar__single active p-rlt d-fx d-fd-col d-al-cen d-ju-cen' : 'option__personalizar__single p-rlt d-fx d-fd-col d-al-cen d-ju-cen'} onClick={() => setDescricaoAtiva("inicio_projeto")}>
                                            <div className='icon__option_single d-fx d-ju-cen d-al-cen' style={{ background: '#e9e9e9', border: '1px solid var(--grey)' }}>
                                                <FcEditImage className='fz-50' />
                                            </div>
                                            <h3 className='tx-cen fz-16 m-top-10 m-bot-10' style={{ color: "var(--grey)", fontWeight: 'bold' }}>Início do Projeto</h3>
                                        </li>
                                        <li className={(descricaoAtiva === "conclusao_projeto") ? 'option__personalizar__single active p-rlt d-fx d-fd-col d-al-cen d-ju-cen' : 'option__personalizar__single p-rlt d-fx d-fd-col d-al-cen d-ju-cen'} onClick={() => setDescricaoAtiva("conclusao_projeto")}>
                                            <div className='icon__option_single d-fx d-ju-cen d-al-cen' style={{ background: '#e9e9e9', border: '1px solid var(--grey)' }}>
                                                <FcRules className='fz-50' />
                                            </div>
                                            <h3 className='tx-cen fz-16 m-top-10 m-bot-10' style={{ color: "var(--grey)", fontWeight: 'bold' }}>Entrega do Projeto</h3>
                                        </li>
                                        {/* <li data={index} className={(descricaoAtiva == index) ? 'option__personalizar__single active p-rlt d-fx d-fd-col d-al-cen d-ju-cen' : 'option__personalizar__single p-rlt d-fx d-fd-col d-al-cen d-ju-cen'} onClick={() => setDescricaoAtiva(index)}>
                                                            <div className='icon__option_single d-fx d-ju-cen d-al-cen' style={{ background: dados.fundo }}>
                                                                <span className='fz-50'>{index + 1}</span>
                                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="fz-50" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
                                                                        <path d={dados.icone}></path>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <h3 className='tx-cen fz-16 m-top-10 m-bot-10' style={{ color: dados.fundo }}>{dados.titulo}</h3>
                                                        </li> */}
                                    </ul>
                            }

                            <ul className='m-bot-25 w80' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                {(() => {
                                    if (descricaoAtiva === 'briefing') {
                                        return (
                                            <li className={(props.responsivoSize && props.responsivoSize < 1023) ? 'description__option__single descricao__responsiva fz-13 p-rlt' : 'description__option__single fz-13 p-rlt'}>
                                                <p className='fz-14'>Através do planos de necessidades do cliente, você irá responder as questões e colocar tudo o que voê precisa para o seu projeto como: dimensão do seu terreno, quantidade de dormitórios, sala, cozinha, sala de jantar integrados e coisas do tipo.</p>
                                            </li>
                                        )
                                    } else if (descricaoAtiva === 'orcamento') {
                                        return (
                                            <li className={(props.responsivoSize && props.responsivoSize < 1023) ? 'description__option__single descricao__responsiva fz-13 p-rlt' : 'description__option__single fz-13 p-rlt'}>
                                                <p className='fz-14'>Através do planos de necessidades do cliente, você irá responder as questões e colocar tudo o que voê precisa para o seu projeto como: dimensão do seu terreno, quantidade de dormitórios, sala, cozinha, sala de jantar integrados e coisas do tipo.</p>
                                            </li>
                                        )
                                    } else if (descricaoAtiva === 'inicio_projeto') {
                                        return (
                                            <li className={(props.responsivoSize && props.responsivoSize < 1023) ? 'description__option__single descricao__responsiva fz-13 p-rlt' : 'description__option__single fz-13 p-rlt'}>
                                                <ul>
                                                    <strong style={{background: '#e9e9e9', border: '1px solid (--grey)', padding: '5px 10px'}} className="b-bot">Nossa plataforma conta com acompanhamento digital de cada fase do seu projeto, na qual você pode acessar e acompanhar seu projeto onde quer que esteja em tempo real.</strong>
                                                    <li>
                                                        <h4 className='fz-16 tx-bold'>1º Etapa: Esboço do projeto</h4>
                                                        <p className='fz-14'>Desenvolveremos a planta da sua casa dentro das informações passadas em briefing + as informações coletadas pelos atendentes.</p>
                                                    </li>
                                                    <li>
                                                        <h4 className='fz-16 tx-bold'>2º Etapa: Projeto de fachada</h4>
                                                        <p className='fz-14'>Nessa fase criaremos a volumetria do seu projeto. Desta forma, imagens de referência, estilo, cores, e texturas ajudam seu arquiteto a ser certeiro no desenvolvimento da sua fachada.</p>
                                                    </li>
                                                    <li>
                                                        <h4 className='fz-16 tx-bold'>3º Etapa: Projeto arquitetônico</h4>
                                                        <p className='fz-14'>Nessa fase teremos a planta e a fachadas definidas e aprovadas, então iniciaremos o detalhamento completo do seu projeto arquitetônico.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        )
                                    } else if (descricaoAtiva === 'conclusao_projeto') {
                                        return (
                                            <li className={(props.responsivoSize && props.responsivoSize < 1023) ? 'description__option__single descricao__responsiva fz-13 p-rlt' : 'description__option__single fz-13 p-rlt'}>
                                                <p className='fz-14'>Através do planos de necessidades do cliente, você irá responder as questões e colocar tudo o que voê precisa para o seu projeto como: dimensão do seu terreno, quantidade de dormitórios, sala, cozinha, sala de jantar integrados e coisas do tipo.</p>
                                            </li>
                                        )
                                    }
                                })()}
                            </ul>
                        </div>

                        <div className="grid product d-fx m-bot-100 d-al-cen content__formulario__imagem" style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column-reverse' } : null}>
                            <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__formulario__imagem column-xs-12 column-md-5 w40 m-0 left" : "wrapper__formulario__imagem column-xs-12 column-md-5 w100 m-0"}>
                                <div className="box-header-proj-single b-bot">
                                    <h1>Diga como deseja personalizar seu projeto</h1>
                                    <p className="fz-12 m-top-10 m-bot-10">* Fique a vontade para solicita ajuda, tirar dúvidas, ou solicitar um suporte totalmente personalizado e privativo.</p>
                                    <p className="fz-11 m-top-10 m-bot-10 ">Para reembolso, reclamações e etc, <a className='fz-11' style={{ color: 'var(--dark-grey-blue)' }} href="javascript:void(0)">clique aqui</a>.</p>
                                </div>{/*box-header-proj-single*/}

                                {/* BOX FORMULARIO CONTATO */}

                                <form id="form_projeto_personalizado" className='singup-person' onSubmit={(el) => enviarFormularioPersonalizacao(el)}>
                                    {/* OPÇÃO GERAL - DEFINIR TIPO DE ORÇAMENTO */}
                                    <div class="box-input-single m-top-20">
                                        <label>
                                            <div className='title-input d-fx d-al-cen'>
                                                <span className='fz-12 m-rig-5'>Solicite um orçamento para:</span>
                                                <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                            </div>
                                        </label>

                                        <div class="select-box">
                                            <div class="options-container orcamento">
                                                <div class="search-box">
                                                    <input className='input-form' type="text" placeholder="Busque aqui a opção desejada..." onKeyUp={(e) => typingSearch(e)} />
                                                </div>

                                                <div className={(orcamentoFinalidade && orcamentoFinalidade == 'criar') ? "option active" : "option"} onClick={(o) => selectedOption(o, "orcamento")}>
                                                    <input type="radio" className="radio m-top-5" id="personalizar_orcamento_novo_projeto" name="orcamento" value="criar" />
                                                    <label for="personalizar_orcamento_novo_projeto" onClick={(o) => selectedOptionSon(o, "orcamento")}>Novo Projeto</label>
                                                </div>

                                                <div class="option" onClick={(o) => selectedOption(o, "orcamento")}>
                                                    <input type="radio" class="radio" id="personalizar_orcamento_alterar_projeto" name="orcamento" value="alterar" />
                                                    <label for="personalizar_orcamento_alterar_projeto" onClick={(o) => selectedOptionSon(o, "orcamento")}>Alterar um projeto pronto</label>
                                                </div>

                                                <div class="option" onClick={(o) => selectedOption(o, "orcamento")}>
                                                    <input type="radio" class="radio" id="personalizar_orcamento_adaptar_projeto" name="orcamento" value="adaptar" />
                                                    <label for="personalizar_orcamento_adaptar_projeto" onClick={(o) => selectedOptionSon(o, "orcamento")}>Adaptar um projeto pronto ao meu terreno</label>
                                                </div>
                                            </div>

                                            <div class="selected__option orcamento input-form" onClick={() => selectedMain("orcamento")}>
                                                {
                                                    (!orcamentoFinalidade)
                                                        ?
                                                        "Selecione o que deseja personalizar no projeto"
                                                        : (() => {
                                                            if (orcamentoFinalidade == 'criar') {
                                                                return "Novo Projeto";
                                                            } else if (orcamentoFinalidade == 'alterar') {
                                                                return "Alterar um projeto pronto"
                                                            } else if (orcamentoFinalidade == 'adaptar') {
                                                                return "Adaptar um projeto pronto ao meu terreno"
                                                            }
                                                        })()
                                                }
                                            </div>

                                            <input id='personalizar_orcamento_finalidade' type="text" name="personalizar_orcamento_finalidade" value={orcamentoFinalidade} readOnly disabled style={{ display: "none" }} />
                                        </div>
                                        {
                                            (!orcamentoFinalidade || orcamentoFinalidade.length <= 0)
                                                ?
                                                <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>Nenhuma opção para orçamento selecionada</span>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                    {/* OPÇÃO 1 - NOVO PROJETO */}
                                    {
                                        (orcamentoFinalidade && orcamentoFinalidade == "criar")
                                            ?
                                            <div class="box-input-single m-top-20">
                                                <label>
                                                    <div className='title-input d-fx d-al-cen'>
                                                        <span className='fz-12 m-rig-5'>Área construída (aproximadamente):</span>
                                                        <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                    </div>
                                                </label>

                                                <div class="select-box">
                                                    <div class="options-container area">
                                                        <div class="search-box">
                                                            <input className='input-form' type="text" placeholder="Busque aqui a opção desejada..." onKeyUp={(e) => typingSearch(e)} />
                                                        </div>
                                                        {
                                                            rows
                                                        }
                                                    </div>

                                                    <div class="selected__option area input-form" onClick={() => selectedMain('area')}>
                                                        Selecione o que deseja personalizar no projeto
                                                    </div>

                                                    <input id='personalizar_orcamento_finalidade' type="text" name="personalizar_orcamento_finalidade" value={orcamentoFinalidade} readOnly disabled style={{ display: "none" }} />
                                                </div>
                                                {
                                                    (!orcamentoFinalidade || orcamentoFinalidade.length <= 0)
                                                        ?
                                                        <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                            <span className='icon-invalid'><TiInfo /></span>
                                                            <span className='text-invalid m-lef-5'>Nenhuma opção para área selecionada</span>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            : null
                                    }
                                    {/* OPÇÃO 2 - ALTERAR PROJETO PRONTO */}
                                    {
                                        (orcamentoFinalidade && orcamentoFinalidade == "alterar" || orcamentoFinalidade == "adaptar")
                                            ?
                                            <div>
                                                {/* SELECIONAR PROJETO PRONTO */}
                                                <div class="box-input-single m-top-20">
                                                    <label>
                                                        <div className='title-input d-fx d-al-cen'>
                                                            <span className='fz-12 m-rig-5'>Selecione o projeto que deseja alterar:</span>
                                                            <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                        </div>
                                                    </label>

                                                    <div class="select-box">
                                                        <div class="options-container projeto_especifico">
                                                            <div class="search-box">
                                                                <input className='input-form' type="text" placeholder="Busque aqui o projeto desejado..." onKeyUp={(e) => typingSearch(e)} />
                                                            </div>
                                                            {
                                                                props.projetos.map((dadosProjeto) => {
                                                                    return (

                                                                        <div className={(orcamentoProjetoEspecifico && orcamentoProjetoEspecifico == dadosProjeto.orderId) ? "option active" : "option"} onClick={(o) => selectedOption(o, "projeto_especifico")}>
                                                                            <input type="radio" className="radio m-top-5" id={`personalizar_orcamento_novo_projeto-codigo-${dadosProjeto.orderId}`} name="projeto_especifico" value={dadosProjeto.orderId} />
                                                                            <label for={`personalizar_orcamento_novo_projeto-codigo-${dadosProjeto.orderId}`} onClick={(o) => selectedOptionSon(o, "projeto_especifico")}>E{dadosProjeto.orderId}A • {dadosProjeto.titulo} </label>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                                        <div class="selected__option projeto_especifico input-form" onClick={() => selectedMain('projeto_especifico')}>
                                                            Selecione qual projeto deseja alterar
                                                        </div>

                                                        <input id='personalizar_orcamento_projetoEspecifico' type="text" name="personalizar_orcamento_projetoEspecifico" value={orcamentoProjetoEspecifico} readOnly disabled style={{ display: "none" }} />
                                                    </div>
                                                    {
                                                        (!orcamentoProjetoEspecifico || orcamentoProjetoEspecifico.length <= 0)
                                                            ?
                                                            <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                <span className='text-invalid m-lef-5'>Nenhuma projeto foi selecionado</span>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                {/* SELECIONAR NIVEL DA ALTERAÇÃO */}
                                                {
                                                    (orcamentoFinalidade == "alterar")
                                                        ?
                                                        <div class="box-input-single m-top-20">
                                                            <label>
                                                                <div className='title-input d-fx d-al-cen'>
                                                                    <span className='fz-12 m-rig-5'>Qual o nível da alteração?</span>
                                                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                                </div>
                                                            </label>

                                                            <div class="select-box">
                                                                <div class="options-container nivel_alteracao">
                                                                    <div class="search-box">
                                                                        <input className='input-form' type="text" placeholder="Busque aqui o projeto desejado..." onKeyUp={(e) => typingSearch(e)} />
                                                                    </div>

                                                                    <div className={(orcamentoNivelAlteracao && orcamentoNivelAlteracao == "baixo") ? "option active" : "option"} onClick={(o) => selectedOption(o, "nivel_alteracao")}>
                                                                        <input type="radio" className="radio m-top-5" id="personalizar_orcamento_novo_projeto-nivel-baixo" name="nivel_alteracao" value="baixo" />
                                                                        <label for="personalizar_orcamento_novo_projeto-nivel-baixo" onClick={(o) => selectedOptionSon(o, "nivel_alteracao")}>Simples (Pequenas mudanças em paredes, portas ou janelas)</label>
                                                                    </div>
                                                                    <div className={(orcamentoNivelAlteracao && orcamentoNivelAlteracao == "medio") ? "option active" : "option"} onClick={(o) => selectedOption(o, "nivel_alteracao")}>
                                                                        <input type="radio" className="radio m-top-5" id="personalizar_orcamento_novo_projeto-nivel-medio" name="nivel_alteracao" value="medio" />
                                                                        <label for="personalizar_orcamento_novo_projeto-nivel-medio" onClick={(o) => selectedOptionSon(o, "nivel_alteracao")}>Média (Aumentar, diminuir ou excluir ambientes)</label>
                                                                    </div>
                                                                    <div className={(orcamentoNivelAlteracao && orcamentoNivelAlteracao == "alto") ? "option active" : "option"} onClick={(o) => selectedOption(o, "nivel_alteracao")}>
                                                                        <input type="radio" className="radio m-top-5" id="personalizar_orcamento_novo_projeto-nivel-alto" name="nivel_alteracao" value="alto" />
                                                                        <label for="personalizar_orcamento_novo_projeto-nivel-alto" onClick={(o) => selectedOptionSon(o, "nivel_alteracao")}>Alta (Alterar fachada, refazer maior parte do projeto)</label>
                                                                    </div>
                                                                </div>

                                                                <div class="selected__option nivel_alteracao input-form" onClick={() => selectedMain('nivel_alteracao')}>
                                                                    Selecione qual projeto deseja alterar
                                                                </div>

                                                                <input id='personalizar_orcamento_nivelAlteracao' type="text" name="personalizar_orcamento_nivelAlteracao" value={orcamentoNivelAlteracao} readOnly disabled style={{ display: "none" }} />
                                                            </div>
                                                            {
                                                                (!orcamentoNivelAlteracao || orcamentoNivelAlteracao.length <= 0)
                                                                    ?
                                                                    <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                                        <span className='icon-invalid'><TiInfo /></span>
                                                                        <span className='text-invalid m-lef-5'>Nenhum nível de alteração foi selecionado</span>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        : null
                                                }
                                                {/* SELECIOANR LARGURA DO TERRENO */}
                                                <div class="box-input-single m-top-20">
                                                    <label>
                                                        <div className='title-input d-fx d-al-cen'>
                                                            <span className='fz-12 m-rig-5'>Largura do meu terreno:</span>
                                                            <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                        </div>
                                                    </label>

                                                    <div class="select-box">
                                                        <div class="options-container largura">
                                                            <div class="search-box">
                                                                <input className='input-form' type="text" placeholder="Busque aqui a largura do terreno desejada..." onKeyUp={(e) => typingSearch(e)} />
                                                            </div>
                                                            {
                                                                larguraTerrenos
                                                            }
                                                        </div>

                                                        <div class="selected__option largura input-form" onClick={() => selectedMain('largura')}>
                                                            Selecione a largura do seu terreno
                                                        </div>

                                                        <input id='personalizar_orcamento_largura' type="text" name="personalizar_orcamento_largura" value={orcamentoLarguraTerreno} readOnly disabled style={{ display: "none" }} />
                                                    </div>
                                                    {
                                                        (!orcamentoLarguraTerreno || orcamentoLarguraTerreno.length <= 0)
                                                            ?
                                                            <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                <span className='text-invalid m-lef-5'>Nenhuma opção para largura do terreno selecionada</span>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                {/* SELECIOANR COMPRIMENTO DO TERRENO */}
                                                <div class="box-input-single m-top-20">
                                                    <label>
                                                        <div className='title-input d-fx d-al-cen'>
                                                            <span className='fz-12 m-rig-5'>Comprimento do meu terreno:</span>
                                                            <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                                        </div>
                                                    </label>

                                                    <div class="select-box">
                                                        <div class="options-container comprimento">
                                                            <div class="search-box">
                                                                <input className='input-form' type="text" placeholder="Busque aqui a comprimento do terreno desejada..." onKeyUp={(e) => typingSearch(e)} />
                                                            </div>
                                                            {
                                                                comprimentoTerrenos
                                                            }
                                                        </div>

                                                        <div class="selected__option comprimento input-form" onClick={() => selectedMain('comprimento')}>
                                                            Selecione o comprimento do seu terreno
                                                        </div>

                                                        <input id='personalizar_orcamento_comprimentoTerreno' type="text" name="personalizar_orcamento_comprimentoTerreno" value={orcamentoComprimentoTerreno} readOnly disabled style={{ display: "none" }} />
                                                    </div>
                                                    {
                                                        (!orcamentoComprimentoTerreno || orcamentoComprimentoTerreno.length <= 0)
                                                            ?
                                                            <div className='info-invalid d-fx d-al-cen m-top-5'>
                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                <span className='text-invalid m-lef-5'>Nenhuma opção para comprimento do terreno selecionada</span>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }

                                    {/* OPÇÕES GERAIS - DADOS PESSOAIS E CONTATO */}

                                    <div className='box-input-single m-top-20'>
                                        <label>
                                            <div className='title-input d-fx d-al-cen'>
                                                <span className='fz-12 m-rig-5'>Nome Completo</span>
                                                <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                            </div>
                                            <div className='helper-input d-nn'>
                                                <h3>Seu CPF será usado para:</h3>
                                                <ul>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                </ul>
                                            </div>{/*Helper*/}
                                        </label>
                                        <input id="nome-cadastro-singup" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                                        {
                                            (nameMessage)
                                                ?
                                                nameMessage.map((infoStatus) => {
                                                    if (infoStatus.status == 'error') {
                                                        return (
                                                            <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                            </div>
                                                        )
                                                    } else if (infoStatus.status == 'success') {
                                                        return (
                                                            <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                <span className='icon-valid'><TiInfo /></span>
                                                                <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                : null
                                        }
                                    </div>{/*box-input-single */}

                                    <div className='box-input-single m-top-20'>
                                        <label>
                                            <div className='title-input d-fx d-al-cen'>
                                                <span className='fz-12 m-rig-5'>E-mail</span>
                                                <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                            </div>
                                            <div className='helper-input d-nn'>
                                                <h3>Seu CPF será usado para:</h3>
                                                <ul>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                    <li>
                                                        Te Identificar
                                                    </li>
                                                </ul>
                                            </div>{/*Helper*/}
                                        </label>
                                        <input id='email-cadastro-singup' className={(email && !emailValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='email' name="email" placeholder='seuemail@email.com' value={email} onChange={(ev) => setEmail(ev.target.value)} />
                                        {
                                            (emailMessage)
                                                ?
                                                emailMessage.map((infoStatus) => {
                                                    if (infoStatus.status == 'error') {
                                                        return (
                                                            <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                <span className='icon-invalid'><TiInfo /></span>
                                                                <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                            </div>
                                                        )
                                                    } else if (infoStatus.status == 'success') {
                                                        return (
                                                            <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                <span className='icon-valid'><TiInfo /></span>
                                                                <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                : null
                                        }
                                    </div>{/*box-input-single */}

                                    <div className='box-input-single m-top-20'>
                                        <label>
                                            <div className='title-input d-fx d-al-cen'>
                                                <span className='fz-12 m-rig-5'>Telefone</span>
                                                <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                            </div>
                                        </label>
                                        <InputMask id='telefone-cadastro-singup' mask="(99) 99999-9999" placeholder='Insira seu telefone' className={(phone && phone.match('[0-9]') && !phoneValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} value={phone} onChange={(ev) => setPhone(ev.target.value)} onBlur={() => validatePhone()} />
                                        <div>
                                            {
                                                (!phoneDDDValid)
                                                    ?
                                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-invalid'><TiInfo /></span>
                                                        <span className='text-invalid m-lef-5'>O DDD informado não é válido</span>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div>
                                            {
                                                (phoneMessage)
                                                    ?
                                                    phoneMessage.map((infoStatus) => {
                                                        if (infoStatus.status == 'error') {
                                                            return (
                                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-invalid'><TiInfo /></span>
                                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        } else if (infoStatus.status == 'success') {
                                                            return (
                                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                    <span className='icon-valid'><TiInfo /></span>
                                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    : null
                                            }
                                        </div>
                                    </div>{/*box-input-single */}

                                    <div className='box-input-single m-top-20'>
                                        <label>
                                            <div className='title-input d-fx d-al-cen'>
                                                <span className='fz-12 m-rig-5'>Observação</span>
                                                <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                            </div>
                                        </label>
                                        {/* <input id='telefone-cadastro' className='input-form w100 fz-16 m-top-5' type='text' name="nome" placeholder='(99) 99999-9999' /> */}
                                        <textarea className='input-form w100 fz-14 m-top-5' style={{ maxWidth: '100%', resize: 'vertical' }} value={orcamentoObservacao} onChange={(ev) => setOrcamentoObservacao(ev.target.value)}>
                                            {
                                                orcamentoObservacao
                                            }
                                        </textarea>
                                    </div>{/*box-input-single */}

                                    <button className="button button-full button-primary margin-top align-left">
                                        Concordo e quero prosseguir
                                    </button>
                                    <div className='aviso-termos m-top-10 helper'>
                                        Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                                    </div>
                                </form>
                            </div>
                            <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__imagem__formulario w50 d-fx d-al-cen d-ju-cen m-top-100 right m-bot-0" : "wrapper__imagem__formulario w100 d-fx d-al-cen d-ju-cen m-top-100 m-bot-0"}>
                                <img src={custom} style={(props.responsivoSize && props.responsivoSize < 350) ? { width: '100%' } : { maxWidth: '80%' }} />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>{/*center*/}
            </div>
        </div>
    );
}

export default ProjetoPersonalizado;