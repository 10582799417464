import { Link, useLocation } from 'react-router-dom'; // IMPORTAÇÃO DE REACT ROUTER
import '../Assets/styles/css/Main.css';
import { useEffect, useState, useRef } from 'react';
import Helmet from 'react-helmet'; // IMPORTAÇÃO DE MANIPULADOR DE TITLE

// IMPORTAÇÃO DE IMAGENS
import addCart from '../../../../images/icones/gif/Add to Cart.gif';
import emptyCart from '../../../../images/icones/svg/empty-cart.svg';
import iconSuport from '../../../../images/icones/png/icon-suport.png';
import iconLove from '../../../../images/icones/png/icon-love.png';
import iconAlert from '../../../../images/icones/png/icon-alert.png';
import atendimento from '../../../../images/projetos/jpg/atendimento.jpg';

// IMPORTAÇÃO DE ICONES
import { HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { AiOutlineMail, AiOutlinePhone, AiOutlineClockCircle } from 'react-icons/ai';
import { BsChevronRight, BsCalendarRange } from 'react-icons/bs';
import { BiMapPin } from 'react-icons/bi';





import { AiOutlineCheck, AiFillInfoCircle } from 'react-icons/ai';
import { FaEye, FaHeart, FaShareSquare, FaStar } from 'react-icons/fa';

// IMPORTAÇÃO DE IMAGENS
import whats from '../../../../images/icones/svg/whats.svg';
import slide from '../../../../images/projetos/png/slide04.png';
import promo from '../../../../images/projetos/png/Projetos Prontos.png';
import home from '../../../../images/projetos/png/home1.png';
import promo2 from '../../../../images/projetos/jpg/promo.jpg';
import promoBox from '../../../../images/projetos/jpg/promo_380x400.jpg';

// IMPORTAÇÃO DE CARROSEL DE IMAGENS
// ESTILO - CARROSEL
// import 'react-multi-carousel/lib/styles.css';
// FUNÇÃO - CARROSSEL
// import Carousel from 'react-multi-carousel';
import CardsProjetos from '../Assets/components/CardsProjetos';
import CuponsOfertas from '../Assets/components/CuponsOfertas';
import Newsletter from '../Assets/components/Newsletter';



function NoMatch(props) {
    let urlLocation = useLocation().pathname.replace('/', '');

    return (
        <div id='carrinho'>
            <Helmet title="Engeart • Página não encontrada" />
            <div className='projeto-single-wrapper'>
                <div className='center'>
                    <div className='box-product-page'>
                        <div className="grid second-nav">
                            <div className="column-xs-12">
                                <nav>
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item m-0">
                                            <Link className="m-0" to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item active m-0">Meu Carrinho</li>
                                    </ol>
                                </nav>
                            </div>

                            <section className='p-g-20 m-top-10' style={{ textAlign: 'center' }}>
                                <h2 className='fz-25 m-bot-20' style={{ color: '#000' }}>Desculpe, não localizamos a página <i>"{urlLocation}"</i> pesquisada.</h2>
                                <p className='m-bot-25'>Volte para a página inicial e continue pesquisando e se inspirando ;)</p>
                                <Link to="/" className='btn-minimalista'>Voltar para a página inicial</Link>
                            </section>


                            <CardsProjetos titulo="Sentimos muito por não achar sua página, mas separamos esses projetos para você" user={props.user} setOpenLike={props.setOpenLike} projetos={props.projetos} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} setItemFavorito={props.setItemFavorito} listasFavoritos={props.listasFavoritos} favorito={props.favorito} visualizacao={props.visualizacao} favoritados={props.favoritados} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} setItensAvaliados={props.setItensAvaliados} />



                            {/* SESSÃO - CUPONS - PROMOÇÕES */}
                            <CuponsOfertas />

                            {/* SESSÃO - NEWSLETTER */}
                            <Newsletter user={props.user} />
                        </div>
                    </div>
                </div>{/*center*/}
            </div>
        </div>
    );
}

export default NoMatch;