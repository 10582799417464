import '../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db } from '../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from './Templates/Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiNotebook } from 'react-icons/gi';
import { AiOutlineFolderView, AiOutlineCheck, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaMoneyBill } from 'react-icons/fa';

import { BsCreditCard2Front, BsCashCoin } from 'react-icons/bs';
import { ImQrcode } from 'react-icons/im';
import { GrTransaction } from 'react-icons/gr';
import { FcMoneyTransfer } from 'react-icons/fc';
import { RiHomeGearLine } from 'react-icons/ri';
import { FaBarcode, FaApplePay, FaGooglePay, FaStar } from 'react-icons/fa';


import contractOkay from '../../../../../../images/icones/svg/Construction costs-pana.svg';
import searchFolder from '../../../../../../images/icones/svg/search-folder.svg';
import searchDocument from '../../../../../../images/icones/svg/search-document.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';

function MyBudgetSingle(props) {
    const [expandedSummary, setExpandedSummary] = useState(false); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);


    const [contratos, setContratos] = useState([]);
    const [slideCard, setSlideCard] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('');

    function aprovarOrcamento(e) {
        e.preventDefault();

        if (!props.user || isEmptyObject(props.user)) {
            toast.error("Não foi possivel recuperar as informações do usuário logado!");
        } else if (!props.orcamentoUnico || !props.orcamentoUnico.cpf_cnpj_contratante) {
            toast.error("Não foi possivel recuperar as informações do orçamento!");
        } else if (props.user.cpf !== props.orcamentoUnico.cpf_cnpj_contratante) {
            toast.error("Apenas o proprietário desta proposta pode aprovar este orçamento!");
        } else {
            const d1 = new Date().toLocaleDateString("en-US");
            const d2 = new Date(props.orcamentoUnico.validade_orcamento).toLocaleDateString("en-US");

            const diffInMs = new Date(d2) - new Date(d1);
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

            if (diffInDays >= 0) {
                props.setOpenModal({ status: true, tipo: "aprovar-orcamento", validade: props.orcamentoUnico.validade_orcamento, token: props.orcamentoUnico.token, metodo_pagamento: paymentMethod })
            } else {
                toast.error("Infelizmente esta proposta está vencida e não pode ser mais aprovada.\nPor favor, solicite uma nova proposta!");
            }
        }
    }

    String.prototype.extenso = function (c) { // Função para converter número no formato string para número por extenso.
        var ex = [
            ["zero", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"],
            ["dez", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"],
            ["cem", "cento", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"],
            ["mil", "milhão", "bilhão", "trilhão", "quadrilhão", "quintilhão", "sextilhão", "setilhão", "octilhão", "nonilhão", "decilhão", "undecilhão", "dodecilhão", "tredecilhão", "quatrodecilhão", "quindecilhão", "sedecilhão", "septendecilhão", "octencilhão", "nonencilhão"]
        ];
        var a, n, v, i, n = this.replace(c ? /[^,\d]/g : /\D/g, "").split(","), e = " e ", $ = "real", d = "centavo", sl;
        for (var f = n.length - 1, l, j = -1, r = [], s = [], t = ""; ++j <= f; s = []) {
            j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2));
            if (!(a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g), v = l % 3 ? [v.slice(0, l % 3)] : [], v = a ? v.concat(a) : v).length) continue;
            for (a = -1, l = v.length; ++a < l; t = "") {
                if (!(i = v[a] * 1)) continue;
                i % 100 < 20 && (t += ex[0][i % 100]) ||
                    i % 100 + 1 && (t += ex[1][(i % 100 / 10 >> 0) - 1] + (i % 10 ? e + ex[0][i % 10] : ""));
                s.push((i < 100 ? t : !(i % 100) ? ex[2][i == 100 ? 0 : i / 100 >> 0] : (ex[2][i / 100 >> 0] + e + t)) +
                    ((t = l - a - 2) > -1 ? " " + (i > 1 && t > 0 ? ex[3][t].replace("ão", "ões") : ex[3][t]) : ""));
            }
            a = ((sl = s.length) > 1 ? (a = s.pop(), s.join(" ") + e + a) : s.join("") || ((!j && (n[j + 1] * 1 > 0) || r.length) ? "" : ex[0][0]));
            a && r.push(a + (c ? (" " + (v.join("") * 1 > 1 ? j ? d + "s" : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is") : j ? d : $)) : ""));
        }
        return r.join(e);
    }
    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title={`Engeart • Orçamento: ${props.orcamentoUnico.token}`} />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        {
                            (() => {
                                if (!props.orcamentoUnico) {
                                    return (
                                        <div className='d-fx d-al-cen'>
                                            <div className='w65 p-g-25'>
                                                <h2 className='m-bot-20'>Ooops!</h2>
                                                <div style={{ lineHeight: 1.5 }}>
                                                    <p className='fz-16'>Não foi possivel localizarmos o contrato informado :(</p>
                                                    <p className='fz-16'>Parece que o contrato que você está buscando está sendo desenvolvido ou o código localizador passado está errado!</p>
                                                </div>

                                                <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                                                    <Link to="/minha-conta/meus-contratos" className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado">
                                                        <span className=' fz-12'>Meus Contratos</span>
                                                    </Link>

                                                    <Link to="/" className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }}>
                                                        <span className=' fz-12'>Página Inícial</span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='w35'>
                                                <img src={searchFolder} />
                                            </div>
                                        </div>
                                    )
                                } else if (isEmptyObject(props.orcamentoUnico)) {
                                    return (
                                        <div className='d-fx d-al-cen'>
                                            <div className='w65 p-g-25'>
                                                <h2 className='m-bot-20'>Ooops!</h2>
                                                <div style={{ lineHeight: 1.5 }}>
                                                    <p className='fz-16'>Não foi possivel recuperarmos as informações do contrato informado :(</p>
                                                    <p className='fz-16'>Parece que o contrato que você está buscando está sendo desenvolvido ou o código localizador passado está errado!</p>
                                                </div>

                                                <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                                                    <Link to="/minha-conta/meus-contratos" className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado">
                                                        <span className=' fz-12'>Meus Contratos</span>
                                                    </Link>

                                                    <Link to="/" className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }}>
                                                        <span className=' fz-12'>Página Inícial</span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='w35'>
                                                <img src={searchDocument} />
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='content-account p-g-25 p-bot-0'>
                                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Orçamento: {props.orcamentoUnico.token}</h1>
                                            <div className='box-forms-singup'>
                                                <div className='d-fx d-al-cen'>
                                                    <div className={(props.responsivoSize && props.responsivoSize > 481) ? 'w65 p-g-25' : 'w100'}>
                                                        <div className='wrapper__card__info__budget'>
                                                            {
                                                                (() => {
                                                                    if (slideCard === 0) { // Capa
                                                                        return (
                                                                            <div className='card__info__budget__single'>
                                                                                <div className='d-fx d-fd-col d-ju-cen d-al-cen'>
                                                                                    <h2 className=''>Proposta de Orçamento</h2>
                                                                                    <div class="description-page__btn-toggle w45"><div class="line-wrapper w100" style={{ cursor: 'text' }}><span className='fz-14'>de</span></div></div>
                                                                                    <h2 className=''>
                                                                                        {
                                                                                            props.orcamentoUnico.categoria_servico
                                                                                        }
                                                                                    </h2>

                                                                                </div>
                                                                                <div className='b-top' style={{ lineHeight: 1.5 }}>
                                                                                    <p className='fz-14'>
                                                                                        <strong>
                                                                                            Cliente:
                                                                                        </strong>
                                                                                        <span className='m-lef-5'>
                                                                                            {
                                                                                                props.orcamentoUnico.contratante
                                                                                            }
                                                                                        </span>
                                                                                    </p>
                                                                                    <p className='fz-14'>
                                                                                        <strong>
                                                                                            Serviço:
                                                                                        </strong>
                                                                                        <span className='m-lef-5'>
                                                                                            {
                                                                                                props.orcamentoUnico.titulo_servico
                                                                                            }
                                                                                        </span>
                                                                                    </p>
                                                                                    {
                                                                                        (props.orcamentoUnico.expedicao && !isEmptyObject(props.orcamentoUnico.expedicao))
                                                                                            ?
                                                                                            <p className='fz-14'>
                                                                                                <strong>
                                                                                                    Data da Proposta:
                                                                                                </strong>
                                                                                                <span className='m-lef-5'>
                                                                                                    {
                                                                                                        props.orcamentoUnico.expedicao.toDate().toLocaleDateString("pt-BR")
                                                                                                    }
                                                                                                </span>
                                                                                            </p>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        (props.orcamentoUnico.expedicao && !isEmptyObject(props.orcamentoUnico.expedicao))
                                                                                            ?
                                                                                            <p className='fz-14'>
                                                                                                <strong>
                                                                                                    Vencimento da Proposta:
                                                                                                </strong>
                                                                                                <span className='m-lef-5'>
                                                                                                    {
                                                                                                        new Date(props.orcamentoUnico.validade_orcamento).toLocaleDateString("pt-BR")
                                                                                                    }
                                                                                                    <span className='m-lef-5'>
                                                                                                        (
                                                                                                        {
                                                                                                            (() => {
                                                                                                                const d1 = new Date().toLocaleDateString("en-US");
                                                                                                                const d2 = new Date(props.orcamentoUnico.validade_orcamento).toLocaleDateString("en-US");

                                                                                                                const diffInMs = new Date(d2) - new Date(d1);
                                                                                                                const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                                                                                                                if (diffInDays > 0) {
                                                                                                                    return (`${diffInDays} dias para o vencimento`)
                                                                                                                } else if (diffInDays === 0) {
                                                                                                                    return (`Hoje é o último dia para aprovar a proposta`)
                                                                                                                } else {
                                                                                                                    return (`Esta proposta está vencida à ${Math.abs(diffInDays)} dias`)
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        )
                                                                                                    </span>
                                                                                                </span>
                                                                                            </p>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 1) { // Agradecimento
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <span className='m-lef-5'>
                                                                                        {
                                                                                            props.orcamentoUnico.expedicao.toDate().toLocaleDateString("pt-BR")
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-14 m-bot-15'>
                                                                                    <strong>
                                                                                        {
                                                                                            props.orcamentoUnico.contratante
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Obrigado por nos dar a oportunidade de enviar-lhe esta proposta de orçamento de obra e pela chance de criarmos uma futura parceria. Anexamos em sua proposta todos os detalhes da sua {props.orcamentoUnico.titulo_servico} para você revisar e realizar o gerenciamento na construção do projeto dos seus sonhos.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Somos líderes no ramo de construção e gerenciamento de projetos na região, com vários anos de experiência no mercado. Entendemos as necessidades e preocupações dos nossos clientes, e seguimos rigorosamente os padrões de qualidade. Entregamos nossos projetos no prazo e dentro do orçamento, graças ao nosso alto nível de gerenciamento e técnicas.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Parte de nossas medidas de qualidade é proporcionar satisfação ao cliente, adicionando valor em seu negócio e em seus interesses. Com isso, nos esperamos construir uma forte parceria com você.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45'>
                                                                                    <span className=''>
                                                                                        Por favor, fique a vontade para revisar a proposta anexa e não hesite em solicitar os ajustes que você precisar. Estou ansioso para te conhecer e discutir mais sobre a nossa proposta na data mais conveniente para você.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Atenciosamente,
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13'>
                                                                                    <span className=''>
                                                                                        {
                                                                                            props.orcamentoUnico.representante_contratado
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13'>
                                                                                    <span className=''>
                                                                                        {
                                                                                            props.orcamentoUnico.cargo_representante
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13'>
                                                                                    <span className=''>
                                                                                        {
                                                                                            props.orcamentoUnico.contratado
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 2) { // Quem nós somos
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <strong>
                                                                                        Quem nós somos
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Inicialmente construindo casas, começamos e trabalhar com projetos maiores de várias categorias. A nossa equipe é composta por profissionais altamente capacitados e focados em entregar qualidade e valor.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Entendemos as necessidades dos nossos clientes e damos-lhes a garantia de entregar os projetos no prazo, no orçamento e com padrões de qualidade a alto nível. Nossos projetistas trabalham em comunicação com os nossos clientes e com isso os desejos do cliente são sempre ouvidos em grandes decisões do projeto. Asseguramos ao cliente, como parte de nossa responsabilidade para manter a integridade do projeto, a transparência nos custos da obra, controle de orçamento e da agenda do projeto.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Acreditamos que a nossa abordagem de negócio conectada a qualidade de serviço é o motivo do nosso contínuo sucesso. Focamos em fortalecer as relações, pois valorizamos os nossos clientes, profissionais e parceiros de negócios, pois consideramos eles como uma vital parte do nosso sucesso.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45'>
                                                                                    <span className=''>
                                                                                        Hoje, somos uma empesa familiar de construções e projetos e temos orgulho do nosso trabalho, por isso, relaxe enquanto assiste a seus sonhos serem construídos por nós.
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 3) { // Aquisição das licenças e permissões
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <strong>
                                                                                        Aquisição das licenças e permissões
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45'>
                                                                                    <span className=''>
                                                                                        O cliente deverá providenciar a documentação necessária para auxiliarmos na aquisição das autorizações e licenças necessárias para a implementação do projeto e início da obra. O cliente assume toda responsabilidade legal, caso deseje realizar por conta própria a requisição das licenças e autorizações.
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 4) { // O Projeto
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <strong>
                                                                                        O Projeto
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        O projeto envolve a {props.orcamentoUnico.descricao_servico} localizado na {`${props.orcamentoUnico.endereco_servico.tipo_logradouro} ${props.orcamentoUnico.endereco_servico.logradouro}, ${props.orcamentoUnico.endereco_servico.numero}, ${props.orcamentoUnico.endereco_servico.bairro} - ${props.orcamentoUnico.endereco_servico.cidade}, - ${props.orcamentoUnico.endereco_servico.estado}, CEP ${(() => {
                                                                                            var new_cep = props.orcamentoUnico.endereco_servico.cep.toString();
                                                                                            return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                                                        })()}`}, com área aproximada de {props.orcamentoUnico.area_servico} metros quadrados.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        A estrutura proposta do edifício terá a altura de 4 metros e terá 1 unidades de espaço comercial disponíveis para aluguel de lojas comerciais e outros serviços estabelecidos no térreo. A proposta inicial de construção será de 3 meses sendo esperado para ser finalizado em {new Date(props.orcamentoUnico.validade_orcamento).toLocaleDateString("pt-BR")}.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        O serviço seguirá as seguintes etapas descitas a seguir:
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45'>
                                                                                    <ol style={{ marginLeft: '20px' }}>
                                                                                        {
                                                                                            props.orcamentoUnico.etapas_servico.map((etapa, index) => {
                                                                                                return (<li key={index}>{etapa.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</li>)
                                                                                            })
                                                                                        }
                                                                                    </ol>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 5) { // Escopo/requisitos
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <strong>
                                                                                        Escopo
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Conforme os requisitos estabelecido, o projeto deve ser realizando com a inclusão de:
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45'>
                                                                                    <ol style={{ marginLeft: '20px' }}>
                                                                                        {
                                                                                            props.orcamentoUnico.requisitos_servico.map((etapa, index) => {
                                                                                                return (<li key={index}>{etapa.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</li>)
                                                                                            })
                                                                                        }
                                                                                    </ol>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 6) { // Logística
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <strong>
                                                                                        Logística
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        A construção de edifício pode ser um grande empreendimento, por isso, tomamos as medidas essências para gerenciar o projeto e diminuir a carga de nossos clientes. Entretanto, é importante entender como o serviço pode afetar você e a sua propriedade.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Exceto se outro acordo tenha sido convencionado, nos assumimos a responsabilidade do excesso de materiais utilizados no local da construção depois do projeto, garantindo que a propriedade fique limpa e sem materiais de construção nas mediações do terreno após a finalização do projeto.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Para a aquisição de materiais extras, nós devemos garantir todo o esforço para que os novos materiais sejam similares com os já existentes. Entretanto, deverá ser entendido que algumas vezes, haverá exceções em que não será possível comprar materiais similares.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Banheiros sanitariamente seguros e outras instalações essenciais deverão ser providenciados pelo cliente para a equipe de construção durante todo o período do projeto.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Deverá ser de responsabilidade do cliente assegurar a segurança da propriedade. Não será de nossa responsabilidade os danos de bens pessoais deixados na área de construção ou o bem-estar de animais trazidos para a área de construção.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45'>
                                                                                    <span className=''>
                                                                                        Teremos o total acesso à energia elétrica para realizar o trabalho, enquanto o acesso ao painel de serviço e os custos de energia elétrica serão de responsabilidade do cliente.
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 7) { // Termos e valores da obra acordados
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <strong>
                                                                                        Termos e valores da obra acordados
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        O valor total de execução do contrato projeto é de
                                                                                        <strong className='m-lef-5'>{
                                                                                            (() => {
                                                                                                var contador = 0;
                                                                                                props.orcamentoUnico.etapas_pagamento.map((etapa) => {
                                                                                                    contador += parseInt(etapa.valor_pag);
                                                                                                })
                                                                                                return (contador.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) + " (" + (contador.toString()).extenso(true) + ")")
                                                                                            })()
                                                                                        }</strong>. Um caução deverá ser realizado antes do início do projeto da obra. A referida quantia será depositada em sua conta a ser fornecida no ato da aceitação da proposta.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        O pagamento parcelado deverá ser pago realizado conforme a tabela abaixo:
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45'>
                                                                                    <table className='table__installment__budget'>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ textAlign: 'center' }}>ID</th>
                                                                                                <th>Descrição</th>
                                                                                                <th>Data do pagamento</th>
                                                                                                <th>Valor</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                props.orcamentoUnico.etapas_pagamento.map((etapa, index) => {
                                                                                                    return (
                                                                                                        <tr>
                                                                                                            <td style={{ fontWeight: 'bold', textAlign: 'center', width: '7%' }}>{index + 1}</td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    etapa.descricao_pag
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    new Date(etapa.data_pag).toLocaleDateString("pt-BR")
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    parseInt(etapa.valor_pag).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    );

                                                                                                })
                                                                                            }
                                                                                            <tr>
                                                                                                <td style={{ fontWeight: 'bold', textAlign: 'center' }}>#</td>
                                                                                                <td>
                                                                                                    <strong>Total Geral</strong>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        `${new Date(props.orcamentoUnico.etapas_pagamento[0].data_pag).toLocaleDateString("pt-BR")} - ${new Date(props.orcamentoUnico.etapas_pagamento.at(-1).data_pag).toLocaleDateString("pt-BR")}`
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        (() => {
                                                                                                            var valorTotal = 0;
                                                                                                            props.orcamentoUnico.etapas_pagamento.map((val) => {
                                                                                                                valorTotal += parseInt(val.valor_pag);
                                                                                                            })
                                                                                                            return (<strong>{`${valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}</strong>);
                                                                                                        })()
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 8) { // Garantia limitada
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <strong>
                                                                                        Garantia limitada
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        Esse serviço possui trabalhos limitados, onde não inclui os seguintes trabalhos, serviços e materiais:
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45 m-lef-15'>
                                                                                    <ul style={{ marginLeft: '20px' }}>
                                                                                        {
                                                                                            props.orcamentoUnico.etapas_sem_garantia.map((etapa, index) => {
                                                                                                return (<li key={index}>{etapa}</li>)
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 9) { // Acordo
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <strong>
                                                                                        Acordo
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45'>
                                                                                    <span className=''>
                                                                                        Todas as informações aqui passadas serão repassadas para o contrato, é da responsabilidade do cliente ler e conferir todas as informações especificadas no contrato, caso haja alguma incompátibilidade poderá o cliente sentir-se a vontade para conversar com um de nossos atendentes e solicitar uma possivel reavaliação contratual.
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    } else if (slideCard === 10) { // Forma de Pagamento
                                                                        return (
                                                                            <div className='card__info__budget__single' style={{ lineHeight: 1.5 }}>
                                                                                <p className='fz-14 m-bot-25'>
                                                                                    <strong>
                                                                                        Forma de Pagamento
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-15'>
                                                                                    <span className=''>
                                                                                        O pagamento só será realizado após a assinatura do contrato, pedimos essa informação da forma de pagamento para especificação no contrato que será gerado.
                                                                                    </span>
                                                                                </p>
                                                                                <p className='fz-13'>
                                                                                    <strong className=''>
                                                                                        Selecione a forma de pagamento
                                                                                    </strong>
                                                                                </p>
                                                                                <p className='fz-13 m-bot-45'>
                                                                                    {/* MEIOS DE PAGAMENTO */}
                                                                                    <div className='dados__content__principal w100'>
                                                                                        <div className='tipo__pagamento'>
                                                                                            <div className="input-button m-g-5" onClick={() => { setPaymentMethod('credito') }}>
                                                                                                <input type="radio" name="payment__type" value="credito" id="pay_CreditCard" checked={(paymentMethod && paymentMethod == 'credito') ? 'checked' : null} />
                                                                                                <label className="label d-al-cen" for="pay_CreditCard" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex' } : { display: 'flex', flexWrap: 'wrap' }}>
                                                                                                    <BsCreditCard2Front className='fz-18 m-rig-5 m-lef-5' />
                                                                                                    <span>Cartão de Crédito</span>
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="input-button m-g-5" onClick={() => { setPaymentMethod('debito') }}>
                                                                                                <input type="radio" name="payment__type" value="debito" id="pay_debito" checked={(paymentMethod && paymentMethod == 'debito') ? 'checked' : null} />
                                                                                                <label className="label d-al-cen" for="pay_debito" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex' } : { display: 'flex', flexWrap: 'wrap' }}>
                                                                                                    <BsCreditCard2Front className='fz-18 m-rig-5 m-lef-5' />
                                                                                                    <span>Cartão de Débito</span>
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="input-button m-g-5" onClick={() => { setPaymentMethod('boleto') }}>
                                                                                                <input type="radio" name="payment__type" value="boleto" id="pay_Boleto" checked={(paymentMethod && paymentMethod == 'boleto') ? 'checked' : null} />
                                                                                                <label className="label d-al-cen" for="pay_Boleto" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex' } : { display: 'flex', flexWrap: 'wrap' }}>
                                                                                                    <FaBarcode className='fz-18 m-rig-5 m-lef-5' />
                                                                                                    <span>Boleto</span>
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="input-button m-g-5" onClick={() => { setPaymentMethod('carne') }}>
                                                                                                <input type="radio" name="payment__type" value="carne" id="pay_carne" checked={(paymentMethod && paymentMethod == 'carne') ? 'checked' : null} />
                                                                                                <label className="label d-al-cen" for="pay_carne" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex' } : { display: 'flex', flexWrap: 'wrap' }}>
                                                                                                    <GiNotebook className='fz-18 m-rig-5 m-lef-5' />
                                                                                                    <span>Carnê</span>
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="input-button m-g-5" onClick={() => { setPaymentMethod('pix') }}>
                                                                                                <input type="radio" name="payment__type" value="pix" id="pay_Pix" checked={(paymentMethod && paymentMethod == 'pix') ? 'checked' : null} />
                                                                                                <label className="label d-al-cen" for="pay_Pix" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex' } : { display: 'flex', flexWrap: 'wrap' }}>
                                                                                                    <ImQrcode className='fz-18 m-rig-5 m-lef-5' />
                                                                                                    <span>PIX</span>
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="input-button m-g-5" onClick={() => { setPaymentMethod('dinheiro') }}>
                                                                                                <input type="radio" name="payment__type" value="dinheiro" id="pay_dinheiro" checked={(paymentMethod && paymentMethod == 'dinheiro') ? 'checked' : null} />
                                                                                                <label className="label d-al-cen" for="pay_dinheiro" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex' } : { display: 'flex', flexWrap: 'wrap' }}>
                                                                                                    <BsCashCoin className='fz-18 m-rig-5 m-lef-5' />
                                                                                                    <span>Dinheiro</span>
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="input-button m-g-5" onClick={(e) => toast.error("Mais meios de pagamento, em breve...")}>
                                                                                                <label className="label d-al-cen" style={(props.responsivoSize && props.responsivoSize > 200) ? { display: 'flex', padding: '.68rem .81rem .68rem .81rem' } : { display: 'flex', padding: '.68rem .81rem .68rem .81rem', flexWrap: 'wrap' }}>
                                                                                                    <RiHomeGearLine className='fz-18 m-rig-5 m-lef-5' />
                                                                                                    <span>Outros meios de pagamento (Em Breve...)</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })()
                                                            }
                                                        </div>

                                                        <div className='d-fx d-al-cen d-ju-sp-ev m-top-20'>
                                                            {
                                                                (slideCard > 0)
                                                                    ?
                                                                    <button className="d-fx d-ju-cen m-top-5 m-bot-5 d-al-cen personalizado" onClick={() => setSlideCard(slideCard - 1)}>
                                                                        <span className=' fz-12'>Anterior</span>
                                                                    </button>
                                                                    : null
                                                            }
                                                            {
                                                                (slideCard < 10)
                                                                    ?
                                                                    <button className="cart" style={{ marginTop: 0, textTransform: 'uppercase' }} onClick={() => setSlideCard(slideCard + 1)}>
                                                                        <span className=' fz-12'>Próximo</span>
                                                                    </button>
                                                                    :
                                                                    <button class="button__payment" onClick={(e) => aprovarOrcamento(e)}>
                                                                        <span class="button__payment__text">
                                                                            <span className='fz-12' style={{ textTransform: 'uppercase', verticalAlign: 'middle' }}>Aprovar Orçamento</span>
                                                                        </span>
                                                                        <svg class="button__payment__svg" role="presentational" viewBox="0 0 600 600">
                                                                            <defs>
                                                                                <clipPath id="myClip">
                                                                                    <rect x="0" y="0" width="100%" height="50%" />
                                                                                </clipPath>
                                                                            </defs>
                                                                            <g clip-path="url(#myClip)">
                                                                                <g id="money">
                                                                                    <path d="M441.9,116.54h-162c-4.66,0-8.49,4.34-8.62,9.83l.85,278.17,178.37,2V126.37C450.38,120.89,446.56,116.52,441.9,116.54Z" fill="#699e64" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                                                                                    <path d="M424.73,165.49c-10-2.53-17.38-12-17.68-24H316.44c-.09,11.58-7,21.53-16.62,23.94-3.24.92-5.54,4.29-5.62,8.21V376.54H430.1V173.71C430.15,169.83,427.93,166.43,424.73,165.49Z" fill="#699e64" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                                                                                </g>
                                                                                <g id="creditcard">
                                                                                    <path d="M372.12,181.59H210.9c-4.64,0-8.45,4.34-8.58,9.83l.85,278.17,177.49,2V191.42C380.55,185.94,376.75,181.57,372.12,181.59Z" fill="#a76fe2" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                                                                                    <path d="M347.55,261.85H332.22c-3.73,0-6.76-3.58-6.76-8v-35.2c0-4.42,3-8,6.76-8h15.33c3.73,0,6.76,3.58,6.76,8v35.2C354.31,258.27,351.28,261.85,347.55,261.85Z" fill="#ffdc67" />
                                                                                    <path d="M249.73,183.76h28.85v274.8H249.73Z" fill="#323c44" />
                                                                                </g>
                                                                            </g>
                                                                            <g id="wallet">
                                                                                <path d="M478,288.23h-337A28.93,28.93,0,0,0,112,317.14V546.2a29,29,0,0,0,28.94,28.95H478a29,29,0,0,0,28.95-28.94h0v-229A29,29,0,0,0,478,288.23Z" fill="#a4bdc1" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                                                                                <path d="M512.83,382.71H416.71a28.93,28.93,0,0,0-28.95,28.94h0V467.8a29,29,0,0,0,28.95,28.95h96.12a19.31,19.31,0,0,0,19.3-19.3V402a19.3,19.3,0,0,0-19.3-19.3Z" fill="#a4bdc1" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                                                                                <path d="M451.46,435.79v7.88a14.48,14.48,0,1,1-29,0v-7.9a14.48,14.48,0,0,1,29,0Z" fill="#a4bdc1" stroke="#323c44" stroke-miterlimit="10" stroke-width="14" />
                                                                                <path d="M147.87,541.93V320.84c-.05-13.2,8.25-21.51,21.62-24.27a42.71,42.71,0,0,1,7.14-1.32l-29.36-.63a67.77,67.77,0,0,0-9.13.45c-13.37,2.75-20.32,12.57-20.27,25.77l.38,221.24c-1.57,15.44,8.15,27.08,25.34,26.1l33-.19c-15.9,0-28.78-10.58-28.76-25.93Z" fill="#7b8f91" />
                                                                                <path d="M148.16,343.22a6,6,0,0,0-6,6v92a6,6,0,0,0,12,0v-92A6,6,0,0,0,148.16,343.22Z" fill="#323c44" />
                                                                            </g>
                                                                        </svg>
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        (props.responsivoSize && props.responsivoSize > 481)
                                                            ?
                                                            <div className='w35'>
                                                                <img src={contractOkay} />
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })()
                        }
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div >
    );

}

export default MyBudgetSingle;