import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri';
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { useMemo, useState } from 'react';
import { db, auth } from '../../../../firebase';
import toast from 'react-hot-toast';

function Reset(props) {
    let navigate = useNavigate();

    // RECUEPERAÇÃO DE PARAMETROS DA QUERY
    let query = useQuery();
    const token = query.get("oobCode"); // VALOR REAL DA BUSCA DIGITADA(cliente)

    // HOOKS - SENHA
    const [password, setPassword] = useState();
    const [passLength, setPassLength] = useState(false);
    const [passLetter, setPassLetter] = useState(false);
    const [passNumber, setPassNumber] = useState(false);
    const [passSequence, setPassSequence] = useState(false);
    const [passCharacters, setPassCharacters] = useState(false);
    const [passValid, setPassValid] = useState(false);
    const [passStateView, setPassStateView] = useState(false);

    // HOOKS - CONFIRMAÇÃO DE SENHA
    const [confirmPass, setConfirmPass] = useState('');
    const [confirmPassValid, setConfirmPassValid] = useState(false);
    const [passConfirmStateView, setPassConfirmStateView] = useState(false);

    // FUNÇÃO DE QUERY PARA ALTERAÇÃO DE SENHA
    function useQuery() {
        const { search } = useLocation();
    
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    useMemo(() => {
        if (password) { // VERIFICAÇÃO DE TAMANHO DE SENHA
            if (password.length >= 8 && password.length <= 20) {
                setPassLength(true);
            } else if (password.length < 8 || password.length > 20) {
                setPassLength(false);
            }
        }

        // VERIFICAÇÃO DE LETRAS EM SENHA
        var padrao = /[A-Za-z]/gi;

        if (password && password.match(padrao)) {
            setPassLetter(true);
        } else {
            setPassLetter(false);
        }

        // VERIFICAÇÃO DE NUMEROS EM SENHA
        var padrao = /[0-9]/;

        if (password && password.match(padrao)) {
            setPassNumber(true);
        } else {
            setPassNumber(false);
        }

        // VERIFICAÇÃO DE SEQUENCIA EM SENHA
        var padrao = '^(?=.*\d)(?=.*[a-zA-Z])(?!.*[\W_\x7B-\xFF]).{4,15}$';
        // var padrao = '"^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,}$"';

        if (password && password.toLowerCase().match(padrao)) {
            setPassSequence(true);
        } else {
            setPassSequence(false);
        }

        // VERIFICAÇÃO DE CARACTERES EM SENHA
        var padrao = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

        if (password && password.match(padrao)) {
            setPassCharacters(true);
        } else {
            setPassCharacters(false);
        }

        validateConfirmPassword()
    }, [password]);

    function validatePassword() {
        if (passLength && passLetter && passNumber && passSequence) {
            if (passCharacters) { // A SENHA POSSUI CARACTERES ENTÃO É UMA SENHA VÁLIDA
                setPassValid("strong");
            } else {
                setPassValid("default");
            }
        } else {
            setPassValid("");
        }
    }

    function alterarVisualizacaoSenha(el) {
        el.preventDefault();

        setPassStateView((prevState) => !prevState);
    }

    // FUNÇÕES PARA CONFIRMAÇÃO DE SENHA
    useMemo(() => {
        validateConfirmPassword();
    }, [confirmPass])

    function validateConfirmPassword() {
        if (password === confirmPass) {
            setConfirmPassValid(true);
        } else {
            setConfirmPassValid(false);
        }
    }

    function alterarVisualizacaoConfirmacaoSenha(el) {
        el.preventDefault();

        setPassConfirmStateView((prevState) => !prevState);
    }

    // VALIDAR INFORMAÇÕES DO USUÁRIO E RECUPERAR SENHA
    async function resetPassword(e) {
        e.preventDefault();

        if (!password.length) {
            toast.error("A senha não pode ser vázia");
        } else if (!passValid) {
            toast.error("A senha não cumpre com os parâmetros de segurança");
        } else if (!confirmPass.length) {
            toast.error("A confirmação de senha não pode ser vázia");
        } else if (!confirmPassValid) {
            toast.error("A senhas não são compatíveis");
        } else {
            // VERIFICAR SE RECUPEROU O TOKEN DE SEGURANÇA DO USUÁRIO
            if (token && token.length) { // O CÓDIGO FOI CONFIRMADO E É VÁLIDO
                auth.confirmPasswordReset(token, password)
                    .then(()=>{
                        toast.success("Senha alterada com sucesso!");
                        navigate("/login");
                    })
                    .catch((error) => {
                        toast.error("Ocorreu um erro ao alterar sua senha\nTente novamente mais tarde");
                        if (error.code && error.code == "auth/expired-action-code") {
                            toast.error("O código de redefinição de senha expirou")
                        } else if (error.code && error.code == "auth/invalid-action-code") {
                            toast.error("O código de redefinição de senha é inválido")
                        } else if (error.code && error.code == "auth/user-disabled") {
                            toast.error("O usuário fornecido está desabilitado")
                        } else if (error.code && error.code == "auth/user-not-found") {
                            toast.error("O usuário não foi encontrado")
                        } else if (error.code && error.code == "auth/weak-password") {
                            toast.error("A nova senha não é forte o suficiente")
                        }
                    })
            } else { // NÃO FOI POSSIVEL RECUPERAR O CÓDIGO
                toast.error("Não foi possível recuperar o token de segurança\nTente novamente mais tarde");
            }
        }
    }
    return (
        <>
            <div className='center'>
                <div className="grid second-nav">
                    <div className="column-xs-12">
                        <nav>
                            {
                                (props.responsivoSize && props.responsivoSize >= 600)
                                    ?
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Início</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/login">Entrar</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Alterar a senha</li>
                                    </ol>
                                    :
                                    <ol className="breadcrumb-list">
                                        <li className="breadcrumb-item">
                                            <Link to="/login" className='d-fx d-al-cen'>
                                                <MdOutlineArrowBackIos className='m-rig-5' />
                                                Entrar
                                            </Link>
                                        </li>
                                    </ol>
                            }
                        </nav>
                    </div>
                    <form id='form_forgot_password' className='w55' style={{ margin: '0 auto 20px' }} onSubmit={(e) => resetPassword(e)}>

                        <h1 className="fz-25 info-primary">Alterando sua senha</h1>

                        <p className="m-top-10 tx-grey-dark fz-16">Por favor, redefina sua senha abaixo.</p>

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Senha</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <div className='p-rlt'>
                                <input id='senha-cadastro-singup' className='input-form w100 fz-16 m-top-5' type={(passStateView) ? 'text' : 'password'} name="nome" placeholder='Insira sua senha' autoComplete='off' value={password} onChange={(ev) => setPassword(ev.target.value)} onBlur={() => validatePassword()} />
                                <button className='btn__toggle__state__password p-abs' onClick={(el) => alterarVisualizacaoSenha(el)}>
                                    {
                                        (passStateView)
                                            ?
                                            <RiEyeFill />
                                            :
                                            <RiEyeCloseLine />
                                    }
                                </button>
                            </div>
                            {
                                (password)
                                    ?
                                    <div>
                                        {
                                            (passValid)
                                                ?
                                                <div className='info-valid m-top-10 d-bk'>
                                                    {
                                                        (() => {
                                                            if (passValid == 'default') {
                                                                return (
                                                                    <div className='d-fx d-al-cen'>
                                                                        <span className='icon-invalid'><TiInfo /></span>
                                                                        <span className='text-invalid m-lef-5'>A senha atende aos requisitos mínimos, mas pode ser mais seguro usar caracteres especiais (Ex.: @, -, _)</span>
                                                                    </div>
                                                                )
                                                            } else if (passValid == 'strong') {
                                                                return (
                                                                    <div className='d-fx d-al-cen'>
                                                                        <span className='icon-valid'><AiOutlineCheck /></span>
                                                                        <span className='text-valid m-lef-5'>A senha é forte</span>
                                                                    </div>
                                                                )
                                                            }
                                                        })()
                                                    }
                                                </div>
                                                :
                                                <div className='info-invalid m-top-10 d-bk'>
                                                    <div className='d-fx d-al-cen'>
                                                        <span className='icon-invalid'><TiInfo /></span>
                                                        <span className='text-invalid m-lef-5'>A senha não atende aos requisitos mínimos abaixo</span>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    : null
                            }
                            <div className='box-requisitos w100 m-top-5 p-g-15'>
                                <h3 className='fz-12'>Sua senha deve ter:</h3>
                                <ul className='m-top-10'>
                                    <li className={(passLength) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12 invalid'}>
                                        <span className='icon-req-pass'>
                                            {
                                                (passLength)
                                                    ?
                                                    <AiOutlineCheck />
                                                    :
                                                    <AiOutlineClose />
                                            }
                                        </span>
                                        <span className='text-req-pass m-lef-5'>De 8 a 20 caracteres*</span>
                                    </li>
                                    <li className={(passLetter) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12 invalid'}>
                                        <span className='icon-req-pass'>
                                            {
                                                (passLetter)
                                                    ?
                                                    <AiOutlineCheck />
                                                    :
                                                    <AiOutlineClose />
                                            }
                                        </span>
                                        <span className='text-req-pass m-lef-5'>Ao menos uma letra*</span>
                                    </li>
                                    <li className={(passNumber) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12 invalid'}>
                                        <span className='icon-req-pass'>
                                            {
                                                (passNumber)
                                                    ?
                                                    <AiOutlineCheck />
                                                    :
                                                    <AiOutlineClose />
                                            }
                                        </span>
                                        <span className='text-req-pass m-lef-5'>Ao menos um número*</span>
                                    </li>
                                    <li className={(passSequence) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12 invalid'}>
                                        <span className='icon-req-pass'>
                                            {
                                                (passSequence)
                                                    ?
                                                    <AiOutlineCheck />
                                                    :
                                                    <AiOutlineClose />
                                            }
                                        </span>
                                        <span className='text-req-pass m-lef-5'>Caracteres fora da sequência*</span>
                                    </li>
                                    <li className={(passCharacters) ? 'request-single-pass m-top-5 fz-12 valid' : 'request-single-pass m-top-5 fz-12'}>
                                        <span className='icon-req-pass'>
                                            {
                                                (passCharacters)
                                                    ?
                                                    <AiOutlineCheck />
                                                    :
                                                    <AiOutlinePlus />
                                            }
                                        </span>
                                        <span className='text-req-pass m-lef-5'>Caracteres especiais (Ex.: @, -, _)</span>
                                    </li>
                                </ul>
                            </div>
                        </div>{/*box-input-single */}


                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Senha</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <div className='p-rlt'>
                                <input id='alterar-senha-confirmacao' className='input-form w100 fz-16 m-top-5' type={(passConfirmStateView) ? 'text' : 'password'} name="nome" placeholder='Insira sua senha' autoComplete='off' value={confirmPass} onChange={(ev) => setConfirmPass(ev.target.value)} onBlur={() => validateConfirmPassword()} />
                                <button className='btn__toggle__state__password p-abs' onClick={(el) => alterarVisualizacaoConfirmacaoSenha(el)}>
                                    {
                                        (passConfirmStateView)
                                            ?
                                            <RiEyeFill />
                                            :
                                            <RiEyeCloseLine />
                                    }
                                </button>
                            </div>
                            {
                                (confirmPassValid)
                                    ?
                                    <div className='info-valid m-top-10 d-bk'>
                                        <div className='d-fx d-al-cen'>
                                            <span className='icon-invalid'><TiInfo /></span>
                                            <span className='text-invalid m-lef-5'>Senhas compatíveis</span>
                                        </div>
                                    </div>
                                    :
                                    <div className='info-invalid m-top-10 d-bk'>
                                        <div className='d-fx d-al-cen'>
                                            <span className='icon-invalid'><TiInfo /></span>
                                            <span className='text-invalid m-lef-5'>Senha não confere. Confirme que a senha digitada acima está correta</span>
                                        </div>
                                    </div>
                            }
                        </div>{/*box-input-single */}

                        <button class="button button-full button-primary tx-lef m-top-15">
                            Entrar
                            <div class="right">
                                {/* <FontAwesomeIcon className="glyph" icon={faAngleDoubleRight} /> */}
                            </div>
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Reset;