
import { AiOutlineClose } from 'react-icons/ai';
import { fecharModal } from '../funcoes/FuncoesGerais';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useState } from 'react';
import logoIcon from '../../../../../images/icones/jpg/logo_icone.jpg';
import { db, fs } from '../../../../../firebase';
import toast from 'react-hot-toast';


function ModalVisualizarDepoimento(props) {
    let navigate = useNavigate();
    const [lazyload, setLazyload] = useState(false); // LAZYLOAD
    const [depoimentos, setDepoimentos] = useState([]); // DEPOIMENTOS DOS CLIENTES

    function fechaModalPreviewDepoimento(el) {
        el.preventDefault();

        fecharModal();
        setTimeout(() => { props.setOpenModal(null) }, 1000);
    }

    function cadastrarDepoimento(el) {
        el.preventDefault();

        db.collection("relatorios")
            .doc("UX")
            .collection("depoimentos")
            .add({
                area: 'geral',
                avaliacao: 5,
                depoimento: props.openModal.depoimento.conteudo,
                titulo: props.openModal.depoimento.titulo,
                nome: props.openModal.depoimento.nome,
                profissao: props.openModal.depoimento.profissao,
                publicar: false,
                tipo: 'cliente'
            })
            .then(() => { // O "ACESSO" é Referente ao numero de vezes que os usuaios acessaram aquele link
                db.collection("convites")
                    .doc(`${props.openModal.depoimento.convite}`)
                    .update("acesso", fs.firestore.FieldValue.increment(1))
                    .then(() => {
                        navigate("/");
                        fechaModalPreviewDepoimento(el);
                        toast.success("O depoimento cadastrado com sucesso!");
                        toast.success("Muito obrigado pelo seu depoimento!");
                    })
            })
            .catch((error) => {
                toast.error("Ocorreu um erro ao cadastrar o depoimento\nTente novamente em alguns instantes");
                console.log(error);
            })
    }
    return (
        <div class="popup" style={{ zIndex: 9999, position: 'fixed', maxWidth: '566px' }}>
            <div className='header-popup'>
                <span>Depoimento do cliente</span>
                <a class="close-popup" onClick={(el) => fechaModalPreviewDepoimento(el)}><AiOutlineClose /></a>
            </div>
            <div class="content">
                {
                    (!lazyload)
                        ?
                        <div className='div1 eachdiv'>
                            <div class="userdetails">
                                <div class="imgbox">
                                    <img src={logoIcon} alt="Imagem do usuário" title="Imagem do usuário" />
                                </div>
                                <div class="detbox">
                                    <p class="name">{props.openModal.depoimento.nome}</p>
                                    {
                                        (props.openModal.depoimento.profissao && props.openModal.depoimento.profissao.length)
                                            ?
                                            <p class="designation fz-13" style={{textTransform: 'capitalize'}}>{props.openModal.depoimento.profissao}</p>
                                            : null
                                    }
                                </div>
                            </div>
                            <div class="review">
                                <h4>{props.openModal.depoimento.titulo}</h4>
                                <p className='fz-15'>{props.openModal.depoimento.conteudo}</p>
                            </div>
                        </div>
                        : null
                }
            </div>
            <div className='w50 right tx-rig'>
                <button className="btn__cancel__modal" onClick={(el) => fechaModalPreviewDepoimento(el)}>
                    Fechar
                </button>
            </div>
            <div className='clear'></div>
        </div>
    )
}

export default ModalVisualizarDepoimento;