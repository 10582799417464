import { useState } from "react";
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';
import $, { isEmptyObject } from 'jquery';


import { BsCashCoin, BsChevronDown, BsTrash } from 'react-icons/bs';

import { AiFillEdit, AiOutlineFundView, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';

import { IoIosInformationCircleOutline } from 'react-icons/io';
import { GiParasaurolophus, GiSave } from 'react-icons/gi';
import { TiInfo } from 'react-icons/ti';

import { db } from "../../../../../../../../../../firebase";
import toast from "react-hot-toast";
import firebase from "firebase";

export default function BudgetBox_single(props) {
    const [expandedSummary, setExpandedSummary] = useState(0); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO
    const [etapa, setEtapa] = useState([]);
    const [editStage, setEditStage] = useState(null);
    const [editStageNew, setEditStageNew] = useState([]);
    const [observacoes, setObservacoes] = useState([]);

    async function salvarEtapa(e, id) {
        e.preventDefault();
        var novaEtapa = etapa.replace(/["]/g, '').split(";");
        var newStage = ([]);

        if (props.dadosContrato.observacoes_privadas && props.dadosContrato.observacoes_privadas.length) {
            props.dadosContrato.observacoes_privadas.map((doc) => {
                newStage.push(doc);
            })
        }

        novaEtapa.map((stage) => {
            newStage.push(stage)
        })
        // console.log(newStage)


        const ref = db.collection('servicos');
        const snapshot = await ref.where('token', '==', props.dadosContrato.token).get();
        if (snapshot.empty) {
            // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
            toast.error("Não foi possivel localizar o orçamento " + props.dadosContrato.token);
        }

        let tokenId = ([]);

        snapshot.forEach(doc => {
            tokenId.push(doc.id);
        });

        db.collection("servicos")
            .doc(tokenId[0])
            .update({ 'observacoes_privadas': newStage })
            .then(() => {
                toast.success("A observação foi adicionada com sucesso!");
                setEtapa(' ');
            }).catch((err) => {
                toast.error("Ocorreu um erro ao salvar sua nova observação!");
                console.log(err)
            })
    }

    async function apagarEtapa(e, id) {
        e.preventDefault();

        var newStage = ([]);
        if (props.dadosContrato.observacoes_privadas && props.dadosContrato.observacoes_privadas.length) {
            props.dadosContrato.observacoes_privadas.map((doc) => {
                newStage.push(doc);
            })
        }
        newStage.splice(id, 1);
        setObservacoes(newStage);

        // console.log(newStage);

        const ref = db.collection('servicos');
        const snapshot = await ref.where('token', '==', props.dadosContrato.token).get();
        if (snapshot.empty) {
            // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
            toast.error("Não foi possivel localizar o orçamento");
        }

        let tokenId = ([]);

        snapshot.forEach(doc => {
            tokenId.push(doc.id);
        });

        db.collection("servicos")
            .doc(tokenId[0])
            .update({ 'observacoes_privadas': newStage })
            .then(() => {
                setEditStage(null);
                setEditStageNew([]);

                toast.success(`A nota de observação foi apagada com sucesso!`);
            }).catch((err) => {
                toast.error("Ocorreu um erro ao apagar a nota de observação!");
                console.log(err)
            })
    }

    async function apagarTodasObsevacoes(e, id) {
        e.preventDefault();

        const ref = db.collection('servicos');
        const snapshot = await ref.where('token', '==', props.dadosContrato.token).get();
        if (snapshot.empty) {
            // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
            toast.error("Não foi possivel localizar o orçamento");
        }

        let tokenId = ([]);

        snapshot.forEach(doc => {
            tokenId.push(doc.id);
        });

        db.collection("servicos")
            .doc(tokenId[0])
            .update({ 'observacoes_privadas': firebase.firestore.FieldValue.delete() })
            .then(() => {
                toast.success(`Todas as notas foram apagadas com sucesso!`);
            }).catch((err) => {
                toast.error("Ocorreu um erro ao apagar as notas!");
                console.log(err)
            })
    }

    function editarEtapa(e, id) {
        e.preventDefault();
        setEditStage(id);
        console.log(editStage);
    }

    async function salvarEtapaEditada(e, id, dataOriginal) {
        e.preventDefault();

        if (editStageNew && editStageNew.length) {
            var newEditStage = ([]);
            if (props.dadosContrato.observacoes_privadas && props.dadosContrato.observacoes_privadas.length) {
                props.dadosContrato.observacoes_privadas.map((doc) => {
                    newEditStage.push(doc);
                })
            }
            newEditStage[id] = editStageNew;
            // console.log(newEditStage);

            const ref = db.collection('servicos');
            const snapshot = await ref.where('token', '==', props.dadosContrato.token).get();
            if (snapshot.empty) {
                // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
                toast.error("Não foi possivel localizar o orçamento");

            }

            let tokenId = ([]);

            snapshot.forEach(doc => {
                tokenId.push(doc.id);
            });

            db.collection("servicos")
                .doc(tokenId[0])
                .update({ 'observacoes_privadas': newEditStage })
                .then(() => {
                    setEditStage(null);
                    setEditStageNew([]);

                    toast.success(`A nota de observação ${editStageNew} foi editada com sucesso!`);
                }).catch((err) => {
                    toast.error("Ocorreu um erro ao salvar edição da nota de observação!");
                    console.log(err)
                })
        } else {
            setEditStage(null);
            setEditStageNew([]);
            toast("Nada foi alterado")
        }
    }

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    return (
        <div>
            <motion.div
                style={{ transformOrigin: "top", overflow: "hidden" }}
                animate={{ height: (expandedSummary) ? "auto" : expandedSummarySize }}
                className='description-page__content'
            >
                <div className={(expandedSummary) ? 'wrapper__request__single w100 p-g-10 box__admin is-open' : 'wrapper__request__single w100 p-g-10 box__admin'}>
                    {/* DADOS PRINCIPAIS */}
                    <div className='order__title__request p-top-10 p-bot-15 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                        <div className='order__item__request info-primary' onClick={() => setExpandedSummary(!expandedSummary)}>
                            {
                                (props.responsivoSize && props.responsivoSize > 351)
                                    ?
                                    <strong>
                                        Orçamento {props.dadosContrato.token}
                                    </strong>
                                    :
                                    <strong>
                                        {props.dadosContrato.token}
                                    </strong>
                            }
                        </div>
                        {
                            (props.responsivoSize && props.responsivoSize > 460 && props.dadosContrato.validade_orcamento && props.dadosContrato.validade_orcamento.length)
                                ?
                                <div className='order__item__request info-default'>
                                    <small className='d-bk m-bot-5'>
                                        Vencimento da Proposta
                                    </small>
                                    <strong className='d-bk'>
                                        {
                                            new Date(props.dadosContrato.validade_orcamento).toLocaleDateString("pt-BR")
                                        }
                                    </strong>
                                </div>
                                : null
                        }
                        {
                            (props.responsivoSize && props.responsivoSize > 600 && props.dadosContrato.aprovacao && !isEmptyObject(props.dadosContrato.aprovacao))
                                ?
                                <div className='order__item__request info-default'>
                                    <small className='d-bk m-bot-5'>
                                        Data da aprovação
                                    </small>
                                    <strong className='d-bk'>
                                        {
                                            props.dadosContrato.aprovacao.toDate().toLocaleDateString("pt-BR")
                                        }
                                    </strong>
                                </div>
                                : null
                        }
                        {
                            (props.responsivoSize && props.responsivoSize > 245)
                                ?
                                <>
                                    {
                                        (() => {
                                            const d1 = new Date().toLocaleDateString("en-US");
                                            const d2 = new Date(props.dadosContrato.validade_orcamento).toLocaleDateString("en-US");

                                            const diffInMs = new Date(d2) - new Date(d1);
                                            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                                            if ((props.dadosContrato.status === "aprovado") || diffInDays >= 0 && props.dadosContrato.status) {
                                                return (
                                                    <div className='order__item__request info-default'>
                                                        <div className={(props.dadosContrato.status === "aprovado") ? 'med-badge active-badge-success' : 'med-badge'} style={{ textTransform: 'capitalize' }}>
                                                            {
                                                                props.dadosContrato.status
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className='med-badge' style={{ textTransform: 'capitalize', background: "#ff000030", color: "#AB133E" }}>
                                                        Vencido
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                </>
                                : null
                        }
                        <buttom className="order__item__request info-primary btn__show__request" onClick={() => setExpandedSummary(!expandedSummary)}>
                            <BsChevronDown className='fz-20' />
                        </buttom>
                    </div>

                    {/* SUB DADOS */}
                    <div className='order__body__request'>
                        <div className='content__request d-fx d-fd-col'>
                            <motion.div
                                style={{ transformOrigin: "top", overflow: "hidden" }}
                                animate={{ height: expandedSubSummary ? "auto" : "70px" }}
                                className='description-page__content'
                            >
                                <div className={(expandedSubSummary) ? 'wrapper__order__request order-content p-g-15 open' : 'wrapper__order__request order-content p-g-15'}> {/* open */}
                                    <div className='order__title__request w100 d-fx d-ju-sp-bt d-al-cen d-fw-wp'>
                                        <div className='delivery__order__type info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                            <strong>Dados do Orçamento</strong>
                                        </div>
                                        {
                                            (props.responsivoSize && props.responsivoSize > 450 && props.dadosContrato.expedicao && !isEmptyObject(props.dadosContrato.expedicao))
                                                ?
                                                <div className='delivery__order__date info-default'>
                                                    <small className='d-bk m-bot-5'>
                                                        Data da expedição
                                                    </small>
                                                    <strong className='d-bk tx-norm fz-13'>
                                                        {
                                                            props.dadosContrato.expedicao.toDate().toLocaleDateString("pt-BR")
                                                        }
                                                    </strong>
                                                </div>
                                                : null
                                        }
                                        <buttom className='delivery__order__action info-primary' onClick={() => setExpandedSubSummary(!expandedSubSummary)}>
                                            <BsChevronDown className='fz-20' />
                                        </buttom>
                                    </div>
                                    <div className='delivery__body__request m-top-20'>
                                        <div className='wrapper__itens__request m-top-20 d-fx d-fw-wp d-ju-sp-bt'>
                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratado</span>
                                                {
                                                    (props.dadosContrato && props.dadosContrato.contratado && props.dadosContrato.contratado.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Empresa:</strong> {props.dadosContrato.contratado}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.cpf_cnpj_contratado && props.dadosContrato.cpf_cnpj_contratado)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            {
                                                                (() => {
                                                                    var cpf_cnpj = props.dadosContrato.cpf_cnpj_contratado.toString();
                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                    } else {
                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                    }
                                                                })()
                                                            }
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.representante_contratado && props.dadosContrato.representante_contratado.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Nome do Representante:</strong> {props.dadosContrato.representante_contratado}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.numero_representante_contratado)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Código do Representante:</strong> {props.dadosContrato.numero_representante_contratado}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.objeto_contratado && props.dadosContrato.objeto_contratado.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Objeto de Contratação:</strong> <span style={{ textTransform: 'capitalize' }}>{props.dadosContrato.objeto_contratado}</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.tipo_servico && props.dadosContrato.tipo_servico.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Tipo de Serviço:</strong> {props.dadosContrato.tipo_servico}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.categoria_servico && props.dadosContrato.categoria_servico.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Categoria de Serviço:</strong> {props.dadosContrato.categoria_servico}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.descricao_servico && props.dadosContrato.descricao_servico.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Descrição de Serviço:</strong> {props.dadosContrato.descricao_servico}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.endereco_contratado && !isEmptyObject(props.dadosContrato.endereco_contratado))
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Endereço:</strong> {`${props.dadosContrato.endereco_contratado.logradouro}, ${props.dadosContrato.endereco_contratado.numero}, ${props.dadosContrato.endereco_contratado.bairro} - ${props.dadosContrato.endereco_contratado.cidade}, - ${props.dadosContrato.endereco_contratado.estado}, CEP ${(() => {
                                                                var new_cep = props.dadosContrato.endereco_contratado.cep.toString();
                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                            })()}`}
                                                        </span>
                                                        : null
                                                }
                                            </div>
                                            <div className='take__product_adress d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#fff', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '100%' }}>
                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Contratante</span>
                                                {
                                                    (props.dadosContrato && props.dadosContrato.contratante && props.dadosContrato.contratante.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Contratante:</strong> {props.dadosContrato.contratante}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.cpf_cnpj_contratante)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            {
                                                                (() => {
                                                                    var cpf_cnpj = props.dadosContrato.cpf_cnpj_contratante.toString();
                                                                    if (cpf_cnpj.length && cpf_cnpj.length > 11) {
                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.***.***/***-$5")
                                                                        return (<span><strong>CNPJ:</strong> {new_cnpj}</span>);
                                                                    } else {
                                                                        var new_cnpj = cpf_cnpj.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                        return (<span><strong>CPF:</strong> {new_cnpj}</span>);
                                                                    }
                                                                })()
                                                            }
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.rg_contratante)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            {
                                                                (() => {
                                                                    var rg = props.dadosContrato.rg_contratante.toString();
                                                                    var new_rg = rg.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{2})?/, "$1.***.***-$4")
                                                                    return (<span><strong>RG:</strong> {new_rg}</span>);
                                                                })()
                                                            }
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.endereco_contratante && !isEmptyObject(props.dadosContrato.endereco_contratante))
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Endereço:</strong> {`${props.dadosContrato.endereco_contratante.logradouro}, ${props.dadosContrato.endereco_contratante.numero}, ${props.dadosContrato.endereco_contratante.bairro} - ${props.dadosContrato.endereco_contratante.cidade}, - ${props.dadosContrato.endereco_contratante.estado}, CEP ${(() => {
                                                                var new_cep = props.dadosContrato.endereco_contratante.cep.toString();
                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                            })()}`}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.email && props.dadosContrato.email.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>E-mail:</strong> {props.dadosContrato.email}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.user.telefone && props.user.telefone.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Telefone:</strong> {props.user.telefone}
                                                        </span>
                                                        : null
                                                }
                                            </div>
                                            <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#fff', borderRadius: '8px', border: "1px solid #ededed" }}>
                                                <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Dados do Serviço</span>
                                                {
                                                    (props.dadosContrato && props.dadosContrato.objeto_contratado && props.dadosContrato.objeto_contratado.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Objeto de Contratação:</strong> <span style={{ textTransform: 'capitalize' }}>{props.dadosContrato.objeto_contratado}</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.tipo_contratado && props.dadosContrato.tipo_contratado.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Tipo de Serviço:</strong> {props.dadosContrato.tipo_contratado}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.categoria_servico && props.dadosContrato.categoria_servico.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Categoria de Serviço:</strong> {props.dadosContrato.categoria_servico}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.descricao_servico && props.dadosContrato.descricao_servico.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Descrição de Serviço:</strong> {props.dadosContrato.descricao_servico}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.tipo_execucao && props.dadosContrato.tipo_execucao.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Tipo de Execução:</strong> <span style={{ textTransform: 'capitalize' }}>{props.dadosContrato.tipo_execucao}</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.endereco_servico && !isEmptyObject(props.dadosContrato.endereco_servico))
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Endereço:</strong> {`${props.dadosContrato.endereco_servico.logradouro}, ${props.dadosContrato.endereco_servico.numero}, ${props.dadosContrato.endereco_servico.bairro} - ${props.dadosContrato.endereco_servico.cidade}, - ${props.dadosContrato.endereco_servico.estado}, CEP ${(() => {
                                                                var new_cep = props.dadosContrato.endereco_servico.cep.toString();
                                                                return (new_cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, "$1-$2"))
                                                            })()}`}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (props.dadosContrato && props.dadosContrato.etapas_pagamento && props.dadosContrato.etapas_pagamento.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Quantidade de Etapas de Pagamento:</strong> {props.dadosContrato.etapas_pagamento.length}
                                                        </span>
                                                        : null
                                                }
                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                    <strong>Acompanhamento de Serviço:</strong> {(props.dadosContrato && props.dadosContrato.acompanhamento) ? "Ativo" : "Não Incluso"}
                                                </span>
                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                    <strong>Gerênciamento de Serviço:</strong> {(props.dadosContrato && props.dadosContrato.gerenciamento) ? "Ativo" : "Não Incluso"}
                                                </span>
                                                <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                    <strong>Compra de Materiais do Serviço:</strong> {(props.dadosContrato && props.dadosContrato.compras) ? "Ativo" : "Não Incluso"}
                                                </span>
                                                {
                                                    (props.dadosContrato && props.dadosContrato.observacoes && props.dadosContrato.observacoes.length)
                                                        ?
                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                            <strong>Observações:</strong>
                                                            <ul style={{ marginLeft: '20px' }}>
                                                                {
                                                                    props.dadosContrato.observacoes.map((obs) => {
                                                                        return (<li>{obs}</li>)
                                                                    })
                                                                }
                                                            </ul>
                                                        </span>
                                                        : null
                                                }
                                            </div>
                                            {
                                                (props.user && props.user.representante)
                                                    ?
                                                    <div className='take__product_adress d-fx d-fw-wp w100 m-top-10 m-bot-10 p-g-15' style={{ background: '#fff', borderRadius: '8px', border: "1px solid #ededed" }}>
                                                        <span className='adress__title tx-bold fz-15 d-bk w100 b-bot-small'>Notas de Observações</span>
                                                        <div className={(props.dadosContrato.observacoes_privadas && props.dadosContrato.observacoes_privadas.length) ? 'box-input-single m-top-20 w100 b-bot' : 'box-input-single m-top-20 w100'}>
                                                            <label>
                                                                <div className='title-input d-fx d-al-cen p-rlt'>
                                                                    <span className='fz-12 m-rig-5'>Nova nota de observação</span>
                                                                    <span className='icon-info-add p-rlt'>
                                                                        <IoIosInformationCircleOutline />
                                                                        <div className='helper-input tx-black d-nn'>
                                                                            <div tabindex="-1" class="css-1pccujx-popover" data-placement="right-start">
                                                                                <span class="css-1i1x8nz">
                                                                                    Sobre esta nota:
                                                                                </span>
                                                                                <div class="css-1l7kih-stack">
                                                                                    <div>
                                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                            <div>
                                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                            </div>
                                                                                            <div>
                                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Está visível apenas para o representante</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                            <div>
                                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                            </div>
                                                                                            <div>
                                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">O Cliente final não visualiza esta nota</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center">
                                                                                            <div>
                                                                                                <svg viewBox="0 0 14 12" fill="currentColor" aria-hidden="true" role="presentation" class="css-cmlben-icon-icon--color-primary-icon--size-12px"><path d="M5.73 11.27l8.05-8.468a.626.626 0 00.22-.507.62.62 0 00-.22-.507L12.818.73a.676.676 0 00-.504-.23.676.676 0 00-.502.23L5.25 7.633l-3.061-3.22a.68.68 0 00-.504-.23.68.68 0 00-.504.23L.218 5.47A.625.625 0 000 5.977c0 .214.073.383.218.505l4.55 4.788c.117.153.278.23.481.23a.57.57 0 00.482-.23"></path></svg>
                                                                                            </div>
                                                                                            <div>
                                                                                                <p class="css-1dwd66e-text-text--kilo-heading--no-margin">Auxilia na negociação</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div data-popper-arrow="true" class="css-tlli5z" style={{ position: 'absolute', top: '0px', transform: 'translate(0px, 18px)' }}></div>
                                                                            </div>
                                                                        </div>{/*Helper*/}
                                                                    </span>
                                                                </div>
                                                            </label>
                                                            <div className='p-rlt'>
                                                                <textarea
                                                                    id='cadastro_notas_observacoes_privadas'
                                                                    className='input-form w100 fz-16 m-top-5'
                                                                    type='text'
                                                                    name="etapa_requisitos"
                                                                    placeholder='O valor mínimo para esse orçamento será de R$ 100,00'
                                                                    value={(etapa && etapa.length) ? etapa : null}
                                                                    onChange={(ev) => setEtapa(ev.target.value)}
                                                                    style={{ paddingRight: '35px', resize: 'vertical', minHeight: '45px' }}
                                                                >
                                                                </textarea>
                                                                <button
                                                                    className='p-abs' style={{ top: "50%", transform: "translateY(-50%)", right: '2%', background: 'none', cursor: 'pointer', }}
                                                                    title="Salvar nota de observação para o serviço"
                                                                    onClick={(e) => salvarEtapa(e)}
                                                                    disabled={(!etapa || !etapa.length) ? true : false}
                                                                >
                                                                    <GiSave className='fz-20' style={{ color: 'var(--logo-color-dark)' }} />
                                                                </button>

                                                            </div>
                                                        </div>

                                                        {
                                                            (props.dadosContrato.observacoes_privadas && props.dadosContrato.observacoes_privadas.length)
                                                                ?
                                                                <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-10 p-g-15 w100' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#fffccf30', borderRadius: '8px', border: "1px solid #fae800", margin: '0 1% 5px 0' } : { background: '#fffccf30', borderRadius: '8px', border: "1px solid #fae800", margin: '0 1% 5px 0', width: '100%' }}>
                                                                    <div className='p-rlt b-bot-medium w100'>
                                                                        <div className='d-fx d-al-cen m-bot-10 d-ju-cen'>
                                                                            <h2 className={(props.responsivoSize && props.responsivoSize > 365) ? 'fz-18 tx-cen w90' : 'fz-14 tx-cen w90'}>Notas de Observações do serviço</h2>
                                                                            {
                                                                                (props.responsivoSize && props.responsivoSize > 365)
                                                                                    ?
                                                                                    <button
                                                                                        className='p-abs'
                                                                                        style={{ right: 0, background: 'none', cursor: 'pointer' }}
                                                                                        title="Apagar todas as notas de observação"
                                                                                        onClick={(e) => { apagarTodasObsevacoes(e) }}
                                                                                    >
                                                                                        <BsTrash
                                                                                            className='fz-18'
                                                                                            style={{ color: '#ff0000' }}
                                                                                        />
                                                                                    </button>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-fx d-fd-col w100'>
                                                                        {
                                                                            props.dadosContrato.observacoes_privadas.map((dadosEtapa, index) => {
                                                                                return (
                                                                                    <div
                                                                                        key={index}
                                                                                        className={(props.responsivoSize && GiParasaurolophus.responsivoSize > 400) ? "d-fx d-al-cen p-g-10 w100" : "d-fx d-al-cen d-fw-wp p-g-10 w100"}
                                                                                    >
                                                                                        <span className='adress__content fz-13 d-bk w100 m-top-5 m-bot-5'>
                                                                                            <span className={(props.responsivoSize && props.responsivoSize > 330) ? 'd-fx d-al-cen' : 'd-fx d-al-cen d-fw-wp'}>
                                                                                                <strong>{`Nota ${index + 1}:`}</strong>
                                                                                                <span className='m-lef-5 w85'>
                                                                                                    {
                                                                                                        (editStage == index)
                                                                                                            ?
                                                                                                            <input
                                                                                                                type='text'
                                                                                                                name='etapa_requisitos'
                                                                                                                value={(editStageNew && !editStageNew.length) ? dadosEtapa : editStageNew}
                                                                                                                onChange={(e) => setEditStageNew(e.target.value)}
                                                                                                                style={{ padding: '3px 5px', borderRadius: '3px', border: '1px solid #ccc' }}
                                                                                                                className="w100"
                                                                                                            />
                                                                                                            :
                                                                                                            <span>{dadosEtapa}</span>
                                                                                                    }
                                                                                                </span>
                                                                                            </span>

                                                                                        </span>
                                                                                        {
                                                                                            (editStage == index)
                                                                                                ?
                                                                                                <button
                                                                                                    className='d-fx d-al-cen m-lef-5 fz-12' style={{ border: '1px solid #1f8900', padding: '3px 5px', borderRadius: '3px', background: "#1f890020", cursor: 'pointer', maxHeight: '25px' }}
                                                                                                    onClick={(e) => salvarEtapaEditada(e, index, dadosEtapa)}
                                                                                                >
                                                                                                    Salvar <GiSave className='fz-14 m-lef-5' style={{ color: "#1f8900" }} />
                                                                                                </button>
                                                                                                :
                                                                                                <div className={(props.responsivoSize && props.responsivoSize > 481) ? 'd-fx' : 'd-fx d-fw-wp'}>
                                                                                                    <button
                                                                                                        className='d-fx d-al-cen m-lef-5 fz-12' style={{ border: '1px solid #dba700', padding: '3px 5px', borderRadius: '3px', background: "#dba70020", cursor: 'pointer', maxHeight: '25px' }}
                                                                                                        onClick={(e) => editarEtapa(e, index)}
                                                                                                        disabled={(editStage && editStage != index) ? true : false}
                                                                                                    >
                                                                                                        Editar <AiFillEdit className='fz-14 m-lef-5' style={{ color: "#896900" }} />
                                                                                                    </button>

                                                                                                    <button
                                                                                                        className='d-fx d-al-cen m-lef-5 fz-12' style={{ border: '1px solid #ff0000', padding: '3px 5px', borderRadius: '3px', background: "#ff000020", cursor: 'pointer', maxHeight: '25px' }}
                                                                                                        onClick={(e) => apagarEtapa(e, index)}
                                                                                                        disabled={(editStage && editStage != index) ? true : false}
                                                                                                    >
                                                                                                        Apagar <BsTrash className='fz-14 m-lef-5' style={{ color: "#ff0000" }} />
                                                                                                    </button>
                                                                                                </div>
                                                                                        }
                                                                                    </div>

                                                                                )
                                                                            })
                                                                        }
                                                                        <div className='box-input-single'>
                                                                            <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                                                <span className='icon-valid'><TiInfo /></span>
                                                                                <span className='text-valid m-lef-5'>{`${props.user.given_name} você tem ${props.dadosContrato.observacoes_privadas.length} nota${(props.dadosContrato.observacoes_privadas.length > 1) ? "s" : ""} de observa${(props.dadosContrato.observacoes_privadas.length > 1) ? "ções" : "ção"} para este serviço.`}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                            <div className='w100 d-fx m-top-15' style={{ justifyContent: 'flex-end' }}>
                                {
                                    (() => {
                                        const d1 = new Date().toLocaleDateString("en-US");
                                        const d2 = new Date(props.dadosContrato.validade_orcamento).toLocaleDateString("en-US");

                                        const diffInMs = new Date(d2) - new Date(d1);
                                        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                                        if (diffInDays >= 0 || props.user.representante === true) {
                                            return (
                                                <Link to={`/minha-conta/meus-orcamentos/${props.dadosContrato.token}`} className='view-button view-btn-secondary' onClick={(e) => props.setOrcamentoUnico(props.dadosContrato)}>
                                                    <AiOutlineFundView className='fz-24' /> Visualizar Orçamento
                                                </Link>
                                            )
                                        } else {
                                            return (
                                                <button
                                                    className='view-button view-btn-secondary'
                                                    onClick={(e) => e.preventDefault()}
                                                    disabled={true}
                                                    title="Este orçamento está vencido"
                                                >
                                                    <AiOutlineFundView className='fz-24' /> Visualizar Orçamento
                                                </button>
                                            )
                                        }
                                    })()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}