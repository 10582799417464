import '../Assets/styles/css/Main.css';
import { useEffect, useState, useRef, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';


import $, { merge } from 'jquery';

// IMPORTAÇÃO DE IMAGENS
import iconeLogo from '../../../../images/icones/jpg/logo_icone.jpg';
import slide from '../../../../images/projetos/png/slide04.png';
import promo from '../../../../images/projetos/png/Projetos Prontos.png';
import home from '../../../../images/projetos/png/home1.png';
import promo2 from '../../../../images/projetos/jpg/promo.jpg';
import promoBox from '../../../../images/projetos/jpg/promo_380x400.jpg';

// REACT ICONS
import { FaBan, FaStar, FaShareSquare, FaEye, FaHeart, FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp, FaTelegramPlane, FaCalendarDay } from 'react-icons/fa';
import { AiFillLike, AiOutlineClose, AiFillInfoCircle } from 'react-icons/ai';
import { BsLink45Deg, BsFillImageFill } from 'react-icons/bs';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from '../Assets/components/Arrows';

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'


import firebase from 'firebase';
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import axios from 'axios';
import toast from 'react-hot-toast';
import { db, fs } from '../../../../firebase';
import SlidesOfertasGerais from '../Assets/components/SlidesOfertasGerais';
import CuponsOfertas from '../Assets/components/CuponsOfertas';
import CardsProjetos from '../Assets/components/CardsProjetos';
import BannersOfertas from '../Assets/components/BannersOfertas';
import { WidgetDepartamentos } from '../Assets/components/Departamentos';
import BoxOfertas from '../Assets/components/BoxOfertas';
import Depoimentos from '../Assets/components/Depoimentos';
import FAQProjetosProntos from '../Assets/components/FAQProjetosProntos';
import Newsletter from '../Assets/components/Newsletter';
import BoxDecoracao from '../Assets/components/BoxDecoracao';

function Home(props) {
    let navigate = useNavigate();

    const [bgSlideHome, setbgSlideHome] = useState([]);

    const [data, setData] = useState([]);
    const carousel = useRef(null);

    const [visualizacao, setVisualizacao] = useState([]);
    const [favoritados, setFavoritados] = useState([]);
    const [ofertasCupons, setOfertasCupons] = useState([]);
    const [projetos, setProjetos] = useState([]);
    return (
        <div id='home'>
            <Helmet title="Engeart • Plantas de Casas, Modelos de Casas e Sobrados e Fachadas de Casas" />
            {/* SLIDES */}
            <SlidesOfertasGerais />
            {/* <section id="slide_home" className="slides-wrapper p-rlt p-bot-25 z-less" style={(bgSlideHome) ? 'background: linear-gradient(' + bgSlideHome.rotate + ', ' + bgSlideHome.left + ', ' + bgSlideHome.right + ')' : null}> */}

            {/* SESSÃO - CUPONS - PROMOÇÕES */}
            <CuponsOfertas />

            {/* SESSÃO - ULTIMAS VISUALIZAÇÕES */}
            {/* <CardsProjetos user={props.user} setOpenLike={props.setOpenLike} projetos={projetos}  setItensFavoritos={props.setItensFavoritos} listasFavoritos={props.listasFavoritos} setListasFavoritos={props.setListasFavoritos} favorito={props.favorito} setFavorito={props.setFavorito} visualizacao={visualizacao} favoritados={favoritados} openModal={props.openModal} setOpenModal={props.setOpenModal} titulo="Projetos que você pode gostar" /> */}

            <CardsProjetos user={props.user} setItemFavorito={props.setItemFavorito} setOpenLike={props.setOpenLike} projetos={props.projetos} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} listasFavoritos={props.listasFavoritos} setListasFavoritos={props.setListasFavoritos} favorito={props.favorito} setFavorito={props.setFavorito} visualizacao={visualizacao} favoritados={favoritados} openModal={props.openModal} setOpenModal={props.setOpenModal} titulo="Projetos que você pode gostar" />

            {/* SESSÃO - BANNERS - PROMOÇÕES */}
            <BannersOfertas />

            {/* SESSÃO - DEPARTAMENTOS */}
            <WidgetDepartamentos />

            {/* SESSÃO - DICAS EM DESTAQUE */}
            {/* <BoxDecoracao titulo="Últimos Conteúdos" /> */}

            {/* SESSÃO - ULTIMAS VISUALIZAÇÕES */}
            <CardsProjetos user={props.user} setOpenLike={props.setOpenLike} projetos={props.projetos} itensFavoritos={props.itensFavoritos} setItensFavoritos={props.setItensFavoritos} setItemFavorito={props.setItemFavorito} listasFavoritos={props.listasFavoritos} favorito={props.favorito} visualizacao={visualizacao} favoritados={favoritados} avaliados={props.avaliados} setAvaliados={props.setAvaliados} itensAvaliados={props.itensAvaliados} setItensAvaliados={props.setItensAvaliados} />

            {/* SESSÃO - NEWSLETTER */}
            <Newsletter user={props.user} />

            {/* SESSÃO - PROMOÇÃO APP */}
            <BoxOfertas />

            {/* SESSÃO - DEPOIMENTOS */}
            <Depoimentos setOpenModal={props.setOpenModal} />

            {/* SESSÃO - FAQ - HOME */}
            <FAQProjetosProntos />
        </div>
    );
}

export default Home;