import { useEffect, useState, useMemo } from 'react';
import { db } from '../../../../../firebase';
import { Link } from 'react-router-dom';

import Carousel, { consts } from 'react-elastic-carousel';
import { ArrowLeft, ArrowRight } from './Arrows';
import { FAQLazyload } from './Lazyload';
import toast from 'react-hot-toast';
import FAQSingle from './FAQSingle';

function FAQProjetosProntos(props) {

    const [FAQ, setFAQ] = useState([]); // PERGUNTAS FREQUENTES DOS CLIENTES | PROJETOS PRONTOS
    const [lazyload, setLazyload] = useState(true); // LAZYLOAD

    useEffect(() => {
        // PEGAR DADOS DO SLIDE E INSERIR NA SESSÃO
        if (!sessionStorage.getItem("FAQ_projetosProntos")) {
            if (!FAQ || !FAQ.length) {
                (async () => {
                    try {
                        const FAQRef = db.collection("FAQ").doc("e_commerce").collection("projetos_prontos").where("status", "==", true);
                        const snapshot = await FAQRef.get();
                        if (snapshot.empty) {
                            toast.error("Não existe perguntas frequêntes disponível no momento!");
                            return;
                        }
                        var arrFAQ = ([]);
                        snapshot.forEach(doc => {
                            arrFAQ.unshift(doc.data());
                        });

                        sessionStorage.setItem('FAQ_projetosProntos',JSON.stringify(arrFAQ));
                        setFAQ(arrFAQ);
                    } catch (error) {
                        toast.error("Ocorreu um erro ao recuperar as perguntas frequêntes!");
                        console.log(error);
                    }
                })()
            }
        } else {
            // RECUPERAR DADOS SALVOS NA SESSÃO
            let dadosFAQ = JSON.parse(sessionStorage.getItem('FAQ_projetosProntos'));
            setFAQ(dadosFAQ);
        }
    }, [])

    useMemo(() => {
        if (FAQ && FAQ.length) {
            setLazyload(false);
        }
    }, [FAQ]);

    let rows = [];
    for (let i = 1; i <= 6; i++) {
        rows.push(<FAQLazyload />)
    }

    function toggleClass(e) {
        e.preventDefault();
        e.currentTarget.classList.toggle('is-open');
    }

    return (
        <section className="faq-wrapper p-rlt container__content w100 z-less">
            <div className="center">
                <h2 className='sub-title d-bk'>Perguntas frequentes</h2>
                <div class="accordion">
                    {
                        (!lazyload)
                            ?
                                (FAQ && FAQ.length > 0)
                                    ?
                                    FAQ.map((dadosPergunta) => {
                                        return (
                                            <FAQSingle pergunta={dadosPergunta} />
                                        )
                                    })
                                    : null
                            : rows

                    }
                </div>
            </div>{/* center */}
        </section>
    )
}

export default FAQProjetosProntos;