import '../../../../../Assets/styles/css/Main.css'; // ESTILO GERAL
import '../../../Assets/Styles/Css/PC-Main.css'; // ESTILO PRIVADO DO PAINEL DO CLIENTE
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import toast from 'react-hot-toast';
import '../../../../../Assets/styles/css/Buttons.css';

// IMPORTAÇÃO FIREBASE
import { auth, db, storage } from '../../../../../../../../firebase';
import firebase from 'firebase';

import PC_Sidebar from '../Sidebar/PC-Sidebar';

import $, { isEmptyObject } from 'jquery';

// IMPORTAÇÃO DE REACT ROUTER

// IMPORTAÇÃO DE MANIPULADOR DE TITLE
import Helmet from 'react-helmet'

// IMPORTAÇÃO DE IMAGENS
import singUp from '../../../../../../../../images/icones/svg/sing-up.svg';

// IMPORTAÇÃO DE ICONES
import { RiFileUserLine } from 'react-icons/ri';
import { GiPresent } from 'react-icons/gi';
import { BsCashCoin, BsChevronDown, BsSave } from 'react-icons/bs';
import { AiOutlineFolderView, AiOutlineFundView, AiOutlinePlusCircle, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { HiOutlineFolderDownload, HiOutlineUserGroup } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo } from 'react-icons/ti';
import { FaRegEdit } from 'react-icons/fa';
import contractOkay from '../../../../../../../../images/icones/svg/contract-okay.svg';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion'; // FRAMER MOTION 
import { Link, useParams } from 'react-router-dom';
import BudgetBox_pendente from './Assets/Components/BudgetBox_single';

// TESTE EMAIL
import emailjs from '@emailjs/browser';



// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond'
import pt_BR from 'filepond/locale/pt-br';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
// FilePond.setOptions(pt_BR);

function PC_ADM_CriarUsuario(props) {
    const [files, setFiles] = useState([])

    const [expandedSummary, setExpandedSummary] = useState(0); // EXPANDIR DIV DE PEDIDO
    const [expandedSubSummary, setExpandedSubSummary] = useState(false); // EXPANDIR DIV DE DADOS DO PEDIDO

    // const [dadosPedido, setDadosPedido] = useState([]);

    const [expandedSummarySize, setExpandedSummarySize] = useState("70px");
    const [status, setStatus] = useState(false);


    const [orcamentos, setOrcamentos] = useState([]);

    useMemo(() => {
        if (props.user && !isEmptyObject(props.user) && props.user.representante) {
            db.collection("orcamentos")
                .where("numero_representante_contratado", "==", props.user.token_representante)
                .onSnapshot((snap) => {
                    setOrcamentos(
                        snap.docs.map((doc) => {
                            return doc.data();
                        })
                    )
                })


            db.collection("drive")
                .onSnapshot(snap => {
                    var arr = ([]);
                    snap.docs.map(doc => {
                        arr.push(doc.data());
                    })
                    console.log(arr);
                })
        }
    }, [])

    function assinarContrato(e) {
        e.preventDefault();
        // toast.success("Contrato assinado");
        props.setOpenModal({ status: true, tipo: "assinatura-contrato" })
    }



    const [progresso, setProgresso] = useState(0);
    const [content, setContent] = useState([]);


    async function fazerUploadArquivo(arquivo, uid) {
        const uploadTask = storage.ref('drive/' + arquivo.name).put(arquivo);



        uploadTask.on('state_changed', (snapshot) => {

            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 1;

            setProgresso(progress);

        },

            function (error) {



            },



            function () {

                storage.ref('SERVICOS/E951357852456FC/' + arquivo.name).getDownloadURL().then((url) => {

                    db.collection('drive').add({

                        diretorio: url,

                        tipo: arquivo.type,

                        orderId: 1

                    })

                })


            }



        )

    }

    return (
        <div id='myAccount' className={(props.responsivoSize && props.responsivoSize >= 992) ? 'wrapper-account' : 'wrapper-account p-g-10'}>
            <Helmet title="Engeart • Área do Administrador" />
            <div className='content-myccount'>
                <div className='center d-fx'>
                    {
                        (props.responsivoSize && props.responsivoSize >= 992)
                            ?
                            <PC_Sidebar user={(props.user) ? props.user : null} />
                            : null
                    }
                    <div className={(props.responsivoSize && props.responsivoSize >= 992) ? 'box-form-singup w80 left' : 'box-form-singup w100'} style={{ background: '#fff', borderRadius: 8, border: '1px solid #ddd' }}>
                        <div className='content-account p-g-25 p-bot-0'>
                            <h1 className='fz-25 tx-norm m-bot-20 w100 tx-cen b-bot'>Área de Orçamentos</h1>
                            <div className='wrapper__boxes__budgets w100 d-fx d-fw-wp'>
                                <div className='wrapper__new__budget w50 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Criar Novo Orçamento</h2>
                                    <p className='fz-14 m-bot-10' style={{ lineHeight: 1.3 }}>
                                        Nulla ea dolore eu elit cillum incididunt non voluptate amet eiusmod consequat voluptate. Excepteur aliquip aliquip proident reprehenderit ut reprehenderit nisi Lorem pariatur.
                                    </p>
                                    <Link to="/minha-conta/administrador/orcamentos/novo" class="button button-full button-primary tx-cen">Criar Orçamento</Link>
                                </div>
                                <div className='wrapper__new__budget w50 d-fx d-fw-wp m-top-10 m-bot-10 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed", margin: '0 1% 5px 0', width: '49%' } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Negociação de Orçamentos</h2>
                                    <p className='fz-14 m-bot-10' style={{ lineHeight: 1.3 }}>
                                        Nulla ea dolore eu elit cillum incididunt non voluptate amet eiusmod consequat voluptate. Excepteur aliquip aliquip proident reprehenderit ut reprehenderit nisi Lorem pariatur.
                                    </p>
                                    {/* <Link to="/minha-conta/administrador/orcamentos/negociacao" class="button button-full button-primary tx-cen" style={{ backgroundColor: '#dba700' }}>Negociar Orçamentos</Link> */}
                                    <buttom
                                        class="button button-full button-primary tx-cen"
                                        style={{ backgroundColor: '#dba700' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toast.error("Esta função está em desenvolvimento!");
                                        }}
                                    >Negociar Orçamentos</buttom>
                                </div>
                                <div className='wrapper__new__budget d-fx d-fw-wp m-top-10 m-bot-25 p-g-15' style={(props.responsivoSize && props.responsivoSize > 481) ? { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" } : { background: '#f8f8f8', borderRadius: '8px', border: "1px solid #ededed" }}>
                                    <h2 className='fz-18 m-bot-10 tx-cen w100 b-bot-medium'>Histórico de Orçamentos</h2>
                                    <div className='box-forms-singup'>
                                        {
                                            (orcamentos && orcamentos.length)
                                                ?
                                                <div className='dados-pessoais-usuario'>
                                                    <div className='box-requests w100 m-bot-20'>
                                                        {
                                                            orcamentos.map((dadosContrato, index) => {
                                                                return (
                                                                    <BudgetBox_pendente
                                                                        key={index}
                                                                        dadosContrato={dadosContrato}
                                                                        user={props.user}
                                                                        responsivoSize={props.responsivoSize}
                                                                        setOrcamentoUnico={props.setOrcamentoUnico}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className='d-fx d-al-cen'>
                                                    <div className='w65 p-g-25'>
                                                        <h2 className='m-bot-20'>Hey!</h2>
                                                        <div style={{ lineHeight: 1.5 }}>
                                                            <p className='fz-16'>Não foi possivel localizarmos nenhum contrato para o CPF do usuário logado :(</p>
                                                            <p className='fz-16'>Parece que o login foi realizado com o CPF de alguém que não seja o contratante principal!</p>
                                                            <div className='m-top-15 p-g-10' style={{ backgroundColor: "#f5f5f5", borderRadius: '6px' }}>
                                                                <p className='fz-14 m-bot-10'>O contratante principal já possui uma conta? Clique em sair e crie uma nova conta totalmente gratuita!</p>
                                                                <p className='fz-14'>Você é o contratante principal e está tendo problemas para visualizar seu contrato? Clique <Link to="/ouvidoria">aqui</Link>!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w35'>
                                                        <img src={contractOkay} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className='w100'>
                                    <FilePond
                                        files={files}
                                        onupdatefiles={setFiles}
                                        allowMultiple={true}
                                        maxFiles={null}
                                        server={{
                                            // fake server to simulate loading a 'local' server file and processing a file
                                            process: (fieldName, file, metadata, load) => {
                                                // simulates uploading a file
                                                fazerUploadArquivo(file, 12345).then(() => {
                                                    load(progresso);
                                                })
                                            },
                                            load: (source, load) => {
                                                // simulates loading a file from the server
                                                fetch(source)
                                                    .then((res) => res.blob())
                                                    .then(load);
                                            },
                                        }}
                                        name="files"
                                        labelIdle='Arraste e solte seus arquivos ou <span class="filepond--label-action">Navegue</span>'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );

}

export default PC_ADM_CriarUsuario;