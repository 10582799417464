import $ from 'jquery';
import toast from 'react-hot-toast';

function abrirModalCompartilhar(link) {
    // console.log(window.location.origin);
    var link = window.location.origin + link;
    var inputLink = $("#modal_compartilhamento_link .popup .share__link__input");
    inputLink.val(link);

    // var link = 'eengeart.com/projetos/' + code; // LEMBRETE: COMO FEATURE NÃO COMPARTILHARÁ APENAS PROJETOS, ENTÃO TEREI QUE FAZER UMA ALTERAÇÃO PARA PEGAR DINAMICAMENTE O TIPO DE ITEM A SER COMPARTILHADO    
    let modalPhone = $('#modal_compartilhamento_link'); // BG
    let modalContent = $("#modal_compartilhamento_link .popup"); // CONTENT
    modalPhone.slideToggle(500);
    setTimeout(() => {
        modalContent.slideDown();
        // modalContent.currentTarget.classList.toggle('show');
    }, 500);
}

function fecharModalCompartilhar(link) {
    let modalPhone = $('#modal_compartilhamento_link'); // BG
    let modalContent = $("#modal_compartilhamento_link .popup"); // CONTENT
    if (modalPhone.css('display') == 'block') {
        modalContent.slideUp(500);
        setTimeout(() => {
            modalPhone.slideToggle();
            // modalContent.currentTarget.classList.toggle('show');
        }, 500);
    }
}

function copiarLinkCompartilhamento(el) {
    el.preventDefault();

    const viewBtn = document.querySelector(".view-modal"),
        popup = document.querySelector(".popup"),
        close = popup.querySelector(".close"),
        field = popup.querySelector(".field"),
        input = field.querySelector("input"),
        copy = field.querySelector("button");

    input.select(); //select input value
    if (document.execCommand("copy")) { //if the selected text copy
        field.classList.add("active");
        copy.innerText = "Copiado";
        toast.success('Link copiado para sua área de transferência!');
        setTimeout(() => {
            window.getSelection().removeAllRanges(); //remove selection from document
            field.classList.remove("active");
            copy.innerText = "Copiar";
        }, 3000);
    }

}

export { fecharModalCompartilhar, abrirModalCompartilhar, copiarLinkCompartilhamento };