import { useEffect, useState, useMemo } from 'react';
// IMPORTAÇÃO DE REACT ROUTER
import { Link } from 'react-router-dom';
// TOATS - ALERTAS
import toast from 'react-hot-toast';

// FIREBASE
import { db } from '../../../../../firebase';
import firebase from 'firebase';
import InputMask from "react-input-mask"; // MASCARA DE INPUT
import $ from 'jquery';

// IMPORTAÇÃO DE ICONES
import { MdHomeWork, MdHomeFilled } from 'react-icons/md';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TiInfo, TiInputChecked } from 'react-icons/ti';
import { AiOutlineMail, AiOutlinePhone, AiOutlineClockCircle, AiOutlineClose } from 'react-icons/ai';

// IMPORTAÇÃO DE IMAGENS
import contactUs from '../../../../../images/icones/svg/vendas.svg';

function FormVendas(props) {
    const [assunto, setAssunto] = useState(null);
    const [observacao, setObservacao] = useState(null);

    // HOOKS - NOME
    const [name, setName] = useState();
    const [nameMessage, setNameMessage] = useState([]);
    const [nameValid, setNameValid] = useState(false);

    // HOOKS - EMAIL
    const [email, setEmail] = useState();
    const [emailCadastrado, setEmailCadastrado] = useState(false);
    const [emailMessage, setEmailMessage] = useState([]);
    const [emailValid, setEmailValid] = useState(false);

    // HOOKS - TELEFONE
    const [phone, setPhone] = useState();
    const [phoneMessage, setPhoneMessage] = useState([]);
    const [phoneValid, setPhoneValid] = useState(false);
    const [phoneDDDValid, setPhoneDDDValid] = useState(false);

    // HOOKS - MEIOS DE CONTATO
    const [contatoEmail, setContatoEmail] = useState(false);
    const [contatoSms, setContatoSms] = useState(false);
    const [contatoWhatsapp, setContatoWhatsapp] = useState(false);
    const [contatoLigacao, setContatoLigacao] = useState(false);
    const [contatoCarta, setContatoCarta] = useState(false);

    // HOOKS - ADICIONAIS
    const [favoritos, setFavoritos] = useState([]);
    const [sendButton, setSendButton] = useState(false); // BOTÃO DE ENVIAR FORMULÁRIO

    useMemo(() => {
        if (name) {
            // VERIFICAÇÃO DE LETRAS EM SENHA
            var padrao = /[^a-zà-ú\s-]/gi;

            var valida_nome = name.match(padrao);

            if (valida_nome) {
                setNameValid(false);
                setNameMessage([{
                    'status': 'error',
                    'message': 'O nome deve conter apenas letras'
                }])
            } else {
                //O NOME É VÁLIDO CONTEM APENAS LETRAS -> VERIFICAR SE O NOME É COMPLETO
                var arrName = name.split(' ');
                if (arrName.length <= 1) {
                    // O USUARIO NÃO PASSOU O NOME COMPLETO
                    setNameValid(false);
                    setNameMessage([{
                        'status': 'error',
                        'message': 'O nome deve ser completo'
                    }])
                } else {
                    // O USUARIO PASSOU O NOME COMPLETO
                    setNameValid(true);
                    setNameMessage([])
                }
            }
        }
    }, [name])

    useMemo(() => {
        if (email && email.length > 0) {
            function IsEmail(email) {
                var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                if (email == '' || !er.test(email)) { return false; }
                else { return true; }
            }
            if (!IsEmail(email)) {
                setEmailValid(false);
                setEmailMessage([{
                    'status': 'error',
                    'message': 'O e-mail informado não é válido'
                }])
            } else {
                setEmailValid(true);
                setEmailMessage([])
                // VALIDANDO CADASTRO EXISTENTE
                var usuariosCadastrados = props.cadastros.filter(produto => produto.email == email);
                if (usuariosCadastrados.length > 0) {
                    // EXISTE UM email JÁ CADASTRADO NO BANCO DE DADOS -> MOSTRAR MENSAGEM DE ERRO
                    setEmailCadastrado(true);
                } else {
                    // NÃO EXISTE NENHUM EMAIL CADASTRADO NO BD -> PODEMOS CONTINUAR COM O CADASTRO -> PASSOU EM TODAS AS VALIDAÇÕES
                    setEmailCadastrado(false);
                }

            }
        }

    }, [email])

    useMemo(() => {
        if (phone) {
            var phoneDDD = phone.replace(/([()])/g, '').replace("_", '').split(" ");

            if (phoneDDD[0].length == 2) { // VERIFICAR EXISTENCIA DO DDD
                function getStateFromDDD(ddd) {

                    let ddds = {
                        "AC": ["68"],
                        "AL": ["82"],
                        "AM": ["92", "97"],
                        "AP": ["96"],
                        "BA": ["71", "73", "74", "75", "77"],
                        "CE": ["85", "88"],
                        "DF": ["61"],
                        "ES": ["27", "28"],
                        "GO": ["62", "64"],
                        "MA": ["98", "99"],
                        "MG": ["31", "32", "33", "34", "35", "37", "38"],
                        "MS": ["67"],
                        "MT": ["65", "66"],
                        "PA": ["91", "93", "94"],
                        "PB": ["83"],
                        "PE": ["81", "87"],
                        "PI": ["86", "89"],
                        "PR": ["41", "42", "43", "44", "45", "46"],
                        "RJ": ["21", "22", "24"],
                        "RN": ["84"],
                        "RO": ["69"],
                        "RR": ["95"],
                        "RS": ["51", "53", "54", "55"],
                        "SC": ["47", "48", "49"],
                        "SE": ["79"],
                        "SP": ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        "TO": ["63"]
                    }

                    for (let index in ddds) {
                        if (ddds[index].includes(ddd)) {
                            return true;
                        }
                    }
                }

                var retornoDDD = getStateFromDDD(phoneDDD[0]);
                console.log(retornoDDD);

                if (retornoDDD) {
                    setPhoneDDDValid(true)
                    setPhoneValid(true);
                } else {
                    setPhoneDDDValid(false)
                    setPhoneValid(false);
                }
            }

            var padrao = /(\d+)| /g;

            if (!phone.match(padrao)) {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([{
                    'status': 'error',
                    'message': 'O telefone deve conter apenas números'
                }]);
            } else {
                setPhoneValid(phone.match(padrao));
                setPhoneMessage([]);
            }
        }
    }, [phone])

    function validatePhone() {
        let newPhoneClear = phone.replace(/[^\d]+/g, '');
        if (newPhoneClear.length < 11) {
            setPhoneValid(false);
            setPhoneMessage([{
                'status': 'error',
                'message': 'O telefone deve conter o DDD'
            }]);
        } else {
            setPhoneValid(true);
            setPhoneMessage([]);
        }
    }

    function abrirModalSelecaoFavoritos(e) {
        let modalPhone = $('#modal_selecao_favorito'); // BG
        let modalContent = $("#modal_selecao_favorito .modal-content"); // CONTENT
        modalPhone.slideToggle(500);
        setTimeout(() => {
            modalContent.slideDown();
        }, 500);
    }

    function fecharModalSelecaoFavoritos(e) {
        let modalPhone = $('#modal_selecao_favorito'); // BG 
        let modalContent = $("#modal_selecao_favorito .modal-content"); // CONTENT
        modalContent.slideUp(500);
        setTimeout(() => {
            modalPhone.slideToggle();
            document.getElementById('form_selecao_favoritos_modal').reset();
        }, 500);
    }

    function sumFavortitos(el) {
        el.preventDefault();
        var checkBoxes = document.querySelectorAll(".card__input");
        var selecionados = 0;
        var arr = new Array();
        checkBoxes.forEach(function (el) {
            if (el.checked) {
                console.log(el.getAttribute("data-value"));
                arr.push({
                    departamento: el.getAttribute("data-departamento"),
                    sub_departamento: el.getAttribute("data-subDepartamento"),
                    codigo: el.value
                });
                selecionados++;
            }
        });
        setFavoritos(arr);
        fecharModalSelecaoFavoritos();
    }

    function cadastrarAtendimento(el) {
        el.preventDefault();

        if (!assunto) {
            toast.error("O assunto do contato deve ser informado");
        } else if (!name || name.length <= 0) {
            toast.error("Você precisa informar seu nome completo");
        } else if (!nameValid) {
            toast.error("O nome informado não é válido");
        } else if (!email || email.length <= 0) {
            toast.error("Você precisa informar seu e-mail completo");
        } else if (!emailValid) {
            toast.error("O e-mail informado não é válido");
        } else if (!phone || phone.length <= 0) {
            toast.error("Você precisa informar seu telefone completo");
        } else if (!phoneDDDValid) {
            toast.error("O DDD do telefone informado não é válido");
        } else if (!phoneValid) {
            toast.error("O telefone informado não é válido");
        } else if (!contatoEmail && !contatoSms && !contatoWhatsapp && !contatoLigacao && !contatoCarta) {
            toast.error("Você precisa selecionar pelo menos um meio de contato");
        } else {
            db.collection('SAC').add({
                categoria: props.paginaAtual,
                perfil: 'publico',
                assunto: assunto,
                nome: name,
                email: email,
                telefone: phone,
                observacao: observacao,
                data_envio: firebase.firestore.FieldValue.serverTimestamp(),
                usuario_cadastrado: emailCadastrado,
                status: 'pendente',
                interesses: favoritos,
                contato_email: contatoEmail,
                contato_sms: contatoSms,
                contato_whatsapp: contatoWhatsapp,
                contato_ligacao: contatoLigacao,
                contato_carta: contatoCarta
            }).then(() => {
                toast.success(name + " sua solicitação foi registrada com sucesso!");
                document.getElementById("dados-form-atendimento").reset();
                setAssunto(null);
                setName(null);
                setEmail(null);
                setPhone("");
                setFavoritos([]);
                setObservacao("");
                setContatoEmail(false);
                setContatoSms(false);
                setContatoWhatsapp(false);
                setContatoLigacao(false);
                setContatoCarta(false);
            }).catch((error) => {
                toast.error("Ocorreu um erro ao registrar sua solicitação, tente novamente");
                console.log(error);
            })
        }
    }

    if (props.formularioImagem) {
        return (
            <div className="grid product d-fx m-bot-25 d-al-cen content__formulario__imagem" style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column-reverse' } : null}>
                <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__formulario__imagem column-xs-12 column-md-5 w40 m-0 left" : "wrapper__formulario__imagem column-xs-12 column-md-5 w100 m-0"}>
                    <div className="box-header-proj-single b-bot">
                        <h1>Como podemos lhe auxiliar em sua compra?</h1>
                        <p className="fz-12 m-top-10 m-bot-10">* Fique a vontade para solicita ajuda, tirar dúvidas, ou solicitar um suporte totalmente personalizado e privativo.</p>
                        <p className="fz-11 m-top-10 m-bot-10 ">Para reembolso, reclamações e etc, <Link to="/ouvidoria" className='fz-11' style={{ color: 'var(--dark-grey-blue)' }} href="javascript:void(0)">clique aqui</Link>.</p>
                    </div>{/*box-header-proj-single*/}

                    {/* BOX FORMULARIO CONTATO */}
                    <form id='dados-form-atendimento' className='singup-person' onSubmit={(el) => cadastrarAtendimento(el)}>
                        <div className='box-input-single'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Assunto</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <input id='dados-contato-assunto' className='input-form w100 fz-16 m-top-5' type='text' name="assunto" placeholder='Seu assunto' value={assunto} onChange={(e) => setAssunto(e.target.value)} />
                            {
                                (!assunto)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>Por favor, digite o assunto em que podemos ajudar</span>
                                    </div>
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-15'> {/* PROJETO FAVORITO */}
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Já possui um projeto que gostou?</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <div className='termos-news-single d-fx d-ju-sp-ev d-fw-wp w100 m-top-10'>
                                <div className='canais-single w100 m-top-10 m-bot-10'>
                                    <input id='vendas-projeto-favorito-positivo' type='radio' name="projeto_favorito" value="sim" onClick={(e) => abrirModalSelecaoFavoritos(e)} />
                                    <label for="vendas-projeto-favorito-positivo">Sim</label>
                                </div>
                                <div className='canais-single w100 m-top-10 m-bot-10'>
                                    <input id='vendas-projeto-favorito-negativo' type='radio' name="projeto_favorito" value="nao" onClick={() => setFavoritos([])} />
                                    <label for="vendas-projeto-favorito-negativo">Não</label>
                                </div>
                            </div>{/*termos-news-single*/}
                            {
                                (favoritos && favoritos.length > 0)
                                    ?
                                    <div className='info-valid d-fx d-al-cen'>
                                        <span className='icon-valid m-top-5'><TiInputChecked className='fz-18' /></span>
                                        <span className='text-valid m-lef-5'>Você selecionou <strong><em>{favoritos.length} {(favoritos.length > 1) ? "Itens" : "Item"}</em></strong>.</span>
                                    </div>
                                    : null
                            }

                            {/* MODAL SELEÇÃO DE PROJETO FAVORITO */}
                            <div id='modal_selecao_favorito' class="modal modal-enter modal-show d-nn">
                                <div id='modal_content_selecao_favorito' class="modal-content modal-content-md modal-content-enter modal-content-show d-nn">
                                    <div class="modal-body">
                                        <div class="container-fluid" data-component="validation">
                                            <div class="modal-title">Onde deseja realizar a busca de favoritos?</div>
                                            <form id='form_selecao_favoritos_modal' class="row__modal">

                                                <div className='w100'>
                                                    <div className='box-input-single m-top-20'>
                                                        <div className='box-opts-acount'>
                                                            <button
                                                                className='otp-acount w50-abs fz-16 active'
                                                                onClick={
                                                                    (e) => {
                                                                        e.preventDefault();
                                                                    }
                                                                }
                                                            >
                                                                <MdHomeFilled />
                                                                <span className='m-lef-5'>Todos os Produtos</span>
                                                            </button>

                                                            <button
                                                                className='otp-acount w50-abs fz-16 default'
                                                                onClick={
                                                                    (e) => {
                                                                        e.preventDefault();
                                                                        toast.error("Esta função está em desenvolvimento");
                                                                    }
                                                                }
                                                            >
                                                                <MdHomeWork />
                                                                <span className='m-lef-5'>Busca Específica...</span>
                                                            </button>
                                                        </div>
                                                    </div>{/*box-input-single */}
                                                </div>
                                                <div class="w100 m-bot-10 m-top-10">
                                                    <h2 className="fz-20 w100 d-bk m-bot-5">Projetos Prontos</h2>
                                                    <div class="box__interesse__area">
                                                        {
                                                            (props.projetos)
                                                                ?
                                                                props.projetos.map((dadosProjeto, index) => {
                                                                    return (
                                                                        <label key={index} class="card__interesse__area__user">
                                                                            <input className="card__input" type="checkbox" name='dados-proj_pronto' style={{ display: 'none' }} value={dadosProjeto.orderId} data-departamento="projetos" data-subDepartamento="projetos-prontos" data-codigo={dadosProjeto.orderId} />
                                                                            <div class="card__body">
                                                                                <div class="card__body-cover">
                                                                                    <img class="card__body-cover-image" src={dadosProjeto.capa} />
                                                                                    <span class="card__body-cover-checkbox">
                                                                                        <svg class="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
                                                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="card__body-header">
                                                                                    <h2 class="card__body-header-title fz-16">{dadosProjeto.titulo}</h2>
                                                                                    <div class="card__body-header-subtitle">

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    );
                                                                })
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 modal-footer right m-top-5">
                                                    <button class="btn-default button-text" type="button" data-trigger="close" onClick={(e) => abrirModalSelecaoFavoritos(e)}>Cancelar</button>
                                                    <button class="btn-default button-primary" type="button" onClick={(e) => sumFavortitos(e)}>Salvar seleção</button>
                                                </div>
                                                <div className='clear'></div>
                                            </form>
                                        </div>
                                    </div>
                                    <label>
                                        <AiOutlineClose className='modal-close' onClick={(e) => abrirModalSelecaoFavoritos(e)} />
                                    </label>
                                </div>
                            </div>
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Nome Completo</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id="nome-cadastro-singup" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                            {
                                (nameMessage)
                                    ?
                                    nameMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>E-mail</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id='email-cadastro-singup' className={(email && !emailValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='email' name="email" placeholder='seuemail@email.com' value={email} onChange={(ev) => setEmail(ev.target.value)} />
                            {
                                (emailMessage)
                                    ?
                                    emailMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Telefone</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <InputMask id='telefone-cadastro-singup' mask="(99) 99999-9999" placeholder='Insira seu telefone' className={(phone && phone.match('[0-9]') && !phoneValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} value={phone} onChange={(ev) => setPhone(ev.target.value)} onBlur={() => validatePhone()} />
                            <div>
                                {
                                    (!phoneDDDValid)
                                        ?
                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                            <span className='icon-invalid'><TiInfo /></span>
                                            <span className='text-invalid m-lef-5'>O DDD informado não é válido</span>
                                        </div>
                                        : null
                                }
                            </div>
                            <div>
                                {
                                    (phoneMessage)
                                        ?
                                        phoneMessage.map((infoStatus) => {
                                            if (infoStatus.status == 'error') {
                                                return (
                                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-invalid'><TiInfo /></span>
                                                        <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            } else if (infoStatus.status == 'success') {
                                                return (
                                                    <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-valid'><TiInfo /></span>
                                                        <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            }
                                        })
                                        : null
                                }
                            </div>
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Informações Adicionais</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            {/* <input id='telefone-cadastro' className='input-form w100 fz-16 m-top-5' type='text' name="nome" placeholder='(99) 99999-9999' /> */}
                            <textarea className='input-form w100 fz-14 m-top-5' style={{ maxWidth: '100%', resize: 'vertical', height: '125px' }} value={observacao} onChange={(ev) => setObservacao(ev.target.value)} placeholder="Ex.: Tenho dúvidas sobre se a cama que eu já possui irá caber no quarto.">
                                {
                                    observacao
                                }
                            </textarea>
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-15'> {/* MEIOS DE COMUNICAÇÃO */}
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Selecione o método que podemos entrar em contato</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <div className='termos-news-single d-fx d-ju-sp-ev d-fw-wp d-fd-col w100 m-top-10'>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-email-dados' type='checkbox' name="contact_email" checked={contatoEmail} onClick={() => setContatoEmail((prevState) => !prevState)} />
                                    <label for="newsletter-email-dados">E-mail</label>
                                </div>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-sms-dados' type='checkbox' name="contact_sms" checked={contatoSms} onClick={() => setContatoSms((prevState) => !prevState)} />
                                    <label for="newsletter-sms-dados">SMS</label>
                                </div>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-whatsapp-dados' type='checkbox' name="contact_whats" checked={contatoWhatsapp} onClick={() => setContatoWhatsapp((prevState) => !prevState)} />
                                    <label for="newsletter-whatsapp-dados">WhatsApp</label>
                                </div>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-phone-dados' type='checkbox' name="contact_phone" checked={contatoLigacao} onClick={() => setContatoLigacao((prevState) => !prevState)} />
                                    <label for="newsletter-phone-dados">Contato telefônico</label>
                                </div>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-letter-dados' type='checkbox' name="contact_letter" checked={contatoCarta} onClick={() => setContatoCarta((prevState) => !prevState)} />
                                    <label for="newsletter-letter-dados">Correspondência</label>
                                </div>
                            </div>{/*termos-news-single*/}
                        </div>{/*box-input-single */}

                        <button className="button button-full button-primary margin-top align-left" title={(assunto && assunto.length && name && nameValid && email && emailValid && phone && phoneDDDValid && phoneValid && (contatoEmail || contatoSms || contatoWhatsapp || contatoLigacao || contatoCarta)) ? "Enviar formulário" : "O botão será habilitado após os campos obrigatórios serem preenchidos corretamente"} disabled={(assunto && assunto.length && name && nameValid && email && emailValid && phone && phoneDDDValid && phoneValid && (contatoEmail || contatoSms || contatoWhatsapp || contatoLigacao || contatoCarta)) ? false : true}>
                            Concordo e quero prosseguir
                        </button>
                        <div className='aviso-termos m-top-10 helper'>
                            Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                        </div>
                    </form>
                </div>
                <div className={(props.responsivoSize && props.responsivoSize > 768) ? "wrapper__imagem__formulario w50 d-fx d-al-cen d-ju-cen m-top-0 right m-bot-0" : "wrapper__imagem__formulario w100 d-fx d-al-cen d-ju-cen m-top-0 m-bot-0"}>
                    <img src={contactUs} style={(props.responsivoSize && props.responsivoSize < 350 || props.responsivoSize > 768) ? { width: '100%' } : { maxWidth: '80%' }} />
                </div>
                <div className="clear"></div>
            </div>
        )
    } else {
        return (
            <div className="grid product d-fx m-bot-25 d-al-cen content__formulario__imagem" style={(props.responsivoSize && props.responsivoSize < 768) ? { flexDirection: 'column-reverse' } : null}>
                <div className="wrapper__formulario__imagem column-xs-12 column-md-5 w100 m-0">
                    <div className="box-header-proj-single b-bot">
                        <h1>Como podemos lhe auxiliar em sua compra?</h1>
                        <p className="fz-12 m-top-10 m-bot-10">* Fique a vontade para solicita ajuda, tirar dúvidas, ou solicitar um suporte totalmente personalizado e privativo.</p>
                        <p className="fz-11 m-top-10 m-bot-10 ">Para reembolso, reclamações e etc, <Link to="/ouvidoria" className='fz-11' style={{ color: 'var(--dark-grey-blue)' }} href="javascript:void(0)">clique aqui</Link>.</p>
                    </div>{/*box-header-proj-single*/}

                    {/* BOX FORMULARIO CONTATO */}
                    <form id='dados-form-atendimento' className='singup-person' onSubmit={(el) => cadastrarAtendimento(el)}>
                        <div className='box-input-single'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Assunto</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <input id='dados-contato-assunto' className='input-form w100 fz-16 m-top-5' type='text' name="assunto" placeholder='Seu assunto' value={assunto} onChange={(e) => setAssunto(e.target.value)} />
                            {
                                (!assunto)
                                    ?
                                    <div className='info-invalid d-fx d-al-cen m-top-10'>
                                        <span className='icon-invalid'><TiInfo /></span>
                                        <span className='text-invalid m-lef-5'>Por favor, digite o assunto em que podemos ajudar</span>
                                    </div>
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-15'> {/* PROJETO FAVORITO */}
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Já possui um projeto que gostou?</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <div className='termos-news-single d-fx d-ju-sp-ev d-fw-wp w100 m-top-10'>
                                <div className='canais-single w100 m-top-10 m-bot-10'>
                                    <input id='vendas-projeto-favorito-positivo' type='radio' name="projeto_favorito" value="sim" onClick={(e) => abrirModalSelecaoFavoritos(e)} />
                                    <label for="vendas-projeto-favorito-positivo">Sim</label>
                                </div>
                                <div className='canais-single w100 m-top-10 m-bot-10'>
                                    <input id='vendas-projeto-favorito-negativo' type='radio' name="projeto_favorito" value="nao" onClick={() => setFavoritos([])} />
                                    <label for="vendas-projeto-favorito-negativo">Não</label>
                                </div>
                            </div>{/*termos-news-single*/}
                            {
                                (favoritos && favoritos.length > 0)
                                    ?
                                    <div className='info-valid d-fx d-al-cen'>
                                        <span className='icon-valid m-top-5'><TiInputChecked className='fz-18' /></span>
                                        <span className='text-valid m-lef-5'>Você selecionou <strong><em>{favoritos.length} {(favoritos.length > 1) ? "Itens" : "Item"}</em></strong>.</span>
                                    </div>
                                    : null
                            }

                            {/* MODAL SELEÇÃO DE PROJETO FAVORITO */}
                            <div id='modal_selecao_favorito' class="modal modal-enter modal-show d-nn">
                                <div id='modal_content_selecao_favorito' class="modal-content modal-content-md modal-content-enter modal-content-show d-nn">
                                    <div class="modal-body">
                                        <div class="container-fluid" data-component="validation">
                                            <div class="modal-title">Onde deseja realizar a busca de favoritos?</div>
                                            <form id='form_selecao_favoritos_modal' class="row__modal">

                                                <div className='w100'>
                                                    <div className='box-input-single m-top-20'>
                                                        <div className='box-opts-acount'>
                                                            <button
                                                                className='otp-acount w50-abs fz-16 active'
                                                                onClick={
                                                                    (e) => {
                                                                        e.preventDefault();
                                                                    }
                                                                }
                                                            >
                                                                <MdHomeFilled />
                                                                <span className='m-lef-5'>Todos os Produtos</span>
                                                            </button>

                                                            <button
                                                                className='otp-acount w50-abs fz-16 default'
                                                                onClick={
                                                                    (e) => {
                                                                        e.preventDefault();
                                                                        toast.error("Esta função está em desenvolvimento");
                                                                    }
                                                                }
                                                            >
                                                                <MdHomeWork />
                                                                <span className='m-lef-5'>Busca Específica...</span>
                                                            </button>
                                                        </div>
                                                    </div>{/*box-input-single */}
                                                </div>
                                                <div class="w100 m-bot-10 m-top-10">
                                                    <h2 className="fz-20 w100 d-bk m-bot-5">Projetos Prontos</h2>
                                                    <div class="box__interesse__area">
                                                        {
                                                            (props.projetos)
                                                                ?
                                                                props.projetos.map((dadosProjeto, index) => {
                                                                    return (
                                                                        <label key={index} class="card__interesse__area__user">
                                                                            <input className="card__input" type="checkbox" name='dados-proj_pronto' style={{ display: 'none' }} value={dadosProjeto.orderId} data-departamento="projetos" data-subDepartamento="projetos-prontos" data-codigo={dadosProjeto.orderId} />
                                                                            <div class="card__body">
                                                                                <div class="card__body-cover">
                                                                                    <img class="card__body-cover-image" src={dadosProjeto.capa} />
                                                                                    <span class="card__body-cover-checkbox">
                                                                                        <svg class="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
                                                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="card__body-header">
                                                                                    <h2 class="card__body-header-title fz-16">{dadosProjeto.titulo}</h2>
                                                                                    <div class="card__body-header-subtitle">

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    );
                                                                })
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 modal-footer right m-top-5">
                                                    <button class="btn-default button-text" type="button" data-trigger="close" onClick={(e) => abrirModalSelecaoFavoritos(e)}>Cancelar</button>
                                                    <button class="btn-default button-primary" type="button" onClick={(e) => sumFavortitos(e)}>Salvar seleção</button>
                                                </div>
                                                <div className='clear'></div>
                                            </form>
                                        </div>
                                    </div>
                                    <label>
                                        <AiOutlineClose className='modal-close' onClick={(e) => abrirModalSelecaoFavoritos(e)} />
                                    </label>
                                </div>
                            </div>
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Nome Completo</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id="nome-cadastro-singup" className={(name && !nameValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='text' name="nome" placeholder='Seu Nome' value={name} onChange={(ev) => setName(ev.target.value)} />
                            {
                                (nameMessage)
                                    ?
                                    nameMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>E-mail</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <input id='email-cadastro-singup' className={(email && !emailValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} type='email' name="email" placeholder='seuemail@email.com' value={email} onChange={(ev) => setEmail(ev.target.value)} />
                            {
                                (emailMessage)
                                    ?
                                    emailMessage.map((infoStatus) => {
                                        if (infoStatus.status == 'error') {
                                            return (
                                                <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-invalid'><TiInfo /></span>
                                                    <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        } else if (infoStatus.status == 'success') {
                                            return (
                                                <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                    <span className='icon-valid'><TiInfo /></span>
                                                    <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                </div>
                                            )
                                        }
                                    })
                                    : null
                            }
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Telefone</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            <InputMask id='telefone-cadastro-singup' mask="(99) 99999-9999" placeholder='Insira seu telefone' className={(phone && phone.match('[0-9]') && !phoneValid) ? 'input-form w100 fz-16 m-top-5 invalid' : 'input-form w100 fz-16 m-top-5'} value={phone} onChange={(ev) => setPhone(ev.target.value)} onBlur={() => validatePhone()} />
                            <div>
                                {
                                    (!phoneDDDValid)
                                        ?
                                        <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                            <span className='icon-invalid'><TiInfo /></span>
                                            <span className='text-invalid m-lef-5'>O DDD informado não é válido</span>
                                        </div>
                                        : null
                                }
                            </div>
                            <div>
                                {
                                    (phoneMessage)
                                        ?
                                        phoneMessage.map((infoStatus) => {
                                            if (infoStatus.status == 'error') {
                                                return (
                                                    <div className='info-invalid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-invalid'><TiInfo /></span>
                                                        <span className='text-invalid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            } else if (infoStatus.status == 'success') {
                                                return (
                                                    <div className='info-valid d-fx d-al-cen m-top-10 d-nn'>
                                                        <span className='icon-valid'><TiInfo /></span>
                                                        <span className='text-valid m-lef-5'>{infoStatus.message}</span>
                                                    </div>
                                                )
                                            }
                                        })
                                        : null
                                }
                            </div>
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-20'>
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Informações Adicionais</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                            </label>
                            {/* <input id='telefone-cadastro' className='input-form w100 fz-16 m-top-5' type='text' name="nome" placeholder='(99) 99999-9999' /> */}
                            <textarea className='input-form w100 fz-14 m-top-5' style={{ maxWidth: '100%', resize: 'vertical', height: '125px' }} value={observacao} onChange={(ev) => setObservacao(ev.target.value)} placeholder="Ex.: Tenho dúvidas sobre se a cama que eu já possui irá caber no quarto.">
                                {
                                    observacao
                                }
                            </textarea>
                        </div>{/*box-input-single */}

                        <div className='box-input-single m-top-15'> {/* MEIOS DE COMUNICAÇÃO */}
                            <label>
                                <div className='title-input d-fx d-al-cen'>
                                    <span className='fz-12 m-rig-5'>Selecione o método que podemos entrar em contato</span>
                                    <span className='icon-info-add'><IoIosInformationCircleOutline /></span>
                                </div>
                                <div className='helper-input d-nn'>
                                    <h3>Seu CPF será usado para:</h3>
                                    <ul>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                        <li>
                                            Te Identificar
                                        </li>
                                    </ul>
                                </div>{/*Helper*/}
                            </label>
                            <div className='termos-news-single d-fx d-ju-sp-ev d-fw-wp d-fd-col w100 m-top-10'>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-email-dados' type='checkbox' name="contact_email" checked={contatoEmail} onClick={() => setContatoEmail((prevState) => !prevState)} />
                                    <label for="newsletter-email-dados">E-mail</label>
                                </div>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-sms-dados' type='checkbox' name="contact_sms" checked={contatoSms} onClick={() => setContatoSms((prevState) => !prevState)} />
                                    <label for="newsletter-sms-dados">SMS</label>
                                </div>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-whatsapp-dados' type='checkbox' name="contact_whats" checked={contatoWhatsapp} onClick={() => setContatoWhatsapp((prevState) => !prevState)} />
                                    <label for="newsletter-whatsapp-dados">WhatsApp</label>
                                </div>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-phone-dados' type='checkbox' name="contact_phone" checked={contatoLigacao} onClick={() => setContatoLigacao((prevState) => !prevState)} />
                                    <label for="newsletter-phone-dados">Contato telefônico</label>
                                </div>
                                <div className='canais-single m-top-10 m-bot-10'>
                                    <input id='newsletter-letter-dados' type='checkbox' name="contact_letter" checked={contatoCarta} onClick={() => setContatoCarta((prevState) => !prevState)} />
                                    <label for="newsletter-letter-dados">Correspondência</label>
                                </div>
                            </div>{/*termos-news-single*/}
                        </div>{/*box-input-single */}

                        <button className="button button-full button-primary margin-top align-left" title={(assunto && assunto.length && name && nameValid && email && emailValid && phone && phoneDDDValid && phoneValid && (contatoEmail || contatoSms || contatoWhatsapp || contatoLigacao || contatoCarta)) ? "Enviar formulário" : "O botão será habilitado após os campos obrigatórios serem preenchidos corretamente"} disabled={(assunto && assunto.length && name && nameValid && email && emailValid && phone && phoneDDDValid && phoneValid && (contatoEmail || contatoSms || contatoWhatsapp || contatoLigacao || contatoCarta)) ? false : true}>
                            Concordo e quero prosseguir
                        </button>
                        <div className='aviso-termos m-top-10 helper'>
                            Ao prosseguir, você declara que é maior de idade e que leu e está de acordo com as <a className='info-primary' href='javascript:void(0)'>Condições de Uso</a> e <a className='info-primary' href='javascript:void(0)'>Política de Privacidade</a> da Engeart.
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default FormVendas