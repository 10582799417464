import { useEffect } from 'react';
// importação react router
import { Outlet } from 'react-router-dom';
import PC_Sidebar from './Pages/Templates/Sidebar/PC-Sidebar';
import { useNavigate } from 'react-router-dom';

function PC_Main(props) {
    {/* TOPICS*/ }
    let navigate = useNavigate();

    useEffect(() => {
        var url_orginal = window.location.href;
        var url_explode = url_orginal.split('/');
        console.log(url_explode);

        if (!props.user) {
            navigate("/");
        }
    }, []);
    return (
        <div id='myPainelUser'>
            <Outlet />
        </div>
    )
}

export default PC_Main;