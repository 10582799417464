import FormSingUp from '../Assets/components/FormSingUp';
import '../Assets/styles/css/Main.css';

function SingUp(props) {
    return (
        <div id='singUp' className='wrapper-singup' style={(props.responsivoSize && props.responsivoSize < 380) ? { flexDirection: 'row', padding: '10px' } : null}>
            <div className='content-singup'>
                <div className='center'>
                    <FormSingUp
                        responsivoSize={props.responsivoSize}
                        setUser={props.setUser}
                    />
                </div>
            </div>
        </div >
    );
}

export default SingUp;